import React, { FC, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { AppUrl } from 'src/Framework/Common/AppUrl';
import Screen from 'src/Framework/Layout/Screen';
import DragAndDrop from 'src/Framework/Layout/DragAndDrop';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';
import { store, useAppSelector } from 'src/store';

import { loadActivityTab } from 'src/App/ActivityPanel/Store/ActivityActions';
import Main from 'src/Activities/Schedule/Main';
import { slicesFilter } from 'src/Activities/Schedule/Utils/Utils';
import {
  getBeginOfDay,
  getEndOfDay
} from 'src/Activities/Schedule/RightSideBar/TimeSelect/Calendar/shared/dates';
import {
  getScheduleSliceEvents,
  getScheduleSlices,
  updateLoader
} from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import {
  setScheduleSlices,
  updateScheduleSlices
} from 'src/Activities/Schedule/Store/ScheduleSlicesActions';
import { updateScheduleSliceEvents } from 'src/Activities/Schedule/Store/ScheduleSliceEventsActions';
import { filterExpiredEvents } from 'src/Activities/Schedule/utils';
import { appointmentsTabsData, getClinicOpen } from './utils';

import Container from '../components/Container';
import Header from '../components/Header';
import Tabs from '../components/Tabs';
import PushHandler from './PushHandler';

import { HeaderLink } from '../components/Header/styled';
import { MainLayout } from './styled';

interface IProps {}

const Component: FC<IProps> = (props: IProps) => {
  const clinics = useAppSelector((state) => state.scheduleDetails.clinics);
  const isLoading = useAppSelector((state) => state.scheduleDetails.isLoading);
  const slices = useAppSelector((state) => state.schedule.slices);
  const providerCode = useAppSelector(
    (state) => state.dashboard.filters.provider?.code
  );
  const [selectedDay, setSelectedDay] = useState<number>(0);
  const [subscriptionInfo, setSubscriptionInfo] = useState<any>(null);
  const currentClinic = clinics.find(
    (clinic) => clinic.id === Number(store.getState().user.current.clinicId)
  )!;

  const currentView = 'day';
  const weekFilter = '';

  // const scheduleTimeProps = useMemo(() => {
  //   return getClinicsStartEndTimeInterval(
  //     currentClinic ? [currentClinic] : []
  //   )!;
  // }, [currentClinic]);

  const filteredSlices = useMemo(() => {
    const { reqTime, ...slicesDict } = slices;
    const filteredSlices = slicesFilter(
      _.values(slicesDict),
      currentView,
      weekFilter
    );
    if (filteredSlices[0]) {
      return [filteredSlices[0]];
    }
    return [];
  }, [slices, currentView, weekFilter]);

  const updateSliceEvents = async (
    currentView: string,
    prevSlices: any,
    startDate: string,
    endDate: string,
    clinicCodes: string[],
    providerCodes: string[],
    type?: string,
    refreshAll?: boolean
  ) => {
    clinicCodes = clinicCodes ?? [];
    providerCodes = providerCodes ?? [];
    var viewType: any = 0;
    if (currentView == 'workweek') {
      providerCodes = providerCodes.slice(0, 1);
      viewType = 1;
    } else if (currentView == 'weekprovider') {
      viewType = 2;
      clinicCodes = clinicCodes.slice(0, 1);
    }
    const reqTime = new Date().getTime();
    store.dispatch(updateLoader(true));
    try {
      const response = await getScheduleSlices(
        true,
        viewType,
        startDate, //start Date
        endDate, //end Date
        clinicCodes, // current clinic codes
        providerCodes // current provider codes
      );
      if (response.data.success) {
        var scheduleSlices = response.data.result;
        let slices = {};
        scheduleSlices.map((s: any, i: number) => {
          slices[s.id] = { ...scheduleSlices[i] };
        });
        let updatedInfo = findUpdatedSlices(
          scheduleSlices,
          prevSlices,
          refreshAll
        );
        let existingSlices = _.clone(prevSlices);
        delete existingSlices['reqTime'];
        let slicesInfo = {
          slices,
          existingSlices
        };
        setSubscriptionInfo(slicesInfo);
        store.dispatch(setScheduleSlices(reqTime, slices));
        if (updatedInfo.updatedSlices.length > 0) {
          fetchSliceEvents(updatedInfo, type);
        }
      } else {
        console.log('schedule slices response success false');
      }
    } catch (error) {
      console.log('getSheduleSlices error', error);
    } finally {
      store.dispatch(updateLoader(false));
    }
  };

  const fetchSliceEvents = (updatedInfo: any, type?: any) => {
    getScheduleSliceEvents(updatedInfo.updatedSlices)
      .then((response) => {
        const reqTime = new Date().getTime();
        if (response.data.success) {
          let apiSlices = response.data.result.slices;
          let newSlices = {};
          apiSlices.forEach((s: any, i: number) => {
            newSlices[s.id] = { ...apiSlices[i], reqTime };
          });
          store.dispatch(updateScheduleSlices(reqTime, newSlices));
          const notExpiredEvents = filterExpiredEvents(
            response.data.result.events
          );
          updateScheduleSliceEvents(reqTime, notExpiredEvents);
          store.dispatch(updateLoader(false));
        } else {
          console.log('schedule slice events response success false');
        }
      })
      .catch((error) => {
        console.log('getSheduleSliceEvents error', error);
      });
  };

  const findUpdatedSlices = (
    scheduleSlices: any,
    slices: any,
    refreshAll?: boolean
  ) => {
    let contentUpdated = false;
    let updatedSlices: any[] = [];
    let keys = Object.keys(slices);
    scheduleSlices.map((s: any) => {
      let exists = false;
      if (!s.checksum) {
        let index = updatedSlices.findIndex((f: any) => f.id === s.id);
        contentUpdated = true;
        if (index < 0) {
          updatedSlices.push(s);
        }
      } else {
        if ((keys && keys.length > 0) || refreshAll) {
          keys.map((key: any) => {
            if (s.id === key) {
              exists = true;
              if (s.checksum != slices[key]['checksum']) {
                let index = updatedSlices.findIndex((f: any) => f.id === key);
                contentUpdated = true;
                if (index < 0) {
                  updatedSlices.push(slices[key]);
                }
              }
            }
          });
          if (!exists) {
            updatedSlices.push(s);
          }
        } else {
          updatedSlices = scheduleSlices;
        }
      }
    });
    updatedSlices = refreshAll ? scheduleSlices : updatedSlices;
    return { contentUpdated, updatedSlices };
  };

  const setCurrentDateRangeHandler = (targetRange: any[]) => {
    if (providerCode) {
      try {
        const startDate =
          targetRange[0] &&
          moment(targetRange[0]).format('YYYY-MM-DDTHH:mm:ss');
        const endDate =
          targetRange[1] &&
          moment(targetRange[1]).format('YYYY-MM-DDTHH:mm:ss');

        updateSliceEvents(
          currentView,
          slices,
          startDate,
          endDate,
          [currentClinic.code],
          [providerCode],
          'datechange'
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (providerCode) {
      const date = new Date();
      date.setDate(date.getDate() + selectedDay);
      setCurrentDateRangeHandler([getBeginOfDay(date), getEndOfDay(date)]);
    }
  }, [selectedDay, providerCode]);

  const clinicOpen = currentClinic
    ? getClinicOpen(currentClinic.code)
    : [false];

  return (
    <Container>
      <Header>
        <HeaderLink
          tabIndex={0}
          role="link"
          onClick={() => loadActivityTab(AppUrl.SCHEDULE, store.dispatch)}
          onKeyDown={onKeyDownAccessibility}
        >
          Appointments
        </HeaderLink>
      </Header>
      <Tabs
        data={appointmentsTabsData(clinicOpen)}
        selected={selectedDay}
        onChange={(value) => {
          setSelectedDay(Number(value));
        }}
      />
      <DragAndDrop>
        <Screen>
          <MainLayout>
            {isLoading ? <AbsoluteLoader /> : null}
            <PushHandler subscriptionInfo={subscriptionInfo} />
            <Main
              widget={true}
              updateWaitingList={emptyFunc}
              getParsedWaitingGroupPatients={() => []}
              setMainSchedulerBody={emptyFunc}
              onMainBodyScroll={emptyFunc}
              // scheduleTimeProps={scheduleTimeProps}
              filteredSlices={filteredSlices}
            />
          </MainLayout>
        </Screen>
      </DragAndDrop>
    </Container>
  );
};

export default React.memo(Component);

const emptyFunc = () => {};

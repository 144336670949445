
import React from "react";


interface IProps {
    stroke?: string;
    height?: string;
    width?: string;
}

export const ArrivedSvg = ({ stroke, height, width }: IProps) => (

    <svg width={width || "20"} height={height || "20"}  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fillRule="evenodd" 
            clipRule="evenodd"
            d="M6.11091 4.11091C7.14236 3.07946 8.54131 2.5 10 2.5C11.4587 2.5 12.8576 3.07946 13.8891 4.11091C14.9205 5.14236 15.5 6.54131 15.5 8C15.5 10.3077 14.107 12.4386 12.6326 14.0359C11.9034 14.8258 11.1727 15.4654 10.6238 15.9075C10.37 16.112 10.1559 16.2736 10 16.3876C9.84414 16.2736 9.63005 16.112 9.37616 15.9075C8.82735 15.4654 8.0966 14.8258 7.3674 14.0359C5.89298 12.4386 4.5 10.3077 4.5 8C4.5 6.54131 5.07946 5.14236 6.11091 4.11091ZM9.72235 17.4158C9.72251 17.4159 9.72265 17.416 10 17L9.72265 17.416C9.8906 17.528 10.1094 17.528 10.2773 17.416L10 17C10.2773 17.416 10.2775 17.4159 10.2777 17.4158L10.2781 17.4155L10.2794 17.4147L10.2836 17.4118L10.2984 17.4018C10.3111 17.3932 10.3292 17.3808 10.3526 17.3645C10.3993 17.3321 10.4666 17.2847 10.5516 17.2229C10.7216 17.0995 10.9627 16.9186 11.2512 16.6863C11.8273 16.2221 12.5966 15.5492 13.3674 14.7141C14.893 13.0614 16.5 10.6923 16.5 8C16.5 6.27609 15.8152 4.62279 14.5962 3.40381C13.3772 2.18482 11.7239 1.5 10 1.5C8.27609 1.5 6.62279 2.18482 5.40381 3.40381C4.18482 4.62279 3.5 6.27609 3.5 8C3.5 10.6923 5.10702 13.0614 6.6326 14.7141C7.4034 15.5492 8.17265 16.2221 8.74884 16.6863C9.03731 16.9186 9.27838 17.0995 9.44839 17.2229C9.53342 17.2847 9.60074 17.3321 9.64742 17.3645C9.67076 17.3808 9.68894 17.3932 9.7016 17.4018L9.7164 17.4118L9.72061 17.4147L9.72191 17.4155L9.72235 17.4158ZM10 10C11.1046 10 12 9.10457 12 8C12 6.89543 11.1046 6 10 6C8.89543 6 8 6.89543 8 8C8 9.10457 8.89543 10 10 10Z"
            fill={stroke || "#666666"} />
    </svg>
);

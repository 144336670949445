import React from 'react';

interface IProps {
  stroke?: string;
}

export const ClockSvg = ({ stroke }: IProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3047 9.65205C17.3047 7.89116 16.6973 6.18416 15.5852 4.81895C14.473 3.45375 12.924 2.51389 11.1994 2.1579C9.47491 1.80191 7.68037 2.05157 6.1185 2.86477C4.55663 3.67797 3.32301 5.00495 2.6257 6.62189C1.9284 8.23883 1.81008 10.0468 2.29071 11.7408C2.77134 13.4348 3.8215 14.9113 5.26406 15.9211C6.70663 16.9309 8.45333 17.4123 10.2095 17.2841C11.9658 17.1559 13.624 16.426 14.9047 15.2173"
      stroke="#3A5B8B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7519 16.9525L16.8609 13.8102L13.5338 13.9951L15.7519 16.9525Z"
      fill="#3A5B8B"
      stroke="#3A5B8B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.92969 5.47803V9.65199H14.1037"
      stroke="#3A5B8B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

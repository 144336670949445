import React, { FC, useEffect, useState } from 'react';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import test_ids from 'src/tests-script/pages/Schedule/GroupApptPopup';

import { GroupPopupContent as PopupContent } from 'src/Activities/Schedule/Common/Classes';
import { getAppointment } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/Actions';
import { IApptResponse } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';

import Main, { IProps as IMainProps } from './Main';

type IMain = Omit<IMainProps, 'data'>;

interface IProps extends IMain {
  callerRef?: React.MutableRefObject<any>;
  appointmentId: number;
  setRecordChanged?: () => void;
}

const Component: FC<IProps> = ({ appointmentId, ...otherProps }: IProps) => {
  const [data, setData] = useState<IApptResponse | null>(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const res = await getAppointment(appointmentId);
      if (res) {
        setData(res);
      }
      setLoading(false);
    };
    init();
  }, []);
  return (
    <PopupContent id={test_ids.groupPopoverContainer}>
      {loading && <AbsoluteLoader />}
      {data && <Main {...otherProps} data={data} />}
    </PopupContent>
  );
};

export default Component;

import React from 'react';
import { TimeBarContainer, TimeLine, BigTimeLineLabel } from './TimeBarStyles';

import { defaultTimeLines } from 'src/Activities/Schedule/InitData';
import { getStandartTimeFromMilitaryTime } from '../../../Utils/Utils';

interface IState {}
interface IProps {
  schedules: any[];
  regularMeetingTime: any;
}

class TimeBar extends React.Component<IProps, IState> {
  render() {
    var { schedules } = this.props;
    var timeLines: any[];
    if (schedules) {
      timeLines = schedules.map((schedule, idx) => {
        return {
          label: schedule.startTime,
          isBig: idx % 2 === 0,
          noShow: schedule['noshow'],
          plShow: schedule['plshow']
        };
      });
    } else {
      timeLines = defaultTimeLines;
    }
    return (
      <TimeBarContainer>
        {timeLines.map((tl: any, index: number) => {
          let time = getStandartTimeFromMilitaryTime(tl.label);
          if (time[1] === ':') {
            time = '0' + time;
          }
          var bigLabel: boolean = parseInt(time[3] + time[4]) == 0;
          if (this.props.regularMeetingTime < 20) {
            bigLabel =
              parseInt(time[3] + time[4]) == 0 ||
              parseInt(time[3] + time[4]) == 30;
          }
          return (
            <TimeLine
              id={
                tl.noShow &&
                timeLines[index - 1] &&
                !timeLines[index - 1].noShow
                  ? 'scroll-to-this'
                  : ''
              }
              plShow={tl.plShow}
              bottomBorderShow={
                tl.noShow &&
                timeLines[index + 1] &&
                !timeLines[index + 1].noShow
              }
              topBorderShow={
                (tl.noShow &&
                  timeLines[index - 1] &&
                  !timeLines[index - 1].noShow) ||
                (tl.noShow && !timeLines[index - 1])
              }
              noShow={tl.noShow}
              isShown={
                parseInt(time[3] + time[4]) % this.props.regularMeetingTime ===
                0
                  ? 'true'
                  : 'false'
              }
              regularMeetingTime={this.props.regularMeetingTime}
              key={JSON.stringify(tl)}
            >
              {bigLabel ? (
                <BigTimeLineLabel noShow={tl.noShow}>{time}</BigTimeLineLabel>
              ) : null}
            </TimeLine>
          );
        })}
      </TimeBarContainer>
    );
  }
}
export default TimeBar;

import React, { FC } from 'react';
import { Row, Col } from 'antd';

import { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import { patientDisplayName } from 'src/Framework/util/format';
import { formattedDateTime } from 'src/Framework/Shared/Shared';

import ButtonsRow from 'src/Activities/Home/Tasks/ButtonsRow';
import { ITaskItem } from 'src/Activities/Home/Tasks/store/types';

import { Container, Name, Date, Description, Label, Value } from './styled';
import { providerDisplayName } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { getCurrentClinicId } from 'src/Framework/Controls/Selectors/ClinicSelector';

interface IProps {
  value: ITaskItem;
  index: number;
}

const Component: FC<IProps> = ({ value, index }: IProps) => {
  return (
    <Container tabIndex={0}>
      <div className="task-icon-container">
        <div
          className="task-icon"
          style={{
            WebkitMaskImage: `url(${ICONS_LIST.editTask})`,
            maskImage: `url(${ICONS_LIST.editTask})`,
            background: '#666666'
          }}
        />
      </div>
      <Col flex="auto">
        <Row
          align="middle"
          justify="space-between"
          style={{ marginTop: '3px' }}
        >
          <Name>
            <EllipsisText maxWidth={256}>
              {patientDisplayName(value.patient)}
            </EllipsisText>
          </Name>
          <Row align="middle">
            <Date>{formattedDateTime(value.dts)}</Date>
          </Row>
        </Row>
        <Description>{value.comment}</Description>
        <Row gutter={16} align="middle">
          <Col span={16}>
            <Row>
              <Label>Type:</Label>
              <Value>{value.taskType.value}</Value>
              <Label>Status:</Label>
              <Value>{value.status}</Value>
              {value.providerId && (
                <>
                  <Label>Provider:</Label>
                  <Value>{providerDisplayName(value.providerId)}</Value>
                </>
              )}
              {value.fromProviderId && (
                <>
                  <Label>From Provider:</Label>
                  <Value>{providerDisplayName(value.fromProviderId)}</Value>
                </>
              )}
            </Row>
          </Col>
          <Col span={8}>
            <ButtonsRow
              clinicId={getCurrentClinicId()}
              value={value}
              containerStyles={{ justifyContent: 'end' }}
            />
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default React.memo(Component);

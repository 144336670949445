import React, { useImperativeHandle, useState } from 'react';
import _ from 'lodash';

import Modal from 'src/Framework/Controls/Modal';

import { Container } from './styled';
import ManageResults from '../ManageResults';

interface IProps {
  onModalClosed?: (data: IData) => any;
  onOrderUpdated?: () => any;
}

interface IData {
  patientId: number;
  reqOrderId: number;
}

interface IShow {
  value: IData;
}

export interface IModalHandles {
  show(obj: IShow): void;
  close(): void;
}

const modalProps = {
  destroyOnClose: true
};

const title = '';
const defaultWidth = 750;

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { onModalClosed },
  ref
) => {
  const [data, setData] = useState<null | IData>(null);
  const [visible, setVisible] = useState(false);

  const show = async ({ value }: IShow) => {
    setData(value);
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
    if (onModalClosed && data) {
      onModalClosed(data);
    }
  };

  const setToDefault = () => {
    setData(null);
  };

  useImperativeHandle(ref, () => ({
    show: (obj: IShow) => show(obj),
    close
  }));

  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        title,
        hideHeader: true
      }}
      width={defaultWidth}
      title={title}
      visible={visible}
      onClose={close}
      headerBorder={true}
      afterClose={setToDefault}
      modalProps={modalProps}
    >
      <Container>{data && <ManageResults {...data} close={close} />}</Container>
    </Modal>
  );
};

export default React.forwardRef(Component);

import React, { FC } from 'react';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import {
  ActivityPath,
  gotoClient
} from 'src/App/ActivityPanel/Store/ActivityActions';

interface IProps {
  patientId: number | string;
  id: string;
}

const Component: FC<IProps> = ({ patientId, id }: IProps) => {
  return (
    <ButtonIcon
      isButton={true}
      label="Forms"
      id={id}
      changeColorOnHover={true}
      name={ICONS_LIST.document}
      tooltip={'Forms'}
      onClick={() => gotoClient(`${patientId}`, ActivityPath.COUNSELING_FORMS)}
    />
  );
};

export default Component;

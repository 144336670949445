import React, { FC } from 'react';
import { connect } from 'react-redux';

import Table from 'src/Framework/Controls/Table';
import {
  ColumnGlobalTitle,
  ColumnIdentifier,
  IColumn,
  ISort
} from 'src/Framework/Controls/Table/types';
import { getMaskOptions } from 'src/Framework/Controls/SelectContainer/Components/Input';
import Mailto from 'src/Framework/Controls/Mailto';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { formattedDate } from 'src/Framework/Shared/Shared';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { patientDisplayName } from 'src/Framework/util/format';
import store, { IStore } from 'src/store';

import { loadActivityTab } from 'src/App/ActivityPanel/Store/ActivityActions';
import { ActivityType } from 'src/App/ActivityPanel/Models/Activity';
import * as dashboardActions from '../store/Actions';
import { IDashboardPatientDto } from '../store/types';

import { Container } from './styled';

interface IOwnProps {}

interface IProps extends IOwnProps {
  studentsOfConcern: IDashboardPatientDto[];
  providersIds: number[];
  sort: ISort;
  clinicId: number | null;
}

const Component: FC<IProps> = ({
  providersIds,
  studentsOfConcern,
  sort,
  clinicId
}: IProps) => {
  return (
    <Container>
      <Table
        infinity={true}
        absoluteContainer={true}
        sort={sort}
        onChangeSort={(value) =>
          dashboardActions.setSort('studentsOfConcern', value)
        }
        data={studentsOfConcern
          .filter((v) => v.latestNoteOfConcern)
          .map((value) => {
            if (!value.latestNoteOfConcern) return {};
            const patientName = patientDisplayName(value.patient);
            return {
              patientId: value.patient?.id,
              patientName,
              lastName: value.patient.lastName,
              date: value.latestNoteOfConcern?.date
                ? formattedDate(value.latestNoteOfConcern?.date)
                : '',
              dateString: value.latestNoteOfConcern?.date
                ? new Date(value.latestNoteOfConcern?.date).getTime()
                : '',
              patient: <strong>{`${patientName}`}</strong>,
              name: value.latestNoteOfConcern?.name,
              phone: getMaskOptions('phone', value.latestNoteOfConcern.phone1)
                .value,
              email: value.latestNoteOfConcern?.email,
              mailto: <Mailto>{value.latestNoteOfConcern?.email}</Mailto>,
              comment: value.latestNoteOfConcern?.comment,
              edit: (
                <ButtonIcon
                  isButton={true}
                  label="Go to Chart"
                  tooltip="Go to Chart"
                  name={ICONS_LIST.folderLink}
                  onClick={() => {
                    loadActivityTab(
                      `/client/${value.patient.id}/counseling/dashboard`,
                      store.dispatch,
                      {
                        activityType: ActivityType.Client
                      }
                    );
                  }}
                />
              )
            };
          })}
        columns={columns}
      />
    </Container>
  );
};

const mapStateToProps = (state: IStore, ownProps: IOwnProps) => {
  const { riskManagement, sort } = state.dashboard;
  return {
    studentsOfConcern: riskManagement.studentsOfConcern,
    sort: sort.studentsOfConcern,
    providersIds: riskManagement.filters.providersIds,
    clinicId: riskManagement.filters.clinicId
  };
};
export default connect(mapStateToProps)(Component);

const columns: IColumn[] = [
  {
    title: 'Date',
    key: 'date',
    sortBy: 'dateString',
    flex: '120px'
  },
  {
    title: ColumnGlobalTitle.accountNumber,
    key: 'patientId',
    sortBy: 'patientId',
    flex: '80px',
    identifier: ColumnIdentifier.patientId
  },
  {
    title: getPatientTerm('Subject'),
    key: 'patient',
    sortBy: 'lastName',
    flex: 'auto',
    ellipsis: true
  },
  {
    title: 'Reported by',
    key: 'name',
    sortBy: 'name',
    flex: '160px',
    ellipsis: true
  },
  {
    title: 'Phone',
    key: 'phone',
    sortBy: 'phone',
    flex: '200px',
    ellipsis: true
  },
  {
    title: 'Email',
    key: 'mailto',
    sortBy: 'email',
    flex: '120px'
  },
  {
    title: 'Comment',
    key: 'comment',
    flex: '200px',
    ellipsis: true
  },
  {
    title: '',
    key: 'edit',
    flex: '80px',
    centered: true
  }
];

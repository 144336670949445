import React, { FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Tooltip } from 'antd';

import Table from 'src/Framework/Controls/Table';
import {
  ColumnGlobalTitle,
  ColumnIdentifier,
  IColumn,
  ISort
} from 'src/Framework/Controls/Table/types';
import { getReasonByID } from 'src/Framework/Controls/Selectors/ReasonSelector/utils';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { patientDisplayName } from 'src/Framework/util/format';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { formattedDateTime } from 'src/Framework/Shared/Shared';
import store, { IStore } from 'src/store';

import { ActivityType } from 'src/App/ActivityPanel/Models/Activity';
import { loadActivityTab } from 'src/App/ActivityPanel/Store/ActivityActions';
import { isRiskModuleEnabled } from 'src/App/Settings/utils';
import TypeRisks from 'src/Activities/Home/components/TypesRisks';
import * as dashboardActions from '../store/Actions';
import { IData } from '../types';
import { Widget } from '../Widgets/Widget';
import TableauReport from '../TableauReport';
import RiskReport from '../RiskReport';

import { Container } from './styled';

interface IOwnProps {}

interface IProps extends IOwnProps {
  watchList: IStore['dashboard']['riskManagement']['myWatchList'];
  providersIds: number[];
  clinicId: number | null;
  sort: ISort;
}

const Component: FC<IProps> = ({ providersIds, clinicId, watchList, sort }) => {
  const columns = useMemo(() => {
    const columns: (IColumn & { hide?: boolean })[] = [
      {
        title: ColumnGlobalTitle.accountNumber,
        key: 'patientId',
        sortBy: 'patientId',
        flex: '80px',
        identifier: ColumnIdentifier.patientId
      },
      {
        title: getPatientTerm('Subject'),
        key: 'fullName',
        sortBy: 'lastName',
        flex: 'auto',
        ellipsis: true
      },
      {
        title: 'AR',
        key: 'ar',
        sortBy: 'ar',
        flex: '100px',
        centered: true,
        hide: !isRiskModuleEnabled()
      },
      {
        title: 'SI',
        key: 'si',
        sortBy: 'si',
        flex: '100px',
        centered: true,
        hide: !isRiskModuleEnabled()
      },
      {
        title: 'HI',
        key: 'hi',
        sortBy: 'hi',
        flex: '100px',
        centered: true,
        hide: !isRiskModuleEnabled()
      },
      {
        title: 'Last appointment',
        key: 'lastAppt',
        sortBy: 'lastApptString',
        flex: '180px'
      },
      {
        title: '',
        key: 'edit',
        flex: '80px',
        centered: true
      }
    ];
    return columns.filter((v) => (v.hide === true ? false : true));
  }, []);
  const gridData: IData = [
    {
      height: '100%',
      columns: [
        {
          span: 14,
          type: 'fixed_widget',
          props: {
            content: (
              <Table
                containerStyleProps={{ height: '100%' }}
                infinity={true}
                absoluteContainer={true}
                sort={sort}
                onChangeSort={(value: ISort) =>
                  dashboardActions.setSort('myWatchList', value)
                }
                data={watchList.map((value) => {
                  const reason = value.latestProviderAppointment
                    ?.appointmentReasonId
                    ? getReasonByID(
                        value.latestProviderAppointment?.appointmentReasonId
                      )
                    : null;
                  const patientName = patientDisplayName(value.patient);
                  return {
                    patientId: value.patient?.id,
                    patientName,
                    lastName: value.patient.lastName,
                    fullName: (
                      <strong style={{ marginRight: '5px' }}>
                        {patientName}
                      </strong>
                    ),
                    ar: (
                      <TypeRisks data={value.typeRiskIds} riskType="academic" />
                    ),
                    si: (
                      <TypeRisks data={value.typeRiskIds} riskType="suicide" />
                    ),
                    hi: (
                      <TypeRisks data={value.typeRiskIds} riskType="homicide" />
                    ),
                    lastAppt: (
                      <Tooltip title={reason?.name || ''} trigger="hover">
                        <div>
                          {value.latestProviderAppointment?.admitTime &&
                            formattedDateTime(
                              value.latestProviderAppointment.admitTime
                            )}
                        </div>
                      </Tooltip>
                    ),
                    lastApptString: value.latestProviderAppointment?.admitTime
                      ? new Date(
                          value.latestProviderAppointment?.admitTime
                        ).getTime()
                      : '',
                    edit: (
                      <ButtonIcon
                        isButton={true}
                        label="Go to Chart"
                        tooltip="Go to Chart"
                        name={ICONS_LIST.folderLink}
                        onClick={() => {
                          loadActivityTab(
                            `/client/${value.patient.id}/counseling/dashboard`,
                            store.dispatch,
                            {
                              activityType: ActivityType.Client
                            }
                          );
                        }}
                      />
                    )
                  };
                })}
                columns={columns}
              />
            )
          }
        },
        {
          span: 10,
          type: 'fixed_widget',
          props: {
            content: (
              <div
                style={{
                  height: '100%',
                  maxHeight: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ minHeight: 100, maxHeight: 400, flexGrow: 1 }}>
                  <TableauReport
                    reportName={'RisksManagementEngagement'}
                    filters={{
                      clinicId: clinicId,
                      providerIds: providersIds
                    }}
                  />
                </div>
                <div
                  style={{
                    maxHeight: 400,
                    flex: 1
                  }}
                >
                  <RiskReport data={watchList} />
                </div>
              </div>
            )
          }
        }
      ]
    }
  ];

  return (
    <Container>
      {gridData.map((value) => (
        <Row
          style={{ height: value.height }}
          tabIndex={0}
          onKeyDown={onKeyDownAccessibility}
        >
          {value.columns.map((val) => (
            <Col
              tabIndex={0}
              span={val.span}
              style={{
                overflowX: 'auto',
                height: '100%'
              }}
            >
              <Widget key={val.type} type={val.type} {...val.props} />
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  );
};

const mapStateToProps = (state: IStore) => {
  const { riskManagement, sort } = state.dashboard;

  return {
    watchList: riskManagement.myWatchList,
    providersIds: riskManagement.filters.providersIds,
    clinicId: riskManagement.filters.clinicId,
    sort: sort.myWatchList
  };
};
export default connect(mapStateToProps)(Component);

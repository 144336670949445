import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

export const postMessages = async (body: any[]) => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            ApiUrl.POST_MESSAGE,
            body,
            {
                withCredentials: true,
                interceptError: true,
            }
        );
        if (response.data.success) {
            const result = response.data.result.summary
            return result;
        }
    } catch (e) {
        console.log({ e });
    } finally {
    }
};
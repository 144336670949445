import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { FixedSizeList as List, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import TimeBar from './TimeBar/TimeBar';
import ScheduleCol from './ScheduleCol';
import { generateEmptySchedulesFromDayStartEndTime } from 'src/Activities/Schedule/Utils/renderer';

import {
  MainContainer,
  ScheduleContentLayout,
  TimerAbsoluteContainer,
  MainRelativeContainer
} from './Main_Styles';
import { Box, ColumnBox } from 'src/CommonStyles/styles';
import CurrentTimeLine from './CurrentTimeLine';
import { useAppSelector } from 'src/store';

interface ICommom {
  getParsedWaitingGroupPatients: () => any[];
  updateWaitingList: (value: any) => any;
  filteredSlices?: any;
}

interface IColItemData extends ICommom {
  outerRef: React.RefObject<HTMLDivElement>;
}

interface IProps extends ICommom {
  widget?: boolean;
  setMainSchedulerBody: (ref: any) => void;
  onMainBodyScroll: (e: any) => void;
}

export const mainContainerField = 'main-layout-container';

interface IProps {}

const mainListClassName = 'virtualized-main-list';

const ColItem = React.memo((props: any) => {
  const style = props.style;
  const index = props.index;
  const {
    filteredSlices,
    updateWaitingList,
    getParsedWaitingGroupPatients,
    outerRef
  } = props.data as IColItemData;
  const slice = (filteredSlices || [])[index];
  return (
    <div style={style}>
      <ScheduleCol
        updateWaitingList={updateWaitingList}
        sliceId={slice.id}
        getParsedWaitingGroupPatients={getParsedWaitingGroupPatients}
        mainScrollContainerRef={outerRef}
        // providerId={key}
      />
    </div>
  );
}, areEqual);

const Component: FC<IProps> = (props: IProps) => {
  const outerRef = useRef<HTMLDivElement>(null);
  const timerContainerRef = useRef<HTMLDivElement>(null);
  const scheduleTimePropsMain = useAppSelector(
    (state) => state.scheduleDetails.filters.scheduleTimeProps
  );
  const currentDateRange = useAppSelector(
    (state) => state.scheduleDetails.filters.currentDateRange
  );
  const {
    setMainSchedulerBody,
    onMainBodyScroll,
    getParsedWaitingGroupPatients,
    filteredSlices,
    widget,
    updateWaitingList
  } = props;

  useEffect(() => {
    let element: HTMLDivElement | null = null;
    const callback = (e: any) => {
      onMainBodyScroll(e);
      if (timerContainerRef.current) {
        timerContainerRef.current.scrollTop = e.target.scrollTop;
      }
    };
    const findElement = () => {
      element = outerRef.current;
      if (element) {
        setMainSchedulerBody(element);
        element.addEventListener('scroll', callback);
      } else {
        setTimeout(() => {
          findElement();
        }, 300);
      }
    };
    findElement();
    return () => {
      element?.removeEventListener('scroll', callback);
    };
  }, []);

  const onKeyDown = useCallback((e: any) => {
    // need for a11y shortkeys
    if (!e.ctrlKey) {
      e.stopPropagation();
    }
  }, []);

  const { timeBarSchedules, scheduleTimeProps } = useMemo(() => {
    const { dayStartTime, dayEndTime } = scheduleTimePropsMain;
    let regularMeetingTime = scheduleTimePropsMain.regularMeetingTime;
    if (isNaN(regularMeetingTime)) {
      regularMeetingTime = 15;
    }

    const timeBarSchedules =
      dayEndTime && dayEndTime && regularMeetingTime
        ? generateEmptySchedulesFromDayStartEndTime(
            dayStartTime,
            dayEndTime,
            regularMeetingTime
          )
        : [];
    return {
      timeBarSchedules,
      scheduleTimeProps: {
        ...scheduleTimePropsMain,
        regularMeetingTime
      }
    };
  }, [scheduleTimePropsMain]);

  const itemData = useMemo(() => {
    return {
      getParsedWaitingGroupPatients,
      updateWaitingList,
      filteredSlices,
      outerRef
    };
  }, [getParsedWaitingGroupPatients, updateWaitingList, filteredSlices]);

  return (
    <MainContainer id={mainContainerField} onKeyDown={onKeyDown}>
      <TimerAbsoluteContainer ref={timerContainerRef}>
        <CurrentTimeLine
          currentDateRange={currentDateRange}
          scheduleTimeProps={scheduleTimeProps}
        />
        <TimeBar
          widget={widget}
          regularMeetingTime={scheduleTimeProps.regularMeetingTime}
          schedules={timeBarSchedules}
          scheduleTimeProps={scheduleTimeProps}
        />
      </TimerAbsoluteContainer>
      <MainRelativeContainer>
        <ScheduleContentLayout
          className="schedule-content-layout"
          columns={filteredSlices ? filteredSlices.length : 0}
          rows={timeBarSchedules.length}
        >
          <Box
            className="schedule-content-box"
            style={{ alignItems: 'unset', flex: 1 }}
          >
            <ColumnBox
              className={cn({
                'all-providers-view': !widget,
                'widget-all-providers-view': widget
              })}
            >
              <Box className="all-providers-container">
                <AutoSizer>
                  {({ height, width }: any) => (
                    <List<typeof itemData>
                      className={mainListClassName}
                      height={height}
                      outerRef={outerRef}
                      itemCount={filteredSlices.length}
                      itemSize={200}
                      width={width}
                      direction="horizontal"
                      itemData={itemData}
                    >
                      {ColItem}
                    </List>
                  )}
                </AutoSizer>
              </Box>
            </ColumnBox>
          </Box>
        </ScheduleContentLayout>
      </MainRelativeContainer>
    </MainContainer>
  );
};

export default React.memo(Component);

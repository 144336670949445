import globalStyleVariables from "src/Framework/Styles/variables.module.scss"
import { ITicketDetailHipaa, TicketDetailHippaAction } from "../store/types"
import { isHippoExpired } from "../store/utils"
// import store from "src/store"
import { formattedDate } from "src/Framework/Shared/Shared"

export const HippaStatusColors: Record<TicketDetailHippaAction, string> = {
    0: '#666666',
    1: '#666666',
    2: globalStyleVariables.blue,
    3: globalStyleVariables.red
}

export const HippaExpireColor = globalStyleVariables.red

export const getHipaaColor = (data: ITicketDetailHipaa, clinicId: number) => {
    const expired = isHippoExpired(clinicId, data?.dosFrom);
    return expired
        ? HippaExpireColor
        : HippaStatusColors[data.action]
}

export const isPrivacyPolicyEnabled = () => false // store.getState().user.preferences.hIPAAAlert?.value === "True"

export const generateDefaultHipaaData = (patientId: ITicketDetailHipaa['patientId'], renderingProviderId: ITicketDetailHipaa['renderingProviderId']): ITicketDetailHipaa => {
    const id = new Date().getTime()
    return {
        id,
        patientId,
        renderingProviderId,
        dosFrom: formattedDate(new Date().toString()),
        action: 2,
    }
}
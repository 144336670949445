import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  overflow-y: auto;
  height: calc(100vh - 58px) !important;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #e6e6e6;
  background: #ffffff;
  margin: 16px 16px 0 16px;
`;

export const TableContainer = styled.div`
  display: flex;
  height: 170px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
`;

export const SyncContainer = styled.div`
  padding: 0 20px 20px 20px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 15px 0 0 0;
`;

export const SwitchLabel = styled.div`
  margin: 0 0 0 10px;
  font-size: 12px;
  font-family: Lato;
`;

export const Label = styled.div`
  font-size: 14px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0;
  font-family: Encode Sans Expanded;
  font-style: normal;
  color: #333333;
`;

export const ContainerV2 = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  flex: 1;
`;

export const PanelContainer = styled.div`
  padding: 0 20px;
`;

export const Header = styled.div`
  margin: 20px;

  .input-with-label {
    display: flex;
    align-items: center;
    gap: 12px;

    label {
      color: ${globalStyleVariables.middleGrey};
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${globalStyleVariables.borderColor};
  margin: 20px 0;
`;

export const NoDataContainer: any = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import React, { useEffect, useMemo, useState } from 'react';
import {
  M1RecordRequests,
  patch
} from 'src/App/M1FormRecords/store/Actions/index';
import Header from 'src/Framework/Controls/Modal/Header';
import { IM1FormRecord } from 'src/App/M1FormRecords/store/types';

import M1Form from 'src/Activities/Forms/M1Form';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { IGetFormValidation } from 'src/App/FormBuilder/FormContainer';

import * as Actions from './Actions';
import { useAppSelector } from 'src/store';

interface IProps<
  T extends {
    m1FormRecordId: number | null;
    [k: string]: any;
  }
> {
  data: T;
  patientId: number;
  onRemoveForm: (value: T) => any;
  hideHeader?: boolean;
  closeModal?: () => any;
  getFormValidation?: IGetFormValidation;
  readOnly?: boolean;
  hideInFormHeader?: boolean;
  defaultForm?: IM1FormRecord;
}

const Component = <
  T extends {
    m1FormRecordId: number | null;
    [k: string]: any;
  }
>({
  patientId,
  data,
  onRemoveForm,
  closeModal,
  getFormValidation,
  readOnly,
  hideHeader,
  hideInFormHeader,
  defaultForm
}: IProps<T>) => {
  const m1Forms = useAppSelector((state) => state.m1FormRecords.patients);
  const [form, setForm] = useState<IM1FormRecord | null>(
    defaultForm ? defaultForm : null
  );
  useEffect(() => {
    const init = async () => {
      if (data.m1FormRecordId) {
        const res = await Actions.getForm(data.m1FormRecordId);
        if (res) {
          await M1RecordRequests.get(data.m1FormRecordId);
          setForm(res);
        }
      }
    };
    if (!defaultForm) {
      init();
    }
    return () => {
      if (data.m1FormRecordId) {
        M1RecordRequests.unsubscribe(data.m1FormRecordId);
      }
    };
  }, []);
  const selectedForm = useMemo(() => {
    if (form) {
      return m1Forms[form.subjectPatientId]?.summary[form.id] || null;
    }
    return null;
  }, [m1Forms, form]);
  return (
    <>
      {selectedForm && (
        <>
          {!hideHeader && (
            <Header
              title={selectedForm.formTemplateName}
              buttons={
                <ButtonIcon
                  name={ICONS_LIST.thinClose}
                  onClick={closeModal}
                  disableBackground={true}
                  color="rgba(153, 153, 153, 1)"
                />
              }
            />
          )}

          <M1Form
            hideHeader={hideInFormHeader}
            form={selectedForm}
            readOnly={readOnly}
            subjectId={`${patientId}`}
            afterRemove={() => onRemoveForm(data)}
            m1FormPatchRequest={patch}
            getFormValidation={getFormValidation}
          />
        </>
      )}
    </>
  );
};

const typedMemo: <T extends React.ComponentType<any>>(
  c: T,
  areEqual?: (
    prev: React.ComponentProps<T>,
    next: React.ComponentProps<T>
  ) => boolean
) => T = React.memo;

export default typedMemo(Component);

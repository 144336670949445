import React from "react";

export const ConflictsSvg = () =>
(
<svg xmlns="http://www.w3.org/2000/svg"x="0px" y="0px" viewBox="0 0 20 20" width="20" height="20">
<path d="M10.5,7c0-0.3-0.2-0.5-0.5-0.5C9.7,6.5,9.5,6.7,9.5,7H10.5z M9.5,10.2c0,0.3,0.2,0.5,0.5,0.5
	c0.3,0,0.5-0.2,0.5-0.5H9.5z M10.5,12.4c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5H10.5z M9.5,12.5c0,0.3,0.2,0.5,0.5,0.5
	c0.3,0,0.5-0.2,0.5-0.5H9.5z M9.5,7v3.2h1V7H9.5z M9.5,12.4L9.5,12.4l1,0.1v-0.1H9.5z" fill="#FF2828" />
<circle cx="10" cy="10" r="6.5" fill="none" stroke="#FF2828"/>
</svg>
);
import React, { FC, useRef } from 'react';

import Modal, { IModalHandles } from '../ManageResultsModal';
import Button from './Button';

interface IProps {
  patientId: number;
  reqOrderId: number;
  onModalClosed?: () => any;
}

const Component: FC<IProps> = ({
  patientId,
  reqOrderId,
  onModalClosed
}: IProps) => {
  const ref = useRef<IModalHandles>(null);
  return (
    <div onKeyDown={(e) => e.stopPropagation()}>
      <Modal ref={ref} onModalClosed={onModalClosed} />
      <Button
        onClick={() => {
          ref.current?.show({
            value: {
              patientId,
              reqOrderId
            }
          });
        }}
      />
    </div>
  );
};

export default React.memo(Component);

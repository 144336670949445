import React, { FC } from 'react';
import AsyncImage from 'src/Framework/Controls/AsyncImage';

interface IProps {
  getStateValue: () => any;
}

const Component: FC<IProps> = ({ getStateValue }: IProps) => {
  const value = getStateValue();
  const data = value || {
    defaultDocumentId: null
  };

  if (data.documentId) {
    return (
      <div style={{ margin: '10px 0' }}>
        <AsyncImage
          key={data.crc}
          file={{
            id: data.documentId,
            crc: data.crc
          }}
          width={300}
          height={300}
        />
      </div>
    );
  }
  if (data.defaultDocumentId) {
    return (
      <div style={{ margin: '10px 0' }}>
        <AsyncImage
          key={data.crc}
          file={{
            id: data.defaultDocumentId,
            crc: data.crc
          }}
          width={300}
          height={300}
        />
      </div>
    );
  }
  return null;
};

export default React.memo(Component);

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Container = styled.div``;

export const DropdownContainer = styled.div`
  .TypeMultiselect-slider {
    .ant-collapse-header {
      padding: 5px 10px !important;
      background-color: #f7f7f7;
      border-bottom: 1px solid ${globalStyleVariables.borderColor};
    }

    .ant-collapse-content-box {
      padding-top: 5px;
      padding-bottom: 0px;
    }
  }
`;

export const Header = styled.div`
  padding: 10px;
`;

export const TitleContainer = styled.div`
  padding: 5px 10px;
  background-color: #f7f7f7;
  border-bottom: 1px solid ${globalStyleVariables.borderColor};
`;

export const CheckboxesList = styled.div`
  position: relative;
  max-height: 200px;
  padding: 0 0 0 5px;
  overflow-y: auto;
`;

export const Item = styled.div`
  margin: 0 0 5px 0;
`;

export const Tag = styled.div`
  padding: 2px 4px;
  margin: 0 2px 0 0;
`;

export const CollapseContainer = styled.div`
  max-height: 300px;
  overflow: auto;
`;

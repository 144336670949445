import React, { FC, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
// @ts-ignore
import postscribe from 'postscribe';

import { configData } from 'src/Framework/Communication/ServerProxy';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

import { TableauContainer } from './styled';

import * as tableauActions from './Actions';
import { useAppSelector } from 'src/store';
import { getCurrentTenantId } from 'src/App/UserPreferences/store/Actions';

const tableauSwitchAuth = true;

interface IFilters {
  providerIds: number[];
  startTime: string | null | undefined;
  endTime: string | null | undefined;
  parallelPrevious: string | null | undefined;
  frequency: string | null;
  clinicId: number | null;
}

interface IProps {
  reportName: string | null;
  disableFilters?: string[];
  headerProps?: any;
  url?: string;
  disabledAllFilters?: boolean;
  filters?: Partial<IFilters>;
  cacheFilter?: boolean;
}
interface IListParams {
  Parallel_Previous: string;
  Frequency: string;
  StartDate: string;
  EndDate: string;
  ClinicID: string;
  ProviderID: string;
}

export const LIST_PARAMS: IListParams = {
  Parallel_Previous: 'Parallel_Previous',
  Frequency: 'Frequency',
  StartDate: 'Start Date',
  EndDate: 'End Date',
  ClinicID: 'ClinicID',
  ProviderID: 'ProviderID'
};

const getTableau = () => {
  // @ts-ignore
  const { tableau } = window;
  return tableau;
};

let loadingTableau = false;

const cachedFilter: {
  [reportUrl: string]: {
    [worksheetName: string]: {
      [FilterName: string]: {
        type: string;
        data: any;
      };
    };
  };
} = {};

export enum TableauFilterType {
  quantitative = 'quantitative',
  categorical = 'categorical'
}

const getUrl = async (name: string, customURL?: string) => {
  const tenantId = getCurrentTenantId() || '';
  const tableauHost = configData?.tableauHost;
  const view = customURL
    ? customURL
    : configData?.tableauDashboardsUrls[name]?.url;
  if (!tenantId) return;
  let url = '';
  if (!tableauSwitchAuth) {
    url = `${tableauHost}t/${tenantId}/views/${view}`;
  } else {
    const token = await tableauActions.getTrustedTicket();
    if (token) {
      url = `${tableauHost}trusted/${token}/t/${tenantId}/views${view}`;
    }
  }
  return url ? url : null;
};

const Component: FC<IProps> = (props: IProps) => {
  const dashboardFilters = useAppSelector((state) => {
    const { filters } = state.dashboard;
    const clinicId = +state.user.current.clinicId;
    return {
      provider: filters.provider,
      providerCode: filters.provider?.code,
      startTime: filters.startTime,
      endTime: filters.endTime,
      parallelPrevious: filters.parallelPrevious,
      frequency: filters.frequency,
      caseManagerProviderId: filters.caseManagerProviderId,
      clinicId
    };
  });
  const getFilters = () => {
    if (props.filters) {
      return {
        ...props.filters,
        provider: null,
        providerCode: null,
        caseManagerProviderId: null
      };
    }
    return {
      ...dashboardFilters,
      providerIds: null
    };
  };
  const {
    providerIds,
    provider,
    startTime,
    endTime,
    parallelPrevious,
    frequency,
    caseManagerProviderId,
    clinicId
  } = getFilters();
  const ref = useRef(null);
  const workbook = useRef<any>(null);
  const [init, setInit] = useState(false);
  const [sheet, setSheet] = useState<any>(null);

  const isCaseManager = props.headerProps?.pageType === 'CaseManager';

  let [viz] = useState<any>(undefined);

  const currentProvidersIds = providerIds
    ? providerIds
    : isCaseManager
    ? caseManagerProviderId
    : provider?.providerId;

  function initViz({
    reportUrl,
    reportName
  }: {
    reportUrl: any;
    reportName: string;
  }) {
    const { disableFilters } = props;
    setInit(true);
    if (viz) {
      // If a viz object exists, delete it.
      viz.dispose();
    }
    let worksheets_to_listen_on: any = null;
    let paramsAndFilters: {
      [key: string]: any;
    } = {
      [LIST_PARAMS.StartDate]: startTime,
      [LIST_PARAMS.EndDate]: endTime
    };
    const includeParamsAndFilters = () => {
      if (!disableFilters?.includes(LIST_PARAMS.ClinicID)) {
        paramsAndFilters[LIST_PARAMS.ClinicID] = clinicId;
      }
      if (!disableFilters?.includes(LIST_PARAMS.ProviderID)) {
        paramsAndFilters[LIST_PARAMS.ProviderID] = currentProvidersIds;
      }
      if (!disableFilters?.includes(LIST_PARAMS.Frequency)) {
        paramsAndFilters[LIST_PARAMS.Frequency] = frequency;
      }
      if (!disableFilters?.includes(LIST_PARAMS.Parallel_Previous)) {
        paramsAndFilters[LIST_PARAMS.Parallel_Previous] = parallelPrevious;
      }
    };
    includeParamsAndFilters();
    if (props.disabledAllFilters) {
      paramsAndFilters = {};
    }
    if (props.cacheFilter) {
      // const reportCache = cachedFilter[reportName];
      // if (reportCache) {
      //   Object.entries(reportCache).forEach(([worksheetName, filters]) => {
      //     Object.entries(filters).forEach(([filterName, values]) => {
      //       if (values.type === 'categorical') {
      //         paramsAndFilters[filterName] = values.data.appliedValues
      //           .map((v: any) => v.value)
      //           .join(',');
      //       }
      //     });
      //   });
      // }
    }
    Object.keys(paramsAndFilters).forEach((key) => {
      if (paramsAndFilters[key] === undefined) {
        delete paramsAndFilters[key];
      }
    });
    // const tabswitch = async () => {
    //   const workbook = viz.getWorkbook();
    //   const activeSheet = workbook.getActiveSheet();
    //   const currentName = activeSheet?._impl?.$5;
    //   const workSheetdata = cachedFilter[reportName]?.[currentName];
    //   if (workSheetdata) {
    //     const filters = await activeSheet.getFiltersAsync();
    //     filters.forEach((filter: any) => {
    //       const fieldName = filter.$4;
    //       const values = workSheetdata[fieldName];
    //       if (values) {
    //         const data = values.data;
    //         filter._updateFromJson(data);
    //       }
    //     });
    //   }
    // };
    const options = {
      onFirstInteractive: async function () {
        const workbookInit = viz.getWorkbook();
        const activeSheet = workbookInit.getActiveSheet();
        worksheets_to_listen_on = activeSheet;
        workbook.current = workbookInit;
        setSheet(worksheets_to_listen_on);
        dateRangeFilter();
      },
      width: '100%',
      height: '100%',
      ...paramsAndFilters,
      device: 'desktop',
      hideToolbar: false,
      hideTabs: false
    };
    // @ts-ignore
    const tableau = getTableau();
    viz = new tableau.Viz(ref.current, reportUrl, options);
    function handleMarksSelection(m: any) {}

    function getMarks(e: any) {
      e.getMarksAsync().then(handleMarksSelection);
    }

    const filterchange = async (e: any) => {
      const workSheet = e.getWorksheet();
      const workbook = workSheet.getWorkbook();
      const activeSheet = workbook.getActiveSheet();
      const activeSheetName = activeSheet.getName();
      const FieldName = e.getFieldName();
      const v = await e.getFilterAsync();
      if (props.cacheFilter) {
        const filterType = v.$7;
        const fieldsList = [
          'appliedValues',
          'isAllSelected',
          'isExcludeMode',
          'domainMax',
          'domainMin',
          'includeNullValues',
          'max',
          'min'
        ];
        const data = {};
        fieldsList.forEach((field) => {
          const method = `get${_.upperFirst(field)}`;
          if (v[method]) {
            data[field] = v[method]();
          }
        });
        cachedFilter[reportName] = {
          ...cachedFilter[reportName],
          [activeSheetName]: {
            ...cachedFilter[reportName]?.[activeSheetName],
            [FieldName]: {
              type: filterType,
              data: data
            }
          }
        };
      }
    };

    viz.addEventListener('marksselection', getMarks);
    viz.addEventListener('filterchange', filterchange);
  }

  const changeFilter = (field: string, value: any) => {
    if (sheet) {
      if (sheet.getSheetType() === 'worksheet') {
        sheet.applyFilterAsync(
          field,
          value,
          getTableau().FilterUpdateType.REPLACE
        );
      } else {
        const worksheetArray = sheet.getWorksheets();
        for (var i = 0; i < worksheetArray.length; i++) {
          worksheetArray[i].applyFilterAsync(
            field,
            value,
            getTableau().FilterUpdateType.REPLACE
          );
        }
      }
    }
  };

  const changeParameter = (field: string, value: any) => {
    if (value) {
      workbook.current?.changeParameterValueAsync(field, value);
    }
  };

  function providerFilter(providerIds: any) {
    changeFilter(LIST_PARAMS.ClinicID, `${clinicId}`);
    changeFilter(LIST_PARAMS.ProviderID, providerIds);
  }

  function dateRangeFilter() {
    changeParameter(LIST_PARAMS.StartDate, startTime);
    changeParameter(LIST_PARAMS.EndDate, endTime);
  }

  function clinicFilter() {
    changeFilter(LIST_PARAMS.ClinicID, `${clinicId}`);
    changeFilter(LIST_PARAMS.ProviderID, providerIds);
  }

  function parallelPreviousFilter(value: string, sheet: any) {
    changeParameter(LIST_PARAMS.Parallel_Previous, value);
  }

  function frequencyFilter(value: string, sheet: any) {
    changeParameter(LIST_PARAMS.Frequency, value);
  }

  useEffect(() => {
    const initMethod = async () => {
      if (props.url) {
        const url = await getUrl(props.url, props.url);
        return initViz({ reportUrl: url, reportName: props.url });
      }
      if (
        props.reportName !== null &&
        viz === undefined &&
        !init &&
        currentProvidersIds
      ) {
        const url = await getUrl(props.reportName);
        if (url !== null) {
          initViz({ reportUrl: url, reportName: props.reportName });
        }
      }
    };

    const tableauGetter = async () => {
      if (!getTableau()?.Viz) {
        if (!loadingTableau) {
          loadingTableau = true;
          const url = configData?.tableauJSFile;
          if (!url) return;
          postscribe(
            '#root',
            `<script language="javascript" src="${url}"></script>`,
            {
              done: () => {
                initMethod();
              }
            }
          );
        } else {
          const checker = () => {
            setTimeout(() => {
              if (getTableau()?.Viz) {
                initMethod();
              } else {
                checker();
              }
            }, 150);
          };
          checker();
        }
      } else {
        initMethod();
      }
    };
    tableauGetter();
  }, [props.reportName, currentProvidersIds]);

  useEffect(() => {
    if (props.disabledAllFilters) return;
    const { disableFilters } = props;
    if (!disableFilters?.includes(LIST_PARAMS.ProviderID)) {
      providerFilter(currentProvidersIds);
    }
  }, [currentProvidersIds]);

  useEffect(() => {
    if (props.disabledAllFilters) return;
    dateRangeFilter();
  }, [startTime, endTime]);

  useEffect(() => {
    if (props.disabledAllFilters) return;
    const { disableFilters } = props;
    if (
      !disableFilters?.includes(LIST_PARAMS.Parallel_Previous) &&
      parallelPrevious
    ) {
      parallelPreviousFilter(parallelPrevious, sheet);
    }
  }, [parallelPrevious]);

  useEffect(() => {
    if (props.disabledAllFilters) return;
    const { disableFilters } = props;
    if (!disableFilters?.includes(LIST_PARAMS.Frequency) && frequency) {
      frequencyFilter(frequency, sheet);
    }
  }, [frequency]);

  useEffect(() => {
    if (props.disabledAllFilters) return;
    clinicFilter();
  }, [clinicId]);
  return (
    <TableauContainer ref={ref} tabIndex={-1}>
      {!init && <AbsoluteLoader backgroundColor="transparent" />}
    </TableauContainer>
  );
};

export default React.memo(Component);

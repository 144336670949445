import styled from 'styled-components';
import { DefaultItemHeight } from '../Main_Styles';

export const TimeBarContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 25px 0 0 0;
  position: relative;
  user-select: none;
`;

interface TimeLineProps {
  isShown: string;
  noShow: any;
  topBorderShow: any;
  bottomBorderShow: any;
  plShow: any;
  slotHeight: number;
}

export const TimeLine = styled.div`
  margin-left: 10px;
  border-top: solid #e6e6e6 1px;
  height: ${({ slotHeight }: TimeLineProps) =>
    `${slotHeight ? `${DefaultItemHeight() * slotHeight}px` : '24px'}`};
  position: relative;
  margin-left: ${(props: TimeLineProps) =>
    `${props.noShow ? '133px' : '10px'}`};
  border-left: ${(props: TimeLineProps) =>
    `${props.noShow ? '2px dashed #333333' : 'none'}`};
  border-right: ${(props: TimeLineProps) =>
    `${props.noShow ? '2px dashed #333333' : 'none'}`};
  border-top: ${(props: TimeLineProps) =>
    `${props.topBorderShow ? '2px dashed #333333' : 'solid #E6E6E6 1px'}`};
  border-bottom: ${(props: TimeLineProps) =>
    `${props.bottomBorderShow ? '2px dashed #333333' : 'none'}`};
  background: ${(props: TimeLineProps) =>
    `${props.noShow ? 'rgba(51, 51, 51, 0.2)' : 'none'}`};
`;

export const BigTimeLineLabel = styled.div`
  position: absolute;
  left: ${(props: { widget?: boolean }) => `${props.widget ? '5px' : '18px'}`};
  top: -12px;
  padding: 0 13px 0 11px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 12px;
  color: #333333;
  background-color: #fff;
`;

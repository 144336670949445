import React, { useImperativeHandle, useState, useRef, useMemo } from 'react';
import { Row, Col } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import Icon from 'src/Framework/Controls/Icon';
import Modal from 'src/Framework/Controls/Modal';
import Button from 'src/Framework/Controls/Button';
import Radio from 'src/Framework/Controls/Radio';
import Checkbox from 'src/Framework/Controls/Checkbox';
import AlertPopUp, {
  IModalHandles as IAlertHandles
} from 'src/Framework/Controls/AlertPopUp';
import UploaderAttachments from 'src/Framework/Controls/UploaderAttachments';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import ClinicSelector, {
  getClinicById
} from 'src/Framework/Controls/Selectors/ClinicSelector';
import ProviderSelector from 'src/Framework/Controls/Selectors/ProviderSelector';
import ReasonSelector from 'src/Framework/Controls/Selectors/ReasonSelector';
import { uploadMultipartFiles } from 'src/Framework/Controls/FileUploader';
import ColorSelector from 'src/Framework/Controls/Selectors/ColorSelector';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { differObjectsDeep } from 'src/Framework/Shared/Shared';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { separatedTimeRange } from 'src/Framework/util/helps';
import { useAppSelector } from 'src/store';
import ids from 'src/tests-script/pages/Activity/Outreach';

import { permissionChecking } from 'src/App/User/Permission';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';
import { IOutreachSimpleDto, IRegistrant } from '../store/types';
import * as outreachActions from '../store/Actions';

import Registrants from './Registrants';
import DefineGuest from './DefineGuest';

import {
  Container,
  Block,
  BlockAbsolute,
  Divider,
  SubTitle,
  BlockFit,
  ModalSubTitle
} from './styled';

interface IProps {}

type IShow = {
  calendar?: {
    date: string;
    startTime: string;
    endTime: string;
    clinicId: number;
  };
  id?: number;
  value?: Omit<IOutreachSimpleDto, 'id'> & {
    id?: number;
  };
};

export interface IModalHandles {
  show(obj: IShow): void;

  close(): void;
}

type Pages = 'main' | 'registrants' | 'define';

type IData = Omit<IOutreachSimpleDto, 'id'> | IOutreachSimpleDto;

const defaultData: IData = {
  title: '',
  audience: '',
  location: '',
  hostingClinicsIds: [],
  presentingProviderIds: [],
  isActive: true,
  isPatientPortalRegistration: true,
  startDateTime: '',
  endDateTime: '',
  expectedAttendance: 0,
  actualAttendance: 0,
  preEventDescription: '',
  preEventFileIds: [],
  postEventDescription: '',
  postEventFileIds: [],
  internalDescription: '',
  color: '',
  registrationType: 'anonymous',
  maxParticipant: 1,
  postEventFiles: [],
  preEventFiles: [],
  isEnable: true,
  guestListFilter: '',
  appointmentReasonId: null
};

const setDateTimeFormat = (date: string, time: string) => {
  return `${date}T${time}:00`;
};

const dateFormat = (date: string, time: string) => {
  const timeFormatted = moment(time).format('HH:mm');
  const dateFormatted = moment(date).format('YYYY-MM-DD');
  return setDateTimeFormat(dateFormatted, timeFormatted);
};

const timeToDateTime = (time: string) => {
  const date = moment();
  const timeStr = moment(time, 'HH:mm');
  date.set({
    hour: timeStr.get('hour'),
    minute: timeStr.get('minute')
  });
  return date.toString();
};

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  {},
  ref
) => {
  const appointmentTypes = useAppSelector(
    (state) => state.adminPanel.appointmentTypes
  );
  const AlertRef = useRef<IAlertHandles>(null);
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState<IData>(_.cloneDeep(defaultData));
  const [prevData, setPrevData] = useState<IOutreachSimpleDto | null>(null);
  const [page, setPage] = useState<Pages>('main');
  const [visible, setVisible] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [postEventFiles, setPostEventFiles] = useState<File[]>([]);
  const [preEventFiles, setPreEventFiles] = useState<File[]>([]);
  const [defineGuestListNumber, setDefineGuestListNumber] = useState(0);
  const [guestNumberLoading, setGuestNumberLoading] = useState(false);
  const [registrants, setRegistrants] = useState<Record<string, IRegistrant>>(
    {}
  );
  const [registrantsLoading, setRegistrantsLoading] = useState(false);
  useModalFocus({ active: visible });
  const clinics = useAppSelector((state) => state.adminPanel.clinics.summary);
  const reasonDuration = useMemo(() => {
    if (!data.appointmentReasonId) {
      return 0;
    } else {
      return +(
        appointmentTypes?.summary[data.appointmentReasonId].duration || 0
      );
    }
  }, [data.appointmentReasonId]);
  const getGuestsNumber = async (value: IData) => {
    if (value.guestListFilter) {
      try {
        const filter = JSON.parse(value.guestListFilter);
        setGuestNumberLoading(true);
        const total = await outreachActions.getDefineGuestList(filter);
        if (total !== undefined) {
          setDefineGuestListNumber(total);
        }
      } catch (e) {
        console.log({ e });
      } finally {
        setGuestNumberLoading(false);
      }
    }
  };

  const getRegistrantsList = async (outreachId: number) => {
    setRegistrantsLoading(true);
    const res = await outreachActions.getRegistrants(outreachId);
    if (res) {
      setRegistrants(res);
    }
    setRegistrantsLoading(false);
  };
  const show = async ({ value, calendar, id }: IShow) => {
    const setValues = (data: IShow['value']) => {
      if (data) {
        setData(_.cloneDeep(data));
        getGuestsNumber(data);
        if (data.id) {
          setPrevData(
            _.cloneDeep({
              ...data,
              id: data.id
            })
          );
          getRegistrantsList(data.id);
        }
      }
    };
    if (id) {
      setVisible(true);
      setLoadingData(true);
      const res = await outreachActions.getById(id);
      if (res) {
        const item = Object.values(res)[0];
        if (item) {
          setValues(item);
        }
      }
      setLoadingData(false);
      return;
    }
    if (calendar) {
      onChangeData({
        startDateTime: dateFormat(
          calendar.date,
          timeToDateTime(calendar.startTime)
        ),
        endDateTime: dateFormat(
          calendar.date,
          timeToDateTime(calendar.endTime)
        ),
        hostingClinicsIds: [calendar.clinicId]
      });
      setVisible(true);
      return;
    }
    if (value) {
      setValues(value);
    } else {
      const endDateTime = new Date();
      const hours = endDateTime.getHours() + 2;
      endDateTime.setHours(hours);
      endDateTime.setMinutes(0);
      const endDate = endDateTime.toISOString();

      const startDateTime = new Date();
      const hoursStart = startDateTime.getHours() + 1;
      startDateTime.setHours(hoursStart);
      startDateTime.setMinutes(0);
      const startDate = startDateTime.toISOString();
      onChangeData({
        startDateTime: dateFormat(startDate, startDate),
        endDateTime: dateFormat(endDate, endDate)
      });
    }
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setPage('main');
    setData(_.cloneDeep(defaultData));
    setPrevData(null);
    setDefineGuestListNumber(0);
    setRegistrants({});
    setPostEventFiles([]);
    setPreEventFiles([]);
    setLoadingData(false);
  };

  useImperativeHandle(ref, () => ({
    show: (obj: IShow) => show(obj),
    close
  }));

  const apply = async () => {
    const onSave = async () => {
      setSaveLoading(true);
      const postEventFileIds = await uploadMultipartFiles(postEventFiles);
      const preEventFileIds = await uploadMultipartFiles(preEventFiles);
      if (prevData) {
        const obj = differObjectsDeep(
          {
            ...data,
            postEventFileIds: [
              ...(data.postEventFiles || []).map((v) => v.id),
              ...postEventFileIds.map((v) => v.id)
            ],
            preEventFileIds: [
              ...(data.preEventFiles || []).map((v) => v.id),
              ...preEventFileIds.map((v) => v.id)
            ]
          },
          prevData
        );
        const { preEventFiles, postEventFiles, ...otherData } = obj;
        const res = await outreachActions.patch({
          ...otherData,
          id: prevData.id
        });
        if (res) {
          close();
        }
      } else {
        const res = await outreachActions.post({
          ...data,
          postEventFileIds: [
            ...(data.postEventFiles || []).map((v) => v.id),
            ...postEventFileIds.map((v) => v.id)
          ],
          preEventFileIds: [
            ...(data.preEventFiles || []).map((v) => v.id),
            ...preEventFileIds.map((v) => v.id)
          ]
        });
        if (res) {
          close();
        }
      }
      setSaveLoading(false);
    };
    const notInRangeClinics = data.hostingClinicsIds.filter((id) => {
      const clinic = getClinicById(id);
      if (clinic) {
        const startTimeOutreach = moment(data.startDateTime).format('HH:mm');
        const startTimeClinic = moment(clinic.startTime).format('HH:mm');
        const compareStartTime = moment(
          startTimeOutreach,
          'HH:mm'
        ).isSameOrAfter(moment(startTimeClinic, 'HH:mm'));
        const endTimeOutreach = moment(data.endDateTime).format('HH:mm');
        const endTimeClinic = moment(clinic.endTime).format('HH:mm');
        const compareEndTime = moment(endTimeOutreach, 'HH:mm').isSameOrBefore(
          moment(endTimeClinic, 'HH:mm')
        );
        if (!compareStartTime || !compareEndTime) {
          return true;
        }
        return false;
      }
      return false;
    });
    if (notInRangeClinics.length !== 0) {
      AlertRef.current?.show({
        content: (
          <span>
            This outreach event starts outside of the working hours for
            clinic(s):
            <br />
            {notInRangeClinics
              .map((v) => getClinicById(v)?.name || '')
              .join(', ')}
            <br />
            This event wont be shown on the calendar for this clinic, do you
            want to continue?
          </span>
        ),
        closeAfterConfirm: true,
        onConfirm: () => {
          onSave();
        }
      });
    } else {
      onSave();
    }
  };

  const onChangeData = (values: Partial<IOutreachSimpleDto>) => {
    setData((data) => ({
      ...data,
      ...values
    }));
  };

  const getTitle = () => {
    switch (page) {
      case 'main': {
        return prevData ? `Edit Outreach Event` : `Set up Outreach Event`;
      }
      case 'registrants': {
        return (
          <Row align="middle" gutter={16}>
            <Col>
              <b>Registrants</b>
            </Col>
            <Col
              style={{
                borderLeft: `1px solid rgba(0,0,0,0.1)`
              }}
            >
              <ModalSubTitle>
                for <b>{prevData?.title}</b>
              </ModalSubTitle>
            </Col>
          </Row>
        );
      }
      case 'define': {
        return (
          <Row align="middle" gutter={16}>
            <Col>
              <b>Define Guest List</b>
            </Col>
            <Col
              style={{
                borderLeft: `1px solid rgba(0,0,0,0.1)`
              }}
            >
              <ModalSubTitle>
                for <b>{prevData?.title}</b>
              </ModalSubTitle>
            </Col>
          </Row>
        );
      }
    }
    return null;
  };

  const checkDisabled = (): boolean => {
    if (
      !data.title ||
      !data.audience ||
      !data.location ||
      !data.hostingClinicsIds ||
      data.hostingClinicsIds.length === 0 ||
      !data.presentingProviderIds ||
      data.presentingProviderIds.length === 0 ||
      !data.startDateTime ||
      !data.endDateTime
    ) {
      return true;
    }
    return false;
  };

  const registrantsList = Object.values(registrants);
  const currentClinicId = data.hostingClinicsIds[0];
  const currentClinic = clinics[currentClinicId]
    ? clinics[currentClinicId]
    : null;
  const options = currentClinic
    ? separatedTimeRange(currentClinic.visitTimeDuration || 60).filter((v) => {
        if (currentClinic.startTime && currentClinic.endTime) {
          if (
            moment(currentClinic.startTime).format('HH:mm') ===
            moment(currentClinic.endTime).format('HH:mm')
          ) {
            return true;
          }
          const dateStart = dateFormat(
            currentClinic.startTime,
            timeToDateTime(v.value)
          );
          const dateEnd = dateFormat(
            currentClinic.endTime,
            timeToDateTime(v.value)
          );
          return (
            moment(currentClinic.startTime) <= moment(dateStart) &&
            moment(currentClinic.endTime) >= moment(dateEnd)
          );
        }
        return false;
      })
    : [];
  const timeOptions = options.slice(0, -1);
  const endTimeOptions = options.filter((v) => {
    const date = dateFormat(data.startDateTime, timeToDateTime(v.value));
    return moment(data.startDateTime) < moment(date);
  });

  const defaultWidth = page === 'main' ? 800 : 950;
  const title = getTitle();
  const headerRight =
    page === 'main' ? (
      <Button
        disabled={checkDisabled()}
        loading={saveLoading}
        id="save-button"
        onClick={apply}
        shape="round"
      >
        Save
      </Button>
    ) : null;

  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        headerRight,
        title
      }}
      id={ids.editOutreachModal}
      width={defaultWidth}
      title={title}
      visible={visible}
      headerBorder={true}
      onClose={close}
      afterClose={setToDefault}
      modalProps={{
        destroyOnClose: true
      }}
      isDragable={true}
      back={page !== 'main'}
      onClickBack={() => setPage('main')}
      buttons={headerRight}
    >
      <AlertPopUp ref={AlertRef} />
      <Container>
        {loadingData ? (
          <AbsoluteLoader />
        ) : (
          <>
            {page === 'define' && (
              <DefineGuest
                defaultGuestListNumber={defineGuestListNumber}
                defaultFilter={data.guestListFilter}
                onSave={(filter, count) => {
                  onChangeData({
                    guestListFilter: JSON.stringify(filter)
                  });
                  setDefineGuestListNumber(count);
                }}
                onClickBack={() => setPage('main')}
              />
            )}
            {page === 'registrants' && prevData && (
              <Registrants
                outreach={prevData}
                onClickBack={() => setPage('main')}
                registrants={registrants}
                setRegistrants={setRegistrants}
              />
            )}
            {page === 'main' && (
              <BlockAbsolute style={{ paddingBottom: 0 }}>
                <BlockFit>
                  <Block rightBorder={true} id="elements-container">
                    <SelectContainer
                      type="input"
                      label="Title"
                      value={data.title}
                      onChange={(title: string) => {
                        onChangeData({
                          title
                        });
                      }}
                      inputProps={{
                        maxLength: 256
                      }}
                    />
                    <SelectContainer
                      type="input"
                      label="Audience"
                      value={data.audience}
                      onChange={(audience: string) => {
                        onChangeData({
                          audience
                        });
                      }}
                      inputProps={{
                        maxLength: 512
                      }}
                    />
                    <SelectContainer
                      type="input"
                      label="Location"
                      value={data.location}
                      onChange={(location: string) => {
                        onChangeData({
                          location
                        });
                      }}
                      inputProps={{
                        maxLength: 512
                      }}
                    />
                    <ClinicSelector
                      multiple={false}
                      selectProps={{
                        label: 'Hosting Clinic',
                        star: true,
                        getPopupContainer: () =>
                          document.getElementById('elements-container')
                      }}
                      value={data.hostingClinicsIds[0]}
                      filterItem={(clinic) =>
                        permissionChecking({
                          anyClinic: false,
                          clinicId: clinic.id,
                          moduleId: 106,
                          functionId: 2
                        }).success
                      }
                      onChange={(clinicId) => {
                        onChangeData({
                          hostingClinicsIds: clinicId ? [+clinicId] : [],
                          appointmentReasonId: null,
                          presentingProviderIds: []
                        });
                      }}
                    />
                    <ReasonSelector
                      name="Type"
                      type="single"
                      clinicIds={
                        data.hostingClinicsIds[0]
                          ? [data.hostingClinicsIds[0]]
                          : []
                      }
                      reasonTypes={['outreach']}
                      value={data.appointmentReasonId}
                      onChange={(appointmentReasonId: number, option: any) => {
                        const time = moment(data.startDateTime);
                        time.add(option?.duration, 'minutes');
                        const date = time.format('YYYY-MM-DDTHH:mm:ss');
                        onChangeData({
                          appointmentReasonId,
                          presentingProviderIds: [],
                          color: option?.color,
                          endDateTime: dateFormat(date, date)
                        });
                      }}
                      selectProps={{
                        bottomMargin: true,
                        getPopupContainer: () =>
                          document.getElementById('elements-container'),
                        inputProps: {
                          disabled: !data.hostingClinicsIds[0]
                        }
                      }}
                    />
                    <ProviderSelector
                      multiple={true}
                      filterByReasons={
                        data.appointmentReasonId
                          ? [data.appointmentReasonId]
                          : []
                      }
                      value={data.presentingProviderIds}
                      onChange={(pressentingProviderIds) => {
                        onChangeData({
                          presentingProviderIds: pressentingProviderIds
                        });
                      }}
                      roleFilter="appointment"
                      filterByClinics={
                        data.hostingClinicsIds[0]
                          ? [data.hostingClinicsIds[0]]
                          : []
                      }
                      disabled={
                        !data.hostingClinicsIds[0] || !data.appointmentReasonId
                      }
                      selectProps={{
                        getPopupContainer: () =>
                          document.getElementById('elements-container'),
                        label: 'Presenting Provider(s)',
                        star: true
                      }}
                    />
                    <Row align="middle" gutter={16}>
                      <Col>
                        <Checkbox
                          checked={data.isEnable}
                          onChange={() => {
                            onChangeData({
                              isEnable: !data.isEnable
                            });
                          }}
                          id="active"
                        >
                          Active
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          checked={data.isPatientPortalRegistration}
                          onChange={() => {
                            onChangeData({
                              isPatientPortalRegistration:
                                !data.isPatientPortalRegistration
                            });
                          }}
                          id="patientPortalRegistration"
                        >
                          Patient Portal Registration
                        </Checkbox>
                      </Col>
                    </Row>
                    <Divider />
                    <Row align="middle" gutter={16}>
                      <Col span={12}>
                        <SelectContainer
                          type="date"
                          label="Start Date"
                          value={data.startDateTime ? data.startDateTime : null}
                          onChange={(date) => {
                            if (date) {
                              onChangeData({
                                startDateTime: dateFormat(
                                  date,
                                  data.startDateTime
                                ),
                                endDateTime: dateFormat(date, data.endDateTime)
                              });
                            }
                          }}
                          inputProps={{
                            getPopupContainer: () =>
                              document.getElementById('elements-container')!,
                            allowClear: false,
                            disabledDate: (currentDate: any) => {
                              if (currentDate) {
                                return currentDate < moment();
                              }
                              return false;
                            }
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <SelectContainer
                          type="select"
                          label="Start time"
                          value={
                            timeOptions.length === 0
                              ? null
                              : moment(data.startDateTime).format('hh:mm A')
                          }
                          disableDefaultSort={true}
                          options={timeOptions}
                          onChange={(time: string) => {
                            const newStartDateTime = dateFormat(
                              data.startDateTime,
                              timeToDateTime(time)
                            );
                            let newEndDateTime = moment(newStartDateTime)
                              .add(reasonDuration, 'minute')
                              .format('YYYY-MM-DDTHH:mm:ss');
                            if (
                              !data.appointmentReasonId &&
                              moment(newEndDateTime).isBefore(
                                moment(data.endDateTime)
                              )
                            ) {
                              newEndDateTime = data.endDateTime;
                            }
                            onChangeData({
                              startDateTime: newStartDateTime,
                              endDateTime: newEndDateTime
                            });
                          }}
                          getPopupContainer={() =>
                            document.getElementById('elements-container')!
                          }
                          inputProps={{
                            disabled: currentClinic ? false : true
                          }}
                        />
                      </Col>
                    </Row>
                    <Row align="middle" gutter={16}>
                      <Col span={12}></Col>
                      <Col span={12}>
                        <SelectContainer
                          type="select"
                          label="End time"
                          value={
                            endTimeOptions.length === 0
                              ? null
                              : moment(data.endDateTime).format('hh:mm A')
                          }
                          disableDefaultSort={true}
                          options={endTimeOptions}
                          onChange={(time: string) => {
                            onChangeData({
                              endDateTime: dateFormat(
                                data.endDateTime,
                                timeToDateTime(time)
                              )
                            });
                          }}
                          getPopupContainer={() =>
                            document.getElementById('elements-container')!
                          }
                          bottomMargin={false}
                          inputProps={{
                            allowClear: false,
                            disabled: currentClinic ? false : true
                          }}
                        />
                      </Col>
                    </Row>
                    <Divider />
                    <Row align="middle" gutter={16}>
                      <Col>
                        <b>Attendance:</b>
                      </Col>
                      <Col>
                        <SelectContainer
                          type="number"
                          label="Expected"
                          value={data.expectedAttendance}
                          star={false}
                          onChange={(expectedAttendance) => {
                            onChangeData({
                              expectedAttendance
                            });
                          }}
                          inputProps={{
                            min: 0,
                            max: 10000,
                            'aria-label': 'Expected Attendance'
                          }}
                        />
                      </Col>
                      <Col>
                        <SelectContainer
                          type="number"
                          label="Actual"
                          value={data.actualAttendance}
                          star={false}
                          onChange={(actualAttendance) => {
                            onChangeData({
                              actualAttendance
                            });
                          }}
                          inputProps={{
                            min: 0,
                            max: 10000,
                            'aria-label': 'Actual Attendance'
                          }}
                        />
                      </Col>
                    </Row>
                  </Block>
                  <Block>
                    <div tabIndex={0} aria-label="Pre-event Info for Portal">
                      <SubTitle>Pre-event Info for Portal</SubTitle>
                      <SelectContainer
                        type="area"
                        label="Description"
                        value={data.preEventDescription}
                        star={false}
                        onChange={(preEventDescription: string) => {
                          onChangeData({
                            preEventDescription
                          });
                        }}
                        inputProps={{
                          maxLength: 2048
                        }}
                      />
                      <UploaderAttachments
                        fileIds={data.preEventFileIds}
                        files={data.preEventFiles || []}
                        filesToUpload={preEventFiles}
                        onChangeFiles={(data) => {
                          onChangeData({
                            preEventFileIds: data.fileIds,
                            preEventFiles: data.files
                          });
                        }}
                        onChangeUploadFiles={(data) => {
                          setPreEventFiles(data);
                        }}
                      />
                      <Divider />
                    </div>
                    <div tabIndex={0} aria-label="Post-event Info for Portal">
                      <SubTitle>Post-event Info for Portal</SubTitle>
                      <SelectContainer
                        type="area"
                        label="Description"
                        value={data.postEventDescription}
                        star={false}
                        onChange={(postEventDescription: string) => {
                          onChangeData({
                            postEventDescription
                          });
                        }}
                        inputProps={{
                          maxLength: 2048
                        }}
                      />
                      <UploaderAttachments
                        fileIds={data.postEventFileIds}
                        files={data.postEventFiles || []}
                        filesToUpload={postEventFiles}
                        onChangeFiles={(data) => {
                          onChangeData({
                            postEventFileIds: data.fileIds,
                            postEventFiles: data.files
                          });
                        }}
                        onChangeUploadFiles={(data) => {
                          setPostEventFiles(data);
                        }}
                      />
                      <Divider />
                    </div>
                    <div tabIndex={0} aria-label="Internal comments">
                      <SubTitle>Comment</SubTitle>
                      <SelectContainer
                        type="area"
                        label="Description"
                        value={data.internalDescription}
                        star={false}
                        onChange={(internalDescription: string) => {
                          onChangeData({
                            internalDescription
                          });
                        }}
                        inputProps={{
                          maxLength: 2048
                        }}
                      />
                      <ColorSelector
                        value={data.color}
                        onChange={(color) => {
                          onChangeData({
                            color
                          });
                        }}
                        selectProps={{
                          label: 'Outreach event color',
                          bottomMargin: false,
                          star: false
                        }}
                      />
                      <Divider />
                      <Row align="middle" gutter={16}>
                        <Col span={4}>
                          <b>Type:</b>
                        </Col>
                        <Col>
                          <Radio
                            id={ids.outreachTypeAnonymous}
                            aria-label="Anonymous outreach"
                            name="type"
                            checked={data.registrationType === 'anonymous'}
                            onClick={() =>
                              onChangeData({ registrationType: 'anonymous' })
                            }
                          >
                            Anonymous
                          </Radio>
                          <Radio
                            id={ids.outreachTypeRegistered}
                            aria-label="Registered outreach"
                            name="type"
                            checked={data.registrationType === 'registered'}
                            onClick={() =>
                              onChangeData({ registrationType: 'registered' })
                            }
                          >
                            Registered
                          </Radio>
                        </Col>
                      </Row>
                      {data.registrationType === 'registered' && (
                        <>
                          <Row
                            align="bottom"
                            gutter={16}
                            style={{ marginTop: 20 }}
                          >
                            <Col span={8}>
                              <SelectContainer
                                type="number"
                                label="Max participants"
                                value={data.maxParticipant}
                                star={false}
                                onChange={(maxParticipant) => {
                                  onChangeData({
                                    maxParticipant
                                  });
                                }}
                                inputProps={{
                                  min: 1,
                                  max: 10000
                                }}
                              />
                            </Col>
                            <Col span={16}>
                              <Button
                                id="define-guest-list-button"
                                aria-haspopup="dialog"
                                aria-controls={ids.defineGuestsDialog}
                                onClick={() => setPage('define')}
                                colorStyles={{
                                  blue: defineGuestListNumber > 0,
                                  boldFont: defineGuestListNumber > 0
                                }}
                                absoluteLoading={guestNumberLoading}
                              >
                                <Row align="middle" gutter={8}>
                                  <Col>
                                    <Icon
                                      color={
                                        defineGuestListNumber > 0
                                          ? globalStyleVariables.newBrandingPrimary200
                                          : '#666666'
                                      }
                                      name="filter"
                                      size={12}
                                    />
                                  </Col>
                                  <Col>{`Define Guest List${
                                    defineGuestListNumber
                                      ? ` (${defineGuestListNumber})`
                                      : ''
                                  }`}</Col>
                                </Row>
                              </Button>
                            </Col>
                          </Row>
                          <Row
                            align="middle"
                            gutter={16}
                            style={{ marginTop: 10 }}
                          >
                            <Col span={8}></Col>
                            <Col span={16}>
                              <Button
                                id="registrants-button"
                                aria-haspopup="dialog"
                                aria-controls={ids.outreachRegistrants}
                                onClick={() => setPage('registrants')}
                                colorStyles={{
                                  grey: prevData === null,
                                  blue: registrantsList.length > 0,
                                  boldFont: registrantsList.length > 0
                                }}
                                disabled={prevData === null}
                                tooltip={
                                  prevData === null
                                    ? {
                                        title: 'Outreach has to be saved first'
                                      }
                                    : undefined
                                }
                                absoluteLoading={registrantsLoading}
                              >
                                <Row align="middle" gutter={8}>
                                  <Col>
                                    <Icon
                                      color={
                                        registrantsList.length > 0
                                          ? globalStyleVariables.newBrandingPrimary200
                                          : '#666666'
                                      }
                                      name="document"
                                      size={12}
                                    />
                                  </Col>
                                  <Col>{`Registrants${
                                    registrantsList.length !== 0
                                      ? ` (${registrantsList.length})`
                                      : ''
                                  }`}</Col>
                                </Row>
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </Block>
                </BlockFit>
              </BlockAbsolute>
            )}
          </>
        )}
      </Container>
    </Modal>
  );
};

export default React.forwardRef(Component);

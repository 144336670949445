import _ from 'lodash'
import store from 'src/store';
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"

import { actionsTypes } from './index'

import { ICategory, IFilter } from './types'

export const setFilter = (payload: Partial<IFilter>) => {
    store.dispatch({
        type: actionsTypes.SET_FILTER,
        payload
    })
}

const setLoading = (payload: boolean) => {
    store.dispatch({
        type: actionsTypes.SET_LOADING,
        payload
    })
}

const setData = (payload: any) => {
    store.dispatch({
        type: actionsTypes.SET,
        payload
    })
}

const onChangeData = (payload: ICategory) => {
    const summary = store.getState().adminPanel.categories.summary
    summary[payload.id] = {
        ...payload
    }
    setData(summary)
}

export const get = async () => {
    try {
        const summary = store.getState().typeRisks.summary
        if (summary.length === 0) {
            setLoading(true)
        }
        const response = await APIHandler.AxiosInstance.get(ApiUrl.CATEGORIES, { withCredentials: true })
        if (response.data) {
            if (response.data.success) {
                const data: any = response.data.result.summary
                setData(data)
                return data
            }
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
        setLoading(false)
    }
}

export const post = async (body: Partial<ICategory>) => {
    try {
        const response = await APIHandler.AxiosInstance.post(ApiUrl.CATEGORIES, body, { withCredentials: true })
        if (response.data) {
            if (response.data.success) {
                const items: any = Object.values(response.data.result.summary)
                items.forEach((v: any) => {
                    onChangeData(v)
                })
                return items
            }
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const patch = async (body: Partial<ICategory>) => {
    try {
        const response = await APIHandler.AxiosInstance.patch(`${ApiUrl.CATEGORIES}/${body.id}`, body, { withCredentials: true })
        if (response.data) {
            if (response.data.success) {
                const items: any = Object.values(response.data.result.summary)
                items.forEach((v: any) => {
                    onChangeData(v)
                })
                return items
            }
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}
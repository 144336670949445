import React, { FC, useEffect, useState, useRef } from 'react';
import { Slider } from 'antd';
import type { SliderMarks } from 'antd/es/slider';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import Popover from 'src/Framework/Controls/Popover';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { useAppSelector } from 'src/store';

import { onChangePreferenceItem } from 'src/App/UserPreferences/store/Actions';
import { PrefEnumKeys } from 'src/App/Preferences/utils';

import { ContentContainer, Title } from './styled';

import ReservationLabelOpacity from './ReservationLabelOpacity';

export interface CalendarOpacityLevel {
  value: null | number;
}

export const defaultValueOpacity = 0.2;

export const defaultCalendarOpacityLevel: CalendarOpacityLevel = {
  value: defaultValueOpacity
};

const marks: SliderMarks = {
  0: '0%',
  100: '100%'
};

interface IProps {}

const Component: FC<IProps> = ({}: IProps) => {
  const popupRef = useRef(null);
  const buttonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const data = useAppSelector(
    (state) =>
      state.user.preferences.calendarOpacityLevel?.parsedValue ||
      defaultCalendarOpacityLevel
  );
  const [value, setValue] = useState<number>(data.value ?? defaultValueOpacity);
  useEffect(() => {
    setValue(data.value ?? defaultValueOpacity);
  }, [data.value]);

  const onKeyDownButton = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      if (e.currentTarget) {
        // @ts-ignore
        e.currentTarget.click();
      }
    }
    if (e.key === 'Tab' && !e.shiftKey && open) {
      if (popupRef.current) {
        e.preventDefault();
        //@ts-ignore
        popupRef.current.focus();
      }
    }
    if (e.key === 'Tab' && e.shiftKey && open) {
      setOpen(false);
    }
  };
  const onKeyDownPopupContainer = (e: React.KeyboardEvent) => {
    if (
      e.key === 'Tab' &&
      !e.shiftKey &&
      (e.target as HTMLElement).tagName === 'INPUT' &&
      (e.target as HTMLInputElement).type === 'checkbox'
    ) {
      if (buttonRef.current) {
        e.preventDefault();
        //@ts-ignore
        buttonRef.current.focus();
      }
    }
    if (e.target === e.currentTarget && e.key === 'Tab' && e.shiftKey) {
      if (buttonRef.current) {
        e.preventDefault();
        //@ts-ignore
        buttonRef.current.focus();
      }
    }
  };
  return (
    <Popover
      open={open}
      trigger="click"
      onOpenChange={(value) => setOpen(value)}
      id="opacity_popup"
      placement="bottom"
      content={
        <ContentContainer
          tabIndex={0}
          ref={popupRef}
          onKeyDown={onKeyDownPopupContainer}
        >
          {loading && <AbsoluteLoader />}
          <Title>Opacity Level:</Title>
          <Slider
            aria-label={value * 100 + 'percents'}
            marks={marks}
            value={value * 100}
            step={1}
            onAfterChange={async (value: number) => {
              setLoading(true);
              await onChangePreferenceItem(PrefEnumKeys.CalendarOpacityLevel, {
                ...data,
                value: value / 100
              });
              setLoading(false);
            }}
            onChange={(value: number) => {
              setValue(value / 100);
            }}
            tooltip={{
              getPopupContainer: (node: any) => node.parentNode
            }}
          />
          <ReservationLabelOpacity />
        </ContentContainer>
      }
    >
      <ButtonIcon
        containerProps={{
          onKeyDown: (e: React.KeyboardEvent) => onKeyDownButton(e),
          ref: buttonRef
        }}
        isButton={true}
        label="Opacity Level"
        haspopup="dialog"
        controlsId="opacity_popup"
        active={open}
        tooltip="Opacity Level"
        name={ICONS_LIST.opacity}
      />
    </Popover>
  );
};

export default React.memo(Component);

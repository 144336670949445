import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Container = styled.div``;

export const Block = styled.div`
  display: flex;
  align-items: center;
  padding: 2px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  width: 100%;
`;

interface IUploadButton {
  color?: string;
}

export const UploadButton = styled.div<IUploadButton>`
  color: ${({ color }) => color || globalStyleVariables.newBrandingPrimary200};
  background: ${globalStyleVariables.newBrandingPrimary20010};
  border: 1px solid
    ${({ color }) => color || globalStyleVariables.newBrandingPrimary200};
  padding: 7px 8px;
  font-size: 12px;
  line-height: 10px;
`;

interface IScannerContainer {
  color?: string;
}

export const ScannerContainer = styled.div<IScannerContainer>`
  color: ${({ color }) => color || globalStyleVariables.newBrandingPrimary200};
  background: ${globalStyleVariables.newBrandingPrimary20010};
  border: 1px solid
    ${({ color }) => color || globalStyleVariables.newBrandingPrimary200};
  padding: 4px;
  margin: 0 0 0 2px;
`;

export const NamesContainer = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 12px;
  flex: 1;
  overflow: hidden;
  padding: 0 5px;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;
`;

export const IconContainer = styled.div`
  position: relative;
  margin: 0 10px 5px 0;
  background-color: #f7f7f7;
  padding: 2px;
`;

export const RemoveIcon = styled.div`
  position: absolute;
  top: -2px;
  right: -10px;
  cursor: pointer;
`;

export const PreviewContainer = styled.div``;

export const PreviewItem = styled.div`
  img {
    object-fit: contain;
  }
`;

import React from 'react';
import {
  WeekdayButton,
  RepeatContainer
} from '../../Common/Editors/ScheduleEditor_Styles';
import { ErrorBlock } from '../../Popups/BlockReservationPopup/BlockReservationPopup_Styles';
import test_ids from 'src/tests-script/pages/Schedule/Recurring';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';

export function WeeklyRecurrenceDaySelector(
  weekdayOptions: any,
  callback: any,
  isWeekdaySelected: boolean,
  dayOfWeekFilter: string
) {
  const onWeekdaySelect = (
    weekday: any,
    index: number,
    weekdayOptions: any
  ) => {
    if (weekday.disabled) {
      return;
    }
    const data = weekdayOptions.map((v: any, i: number) => {
      const isSelected = dayOfWeekFilter.split('').includes(v.value);
      return {
        ...v,
        isSelected: i === index ? !isSelected : isSelected
      };
    });
    callback(data);
  };
  return (
    <RepeatContainer style={{ marginTop: '30px' }} id={test_ids.weeksButtons}>
      <div
        style={{
          display: 'flex'
        }}
      >
        {weekdayOptions &&
          weekdayOptions.map((weekday: any, index: number) => {
            const isSelected = dayOfWeekFilter
              .split('')
              .includes(weekday.value);
            return (
              <WeekdayButton
                role="button"
                tabIndex={0}
                aria-label={weekday.label}
                aria-pressed={isSelected}
                aria-disabled={weekday.disabled}
                onKeyDown={(e) => onKeyDownAccessibility(e)}
                id={test_ids.dayItem(weekday.code)}
                isSelected={isSelected}
                isDisabled={weekday.disabled}
                key={index}
                onClick={() => onWeekdaySelect(weekday, index, weekdayOptions)}
              >
                {weekday.label[0]}
              </WeekdayButton>
            );
          })}
      </div>
      <ErrorBlock error={isWeekdaySelected}>Please Select a weekday</ErrorBlock>
    </RepeatContainer>
  );
}

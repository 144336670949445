import React, { FC } from 'react';
import { Tag } from 'antd';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import EllipsisText from 'src/Framework/Controls/EllipsisText';

import { ISubjectTag } from 'src/Activities/Schedule/Common/Select/Tabulators/SubjectSearch';

interface IProps {
  subject: ISubjectTag;
  onRemove?: (subjectId: number) => void;
}

const Component: FC<IProps> = ({ subject, onRemove }) => {
  return (
    <Tag
      tabIndex={0}
      role="listitem"
      id={`selected-subject-${subject.subjectId}`}
      key={subject.subjectId}
      closable={!!onRemove}
      className={'subject-tag'}
      closeIcon={
        onRemove && (
          <ButtonIcon
            isButton={true}
            label="remove item"
            name={ICONS_LIST.thinClose}
            size={16}
            disableBackground={true}
          />
        )
      }
      onClose={onRemove ? () => onRemove(subject.subjectId) : undefined}
    >
      <EllipsisText maxWidth={180}>{subject.subjectName}</EllipsisText>
    </Tag>
  );
};

export default React.memo(Component);

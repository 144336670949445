import React from "react";

export const CoupleApptSvg = () => (
<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5">
<path d="M6.99976 2.8332C6.99976 3.66166 7.67123 4.66646 8.4997 4.66646C9.32817 4.66646 9.99964 3.66166 9.99964 2.8332C9.99964 2.00473 9.32817 1.33325 8.4997 1.33325C7.67123 1.33325 6.99976 2.00473 6.99976 2.8332Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.66702 7.99968H11.0003V7.27904C11.0003 6.87139 10.7553 6.5029 10.3766 6.35208C9.94313 6.17925 9.30032 5.99976 8.50035 5.99976C7.70038 5.99976 7.05758 6.17925 6.62409 6.35191C6.44543 6.42307 6.29727 6.5419 6.18994 6.69123" fill="#333333"/>
<path d="M8.66702 7.99968H11.0003V7.27904C11.0003 6.87139 10.7553 6.5029 10.3766 6.35208C9.94313 6.17925 9.30032 5.99976 8.50035 5.99976C7.70038 5.99976 7.05758 6.17925 6.62409 6.35191C6.44543 6.42307 6.29727 6.5419 6.18994 6.69123" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.3331 8.99958H1V8.06128C1 7.55263 1.28699 7.08715 1.74431 6.86432C2.27795 6.60433 3.09226 6.33301 4.16655 6.33301C5.24084 6.33301 6.05514 6.60433 6.58879 6.86432C7.04611 7.08715 7.3331 7.55263 7.3331 8.06128V8.99958Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.99978 2.83327C5.99978 3.84573 5.17898 4.99985 4.16652 4.99985C3.15405 4.99985 2.33325 3.84573 2.33325 2.83327C2.33325 1.8208 3.15405 1 4.16652 1C5.17898 1 5.99978 1.8208 5.99978 2.83327Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</svg>







);

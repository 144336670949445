import React, { FC, useEffect, useRef, useState } from 'react';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import Modal, { IModalHandles } from '../index';
import { IM1FormRecord } from 'src/App/M1FormRecords/store/types';
import * as Actions from '../Actions';
import { Container, RemoveIcon } from './styled';
import Icon from 'src/Framework/Controls/Icon';
import AlertPopUp, {
  IModalHandles as IAlertPopUp
} from 'src/Framework/Controls/AlertPopUp';

interface IProps {
  patientId: number;
  m1FormRecordId: number;
  onRemoveForm: () => any;
  showRemoveIcon?: boolean;
  onClickRemoveIcon?: () => any;
  showRemovedAlert?: boolean;
  getFormCallback?: (value: IM1FormRecord) => any;
}

const Component: FC<IProps> = ({
  patientId,
  m1FormRecordId,
  onRemoveForm,
  showRemoveIcon,
  onClickRemoveIcon,
  showRemovedAlert,
  getFormCallback
}: IProps) => {
  const alertRef = useRef<IAlertPopUp>(null);
  const [form, setForm] = useState<IM1FormRecord | null>(null);
  const getData = async () => {
    const res = await Actions.getForm(m1FormRecordId);
    if (res) {
      if (getFormCallback) {
        getFormCallback(res);
      }
      setForm(res);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const ref = useRef<IModalHandles>(null);
  return (
    <Container>
      <AlertPopUp ref={alertRef} />
      <Modal
        ref={ref}
        onRemoveForm={() => {
          getData();
          ref.current?.close();
          onRemoveForm();
        }}
      />
      {showRemoveIcon && (
        <RemoveIcon tabIndex={0} role="button" onClick={onClickRemoveIcon}>
          <Icon name="removeCircle" color="black" size={12} />
        </RemoveIcon>
      )}
      <ButtonIcon
        tooltip={form?.formTemplateName || ''}
        name={ICONS_LIST.document}
        changeColorOnHover={true}
        onClick={() => {
          if (showRemovedAlert) {
            if (form && form.isActive === false) {
              alertRef.current?.show({
                action: 'delete',
                content: `The '${
                  form?.formTemplateName || ''
                }' form has been deleted.`,
                hideClose: true,
                hideOk: true
              });
              return;
            }
          }
          ref.current?.show({
            value: {
              patientId,
              m1FormRecordId
            }
          });
        }}
      />
    </Container>
  );
};

export default React.memo(Component);

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const ProviderContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: 23.5px;
  border-right: solid #e6e6e6 1px;
  position: relative;
  user-select: none;

  &:active {
    background-color: ${globalStyleVariables.newBrandingPrimary100};
  }

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const ProviderContainerWeek = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: 23.5px;
  border-right: solid #e6e6e6 1px;
  position: relative;
  user-select: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const AllowedLimit = styled.div`
  padding-right: 2rem;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${globalStyleVariables.brandGray};
  cursor: pointer;
`;

export const DayViewProviderNameStyle = styled.div`
  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 800;
  line-height: 22px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  color: #333333;
`;

interface WeekViewWeekDayProps {
  colorDay?: string;
}

export const WeekViewWeekDayStyle = styled.div`
  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: space-between;
  /* Brand Green / Default */

  color: ${(props: WeekViewWeekDayProps) => props.colorDay};
`;

export const ClosedLabel = styled.span`
  font-size: 10px;
  line-height: 17px;
  /* identical to box height */
  margin-right: 8px;
  display: flex;
  align-items: center;
  color: #f9b40b;
`;

export const ClinicStyle = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  font-size: 12px;
  color: #666666;
  width: 80%;
`;

export const ProviderNameContainer = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 30px);
`;

import React, { FC } from 'react';
import cn from 'classnames';

import { Container } from './styled';

interface IProps {
  children: React.ReactNode;
  borderBottom?: boolean;
}

const Component: FC<IProps> = ({ children, borderBottom }: IProps) => {
  return (
    <Container
      className={cn({
        borderBottom: borderBottom
      })}
    >
      {children}
    </Container>
  );
};

 

export default Component;

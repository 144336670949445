import React, { FC } from 'react';

import EllipsisText from 'src/Framework/Controls/EllipsisText';
import { useCheckInStatus } from 'src/App/Admin/Pages/CheckInStatus/store/Hook/useCheckInStatus';

import { Container } from './styled';

interface IProps {
  statusCode: string;
  maxWidth?: number | string;
  withoutContainer?: boolean;
}

const Component: FC<IProps> = ({
  statusCode,
  withoutContainer,
  maxWidth = 84
}) => {
  const typeStatuses = useCheckInStatus();
  const statusLabel =
    typeStatuses.finder(statusCode, 'code')?.name || statusCode;
  if (withoutContainer) {
    return <>{statusLabel}</>;
  }
  return (
    <Container>
      <EllipsisText maxWidth={maxWidth}>{statusLabel}</EllipsisText>
    </Container>
  );
};

export default React.memo(Component);

import React, { FC } from 'react';
import { List } from 'antd';

import { formattedDateTime, formattedTime } from 'src/Framework/Shared/Shared';
import EllipsisText from 'src/Framework/Controls/EllipsisText';

import { IConflictsList } from 'src/Activities/Schedule/ConflictsBar/ConflictsContent';
import { ListContainer, Info } from './ConflictsBar_Styles';

interface IProps {
  data: IConflictsList[];
}

const Component: FC<IProps> = ({ data }) => {
  return (
    <ListContainer>
      <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Info>
              <div>
                {formattedDateTime(item.startDate)} -{' '}
                {formattedTime(item.endDate)}
              </div>
              <EllipsisText maxWidth={160}>{item.providerName}</EllipsisText>
            </Info>
          </List.Item>
        )}
      />
    </ListContainer>
  );
};

export default React.memo(Component);

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Container = styled.div`
  display: flex;
  background: #f7f7f7;
  border-radius: 4px;
  margin: 0 0 12px 0;
  overflow: hidden;
  visibility: inherit;
`;

export const DragContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  background: rgba(0, 0, 0, 0.1);
  cursor: grab;
`;

export const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 6px 10px;
  overflow: hidden;

  .notShowed {
    width: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden;
  }
`;

interface INameProps {
  isShowing: boolean;
}

export const Name = styled.div<INameProps>`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  display: flex;
  visibility: ${({ isShowing }) => (isShowing ? 'visible' : 'hidden')};
`;

export const ApptType = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: #333333;
`;

export const Time = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: ${globalStyleVariables.brandGray};
`;

export const MoreIcon = styled.div`
  transition: transform 0.25s ease-in-out;

  &.visible {
    transform: rotate(90deg);
  }
`;

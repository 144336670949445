import React, { useImperativeHandle, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import _ from 'lodash';

import Modal from 'src/Framework/Controls/Modal';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import Button from 'src/Framework/Controls/Button';
import Icon from 'src/Framework/Controls/Icon';
import { getClinicByCode } from 'src/Framework/Controls/Selectors/ClinicSelector';
import { getMaskOptions } from 'src/Framework/Controls/SelectContainer/Components/Input';
import ProviderSelector from 'src/Framework/Controls/Selectors/ProviderSelector';
import ElementTitle from 'src/Framework/Controls/ElementTitle';
import ReasonSelector from 'src/Framework/Controls/Selectors/ReasonSelector';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { patientDisplayName } from 'src/Framework/util/format';
import { differObjects } from 'src/Framework/Shared/Shared';

import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';
import {
  IAppointment,
  IGroup
} from 'src/Activities/Schedule/RightSideBar/WaitingList/Group/types';
import { TypeListEnum } from 'src/Activities/Schedule/WaitingListSettings/store/types';
import { IScheduleEvent } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import { patchAppointments } from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import * as scheduleEventActions from 'src/Activities/Schedule/Store/ScheduleEventsReducer/Actions';
import { getAppointment } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/Actions';
import Remove from 'src/Activities/Schedule/RightSideBar/WaitingList/Group/GroupItem/StudentItem/Modal/Remove';
import Status from 'src/Activities/Schedule/RightSideBar/WaitingList/Group/GroupItem/StudentItem/Modal/Status';
import Tags2 from 'src/App/Tags/Components/Tags2';
import CommentsMain from 'src/Activities/Schedule/RightSideBar/WaitingList/Group/GroupItem/StudentItem/Comments/Main';
import { waitingListTagDefinition } from 'src/Activities/Schedule/RightSideBar/WaitingList/Group/GroupItem/StudentItem/Modal/index';
import { statusProgressHandler } from 'src/Activities/Schedule/Utils/Utils';
import { IOnChangeComment } from '../Comments';

import { PopoverTitleContainer } from 'src/Activities/Schedule/RightSideBar/WaitingList/GroupList/GroupList_Styles';
import { PopoverContent } from 'src/Activities/Subject/Facts/Facts/styles';
import {
  CommentsContainer,
  HorizontalPadding,
  InfoContact,
  ModalBody,
  StatusContainer,
  TagsContainer
} from 'src/Activities/Schedule/RightSideBar/WaitingList/Group/GroupItem/StudentItem/Modal/styles';

interface IShow {}

interface IProps {
  data: IGroup[];
  value: IAppointment;
  group: IGroup;
  updateWaitingList: Function;
  onChangeComment: IOnChangeComment;
}

export interface IModalHandles {
  show(obj: IShow): void;

  close(): void;
}

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  props,
  ref
) => {
  const [apptData, setApptData] = useState<{
    scheduleEvent: IScheduleEvent;
  } | null>(null);
  const { value, group, data, updateWaitingList, onChangeComment } = props;
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [remove, setRemove] = useState(false);
  const [patient, setPatient] = useState(_.cloneDeep(value));
  const [prevPatient, setPrevPatient] = useState(_.cloneDeep(value));
  const [selectedCancellationReason, setSelectedCancellationReason] =
    useState(null);
  useModalFocus({ active: visible });

  const clinicsFilter = useMemo(() => {
    if (
      patient &&
      patient?.clinicCode &&
      getClinicByCode(patient?.clinicCode)
    ) {
      return [getClinicByCode(patient?.clinicCode)!.id];
    } else {
      return [];
    }
  }, [patient]);

  const show = (incomingData: IShow) => {
    setPatient(_.cloneDeep(value));
    setPrevPatient(_.cloneDeep(value));
    setVisible(true);
    const init = async () => {
      setLoading(true);
      const res = await getAppointment(value.appointmentId, false);
      if (res) {
        setApptData(res);
      }
      setLoading(false);
    };
    if (group && group.typeList === TypeListEnum.triage) {
      init();
    }
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setRemove(false);
    setSelectedCancellationReason(null);
    setApptData(null);
  };

  useImperativeHandle(ref, () => ({
    show: (obj: IShow) => show(obj),
    close
  }));

  const onUpdateHandler = async () => {
    const obj = differObjects(
      {
        appointmentReasonId: patient.reason.id,
        note: patient.note,
        tags: patient.tags,
        waitingListProviderIds: patient.waitingListProviderIds
      },
      {
        appointmentReasonId: prevPatient.reason.id,
        note: prevPatient.note,
        tags: prevPatient.tags,
        waitingListProviderIds: prevPatient.waitingListProviderIds
      }
    );
    const res = await patchAppointments({
      ...obj,
      id: patient.appointmentId,
      patientId: patient.patientInfo.patientId
    });
    if (res) {
      const groups = _.cloneDeep(props.data);
      const i = groups.findIndex((v) => v.id === group.id);
      if (i !== -1) {
        const index = groups[i].patients.findIndex((v) => v.uid === res.uid);
        if (index !== -1) {
          groups[i].patients[index] = {
            ...res,
            subjectId: res?.subjectId || res.patientInfo.patientId,
            note: res.note || '',
            name: res?.patientInfo?.patientName || ''
          };
        }
      }
      updateWaitingList(groups);
      close();
    }
  };

  const title = value && (
    <EllipsisText maxWidth={230}>
      {`${patientDisplayName(value?.patientInfo)} (${
        value?.subjectId ? value?.subjectId : value?.patientInfo.patientId
      })`}
    </EllipsisText>
  );
  const defaultWidth = 600;
  const headerRight = !remove && (
    <Row align="middle" gutter={8} justify="end">
      <Col>
        <ButtonIcon
          isButton={true}
          label="remove item"
          name={ICONS_LIST.delete}
          changeColorOnHover={true}
          color={globalStyleVariables.red}
          onClick={() => setRemove(true)}
        />
      </Col>
      <Col>
        <Button id="cancel-button" onClick={(e: any) => close()}>
          Cancel
        </Button>
      </Col>
      <Col>
        <Button
          id="update-button"
          style={{ minWidth: '80px' }}
          onClick={async () => await onUpdateHandler()}
        >
          Update
        </Button>
      </Col>
    </Row>
  );

  const onStatusChange = async (newStatus: any) => {
    if (apptData) {
      const eventInfo = apptData;
      if (eventInfo) {
        const patientId = eventInfo.scheduleEvent.patient.id;
        const status = {
          ...eventInfo.scheduleEvent.status
        };
        const details: any = statusProgressHandler(newStatus, status);
        const payload = {
          appointmentId: eventInfo.scheduleEvent['appointmentId'],
          subjectId: patientId,
          status: {
            ...details
          },
          dts: new Date()
        };
        const response = await scheduleEventActions.updatePatientStatus(
          payload
        );
        if (response) {
          setApptData((data) => {
            if (!data) return null;
            return {
              ...data,
              scheduleEvent: {
                ...data.scheduleEvent,
                status: response.status
              }
            };
          });
          const groups = _.cloneDeep(props.data);
          const groupIndex = groups.findIndex((v) => v.id === group.id);
          if (groupIndex !== -1) {
            const patientIndex = groups[groupIndex].patients.findIndex(
              (v) => v.uid === response.appointmentId + ''
            );
            if (patientIndex !== -1) {
              groups[groupIndex].patients[
                patientIndex
              ].patientInfo.patientStatus = response.status;
            }
          }
          updateWaitingList(groups);
        }
      }
    }
  };

  return value ? (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        headerRight,
        title
      }}
      id="edit-member-modal"
      width={defaultWidth}
      title={title}
      visible={visible}
      onClose={close}
      afterClose={setToDefault}
      buttons={headerRight}
      modalProps={{
        destroyOnClose: true,
        onCancel: close
      }}
    >
      <ModalBody className="custom-checkbox">
        {loading && <AbsoluteLoader />}
        {remove ? (
          <HorizontalPadding>
            <Remove
              close={close}
              data={data}
              group={group}
              value={value}
              setRemove={setRemove}
              updateWaitingList={updateWaitingList}
              selectedCancellationReason={selectedCancellationReason}
              setSelectedCancellationReason={setSelectedCancellationReason}
            />
          </HorizontalPadding>
        ) : (
          <PopoverContent
            className={'waiting-list'}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexDirection: 'column',
              marginTop: '2rem'
            }}
          >
            <HorizontalPadding>
              <PopoverTitleContainer>
                <InfoContact>
                  <span className={'icon'}>
                    <Icon name="phone" size={20} />
                  </span>
                  <span className={'copy'}>
                    {
                      getMaskOptions(
                        'phoneHome',
                        value.patientInfo.patientPreferredPhone
                      ).value
                    }
                  </span>
                </InfoContact>
                <InfoContact>
                  <span className={'icon'}>
                    <Icon name="messageUnread" size={16} />
                  </span>
                  <span className={'copy'}>
                    <EllipsisText maxWidth={256}>
                      {value.patientInfo.patientEmail}
                    </EllipsisText>
                  </span>
                </InfoContact>
              </PopoverTitleContainer>
            </HorizontalPadding>
            <PopoverTitleContainer
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                marginTop: 10,
                marginBottom: 20
              }}
            >
              {group && group.typeList === TypeListEnum.roster && (
                <HorizontalPadding>
                  <ProviderSelector
                    enableFilters={true}
                    multiple={true}
                    containerStyles={{
                      width: '100%'
                    }}
                    roleFilter="appointment"
                    filterByClinics={clinicsFilter}
                    value={patient?.waitingListProviderIds || []}
                    onChange={(values: string[]) => {
                      setPatient((data) => ({
                        ...data,
                        waitingListProviderIds: values.map((item) => +item)
                      }));
                    }}
                    selectProps={{
                      bottomMargin: true,
                      inputProps: {
                        id: 'waiting-list-client-provider-selector'
                      }
                    }}
                  />
                </HorizontalPadding>
              )}
              <HorizontalPadding>
                <ReasonSelector
                  name="Type"
                  value={patient?.reason?.id}
                  clinicCodes={[value?.clinicCode]}
                  onChange={(value: any) => {
                    setPatient((data) => ({
                      ...data,
                      reason: {
                        ...data.reason,
                        id: value
                      }
                    }));
                  }}
                  reasonTypes={['appointment']}
                  selectProps={{
                    star: false,
                    inputProps: {
                      placeholder: 'Select Type'
                    }
                  }}
                />
              </HorizontalPadding>
            </PopoverTitleContainer>
            {group && group.typeList === TypeListEnum.triage ? (
              <StatusContainer>
                <ElementTitle name={'CHECK IN STATUS:'} />
                {apptData && (
                  <Status apptData={apptData} onStatusChange={onStatusChange} />
                )}
              </StatusContainer>
            ) : (
              <TagsContainer>
                <ElementTitle name={'Tags:'} />
                <Tags2
                  tags={patient?.tags || []}
                  tagDefinitionId={waitingListTagDefinition}
                  onChange={async (tags) => {
                    setPatient((data) => ({
                      ...data,
                      tags
                    }));
                  }}
                />
              </TagsContainer>
            )}
            <CommentsContainer>
              <CommentsMain
                title={`${patientDisplayName(value.patientInfo)} (${
                  (value.comments || []).filter((v) => v.isActive).length
                })`}
                comments={value.comments || []}
                appointmentId={value.appointmentId}
                onChangeComment={onChangeComment}
              />
            </CommentsContainer>
          </PopoverContent>
        )}
      </ModalBody>
    </Modal>
  ) : null;
};
export default React.forwardRef(Component);

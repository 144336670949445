const ids = {
    cancelledMembers: 'cancelled-members-expand',
    groupPopoverContainer: 'group-popover-container',
    editButton: 'edit-button',
    cancelEventButton: 'cancel-event-button',
    removeEventButton: 'remove-event-button',
    cancelEventContainer: 'cancel-event-container',
    removeReason: 'remove-reason',
    removeNoteArea: 'remove-note-area',
    cancelButton: "cancel-button",
    removeButton: "remove-button"
}

export default ids
import React, { FC, useRef } from 'react';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import { ITicketDetailHipaa } from '../../store/types';

import PolicyStatusText from '../PolicyStatusText';

import PrivacyPolicyStatusModal, {
  IModalHandles
} from '../PrivacyPolicyStatusModal';
import { HippaStatusColors } from '../utils';
import { ICommon } from '.';
import Icon from 'src/Framework/Controls/Icon';
import { getCurrentClinicId } from 'src/Framework/Controls/Selectors/ClinicSelector';

import { isHippoExpired } from 'src/App/Hippa/store/utils';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

interface IProps extends ICommon {
  data?: ITicketDetailHipaa;
  loading?: boolean;
}

const tooltipProps = {
  showArrow: false
};

const Component: FC<IProps> = ({
  loading,
  data,
  statusModal,
  useIcon,
  clinicId
}: IProps) => {
  const currentClinicId = clinicId || getCurrentClinicId();
  const modalRef = useRef<IModalHandles>(null);
  const expired = isHippoExpired(currentClinicId, data?.dosFrom);
  const color = expired
    ? globalStyleVariables.red
    : data
    ? HippaStatusColors[data?.action]
    : undefined;
  const tooltip = data ? (
    <PolicyStatusText disableHippaColor={true} data={data} />
  ) : undefined;
  const onClick = () => {
    if (statusModal) {
      if (data) {
        modalRef.current?.show({
          value: data
        });
      }
    }
  };
  return (
    <>
      {statusModal && (
        <PrivacyPolicyStatusModal ref={modalRef} clinicId={currentClinicId} />
      )}
      {useIcon ? (
        <Icon
          name="privacyPolicy"
          color={color}
          tooltip={tooltip}
          onClick={onClick}
          tooltipProps={tooltipProps}
        />
      ) : (
        <ButtonIcon
          tooltip={tooltip}
          name={ICONS_LIST.privacyPolicy}
          color={color}
          loading={loading}
          size={50}
          iconScale={2.3}
          onClick={onClick}
          tooltipProps={tooltipProps}
        />
      )}
    </>
  );
};

export default React.memo(Component);

import React, { FC, useCallback, useState } from 'react';

import Popover from 'src/Framework/Controls/Popover';

import Content from './Content';
import { ICommon } from './types';

interface IProps extends ICommon {
  useOpenMethod?: boolean;
  children: ({
    open,
    setOpen
  }: {
    open: boolean;
    setOpen: (value: boolean) => any;
  }) => React.ReactNode;
}

const Component: FC<IProps> = ({
  children,
  useOpenMethod,
  ...common
}: IProps) => {
  const [open, setOpen] = useState(false);
  const onChangeOpen = useCallback((value: boolean) => {
    setOpen(value);
  }, []);
  return (
    <Popover
      id="remove-patient-popover"
      trigger={'click'}
      removePaddings={true}
      content={<Content {...common} onChangeOpen={onChangeOpen} />}
      showArrow={false}
      placement="right"
      globalOverlay={false}
      open={open}
      onOpenChange={(open) => {
        if (useOpenMethod && open) {
        } else {
          setOpen(open);
        }
      }}
    >
      {children({ open, setOpen: onChangeOpen })}
    </Popover>
  );
};

export default Component;

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 0 20px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 82px);
`;

export const PopoverContentContainer = styled.div`
  padding: 20px 0 0 0;
  width: 400px;
`;

interface IListContainerProps {
  popoverMode?: boolean;
}

export const ListContainer = styled.div<IListContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  max-height: ${({ popoverMode }) => (popoverMode ? '400px' : 'none')};
  overflow: auto;
  scroll-behavior: smooth;
  user-select: text;
`;

export const AbsoluteListContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
`;

interface IFooterProps {
  creationMode?: boolean;
}

export const Footer = styled.div<IFooterProps>`
  position: relative;
  margin: ${(props: IFooterProps) => (props.creationMode ? 0 : '0 20px')};

  .area-with-suffix {
    padding-right: 46px !important;
  }
`;

interface ISuffixProps {
  areaHideLabel?: boolean;
}

export const AreaSuffix = styled.div<ISuffixProps>`
  position: absolute;
  top: ${(props: ISuffixProps) => (props?.areaHideLabel ? '0' : '20px')};
  bottom: 0;
  right: 10px;
  display: flex;
  align-items: center;
`;

interface IItemContainer {
  removeItemHorizontalMargins?: boolean
}

export const ItemContainer = styled.div<IItemContainer>`
  padding: 10px;
  background-color: ${globalStyleVariables.grey};
  margin: 0 20px 16px 20px;
  word-wrap: break-word;
  ${({ removeItemHorizontalMargins }) => removeItemHorizontalMargins && `
    margin-left: 0px;
    margin-right: 0px;
`
  }
`;

export const ItemEditContainer = styled.div`
  margin: 0 20px 16px 20px;
`;

export const EditFooter = styled.div`
  padding: 5px 0;
  border-bottom: 1px solid ${globalStyleVariables.borderColor};
`;

export const UserName = styled.div`
  color: ${globalStyleVariables.newBrandingPrimary200};
  font-style: normal;
  font-weight: 700;
  font-family: Lato;
`;

export const Comment = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 5px 0;
`;

export const DateTime = styled.div`
  color: ${globalStyleVariables.newBrandingPrimary200};
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;

import React, { FC } from 'react';
import { ActionRow, EditPopupBody, LabelContainer } from './RClickPopup_Styles';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { permissionChecking } from 'src/App/User/Permission';
import Popover from 'src/Framework/Controls/Popover';
import {
  eventName,
  EApptTypeCode,
  EEventTypeCode,
  eventPermission
} from 'src/Activities/Schedule/utils';

interface IProps {
  inReservaton: boolean;
  reservedReason: any;
  scheduleCol: any;
  onClosePopup: () => void;
  onOpenIndividualApptPopup: any;
  onOpenGroupApptPopup: (typeAppointmentParticipantId: number) => any;
  onOpenReservationPopup: any;
  onOpenBlockPopup: any;
  onOpenEventPopup?: any;
  children?: React.ReactElement;
  startTime?: string;
  isWidget?: boolean;
  outOfTimeRange?: boolean;
  onOpenOutreach: () => any;
  popoverOpen: boolean;
  onVisibleChanged: (value: boolean) => any;
}

const RClickPopup: FC<IProps> = (props: IProps) => {
  const {
    inReservaton,
    reservedReason,
    scheduleCol,
    onOpenIndividualApptPopup,
    onOpenGroupApptPopup,
    onOpenReservationPopup,
    onOpenEventPopup,
    onOpenBlockPopup,
    children,
    startTime,
    outOfTimeRange,
    onOpenOutreach,
    onVisibleChanged,
    popoverOpen: visible
  } = props;

  const close = () => {
    onVisibleChanged(false);
  };
  return (
    <Popover
      globalOverlay={true}
      overlayClassName="RClickPopup"
      placement="rightTop"
      id="event-type-selection-popover"
      content={
        <EditPopupBody
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ActionRow
            hidden={
              !eventPermission(
                EEventTypeCode.appointment,
                EApptTypeCode.individual
              ).success
            }
            id="individual-appointment"
            onClick={() => {
              close();
              onOpenIndividualApptPopup(
                1,
                inReservaton ? 'reservedAppt' : null,
                reservedReason?.reason,
                scheduleCol,
                startTime,
                scheduleCol.date
              );
            }}
          >
            <ButtonIcon
              disabled={true}
              containerProps={{
                style: {
                  filter: 'none',
                  opacity: 1
                }
              }}
              iconScale={2}
              name={ICONS_LIST.addUser}
            />
            <LabelContainer>Add Individual Appt</LabelContainer>
          </ActionRow>
          <ActionRow
            hidden={
              !eventPermission(EEventTypeCode.appointment, EApptTypeCode.couple)
                .success
            }
            id="couple-appointment"
            onClick={() => {
              close();
              onOpenGroupApptPopup(2);
            }}
          >
            <ButtonIcon
              disabled={true}
              containerProps={{
                style: {
                  filter: 'none',
                  opacity: 1
                }
              }}
              iconScale={2}
              name={ICONS_LIST.usersGroup}
            />
            <LabelContainer>Add Couple Appt</LabelContainer>
          </ActionRow>
          <ActionRow
            hidden={
              !eventPermission(EEventTypeCode.appointment, EApptTypeCode.family)
                .success
            }
            id="family-appointment"
            onClick={() => {
              close();
              onOpenGroupApptPopup(3);
            }}
          >
            <ButtonIcon
              disabled={true}
              containerProps={{
                style: {
                  filter: 'none',
                  opacity: 1
                }
              }}
              name={ICONS_LIST.family}
            />
            <LabelContainer>Add Family Appt</LabelContainer>
          </ActionRow>
          <ActionRow
            hidden={
              !eventPermission(EEventTypeCode.appointment, EApptTypeCode.group)
                .success
            }
            id="group-appointment"
            onClick={() => {
              close();
              onOpenGroupApptPopup(4);
            }}
          >
            <ButtonIcon
              disabled={true}
              containerProps={{
                style: {
                  filter: 'none',
                  opacity: 1
                }
              }}
              name={ICONS_LIST.groupList}
            />
            <LabelContainer>Add Group Appt</LabelContainer>
          </ActionRow>
          <ActionRow
            id="reservation-event"
            hidden={
              !permissionChecking({
                anyClinic: false,
                clinicId: scheduleCol.clinicId,
                moduleId: 1,
                functionId: 4
              }).success
            }
            onClick={() => {
              close();
              onOpenReservationPopup(
                {
                  providerWithSchedules: scheduleCol
                },
                {
                  startTime,
                  date: scheduleCol.date
                }
              );
            }}
          >
            <ButtonIcon
              disabled={true}
              containerProps={{
                style: {
                  filter: 'none',
                  opacity: 1
                }
              }}
              iconScale={2}
              name={ICONS_LIST.calendarReservation}
            />
            <LabelContainer>Add Reservation</LabelContainer>
          </ActionRow>
          <ActionRow
            hidden={!eventPermission(EEventTypeCode.staffAppointment).success}
            id="provider-event"
            onClick={() => {
              close();
              onOpenEventPopup(
                {
                  providerWithSchedules: scheduleCol
                },
                {
                  startTime,
                  date: scheduleCol.date
                }
              );
            }}
          >
            <ButtonIcon
              disabled={true}
              iconScale={1.4}
              containerProps={{
                style: {
                  filter: 'none',
                  opacity: 1
                }
              }}
              name={ICONS_LIST.event}
            />
            <LabelContainer>Add {eventName}</LabelContainer>
          </ActionRow>
          <ActionRow
            hidden={
              !permissionChecking({
                anyClinic: false,
                clinicId: scheduleCol.clinicId,
                moduleId: 1,
                functionId: 4
              }).success
            }
            id="block-event"
            onClick={() => {
              close();
              onOpenBlockPopup(
                {
                  providerWithSchedules: scheduleCol
                },
                {
                  startTime,
                  date: scheduleCol.date
                }
              );
            }}
          >
            <ButtonIcon
              disabled={true}
              containerProps={{
                style: {
                  filter: 'none',
                  opacity: 1
                }
              }}
              iconScale={2}
              name={ICONS_LIST.calendarBlock}
            />
            <LabelContainer>Add Block</LabelContainer>
          </ActionRow>
          <ActionRow
            hidden={!eventPermission(EEventTypeCode.outreach).success}
            id="outreach-event"
            onClick={() => {
              close();
              onOpenOutreach();
            }}
          >
            <ButtonIcon
              disabled={true}
              containerProps={{
                style: {
                  filter: 'none',
                  opacity: 1
                }
              }}
              iconScale={2}
              name={ICONS_LIST.event}
            />
            <LabelContainer>Add Outreach</LabelContainer>
          </ActionRow>
        </EditPopupBody>
      }
      trigger="click"
      open={outOfTimeRange ? false : visible}
      onOpenChange={onVisibleChanged}
    >
      {children}
    </Popover>
  );
};

export default React.memo(RClickPopup);

import styled from 'styled-components';

import { mediaStylesDevice } from 'src/Framework/util/styled';

import { gridGap } from '../Widgets/components/Container/styled';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

const fontsPosition = '8px';
const fontSize = '18px';

const medias = `
    @media ${mediaStylesDevice.tablet} { 
        font-size: 12px;
        line-height: 12px;
    }
    @media ${mediaStylesDevice.laptop} { 
        font-size: 14px;
        line-height: 14px;
    }
    @media ${mediaStylesDevice.laptopL} { 
        font-size: 18px;
        line-height: 18px;
    }
`;

export const OuterWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100% - ${gridGap}px);
  margin-top: ${gridGap}px;
`;

export const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
`;

export const HeaderWrapper = styled.div`
  height: 31px;
  margin: 0 ${gridGap / 2}px;
  border: 1px solid ${globalStyleVariables.borderColor};
  border-bottom: none;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${gridGap / 2}px;
  right: ${gridGap / 2}px;
`;

export const ListRow = styled.div`
  display: flex;
  flex: 1;
`;

export const ListCol = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  position: relative;
  min-height: 64px;
  border: 1px solid ${(props) => props.color};
  background-color: ${(props) => (props.color as string) + '80'};
`;

export const TypeName = styled.div`
  position: absolute;
  top: ${fontsPosition};
  left: ${fontsPosition};
  font-size: ${fontSize};
  line-height: 18px;
  opacity: 0.95;
  text-transform: uppercase;
  word-spacing: 100vw;
  ${medias}
`;

export const PatientsLength = styled.div`
  position: absolute;
  bottom: ${fontsPosition};
  right: ${fontsPosition};
  font-size: ${fontSize};
  line-height: 18px;
  font-weight: 800;
  ${medias}
`;

export const InnerContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const TableauContainer = styled.div`
  max-height: 300px;
  width: 336px;
  display: flex;
  flex-direction: column;
`;

export const TableTitle = styled.div`
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

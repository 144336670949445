import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import SelectContainer from 'src/Framework/Controls/SelectContainer';
import { IStore } from 'src/index';
import ids from 'src/tests-script/pages/counseling/Dashboard/Referrals';

import { getReqPriorities } from 'src/Activities/Counseling/Dashboard/CounselingReferrals/store/ReferralsActions';

interface IOwnProps {
  priorityId: number | null;
  setPriority: (priority: any) => void;
  onChange?: (priority: IReqPriority) => void;
  reset?: boolean;
  withLabel: boolean;
  onModal?: boolean;
  validate?: boolean;
  fontSize?: string;
}

interface IProps extends IOwnProps {
  priorities: IStore['reqOrders']['reqPriorities'];
}

export interface IReqPriority {
  id: number;
  name: string;
  value: number;
  color: string;
  label: any;
}

function ReqPrioritySelect(props: IProps) {
  const [priority, setPriority] = useState<IReqPriority | null>(null);
  const [priorityOptions, setPriorityOptions] = useState<IReqPriority[]>([]);

  const onChangePriority = (priorityId: number) => {
    const priority = priorityOptions.find((item) => item.id === priorityId);
    setPriority(priority!);
    props.setPriority(props.priorities[priorityId + '']);
    if (props.onChange) props.onChange(priority!);
  };

  useEffect(() => {
    if (props.reset) {
      setPriority(null);
    }
  }, [props.reset]);

  useEffect(() => {
    if (props.priorityId !== null && priorityOptions.length > 0) {
      const selected: undefined | IReqPriority = priorityOptions.find(
        (p: IReqPriority) => p.id === props.priorityId
      );
      if (selected) setPriority(selected);
    }
  }, [props.priorityId, priorityOptions]);

  useEffect(() => {
    if (Object.values(props.priorities).length === 0) {
      getReqPriorities();
    }
  }, []);

  useEffect(() => {
    if (props.priorities !== null) {
      const options = Object.values(props.priorities)
        .filter((priority: any) => priority.typeTxnClassId === 8)
        .map((item: any) => {
          return {
            name: item.name,
            color: item.backColor,
            label: item.name,
            value: item.id,
            id: item.id
          };
        });
      setPriorityOptions(options);
    }
  }, [props.priorities]);
  return (
    <SelectContainer
      label={props.withLabel ? 'Priority' : ''}
      type="select"
      star={true}
      inputProps={{
        id: ids.REFERRALS_PRIORITY_SELECTOR,
        style: {
          fontSize: props.fontSize ?? 14
        }
      }}
      options={priorityOptions}
      value={priority?.value}
      onChange={(value: number) => {
        onChangePriority(value);
      }}
      getPopupContainer={
        props.withLabel || props.onModal
          ? (node: any) => node.parentNode
          : undefined
      }
    />
  );
}

ReqPrioritySelect.defaultProps = {
  priorityId: null,
  reset: false,
  setPriority: () => {},
  withLabel: true,
  onModal: false,
  validate: true
};

const mapStateToProps = (state: IStore, ownProps: IOwnProps) => {
  return {
    priorities: state.reqOrders.reqPriorities
  };
};

export default connect(mapStateToProps)(ReqPrioritySelect);

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const PopupOverlay = styled.div`
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;

  .input-label {
    color: #666666;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }

  .title-label {
    font-family: Encode Sans Expanded;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-transform: uppercase;
    color: #666666;
  }

  .loader {
    border: 6px solid rgb(83, 200, 46, 0.1);
    border-radius: 50%;
    border-top: 6px solid ${globalStyleVariables.newBrandingPrimary200};
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1.2s linear infinite; /* Safari */
    animation: spin 1.2s linear infinite;
  }
`;

// interface PopupContentProps {
//   top?: number;
//   left?: number;
// }

export const OuterContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
  height: 100%;
  flex-grow: 1;

  .header {
    height: 88px;
    border-top: solid ${globalStyleVariables.newBrandingPrimary200} 4px;
  }

  .custom-button {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 50px;
    border: 1px solid ${globalStyleVariables.newBrandingPrimary200};
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: ${globalStyleVariables.newBrandingPrimary200};
  }

  .buttonType2 {
    background: rgba(246, 246, 246, 0.7);
    border: none;
  }

  .buttonType3 {
    background: ${globalStyleVariables.newBrandingPrimary200};
    color: #ffffff;
    border: none;
  }

  .buttonType4 {
    background: transparent;
    color: #666666;
    border: none;
  }

  .disabled {
    color: #cccccc;
  }
`;

interface PopupContentProps2 {
  top: number;
  left: number;
  bubbleLR: Boolean;
  bubbleY: number;
  scheduleOneHeight: number;
  negativeTop: boolean;
  disableAbsolute?: boolean;
}

export const GroupPopupContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 840px;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;

  .loader {
    border: 6px solid rgb(83, 200, 46, 0.1);
    border-radius: 50%;
    border-top: 6px solid ${globalStyleVariables.newBrandingPrimary200};
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1.2s linear infinite; /* Safari */
    animation: spin 1.2s linear infinite;
  }
`;

export const PopupContent2 = styled.div<PopupContentProps2>`
  position: fixed;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  z-index: 100;
  width: 600px;
  height: 674px;

  background: #ffffff;
  box-shadow: 0 5px 25px rgba(0, 84, 128, 0.4);
  border-radius: 4px;

  .loader {
    border: 6px solid rgb(83, 200, 46, 0.1);
    border-radius: 50%;
    border-top: 6px solid ${globalStyleVariables.newBrandingPrimary200};
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1.2s linear infinite; /* Safari */
    animation: spin 1.2s linear infinite;
  }

  ${(props) =>
    props.bubbleLR
      ? `&::before {
      content: "";
      position: absolute;
      display: none;
      width: 0;
      border-style: solid;
      top: ${
        props.negativeTop && props.top < props.scheduleOneHeight
          ? `${props.bubbleY + props.top - 7}px`
          : `${props.bubbleY - props.top}px`
      };
      left: -7px;
      border-color: white;
      background-color: white;
      height: 14px;
      width: 14px;
      transform: rotate(-45deg);
    }
  `
      : `&::before {
      content: "";
      position: absolute;
      display: none;
      width: 0;
      border-style: solid;
      top: ${
        props.negativeTop && props.top < props.scheduleOneHeight
          ? `${props.bubbleY + props.top - 7}px`
          : `${props.bubbleY - props.top}px`
      };
      right: -7px;
      bottom: auto;
      border-color: transparent #fff;
    right: -7px;
    border-color: white;
    background-color: white;
    height: 14px;
    width: 14px;
    transform: rotate(-45deg);
    z-index: -1;
    }
  `}
`;

interface RowProps {
  borderTop?: Boolean;
  paddingBottom?: Boolean;
  disableSvg?: Boolean;
}

export const ExpandHeader = styled.div`
  position: relative;
  border-top: ${({ borderTop }: RowProps) =>
    `${borderTop ? 'solid #E6E6E6 0.5px' : 'none'}`};
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: transparent;
`;

export const Row = styled.div`
  border-top: ${({ borderTop }: RowProps) =>
    `${borderTop ? 'solid #E6E6E6 0.5px' : 'none'}`};
  padding: 24px 0 0 0;
  padding-bottom: ${({ paddingBottom }: RowProps) =>
    `${paddingBottom ? '24px' : '0px'}`};
  display: block;
  position: relative;

  .scheduleRecurr {
    cursor: ${({ disableSvg }: RowProps) =>
        `${disableSvg ? 'not-allowed' : 'pointer'}`}
      svg > circle {
      fill: #ffffff;
    }

    svg > path {
      stroke: ${({ disableSvg }: RowProps) =>
        `${disableSvg ? '#666666' : 'rgb(6, 86, 128)'}`};
    }
  }
`;

export const Row2 = styled.div`
  border-top: ${({ borderTop }: RowProps) =>
    `${borderTop ? 'solid #E6E6E6 0.5px' : 'none'}`};
  padding: 16px 24px;
  padding-bottom: ${({ paddingBottom }: RowProps) =>
    `${paddingBottom ? '16px' : '0px'}`};
  display: block;
  position: relative;

  .preview-label-style {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    /* identical to box height, or 16px */

    display: flex;
    align-items: center;

    /* Black / 60% */

    color: #666666;
  }

  .preview-tab-style {
    font-family: Encode Sans Expanded;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    /* Black / 60% */

    color: #666666;
  }
`;

export const Info = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  min-height: 24px;
  line-height: 24px;
  color: #333333;
  overflow: hidden;
  display: block;
  word-wrap: break-word;
`;

export const AlignLeft = styled.div`
  float: left;
`;

export const AlignRight = styled.div`
  float: right;
`;

import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import {
  IEventMember,
  IProviderEvent
} from 'src/Activities/Schedule/Store/Events/types';

export const postEvent = async (event: Partial<IProviderEvent>) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.PROVIDER_EVENT,
      event,
      { withCredentials: true }
    );
    if (response.data.success) {
      return response.data.result;
    }
    return;
  } catch (err) {
    console.error({ err });
    return;
  }
};
export const getEvent = async (eventId: number) => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.PROVIDER_EVENT}?request.id=${eventId}`
    );
    if (response.data.success) {
      return response.data.result.summary[eventId + ''];
    }
    return;
  } catch (err) {
    console.error({ err });
    return;
  }
};
export const deleteEvent = async (eventId: number, isSeries: boolean) => {
  try {
    const response = await APIHandler.AxiosInstance.delete(
      `${ApiUrl.PROVIDER_EVENT}/${eventId}?isSeries=${isSeries}`
    );
    if (response.data.success) {
      return response.data.success;
    }
    return;
  } catch (err) {
    console.error({ err });
    return;
  }
};
export const getEventSeries = async (eventSeriesId: number) => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.PROVIDER_EVENT}?request.appointmentSeriesId=${eventSeriesId}`
    );
    if (response.data.success) {
      return response.data.result.summary[eventSeriesId + ''];
    }
    return;
  } catch (err) {
    console.error({ err });
    return;
  }
};
export const patchEvent = async (event: Partial<IProviderEvent>) => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.PROVIDER_EVENT}/${event.id}`,
      event,
      { withCredentials: true }
    );
    if (response.data.success) {
      const data = response.data.result;
      return data;
    }
    return;
  } catch (err) {
    console.error({ err });
    return;
  }
};
export const patchEventMember = async (eventMember: Partial<IEventMember>) => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.PROVIDER_EVENT_MEMBERS}/${eventMember.id}`,
      eventMember,
      { withCredentials: true }
    );
    if (response.data.success) {
      return response.data.result.summary[eventMember.id + ''];
    }
    return;
  } catch (err) {
    console.error({ err });
    return;
  }
};
export const deleteEventMember = async (memberId: number) => {
  try {
    const response = await APIHandler.AxiosInstance.delete(
      `${ApiUrl.PROVIDER_EVENT_MEMBERS}/${memberId}`,
      { withCredentials: true }
    );
    if (response.data.success) {
      return response.data.success;
    }
    return;
  } catch (err) {
    console.error({ err });
    return;
  }
};

export const getEventsHistory = (providerId: number) => {
  return APIHandler.AxiosInstance.get(
    `${ApiUrl.PROVIDER_EVENT_HISTORY}?providerId=${providerId}`,
    { withCredentials: true }
  );
};

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  border-top: 4px solid ${globalStyleVariables.newBrandingPrimary200};
  flex-grow: 1;
`;

export const LineDivider = styled.div`
  position: absolute;
  width: 1px;
  top: 0;
  bottom: 0;
  left: 48px;
  background-color: ${globalStyleVariables.borderColor};
  z-index: 2;
`;

export const Sidebar = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-right: 1px solid ${globalStyleVariables.borderColor};
  overflow: auto;
`;

export const PatientItemContainer = styled.div<{ active: boolean }>`
  display: flex;
  border-left: 1px solid transparent;
  cursor: pointer;
  margin: 0 0 4px 0;
  ${({ active }) =>
    active &&
    `
        border-left: 1px solid ${globalStyleVariables.green};
        background: #F7F7F7;
    `}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const Item = styled.div`
  padding: 4px 16px;
`;

export const Right = styled.div`
  padding: 22px 20px 20px 20px;
`;

export const Title = styled.div`
  font-size: 20px;
  margin: 0 0 20px 0;

  .patients-number {
    color: ${globalStyleVariables.newBrandingPrimary200};
  }
`;

export const PatientsListContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

export const PatientsListAbsolute = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${globalStyleVariables.borderColor};
  margin: 10px 0;
`;

export const PatientName = styled.div`
  line-height: 16px;
`;

import React, { FC, useState } from 'react';

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { useAppSelector } from 'src/store';

import { ISelect } from 'src/Framework/Controls/SelectContainer/Components/Select';
import SelectContainer from '../../SelectContainer';
import { RiskGroup } from 'src/App/Admin/Pages/TypeRisks/store/types';
import { pickTextColorBasedOnBgColorSimple } from 'src/Framework/util/helps';

import { Container } from './styled';

interface IProps {
  riskGroup: RiskGroup;
  selectProps?: Partial<ISelect>;
  value: any;
  onChange: Function;
}

const Component: FC<IProps> = ({
  selectProps,
  value,
  onChange,
  riskGroup
}: IProps) => {
  const typeRisks = useAppSelector((state) => state.typeRisks.summary);
  const [containerId] = useState(uuidv4());
  const list = _.sortBy(
    typeRisks.filter(
      (v) => v.isActive && v.isEnabled && v.riskGroup === riskGroup
    ),
    (v) => v.order
  );
  const allOptions = typeRisks.map((v) => ({
    title: v.name,
    value: v.id,
    backgroundColor: v.riskColor
  }));
  const options = list.map((v) => ({
    title: v.name,
    value: v.id,
    backgroundColor: v.riskColor
  }));
  const current = value ? typeRisks.find((v) => v.id == value) : null;
  return (
    <Container id={containerId} color={current?.riskColor}>
      <SelectContainer
        label="Risk type"
        type="select"
        value={value}
        onChange={onChange}
        options={options}
        allOptions={allOptions}
        disableDefaultSort={true}
        getPopupContainer={() => document.getElementById(containerId)}
        {...selectProps}
        inputProps={{
          ...selectProps?.inputProps,
          style: {
            color: current?.riskColor
              ? pickTextColorBasedOnBgColorSimple(current?.riskColor)
              : 'black'
          }
        }}
      />
    </Container>
  );
};
export default Component;

import styled from 'styled-components';

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 0 25px 20px 25px;
`;

export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
`;

export const ModalBody = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
`;

export const HorizontalPadding = styled.div`
  padding: 0 24px;
  width: 100%;
`;

export const ButtonSpanPatient = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0 0 1.5rem;
  width: 100%;
`;

export const InfoContact = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    display: flex;
    place-items: center;
  }

  .copy {
    font-weight: bold;
  }
`;


export const StatusContainer = styled.div`
  width: 100%;
  padding: 10px 20px;
`
import styled from 'styled-components';

interface EditPopupContainerProps {
  top: any;
  left: any;
}

export const TempContainer = styled.div`
  position: absolute;
  width: 185px;
  height: 24px;
`;

export const PopupContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 10;
`;

export const EditPopupContainer = styled.div`
  position: absolute;
  left: ${(props: EditPopupContainerProps) => `${props.left}px`};
  top: ${(props: EditPopupContainerProps) => `${props.top}px`};
  padding: 10px;
  width: 340px;
  box-shadow: 0 0 20px rgba(19, 65, 98, 0.36);
  border-radius: 4px;
  background: white;
  z-index: 1000;
`;

export const EditPopupBody = styled.div`
  padding: 10px 0 0 0;
  width: 170px;
  z-index: 50;
  .deleteList {
    color: rgb(243, 8, 31);
  }
`;

export const ActionRow = styled.div`
  position: relative;
  width: 100%;
  height: 42px;
  cursor: pointer;
`;

export const LabelContainer = styled.div`
  position: absolute;
  left: 40px;
  top: 0;
  width: 150px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
`;

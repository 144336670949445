import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { List, Row, Col } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import Breadcrumbs from 'src/Framework/Controls/Breadcrumbs';
import Pagination from 'src/Framework/Controls/Table/Pagination';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import store, { IStore } from 'src/store';

import { loadActivityTab } from 'src/App/ActivityPanel/Store/ActivityActions';
import * as dashboardActions from 'src/Activities/Home/Dashboards/store/Actions';
import { ITaskItem } from 'src/Activities/Home/Tasks/store/types';

import Item from './Item';
import Container from '../components/Container';

import {
  ListContainer,
  ListInnerContainerTasks,
  Footer,
  Main
} from 'src/Activities/Home/Dashboards/styled';

interface IOwnProps {
  isSupervisor?: boolean;
}

interface IProps extends IOwnProps {
  dashboard: IStore['dashboard'];
}

const Component: FC<IProps> = ({ dashboard, isSupervisor }: IProps) => {
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageLength: 50
  });
  const [sort, setSort] = useState<'asc' | 'desc'>('asc');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const { supervisonProvidersIds, provider } = dashboard.filters;
      if (isSupervisor) {
        await dashboardActions.getTasks(supervisonProvidersIds);
      } else if (provider?.providerId) {
        await dashboardActions.getTasks([provider?.providerId]);
      }
      setLoading(false);
    };
    init();
  }, [dashboard.filters.provider, dashboard.filters.supervisonProvidersIds]);

  let allItems: ITaskItem[] = [];
  Object.values(dashboard.tasks.summary)
    .map((v) => v.data)
    .forEach((arr) => {
      allItems = [...allItems, ...arr];
    });
  allItems = allItems.map((v) => ({
    ...v,
    time: new Date(v.dts).getTime()
  }));
  const sortedList = _.orderBy(allItems, 'time', sort);
  const { pageNumber, pageLength } = pagination;
  const paginationList = sortedList.slice(
    pageNumber * pageLength,
    (pageNumber + 1) * pageLength
  );

  const onChangePagination = useCallback((obj: any) => {
    setPagination((pagination) => ({
      ...pagination,
      ...obj
    }));
  }, []);

  const paginationControls = useMemo(() => {
    return {
      pageLength: pageLength,
      startIndex: pageNumber * pageLength,
      total: sortedList.length,
      onChangePage: (pageNumber: number) => {
        onChangePagination({
          pageNumber
        });
      }
    };
  }, [pageNumber, sortedList]);

  const breadcrumbs = [
    {
      id: 0,
      title: `Tasks (${sortedList.length})`,
      onClick: () => {
        loadActivityTab(`/activity/tasks`, store.dispatch);
      }
    }
  ];

  return (
    <Container>
      <Breadcrumbs data={breadcrumbs} />
      {loading ? (
        <AbsoluteLoader />
      ) : (
        <ListContainer>
          <Main>
            <ListInnerContainerTasks
              key={`task-list-${sort}-${pagination.pageNumber}`}
            >
              <List
                rowKey={'uuid'}
                dataSource={paginationList}
                renderItem={(value, index) => (
                  <Item key={`${value.uuid}`} value={value} index={index} />
                )}
              />
            </ListInnerContainerTasks>
          </Main>
          {sortedList && sortedList.length > 0 && (
            <Footer>
              <Row align="middle" gutter={8} style={{ height: 52 }}>
                <Col flex={1}>
                  <Pagination
                    pagination={paginationControls}
                    hideBorder={true}
                  />
                </Col>
                <Col>
                  <ButtonIcon
                    name={
                      sort === 'desc'
                        ? ICONS_LIST.sortDescending
                        : ICONS_LIST.sortAscending
                    }
                    color={globalStyleVariables.brandGray}
                    tooltip={
                      sort === 'desc'
                        ? 'Sort Descending'
                        : sort === 'asc'
                        ? 'Sort Ascending'
                        : ''
                    }
                    isButton={true}
                    label={
                      sort === 'desc'
                        ? 'Sort Descending'
                        : sort === 'asc'
                        ? 'Sort Ascending'
                        : ''
                    }
                    onClick={() => {
                      if (sort === 'asc') {
                        setSort('desc');
                      } else {
                        setSort('asc');
                      }
                    }}
                  />
                </Col>
              </Row>
            </Footer>
          )}
        </ListContainer>
      )}
    </Container>
  );
};

const mapStateToProps = (state: IStore, ownProps: IOwnProps) => {
  return {
    dashboard: state.dashboard
  };
};

export default connect(mapStateToProps)(React.memo(Component));

import React, { FC, useRef, useState, useEffect } from 'react';

import SelectContainer from 'src/Framework/Controls/SelectContainer';
import { IInput } from 'src/Framework/Controls/SelectContainer/Components/Input';
import { debouncer } from 'src/Framework/util/helps';

interface IProps extends Partial<IInput> {}

const DebounceSearch: FC<IProps> = ({ value, onChange, ...props }: IProps) => {
  const debounce = useRef(debouncer(500));
  const [search, setSearch] = useState(value);

  useEffect(() => {
    if (value !== search) {
      setSearch(value);
    }
  }, [value]);

  return (
    <SelectContainer
      label="Search"
      star={false}
      {...props}
      type="input"
      value={search}
      onChange={(search) => {
        setSearch(search);
        debounce.current(() => {
          if (onChange) {
            onChange(search);
          }
        });
      }}
      searchIcon={true}
    />
  );
};

export default React.memo(DebounceSearch);

import React, { FC, useEffect, useRef } from 'react';
import { DragSource } from 'react-dnd';

import { updateDragProgress } from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';

import { ItemTypes } from './ItemTypes';

import { DragSourceStyle, TotalDragSourceStyle } from './ScheduleCol_Styles';
import InteractionMiddleware from './InteractionMiddleware';
import { useInteractionObserver } from 'src/Framework/InteractionObserver/useInteractionObserver';
import { CALENDAR_ZINDEX } from './utils';

export interface Common {
  x: number;
  y: number;
  width: number;
  height: number;
  overflow?: boolean;
  isDragging?: boolean;
  patient?: any;
  lastInReservation?: boolean;
  firstInReservation?: boolean;
  mainScrollContainerRef?: React.RefObject<HTMLDivElement>;
}

interface IProps extends Common {
  patient: any;
  connectDragSource: any;
  connectDragPreview: any;
  isDragging: boolean;
  providerId: number;
  canDrag?: any;
  onStartDrag?: any;
  onEndDrag?: any;
  isRelative?: boolean;
  children?: React.ReactChild;
}

const dragSource = {
  canDrag(props: IProps) {
    return props.canDrag;
  },
  beginDrag(props: IProps, monitor: any) {
    if (props.onStartDrag) {
      props.onStartDrag();
    }
    const item = {
      ...props.patient,
      id: props.patient.appointmentId,
      providerId: props.providerId
    };
    return item;
  },
  endDrag(props: IProps, monitor: any) {
    updateDragProgress(false);
    if (props.onEndDrag) {
      props.onEndDrag();
    }
  }
};

function collect(connect: any, monitor: any) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

export const CellContainer = (
  props: Common & {
    children: React.ReactElement;
    isRelative?: boolean;
    id?: string;
    isFake?: boolean;
  }
) => {
  return (
    <div>
      <InteractionMiddleware {...props} />
    </div>
  );
};

const DragSourceComponent: FC<IProps> = (props: IProps) => {
  const { mainScrollContainerRef } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { isVisible } = useInteractionObserver({
    targetRef: ref,
    root: mainScrollContainerRef
  });

  useEffect(() => {
    updateDragProgress(props.isDragging);
  }, [props.isDragging]);
  const {
    x,
    y,
    width,
    height,
    connectDragSource,
    connectDragPreview,
    isDragging,
    children,
    isRelative,
    patient,
    lastInReservation,
    firstInReservation
  } = props;
  return connectDragPreview(
    <div
      ref={ref}
      style={{
        ...TotalDragSourceStyle(
          x,
          y,
          width,
          height,
          patient,
          lastInReservation,
          firstInReservation
        ),
        zIndex: isDragging ? 100 : CALENDAR_ZINDEX.events,
        position: isRelative ? 'relative' : 'absolute'
      }}
    >
      {isVisible ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            visibility: isDragging ? 'hidden' : 'visible'
          }}
        >
          {connectDragSource(<div style={DragSourceStyle()}>{children}</div>)}
        </div>
      ) : null}
    </div>
  );
};

export default DragSource(
  () => ItemTypes.ScheduleEvent,
  dragSource,
  collect
)(React.memo(DragSourceComponent));

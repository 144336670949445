import * as React from 'react';

import NoData from 'src/Framework/Controls/Table/NoData';

import { NotesLayout } from './styled';

interface IState {}

interface IProps {
  notes: string;
}

class GroupMembersComponent extends React.Component<IProps, IState> {
  render() {
    const { notes } = this.props;
    return notes ? <NotesLayout>{notes}</NotesLayout> : <NoData />;
  }
}

export default GroupMembersComponent;

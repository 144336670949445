import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const PopupHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 88px;
  position: relative;
  border-top: solid ${globalStyleVariables.newBrandingPrimary200} 4px;
`;

export const Container = styled.div`
  position: relative;
  min-height: 500px;
  background-color: white;
  box-sizing: border-box;
  flex-grow: 1;
`;

export const NoteContainer = styled.div`
  display: inline-block;
  width: 100%;

  .Note {
    height: 40px;
    line-height: 40px;
    background: #f7f7f7;
    padding: 0 12px;
  }
`;

export const PopupTitle = styled.div`
  padding: 24px;

  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 40px;

  color: #000000;
`;

export const ErrorBlock = styled.span`
  color: ${globalStyleVariables.errorColor};
  font-family: Lato;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  visibility: ${(props: { error?: any }) =>
    props.error ? 'initial' : 'hidden'};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  .category-label {
    font-weight: bold;
    font-size: 14px;
    color: #333333;
    font-family: Lato;
    font-style: normal;
  }
`;

export const EyeIconContainer = styled.div`
  svg {
    height: 28;
    width: 28;
  }
`;
export const PopupBody = styled.div`
  position: relative;
  min-height: 540px;
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px 0;
  border-top: solid #e6e6e6 0.5px;

  .ant-select-selection {
    align-items: center;
    background-color: #f7f7f7;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    border: none;
    border-radius: unset;
  }

  .error-border {
    border: 1px solid #f9b40b;
  }

  .column-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const BlockReservationTypeContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

export const ClinicSelectContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

export const ProviderSelectContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin-left: 24px;
`;

export const ReasonSelectContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin-right: 24px;
`;

export const SelectionContainer = styled.div`
  vertical-align: top;
  display: inline-block;
  margin-right: 24px;
  width: 168px;

  .rc-calendar-body {
    height: 170px;
  }

  .rc-calendar-date {
    width: 19px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rc-calendar-year-panel-decade-select,
  .rc-calendar-month-panel-year-select {
    width: 120px !important;
  }

  .rc-calendar-decade-panel-decade {
    font-size: 8px;
  }

  .rc-calendar-header .rc-calendar-prev-month-btn {
    position: absolute;
    left: 19px;
  }

  .rc-calendar-header .rc-calendar-next-month-btn {
    position: absolute;
    right: 19px;
  }
`;

export const ReleaseBeforeCheckBoxContainer = styled.div`
  display: inline-block;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
`;

export const DayHourMinSelectionContainer = styled.div`
  display: inline-block;
  margin-left: 20px;
  margin-bottom: 34px;
  width: 72px;

  .input-dayhrmin-editor {
    outline: none;
    border: none;
    background: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 0;
    height: 39px;
    width: 45px;
    text-align: center;
  }
`;

export const Block = styled.div`
  position: relative;
  height: 300px;
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  padding: 0 24px;
`;

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const SetAllContainer = styled.div`
  padding: 10px 20px;
`;

export const SelectDateTimeContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  height: 500px;
`;

export const WeekdayButton = styled.div`
  height: 32px;
  width: 20px;
  padding: 0.5rem;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  color: ${(props: { isSelected?: boolean; isDisabled?: boolean }) =>
    props.isDisabled ? '#f9c23b' : props.isSelected ? '#ffffff' : '#53C82E'};
  border-color: ${(props: { isDisabled?: boolean }) =>
    props.isDisabled ? '#f9c23b' : '#53C82E'};
  background: ${(props: { isSelected?: boolean }) =>
    props.isSelected ? '#53C82E' : '#ffffff'};
  cursor: ${(props: { isDisabled?: boolean }) =>
    props.isDisabled ? 'not-allowed' : 'pointer'};
`;

interface MonthDateButtonProps {
  isSelected: number;
}

export const MonthDateButton = styled.div`
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;
  background-color: ${(props: MonthDateButtonProps) =>
    props.isSelected ? 'rgba(83, 200, 46, 1)' : 'none'};
  color: ${(props: MonthDateButtonProps) =>
    props.isSelected ? '#ffffff' : '#333333'};

  :hover {
    background-color: ${(props: MonthDateButtonProps) =>
      props.isSelected ? 'rgba(83, 200, 46, 1)' : 'rgba(83, 200, 46, 0.3)'};
  }
`;
export const MonthRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface MonthlyContainerProps {
  length: number;
}

export const MonthlyContainer = styled.div`
  .ant-select {
    width: 100% !important;
    background-color: #f7f7f7 !important;
  }

  .ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f7f7f7 !important;
    border: none !important;
    outline: none;
    display: flex;
    align-items: center;
    height: 38px;
    overflow: ${(props: MonthlyContainerProps) =>
      props.length > 5 ? 'scroll' : 'hidden'};
  }

  .ant-select-selection-item {
    background: rgba(83, 200, 46, 0.2) !important;
    border: none !important;
    border-radius: 5px !important;

    .ant-select-selection-item-remove {
      display: none !important;
    }
  }

  .ant-select-selector {
    height: 100%;
    max-height: 94px;
  }
`;

export const ButtonBlock = styled.div`
  display: flex;
`;

export const RepeatContainer = styled.div`
  display: inline-block;
  margin-left: 24px;
  margin-top: 10px;
  width: 100%;
`;

export const SettingsContainer = styled.div`
  padding: 24px 0;

  .settingsLabel {
    margin-left: 24px;
  }
`;

export const ConflictsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 55%;
  height: 100%;
`;

export const ConflictsBody = styled.div`
  border-top: 4px solid #e6e6e6;
  overflow-y: scroll;
  height: calc(100% - 60px);
`;

export const ListItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  border-bottom: 1px solid #e6e6e6;
`;

export const DateSpan = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  svg > path {
    stroke: ${globalStyleVariables.brandGray};
  }

  /* identical to box height, or 114% */

  display: flex;
  align-items: center;

  /* Black / 80% */

  color: #333333;
`;

export const ClosedLabel = styled.span`
  font-size: 14px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  color: #f9b40b;
`;

export const ConflictMessage = styled.div`
  margin: 0 0 0 10px;
`;

export const ConflictsHeader = styled.div`
  border-radius: 8px;
  margin: 1rem;
  padding: 10px;
  background: ${(props: { hasConflicts: any }) =>
    props.hasConflicts === 'true'
      ? '#FFEAEA'
      : globalStyleVariables.newBrandingSuccessBG};
  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #000000;
`;
export const SaveRecurringButtonContainer = styled.div`
  display: inline-block;
  width: 177px;
  height: 40px;
  cursor: pointer;
  margin-right: 3rem;
`;

export const OverrideButtonContainer = styled.div`
  display: inline-block;
  width: 100px;
  height: 40px;
  cursor: pointer;
  margin-right: 3rem;
`;

export const CancelRecurringButtonContainer = styled.div`
  display: inline-block;
  width: 90px;
  height: 40px;
  cursor: pointer;
`;

export const ListContainer = styled.div`
  padding-left: 16px;
  width: 364px;
  max-height: 304px;
  overflow-y: auto;
`;

export const Info = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

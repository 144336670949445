import React, { FC, useEffect, useRef, useState } from 'react';

import { CurrentTimeLine } from './Main_Styles';
import {
  getHourAndMinFormat,
  timeDiff,
  getEndTime
} from 'src/Activities/Schedule/Utils/Utils';
import moment from 'moment';
import { ScheduleTimeProps } from '../Common/Interfaces';

interface IProps {
  currentDateRange: any;
  scheduleTimeProps: ScheduleTimeProps;
}

const Component: FC<IProps> = ({
  currentDateRange,
  scheduleTimeProps
}: IProps) => {
  let showCurrentTimeBar = true;
  if (currentDateRange && currentDateRange.length === 2) {
    const start = moment(currentDateRange[0]).format('MM/DD/YYYY');
    const end = moment(currentDateRange[1]).format('MM/DD/YYYY');
    if (start === end && start === moment().format('MM/DD/YYYY')) {
      showCurrentTimeBar = true;
    } else {
      showCurrentTimeBar = moment().isBetween(start, end);
    }
  }

  const currentTimeRef = useRef(getHourAndMinFormat(new Date(), false));
  const [currentTime, setCurrentTime] = useState(
    getHourAndMinFormat(new Date(), false)
  );

  useEffect(() => {
    currentTimeRef.current = currentTime;
  }, [currentTime]);

  useEffect(() => {
    if (showCurrentTimeBar) {
      const interval = setInterval(function () {
        const currentTime = currentTimeRef.current;
        const nextTime = getEndTime(currentTime, '1');
        setCurrentTime(nextTime);
      }, 1000 * 60);
      return () => {
        clearInterval(interval);
      };
    }
    return () => {};
  }, []);

  const { dayStartTime, slotHeight } = scheduleTimeProps;
  let regularMeetingTime = scheduleTimeProps.regularMeetingTime;
  if (isNaN(regularMeetingTime)) {
    regularMeetingTime = 15;
  }
  let fromTopLen = 0;
  if (regularMeetingTime && dayStartTime) {
    fromTopLen =
      (timeDiff(currentTime, dayStartTime) / regularMeetingTime) * slotHeight;
  }

  return showCurrentTimeBar ? (
    <CurrentTimeLine fromTopLen={fromTopLen} />
  ) : null;
};

export default React.memo(Component);

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 250px;
  max-height: 400px;
  overflow: overlay;
`;

export const Title = styled.div`
  padding: 5px 20px;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: black;
`;

export const Content = styled.div`
  padding: 14px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const FileContainer = styled.div`
  padding: 5px;
  background: #f7f7f7;
  border-radius: 4px;
`;

export const Link = styled.div`
  color: ${globalStyleVariables.newBrandingPrimary200};
`;

export const AttachmentContainer = styled.div`
  position: relative;
  cursor: pointer;
  margin: 5px 0;
`;

import _ from 'lodash'
import store from 'src/store';
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"

import { actionsTypes } from './Reducer'
import { IClinicProvider, IFilter, IProvider, IProviderExtendedDTO, IProviderFacility, IProviderTypeProvider, ISupervisor } from './types'
import { ISort, TempFieldsForTable } from 'src/Framework/Controls/Table/types';
import { RequestHelper } from 'src/Framework/ApiSubscription/RequestHelper';
import { BucketsList } from 'src/Framework/ApiSubscription';

const setProviders = (payload: any) => {
    store.dispatch({
        type: actionsTypes.SET_ALL_PROVIDERS,
        payload
    })
}

export const setProvider = (payload: IProvider & Partial<TempFieldsForTable>) => {
    const list = { ...store.getState().user.providers.summary }
    list[payload.providerId] = payload
    setProviders(list)
}

export const SubscriptionData = () => ({
    bucket: BucketsList.provider,
    filter: `true`
})

export const requests = new RequestHelper<IProvider>({
    link: ApiUrl.GET_ALL_PROVIDERS,
    subscription: {
        subscriptionData: SubscriptionData,
        setSummary: setProviders,
        parseMethod: (data) => {
            const obj = {}
            Object.values(data).forEach((v) => {
                obj[v.providerId] = v
            })
            return obj
        }
    }
})

export const deleteProviderTypeProviders = async (providerId: number, providerTypeId: number): Promise<boolean | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.delete(`${ApiUrl.GET_ALL_PROVIDERS}/${providerId}/typeProviders/${providerTypeId}`, {
            withCredentials: true
        })
        if (response.data) {
            if (response.data.success) {
                const data = response.data.success
                return data
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const putProviderTypeProviders = async (providerId: number, providerTypeId: number): Promise<boolean | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.put(`${ApiUrl.GET_ALL_PROVIDERS}/${providerId}/typeProviders/${providerTypeId}`, {}, {
            withCredentials: true
        })
        if (response.data) {
            if (response.data.success) {
                const data = response.data.success
                return data
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const getProviderTypeProviders = async (providerId: number): Promise<IProviderTypeProvider[] | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(`${ApiUrl.GET_ALL_PROVIDERS}/${providerId}/typeProviders`, {
            withCredentials: true
        })
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result.summary
                return data
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

const setFacilities = (payload: any) => {
    store.dispatch({
        type: actionsTypes.SET_FACILITIES,
        payload
    })
}

export const putProviderFacility = async (providerId: number, data: IProviderFacility[]) => {
    try {
        const response = await APIHandler.AxiosInstance.put(`${ApiUrl.GET_ALL_PROVIDERS}/${providerId}/facilities`, data, {
            withCredentials: true
        })
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result.summary
                return data
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const getProviderFacility = async (providerId: number): Promise<Record<string, IProviderFacility> | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(`${ApiUrl.GET_ALL_PROVIDERS}/${providerId}/facilities`, {
            withCredentials: true
        })
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result.summary
                return data
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const getFacility = async () => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.FACILITY, {
            withCredentials: true
        })
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result.summary
                setFacilities(data)
                return data
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}


export const postClinicProviders = async (data: IClinicProvider[]): Promise<IClinicProvider | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(ApiUrl.CLINIC_PROVIDERS, data, {
            withCredentials: true
        })
        if (response.data) {
            if (response.data.success) {
                const data: any = Object.values(response.data.result.summary)[0]
                return data
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const getClinicProviders = async ({
    clinicIds,
    providerIds
}: {
    clinicIds?: number[],
    providerIds: number[]
}) => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.CLINIC_PROVIDERS, {
            withCredentials: true, params: {
                clinicIds: clinicIds?.join(),
                providerIds: providerIds.join()
            }
        })
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result.summary
                return data
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const setFilter = (payload: Partial<IFilter>) => {
    store.dispatch({
        type: actionsTypes.SET_FILTER,
        payload
    })
}

const setLoading = (payload: boolean) => {
    store.dispatch({
        type: actionsTypes.SET_LOADING,
        payload
    })
}


export const getAllproviders = async () => {
    try {
        return
        const response = await APIHandler.AxiosInstance.get(ApiUrl.GET_ALL_PROVIDERS, { withCredentials: true })
        if (response.data) {
            if (response.data.success) {
                setProviders(response.data.result.summary)
                return response.data.result.summary
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}


export const getProviders = async (arr: number[]) => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.GET_ALL_PROVIDERS, {
            withCredentials: true, params: {
                ids: arr.join(),
                format: 'extended'
            }
        })
        if (response.data) {
            if (response.data.success) {
                const data = Object.values(response.data.result.summary)[0]
                if (data) {
                    return data
                }
            }
        }
        return;
    } catch (e) {
        return
    } finally {
        setLoading(false)
    }
}

export const patch = async (data: Partial<IProvider> & { providerId: number }) => {
    try {
        const response = await APIHandler.AxiosInstance.patch(`${ApiUrl.GET_ALL_PROVIDERS}/${data.providerId}`, data, {
            withCredentials: true
        })
        if (response.data) {
            if (response.data.success) {
                const provider: any = Object.values(response.data.result.summary)[0]
                setProvider(provider)
                return provider
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const post = async (data: Partial<IProvider>): Promise<IProviderExtendedDTO | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(ApiUrl.GET_ALL_PROVIDERS, data, {
            withCredentials: true
        })
        if (response.data) {
            if (response.data.success) {
                const provider: any = Object.values(response.data.result.summary)[0]
                setProvider(provider)
                return provider
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

const setSupervisorsData = (payload: Record<number, ISupervisor>) => {
    store.dispatch({
        type: actionsTypes.SET_SUPERVISOR,
        payload
    })
}

const onAddSupervisor = (payload: ISupervisor) => {
    const data = store.getState().user.providers.supervisor
    const i = data.findIndex((v) => _.isEqual(payload, v))
    if (i === -1) {
        data.push(payload)
    }
    setSupervisorsData(data)
}

const onRemoveSupervisor = (payload: ISupervisor) => {
    const data = store.getState().user.providers.supervisor
    const i = data.findIndex((v) => _.isEqual(payload, v))
    if (i !== -1) {
        data.splice(i, 1)
    }
    setSupervisorsData(data)
}

export const getSupervisor = async () => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.ClinicProviderHierarchies, {
            withCredentials: true
        })
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result.summary
                setSupervisorsData(data)
                return data
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const postSupervisor = async (data: Partial<ISupervisor>) => {
    try {
        const response = await APIHandler.AxiosInstance.post(ApiUrl.ClinicProviderHierarchies, data, {
            withCredentials: true,
            interceptError: true

        })
        if (response.data) {
            if (response.data.success) {
                const data: ISupervisor[] = response.data.result.summary
                data.forEach((v) => onAddSupervisor(v))
                return data
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const deleteSupervisor = async (data: ISupervisor) => {
    try {
        const response = await APIHandler.AxiosInstance.delete(ApiUrl.ClinicProviderHierarchies, {
            withCredentials: true,
            interceptError: true,
            params: data
        })
        if (response.data) {
            if (response.data.success) {
                onRemoveSupervisor(data)
                return true
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const onChangeSort = (payload: ISort) => {
    store.dispatch({
        type: actionsTypes.SET_SORT,
        payload
    })
}
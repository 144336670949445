import React from 'react';
import { connect } from 'react-redux';
import { Input as AntdInput, Space } from 'antd';
import { AbstractTooltipProps } from 'antd/es/tooltip';
import moment from 'moment';
import * as _ from 'lodash';
import classnames from 'classnames';

import { SearchIconSvg } from 'src/Framework/Common/Svg/SearchIconSvg';
import Popover from 'src/Framework/Controls/Popover';
import Spinner from 'src/Framework/Controls/AbsoluteLoader/Spinner';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { patientDisplayName } from 'src/Framework/util/format';
import { IStore } from 'src/store';

import { getSubjectSearch } from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import Input from 'src/Activities/Schedule/Common/Input/Input';
import SubjectTabulator from './SubjectTabulator';
import SubjectTag from './SubjectTag';
import AgeModal, {
  IModalHandles as IAgeModalHandles
} from 'src/Activities/Subject/AgeModal';

import { InputWrapper, TagsContainer } from './SubjectSearch_styles';

interface IState {
  searchData: any;
  searchText: string;
  selected: any[];
  isPopoverOpen: boolean;
  waitFor: any;
  originalSelection: any[];
  loading: boolean;
}

export interface IPatient {
  dob: string;
  firstName: string;
  highRisk: boolean;
  id: number;
  inactive: boolean;
  lastName: string;
  middleName: null | string;
  nickName: string;
  otherId: string;
  phoneNo: string;
  pictureFileDefinitionId: number;
  preferredName: string;
  sex: string;
  subjectId: number;
}

export interface ISubjectTag {
  subjectId: number;
  subjectName?: string;
}

interface IProps {
  align: any;
  isMulti: boolean;
  maxSelectionCount?: number;
  onSelectionChanged?: (patients: IPatient[]) => any;
  onFinishSelection?: any;
  contentLocation?: any;
  showSelectionName: boolean;
  selected?: any;
  tagsModeSelected?: ISubjectTag[];
  tagsModeRemoveTag?: (subjectId: number) => void;
  placeHolder: any;
  autoSearch: boolean;
  className?: string;
  newInput?: boolean;
  minorAlert?: any;
  parentId?: string;
  disablePatientAge?: boolean;
  modalHomeId?: string;
  label?: string;
  required?: boolean;
  clearButton?: boolean;
  presentationMaxWidth?: number;
  disableAutoAdjustOverflow?: boolean;
  filterPatients?: (value: IPatient) => boolean;
  disabled?: boolean;
  placement?: AbstractTooltipProps['placement'];
  columnsToShow?: string[];
  containerWidth?: number;
}

type TCallback = () => void;

const ageUnderRule = (dob: any, limit: number) => {
  const birthday = moment(dob, 'MM/DD/YYYY');
  const age = moment().diff(birthday, 'year');
  return age < limit;
};

const subjectSearchInputId = 'search-input';

class SubjectSearch extends React.Component<IProps, IState> {
  static defaultProps = {
    placeHolder: 'Search'
  };
  public popoverRef: React.MutableRefObject<any>;

  onSearchRecipientsThrottled: any;
  onSearchRecipientsDebounced: any;
  modalRef = React.createRef<IAgeModalHandles>();

  constructor(props: IProps) {
    super(props);
    this.popoverRef = React.createRef();
    let selected: any[] = [];
    if (this.props.selected) {
      selected = [...this.props.selected];
    }
    this.state = {
      searchData: [],
      searchText: '',
      selected: selected,
      isPopoverOpen: false,
      waitFor: '',
      originalSelection: selected,
      loading: false
      //isActive: false,
    };
    this.onSearchRecipientsThrottled = _.throttle(this.onSearchRecipients, 500);
    this.onSearchRecipientsDebounced = _.debounce(this.onSearchRecipients, 500);
    this.onSelectionChangedHandler = this.onSelectionChangedHandler.bind(this);
    this.onFinishSelectionHandler = this.onFinishSelectionHandler.bind(this);
    this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
    this.handleOpenAgeModal = this.handleOpenAgeModal.bind(this);
    this.selectStudent = this.selectStudent.bind(this);
  }

  selectStudent = (subjectData: IPatient, handleOpenAgeModal: TCallback) => {
    if (
      ageUnderRule(subjectData.dob, 18) &&
      (!this.props.minorAlert || this.props.minorAlert?.value === 'True')
    )
      handleOpenAgeModal();
  };

  changeQuery = (event: any) => {
    if (this.props.autoSearch) {
      this.setState({ searchText: event.target.value }, () => {
        const q = this.state.searchText;
        this.setState({ waitFor: q });
        // if (q.length > 2) {
        //   this.onSearchRecipientsThrottled(q);
        // } else {
        this.onSearchRecipientsDebounced(q);
        // }
      });
    } else {
      this.setState({ searchText: event.target.value });
    }
  };

  onSearchRecipients = async (value: any) => {
    let opened = false;
    if (value && value.length > 0) {
      try {
        this.setState({ loading: true });
        const response = await getSubjectSearch(value, 0, '', 20);
        if (response.data.success) {
          let result = response.data.result.summary;
          let data: any = [];
          result.map((m: any, index: number) => {
            data.push({
              ...m,
              id: index + 1,
              firstName: m.firstName,
              lastName: m.lastName,
              preferredName: m.nickName ? m.nickName : '',
              sex: m.sex,
              otherId: m.otherId ? `${m.otherId}` : '',
              dob: moment(m.dob).format('MM/DD/YYYY'),
              subjectId: m.subjectId
            });
          });
          if (value === this.state.waitFor) {
            if (
              data.length === 1 &&
              !this.props.isMulti &&
              !this.props.autoSearch
            ) {
              opened = false;
              this.setState({
                searchData: data
              });
              this.onFinishSelectionHandler(data);
            } else {
              opened = true;
              this.setState({
                searchData: data,
                isPopoverOpen: true,
                originalSelection: _.cloneDeep(this.state.selected)
              });
            }
          }
        }
      } catch (e) {}
    }
    this.setState({
      isPopoverOpen: opened,
      originalSelection: _.cloneDeep(this.state.selected),
      loading: false
    });
  };

  handleOpenAgeModal() {
    if (this.props.disablePatientAge) return;
    if (this.modalRef.current) {
      //@ts-ignore
      this.modalRef.current.show();
    }
  }

  onSelectionChangedHandler(selection: any) {
    if (!this.props.isMulti && selection && selection.length > 0) {
      this.setState({
        isPopoverOpen: false,
        selected: selection
      });
    }
    this.setState({
      selected: selection
    });
    if (!this.props.isMulti && this.props.onSelectionChanged) {
      this.props.onSelectionChanged(selection);
    }
    if (Array.isArray(selection) && selection.length === 0) {
      this.focusHome();
    }
  }

  onFinishSelectionHandler(selection: any) {
    if (selection.length > 0) {
      this.selectStudent(selection[0], this.handleOpenAgeModal);
    }
    if (this.props.onSelectionChanged) {
      this.props.onSelectionChanged(selection);
    }
    if (this.props.onFinishSelection) {
      this.props.onFinishSelection(selection);
    }
    this.setState({
      isPopoverOpen: false,
      searchText: '',
      selected: selection
    });
    this.focusHome();
  }

  focusHome = () => {
    const homeTagId = this.props.modalHomeId || subjectSearchInputId;
    const homeTag = document.getElementById(homeTagId);
    if (homeTag) {
      homeTag.focus();
    }
  };

  onCancelSelectionHandler = () => {
    if (this.props.onSelectionChanged) {
      this.props.onSelectionChanged(this.state.originalSelection);
    }
    if (this.props.onFinishSelection) {
      this.props.onFinishSelection(this.state.originalSelection);
    }
    this.setState({
      isPopoverOpen: false,
      searchText: '',
      selected: this.state.originalSelection
    });
    this.focusHome();
  };

  onKeyPressHandler(event: any) {
    if (event.key === 'Enter') {
      this.onSearchRecipients(this.state.searchText);
      this.setState({ waitFor: this.state.searchText });
    }
    if (event.key === 'Tab' && this.state.isPopoverOpen) {
      event.preventDefault();
      if (this.popoverRef.current) {
        this.popoverRef.current.focus();
      }
    }
    if (event.key === 'Escape' && this.state.isPopoverOpen) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({
        isPopoverOpen: false
      });
    }
  }

  onClick = () => {
    let { isMulti } = this.props;
    let { isPopoverOpen, selected } = this.state;
    let newPopoverOpen =
      isMulti && selected && selected.length > 0 ? true : isPopoverOpen;
    if (newPopoverOpen && !isPopoverOpen) {
      this.setState({ originalSelection: _.cloneDeep(selected) });
    }
    this.setState({
      searchText: '',
      isPopoverOpen: newPopoverOpen
    });
  };

  componentDidUpdate(prevProps: IProps) {
    if (!_.isEqual(this.props.selected, prevProps.selected)) {
      this.setState({
        selected: this.props.selected
      });
    }
  }

  render() {
    let { isPopoverOpen, selected, searchData, loading } = this.state;
    let data: IPatient[] = [];
    const {
      // contentLocation,
      placeHolder,
      align,
      isMulti,
      showSelectionName,
      newInput,
      label,
      clearButton,
      presentationMaxWidth,
      disableAutoAdjustOverflow,
      filterPatients
    } = this.props;
    let searchText = this.state.searchText;
    let selectionText = getPatientTerm(placeHolder);
    if (showSelectionName)
      if (selected && selected[0] && !isMulti) {
        selectionText = patientDisplayName(selected[0]);
      }
    if (selected && isMulti) {
      searchData.map((m: any, index: number) => {
        let indexL = -1;
        indexL = selected.findIndex(
          (item: any) => item.subjectId === m.subjectId
        );
        if (indexL === -1 || indexL === null) {
          data.push(m);
        }
      });
    } else {
      data = searchData;
    }
    if (filterPatients) {
      data = data.filter((v) => filterPatients(v));
    }
    return (
      <>
        <Popover
          id=""
          open={isPopoverOpen}
          placement={this.props?.placement || 'bottomLeft'}
          getPopupContainer={
            this.props.parentId
              ? () => document.getElementById(this.props.parentId!)!
              : undefined
          }
          disableAutofocus={true}
          autoAdjustOverflow={disableAutoAdjustOverflow ? false : true}
          globalOverlay={true}
          align={align}
          overlayClassName="popover-search-patient"
          onClickOutSide={() =>
            this.setState({ isPopoverOpen: isMulti && isPopoverOpen })
          }
          // containerClassName="medicat-popover"
          // windowBorderPadding={20}
          content={
            <SubjectTabulator
              popupRef={this.popoverRef}
              isMulti={isMulti}
              data={data}
              maxSelectionCount={this.props.maxSelectionCount}
              selected={selected}
              onSelectionChanged={this.onSelectionChangedHandler}
              onFinishSelection={this.onFinishSelectionHandler}
              onCancelSelection={this.onCancelSelectionHandler}
              columnsToShow={this.props.columnsToShow}
              containerWidth={this.props.containerWidth}
              searchText={searchText}
            />
          }
        >
          {newInput ? (
            <InputWrapper>
              {searchText === '' && (
                <span
                  role="presentation"
                  className={classnames({
                    pleceholder_text: selectionText === 'Search'
                  })}
                  style={{
                    maxWidth: presentationMaxWidth
                  }}
                >
                  {selectionText}
                </span>
              )}
              <AntdInput
                autoComplete="off"
                id={this.props.modalHomeId || subjectSearchInputId}
                aria-required={this.props.required}
                aria-label={
                  selectionText === 'Search'
                    ? `search ${getPatientTerm('patient')}`
                    : `selected ${getPatientTerm(
                        'patient'
                      )} is ${selectionText}`
                }
                prefix={<SearchIconSvg />}
                // placeholder={selectionText}
                // onKeyPress={this.onKeyPressHandler}
                onKeyDown={this.onKeyPressHandler}
                value={searchText}
                onClick={this.onClick}
                onChange={this.changeQuery}
                className={classnames({
                  [this.props.className as string]: true,
                  ['pleceholder_text']: selectionText === 'Search'
                })}
                suffix={
                  loading ? (
                    <Spinner size={16} border={1} />
                  ) : clearButton && selected && selected.length > 0 ? (
                    <ButtonIcon
                      isButton={true}
                      label={'clear selection'}
                      disableBackground={true}
                      name={ICONS_LIST.closeCircle}
                      size={20}
                      color={'rgba(0, 0, 0, 0.5)'}
                      onClick={() => {
                        this.onSelectionChangedHandler([]);
                      }}
                    />
                  ) : (
                    <div style={{ width: 16 }} />
                  )
                }
                disabled={this.props.disabled}
              />
              {this.props.tagsModeSelected &&
                this.props.tagsModeSelected.length > 0 && (
                  <TagsContainer
                    tabIndex={0}
                    aria-label={`selected ${getPatientTerm('clients')}`}
                    role="list"
                  >
                    <Space size="small">
                      {this.props.tagsModeSelected.map((tag: ISubjectTag) => (
                        <SubjectTag
                          key={tag.subjectId}
                          subject={tag}
                          onRemove={this.props.tagsModeRemoveTag}
                        />
                      ))}
                    </Space>
                  </TagsContainer>
                )}
            </InputWrapper>
          ) : (
            <Input
              disabled={this.props.disabled}
              autoComplete="off"
              id={this.props.modalHomeId || subjectSearchInputId}
              prefix={<SearchIconSvg />}
              style={{
                backgroundColor: '#f7f7f7',
                fontFamily: 'Lato',
                fontSize: 16,
                overflow: 'auto'
              }}
              aria-label={label ?? 'search'}
              placeholder={this.props.placeHolder}
              // placeholder={selectionText}
              // onKeyPress={this.onKeyPressHandler}
              onKeyDown={this.onKeyPressHandler}
              value={searchText}
              onClick={this.onClick}
              onChange={this.changeQuery}
              className={this.props.className}
            />
          )}
        </Popover>
        <AgeModal ref={this.modalRef} />
      </>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: IProps) => {
  return {
    minorAlert: state.user.preferences.minorAlert
  };
};

export default connect(mapStateToProps)(SubjectSearch);

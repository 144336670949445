import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Body = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 20px 24px;
  width: 35%;
`;

export const Label = styled.div`
  font-size: 14px;
`;

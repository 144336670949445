import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import store from 'src/store';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

import { actionsTypes } from './Reducer';

import { IDashboardPatientDto, IFilter, IRisksFilter } from './types';

import { ITodoItem } from '../../Todos/store/types';
import { getCurrentClinicId } from 'src/Framework/Controls/Selectors/ClinicSelector';

export const setActiveDashboard = (dashboard: string) => {
  store.dispatch({
    type: actionsTypes.SET_ACTIVE_DASHBOARD,
    payload: dashboard
  });
};

export const setActiveRiskDashboard = (dashboard: number) => {
  store.dispatch({
    type: actionsTypes.SET_ACTIVE_RISK,
    payload: dashboard
  });
};

export const setSort = (sortType: string, payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_SORT,
    sortType,
    payload
  });
};

export const setFilter = (filterType: keyof IFilter, payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_FILTERS,
    filterType,
    payload
  });
};

export const setRisksFilters = (payload: Partial<IRisksFilter>) => {
  store.dispatch({
    type: actionsTypes.SET_RISKS_FILTERS,
    payload
  });
};

const setTasks = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_TASK,
    payload
  });
};

const setTodos = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_TODOS,
    payload
  });
};

const setMyWatchList = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_MY_WATCH_LIST,
    payload
  });
};

const setSupervisorMyWatchList = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_SUPERVISOR_MY_WATCH_LIST,
    payload
  });
};

const setStudentsOfConcern = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_STUDENTS_OF_CONCERN,
    payload
  });
};

const setStudentsOnHighRisk = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_STUDENTS_ON_HIGH_RISK,
    payload
  });
};

const setCrisisAppointments = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_CRISIS_APPOINTMENTS,
    payload
  });
};

export const getTasks = async (providerIds?: number[]) => {
  try {
    const { clinicId } = store.getState().user.current;
    const { preferences } = store.getState().user;
    const ids =
      providerIds && providerIds.length !== 0
        ? providerIds.join()
        : preferences.userProviderId?.value;
    const response = await APIHandler.AxiosInstance.get(`${ApiUrl.GET_TASKS}`, {
      withCredentials: true,
      params: {
        providerIds: ids,
        clinicIds: [clinicId]
      }
    });
    if (response.data.success) {
      const data = response.data.result.summary;
      Object.keys(data).forEach((key) => {
        data[key].data.forEach((v: any) => {
          v.uuid = uuidv4();
        });
      });
      setTasks(data);
      return data;
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const getTodos = async (daySelected?: number) => {
  try {
    const providerId = store.getState().dashboard.filters.provider?.providerId;
    const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
    const yesterday = moment().add(-1, 'days').format('YYYY-MM-DD');
    const response = await APIHandler.AxiosInstance.get(`${ApiUrl.GET_TODOS}`, {
      withCredentials: true,
      params: {
        providerIds: providerId,
        dateFrom: yesterday,
        dateTo: tomorrow
      }
    });
    if (response.data.success) {
      const data = response.data.result.summary;
      setTodos(data);
      return data;
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

const onChangeTodoItem = (payload: ITodoItem) => {
  const summary = store.getState().dashboard.todos;
  const index = summary.findIndex((v) => v.id === payload.id);
  if (index !== -1) {
    summary[index] = { ...payload };
  } else {
    summary.unshift({ ...payload });
  }
  setTodos(summary);
};

export const patchTodo = async (body: Partial<ITodoItem>) => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.GET_TODOS}/${body.id}`,
      body,
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data.success) {
      const item: any = response.data.result.summary[0];
      onChangeTodoItem(item);
      return item;
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const getSupervisorMyWatchList = async (providerIds: Array<number>) => {
  try {
    const { clinicId } = store.getState().user.current;
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.GET_PATIENTS}`,
      {
        withCredentials: true,
        params: {
          assignedToProviderIds: providerIds.join(),
          assignedToProviderOnClinicId: clinicId,
          onAssignedToProviderWatchlist: true
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      setSupervisorMyWatchList(_.values(data));
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const getActiveReferrals = async (providerIds: number[], clinicId: number, typeReqPriorityIds: number[], typeTxnClassIds: number[], typeReqStatusIds: number[]) => {
  try {
    const response = await APIHandler.AxiosInstance.get(ApiUrl.REQ_ORDER, {
      withCredentials: true, params: {
        clinicId,
        typeReqPriorityIds,
        typeTxnClassIds,
        typeReqStatusIds,
        renderingProviderIds: providerIds
      }
    })
    if (response.data.result && response.data.result.summary) {
      return response.data.result.summary
    }
  } catch (error) {
    console.log(error);
    return
  }
};

interface IGetMyWatchList {
  providerIds: number[]
  clinicId: number
}

export const getMyWatchList = async ({ providerIds, clinicId }: IGetMyWatchList) => {
  try {
    if (providerIds.length === 0) {
      return []
    }
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.GET_PATIENTS}`,
      {
        withCredentials: true,
        params: {
          assignedToProviderIds: providerIds.join(','),
          assignedToProviderOnClinicId: clinicId,
          onAssignedToProviderWatchlist: true
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      return _.values(data)
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const getMyWatchListForRisks = async ({
  providersIds,
  clinicId
}: {
  providersIds: number[];
  clinicId: number | null;
}) => {
  try {
    if (providersIds.length === 0 || !clinicId) {
      return setMyWatchList([]);
    }
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.GET_PATIENTS}`,
      {
        withCredentials: true,
        params: {
          assignedToProviderIds: providersIds.join(','),
          assignedToProviderOnClinicId: clinicId,
          onAssignedToProviderWatchlist: true
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      setMyWatchList(_.values(data));
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

const setProviderDashboardRisks = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_PROVIDER_DASHBOARD_RISKS,
    payload
  });
};

export const getProviderDashboardRisks = async (providerId: number) => {
  try {
    const clinicId = getCurrentClinicId();
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.GET_PATIENTS}`,
      {
        withCredentials: true,
        params: {
          assignedToProviderIds: [providerId],
          assignedToProviderOnClinicId: clinicId,
          onAssignedToProviderWatchlist: false
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      setProviderDashboardRisks(_.values(data));
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

const setCaseManagementRisks = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_CASE_MANAGEMENT_DASHBOARD_RISKS,
    payload
  });
};

export const getCaseManagementRisks = async (providerId: number) => {
  try {
    const clinicId = getCurrentClinicId();
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.GET_PATIENTS}`,
      {
        withCredentials: true,
        params: {
          assignedToProviderIds: [providerId],
          assignedToProviderOnClinicId: clinicId,
          onAssignedToProviderWatchlist: false
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      setCaseManagementRisks(_.values(data));
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

interface IGetAssignedClients {
  providerIds: number[]
  clinicId: number
}

export const getAssignedClients = async ({ clinicId, providerIds }: IGetAssignedClients): Promise<IDashboardPatientDto[] | undefined> => {
  try {
    if (!clinicId || providerIds.length === 0) {
      return []
    }
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.GET_PATIENTS}`,
      {
        withCredentials: true,
        params: {
          assignedToProviderIds: providerIds,
          assignedToProviderOnClinicId: clinicId,
          onAssignedToProviderWatchlist: false
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      const values = _.values(data)
      return values
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const getStudentsOfConcern = async ({
  clinicId
}: {
  clinicId: number | null;
}) => {
  try {
    if (!clinicId) {
      return setStudentsOfConcern([]);
    }
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.GET_PATIENTS}`,
      {
        withCredentials: true,
        params: {
          isPatientOfConcernInClinicId: clinicId
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      setStudentsOfConcern(_.values(data));
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const getStudentsOnHighRisk = async ({
  providersIds,
  clinicId
}: {
  providersIds: number[];
  clinicId: number | null;
}) => {
  try {
    if (providersIds.length === 0 || !clinicId) {
      return setStudentsOnHighRisk([]);
    }
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.GET_PATIENTS}`,
      {
        withCredentials: true,
        params: {
          assignedToProviderIds: providersIds.join(','),
          assignedToProviderOnClinicId: clinicId,
          latestRiskIsHighest: true
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      setStudentsOnHighRisk(_.values(data));
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const getCrisisAppointments = async ({
  providersIds,
  clinicId,
  dateRange
}: {
  providersIds: number[];
  clinicId: number | null;
  dateRange: [string | null, string | null];
}) => {
  try {
    if (
      providersIds.length === 0 ||
      !clinicId ||
      !dateRange[0] ||
      !dateRange[1]
    ) {
      return setCrisisAppointments([]);
    }
    const { appointmentType } = store.getState().dashboard.filters;

    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.GET_PATIENTS}`,
      {
        withCredentials: true,
        params: {
          assignedToProviderIds: providersIds,
          assignedToProviderOnClinicId: clinicId,
          latestAppointmentFrom: dateRange[0],
          latestAppointmentTo: dateRange[1],
          latestAppointmentIsCrisis: true,
          appointmentReasonIds: appointmentType ? [appointmentType] : []
        }
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      setCrisisAppointments(_.values(data));
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

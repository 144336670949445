import React, { FC, useState } from 'react';
import { Input, Row, Col, Tag } from 'antd';

import SelectContainer from '../../SelectContainer';
import Spinner from '../../AbsoluteLoader/Spinner';
import ButtonIcon, { ICONS_LIST } from '../../ButtonIcon';

import { Container, TagsContainer } from './styled';
import { debouncer } from 'src/Framework/util/helps';

import * as actions from './Actions';
import { IMajor } from './types';

interface IProps {
  value: string[];
  onChange: (values: string[]) => any;
}

const debounce = debouncer(1000);

const Component: FC<IProps> = ({ value, onChange }: IProps) => {
  const [majors, setMajors] = useState<IMajor[]>([]);
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const getOptions = (search: string) => {
    debounce(async () => {
      setLoading(true);
      const res = await actions.get(search);
      if (res) {
        setMajors(res);
      } else {
        setMajors([]);
      }
      setLoading(false);
    });
  };
  return (
    <Container>
      <SelectContainer
        star={false}
        label="Majors"
        type="auto-complete"
        value={search}
        onChange={(v) => {
          const exist = value.includes(v);
          if (!exist) {
            onChange([...value, v]);
            setSearch('');
          }
        }}
        onSearch={(search) => {
          getOptions(search);
          setSearch(search);
        }}
        options={majors.map((v) => ({
          label: v.value,
          value: v.value
        }))}
        bottomMargin={false}
        inputProps={{
          searchValue: search,
          children: (
            <Input
              value={search}
              suffix={
                <Row align="middle" gutter={8}>
                  <Col>{loading && <Spinner size={18} />}</Col>
                  <Col>
                    {search.length > 0 && (
                      <ButtonIcon
                        isButton={true}
                        label="Add item"
                        name={ICONS_LIST.add}
                        size={22}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          const exist = value.includes(search);
                          if (!exist) {
                            setSearch('');
                            onChange([...value, search]);
                          }
                        }}
                      />
                    )}
                  </Col>
                </Row>
              }
            />
          )
        }}
      />
      <TagsContainer>
        {value.map((v, index) => (
          <Tag
            key={v}
            closable={true}
            onClose={() => {
              const arr = [...value];
              arr.splice(index, 1);
              onChange(arr);
            }}
          >
            {v}
          </Tag>
        ))}
      </TagsContainer>
    </Container>
  );
};

export default Component;

import React, {
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef
} from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';

import ElementTitle from 'src/Framework/Controls/ElementTitle';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import ChartButton from 'src/Framework/Controls/PatientPagesButtons/ChartButton';
import FormsButton from 'src/Framework/Controls/PatientPagesButtons/FormsButton';
import InfoButton from 'src/Framework/Controls/PatientPagesButtons/InfoButton';
import NoteButton from 'src/Framework/Controls/PatientPagesButtons/NoteButton';
import Button from 'src/Framework/Controls/Button';
import ReasonSelector from 'src/Framework/Controls/Selectors/ReasonSelector';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import { patientDisplayName } from 'src/Framework/util/format';
import { formattedDate, formattedTime } from 'src/Framework/Shared/Shared';
import test_ids from 'src/tests-script/pages/Schedule/RemovePatientPopover';

import { ICommon } from './types';

import {
  Container,
  Top,
  Header,
  Main,
  Bottom,
  Name,
  SubTitle,
  BlockContainer,
  Info
} from './styled';

const noteAreaInputProps = {
  rows: 6
};
const removeColorStyles = {
  redBorder: true
};
const cancelColorStyles = {
  blueBorder: true
};

interface IProps extends ICommon {
  onChangeOpen: (open: boolean) => any;
}

const Component: FC<IProps> = ({
  appointment,
  patientId,
  onChangeOpen,
  onRemove
}: IProps) => {
  const [cancelReasonCode, setCancelReasonCode] = useState<string | null>(null);
  const [cancelNote, setCancelNote] = useState('');
  const componentRef = useRef(null);
  const patientInfo = useMemo(
    () =>
      appointment.patientsInfo.find(
        (patient) => patient.subjectId === patientId
      ),
    [patientId]
  );
  useEffect(() => {
    if (componentRef?.current) {
      //@ts-ignore
      componentRef?.current.focus();
    }
  }, []);
  const onClickRemove = async () => {
    if (patientInfo) {
      const res = await onRemove(patientInfo, {
        cancelNote,
        cancelReasonCode
      });
      if (res) {
        onChangeOpen(false);
      }
    }
  };
  const onClickCancel = useCallback(() => {
    onChangeOpen(false);
  }, [onChangeOpen]);
  if (!patientInfo) {
    return <span>Patient not found</span>;
  }

  const onKeyDownButton = (e: React.KeyboardEvent) => {
    if (
      !e.shiftKey &&
      e.code === 'Tab' &&
      ((!cancelReasonCode &&
        (e.target as HTMLElement).id === test_ids.cancel) ||
        (cancelReasonCode && (e.target as HTMLElement).id === test_ids.remove))
    ) {
      if (componentRef?.current) {
        e.preventDefault();
        //@ts-ignore
        componentRef?.current.focus();
      }
    }
    if (e.key === 'Enter') {
      if (e.target) {
        (e.target as HTMLElement).click();
      }
    }
  };
  const onKeyDownContainer = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.shiftKey && e.code === 'Tab' && e.target === e.currentTarget) {
      const cancelButton = document.getElementById(test_ids.cancel);
      if (cancelButton) {
        e.preventDefault();
        //@ts-ignore
        cancelButton.focus();
      }
    }
    if (e.code === 'Escape') {
      onChangeOpen(false);
    }
  };
  return (
    <Container tabIndex={0} ref={componentRef} onKeyDown={onKeyDownContainer}>
      <Header>
        <Row align="middle" gutter={16} justify="space-between">
          <Col flex={1}>
            <Name>{patientDisplayName(patientInfo)}</Name>
          </Col>
          <Col>
            <Row align="middle" gutter={16}>
              <Col>
                <InfoButton
                  id={test_ids.info}
                  patientId={patientInfo.subjectId}
                />
              </Col>
              <Col>
                <FormsButton
                  id={test_ids.forms}
                  patientId={patientInfo.subjectId}
                />
              </Col>
              <Col>
                <ChartButton
                  id={test_ids.chart}
                  patientId={patientInfo.subjectId}
                />
              </Col>
              <Col>
                <NoteButton
                  id={test_ids.note}
                  patientId={patientInfo.subjectId}
                  appointmentId={appointment.scheduleEvent.appointmentId}
                />
              </Col>
              <Col>
                <ButtonIcon
                  isButton={true}
                  label="close popup"
                  id={test_ids.close}
                  name={ICONS_LIST.thinClose}
                  iconScale={2.5}
                  changeColorOnHover={true}
                  onClick={() => {
                    onChangeOpen(false);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>
      <Top>
        <Row gutter={16}>
          <Col>
            <ElementTitle name="Age" />
            {moment().diff(moment(patientInfo.dob, 'YYYY-MM-DD'), 'years')}
          </Col>
          <Col>
            <ElementTitle name="Sex" />
            {patientInfo.sex}
          </Col>
          <Col>
            <ElementTitle name="Account Number" />
            {patientInfo.subjectId}
          </Col>
        </Row>
      </Top>
      <Main>
        <Row>
          <Col span={12}>
            <BlockContainer>
              <SubTitle>Group Appointment Removal</SubTitle>
              <ReasonSelector
                id={test_ids.reason}
                reasonTypes={['cancellation']}
                useCode={true}
                value={cancelReasonCode}
                onChange={(code: string) => {
                  setCancelReasonCode(code);
                }}
                name="Reason for Appointment removal"
                selectProps={{
                  star: true,
                  bottomMargin: true
                }}
              />
              <SelectContainer
                id={test_ids.noteArea}
                type="area"
                star={false}
                label="Note"
                value={cancelNote}
                onChange={(note: string) => {
                  setCancelNote(note);
                }}
                inputProps={noteAreaInputProps}
                bottomMargin={false}
              />
            </BlockContainer>
          </Col>
          <Col span={12}>
            <BlockContainer
              style={{
                borderLeft: '1px solid rgba(0,0,0,0.1)',
                background: '#F7F7F7'
              }}
            >
              <Info>
                <ElementTitle name="Clinic" />
                <div>{appointment.scheduleEvent.clinicName}</div>
              </Info>
              <Info>
                <ElementTitle name="Provider" />
                <div>{appointment.scheduleEvent.provider?.name || ''}</div>
              </Info>
              <Info>
                <ElementTitle name="Date" />
                <div>
                  {formattedDate(
                    appointment.scheduleEvent.scheduleEventDateTime
                  )}
                </div>
              </Info>
              <Info>
                <ElementTitle name="Time" />
                <div>
                  {formattedTime(
                    appointment.scheduleEvent.scheduleEventDateTime
                  )}
                </div>
              </Info>
              <Info>
                <ElementTitle name="Reason Code" />
                <div>{appointment.scheduleEvent.reason?.code || ''}</div>
              </Info>
            </BlockContainer>
          </Col>
        </Row>
      </Main>
      <Bottom>
        <Row align="middle" justify="end" gutter={16}>
          <Col>
            <Button
              id={test_ids.cancel}
              colorStyles={cancelColorStyles}
              onClick={onClickCancel}
              onKeyDown={onKeyDownButton}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              disabled={!cancelReasonCode}
              id={test_ids.remove}
              colorStyles={removeColorStyles}
              onClick={onClickRemove}
              onKeyDown={onKeyDownButton}
            >
              Remove
            </Button>
          </Col>
        </Row>
      </Bottom>
    </Container>
  );
};

export default Component;

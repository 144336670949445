import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;

  span[role='presentation'] {
    position: absolute;
    top: 2px;
    height: 28px;
    left: 31px;
    right: 5px;
    z-index: ${globalStyleVariables.virtualPlaceholderZIndex};
    pointer-events: none;
    font-size: 1.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }
`;

export const TagsContainer = styled.div`
  margin-top: 15px;
  min-height: 40px;

  .subject-tag {
    display: flex;
    gap: 10px;
    font-size: 14px;
    padding: 4px;
    align-items: center;
  }
`;

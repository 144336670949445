import React, { FC, useCallback, useEffect, useState } from 'react';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

import { GroupPopupContent as PopupContent } from 'src/Activities/Schedule/Common/Classes';
import { getAppointment } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/Actions';
import { IApptResponse } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import { getSubjectSummary } from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';

import Main, { IProps as IMainProps } from '../Main';

type IMain = Omit<IMainProps, 'data' | 'subjectSummary'>;

export interface IProps extends IMain {
  callerRef?: React.MutableRefObject<any>;
  appointmentId: number;
  setRecordChanged?: () => void;
}

const Component: FC<IProps> = ({ appointmentId, ...otherProps }: IProps) => {
  const [data, setData] = useState<IApptResponse | null>(null);
  const [subjectSummary, setSubjectSummary] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const getSummary = useCallback(async (patientId: number) => {
    const response = await getSubjectSummary(patientId);
    if (response.data.success) {
      setSubjectSummary(response.data.result);
      return response.data.result;
    }
    return;
  }, []);
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const res = await getAppointment(appointmentId, false);
      if (res) {
        await getSummary(res.scheduleEvent.patient.id);
        setData(res);
      }
      setLoading(false);
    };
    init();
  }, []);
  return (
    <PopupContent style={{ width: 'unset' }}>
      {loading && <AbsoluteLoader />}
      {data && subjectSummary && (
        <Main
          {...otherProps}
          data={data}
          subjectSummary={subjectSummary}
          getSummary={getSummary}
        />
      )}
    </PopupContent>
  );
};

export default React.memo(Component);

import React, { FC } from 'react';
import _ from 'lodash';

import { useAppSelector } from 'src/store';

import SelectContainer, { ISelect } from '../../SelectContainer';
import { UserSimpleDto } from 'src/App/Admin/Pages/Users/store/types';

interface IProps {
  onChange: (value: string | null) => void;
  value: any;
  selectProps?: Partial<ISelect>;
  filterItem?: (user: UserSimpleDto) => boolean;
  removeSearchOnChange?: boolean;
}

const Component: FC<IProps> = ({
  onChange,
  value,
  selectProps,
  filterItem,
  removeSearchOnChange
}: IProps) => {
  const users = useAppSelector((state) => state.adminPanel.users.summary);
  const allOptions = Object.values(users).map((v) => ({
    value: v.id,
    isActive: v.isActive,
    title: v.name,
    dto: v
  }));
  let options = allOptions.filter((v) => v.isActive);
  if (filterItem) {
    options = options.filter((v) => filterItem(v.dto));
  }
  return (
    <SelectContainer
      star={false}
      label="Assign to user"
      type="select"
      value={value}
      onChange={(value: any) => {
        onChange(value);
      }}
      allOptions={allOptions}
      options={options}
      {...selectProps}
      inputProps={{
        allowClear: true,
        ...selectProps?.inputProps
      }}
    />
  );
};

export default React.memo(Component);

import React, { FC } from 'react';
import { useHippa } from '../../store/Hooks/useHippa';

import Status from './Status';
import { ICommon } from '.';

interface IProps extends ICommon {
  patientId: number | string;
}

const Component: FC<IProps> = ({ patientId, ...common }: IProps) => {
  const { loading, data } = useHippa({ patientId });
  return <Status {...common} loading={loading} data={data} />;
};

export default React.memo(Component);

import React, { FC, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { IconLabelContainer, TimeText } from './styled';
import { statusProgressHandler } from '../../../Utils/Utils';

import {
  IApptResponse,
  IPatientInfo
} from '../../../Store/ScheduleEventsReducer/types';

interface IProps {
  params: {
    column: any;
    data: IPatientInfo;
  };
  date: any;
  renderSvgFromAction: any;
  getInfoAction: any;
  onStatusUpdate: any;
  appointment: IApptResponse;
}

const Component: FC<IProps> = (props: IProps) => {
  const [loading, setLoading] = useState(false);
  const {
    params,
    date,
    renderSvgFromAction,
    getInfoAction,
    onStatusUpdate,
    appointment
  } = props;
  const currentDate = new Date();
  const onStatusChange = async (status: any, patient: IPatientInfo, e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (loading) return;
    const details: any = statusProgressHandler(
      status.charAt(0).toUpperCase() + status.slice(1),
      {
        ...appointment.patientStatus[patient.subjectId]
      }
    );
    const payload = {
      appointmentId: appointment.scheduleEvent.appointmentId,
      subjectId: patient.subjectId,
      status: {
        ...details
      },
      dts: new Date()
    };
    setLoading(true);
    await onStatusUpdate(payload);
    setLoading(false);
    return;
  };
  const {
    column: { colId: columnName },
    data: patient
  } = params;
  let notStatus =
    columnName === 'delete' ||
    columnName === 'link' ||
    columnName === 'note' ||
    columnName === 'forms' ||
    columnName === 'name';
  const isAfter = moment(date).isAfter(currentDate);
  const readonly = isAfter && !notStatus;
  const info = getInfoAction(columnName, patient);
  return (
    <IconLabelContainer
      onClick={(e: any) => {
        if (!readonly) {
          onStatusChange(columnName, patient, e);
        }
      }}
      style={
        readonly
          ? {
              cursor: 'no-drop',
              opacity: 0.5
            }
          : {}
      }
    >
      {loading && <AbsoluteLoader />}
      <div style={readonly ? { pointerEvents: 'none' } : {}}>
        {renderSvgFromAction(columnName, patient.subjectId, patient)}
      </div>
      {info !== 'noshow' && <TimeText>{info}</TimeText>}
    </IconLabelContainer>
  );
};

export default Component;

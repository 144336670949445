import _ from 'lodash'
import store from 'src/store';
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"
import { actionsTypes } from './Reducer'
import { IAlert, IFilter } from './types'

import { locations, getLocationsFromNumber } from 'src/Activities/Counseling/Dashboard/Alerts/utils';
import { openAlert } from '../Alert'

// Parameter has to be a string of patient ids
export const get = async (patientIds: (string | number)[], set = true) => {
    try {
        const response = await APIHandler.AxiosInstance.get(`${ApiUrl.GET_PATIENT_ALERTS}`, {
            withCredentials: true, params: {
                patientIds,
                isActive: true
            }
        })
        if (response.data.success) {
            const data: any = Object.values(response.data.result.summary);
            data.forEach((alert: IAlert) => {
                alert.toastConfigJSON = JSON.parse(alert.toastConfig);
                const users = store.getState().adminPanel.users.summary
                let name = ''
                const user = users[alert.createdUserId]
                if (user) {
                    name = user.name
                }
                alert.userName = name;
            })
            const obj = {}
            patientIds.forEach((patientId) => {
                const patientAlerts = data.filter((alert: any) => alert.patient.id === Number(patientId));
                obj[patientId] = {
                    summary: patientAlerts
                }
                if (set) {
                    setData(patientId, patientAlerts)
                }
            })
            return obj
        }
        return
    } catch (e) {
        console.log({ e })
        return
    }
}

export const post = async (patientId: string, body: Partial<IAlert>) => {
    try {
        const data = {
            ...body,
            dts: new Date(),
            patientId
        }
        const response = await APIHandler.AxiosInstance.post(`${ApiUrl.GET_PATIENT_ALERTS}`, data, {
            withCredentials: true,
        })
        if (response.data.success) {
            const item: any = Object.values(response.data.result.summary)[0]
            addItem(patientId, item)
            return item
        }
        return
    } catch (e) {
        return
    }
}


export const patch = async (patientId: string, id: number, body: Partial<IAlert>) => {
    try {
        const response = await APIHandler.AxiosInstance.patch(`${ApiUrl.GET_PATIENT_ALERTS}/${id}`, body, {
            withCredentials: true,
            interceptError: true
        })
        if (response.data.success && response.data.result.summary) {
            const item: any = Object.values(response.data.result.summary)[0]
            editItem(patientId, item)
            return item
        }
        return
    } catch (e) {
        return
    }
}

export const getAlertTypes = async () => {
    try {
        const response = await APIHandler.AxiosInstance.get(`${ApiUrl.GET_PATIENT_ALERT_TYPES}`, {
            withCredentials: true
        })
        if (response.data.success) {
            const data = Object.values(response.data.result.summary)
            setTypes(data)
        }
        return
    } catch (e) {
        return
    }
}

const addItem = (patientId: string, item: IAlert) => {
    const list = [...store.getState().patientsAlerts.patients[patientId].summary]
    const index = list.findIndex((v) => v.id === item.id)
    if (index === -1 && item) {
        list.unshift({ ...item })
        setData(patientId, list)
    }
}


const editItem = (patientId: string, item: IAlert) => {
    const list = [...store.getState().patientsAlerts.patients[patientId].summary]
    const index = list.findIndex((v) => v.id === item.id)
    if (index !== -1 && item) {
        list[index] = { ...item }
        setData(patientId, list)
    }
}

const setData = (patientId: string | number, payload: any) => {
    store.dispatch({
        type: actionsTypes.SET,
        payload,
        patientId
    })
}

const setTypes = (payload: any) => {
    store.dispatch({
        type: actionsTypes.SET_TYPES,
        payload,
    })
}

export const setFilters = (payload: Partial<IFilter>, patientId: number | string) => {
    store.dispatch({
        type: actionsTypes.SET_FILTER,
        payload,
        patientId
    })
}

export const getPatientsAlerts = async (subjectIds: Array<string>, locationIndex: number, alertPopup?: boolean) => {
    const alertTypes = store.getState().patientsAlerts.patientAlertTypes;
    if (_.isEmpty(alertTypes) || _.isNull(alertTypes)) {
        await getAlertTypes();
    }
    await get(subjectIds)
    if (alertPopup) {
        checkAlertsByLocation(subjectIds, locationIndex)
    } else {
        return returnAlertsByLocation(subjectIds, locationIndex)
    }
    return
}

const getValidAlerts = (data: IAlert[]) => {
    return data.filter((v) => {
        if (v.isEnabled && v.isActive) {
            const currentTime = new Date().getTime()
            const startTime = new Date(v.startDateTime).getTime()
            const endTime = new Date(v.endDateTime).getTime()
            return (currentTime >= startTime) && (currentTime <= endTime)
        }
        return false
    })
}

export const checkAlertsByLocation = (patientIds: Array<string>, index: number) => {
    patientIds.forEach((patientId) => {
        const location = locations()[index]
        const data = store.getState().patientsAlerts.patients[patientId]
        if (data) {
            const list = getValidAlerts([...data.summary])
            list.forEach((alert) => {
                const arr = getLocationsFromNumber(alert.locations)
                const inLocation = arr.find((l) => l.id === location.id)
                if (inLocation) {
                    openAlert(alert)
                }
            })
        }
    })
}

export const returnAlertsByLocation = (patientIds: (string | number)[], index: number, alertsData = store.getState().patientsAlerts.patients) => {
    const location = locations()[index];
    const alerts: IAlert[] = [];
    patientIds.forEach((patientId) => {
        const data = alertsData[patientId]
        if (data) {
            const list = getValidAlerts([...data.summary])
            list.forEach((alert) => {
                const arr = getLocationsFromNumber(alert.locations)
                const inLocation = arr.find((l) => l.id === location.id);
                if (inLocation) {
                    alerts.push(alert);
                }
            })
        }
    })

    return alerts;
}
import React, { FC } from 'react';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import { Item } from './styled';

interface IProps {
  value: any;
  type?: string;
  onRemove?: Function;
  index: number;
}

const defaultType =
  require('src/Framework/Common/Svg/attachments/text.svg').default;

const Component: FC<IProps> = ({ value, onRemove, index }: IProps) => {
  const typeIcon = defaultType;
  const onClick = () => {};
  return (
    <Item onClick={onClick} tabIndex={0} role="listitem">
      <div className="type-icon-container">
        <div
          className="type-icon"
          style={{
            WebkitMaskImage: `url(${typeIcon})`,
            maskImage: `url(${typeIcon})`
          }}
        />
      </div>
      <div className="name">{value.code}</div>
      {onRemove && (
        <ButtonIcon
          name={ICONS_LIST.thinClose}
          isButton={true}
          label="remove item"
          onClick={(e) => {
            e.stopPropagation();
            onRemove && onRemove(value, index);
          }}
          size={14}
          disableBackground={true}
        />
      )}
    </Item>
  );
};

export default Component;

import React, { FC } from 'react';
import Linkify from 'react-linkify';

import { ITelehealth } from '../../AppointmentPopup/types';

import { SubTitle, TextTelehealth, ContainerBlock } from './styled';

interface IProps {
  data: ITelehealth;
}

const Component: FC<IProps> = ({ data }: IProps) => {
  return data ? (
    <>
      <ContainerBlock>
        <SubTitle>Telehealth Patient Connection Info</SubTitle>
        <TextTelehealth>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {data.patientConnectionInfo}
          </Linkify>
        </TextTelehealth>
        <SubTitle>Telehealth Provider Connection Info</SubTitle>
        <TextTelehealth>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {data.providerConnectionInfo}
          </Linkify>
        </TextTelehealth>
      </ContainerBlock>
    </>
  ) : null;
};

export default Component;

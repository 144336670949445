import React from 'react';
import { getNextAvailableEvents } from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import SelectDateTime from './SelectDateTime';
interface IState {
  nextEvents: any;
  isLoading: boolean;
}
interface IProps {
  newEventPayload: any;
  onSaveSelection: (event: any) => void;
  onCancelSelection: () => void;
  selectedClinic: any;
  enableMulti: boolean;
  clinics: any;
  providers: any;
}

class SchedulingContainer extends React.Component<IProps, IState> {
  state: IState = {
    nextEvents: [],
    isLoading: true
  };
  buildNextEvents(nextEvents: any): any {
    const nextAvailableOptions = nextEvents.map((nextEvent: any) => ({
      DATE:
        nextEvent.appointmentDateString.split(' ')[0] +
        ' ' +
        nextEvent.appointmentDateString.split(' ')[1].substring(0, 3),
      TIME: nextEvent.appointmentTimeString,
      CLINIC: nextEvent.clinicName,
      PROVIDER: nextEvent.providerCodes
        ? nextEvent.providerCodes
        : [nextEvent.providerCode],
      DateValue: nextEvent.startTime,
      Event: nextEvent
    }));
    return {
      nextAvailableOptions
    };
  }
  updateNextAvailable = async (schedulingPayload: any) => {
    this.setState({
      isLoading: true
    });
    try {
      const response = await getNextAvailableEvents(schedulingPayload);
      if (response.data.success) {
        const nextEvents = this.buildNextEvents(response.data.result);
        this.setState({
          nextEvents
        });
      } else {
        console.log('next available response success false');
      }
    } catch (e) {
    } finally {
      this.setState({
        isLoading: false
      });
    }
  };

  render() {
    return (
      <>
        {this.state.nextEvents ? (
          <SelectDateTime
            isLoading={this.state.isLoading}
            nextEvents={this.state.nextEvents}
            newEventPayload={this.props.newEventPayload}
            enableMulti={this.props.enableMulti}
            clinics={this.props.clinics}
            selectedClinic={this.props.selectedClinic}
            providers={this.props.providers}
            onSaveSelection={this.props.onSaveSelection.bind(this)}
            onCancelSelection={this.props.onCancelSelection.bind(this)}
            updateNextAvailable={this.updateNextAvailable.bind(this)}
          />
        ) : null}
      </>
    );
  }
}
export default SchedulingContainer;

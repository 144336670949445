import React, { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';

import UploaderAttachments from 'src/Framework/Controls/UploaderAttachments';
import {
  IFileSimpleDto,
  uploadMultipartFiles
} from 'src/Framework/Controls/FileUploader';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import Radio from 'src/Framework/Controls/Radio';
import ElementTitle from 'src/Framework/Controls/ElementTitle';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { formattedDateTime } from 'src/Framework/Shared/Shared';
import { Alert } from 'src/Framework/Common/Notification';

import ReqPrioritySelect from 'src/Activities/Counseling/Notes/EditNote/NoteSection/Sections/PlanSection/Referrals/ReqPrioritySelect';
import ReqStatusSelect from 'src/Activities/Counseling/Notes/EditNote/NoteSection/Sections/PlanSection/Referrals/ReqStatusSelect';
import ProviderSelect from 'src/Activities/Counseling/Notes/EditNote/NoteSection/Sections/PlanSection/Referrals/ProviderSelect';
import {
  ITypeReqPrioritySimpleDto,
  ITypeReqStatusSimpleDto
} from '../store/types';

import {
  InlineField,
  InlineFieldOption,
  InlineFieldRadio,
  InlineFields
} from '../NewReferralModal/styled';
import { Container } from './styled';

interface IProps {
  onSubmit: any;
  goBack: () => void;
  reqOrderId: number | null;
  submit: boolean;
  setLoading: (load: boolean) => void;
  setSubmitDisabled: (disabled: boolean) => void;
  lastResultPriority: ITypeReqPrioritySimpleDto | null;
  lastResultStatus: ITypeReqStatusSimpleDto | null;
  providers: any;
  subjectId?: number | string;
}

interface IFile extends File {
  id?: string;
}

function NewResultForm({ ...props }: IProps) {
  const [loading, setLoading] = useState(false);
  const now = moment(new Date());
  const passedHalfHour = Number(now.format('mm')) > 30;
  if (passedHalfHour) {
    now.set('minute', 0);
    now.add(1, 'hours');
  } else {
    now.set('minute', 30);
  }

  const [files, setFiles] = useState<IFile[]>([]);
  const [from, setFrom] = useState('');
  const [dts, setDts] = useState(now.format('MM/DD/YYYY'));
  const [time, setTime] = useState(now.format('hh:mm A'));
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [comments, setComments] = useState('');
  let userId = localStorage.getItem('userId');
  const [showErrors, setShowErrors] = useState(false);
  const [priority, setPriority] = useState<ITypeReqPrioritySimpleDto | null>(
    props.lastResultPriority
  );
  const [status, setStatus] = useState<any | null>(props.lastResultStatus);
  const [renderingProvider, setRenderingProvider] = useState<any>(null);
  const [radio, setRadio] = useState(0);

  const submit = async () => {
    if (loading) return;
    if (!submitDisabled) {
      const newResult: any = {
        comment: comments,
        reqOrderId: props.reqOrderId,
        from: from,
        providerId:
          radio === 0 && renderingProvider
            ? renderingProvider.providerId
            : null,
        userId: userId,
        typeReqPriorityId: priority?.id,
        typeReqStatusId: status?.id,
        activityDate: formattedDateTime(dts + ' ' + time)
      };

      if (files.length !== 0) {
        const onSuccess = async (fileIds: number[]) => {
          newResult['fileIds'] = fileIds;
          setLoading(true);
          await props.onSubmit(newResult);
          setLoading(false);
          goBack();
        };

        const onError = (error?: string) => {
          Alert('error', 'Error', error || '');
        };

        const res: any = await uploadMultipartFiles(files);
        const fileIds = res.map((v: IFileSimpleDto) => v.id);
        if (fileIds.length > 0) {
          onSuccess(fileIds);
        } else {
          onError();
        }
      } else {
        setLoading(true);
        await props.onSubmit(newResult);
        setLoading(false);
        goBack();
      }
    } else {
      setShowErrors(true);
      props.onSubmit();
    }
  };

  const goBack = () => {
    clearForm();
    setSubmitDisabled(false);
    props.goBack();
  };

  const clearForm = () => {
    setFiles([]);
    setFrom('');
    setDts('');
    setTime('');
    setComments('');
    setStatus(null);
    setPriority(null);
    setRenderingProvider(null);
  };

  useEffect(() => {
    if (
      dts === '' ||
      (radio === 1 && _.isEmpty(from)) ||
      (radio === 0 && !renderingProvider) ||
      time === '' ||
      priority === null ||
      status === null ||
      _.isEmpty(comments)
    ) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [from, dts, time, priority, status, renderingProvider, comments, radio]);

  useEffect(() => {
    if (props.submit) {
      submit();
    }
  }, [props.submit]);

  useEffect(() => {
    props.setSubmitDisabled(submitDisabled);
  }, [submitDisabled]);

  useEffect(() => {
    if (radio === 1) {
      setRenderingProvider(null);
    } else {
      setFrom('');
    }
  }, [radio]);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === 'ArrowRight' || e.code === 'ArrowLeft') {
      const nextOption = document.querySelector(
        'input[type=radio][value=false]'
      );
      if (nextOption) {
        e.preventDefault();
        (nextOption as HTMLElement).focus();
        (nextOption as HTMLElement).click();
      }
    }
  };

  return (
    <Container>
      {loading && <AbsoluteLoader />}
      <ElementTitle name="Source" star={true} />
      <InlineFields>
        <Radio
          onKeyDown={onKeyDown}
          checked={radio === 0}
          onClick={(e: any) => setRadio(0)}
          name="radio-source"
          id="internal"
        >
          <label className={'visually-hidden'}>Internal Source</label>
        </Radio>

        <InlineFieldOption>
          <ProviderSelect
            validate={radio === 0 && showErrors}
            label="Internal"
            disabled={radio === 1}
            hideTitle={true}
            providers={_.orderBy(props.providers, ['firstName'], ['asc'])}
            providerId={renderingProvider ? renderingProvider.id : null}
            setProvider={setRenderingProvider}
          />
        </InlineFieldOption>
        <InlineFieldRadio />
        <Radio
          onKeyDown={onKeyDown}
          checked={radio === 1}
          onClick={(e: any) => setRadio(1)}
          name="radio-source"
          id="external"
        >
          <label className={'visually-hidden'}>External Source</label>
        </Radio>
        <InlineFieldOption>
          <SelectContainer
            type="input"
            label="External"
            hasError={showErrors && (_.isEmpty(from) || from === null)}
            inputProps={{ maxLength: 255 }}
            value={from}
            hideLabel={true}
            disabled={radio === 0}
            onChange={(val: string) => setFrom(val)}
          />
        </InlineFieldOption>
      </InlineFields>
      <InlineFields>
        <InlineField>
          <SelectContainer
            hasError={showErrors && (dts === '' || dts === null)}
            type="date"
            label="Date"
            value={dts}
            onChange={(val) => setDts(val ? val : '')}
            format={'MM/DD/YYYY'}
          />
        </InlineField>
        <InlineField>
          <SelectContainer
            hasError={showErrors && (time === '' || time === null)}
            type="time"
            label="Time"
            value={time}
            onChange={(val: string) => setTime(val)}
          />
        </InlineField>
      </InlineFields>
      <SelectContainer
        type="area"
        bottomMargin={true}
        inputProps={{ rows: 6, maxLength: 4096 }}
        value={comments}
        onChange={(val: string) => setComments(val)}
        label="Comments"
      />
      <UploaderAttachments
        patientId={props.subjectId}
        fileIds={[]}
        files={[]}
        onChangeFiles={() => {}}
        filesToUpload={files}
        onChangeUploadFiles={(files) => {
          setFiles(files);
        }}
      />
      <InlineFields>
        <InlineField>
          <ReqPrioritySelect
            fontSize={'12px'}
            validate={showErrors}
            priorityId={priority?.id}
            setPriority={setPriority}
          />
        </InlineField>
        <InlineField>
          <ReqStatusSelect
            fontSize={'12px'}
            validate={showErrors}
            statusId={status?.id}
            setStatus={setStatus}
          />
        </InlineField>
      </InlineFields>
    </Container>
  );
}

export default NewResultForm;

import styled from "styled-components";

export const Container = styled.div`
    padding: 12px 20px;
    background-color: white;
    &.borderBottom {
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }
`

export const HeaderLink = styled.div`
    cursor: pointer;
    color: #333333;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    font-family: Encode Sans Expanded;
    font-style: normal;
`
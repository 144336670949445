export interface IStateReducer {
    summary: Array<ITypeRisksItem>
    loading: boolean
    riskSelectedGroup: RiskGroup
}

export interface IAction {
    type: string;
    payload: any;
}

export interface ITypeRisksItem {
    id: number
    isEnabled: boolean
    isActive: boolean
    isDashboardShown: boolean
    name: string
    order: string
    riskColor: string
    riskGroup: RiskGroup
    highestLevelMark: boolean
}

export const riskGroups = ['suicide', 'homicide', 'academic'] as const

export type RiskGroup = typeof riskGroups[number]
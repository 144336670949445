import { IM1FormRecord } from "src/App/M1FormRecords/store/types";
import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { APIHandler } from "src/Framework/Communication/ServerProxy";

export const getForm = async (id: string | number) => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            ApiUrl.M1FormRecords,
            {
                withCredentials: true, params: {
                    ids: id
                }
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data: IM1FormRecord = response.data.result.summary[id];
                if (data) {
                    return data;
                }
            }
        }
        return;
    } catch (e) {
        console.log({ e });
        return;
    } finally {
    }
};
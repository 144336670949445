import React, { FC, useMemo } from 'react';
import { Col, Row } from 'antd';

import { Container, Item, ItemContainer } from './styled';

export interface IBreadcrumd {
  id: number | string;
  title: string | React.ReactNode;
  onClick?: () => any;
}

interface IProps {
  data: IBreadcrumd[];
  rightComponent?: React.ReactNode;
  leftComponent?: React.ReactNode;
  isHeader?: boolean;
  hideParent?: boolean;
}

const Component: FC<IProps> = ({
  data,
  rightComponent,
  leftComponent,
  isHeader,
  hideParent
}: IProps) => {
  const dataToShow = useMemo(() => {
    if (hideParent) {
      return data.slice(1);
    }
    return data;
  }, [data, hideParent]);
  return (
    <Container>
      <Row
        align="middle"
        justify="space-between"
        tabIndex={isHeader ? 0 : -1}
        id={isHeader ? 'page-starter' : undefined}
        style={{ width: '100%' }}
      >
        <Col>
          <Row align={'middle'} gutter={12}>
            <Col>
              <nav aria-label="Breadcrumb">
                <ol>
                  {dataToShow.map((v, i) => {
                    return (
                      <li key={v.id}>
                        <ItemContainer
                          onClick={v.onClick}
                          aria-current={
                            i === dataToShow.length - 1 ? 'page' : undefined
                          }
                        >
                          <Item>{v.title}</Item>
                        </ItemContainer>
                      </li>
                    );
                  })}
                </ol>
              </nav>
            </Col>
            {leftComponent && <Col>{leftComponent}</Col>}
          </Row>
        </Col>
        {rightComponent && <Col>{rightComponent}</Col>}
      </Row>
    </Container>
  );
};

export default React.memo(Component);

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Container: any = styled.div`
  width: min-content;
  display: flex;
  align-items: center;
  border-left: 2px solid ${globalStyleVariables.borderColor};
  padding: 0 0 0 24px;
  margin: 0 0 0 24px;
  font-size: 12px;
  color: #333333;

  .current-items {
  }

  ${(props: any) => props.hideBorder && `border: none;`}
  ${(props: any) => props.disableMargin && `margin:0;`}
`;

interface IArrowsContainerProps {
  rightMargin?: number;
}

export const ArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 10px;
  width: 75px;
  justify-content: space-between;
  ${(props: IArrowsContainerProps) =>
    props.rightMargin && `margin-right: ${props.rightMargin}px;`}
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  white-space: nowrap;
`;

import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import Pagination from 'src/Framework/Controls/Pagination';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { ApptConflictSvg } from 'src/Framework/Common/Svg/ApptConflictSvg';
import { getProviderByCode } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { capitalizeFirstLetter } from 'src/Framework/util/format';
import { formattedDate } from 'src/Framework/Shared/Shared';
import {
  getClinicByCode,
  getClinicByCodeNoCase
} from 'src/Framework/Controls/Selectors/ClinicSelector';
import { Box, ColumnBox } from 'src/CommonStyles/styles';

import {
  TimeBarLayout,
  CalendarSnapContainer
} from 'src/Activities/Schedule/Main/Main_Styles';
import {
  getClinicsStartEndTimeInterval,
  getMinFrom1200AM,
  isClinicOpened
} from 'src/Activities/Schedule/Utils/Utils';
import {
  generateEmptySchedulesFromDayStartEndTime,
  getParsedEvents
} from 'src/Activities/Schedule/Utils/renderer';
import {
  getScheduleSlices,
  getScheduleSliceEvents
} from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import CalendarSnapCol from './CalendarSnapCol';
import {
  DateTitle,
  Header,
  PaginationContainer,
  ProviderTitle,
  ScrollContainer,
  ScheduleContentLayout,
  ClosedClinic,
  DateProviderTitle,
  ColumnHeader,
  ColumnsContainer
} from './CalendarSnap_Style';
import TimeBar from './TimeBar';

interface IState {
  emptySchedules: any;
  isLoading: boolean;
  events: any;
  regularMeetingTime: any;
  dayStartTime: any;
  dayEndTime: any;
  currentPage: number;
}

interface IProps {
  searchLine: any;
  clinicOptions: any;
  providers: any;
  timeLayerMode?: boolean;
}

export const columnsNumberLimit = 5;

const markSlots = (schedules: any, event: any) => {
  let updatedSchedules = _.cloneDeep(schedules);
  const eventStartTime = moment(event.startTime).format('HH:mm');
  const eventEndTime = moment(event.endTime).format('HH:mm');
  let eventStartMins = getMinFrom1200AM(eventStartTime);
  let eventEndMins = getMinFrom1200AM(eventEndTime);
  updatedSchedules.forEach((slot: any, index: number) => {
    let slotStartMins = getMinFrom1200AM(slot.startTime);
    let slotEndMins = getMinFrom1200AM(slot.endTime);
    if (
      slotStartMins >= eventStartMins &&
      eventStartMins < slotEndMins &&
      eventEndMins > slotStartMins
    ) {
      updatedSchedules[index]['noshow'] = true;
    }
    if (
      slotStartMins + 60 >= eventStartMins &&
      eventStartMins < slotEndMins + 60 &&
      eventEndMins > slotStartMins - 60
    ) {
      updatedSchedules[index]['plshow'] = true;
    }
  });
  return updatedSchedules;
};

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

class CalendarSnap extends React.Component<IProps, IState> {
  public mainRef: any;
  public scrollInterval: any = null;
  public scrollSpeed: number = 0;
  public scrollHeight: number = 0;
  public activeScheduleOneElement: any;
  public activeEmptyScheduleElement: any;
  public clockInterval: any = null;
  public wrapperRef: any;

  timebarContainerRef = React.createRef<HTMLDivElement>();
  mainContainerRef = React.createRef<HTMLDivElement>();

  constructor(props: IProps) {
    super(props);
    const { searchLine } = props;
    const event = searchLine.Event;
    let object: any = {
      regularMeetingTime: undefined,
      dayStartTime: undefined,
      dayEndTime: undefined
    };
    const clinic = getClinicByCode(event.clinicCode);
    let emptySchedules = [];
    if (clinic) {
      const interval = getClinicsStartEndTimeInterval([clinic])!;
      if (!interval) return;
      object = {
        ...object,
        ...interval
      };
      if (
        object.dayStartTime &&
        object.dayEndTime &&
        object.regularMeetingTime
      ) {
        const timeBarSchedules = generateEmptySchedulesFromDayStartEndTime(
          object.dayStartTime,
          object.dayEndTime,
          object.regularMeetingTime
        );
        emptySchedules = markSlots(timeBarSchedules, event);
      }
    }
    this.state = {
      currentPage: 0,
      emptySchedules,
      isLoading: true,
      events: [],
      ...object
    };
    this.doScroll = this.doScroll.bind(this);
  }

  componentDidMount() {
    const { searchLine, providers } = this.props;
    if (searchLine) {
      const event = searchLine.Event;
      const providerCodes = providers.map((item: any) => item.value);
      this.getSliceEvents(
        event.startTime,
        event.endTime,
        [event.clinicCode],
        providerCodes
      );
    }
  }

  getEventsPage() {
    return _.sortBy(
      this.state.events.slice(
        this.state.currentPage * columnsNumberLimit,
        (this.state.currentPage + 1) * columnsNumberLimit
      ),
      (item: any) => (item.date as Date).getTime()
    );
  }

  getSliceEvents = async (
    startDate: string,
    endDate: string,
    clinicCodes: string[],
    providerCodes: string[]
  ) => {
    this.setState({
      isLoading: true
    });
    try {
      const response = await getScheduleSlices(
        true,
        0,
        startDate, //start Date
        endDate, //end Date
        clinicCodes, // current clinic codes
        providerCodes // current provider codes
      );
      if (response.data.success) {
        const scheduleSlices = response.data.result;
        if (scheduleSlices.length > 0) {
          const res = await getScheduleSliceEvents(scheduleSlices);
          if (res.data.success) {
            this.generateSchedules(scheduleSlices, res.data.result.events);
          } else {
            console.log('schedule slice events response success false');
          }
        }
      }
    } catch (e) {
    } finally {
      this.setState({
        isLoading: false
      });
    }
  };

  generateSchedules(slices: any, events: any) {
    const { clinicOptions, providers } = this.props;
    const { regularMeetingTime, dayStartTime, dayEndTime } = this.state;
    const clinicCodeToName = {};
    clinicOptions.forEach((clinic: any) => {
      clinicCodeToName[clinic.value.toLowerCase()] = clinic.label;
    });
    const providerDetailsByCode = {};
    providers.forEach((provider: any, providerIdx: number) => {
      const lowercaseCode = provider.value.toLowerCase();
      providerDetailsByCode[lowercaseCode] = {
        ...providers[providerIdx]?.provider,
        fullName: provider.label
      };
    });

    const parsedScheduleEvents: any[] = [];

    Object.keys(events).forEach((sliceId: string) => {
      const params = {};
      sliceId.split(':').forEach((paramData: string) => {
        const [paramKey, paramValue] = paramData.split('#');
        params[paramKey] = paramValue;
      });
      const clinicCode = params['clinic'];
      const providerCode = params['provider'].toLowerCase();

      const clinicName = clinicCodeToName[clinicCode] || clinicCode;
      const clinic = clinicOptions.find(
        (clinic: any) =>
          clinicCodeToName[clinic.value.toLowerCase()] === clinicCode
      );
      const currentProvider = providerDetailsByCode[providerCode];
      let otherProvider;
      if (!currentProvider) {
        otherProvider = getProviderByCode(capitalizeFirstLetter(providerCode));
      }
      const providerName = currentProvider
        ? currentProvider.fullName
        : `${otherProvider?.lastName} ${otherProvider?.firstName}`;
      const providerPriority = currentProvider && currentProvider.priority;
      const slice = slices.find((slice: any) => slice.id === sliceId);
      const allowedLimit = slice?.pages ?? 1;
      const date = new Date(
        params['date'].replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3')
      );

      const weekDay = weekDays[date.getDay()];
      const sliceData = events[sliceId];

      const parsedEventObject = getParsedEvents(
        sliceData,
        dayStartTime,
        dayEndTime,
        regularMeetingTime,
        date,
        allowedLimit,
        sliceId,
        false
      );

      parsedScheduleEvents.push({
        date,
        weekDay,
        providerCode,
        providerName,
        providerPriority,
        clinicCode,
        clinicName,
        clinicId: clinic && clinic.id,
        clinicPriority: 0,
        schedules: markSlots(
          parsedEventObject.schedules,
          this.props.searchLine.Event
        ),
        scheduleGroups: parsedEventObject.scheduleGroups,
        objKey: sliceId,
        pages: allowedLimit
      });
    });
    this.setState(
      {
        events: parsedScheduleEvents,
        isLoading: false
      },
      () => {
        setTimeout(() => {
          this.scrollToPos('scroll-to-this');
        }, 250);
      }
    );
  }

  doScroll() {
    if ((window as any).stopScroll) {
      (window as any).stopScroll = false;
      this.stopScroll();
      return;
    }

    let newScrollTo = this.mainRef.scrollTop + this.scrollSpeed;
    if (newScrollTo < 0) {
      newScrollTo = 0;
    } else if (newScrollTo > this.scrollHeight - this.mainRef.clientHeight) {
      newScrollTo = this.scrollHeight - this.mainRef.clientHeight;
    }
    this.mainRef.scrollTo({
      top: newScrollTo,
      left: 0,
      behavior: 'smooth'
    });
  }

  scrollToTop() {
    this.scrollSpeed = -10;
    if (!this.scrollInterval)
      this.scrollInterval = setInterval(this.doScroll, 100);
  }

  scrollToBottom() {
    this.scrollSpeed = 40;
    if (!this.scrollInterval)
      this.scrollInterval = setInterval(this.doScroll, 100);
  }

  stopScroll() {
    this.scrollSpeed = 0;
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
      this.scrollInterval = null;
    }
  }

  getScrollTop(): number {
    return this.mainRef.scrollTop;
  }

  getElementHeight(): number {
    return this.mainRef.clientHeight;
  }

  getPopupPositionInfoFromElement(element: any) {
    const boundingBox = element.getBoundingClientRect();

    const bubbleLR = boundingBox.left < 650;
    const popupWindowWidth = 660;
    const popupWindowHeight = 674;
    const speechBubbleWidth = 14;
    const newState: any = {
      bubbleLR,
      bubbleY: boundingBox.top + 20,
      popupLeft: bubbleLR
        ? boundingBox.left + boundingBox.width + speechBubbleWidth
        : boundingBox.left - popupWindowWidth - speechBubbleWidth,
      popupTop:
        boundingBox.top + boundingBox.height / 2 - popupWindowHeight / 2,
      scheduleOneHeight: boundingBox.height,
      negativeTop:
        boundingBox.top + boundingBox.height / 2 - popupWindowHeight / 2 < 0 &&
        boundingBox.height != 54.5 &&
        boundingBox.height != 114
    };
    if (newState.popupTop < 100) {
      newState.popupTop = 100;
    } else if (newState.popupTop + popupWindowHeight > window.innerHeight) {
      newState.popupTop = window.innerHeight - popupWindowHeight;
    }

    return newState;
  }

  onScroll() {
    if (!this.mainRef || !this.activeScheduleOneElement) {
      return;
    }

    const mainPageBoundingBox = this.mainRef.getBoundingClientRect();

    const activeElementBoundingBox =
      this.activeScheduleOneElement.getBoundingClientRect();

    const pageTop = mainPageBoundingBox.top;
    const pageBottom = mainPageBoundingBox.bottom;
    const elementTop = activeElementBoundingBox.top;
    const elementBottom = activeElementBoundingBox.bottom;

    const isVisible = elementTop <= pageBottom && elementBottom >= pageTop;

    if (!isVisible) {
    } else {
      const newState = this.getPopupPositionInfoFromElement(
        this.activeScheduleOneElement
      );
      this.setState(newState);
    }
  }

  scrollToPos = (id: string) => {
    const element = document.getElementById('event-to-scroll');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  onScrollMain = (e: any) => {
    if (this.timebarContainerRef.current) {
      this.timebarContainerRef.current.scrollTop = e.target.scrollTop;
    }
  };

  render() {
    const {
      events,
      emptySchedules,
      regularMeetingTime,
      dayEndTime,
      dayStartTime,
      currentPage
    } = this.state;
    const scheduleTimeProps = {
      dayStartTime,
      dayEndTime,
      regularMeetingTime
    };
    const scheduleColScrollProps = {
      scrollToTop: this.scrollToTop.bind(this),
      scrollToBottom: this.scrollToBottom.bind(this),
      stopScroll: this.stopScroll.bind(this),
      getScrollTop: this.getScrollTop.bind(this)
    };

    const clinicOpened = (clinicCode: string, date: any) => {
      return isClinicOpened(
        getClinicByCodeNoCase(clinicCode),
        moment(date).format()
      );
    };

    return (
      <CalendarSnapContainer
        ref={(ref) => (this.mainRef = ref)}
        onScroll={this.onScroll.bind(this)}
      >
        {this.state.isLoading && <AbsoluteLoader />}
        <DateProviderTitle>
          <Header>
            <ApptConflictSvg />
            {events.length > columnsNumberLimit && (
              <PaginationContainer>
                <Pagination
                  hideBorder={true}
                  pageNumber={currentPage}
                  pageLength={columnsNumberLimit}
                  dataLength={events.length}
                  onChangePage={(currentPage) => {
                    this.setState({ currentPage });
                  }}
                />
              </PaginationContainer>
            )}
          </Header>
          <ColumnsContainer providerCnt={events && this.getEventsPage().length}>
            {this.getEventsPage().map((event: any) => (
              <ColumnHeader>
                {!clinicOpened(event.clinicCode, event.date) && (
                  <ClosedClinic>CLOSED</ClosedClinic>
                )}
                <DateTitle>{formattedDate(event.date)}</DateTitle>
                <ProviderTitle>
                  <span></span>
                  <span>{event.providerName}</span>
                  <span>{event.pages === 0 ? 1 : event.pages}</span>
                </ProviderTitle>
              </ColumnHeader>
            ))}
          </ColumnsContainer>
        </DateProviderTitle>

        <ScrollContainer
          id={'scroll-main'}
          providerCnt={events && this.getEventsPage().length}
        >
          <TimeBarLayout ref={this.timebarContainerRef}>
            <TimeBar
              regularMeetingTime={regularMeetingTime}
              schedules={emptySchedules}
            />
          </TimeBarLayout>
          <ScheduleContentLayout
            ref={this.mainContainerRef}
            className="schedule-content-layout"
            providerCnt={events && this.getEventsPage().length}
            onScroll={this.onScrollMain}
          >
            <Box>
              <ColumnBox className="all-providers-view">
                <Box className="all-providers-container">
                  {this.getEventsPage().map((colEvents: any, key: any) => (
                    <ColumnBox
                      key={`${colEvents.providerName}${key}`}
                      className="provider-container"
                    >
                      <CalendarSnapCol
                        isShowingPatientName={true}
                        {...colEvents}
                        colEvents={colEvents}
                        providerId={key}
                        objKey={colEvents.objKey}
                        overflowPopupIsOpen={false}
                        {...scheduleTimeProps}
                        {...scheduleColScrollProps}
                        isShowingReservationLabel={true}
                        getElementHeight={() => this.getElementHeight()}
                        timeToMarkStart={this.props.searchLine?.Event.startTime}
                        timeToMarkEnd={this.props.searchLine?.Event.endTime}
                        timeLayerMode={this.props.timeLayerMode}
                      />
                    </ColumnBox>
                  ))}
                </Box>
              </ColumnBox>
            </Box>
          </ScheduleContentLayout>
        </ScrollContainer>
      </CalendarSnapContainer>
    );
  }
}

export default CalendarSnap;

import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import SelectContainer from 'src/Framework/Controls/SelectContainer';

import { InputContainer } from './styled';

interface IProps {
  providers: any;
  providerId: number | null;
  setProvider: (provider: any) => void;
  onChange?: (provider: IProvider) => void;
  validate?: boolean;
  label: string;
  gray?: boolean;
  disabled: boolean;
  hideTitle: boolean;
}

interface IProvider {
  id: number;
  name: string;
  value: number;
  title: string;
}

const ProviderSelect = (props: IProps) => {
  const [provider, setProvider] = useState<IProvider | null>(null);
  const [providerOptions, setProviderOptions] = useState<IProvider[]>([]);

  const onChangeProvider = (provider: IProvider) => {
    setProvider(provider);
    props.setProvider(
      Object.values(props.providers).find(
        (p: any) => p.providerId == provider.value
      )
    );
  };

  useEffect(() => {
    if (props.providerId !== null) {
      const selected: undefined | IProvider = providerOptions.find(
        (p: IProvider) => p.id == props.providerId
      );
      console.log({
        selected
      });
      if (selected) setProvider(selected);
    } else {
      setProvider(null);
    }
  }, [props.providerId, providerOptions]);

  useEffect(() => {
    const providersOptions = _.sortBy(
      Object.values(props.providers),
      ['lastName', 'firstName'],
      'asc'
    )
      .filter((item: any) => item.inactive === false)
      .map((item: any) => {
        return {
          name: item.lastName + ' ' + item.firstName,
          title: item.lastName + ' ' + item.firstName,
          value: item.providerId,
          id: item.providerId
        };
      });
    setProviderOptions(providersOptions);
  }, [props.providers]);

  return (
    <InputContainer>
      <SelectContainer
        gray={props.gray}
        type="select"
        label={props.label}
        options={providerOptions}
        value={provider ? provider.value : null}
        bottomMargin={false}
        hideLabel={props.hideTitle}
        hasError={props.validate && provider === null}
        onChange={(value: any, option: any) => onChangeProvider(option)}
        inputProps={{
          getPopupContainer: (node: any) => node.parentNode,
          disabled: props.disabled
        }}
      />
    </InputContainer>
  );
};

ProviderSelect.defaultProps = {
  providers: {},
  setPriority: () => {},
  label: 'Provider',
  validate: true,
  disabled: false,
  hideTitle: false
};

export default ProviderSelect;

import React, { FC } from 'react';
import moment from 'moment';
import { List, Space } from 'antd';

import { formattedDate, formattedTime } from 'src/Framework/Shared/Shared';

import { IOccurrence } from 'src/Activities/Schedule/Common/Editors/RecurrenceDisplay';
import { ListContainer, Info } from './ScheduleEditor_Styles';

interface IProps {
  data: IOccurrence[];
}

const Component: FC<IProps> = ({ data }) => {
  return (
    <ListContainer>
      <List
        style={{
          marginRight: 16
        }}
        dataSource={data.sort((a, b) =>
          moment(b.date).isAfter(moment(a.date))
            ? -1
            : moment(a.date).isAfter(moment(b.date))
            ? 1
            : 0
        )}
        renderItem={(item) => (
          <List.Item>
            <Info>
              <div>
                <Space size="middle">
                  <span>{`${formattedDate(item.date)}`}</span>
                  <span>{`${formattedTime(item.startTime)} - ${formattedTime(
                    item.endTime
                  )}`}</span>
                </Space>
              </div>
              <div>{item.isSkipped && 'Skipped'}</div>
            </Info>
          </List.Item>
        )}
      />
    </ListContainer>
  );
};

export default React.memo(Component);

import React, { FC, useEffect, useRef } from 'react';

import { areEqual } from 'src/Framework/util/helps';
import ScheduleSubscriptionWidget from 'src/Framework/ScheduleSubscription/Widget';

interface IProps {
  subscriptionInfo: any;
}

const Component: FC<IProps> = ({ subscriptionInfo }: IProps) => {
  const checkUnmout = useRef(null);
  useEffect(() => {
    ScheduleSubscriptionWidget.instance.refreshSubscriptions(
      subscriptionInfo,
      false
    );
  }, [subscriptionInfo]);
  return <div ref={checkUnmout}></div>;
};

export default React.memo(Component, (prevProps, nextProps) =>
  areEqual(prevProps, nextProps, ['subscriptionInfo'])
);

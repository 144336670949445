import React, { FC, useState } from 'react';

import Radio from 'src/Framework/Controls/Radio';
import { useAppSelector } from 'src/store';

import { onChangePreferenceItem } from './store/Actions';
import { PrefEnumKeys } from '../Preferences/utils';
import { Col, Row } from 'antd';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

import { Label } from './styled';
import { CollapseEnum, searchKeyWordsLibrary } from './utils';
import HightlightText from './HightlightText';

interface IProps {
  containerId?: string;
  search: string;
}

export enum ApptReasonLabel {
  name = 'Name',
  code = 'Code',
  both = 'Both'
}

const options = Object.values(ApptReasonLabel).map((v) => ({
  label: v,
  value: v
}));

const Component: FC<IProps> = ({ containerId, search }) => {
  const [loading, setLoading] = useState(false);
  const apptReasonDisplay = useAppSelector(
    (state) => state.user.preferences.apptReasonDisplay?.parsedValue
  );
  const currentValue = apptReasonDisplay || ApptReasonLabel.name;
  const wordsLibrary = searchKeyWordsLibrary()[CollapseEnum.Calendar].words;
  return (
    <Row
      justify="start"
      align="middle"
      gutter={8}
      style={{ position: 'relative', marginBottom: 10 }}
    >
      <Col flex={'215px'}>
        <Label>
          <HightlightText search={search}>
            {wordsLibrary.apptTypeLabel}
          </HightlightText>
        </Label>
      </Col>
      <Col>
        <Row align="middle" gutter={8}>
          {loading && <AbsoluteLoader />}
          {options.map((v) => (
            <Col key={v.value}>
              <Radio
                name={wordsLibrary.apptTypeLabel}
                id=""
                checked={v.value === currentValue}
                onChange={async () => {
                  setLoading(true);
                  await onChangePreferenceItem(
                    PrefEnumKeys.ApptReasonDisplay,
                    v.value
                  );
                  setLoading(false);
                }}
              >
                <label className={'visually-hidden'}>
                  {`${wordsLibrary.apptTypeLabel}: `}
                </label>
                {v.label}
              </Radio>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default React.memo(Component);

import React, { FC, useCallback, useMemo, useState } from 'react';
import _ from 'lodash';

import Table from 'src/Framework/Controls/Table';
import {
  IColumn,
  ITableRenderItemData
} from 'src/Framework/Controls/Table/types';
import Checkbox from 'src/Framework/Controls/Checkbox';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { useAppSelector } from 'src/store';

import { onChangePreferenceItem } from '../store/Actions';
import { PrefEnumKeys } from 'src/App/Preferences/utils';

import { Container } from './styled';

interface IProps {}

const itemName = (): Record<string, string> => {
  return {
    // 'Client Status': `${getPatientTerm('Student')} Status`,
    'Client Memo': `${getPatientTerm('Patient')} Memo`,
    'Client Percent': `${getPatientTerm('Patient')} Percent`
  };
};

const separator = ';';

const fullListString = `Last Visit;Other ID;Gender;Preferred Name;DOB;School Name;Eligibility;Client Status;International;Immunization Status;Appt Created;Appt Modified;Primary Insurance;Chart No;Secondary Insurance;Authorized Visits;Cell Phone;Account Number;Home Phone;Work Phone;Client Memo;Account Balance;Address;Campus Address;Email;Enrollment Date;Major;Signature On File;Standing;Employer;Fee Schedule;Provider Code;Billing Method;Employed;Marital Status;Pager;Group;OSH User;Class;Privacy Policy;Primary Policy No;Secondary Policy No;Appts Kept;Appts Cancelled;Copay;Client Percent;Categories;Alt. Phone`;

export const unusedFields = [
  'OSH User',
  'Client Status',
  'Pager',
  'Authorized Visits',
  'Email',
  'Appts Kept',
  'Appts Cancelled'
];

const fullListArr = fullListString
  .split(separator)
  .filter((item) => !unusedFields.includes(item));

// ATTENTION!!! - Work with this part super carefully, every move can break legacy logic
const Component: FC<IProps> = ({}: IProps) => {
  const [loading, setLoading] = useState(false);
  const patientFieldOrder = useAppSelector(
    (state) => state.user.preferences.patientFieldOrder?.value as string
  );
  const onChange = async (data: string[]) => {
    setLoading(true);
    await onChangePreferenceItem(
      PrefEnumKeys.patientFieldOrder,
      `${data.join(separator)}${separator}`,
      {
        disableJsonStrigify: true
      }
    );
    setLoading(false);
  };
  const currentValue = (patientFieldOrder || '')
    .split(separator)
    .filter((v) => v);

  const dataSource = useMemo(() => {
    const fullMappedList = [...fullListArr].map((v, i) => ({
      id: v,
      value: v,
      label: v,
      index: currentValue.indexOf(v)
    }));
    const usedElements = fullMappedList
      .filter((v) => v.index !== -1)
      .sort((a, b) => currentValue.indexOf(a.id) - currentValue.indexOf(b.id));
    const notUsedElement = fullMappedList.filter((v) => v.index === -1);
    return [...usedElements, ...notUsedElement];
  }, [currentValue]);

  const renderItemData = useCallback(
    (
      value: typeof dataSource[number],
      index: number
    ): ITableRenderItemData<typeof value> => {
      return {
        ...value,
        label: itemName()[value.label] || value.label,
        hideOrderButtons: value.index === -1,
        order: value.index === -1 ? '' : index + 1,
        checkbox: (
          <Checkbox
            id=""
            checked={value.index !== -1}
            asyncChange={true}
            onChange={async () => {
              if (value.index === -1) {
                const newValue = [...currentValue, value.value];
                onChange(newValue);
              } else {
                const newValue = [...currentValue];
                newValue.splice(value.index, 1);
                onChange(newValue);
              }
            }}
          />
        )
      };
    },
    [currentValue]
  );
  const onChangeOrder = async (prev: number, next: number) => {
    const arr = _.cloneDeep(dataSource.filter((v) => v.index !== -1));
    const element = arr[prev];
    arr.splice(prev, 1);
    arr.splice(next, 0, element);
    onChange(arr.map((v) => v.id));
  };
  return (
    <Container>
      <Table
        absoluteContainer={true}
        absoluteLoading={loading}
        dataSource={dataSource}
        renderItemData={renderItemData}
        columns={columns}
        dragMarginLeft={true}
        rowHover={true}
        onChangeOrder={onChangeOrder}
        dragAndDropProps={{
          rootList: dataSource,
          onChangeOrder: onChangeOrder
        }}
      />
    </Container>
  );
};

const columns: IColumn[] = [
  {
    title: 'Name',
    key: 'label',
    flex: 'auto'
  },
  {
    title: 'Order',
    key: 'order',
    flex: '80px',
    centered: true
  },
  {
    title: 'Show',
    key: 'checkbox',
    flex: '80px',
    centered: true
  },
  {
    flex: '130px',
    key: '',
    title: '',
    centered: true,
    type: 'change-order'
  }
];

export default React.memo(Component);

import React, { FC, useEffect, useState } from 'react';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { handleNoteClick } from 'src/Framework/Controls/NoteButton';
import NoData from 'src/Framework/Controls/Table/NoData';

import { getNotesV2AttachedToAppt } from 'src/Activities/Counseling/NewNotes/Note/store/Actions';
import { NoteSimpleDto } from 'src/Activities/Counseling/NewNotes/Note/store/types';

import { ContainerBlock, ItemContainer } from './styled';

interface IProps {
  scheduleEvent: {
    appointmentId: number;
    patient: {
      id: number;
    };
  };
}

const Component: FC<IProps> = ({ scheduleEvent }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Record<string, NoteSimpleDto>>({});
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const res = await getNotesV2AttachedToAppt({
        patientId: scheduleEvent.patient.id,
        appointmentId: scheduleEvent.appointmentId
      });
      if (res) {
        setData(res);
      }
      setLoading(false);
    };
    init();
  }, []);
  const list = Object.values(data).filter((v) => v.isActive);
  return (
    <ContainerBlock
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: list.length === 0 ? 'center' : 'flex-start'
      }}
    >
      {loading && <AbsoluteLoader />}
      {list.length === 0 ? (
        <NoData removeVerticalPaddings={true} />
      ) : (
        list.map((v) => (
          <ItemContainer
            onClick={() => {
              handleNoteClick(v.id, v.patientId, true, v.typeNote);
            }}
          >
            {v.summary}
          </ItemContainer>
        ))
      )}
    </ContainerBlock>
  );
};

export default Component;

import React, {
  useImperativeHandle,
  useState,
  useMemo,
  useCallback
} from 'react';
import { Col, Row, Tag, Tabs, TabsProps } from 'antd';

import Modal from 'src/Framework/Controls/Modal';
import Button from 'src/Framework/Controls/Button';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import Radio from 'src/Framework/Controls/Radio';
import AbsoluteScroller from 'src/Framework/Controls/AbsoluteScroller';
import {
  getClinicById,
  getCurrentClinicId
} from 'src/Framework/Controls/Selectors/ClinicSelector';
import { ClinicSelector } from 'src/Framework/Controls/Selectors';
import Table from 'src/Framework/Controls/Table';
import { IColumn } from 'src/Framework/Controls/Table/types';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import ElementTitle from 'src/Framework/Controls/ElementTitle';
import { formattedDate } from 'src/Framework/Shared/Shared';
import { clinicDisplayName } from 'src/Framework/util/format';
import { useAppSelector } from 'src/store';
import ids from 'src/tests-script/pages/Messages';

import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';
import * as newMessagesActions from 'src/Activities/Home/NewMessage/store/Actions';
import { IBuildingForm } from 'src/App/Admin/Pages/FormBuilder/Lists/Forms/store/types';
import { ITemplateForm } from 'src/App/TemplateForms/store/types';
import { IFormItem } from 'src/Activities/Forms/FormList/types';
import * as m1FormRecordActions from 'src/App/M1FormRecords/store/Actions';
import { IM1FormRecord } from 'src/App/M1FormRecords/store/types';

import {
  FormBody,
  TabContainer,
  TagsContainer,
  TabTitle,
  Header,
  MainHeader
} from './styled';

type IItem =
  | {
      type: 'm1';
      value: IBuildingForm;
    }
  | {
      type: 'legacy';
      value: ITemplateForm;
    };

type IItemPatient =
  | {
      type: 'm1';
      value: IM1FormRecord;
    }
  | {
      type: 'legacy';
      value: IFormItem;
    };

type FormatType = 'link' | 'pdf';

interface IProps {
  onChangeForms: (data: {
    formTemplates: IItem[];
    formatType: FormatType;
    patientId?: string | number | null;
    patientForms?: IItemPatient[];
  }) => any;
  message: {
    clinicId?: any;
  };
}

interface IShow {
  patientId?: number | string;
}

export interface IModalHandles {
  show(obj: IShow): void;

  close(): void;
}

const defaultPatientFilter = () => {
  return {
    clinics: [getCurrentClinicId()]
  };
};

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { onChangeForms, message },
  ref
) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [searchPatient, setSearchPatient] = useState<string>('');
  const [selectedForms, setSelectedForms] = useState<IItem[]>([]);
  const [selectedPatientForms, setSelectedPatientForms] = useState<
    IItemPatient[]
  >([]);
  const [forms, setForms] = useState<ITemplateForm[]>([]);
  const [m1TemplateForms, setM1TemplateForms] = useState<IBuildingForm[]>([]);
  const [patientLegacyForms, setPatientLegacyForms] = useState<IFormItem[]>([]);
  const [type, setType] = useState<FormatType>('link');
  const [activeKey, setActiveKey] = useState('1');
  const [patientId, setPatientId] = useState<string | number | null>(null);
  const [patientFilter, setPatientFilter] = useState(defaultPatientFilter());

  const m1AllPatientsForms = useAppSelector(
    (state) => state.m1FormRecords.patients
  );

  const m1PatientForm = useMemo(() => {
    if (patientId) {
      return m1AllPatientsForms[patientId]?.summary || {};
    }
    return {};
  }, [patientId]);

  useModalFocus({ active: visible });

  const getForms = async () => {
    const [data, m1Templates] = await Promise.all([
      newMessagesActions.getForms(),
      newMessagesActions.getM1Forms()
    ]);
    if (data) {
      setForms(Object.values(data));
    }
    if (m1Templates) {
      setM1TemplateForms(Object.values(m1Templates));
    }
  };

  const getPatientForms = async (patientId: string | number) => {
    const [legacyForms] = await Promise.all([
      newMessagesActions.getLegacyPatientForms(patientId),
      m1FormRecordActions.get(patientId)
    ]);
    if (legacyForms) {
      setPatientLegacyForms(legacyForms.filter((v) => v.status === 'locked'));
    }
  };

  const show = async ({ patientId }: IShow) => {
    setVisible(true);
    if (patientId) {
      setPatientId(patientId);
    }
    const init = async () => {
      setLoading(true);
      await getForms();
      if (patientId) {
        await getPatientForms(patientId);
      }
      setLoading(false);
    };
    init();
  };

  const close = () => {
    setVisible(false);
  };

  const setDefault = () => {
    setSearch('');
    setForms([]);
    setSelectedForms([]);
    setM1TemplateForms([]);
    setType('link');
    setActiveKey('1');
    setPatientLegacyForms([]);
    setSearchPatient('');
    setSelectedPatientForms([]);
    setPatientFilter(defaultPatientFilter());
    if (patientId) {
      m1FormRecordActions.unsubscribe(patientId);
    }
  };

  useImperativeHandle(ref, () => ({
    show: (obj: IShow) => show(obj),
    close
  }));

  const handleItem = (value: IItem) => {
    const data = [...selectedForms];
    const index = data.findIndex(
      (v) => v.type === value.type && v.value.id === value.value.id
    );
    if (index !== -1) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setSelectedForms(data);
  };

  const handleItemPatient = (value: IItemPatient) => {
    const data = [...selectedPatientForms];
    const index = data.findIndex(
      (v) => v.type === value.type && v.value.id === value.value.id
    );
    if (index !== -1) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setSelectedPatientForms(data);
  };

  const onClickAdd = async () => {
    await onChangeForms({
      formTemplates: type !== 'pdf' ? selectedForms : [],
      formatType: type,
      patientId,
      patientForms: selectedPatientForms
    });
    close();
  };

  const headerRight = (
    <Button
      id=""
      disabled={selectedForms.length === 0 && selectedPatientForms.length === 0}
      shape="round"
      onClick={onClickAdd}
    >
      Save
    </Button>
  );

  const title = 'Select Form';

  const dataSource = useMemo(() => {
    const filteredForms = (
      [
        ...forms
          .filter((v) => v.isActive)
          .map((value) => ({
            type: 'legacy',
            value
          })),
        ...m1TemplateForms
          .filter((v) => v.isActive && v.latestPublishedVersion)
          .map((value) => ({
            type: 'm1',
            value
          }))
      ] as IItem[]
    )
      .sort((a, b) => (a.value.name || '').localeCompare(b.value.name))
      .filter((v) => {
        if (v.type === 'legacy') {
          return (
            (message.clinicId
              ? v.value.clinicIds.includes(+message.clinicId)
              : false) &&
            v.value.code.toLowerCase().includes(search.toLowerCase())
          );
        }
        if (v.type === 'm1') {
          return (
            (message.clinicId
              ? v.value.clinicIds.includes(+message.clinicId)
              : false) &&
            v.value.name.toLowerCase().includes(search.toLowerCase())
          );
        }
        return;
      });
    return filteredForms;
  }, [search, m1TemplateForms, forms]);

  const renderItemData = useCallback((value: typeof dataSource[number]) => {
    return {
      ...value,
      name:
        value.type === 'legacy'
          ? `${value.value.code}`
          : value.type === 'm1'
          ? `${value.value.name}-m1`
          : '',
      type: value.type === 'legacy' && (
        <ButtonIcon size={20} name={ICONS_LIST.letterL} disableClick={true} />
      ),
      dto: value
    };
  }, []);

  const dataSource2 = useMemo(() => {
    const filteredForms = (
      [
        ...patientLegacyForms.map((value) => ({
          type: 'legacy',
          value
        })),
        ...Object.values(m1PatientForm)
          .filter((v) => v.history.some((v) => v.actionContent === 'Locked'))
          .map((value) => ({
            type: 'm1',
            value
          }))
      ] as IItemPatient[]
    )
      .sort((a, b) =>
        (
          (a.type === 'legacy'
            ? a.value?.templateForm?.name || ''
            : a.value.formTemplateName) || ''
        ).localeCompare(
          (b.type === 'legacy'
            ? b.value?.templateForm?.name || ''
            : b.value.formTemplateName) || ''
        )
      )
      .filter((v) => {
        if (v.type === 'legacy') {
          return (
            (message.clinicId
              ? patientFilter.clinics.length !== 0
                ? patientFilter.clinics.includes(v.value.clinicId)
                : true
              : false) &&
            (v.value?.templateForm?.name || '')
              .toLowerCase()
              .includes(searchPatient.toLowerCase())
          );
        }
        if (v.type === 'm1') {
          return (
            (message.clinicId
              ? patientFilter.clinics.length !== 0
                ? patientFilter.clinics.includes(v.value.clinicId)
                : true
              : false) &&
            (v.value.formTemplateName || '')
              .toLowerCase()
              .includes(searchPatient.toLowerCase())
          );
        }
        return;
      });
    return filteredForms;
  }, [patientLegacyForms, searchPatient, m1PatientForm, patientFilter]);

  const renderItemData2 = useCallback((value: typeof dataSource2[number]) => {
    if (value.type === 'legacy') {
      return {
        ...value,
        lockedDate: formattedDate(value.value.lockedDate),
        name: value.value?.templateForm?.name || '',
        clinic: clinicDisplayName(getClinicById(value.value.clinicId)),
        type: (
          <ButtonIcon size={20} name={ICONS_LIST.letterL} disableClick={true} />
        ),
        dto: value
      };
    }
    const lockedDate = value.value.history
      .filter((v) => v.actionContent === 'Locked')
      .sort(
        (a, b) => new Date(a.dts).getTime() - new Date(b.dts).getTime()
      )[0]?.dts;
    return {
      ...value,
      lockedDate: lockedDate ? formattedDate(lockedDate) : null,
      clinic: clinicDisplayName(getClinicById(value.value.clinicId)),
      name: value.value.formTemplateName,
      dto: value,
      type: null
    };
  }, []);

  const items: TabsProps['items'] = [
    {
      key: patientId ? '1' : '2',
      label: <TabTitle>PATIENT LOCKED FORMS</TabTitle>,
      children: (
        <AbsoluteScroller>
          <TabContainer>
            <Header>
              <ClinicSelector
                multiple={true}
                value={patientFilter.clinics}
                onChange={(clinics) => {
                  setPatientFilter((v) => ({
                    ...v,
                    clinics
                  }));
                }}
                selectProps={{
                  selectProps: {
                    star: false
                  }
                }}
              />
              <SelectContainer
                type="input"
                label="Form Name"
                star={false}
                value={searchPatient}
                bottomMargin={true}
                onChange={(value: string) => {
                  setSearchPatient(value);
                }}
                searchIcon={true}
                inputProps={{
                  placeholder: 'Search by Form Name'
                }}
              />
            </Header>
            <Table
              columns={columns2}
              absoluteContainer={true}
              dataSource={dataSource2}
              renderItemData={renderItemData2}
              rowHover={true}
              onClickRow={(i, value: any) => handleItemPatient(value.dto)}
              dynamicClassNameRow={{
                className: 'selected-form-row',
                onEnabled: (value: ReturnType<typeof renderItemData>) =>
                  selectedPatientForms.some(
                    (v) =>
                      v.type === value.dto.type &&
                      v.value.id === value.dto.value.id
                  )
              }}
            />
          </TabContainer>
        </AbsoluteScroller>
      )
    }
  ];

  if (type !== 'pdf') {
    items.push({
      key: patientId ? '2' : '1',
      label: <TabTitle>FORMS</TabTitle>,
      children: (
        <AbsoluteScroller>
          <TabContainer>
            <Header>
              <SelectContainer
                type="input"
                label="Form Name"
                star={false}
                value={search}
                bottomMargin={true}
                onChange={(value: string) => {
                  setSearch(value);
                }}
                searchIcon={true}
                inputProps={{
                  placeholder: 'Search by Form Name'
                }}
              />
            </Header>
            <Table
              columns={columns1}
              absoluteContainer={true}
              dataSource={dataSource}
              renderItemData={renderItemData}
              rowHover={true}
              onClickRow={(i, value: any) => handleItem(value.dto)}
              dynamicClassNameRow={{
                className: 'selected-form-row',
                onEnabled: (value: ReturnType<typeof renderItemData>) =>
                  selectedForms.some(
                    (v) =>
                      v.type === value.dto.type &&
                      v.value.id === value.dto.value.id
                  )
              }}
            />
          </TabContainer>
        </AbsoluteScroller>
      )
    });
  }

  const onChange = (value: string) => {
    setActiveKey(value);
  };

  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: '700px',
        isOpen: visible,
        afterClose: setDefault,
        headerRight,
        onClose: close,
        title
      }}
      id={ids.MESSAGE_FORMS_MODAL}
      width={1200}
      visible={visible}
      onClose={close}
      title={title}
      afterClose={setDefault}
      buttons={headerRight}
    >
      {loading && <AbsoluteLoader />}
      <FormBody hideTabHeader={patientId === null}>
        <MainHeader>
          {patientId && (
            <>
              <b>Attach Form as:</b>
              <Row align="middle" gutter={[16, 8]}>
                <Col>
                  <Radio
                    id=""
                    checked={type === 'link'}
                    onChange={() => setType('link')}
                  >
                    Link
                  </Radio>
                </Col>
                <Col>
                  <Radio
                    id=""
                    checked={type === 'pdf'}
                    onChange={() => {
                      setType('pdf');
                      setActiveKey('1');
                    }}
                  >
                    PDF
                  </Radio>
                </Col>
              </Row>
            </>
          )}
        </MainHeader>

        {type !== 'pdf' && selectedForms.length > 0 && (
          <TagsContainer
            tabIndex={selectedForms.length > 0 ? 0 : -1}
            aria-label="selected forms"
            role="list"
          >
            <ElementTitle star={false} name="Forms:" />
            <Row gutter={[4, 4]}>
              {selectedForms.map((value) => (
                <Col
                  key={
                    value.type === 'legacy'
                      ? `${value.value.id}-legacy`
                      : value.type === 'm1'
                      ? `${value.value.id}-m1`
                      : ''
                  }
                >
                  <Tag
                    role="listitem"
                    tabIndex={0}
                    closable
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    onClose={() => handleItem(value)}
                    closeIcon={
                      <ButtonIcon
                        isButton={true}
                        label="remove selected form"
                        name={ICONS_LIST.thinClose}
                        size={12}
                        containerProps={{
                          style: {
                            display: 'inline-block'
                          }
                        }}
                      />
                    }
                  >
                    <label>
                      <EllipsisText maxWidth={250}>
                        {value.type === 'legacy'
                          ? value.value.code
                          : value.type === 'm1'
                          ? value.value.name
                          : ''}
                      </EllipsisText>
                    </label>
                  </Tag>
                </Col>
              ))}
            </Row>
          </TagsContainer>
        )}
        {selectedPatientForms.length > 0 && (
          <TagsContainer
            tabIndex={0}
            aria-label="selected patient forms"
            role="list"
          >
            <ElementTitle star={false} name="Patient Locked Forms:" />
            <Row gutter={[4, 4]}>
              {selectedPatientForms.map((value) => (
                <Col
                  key={
                    value.type === 'legacy'
                      ? `${value.value.id}-legacy`
                      : value.type === 'm1'
                      ? `${value.value.id}-m1`
                      : ''
                  }
                >
                  <Tag
                    role="listitem"
                    tabIndex={0}
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    closable
                    onClose={() => handleItemPatient(value)}
                    closeIcon={
                      <ButtonIcon
                        isButton={true}
                        label="remove selected form"
                        name={ICONS_LIST.thinClose}
                        size={12}
                        containerProps={{
                          style: {
                            display: 'inline-block'
                          }
                        }}
                      />
                    }
                  >
                    <label>
                      <EllipsisText maxWidth={250}>
                        {value.type === 'legacy'
                          ? value.value?.templateForm?.name || ''
                          : value.type === 'm1'
                          ? value.value.formTemplateName
                          : ''}
                      </EllipsisText>
                    </label>
                  </Tag>
                </Col>
              ))}
            </Row>
          </TagsContainer>
        )}
        <Tabs
          activeKey={activeKey}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </FormBody>
    </Modal>
  );
};

export default React.forwardRef(Component);

const columns1: IColumn[] = [
  {
    title: 'Template Name',
    flex: 'auto',
    key: 'name',
    ellipsis: true
  },
  {
    title: '',
    flex: '80px',
    key: 'type',
    centered: true
  }
];

const columns2: IColumn[] = [
  {
    title: 'Form Name',
    flex: 'auto',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'Locked Date',
    flex: '100px',
    key: 'lockedDate'
  },
  {
    title: 'Clinic',
    flex: '200px',
    key: 'clinic',
    ellipsis: true
  },
  {
    title: '',
    flex: '80px',
    key: 'type',
    centered: true
  }
];

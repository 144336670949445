import React, { FC, useState, useRef } from 'react';
import { Row, Col } from 'antd';
import _ from 'lodash';

import Button from 'src/Framework/Controls/Button';
import Checkbox from 'src/Framework/Controls/Checkbox';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import CategorySelector from 'src/Framework/Controls/Selectors/CategorySelector';
import MajorsSelector from 'src/Framework/Controls/Selectors/MajorsSelector';
import { debouncer } from 'src/Framework/util/helps';
import ids from 'src/tests-script/pages/Activity/Outreach';

import TypeCampusStatus from 'src/App/GenericTypes/Selectors/TypeCampusStatus';
import TypeClass from 'src/App/GenericTypes/Selectors/TypeClass';
import TypeEligibility from 'src/App/GenericTypes/Selectors/TypeEligibility';
import TypeResidency from 'src/App/GenericTypes/Selectors/TypeResidency';
import TypeSchool from 'src/App/GenericTypes/Selectors/TypeSchool';
import TypeStanding from 'src/App/GenericTypes/Selectors/TypeStanding';

import * as outreachActions from 'src/Activities/Home/Outreach/store/Actions';
import {
  ISearchFilter,
  IOutreachSimpleDto
} from 'src/Activities/Home/Outreach/store/types';

import { Container, Body, Footer, BodyInner } from './styled';

interface IProps {
  defaultGuestListNumber: number;
  defaultFilter: IOutreachSimpleDto['guestListFilter'];
  onClickBack: () => any;
  onSave: (filter: ISearchFilter, guestListNumber: number) => any;
}

export const defaultSearchFilter: ISearchFilter = {
  categoryIds: [],
  foreignSudent: false,
  campusStatusIds: [],
  standingIds: [],
  classIds: [],
  residenceIds: [],
  schoolIds: [],
  eligibilityIds: [],
  majors: []
};

const Component: FC<IProps> = ({
  onClickBack,
  onSave,
  defaultFilter,
  defaultGuestListNumber
}: IProps) => {
  const debounce = useRef(debouncer(1000));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ISearchFilter>(() => {
    if (defaultFilter) {
      try {
        const filter = JSON.parse(defaultFilter);
        return filter;
      } catch (e) {
        console.log({ e });
      }
    }
    return _.cloneDeep(defaultSearchFilter);
  });
  const [total, setTotal] = useState(defaultGuestListNumber);

  const onSearch = (params: ISearchFilter) => {
    debounce.current(async () => {
      setLoading(true);
      const total = await outreachActions.getDefineGuestList(params);
      if (total !== undefined) {
        setTotal(total);
      }
      setLoading(false);
    });
  };
  const onChangeData = (obj: Partial<ISearchFilter>) => {
    setData((data) => {
      const params = {
        ...data,
        ...obj
      };
      onSearch(params);
      return params;
    });
  };
  return (
    <Container id={ids.defineGuestsDialog}>
      <Body>
        <BodyInner>
          <Row gutter={16} style={{ flex: 1, width: '100%' }}>
            <Col span={12} style={{ padding: `20px 20px 0 0` }}>
              <CategorySelector
                id=""
                type="multiple"
                value={data.categoryIds}
                onChange={(categoryIds: any) => {
                  onChangeData({
                    categoryIds
                  });
                }}
                selectProps={{
                  star: false
                }}
              />
              <TypeCampusStatus
                id=""
                name="Campus Status"
                type="multiple"
                value={data.campusStatusIds}
                onChange={(campusStatusIds) => {
                  onChangeData({
                    campusStatusIds
                  });
                }}
                selectProps={{
                  star: false
                }}
              />
              <TypeStanding
                id=""
                type="multiple"
                name="Standing"
                value={data.standingIds}
                onChange={(standings) => {
                  onChangeData({
                    standingIds: standings
                  });
                }}
                selectProps={{
                  star: false
                }}
              />
              <TypeClass
                id=""
                name="Class"
                type="multiple"
                value={data.classIds}
                onChange={(classIds) => {
                  onChangeData({
                    classIds
                  });
                }}
                selectProps={{
                  star: false
                }}
              />
              <Checkbox
                id="foreignSudent"
                checked={data.foreignSudent}
                onChange={() => {
                  onChangeData({
                    foreignSudent: !data.foreignSudent
                  });
                }}
              >
                International
              </Checkbox>
            </Col>
            <Col
              span={12}
              style={{
                borderLeft: '1px solid rgba(0,0,0,0.1)',
                padding: `20px 0 0 20px`
              }}
            >
              <TypeResidency
                id=""
                type="multiple"
                name="Residence"
                value={data.residenceIds}
                onChange={(residenceIds) => {
                  onChangeData({
                    residenceIds
                  });
                }}
                selectProps={{
                  star: false
                }}
              />

              <TypeSchool
                id=""
                type="multiple"
                name="School name"
                value={data.schoolIds}
                onChange={(schools) => {
                  onChangeData({
                    schoolIds: schools
                  });
                }}
                selectProps={{
                  star: false
                }}
              />
              <TypeEligibility
                id=""
                type="multiple"
                name="Eligibility"
                value={data.eligibilityIds}
                onChange={(eligibilityIds) => {
                  onChangeData({
                    eligibilityIds
                  });
                }}
                selectProps={{
                  star: false
                }}
              />
              <MajorsSelector
                value={data.majors || []}
                onChange={(majors) => {
                  onChangeData({
                    majors: majors
                  });
                }}
              />
            </Col>
          </Row>
        </BodyInner>
      </Body>
      <Footer>
        <Row align="middle" justify="space-between" style={{ flex: 1 }}>
          <Col style={{ position: 'relative' }}>
            {loading && <AbsoluteLoader />}
            <span>
              <b>Number of potential guests: </b> {total}
            </span>
          </Col>
          <Row align="middle" gutter={16}>
            <Col>
              <Button id="cancel-button" onClick={() => onClickBack()}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                id="save-button"
                onClick={() => {
                  onSave(data, total);
                  onClickBack();
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Row>
      </Footer>
    </Container>
  );
};

export default React.memo(Component);

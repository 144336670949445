import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import SelectContainer from 'src/Framework/Controls/SelectContainer';
import { IStore } from 'src/index';
import ids from 'src/tests-script/pages/counseling/Dashboard/Referrals';

import { getReqStatuses } from 'src/Activities/Counseling/Dashboard/CounselingReferrals/store/ReferralsActions';

interface IOwnProps {
  statusId: number | null;
  setStatus: (status: IReqStatus) => void;
  onChange?: (status: IReqStatus) => void;
  reset?: boolean;
  withLabel?: boolean;
  onModal?: boolean;
  fontSize?: string;
  validate?: boolean;
}

interface IProps extends IOwnProps {
  statuses: IStore['reqOrders']['reqStatuses'];
}

export interface IReqStatus {
  id: number;
  name: string;
  value: number;
  color: string;
  label: any;
}

function ReqStatusSelect(props: IProps) {
  const [status, setStatus] = useState<IReqStatus | null>(null);
  const [statusOptions, setStatusOptions] = useState<IReqStatus[]>([]);
  const onChangeStatus = (statusId: number) => {
    const status = statusOptions.find((item) => item.id === statusId);
    setStatus(status!);
    // @ts-ignore
    // props.setStatus(status); // NEED TO RECHECK IT, LOOKS REALLY STRANGE.
    props.setStatus(props.statuses[statusId + '']);
    if (props.onChange) props.onChange(status!);
  };

  useEffect(() => {
    if (props.reset) {
      setStatus(null);
    }
  }, [props.reset]);

  useEffect(() => {
    if (props.statusId !== null && statusOptions.length > 0) {
      const selected = statusOptions.find((p) => p.id === props.statusId);
      if (selected) setStatus(selected);
    }
  }, [props.statusId, statusOptions]);

  useEffect(() => {
    if (Object.values(props.statuses).length === 0) {
      getReqStatuses();
    }
  }, []);
  useEffect(() => {
    const options = Object.values(props.statuses)
      .filter(
        (status) => status.typeTxnClassId === 8 && status.isActive === true
      )
      .map((item) => {
        return {
          name: item.name,
          color: item.backColor,
          label: item.name,
          value: item.id,
          id: item.id
        };
      });
    setStatusOptions(options);
  }, [props.statuses]);

  return (
    <SelectContainer
      label={props.withLabel ? 'Status' : ''}
      type="select"
      star={true}
      containerClassName="color-background-selector"
      inputProps={{
        id: ids.REFERRALS_STATUS_SELECTOR,
        style: {
          fontSize: props.fontSize ?? 14,
          backgroundColor: status?.color
        }
      }}
      options={statusOptions}
      value={status?.value}
      onChange={(value: number) => {
        onChangeStatus(value);
      }}
      getPopupContainer={
        props.withLabel || props.onModal
          ? (node: any) => node.parentNode
          : undefined
      }
    />
  );
}

ReqStatusSelect.defaultProps = {
  statusId: null,
  reset: false,
  setStatus: () => {},
  withLabel: true,
  onModal: false,
  validate: true
};

const mapStateToProps = (state: IStore, ownProps: IOwnProps) => {
  return {
    statuses: state.reqOrders.reqStatuses
  };
};

export default connect(mapStateToProps)(ReqStatusSelect);

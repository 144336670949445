import React from 'react';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import { PatientsGroupItemContainer } from './PatientsGroupItem_Styles';

interface IState {}

interface IProps {
  label: string;
  onRemove: () => void;
}

class PatientsGroupItem extends React.Component<IProps, IState> {
  render() {
    const { label, onRemove } = this.props;
    return (
      <PatientsGroupItemContainer>
        <ButtonIcon
          isButton={true}
          label={`remove ${label}`}
          color={globalStyleVariables.red}
          name={ICONS_LIST.thinClose}
          onClick={() => onRemove()}
          size={16}
        />
        <div>{label}</div>
      </PatientsGroupItemContainer>
    );
  }
}

export default PatientsGroupItem;

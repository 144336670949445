import * as Setters from './Setters'
import * as Getters from './Getters'

import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"

import { ISummary } from '../types'

export const get = async (): Promise<ISummary | undefined> => {
    try {
        const { loading, summary } = Getters.reducer()
        if (loading) {
            // Preventing multiple unnecessary requests
            return
        }
        if (Object.values(summary).length === 0) {
            Setters.loading(true)
        }
        const response = await APIHandler.AxiosInstance.get(ApiUrl.GET_FORM_TEMPLATE, {
            withCredentials: true, params: {
                isOnlyLatestForm: true
            }
        })
        if (response.data.success) {
            const data = response.data.result.summary
            Setters.summary(data)
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
        Setters.loading(false)
    }
}
import React, { useImperativeHandle, useState } from 'react';

import Modal from 'src/Framework/Controls/Modal';

import Appointment, { IActionOptions } from './index';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

interface IProps {
  onClosePopup?: () => void;
}

interface IShow {
  options: IActionOptions;
}

export interface IModalHandles {
  show(obj: IShow): void;

  close(): void;
}

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { onClosePopup },
  ref
) => {
  const [options, setOptions] = useState<any | null>(null);
  const [visible, setVisible] = useState(false);
  useModalFocus({ active: visible });

  const show = ({ options }: IShow) => {
    setOptions(options);
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setOptions(null);
  };

  useImperativeHandle(ref, () => ({
    show,
    close
  }));

  const closeHandler = () => {
    close();
    onClosePopup && onClosePopup();
  };

  const defaultWidth = 1080;

  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: closeHandler,
        afterClose: setToDefault,
        hideHeader: true,
        hideCloseButton: true
      }}
      width={defaultWidth}
      title=""
      visible={visible}
      onClose={closeHandler}
      headerBorder={true}
      afterClose={setToDefault}
      hideClose={true}
      isDragable={true}
      modalProps={{
        destroyOnClose: true,
        mask: false
      }}
      hideHeader={true}
    >
      {options && (
        <Appointment
          actionOption={options}
          onClosePopup={() => {
            close();
            onClosePopup && onClosePopup();
          }}
        />
      )}
    </Modal>
  );
};

export default React.forwardRef(Component);

import React from 'react';

import PatientHistory from '../../PatientHistory';

interface IProps {
  patientId: number | null;
}

const AppointmentHistoryDetail = ({ patientId }: IProps) => {
  return (
    <>
      {patientId && (
        <div style={{ width: '100%', height: 'auto' }}>
          <PatientHistory key={patientId} patientId={patientId} />
        </div>
      )}
      {patientId === null && (
        <div style={{ padding: '0 0 24px 24px' }}>
          Select a patient to see appointment history
        </div>
      )}
    </>
  );
};
export default AppointmentHistoryDetail;

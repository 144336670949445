import React, { FC, useState } from 'react';
import { Col, Row } from 'antd';

import SelectContainer from 'src/Framework/Controls/SelectContainer';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { formattedDateTime } from 'src/Framework/Shared/Shared';
import { useAppSelector } from 'src/store';

import { IOnChangeComment } from '.';
import { IComment } from 'src/Activities/Schedule/RightSideBar/WaitingList/Group/types';
import * as actions from '../Actions';
import { getCurrentUserId } from 'src/App/User/Current/Setters';

import {
  ItemContainer,
  UserName,
  Comment,
  DateTime,
  ItemEditContainer,
  EditFooter
} from './styled';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

interface IProps {
  value: IComment;
  viewMode?: boolean;
  onChangeComment?: IOnChangeComment;
  removeItemHorizontalMargins?: boolean;
}

const Component: FC<IProps> = ({
  value,
  viewMode,
  onChangeComment,
  removeItemHorizontalMargins
}: IProps) => {
  const userID = getCurrentUserId();
  const multiline = value.comment.includes(`\n`);
  const showEditButtons = viewMode ? false : value.creatorUserId === userID;
  const users = useAppSelector((state) => state.adminPanel.users.summary);
  const [editMode, setEditMode] = useState(false);
  const [comment, setComment] = useState('');
  const onRemove = async () => {
    const res = await actions.patchAppointmentComments({
      id: value.id!,
      isActive: false
    });
    if (res) {
      if (onChangeComment) {
        onChangeComment(res);
        return res;
      }
    }
    return;
  };
  const onEdit = async () => {
    const res = await actions.patchAppointmentComments({
      id: value.id!,
      comment
    });
    if (res) {
      if (onChangeComment) {
        onChangeComment(res);
        setEditMode(false);
        setComment('');
        return res;
      }
    }
    return;
  };
  if (editMode) {
    return (
      <ItemEditContainer tabIndex={0}>
        <SelectContainer
          type="area"
          label=""
          value={comment}
          onChange={(text: any) => setComment(text)}
          bottomMargin={false}
          areaAutoReplace={true}
          hideLabel={true}
          inputProps={{
            rows: 4,
            maxLength: 255
          }}
        />
        <EditFooter>
          <Row align="middle" gutter={8} justify="end">
            <Col>
              <ButtonIcon
                isButton={true}
                label="save comment"
                disabled={value.comment === comment}
                name={ICONS_LIST.check2}
                asyncOnClick={onEdit}
              />
            </Col>
            <Col>
              <ButtonIcon
                isButton={true}
                label="cancel editing comment"
                iconScale={2.3}
                color={globalStyleVariables.brandGray}
                name={ICONS_LIST.thinClose}
                onClick={() => {
                  setEditMode(false);
                  setComment('');
                }}
              />
            </Col>
          </Row>
        </EditFooter>
      </ItemEditContainer>
    );
  }
  return (
    <ItemContainer
      tabIndex={0}
      role="listitem"
      aria-label={value.comment}
      removeItemHorizontalMargins={removeItemHorizontalMargins}
    >
      <Row align="middle" gutter={16} justify="space-between">
        <Col>
          <UserName>
            {value?.creatorUserId && users[value?.creatorUserId]?.name
              ? users[value?.creatorUserId]?.name
              : ''}
          </UserName>
        </Col>
        {showEditButtons && (
          <Col>
            <Row align="middle" gutter={16}>
              <Col>
                <ButtonIcon
                  isButton={true}
                  label="edit comment"
                  name={ICONS_LIST.edit}
                  size={20}
                  onClick={() => {
                    setEditMode(true);
                    setComment(value.comment);
                  }}
                />
              </Col>
              <Col>
                <ButtonIcon
                  name={ICONS_LIST.delete}
                  isButton={true}
                  label="remove comment"
                  size={20}
                  asyncOnClick={async () => {
                    const res = await onRemove();
                    if (res) {
                      setEditMode(false);
                      setComment('');
                    }
                  }}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      {multiline ? (
        value.comment.split(`\n`).map((item) => <Comment>{item}</Comment>)
      ) : (
        <Comment>{value.comment}</Comment>
      )}
      <DateTime>{formattedDateTime(value.dts)}</DateTime>
    </ItemContainer>
  );
};

export default React.memo(Component);

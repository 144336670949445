import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const OptionContainer = styled.label`
  input[type='radio'] {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    outline: none;
    border: 1px solid #cccccc;
    vertical-align: middle;
  }

  input[type='radio']:checked {
    border: 4px solid ${globalStyleVariables.newBrandingPrimary200};
  }

  input[type='radio']:focus {
    width: 20px;
    height: 20px;
    border: 2px solid ${globalStyleVariables.newBrandingPrimary200};
  }

  input[type='radio']:focus:checked {
    width: 20px;
    height: 20px;
    border: 6px solid ${globalStyleVariables.newBrandingPrimary200};
  }

  span {
    user-select: none;
    padding-left: 12px;
    vertical-align: middle;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
`;

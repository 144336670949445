import {
  getClinicById,
  getClinics
} from 'src/Framework/Controls/Selectors/ClinicSelector';
import { ClinicSimpleQueryResult } from '../Admin/Pages/Clinics/store/types';
import {
  checkForEveryClinicPermission,
  checkPermissions
} from './Permissions/Actions';

export const MODULES = {
  1: 'Appointment/Calendar',
  2: 'User Administration',
  4: 'Patient Info',
  6: 'Daily TXN',
  7: 'Diagnosis Setup',
  8: 'Txn Setup',
  9: 'Appointment Setup',
  14: 'Referral Setup',
  15: 'Provider Setup',
  16: 'Category Setup',
  27: 'Notes',
  32: 'Manufacturer Setup',
  36: 'Medicat Patient Portal Setup',
  40: 'EHR',
  44: 'Triage Setup',
  45: 'Order Manager',
  48: 'Order Manager Status Setup',
  60: 'Immunization',
  72: 'Clinic',
  78: 'Rcopia',
  81: 'Drop-down management',
  84: 'Document Manager',
  88: 'Rule Manager',
  102: 'Activity',
  103: 'Counseling Setup',
  105: 'Activity Dashboard',
  106: 'M1Outreach',
  107: 'Form Builder',
  108: 'Supervision - Training',
  109: 'M1TableauReports',
  110: 'Files',
  300: 'Calendar Events'
} as const;

export const FUNCTIONS = {
  1: 'View',
  2: 'Add/Modify',
  3: 'Add/Modify Appointment',
  4: 'Add/Modify Block',
  7: 'View Pat Diagnosis',
  12: 'Add modify user info',
  23: 'Access',
  24: 'Checkin Appointment',
  98: 'Modify Structured Note',
  97: 'View Structured Note',
  117: 'Mark Results as Read/Unread',
  119: 'Add',
  120: 'Modify',
  129: 'Lock SOAP/Clinical Notes',
  130: 'Sign SOAP/Clinical Notes',
  149: 'New Secure Message',
  163: 'Review Group Summary',
  164: 'View Group Summary Review',
  167: 'Edit Room',
  169: 'Edit Nurse',
  179: 'Change Order Status: Lab',
  180: 'Change Order Status: Radiology',
  181: 'Change Order Status: Rx',
  182: 'Change Order Status: Nurse',
  225: 'Change Order Status: Referral',
  231: 'BreakGlass',
  235: 'Limit User Access to Charts',
  259: 'View secure messages',
  260: 'View Provider Assignment',
  261: 'Add Provider Assignment',
  262: 'Remove Provider Assignment',
  263: 'Open Chart',
  274: 'Discontinue Rcopia',
  278: 'CCAPS',
  290: 'Read Secure Message',
  308: 'Delete Soap/Clinical/M1 Note',
  310: 'Delete Form/Structured Note',
  318: 'View Custom Fields',
  319: 'Edit Custom Fields',
  330: 'Append SOAP/Clinical Note',
  333: 'Edit Note Date',
  335: 'Modify Patient Other ID',
  345: 'Download Attachment',
  350: 'Delete Secure Message',
  507: 'View Lab',
  508: 'View Xray',
  509: 'View Rx',
  510: 'View Nurse',
  511: 'View Referral',
  1000: 'View Consents',
  1001: 'Edit Consent',
  1002: 'Create Consent',
  1010: 'View Hospitalization',
  1011: 'Edit Hospitalization',
  1012: 'Create Hospitalization',
  1013: 'Create Pat Diagnosis',
  1020: 'View Note of concern',
  1022: 'Create note of concern',
  1100: 'View Assessment',
  1101: 'Edit Assessment',
  1102: 'Create Assessment',
  1200: 'Risk Management My Watchlist',
  1201: 'Add/Modify Counseling Note Type',
  1202: 'Risk Management High Risk Patients',
  1203: 'Risk Management Crisis Appts',
  1204: 'Supervisor Dashboard',
  1205: 'Add/Modify Counseling Note',
  1206: 'Manage Supervision-Training Note Types',
  1207: 'M1TableauReportsCounseling',
  1208: 'M1TableauReportsICM',
  1209: 'M1TableauReportsMedical',
  1210: 'Provider Dashboard',
  1211: 'Case Management',
  1212: 'Appointments',
  1213: 'My Clients',
  1214: 'Outreach',
  1215: 'View Unassigned Clients',
  1216: 'View Reports of Concern',
  1217: 'Delete Unassigned Clients',
  1220: 'Preview Files',
  1221: 'Download Files',
  1222: 'Soft Sign SOAP/Clinical Notes',
  1230: 'View Patient List',
  1231: 'View Blaster',
  1232: 'View Referrals',
  1241: 'Resolve comment',
  1242: 'Advanced Access',
  1300: 'View Diagnosis codes by clinic',
  1400: 'Allow Individual Appointment',
  1401: 'Allow Couple Appointment',
  1402: 'Allow Family Appointment',
  1403: 'Allow Group Appointment',
  1404: 'Allow Reservation',
  1405: 'Allow Staff Appointment',
  1406: 'Allow Blocks',
  1407: 'Allow Outreach',
  1408: 'Allow Mass Discharge'
} as const;

type ModuleType = keyof typeof MODULES;
export type FuctionType = keyof typeof FUNCTIONS;

interface ICommon {
  /**
   * Module identifier
   * @type {number} - Check {@link MODULES} to see module's name
   */
  moduleId: ModuleType;
  /**
   * Module identifier
   * @type {number} - Check {@link FUNCTIONS} to see function's name
   */
  functionId: FuctionType;
}

interface ICommonMessageGenerator {
  moduleId: ModuleType;
  functionId: FuctionType;
  moduleName: string;
  funtionName: string;
}

interface IMessageGeneratorAnyClinic extends ICommonMessageGenerator {}

interface IMessageGeneratorOnClinic extends ICommonMessageGenerator {
  clinicId?: number;
  clinic?: ClinicSimpleQueryResult;
}

export interface PermissionCheckingAnyClinic extends ICommon {
  anyClinic: true;
  messageGenerator?: (data: IMessageGeneratorAnyClinic) => string;
}

export interface PermissionCheckingOnClinic extends ICommon {
  anyClinic: false;
  clinicId?: number;
  filterClinic?: (clinic: ClinicSimpleQueryResult) => boolean;
  messageGenerator?: (data: IMessageGeneratorOnClinic) => string;
}

/**
 * @requires anyClinic
 * @param data
 * @returns
 */
export const permissionChecking = (
  data: PermissionCheckingAnyClinic | PermissionCheckingOnClinic
) => {
  const { moduleId, functionId } = data;
  const common = {
    functionId,
    moduleId,
    moduleName: MODULES[moduleId],
    funtionName: FUNCTIONS[functionId]
  };
  if (data.anyClinic) {
    const { messageGenerator } = data;
    const message = messageGenerator
      ? messageGenerator({
          ...common
        })
      : `You don't have ${MODULES[moduleId]} ${FUNCTIONS[functionId]} permissions on any clinic`;
    const permission = checkForEveryClinicPermission(moduleId, functionId);
    return {
      ...common,
      success: permission ? true : false,
      message: !permission ? message : ''
    };
  } else {
    const { filterClinic } = data;
    if (filterClinic) {
      const { messageGenerator, filterClinic } = data;
      const list = getClinics();
      const clinics = filterClinic
        ? list.filter((clinic) => filterClinic(clinic))
        : list;
      const permissions = clinics.map((clinic) => ({
        clinic,
        hasPermission: checkPermissions(clinic.id, moduleId, functionId)
      }));
      const persmission = permissions.some((v) => v.hasPermission);
      const message = messageGenerator
        ? messageGenerator({
            ...common
          })
        : `You don't have ${MODULES[moduleId]} ${
            FUNCTIONS[functionId]
          } permissions on ${permissions
            .filter((v) => !v.hasPermission)
            .map((v) => v.clinic.name)
            .join(', ')} clinic(s)`;
      return {
        ...common,
        success: persmission ? true : false,
        message: !persmission ? message : ''
      };
    } else {
      const { clinicId, messageGenerator } = data;
      const persmission = checkPermissions(clinicId, moduleId, functionId);
      const clinic = clinicId ? getClinicById(clinicId) : undefined;
      const message = messageGenerator
        ? messageGenerator({
            ...common,
            clinicId,
            clinic
          })
        : `You don't have ${MODULES[moduleId]} ${
            FUNCTIONS[functionId]
          } permissions on ${clinic?.name || 'clinic'}`;
      return {
        ...common,
        clinic: clinic ? clinic : undefined,
        success: persmission ? true : false,
        message: !persmission ? message : ''
      };
    }
  }
};

import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import {
  addMessagesOverview,
  updateMessagesOverview,
  IMessagesOverview,
  addMessage
} from '../Reducer';
import { expiryCheck } from 'src/App/LoginPage/store/Actions';

export interface ISubjectOverview {
  cohorts: ICohortDetails;
  contactInfo: ISubjectContactInfo;
  name: ISubjectNameInfo;
  other: ISubjectOtherInfo;
  members: number;
  personalInfo: ISubjectPersonalInfo;
  subjectId: number;
  required: number;
  nonRequired: number;
}

export interface ICohortDetails {
  cohortId: number;
  cohortName: string;
}

export interface ISubjectContactInfo {
  email: string;
  mobile: number;
}

export interface ISubjectNameInfo {
  firstName: string;
  lastName: string;
  middleName: string;
  nickName: string;
  proNoun: string;
}

export interface ISubjectOtherInfo {
  activeStatus: string;
  class: string;
  enrollmentDate: string;
  international: boolean;
  residency: string;
  school: string;
  standing: string;
  status: string;
}

export interface ISubjectPersonalInfo {
  age: number;
  dob: Date;
  gender: string;
  sex: string;
}

export const subjectMessagesCount = 20;

export const getMessagesOverview = (
  currentPage = 1,
  count = subjectMessagesCount,
  subjectId: number,
  sortBy: any,
  searchText: string,
  callBack: any
): any => {
  let paginationDetails = {
    startIndex: (currentPage - 1) * count,
    sortBy: sortBy,
    count: count,
    subjectId: subjectId,
    Filter: {
      searchString: searchText,
      direction: 'in'
    }
  };

  return (dispatch: any) => {
    APIHandler.AxiosInstance.post(
      ApiUrl.GET_SUBJECT_MESSAGES,
      paginationDetails,
      { withCredentials: true }
    )
      .then((response) => {
        if (callBack) {
          callBack();
        }
        let data: any = response.data;
        if (data.success && data.result) {
          let pagination = {
            total: data.result.total,
            current: currentPage
          };
          let messagesData = data.result.summary.find(
            (m: IMessagesOverview) => m.subjectId === subjectId
          );
          dispatch(
            addMessagesOverview(
              messagesData,
              pagination,
              paginationDetails.Filter.direction
            )
          );
        }
      })
      .catch((error) => {
        if (callBack) callBack();
        dispatch(expiryCheck(error));
        console.log(error);
      });
  };
};

export const getMessagesOverviewSent = (
  currentPage = 1,
  count = subjectMessagesCount,
  subjectId: number,
  sortBy: any,
  searchText: string,
  callBack: any
): any => {
  let paginationDetails = {
    startIndex: (currentPage - 1) * count,
    sortBy: sortBy,
    count: count,
    subjectId: subjectId,
    Filter: {
      searchString: searchText,
      direction: 'out'
    }
  };

  return (dispatch: any) => {
    APIHandler.AxiosInstance.post(
      ApiUrl.GET_SUBJECT_MESSAGES,
      paginationDetails,
      { withCredentials: true }
    )
      .then((response) => {
        if (callBack) {
          callBack();
        }
        let data: any = response.data;
        if (data.success && data.result) {
          let pagination = {
            total: data.result.total,
            current: currentPage
          };
          if (data.result.summary) {
            let messagesData = data.result.summary.find(
              (m: IMessagesOverview) => m.subjectId === subjectId
            );
            dispatch(
              addMessagesOverview(
                messagesData,
                pagination,
                paginationDetails.Filter.direction
              )
            );
          }
        }
      })
      .catch((error) => {
        if (callBack) callBack();
        dispatch(expiryCheck(error));
        console.log(error);
      });
  };
};

export const getMessage = (messageDetailId: number, callBack?: any): any => {
  let Details = {
    messageId: messageDetailId
  };

  return (dispatch: any) => {
    APIHandler.AxiosInstance.post(ApiUrl.GET_SUBJECT_MESSAGE, Details, {
      withCredentials: true
    })
      .then((response) => {
        let data: any = response.data;
        if (data.success) {
          let messagesData = data.result;
          dispatch(addMessage(messagesData, messageDetailId));
          if (callBack) {
            callBack();
          }
        }
      })
      .catch((error) => {
        dispatch(expiryCheck(error));
        console.log(error);
      });
  };
};

export const updateReadStatus = (
  messageId: number,
  readStatus: boolean,
  flagForFollowup: boolean,
  followupDate: Date,
  subjectId: number,
  type: string,
  messageType: string
) => {
  let Details = {
    messageId: messageId,
    readStatus: readStatus,
    flagForFollowup: flagForFollowup,
    followupDate: followupDate,
    subjectId: subjectId
  };

  return (dispatch: any) => {
    APIHandler.AxiosInstance.post(ApiUrl.UPDATE_READ_STATUS, Details, {
      withCredentials: true
    })
      .then((response) => {
        let data: any = response.data;
        if (data.success) {
          dispatch(updateMessagesOverview(Details, type, messageType));
        }
      })
      .catch((error) => {
        dispatch(expiryCheck(error));
        console.log(error);
      });
  };
};

import _ from 'lodash'
import store from 'src/store';
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"

import * as providerActions from 'src/App/Admin/Pages/Providers/store/Actions'

import { IPatientProviderAssignmentSimpleDto } from './types'

import { actionsTypes } from './Reducer'

export const onChangeSort = (patientId: string, payload: any) => {
    store.dispatch({
        type: actionsTypes.SET_SORT,
        payload,
        patientId
    })
}

const setProviders = (payload: any, patientId: string | number) => {
    store.dispatch({
        type: actionsTypes.SET,
        payload,
        patientId
    })
}

const changeItem = (patientId: string | number, payload: IPatientProviderAssignmentSimpleDto) => {
    const data = store.getState().patientsProviders.patients[patientId]?.summary || {}
    data[payload.id] = { ...payload }
    store.dispatch({
        type: actionsTypes.SET,
        payload: data,
        patientId
    })
}

export const get = async (patientId: string | number) => {
    try {
        const response = await APIHandler.AxiosInstance.get(`${ApiUrl.GET_PATIENT_ASSIGNMENTS}`, {
            withCredentials: true,
            params: {
                patientId
            }
        })
        if (response.data.success) {
            const data = response.data.result.summary
            setProviders(data, patientId)
            return data
        }
        return
    } catch (e) {
        return
    }
}

export const patch = async (data: Partial<IPatientProviderAssignmentSimpleDto>) => {
    try {
        const response = await APIHandler.AxiosInstance.patch(`${ApiUrl.GET_PATIENT_ASSIGNMENTS}/${data.id}`, {
            ...data,
        }, {
            withCredentials: true,
            interceptError: true
        })
        if (response.data.success) {
            providerActions.getAllproviders()
            const data: any = Object.values(response.data.result.summary)[0]
            changeItem(data.patient.id, data)
            return data
        }
        return
    } catch (e) {
        return
    }
}

export const post = async (data: any, patientId: string | number,) => {
    try {
        const response = await APIHandler.AxiosInstance.post(`${ApiUrl.GET_PATIENT_ASSIGNMENTS}`, {
            ...data,
            patientId,
            dts: new Date()
        }, {
            withCredentials: true,
            interceptError: true
        })
        if (response.data.success) {
            providerActions.getAllproviders()
            const item: any = Object.values(response.data.result.summary)[0]
            changeItem(patientId, item)
            return item
        }
        return
    } catch (e) {
        return
    }
}
import React from 'react';

interface IProps {
  fill?: string;
  stroke?: string;
}

export const CircleDownSvg = ({ fill, stroke }: IProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill={fill || '#F7F7F7'} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6464 13.6464C11.8417 13.4512 12.1583 13.4512 12.3536 13.6464L16 17.2929L19.6464 13.6464C19.8417 13.4512 20.1583 13.4512 20.3536 13.6464C20.5488 13.8417 20.5488 14.1583 20.3536 14.3536L16.3536 18.3536C16.1583 18.5488 15.8417 18.5488 15.6464 18.3536L11.6464 14.3536C11.4512 14.1583 11.4512 13.8417 11.6464 13.6464Z"
      fill={stroke || '#3A5B8B'}
    />
  </svg>
);

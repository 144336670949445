import { ISort } from "src/Framework/Controls/Table/types"
import store from "src/store"
import { actionsTypes } from '../index'
import { IFilter, IOutreachSimpleDto } from '../types'
import * as Getters from './Getters'

export const setItem = (payload: IOutreachSimpleDto) => {
    const summary = Getters.getSummary()
    summary[payload.id] = {
        ...payload
    }
    setSummary({ ...summary })
}

export const setSummary = (payload: Record<string, IOutreachSimpleDto>) => {
    store.dispatch({
        type: actionsTypes.SET,
        payload
    })
}

export const setLoading = (payload: boolean) => {
    store.dispatch({
        type: actionsTypes.SET_LOADING,
        payload
    })
}

export const setFilter = (payload: Partial<IFilter>) => {
    const data = {...payload}
    if(data.pageNumber === undefined) {
        data.pageNumber = 0
    }
    store.dispatch({
        type: actionsTypes.SET_FILTER,
        payload:data
    })
}

export const setSort = (payload: ISort) => {
    store.dispatch({
        type: actionsTypes.SET_SORT,
        payload
    })
}
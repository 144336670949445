import React from 'react';
import _ from 'lodash';
import { TimeBarContainer, TimeLine, BigTimeLineLabel } from './TimeBar_Styles';

import { defaultTimeLines } from 'src/Activities/Schedule/InitData';
import { getStandartTimeFromMilitaryTime } from '../../Utils/Utils';
import { ScheduleTimeProps } from '../../Common/Interfaces';

interface IState {}

interface IProps {
  widget?: boolean;
  schedules: any[];
  regularMeetingTime: any;
  showConflict?: boolean;
  scheduleTimeProps: ScheduleTimeProps;
}

class TimeBar extends React.Component<IProps, IState> {
  shouldComponentUpdate(nextProps: IProps) {
    return !_.isEqual(nextProps, this.props);
  }

  render() {
    const { schedules, showConflict, scheduleTimeProps } = this.props;
    const { regularMeetingTime, slotHeight } = scheduleTimeProps;
    let timeLines: any[] = [];
    if (schedules) {
      timeLines = schedules.map((schedule) => {
        const arr = schedule.startTime.split(':');
        return {
          label: schedule.startTime,
          isBig: arr[1] !== '00',
          noShow: schedule['noshow'],
          plShow: schedule['plshow']
        };
      });
    } else {
      timeLines = _.cloneDeep(defaultTimeLines);
    }
    return (
      <TimeBarContainer>
        {timeLines.map((tl: any, index) => {
          let time = getStandartTimeFromMilitaryTime(tl.label);
          if (time[1] === ':') {
            time = '0' + time;
          }
          let bigLabel: boolean = parseInt(time[3] + time[4]) == 0;

          let obj: any = {};
          if (showConflict) {
            if (regularMeetingTime < 20) {
              bigLabel =
                parseInt(time[3] + time[4]) == 0 ||
                parseInt(time[3] + time[4]) == 30;
            }

            obj = {
              plShow: tl.plShow,
              bottomBorderShow:
                tl.noShow &&
                timeLines[index + 1] &&
                !timeLines[index + 1].noShow,
              topBorderShow:
                (tl.noShow &&
                  timeLines[index - 1] &&
                  !timeLines[index - 1].noShow) ||
                (tl.noShow && !timeLines[index - 1]),
              noShow: tl.noShow,
              isShown:
                parseInt(time[3] + time[4]) % regularMeetingTime === 0
                  ? 'true'
                  : 'false'
            };
          }
          return (
            <TimeLine
              key={JSON.stringify(tl)}
              style={{
                borderTop: !tl.isBig
                  ? 'solid #E6E6E6 2px'
                  : 'dashed #E6E6E6 2px'
              }}
              slotHeight={slotHeight}
              {...obj}
            >
              {bigLabel ? (
                <BigTimeLineLabel widget={this.props.widget}>
                  {time}
                </BigTimeLineLabel>
              ) : null}
            </TimeLine>
          );
        })}
        <div style={{ height: '0.7rem' }} />
      </TimeBarContainer>
    );
  }
}

export default TimeBar;

import React, { FC, useEffect, useState } from 'react';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

import { PopupContainer } from 'src/Activities/Schedule/Popups/EventPopup/styled';
import { IProps as IMainProps } from 'src/Activities/Schedule/Popups/ScheduleDetailPopup/Main';
import { getEvent } from 'src/Activities/Schedule/Store/Events/Actions';
import { IProviderEvent } from 'src/Activities/Schedule/Store/Events/types';
import EventInfo from './EventInfo';

type IMain = Omit<IMainProps, 'data' | 'subjectSummary'>;

interface IProps extends IMain {
  eventId: number;
  sliceId?: any;
  currentProviderId?: number;
}

const Component: FC<IProps> = ({ eventId, ...otherProps }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IProviderEvent | null>(null);
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const eventInfo = await getEvent(eventId);
      if (eventInfo) {
        setData(eventInfo);
      }
      setLoading(false);
    };
    init();
  }, []);

  return (
    <PopupContainer>
      {loading && <AbsoluteLoader />}
      {data && <EventInfo data={data} setData={setData} {...otherProps} />}
    </PopupContainer>
  );
};

export default Component;

import React, { FC, useCallback } from 'react';
import { IApptResponse } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';

import Table from 'src/Framework/Controls/Table';
import Icon from 'src/Framework/Controls/Icon';
import NoData from 'src/Framework/Controls/Table/NoData';

import Name from '../Components/Name';
import Form from '../Form';
import { formsContent } from '../GroupMembersDetail/index';

import { Container } from './styled';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import {
  ActivityPath,
  gotoClient
} from 'src/App/ActivityPanel/Store/ActivityActions';
import { getReasonByID } from 'src/Framework/Controls/Selectors/ReasonSelector/utils';

interface IProps {
  appointment: IApptResponse;
}

const Component: FC<IProps> = ({ appointment }: IProps) => {
  const renderItemData = useCallback(
    (value: typeof appointment.cancelledPatientsInfo[number]) => ({
      name: <Name patient={value} />,
      reason: value.cancellation
        ? getReasonByID(value.cancellation.cancelReasonId)?.name || ''
        : '',
      note: value.comment,
      form: (
        <Form
          appointment={appointment}
          patient={value}
          formsContent={formsContent}
        />
      ),
      user: (
        <Icon
          size={18}
          name="user"
          tooltip={`${getPatientTerm('Subject')} Info`}
          onClick={() =>
            gotoClient(value.subjectId || value.patientId, ActivityPath.CLIENT_DEMOGRAPHICS, value)
          }
        />
      )
    }),
    [appointment]
  );
  return (
    <Container>
      {appointment.cancelledPatientsInfo.length === 0 ? (
        <NoData />
      ) : (
        <Table
          dataSource={appointment.cancelledPatientsInfo}
          renderItemData={renderItemData}
          columns={columns}
        />
      )}
    </Container>
  );
};

export default React.memo(Component);

const columns = [
  {
    title: 'Name',
    key: 'name',
    flex: '200px'
  },
  {
    title: 'Reason for removal',
    key: 'reason',
    flex: '180px'
  },
  {
    title: 'Note',
    key: 'note',
    flex: 'auto'
  },
  {
    title: '',
    key: 'form',
    flex: '30px',
    centered: true
  },
  {
    title: '',
    key: 'user',
    flex: '30px',
    centered: true
  }
];

import React from 'react';
import * as _ from 'lodash';
import { List } from 'antd';

import { GridWrapper } from 'src/Framework/Controls/Select/Grid/Styles';
import Table from 'src/Framework/Controls/Table';
import Checkbox from 'src/Framework/Controls/Table/Checkbox';
import RemoveButton from 'src/Framework/Controls/Table/RemoveButton';
import TemplateForm, {
  IDTOItem,
  Type
} from 'src/Framework/Controls/TemplatesForm';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { IColumn } from 'src/Framework/Controls/Table/types';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';

import Frequency from 'src/App/Admin/Pages/AppointmentTypes/Forms/Frequency';
import { showM1FormsInAppts } from 'src/App/Admin/Pages/AppointmentTypes/utils';

import {
  PatientContainer,
  PatientSelectContainer,
  Footer
} from 'src/Activities/Schedule/Popups/AppointmentPopup/AppointmentPopup_Styles';
import { SaveRecurringButtonContainer } from 'src/Activities/Schedule/Common/Editors/ScheduleEditor_Styles';
import * as container from 'src/Activities/Counseling/Dashboard/styles';

interface IState {
  searchData: any;
  searchText: string;
  displayText: string;
  waitFor: any;
  newEventPayload: any;
  searchOptions: any;
}

interface IProps {
  selectedClinic: any;
  selectedReason: any;
  newEventPayload: any;
  forms: any;
  onSelectionChanged: any;
  onSelectSettings: any;
  onChangeSelectedForms: any;
  recurrence: any;
  editSingleSeries: boolean;
}

class SettingsAppointment extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      searchData: [],
      searchText: '',
      displayText: '',
      waitFor: '',
      newEventPayload: this.props.newEventPayload,
      searchOptions: []
    };
  }

  componentDidMount() {
    if (this.props.forms) {
      let searchOptions: any[] = [];
      let sortedSearchOptions: any = [];
      this.props.forms.clinicForms &&
        Object.values(this.props.forms.clinicForms)?.map((item: any) => {
          searchOptions.push({
            ...item,
            value: item.name,
            label: item.name
          });
        });
      sortedSearchOptions = _.sortBy(searchOptions, (item: any) =>
        item.name?.toLowerCase()
      );
      this.setState({
        searchOptions: sortedSearchOptions
      });
      if (this.props.newEventPayload) {
        this.setState({
          newEventPayload: this.props.newEventPayload
        });
      }
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (!_.isEqual(this.state.newEventPayload, prevState.newEventPayload)) {
      this.props.onChangeSelectedForms(this.state.newEventPayload);
    }
    if (!_.isEqual(this.props.forms, prevProps.forms)) {
      let searchOptions: any[] = [];
      let sortedSearchOptions: any = [];
      this.props.forms.clinicForms &&
        Object.values(this.props.forms.clinicForms)?.map((item: any) => {
          searchOptions.push({
            ...item,
            value: item.name,
            label: item.name
          });
        });
      sortedSearchOptions = _.sortBy(searchOptions, (item: any) =>
        item.name?.toLowerCase()
      );
      this.setState({
        searchOptions: sortedSearchOptions
      });
    }
  }

  onSelectionChanged(item: any) {
    let { newEventPayload } = this.state;
    let { recurrence, editSingleSeries } = this.props.recurrence;
    newEventPayload['appointmentForms'] = newEventPayload['seriesForms'] = [];
    item.api.forEachNode(function (rowNode: any) {
      if (recurrence) {
        if (editSingleSeries) {
          newEventPayload['appointmentForms'].push(rowNode.data);
          newEventPayload['seriesForms'] = [];
        } else {
          newEventPayload['seriesForms'].push(rowNode.data);
        }
      } else {
        newEventPayload['appointmentForms'].push(rowNode.data);
      }
    });
    this.setState({
      newEventPayload: { ...newEventPayload }
    });
    this.props.onChangeSelectedForms({ ...newEventPayload });
  }

  onClickConfirm() {
    this.props.onSelectionChanged(this.state.newEventPayload);
  }

  onFormSelect(
    data: any[],
    formId: number,
    type: typeof Type.m1 | typeof Type.legacy
  ) {
    let options = this.state.searchOptions.filter((v: any) => !v.inactive);
    let form: any;
    if (type === Type.legacy) {
      form = options.find((item: any) => item.seedFormId === formId);
    } else {
      form = options.find((item: any) => item.m1FormId === formId);
    }
    let newEventPayload = _.cloneDeep(this.state.newEventPayload);
    newEventPayload['appointmentForms'] = newEventPayload['appointmentForms']
      ? newEventPayload['appointmentForms']
      : [];
    newEventPayload['seriesForms'] = newEventPayload['seriesForms']
      ? newEventPayload['seriesForms']
      : [];
    if (form) {
      if (data.length > 0) {
        let index = data.findIndex((item: any) => {
          if (form.isM1Form) {
            return item.m1FormId === form.m1FormId;
          } else {
            return item.seedFormId === form.seedFormId;
          }
        });
        if (index === -1) {
          if (this.props.recurrence) {
            if (this.props.editSingleSeries) {
              newEventPayload['appointmentForms'].push(form);
              newEventPayload['seriesForms'] = [];
            } else {
              newEventPayload['seriesForms'].push(form);
            }
          } else {
            newEventPayload['appointmentForms'].push(form);
          }
        }
      } else {
        if (this.props.recurrence) {
          if (this.props.editSingleSeries) {
            newEventPayload['appointmentForms'].push(form);
            newEventPayload['seriesForms'] = [];
          } else {
            newEventPayload['seriesForms'].push(form);
          }
        } else {
          newEventPayload['appointmentForms'].push(form);
        }
      }
      this.setState({
        newEventPayload: newEventPayload
      });
    }
  }

  deleteHandler(itemToRemove: any) {
    const { newEventPayload } = this.state;
    let newData: any = [];
    let appointmentForms =
      (newEventPayload.appointmentForms &&
        _.cloneDeep(newEventPayload.appointmentForms).filter(
          (v: any) => !v.inactive
        )) ||
      [];
    let seriesForms =
      (newEventPayload.seriesForms &&
        _.cloneDeep(newEventPayload.seriesForms).filter(
          (v: any) => !v.inactive
        )) ||
      [];
    if (this.props.recurrence && !this.props.editSingleSeries) {
      newData = seriesForms.filter((item: any) => {
        if (itemToRemove.formId) {
          return item.formId !== itemToRemove.formId;
        } else {
          return item.m1FormId !== itemToRemove.m1FormId;
        }
      });
    } else {
      newData = appointmentForms.filter((item: any) => {
        if (itemToRemove.formId) {
          return item.formId !== itemToRemove.formId;
        } else {
          return item.m1FormId !== itemToRemove.m1FormId;
        }
      });
    }
    this.setState({
      newEventPayload: {
        ...newEventPayload,
        appointmentForms:
          this.props.recurrence && !this.props.editSingleSeries
            ? this.state.newEventPayload.appointmentForms
            : newData,
        seriesForms:
          this.props.recurrence && !this.props.editSingleSeries
            ? newData
            : this.state.newEventPayload.seriesForms
      }
    });
  }

  viaPortalHandler(currentItem: any) {
    const { newEventPayload } = this.state;
    let newData: any =
      this.props.recurrence && !this.props.editSingleSeries
        ? _.cloneDeep(newEventPayload.seriesForms)
        : _.cloneDeep(newEventPayload.appointmentForms);
    const changedItem = newData.find((item: any) => {
      if (currentItem.formId) {
        return item.formId === currentItem.formId;
      } else {
        return item.m1FormId === currentItem.m1FormId;
      }
    });
    changedItem.viaPortal = !changedItem.viaPortal;
    this.setState({
      newEventPayload: {
        ...newEventPayload,
        appointmentForms:
          this.props.recurrence && !this.props.editSingleSeries
            ? this.state.newEventPayload.appointmentForms
            : newData,
        seriesForms:
          this.props.recurrence && !this.props.editSingleSeries
            ? newData
            : this.state.newEventPayload.seriesForms
      }
    });
  }

  showOnCheckInHandler(currentItem: any) {
    const { newEventPayload } = this.state;
    let newData: any =
      this.props.recurrence && !this.props.editSingleSeries
        ? _.cloneDeep(newEventPayload.seriesForms)
        : _.cloneDeep(newEventPayload.appointmentForms);
    const changedItem = newData.find((item: any) => {
      if (currentItem.formId) {
        return item.formId === currentItem.formId;
      } else {
        return item.m1FormId === currentItem.m1FormId;
      }
    });
    changedItem.showOnCheckIn = !changedItem.showOnCheckIn;
    this.setState({
      newEventPayload: {
        ...newEventPayload,
        appointmentForms:
          this.props.recurrence && !this.props.editSingleSeries
            ? this.state.newEventPayload.appointmentForms
            : newData,
        seriesForms:
          this.props.recurrence && !this.props.editSingleSeries
            ? newData
            : this.state.newEventPayload.seriesForms
      }
    });
  }

  render() {
    const { newEventPayload } = this.state;
    let data: any = [];
    if (newEventPayload) {
      let appointmentForms = Object.values(
        newEventPayload.appointmentForms || {}
      ).filter((v: any) => !v.inactive);
      let seriesForms = Object.values(newEventPayload.seriesForms || {}).filter(
        (v: any) => !v.inactive
      );
      if (this.props.recurrence) {
        if (this.props.editSingleSeries) {
          data = appointmentForms;
        } else {
          data = seriesForms;
        }
      } else {
        data = appointmentForms;
      }
    }
    data.sort((a: any, b: any) => (a.name || '').localeCompare(b.name || ''));
    // let options = this.state.searchOptions.filter((v: any) => !v.inactive);

    const onFilter = (formDTO: IDTOItem) => {
      if (formDTO.legacy) {
        return (
          formDTO.legacy.clinicIds.some(
            (v) => v === this.props.selectedClinic.id
          ) && !data.some((v: any) => v.seedFormId === formDTO.legacy?.seedId)
        );
      }
      if (formDTO.m1) {
        return (
          formDTO.m1.clinicIds.some(
            (v) => v === this.props.selectedClinic.id
          ) && !data.some((v: any) => v.m1FormId === formDTO.m1?.id)
        );
      }
      return false;
    };

    const getFormRecurrence = (currentItem: any) => {
      return (
        <Frequency
          value={{ ...currentItem, templateFormSeedId: currentItem.formId }}
          onChangeDataItem={(oldData, newData) => {
            const { newEventPayload } = this.state;
            let temporaryData: any =
              this.props.recurrence && !this.props.editSingleSeries
                ? _.cloneDeep(newEventPayload.seriesForms)
                : _.cloneDeep(newEventPayload.appointmentForms);
            const changedItem = temporaryData.find(
              (item: any) => item.formId === currentItem.formId
            );
            changedItem.frequency = newData.frequency + '';
            this.setState({
              newEventPayload: {
                ...newEventPayload,
                appointmentForms:
                  this.props.recurrence && !this.props.editSingleSeries
                    ? this.state.newEventPayload.appointmentForms
                    : temporaryData,
                seriesForms:
                  this.props.recurrence && !this.props.editSingleSeries
                    ? temporaryData
                    : this.state.newEventPayload.seriesForms
              }
            });
          }}
          onChangeForm={() => {}}
        />
      );
    };

    const renderItemData = (item: any) => {
      // maybe not needed
      // const reasonForms = Object.values(
      //   this.props.forms.appointmentReasonForms
      // );
      // let nonAutoselectedReason: any = null;
      // if (item.isM1Form) {
      //   const tempReason = reasonForms.find(
      //     (reason: any) =>
      //       reason.isM1Form &&
      //       !reason.selected &&
      //       reason.m1FormId === item.m1FormId
      //   );
      //   if (tempReason) {
      //     nonAutoselectedReason = tempReason;
      //   }
      // }
      // if (!item.isM1Form) {
      //   const tempReason = reasonForms.find(
      //     (reason: any) =>
      //       !reason.isM1Form &&
      //       !reason.selected &&
      //       reason.seedFormId === item.seedFormId
      //   );
      //   if (tempReason) {
      //     nonAutoselectedReason = tempReason;
      //   }
      // }

      return {
        ...item,
        legacyType: item.seedFormId !== null && (
          <ButtonIcon disableClick={true} name={ICONS_LIST.letterL} size={24} />
        ),
        name: <label>{item.name}</label>,
        delete: (
          <RemoveButton
            id={`form-${item.formId}-remove-button`}
            approve={true}
            approveText="Are you sure you want to delete it?"
            removeAction={async () => this.deleteHandler(item)}
          />
        ),
        frequency:
          this.props.recurrence && !this.props.editSingleSeries
            ? getFormRecurrence(item)
            : null,
        viaPortal: (
          <Checkbox
            onChangeAction={async () => this.viaPortalHandler(item)}
            value={item.allowViaPortal ? item.viaPortal : false}
            checkboxProps={{
              id: `form-${item.formId}-via-portal-checkbox`,
              disabled: !item.allowViaPortal
            }}
            tooltip={
              item.allowViaPortal
                ? undefined
                : 'This form cannot be sent to Patient Portal, to enable it go to the forms setup under the appt type in admin page'
            }
          />
        ),
        showOnCheckIn: (
          <Checkbox
            onChangeAction={async () => this.showOnCheckInHandler(item)}
            value={item.showOnCheckIn}
            checkboxProps={{
              id: `form-${item.formId}-show-on-checkin-checkbox`
            }}
          />
        )
      };
    };

    const columns: IColumn[] = [
      {
        title: 'Form name',
        key: 'name',
        flex: 'auto'
      },
      {
        title: 'Frequency',
        key: 'frequency',
        flex: '512px',
        style: { visibility: this.props.recurrence ? 'visible' : 'hidden' }
      },
      {
        title: 'Via Portal',
        key: 'viaPortal',
        flex: '96px',
        centered: true
      },
      {
        title: 'Show on Check In',
        tooltipText: `Allow the form to be sent to the ${getPatientTerm(
          'student'
        )} via PPT once the ${getPatientTerm('student')} is checked in`,
        key: 'showOnCheckIn',
        flex: '110px',
        centered: true
      },
      {
        title: '',
        key: 'legacyType',
        flex: '80px',
        centered: true
      },
      {
        title: 'Delete',
        key: 'delete',
        flex: '96px',
        centered: true
      }
    ];
    return (
      <PatientContainer
        style={{
          position: 'absolute',
          left: '0',
          bottom: 0,
          top: 0,
          right: 0,
          background: '#ffffff',
          padding: '15px 0 0 0'
        }}
      >
        <PatientSelectContainer
          id={'form-table'}
          style={{ padding: '0 24px 24px', width: '100%' }}
        >
          <TemplateForm
            label="Search Form"
            id="templateFormSearch"
            valueLegacy={null}
            valueM1={null}
            onChange={(value: number | null, type) => {
              if (value && type) {
                this.onFormSelect(data, value, type);
              }
            }}
            filter={onFilter}
            hideM1Forms={!showM1FormsInAppts()}
            bottomMargin={false}
            inputProps={{
              allowClear: true
            }}
          />
        </PatientSelectContainer>
        <container.TableContainer>
          <GridWrapper
            style={{
              width: '100%',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              flex: 1
            }}
            className="ag-theme-alpine ag__theme__custom"
          >
            {data.length === 0 ? (
              <List dataSource={[]} />
            ) : (
              <Table
                containerStyleProps={{
                  borderTop: '1px solid #0000001f'
                }}
                absoluteContainer={true}
                dataSource={data}
                columns={columns}
                renderItemData={renderItemData}
              />
            )}
          </GridWrapper>
        </container.TableContainer>
        <Footer>
          <SaveRecurringButtonContainer
            style={{ width: '95px' }}
            onClick={() => this.onClickConfirm()}
          >
            <button className="custom-button">Back</button>
          </SaveRecurringButtonContainer>
        </Footer>
      </PatientContainer>
    );
  }
}

export default SettingsAppointment;

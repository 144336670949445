import globalStyleVariables from "src/Framework/Styles/variables.module.scss";
import styled from "styled-components";

export const ContentContainer = styled.div`
    width: 250px;
    position: relative;
`

export const Title = styled.div`
    color:${globalStyleVariables.darkGrey};
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
`
import React, { FC } from 'react';

import MultipleSelector from '../MultipleSelector';

import { IMultipleType } from './index';

type IProps = IMultipleType & {
  options: any[];
  allOptions: any[];
};

const Component: FC<IProps> = (props: IProps) => {
  const { name, value, onChange, options, selectProps, allOptions } = props;
  return (
    <MultipleSelector
      id={props.id}
      label={name}
      value={value}
      onChange={(v) => onChange(v)}
      options={options}
      selectProps={{
        ...selectProps,
        allOptions
      }}
    />
  );
};

export default Component;

import React from "react";

export const FamilyApptSvg = () => (
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5">
<path d="M9 8.66675C9.55228 8.66675 10 8.21903 10 7.66675C10 7.11446 9.55228 6.66675 9 6.66675C8.44772 6.66675 8 7.11446 8 7.66675C8 8.21903 8.44772 8.66675 9 8.66675Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3 8.66675C3.55228 8.66675 4 8.21903 4 7.66675C4 7.11446 3.55228 6.66675 3 6.66675C2.44772 6.66675 2 7.11446 2 7.66675C2 8.21903 2.44772 8.66675 3 8.66675Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5 11.0001V10.6724C5 10.4357 4.876 10.2181 4.67233 10.0974C4.348 9.90575 3.786 9.66675 3 9.66675C2.204 9.66675 1.64533 9.90441 1.32467 10.0961C1.12267 10.2167 1 10.4337 1 10.6691V11.0001H5Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11 11.0001V10.6724C11 10.4357 10.876 10.2181 10.6723 10.0974C10.348 9.90575 9.786 9.66675 9 9.66675C8.204 9.66675 7.64533 9.90441 7.32467 10.0961C7.12267 10.2167 7 10.4337 7 10.6691V11.0001H11Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8 5.33333V5.00567C8 4.769 7.876 4.55133 7.67233 4.43067C7.348 4.239 6.786 4 6 4C5.204 4 4.64533 4.23767 4.32467 4.42933C4.12267 4.55 4 4.767 4 5.00233V5.33333H8Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6 3C6.55228 3 7 2.55228 7 2C7 1.44772 6.55228 1 6 1C5.44772 1 5 1.44772 5 2C5 2.55228 5.44772 3 6 3Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</svg>








);

import React from 'react';

interface IProps {
  fill?: string;
  stroke?: string;
}

export const CircleUpSvg = ({ fill, stroke }: IProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#3A5B8B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3536 18.3536C20.1583 18.5488 19.8417 18.5488 19.6464 18.3536L16 14.7071L12.3536 18.3536C12.1583 18.5488 11.8417 18.5488 11.6464 18.3536C11.4512 18.1583 11.4512 17.8417 11.6464 17.6464L15.6464 13.6464C15.8417 13.4512 16.1583 13.4512 16.3536 13.6464L20.3536 17.6464C20.5488 17.8417 20.5488 18.1583 20.3536 18.3536Z"
      fill="white"
    />
  </svg>
);

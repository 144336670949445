
import React from "react";

interface IProps {
    stroke?: string;
    height?: string;
    width?: string;
}


export const AdmittedSvg = ({ stroke, height, width }: IProps) => (
    <svg width={width || "20"} height={height || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6663 10.0002C16.6663 8.5835 17.7497 7.50016 19.1663 7.50016V3.3335H0.833008V7.50016C2.24967 7.50016 3.33301 8.5835 3.33301 10.0002C3.33301 11.4168 2.24967 12.5002 0.833008 12.5002V16.6668H19.1663V12.5002C17.7497 12.5002 16.6663 11.4168 16.6663 10.0002Z" 
        stroke={stroke || "#666666"} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.7002 10.9003L8.5002 12.7003L13.3002 7.30029" stroke={stroke || "#666666"} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

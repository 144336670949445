import React, {
  useEffect,
  FC,
  useRef,
  useCallback,
  useState,
  useMemo
} from 'react';
import { Row, Col, Button } from 'antd';

import Table from 'src/Framework/Controls/Table';
import Checkbox from 'src/Framework/Controls/Checkbox';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import EditButton from 'src/Framework/Controls/Table/EditButton';
import Icon from 'src/Framework/Controls/Icon';
import TableCheckbox from 'src/Framework/Controls/Table/Checkbox';
import { ContentTypes, Formats } from 'src/Framework/Controls/FileViewer/types';
import { generateAndDownloadFile } from 'src/Framework/Controls/FileViewer/utils';
import {
  IColumn,
  ITableRenderItemData
} from 'src/Framework/Controls/Table/types';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { formattedDate } from 'src/Framework/Shared/Shared';
import { useAppSelector } from 'src/store';
import test_ids from 'src/tests-script/pages/AdminPanel/FormBuilder';

import { IBuildingForm } from './store/types';
import * as formsActions from './store/Actions';
import * as Setters from './store/Actions/Setters';
import Tags from 'src/App/Tags/Components/Tags';
import Assignment from 'src/App/Tags/Components/Assignment';
import useTags from 'src/App/Tags/Hooks/useTags';
import { TagDefinitionIds, TagValuesIds } from 'src/App/Tags/store/utils';
import { openBuildingFormTab, pageCount } from './utils';
import PreviewPopover from 'src/App/Admin/Pages/FormBuilder/Components/PreviewPopover';
import Header from 'src/App/Admin/components/Header';
import { TableOnKeyDownRow } from 'src/App/Admin/utils';
import { useMarketplace } from 'src/App/Admin/Pages/PublicForms/store/Hooks/useMarketplace';
import CertifiedContainer from 'src/App/Admin/Pages/PublicForms/Components/Certified/CertifiedContainer';
import Modal, { IModalHandles } from './Modal';
import ImportPublicForms, {
  IModalHandles as IImportPublicFormsModalHandles
} from './ImportPublicForms';
import UpdateForms from './UpdateForms';

import { Container, Importer, TableContainer } from './styled';
import DebounceSearch from 'src/Framework/Controls/DebounceSearch';
import { tableSearch } from 'src/Framework/Controls/Table/utils';
import { searchHighlighter } from 'src/Framework/Controls/Table/searchHightLight';

interface IProps {}

const columns: IColumn[] = [
  {
    title: 'Name',
    flex: 'auto',
    key: 'name',
    sortBy: 'nameToSort',
    ellipsis: true
  },
  {
    title: 'Updated date',
    flex: '120px',
    key: 'updatedDate',
    sortBy: 'updatedDateTimestamp'
  },
  {
    title: 'Tags',
    flex: '180px',
    key: 'tags'
  },
  {
    title: 'Assignment',
    flex: '180px',
    key: 'assignmentTags'
  },
  {
    title: 'Standard',
    flex: '200px',
    key: 'standart',
    centered: true
  },
  {
    title: 'Update',
    flex: '80px',
    key: 'update',
    centered: true
  },
  {
    title: 'Read only',
    flex: '80px',
    key: 'readOnly',
    centered: true
  },
  {
    title: (
      <div>
        <div
          style={{
            textAlign: 'center'
          }}
        >
          Latest
        </div>
        <div
          style={{
            textAlign: 'center'
          }}
        >
          version
        </div>
      </div>
    ),
    flex: '80px',
    key: 'version',
    centered: true
  },
  {
    title: 'Public',
    flex: '80px',
    key: 'public',
    centered: true
  },
  {
    title: 'Export',
    flex: '80px',
    key: 'export',
    centered: true
  },
  {
    title: 'Active',
    flex: '80px',
    key: 'active',
    centered: true
  },
  {
    title: 'Draft',
    flex: '80px',
    key: 'draft',
    centered: true
  },
  {
    title: 'Preview',
    flex: '80px',
    key: 'preview',
    centered: true
  },
  {
    title: 'Modify',
    flex: '80px',
    key: 'edit',
    centered: true
  }
];

const tagDefinitionId = TagDefinitionIds.FormTemplateTags;
const tagDefinitionIdInstitutional = TagDefinitionIds.Institutional;
export const assignmenttagDefinitionId = TagDefinitionIds.Category;

const Component: FC<IProps> = ({}: IProps) => {
  const { actions } = useMarketplace({});
  const ImportPublicFormsRef = useRef<IImportPublicFormsModalHandles>(null);
  const importRef = useRef<HTMLInputElement>(null);
  const ModalRef = useRef<IModalHandles>(null);
  const [loadingImport, setLoadingImport] = useState(false);
  const { values, findById } = useTags({ tagDefinitionId });
  const { values: assignmentValues, findById: findAssignmentsById } = useTags({
    tagDefinitionId: assignmenttagDefinitionId
  });
  const { values: valuesInstitutional } = useTags({
    tagDefinitionId: tagDefinitionIdInstitutional
  });
  const summary = useAppSelector(
    (state) => state.adminPanel.formBuilder.FormsReducer.summary
  );
  const loading = useAppSelector(
    (state) => state.adminPanel.formBuilder.FormsReducer.loading
  );
  const filters = useAppSelector(
    (state) => state.adminPanel.formBuilder.FormsReducer.filters
  );
  const sort = useAppSelector(
    (state) => state.adminPanel.formBuilder.FormsReducer.sort
  );

  const getData = useCallback(async () => {
    await formsActions.get({
      IncludeSharedFormInformation: true
    });
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const onChangeImport = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const arr = Array.from(e.target.files);
        const file = arr[0];
        if (file) {
          setLoadingImport(true);
          try {
            const data = await file.text();
            const json = JSON.parse(JSON.parse(data));
            const res = await formsActions.importM1FormTemplate(json);
            if (res) {
              await formsActions.get({
                IncludeSharedFormInformation: true
              });
            }
          } catch (e) {}
          setLoadingImport(false);
        }
      }
    },
    []
  );

  const dataSource = useMemo(() => {
    const filteredData = Object.values(summary).filter((item) => {
      if (!filters.isInactive && !item.isActive) {
        return false;
      }
      return true;
    });
    return filteredData;
  }, [summary, filters]);

  const onClickUpdateForm = useCallback(async (value: IBuildingForm) => {
    const res = await actions.postFormUpdateVersion(value.importedEntityId);
    if (res) {
      getData();
    }
  }, []);

  const renderItemData = useCallback(
    (value: typeof dataSource[number]): ITableRenderItemData<typeof value> => {
      const isReadOnly =
        value.isImported &&
        value.tags.includes(TagValuesIds.Institutional.ReadOnly);
      const isUpdateAvailable = value.isUpdateAvailable;
      const tags = value.tags.filter((v) => !valuesInstitutional[v]);
      const tagsSearch = tags
        .map((v) => findById(v)?.value || findAssignmentsById(v)?.value || '')
        .filter((v) => v);
      const name = value.name;
      const updatedDate = formattedDate(value.modifyDts);
      return {
        ...value,
        searchFields: [name, updatedDate, ...tagsSearch],
        nameToSort: name,
        updatedDate,
        ...searchHighlighter({
          data: {
            name,
            updatedDate
          },
          search: filters.searchString
        }),
        updatedDateTimestamp: value.modifyDts
          ? new Date(value.modifyDts).getTime()
          : null,

        standart: <CertifiedContainer tags={value.tags} />,
        update: isReadOnly && value.isImported && (
          <ButtonIcon
            name={ICONS_LIST.refresh}
            active={isUpdateAvailable}
            disabled={!isUpdateAvailable}
            asyncOnClick={async () => {
              await onClickUpdateForm(value);
            }}
          />
        ),
        public: value.isImported && (
          <Icon name="library" color="rgba(0,0,0,0.5)" />
        ),
        readOnly: isReadOnly ? (
          <Icon name="check2" color={globalStyleVariables.darkGrey} />
        ) : null,
        version: value.latestPublishedVersion,
        active: (
          <TableCheckbox
            value={value.isActive}
            onChangeAction={async () => {
              const res = await formsActions.patch({
                id: value.id,
                isActive: !value.isActive
              });
              if (res) {
                Setters.onChangeItem(res);
              }
            }}
          />
        ),
        assignmentTags: (
          <Assignment
            search={filters.searchString}
            clinicIds={value.clinicIds}
            tags={tags}
            values={assignmentValues}
            onChange={async (data) => {
              const res = await formsActions.patch({
                id: value.id,
                tags: data.tags
              });
              if (res) {
                Setters.onChangeItem(res);
              }
              return res;
            }}
          />
        ),
        tags: (
          <Tags
            search={filters.searchString}
            tagId={test_ids.formsTagsButton + value.id}
            tags={tags}
            values={values}
            tagDefinitionId={tagDefinitionId}
            onChange={async (data) => {
              const res = await formsActions.patch({
                id: value.id,
                tags: data.tags
              });
              if (res) {
                Setters.onChangeItem(res);
              }
              return res;
            }}
          />
        ),
        export: (
          <ButtonIcon
            name={ICONS_LIST.upload}
            changeColorOnHover={true}
            asyncOnClick={async () => {
              const res = await formsActions.exportM1FormTemplate(value.id);
              if (res) {
                generateAndDownloadFile(
                  JSON.stringify(res),
                  `${value.name}`,
                  Formats.text,
                  ContentTypes.json
                );
              }
            }}
          />
        ),
        draft: value.draftVersion ? (
          <Icon size={24} name="formDraft" color="#666666a9" />
        ) : null,
        edit: (
          <EditButton
            onClick={() => {
              openBuildingFormTab(value);
            }}
          />
        ),
        preview: (
          <PreviewPopover
            value={value}
            type="form"
            disableOnlyPublished={value.latestPublishedVersion ? false : true}
          >
            {({ visible }) => (
              <ButtonIcon
                id={test_ids.formsPreviewButton + value.id}
                active={visible}
                name={ICONS_LIST.eye2}
                changeColorOnHover={true}
              />
            )}
          </PreviewPopover>
        )
      };
    },
    [values, assignmentValues, filters.searchString]
  );

  const onChangePage = useCallback((pageNumber: number) => {
    Setters.filters({
      pageNumber
    });
  }, []);

  const isUpdateAvailableForms = Object.values(summary).filter((value) => {
    const isReadOnly =
      value.isImported &&
      value.tags.includes(TagValuesIds.Institutional.ReadOnly);
    return isReadOnly && value.isUpdateAvailable;
  });

  const tableData = useMemo(() => {
    return tableSearch({
      search: filters.searchString,
      dataSource,
      renderItemData
    });
  }, [filters.searchString, dataSource, renderItemData]);

  return (
    <Container>
      <ImportPublicForms ref={ImportPublicFormsRef} onApplyClose={getData} />
      <Header title="Forms">
        <Row align="middle" gutter={16}>
          <Col>
            <Importer
              onClick={(e: any) => (e.target.value = null)}
              onChange={onChangeImport}
              type="file"
              accept=".json"
              multiple={false}
              ref={importRef}
            />
            <Button
              loading={loadingImport}
              shape="round"
              id="import-button"
              onClick={() => {
                if (importRef.current?.click) {
                  importRef.current.click();
                }
              }}
            >
              Import
            </Button>
          </Col>
          <Col style={{ width: 300 }} className={'search-container'}>
            <DebounceSearch
              label="Search:"
              labelInRow={true}
              value={filters.searchString}
              onChange={(value) =>
                formsActions.setFilters({
                  searchString: value
                })
              }
              bottomMargin={false}
            />
          </Col>
          <Col>
            <Checkbox
              id="showActive"
              checked={filters.isInactive}
              onChange={() =>
                formsActions.setFilters({
                  isInactive: !filters.isInactive
                })
              }
            >
              Show inactive
            </Checkbox>
          </Col>
          {/* <Col>
            <ButtonIcon
              name={ICONS_LIST.library}
              onClick={() => {
                ImportPublicFormsRef.current?.show({});
              }}
            />
          </Col> */}
          <Col>
            <Button
              id="createButton"
              shape="round"
              onClick={() => ModalRef.current?.show({})}
            >
              Create a Form
            </Button>
          </Col>
        </Row>
      </Header>
      {isUpdateAvailableForms.length !== 0 && (
        <UpdateForms forms={isUpdateAvailableForms} />
      )}
      <TableContainer>
        <Table
          key={`${filters.pageNumber}-${filters.searchString}-${sort.sortBy}-${sort.sortType}`}
          sort={sort}
          onChangeSort={Setters.setSorting}
          absoluteContainer={true}
          rowHover={true}
          absoluteLoading={loading}
          dataSource={tableData}
          renderItemData={renderItemData}
          columns={columns}
          onKeyDownRow={TableOnKeyDownRow}
          innerPagination={{
            pageNumber: filters.pageNumber,
            pageLength: pageCount
          }}
          paginationControls={{
            total: tableData.length,
            pageLength: pageCount,
            startIndex: (filters.pageNumber - 1) * pageCount,
            onChangePage: (newNumber) => onChangePage(newNumber + 1)
          }}
        />
      </TableContainer>
      <Modal ref={ModalRef} />
    </Container>
  );
};

export default React.memo(Component);

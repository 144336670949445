import { IPreference } from 'src/App/Preferences/types';
import { defaultCellSettings } from 'src/App/UserPreferences/CellSettings';
import { ICellSettings } from 'src/App/UserPreferences/store/types';
import store from 'src/store';
import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const MainContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  min-height: 200px;
  width: 100%; //scrollbar in calendar
  overflow: hidden;
  opacity: 1;
  display: flex;
  flex: 1;
`;

export const TimerAbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  scrollbar-width: none;
  pointer-events: none;
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar in WebKit browsers */
  }
`

export const MainRelativeContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 150px;
`

export const CalendarSnapContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  min-height: 200px;
  width: 100%; //scrollbar in calendar
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const TimeBarLayout = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  scrollbar-width: none;
  pointer-events: none;
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar in WebKit browsers */
  }
`;

export const DefaultItemWidth = 200;

export const getReservationSettings = () => {
  const cellSettings: IPreference<ICellSettings> = store.getState().user
    .preferences
    ? Object.values(store.getState().user.preferences).find(
        (value) => value?.id === 3036
      )
    : undefined;
  const reservationSettings = cellSettings?.parsedValue?.reservationSettings;
  return reservationSettings || defaultCellSettings.reservationSettings!;
};
export const DefaultItemHeight = () =>
  getReservationSettings().overlap ? 28 : 34;
// const ScheduleContentPadding = 35;

interface ScheduleContentLayoutProps {
  columns: number;
  rows: number;
}

// height: ${({ rows }: ScheduleContentLayoutProps) =>
//   rows * DefaultItemHeight() + ScheduleContentPadding}px;

export const ScheduleContentLayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex: 0 1 auto;
  /* min-height: 100%; */
  height: 100%;
  .selected-date-container {
    width: 150px;
    height: 100%;
  }

  .widget-selected-date-container {
    width: 101px;
    margin-left: 24px;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .all-providers-view {
    width: 100%;
    overflow: hidden;
    flex: 1;
    border-left: 2px solid rgb(230, 230, 230);
    flex-flow: column;
    height: 100%;
  }

  .widget-all-providers-view {
    width: calc(100% - 101px);
    overflow-y: hidden;
    border-left: 1px solid rgb(230, 230, 230);
    flex-flow: column;
    height: 100%;
  }

  .all-providers-container {
    align-items: start;
    /* width: ${({ columns }: ScheduleContentLayoutProps) =>columns * DefaultItemWidth}px; */
    flex: 0 1 auto;
  }

  .all-providers-remianing-space {
    width: ${({ columns }: ScheduleContentLayoutProps) =>
      columns * DefaultItemWidth}px;
    flex: 1 1 auto;
  }

  .provider-container {
    width: 200px;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .widget-provider-container {
    width: 200px;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid white;
  }
`;

interface CurrentTimeLineProps {
  fromTopLen: number;
}

export const CurrentTimeLine = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  z-index: 6;
  left: 0;
  top: ${({ fromTopLen }: CurrentTimeLineProps) =>
    25 + DefaultItemHeight() * fromTopLen}px;
  background: ${globalStyleVariables.newBrandingPrimary200};
`;

import React from 'react';

interface IProps {
  stroke?: string;
  height?: string;
  width?: string;
}

export const ReadySvg = ({ stroke, height, width }: IProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 2V18M7 2L15 7L7 12V2Z"
      stroke={stroke || '#666666'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
`

export const AbsoluteContainer = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
`

export const InnerContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: fit-content;
`
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Container = styled.span`
  color: ${globalStyleVariables.newBrandingPrimary200};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: unset;
  }
`;

import React, { FC, Fragment, useCallback, useRef } from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import hexToRgba from 'hex-to-rgba';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import Icon from 'src/Framework/Controls/Icon';

import { providerDisplayNameByCode } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { formattedLTTime, formattedTime } from 'src/Framework/Shared/Shared';

import {
  getContrastColor,
  pickTextColorBasedOnBgColorSimple
} from 'src/Framework/util/helps';
import { patientDisplayName } from 'src/Framework/util/format';
import store, { useAppSelector } from 'src/store';

import { isEventLate } from 'src/Activities/Schedule/Utils/Utils';
import { getApptIcon, getApptColor } from 'src/Activities/Schedule/utils';

import { defaultCellSettings } from 'src/App/UserPreferences/CellSettings';
import { ApptReasonLabel } from 'src/App/UserPreferences/ApptReasonDisplay';
import { defaultCalendarOpacityLevel } from 'src/Activities/Schedule/TopBar/FilterBar/OpacityLevel';
import { AppointmentReasonSimpleDto } from 'src/App/Admin/Pages/AppointmentTypes/store/types';
import { checkClinicOutOfRange } from '../utils';
import CancellationModal, {
  IModalHandles
} from 'src/Activities/Schedule/Main/ScheduleCol/CancellationModal';

import {
  ScheduleOneContainer,
  PatientNameContainer,
  IconContainer,
  TitleNameContainer,
  OverflowCountText,
  TimeBlock,
  IconBlock,
  TextContainer,
  RowContainer,
  Wrapper
} from './styled';
import { DefaultItemHeight } from '../ScheduleCol_Styles';
import { getReservationSettings } from '../../Main_Styles';

interface IPatientInfo {
  firstName: string;
  lastName: string;
  patientBirthDate: string;
  patientEmail: string;
  patientId: number;
  patientName: string;
  patientNotes: null | any;
  patientPreferredPhone: string;
  patientSex: string;
  patientStatus: null | any;
  preferredName: string;
  comment?: string;
  cancellation?: {
    cancelReasonName: string;
    cancelUserCode: string | null;
    cancelledDate: string;
  };
}

export interface IAppointmentFull {
  idx: number;
  color: string;
  startTime: string;
  endTime: string;
  duration: string;
  appointmentId: number;
  isTelehealth: boolean;
  tempLoading?: boolean;
  overflowing: any;
  providerCode: string;
  providerName?: string;
  overflowCount: number;
  patientInfo: IPatientInfo;
  uid: any;
  visitReason: string;
  isReservation: boolean;
  isEvent: boolean;
  isBlock: boolean;
  groupAppointment: boolean;
  categoryCode: any;
  hasConflict: boolean;
  inSeries: boolean;
  isMultiProvider: boolean;
  reason: any;
  typeAppointmentParticipantId: any;
  appointmentFor: string;
  inReservation: boolean;
  date: any;
  note: string;
  inOverflow?: boolean;
  clinicCode: string;
  scheduleEventTypeId: number;
  patients?: Record<string, IPatientInfo>;
  isOutreach: boolean;
  cancelledPatients?: Record<string, IPatientInfo>;
  scheduleEventDateTime: string;
  cancellation?: {
    cancelReasonName: string;
    cancelledDate: string;
    cancelUserCode: null | string;
  };
}

interface IOwnProps {
  isShowingPatientName: boolean;
  onClick?: any;
  onClickOverflow?: () => any;
  onDoubleClick: any;
  isDragHover?: boolean;
  height?: number;
  firstInReservation?: boolean;
  lastInReservation?: boolean;
  appointment: IAppointmentFull;
  cellRef?: React.MutableRefObject<any>;
  overflowPopupRef?: React.MutableRefObject<any>;
  overflowPopupOpened?: boolean;
}

type IProps = IOwnProps;

export const checkPatientStatus = (
  patientStatus: any,
  date: any,
  startTime: string,
  {
    colorWithBackground,
    colorWithoutBackground
  }: { colorWithBackground: string; colorWithoutBackground: string }
) => {
  let statusIcon: any;
  let scheduleEventDateTime = `${moment(date).format(
    'YYYY-MM-DD'
  )}T${startTime}:00`;
  const isLate = isEventLate({ scheduleEventDateTime });
  if (isLate && !patientStatus.checkedIn) {
    const delay = store.getState().user.preferences.appointmentLateDelay?.value;
    if (delay) {
      const newDate = moment(scheduleEventDateTime).add(delay, 'm').toDate();
      const isLate = isEventLate({
        scheduleEventDateTime: newDate
      });
      if (isLate) {
        statusIcon = {
          icon: <Icon color={colorWithoutBackground} size={15} name="late" />,
          type: 'late'
        };
      }
    } else {
      statusIcon = {
        icon: <Icon color={colorWithoutBackground} size={15} name="late" />,
        type: 'late'
      };
    }
  }
  if (patientStatus.checkedIn) {
    statusIcon = {
      icon: <Icon color={colorWithoutBackground} size={15} name="arrived" />,
      type: 'arrived',
      date: patientStatus.checkedInTime
    };
  }
  if (patientStatus.ready) {
    statusIcon = {
      icon: <Icon color={colorWithBackground} size={15} name="ready" />,
      type: 'ready',
      date: patientStatus.readyTime
    };
  }
  if (patientStatus.admit) {
    statusIcon = {
      icon: <Icon color={colorWithBackground} size={15} name="admitted" />,
      type: 'admitted',
      date: patientStatus.admitTime
    };
  }
  if (patientStatus.discharged) {
    statusIcon = {
      icon: <Icon color={colorWithoutBackground} size={15} name="check" />,
      type: 'discharged',
      date: patientStatus.dischargeTime
    };
  }

  return statusIcon;
};

const Component: FC<IProps> = (props) => {
  const CancellationModalRef = useRef<IModalHandles>(null);
  const calendarOpacityLevel = useAppSelector(
    (state) =>
      state.user.preferences.calendarOpacityLevel?.parsedValue ||
      defaultCalendarOpacityLevel
  );
  const cellSettings = useAppSelector(
    (state) =>
      state.user.preferences.calendarCellSettings?.parsedValue ||
      defaultCellSettings
  );
  const apptReasonDisplay = useAppSelector(
    (state) => state.user.preferences.apptReasonDisplay?.parsedValue
  );
  const getEventLabel = useCallback((event: IAppointmentFull) => {
    let typeString = event.isEvent
      ? 'staff appointment'
      : event.isBlock
      ? 'block'
      : event.isReservation
      ? 'reservation'
      : event.isOutreach
      ? 'outreach event'
      : event.groupAppointment
      ? 'group appointment'
      : event.overflowing
      ? `overflowing block with ${event.overflowCount} items. use Enter to get extra menu`
      : 'appointment';

    let descriptionString = event.appointmentFor
      ? event.appointmentFor + '.'
      : '';

    let seriesString = event.inSeries
      ? 'in series. use Shift plus Enter to get extra menu.'
      : '';

    let providerString =
      event.isEvent || event.overflowing
        ? ''
        : event.isMultiProvider
        ? 'Several providers.'
        : event.isOutreach
        ? `Provider is ${providerDisplayNameByCode(event.providerCode)}`
        : `Provider is ${event.providerName}.`;

    return `time is ${formattedTime(
      moment(event.startTime, 'HH:mm').toISOString()
    )}. ${typeString} ${descriptionString} ${seriesString} ${providerString}`;
  }, []);

  const apptReasonFormatted = useCallback(
    (v: AppointmentReasonSimpleDto) => {
      if (apptReasonDisplay) {
        if (apptReasonDisplay === ApptReasonLabel.code) {
          return v.code || '';
        }
        if (apptReasonDisplay === ApptReasonLabel.both) {
          return `${`(${v.code}) ` || ''}${v.name}`;
        }
      }
      return v.name || '';
    },
    [apptReasonDisplay]
  );

  const checkApptType = (typeAppointmentParticipantId: number) => {
    const { isBlock, isOutreach, isEvent } = props.appointment;
    const iconType = getApptIcon({
      typeAppointmentParticipantId,
      isBlock,
      isOutreach,
      isEvent
    });
    return (
      <IconBlock title={iconType.text}>
        <Icon name={iconType.name} size={12} color="#333333" />
      </IconBlock>
    );
  };

  const onClickHandler = (e: any, overflowing: number, appointmentFor: any) => {
    const { onClickOverflow, onClick } = props;
    if (overflowing && !appointmentFor) {
      if (onClickOverflow) {
        onClickOverflow();
      }
    } else {
      if (onClick) {
        onClick(e);
      }
    }
  };

  const {
    isShowingPatientName,
    onDoubleClick,
    isDragHover,
    appointment,
    height,
    firstInReservation,
    lastInReservation
  } = props;
  const {
    uid,
    isReservation,
    patientInfo,
    isBlock,
    date,
    overflowing,
    overflowCount,
    appointmentFor,
    reason,
    note,
    typeAppointmentParticipantId,
    startTime,
    hasConflict,
    categoryCode,
    tempLoading,
    scheduleEventTypeId,
    inOverflow,
    endTime,
    isTelehealth,
    isOutreach,
    inSeries,
    isEvent,
    clinicCode
  } = appointment;

  if (isReservation) {
    return null;
  }

  if (
    !checkClinicOutOfRange({
      clinicCode,
      endTime
    })
  ) {
    return null;
  }

  const patientName = patientDisplayName(patientInfo);
  const patientStatus = patientInfo?.patientStatus;
  const isCancelled = scheduleEventTypeId === 4;

  const leftIcon = uid && checkApptType(typeAppointmentParticipantId);

  const iconsWithBackgroundColor = pickTextColorBasedOnBgColorSimple(
    getApptColor(appointment)
  );
  const rgba = hexToRgba(getApptColor(appointment), 0.2);
  const iconsWithoutBackgroundColor = getContrastColor(rgba);
  const iconContainerStyle = {
    backgroundColor: getApptColor(appointment)
  };
  const statusIcon =
    patientStatus &&
    checkPatientStatus(patientStatus, date, startTime, {
      colorWithBackground: iconsWithBackgroundColor,
      colorWithoutBackground: iconsWithoutBackgroundColor
    });
  const list = [
    {
      key: 'recurring'
    },
    {
      key: 'participation'
    },
    {
      key: 'fullName'
    },
    {
      key: 'telehealth'
    },
    {
      key: 'note'
    },
    {
      key: 'status'
    },
    {
      key: 'appointmentType'
    }
  ]
    .sort(
      (a, b) =>
        cellSettings.order.indexOf(a.key) - cellSettings.order.indexOf(b.key)
    )
    .filter((v) => cellSettings.show[v.key]);
  const fullName =
    isEvent || isOutreach
      ? appointmentFor
      : isShowingPatientName
      ? !isBlock
        ? typeAppointmentParticipantId === 4
          ? categoryCode
          : patientName
        : ''
      : '';
  const components = {
    recurring: inSeries && (
      <IconBlock title={'This event is part of a series'}>
        <Icon name="recurrence" color="#333333" size={14} />
      </IconBlock>
    ),
    participation: leftIcon,
    fullName: <TextContainer>{fullName}</TextContainer>,
    telehealth: isTelehealth && (
      <IconBlock>
        <Tooltip title={'Telehealth'}>
          <IconContainer style={iconContainerStyle}>
            <Icon name="camera" size={15} color={iconsWithBackgroundColor} />
          </IconContainer>
        </Tooltip>
      </IconBlock>
    ),
    note: note?.length > 0 && (
      <>
        {cellSettings.noteSettings.icon && (
          <IconBlock>
            <Tooltip
              title={note.length > 255 ? note.slice(0, 255) + '...' : note}
            >
              <div>
                <Icon
                  name="paper"
                  size={15}
                  color={iconsWithoutBackgroundColor}
                />
              </div>
            </Tooltip>
          </IconBlock>
        )}
        {cellSettings.noteSettings.text && (
          <TextContainer>{note}</TextContainer>
        )}
      </>
    ),
    status: statusIcon && (
      <IconBlock>
        <IconContainer
          style={
            statusIcon?.type === 'ready' || statusIcon?.type === 'admitted'
              ? iconContainerStyle
              : {}
          }
        >
          {statusIcon.icon}
        </IconContainer>
      </IconBlock>
    ),
    appointmentType: reason ? (
      <TextContainer>{apptReasonFormatted(reason)}</TextContainer>
    ) : (
      ''
    )
  };
  const filteredList = list.filter((v) => components[v.key]);

  return (
    <>
      {tempLoading && <AbsoluteLoader />}
      <Wrapper
        ref={props.cellRef}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Tab' && !e.shiftKey && props.overflowPopupOpened) {
            e.preventDefault();
            if (props.overflowPopupRef?.current) {
              //@ts-ignore
              props.overflowPopupRef.current.focus();
            }
          }
          if (e.key === 'Tab' && !e.shiftKey && props.overflowPopupOpened) {
            e.preventDefault();
            if (props.overflowPopupRef?.current) {
              //@ts-ignore
              props.overflowPopupRef.current.focus();
            }
          }

          if (e.key === 'Enter') {
            const event = new Event('click', { bubbles: true });
            //@ts-ignore
            event.detail = !inSeries || (!e.shiftKey && inSeries) ? 2 : 1;
            //@ts-ignore
            e.target.firstElementChild?.dispatchEvent(event);
          }
        }}
        id={'schedule-one-id-' + uid}
      >
        <ScheduleOneContainer
          aria-label={getEventLabel(appointment)}
          height={height}
          id={uid}
          onDoubleClick={(e) => {
            if (!isCancelled && uid) {
              onDoubleClick(e);
            }
          }}
          onClick={(e) => {
            if (!isCancelled) {
              onClickHandler(e, overflowing, appointmentFor);
            } else {
              CancellationModalRef.current?.show({
                appointment
              });
            }
          }}
          color={getApptColor(appointment)}
          uid={uid}
          hasConflict={hasConflict}
          overflowing={overflowing}
          isBlock={isBlock}
          isAppt={!isBlock}
          className="scheduleOne"
          isCancelled={isCancelled}
          firstInReservation={inOverflow ? false : firstInReservation}
          lastInReservation={lastInReservation}
          inOverflow={inOverflow}
          isDragHover={isDragHover}
          iconsWithBackgroundColor={iconsWithBackgroundColor}
          reservationSettings={getReservationSettings()}
          calendarOpacityLevel={calendarOpacityLevel}
        >
          <RowContainer
            height={DefaultItemHeight()}
            reservationSettings={getReservationSettings()}
          >
            {!overflowing && (
              <>
                {filteredList.map((v) => (
                  <Fragment key={v.key}>{components[v.key]}</Fragment>
                ))}
                {isCancelled && (
                  <IconBlock>
                    <Tooltip title={'Appointment cancelled'}>
                      <IconContainer>
                        <Icon
                          tabIndex={-1}
                          name="cancelled"
                          size={15}
                          color={iconsWithoutBackgroundColor}
                        />
                      </IconContainer>
                    </Tooltip>
                  </IconBlock>
                )}
              </>
            )}
          </RowContainer>
          <PatientNameContainer>
            <TitleNameContainer>
              {overflowCount && overflowing && !appointmentFor && (
                <OverflowCountText
                  style={{
                    color: hasConflict ? '#FF2828' : iconsWithBackgroundColor
                  }}
                >
                  {'+' + overflowCount}
                </OverflowCountText>
              )}
              {inOverflow && (
                <TimeBlock>{`${formattedLTTime(
                  moment(startTime, 'HH:mm').toISOString()
                )} - ${formattedLTTime(
                  moment(endTime, 'HH:mm').toISOString()
                )}`}</TimeBlock>
              )}
            </TitleNameContainer>
          </PatientNameContainer>
        </ScheduleOneContainer>
      </Wrapper>
      <CancellationModal ref={CancellationModalRef} />
    </>
  );
};

export default Component;

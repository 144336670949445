import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Label = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: ${globalStyleVariables.brandGray};
  margin: 0 5px 0 0;
  width: 100%;
`;

export const Value = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: ${globalStyleVariables.brandGray};
  width: 100%;
  min-height: 18px;
`;

export const Container = styled.div`
  display: flex;
  border-bottom: 1px solid #e6e6e6;
  padding: 20px 15px 10px 20px;

  .task-icon-container {
    height: 20px;
    width: 20px;
    margin: 0 14px 0 0;

    .task-icon {
      height: 20px;
      width: 20px;
      mask-size: contain;
      mask-position: center center;
      mask-repeat: no-repeat no-repeat;
    }
  }

  .right-item {
    padding: 0 0 0 16px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    height: 100%;
  }
`;

export const Description = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #333333;
  margin-bottom: 9px;
`;

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Container = styled.div`
  position: relative;
  min-height: 150px;
`;

export const Header = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &.handleDrag {
    cursor: grab;
  }
`;

export const InfoContainer = styled.div`
  position: relative;
  max-height: 300px;
  overflow: overlay;
  padding: 24px;
`;

export const InfoTitle = styled.div`
  font-size: 12px;
  color: #666666;
`;

export const InfoData = styled.div`
  color: #333333;
  font-size: 16px;
`;

export const MainContainer = styled.div``;

export const Title = styled.div`
  color: #666666;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
`;

export const ModalTitle = styled.div`
  font-weight: 800;
  font-size: 18px;
  color: #333333;
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${globalStyleVariables.newBrandingPrimary200};
  background-color: #e7f0f5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: 200px;
`;

import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';

import SelectContainer from 'src/Framework/Controls/SelectContainer';
import { ProviderSelector } from 'src/Framework/Controls/Selectors';
import {
  getClinicConfig,
  getProviderByCode,
  providersValidForReason
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import Checkbox from 'src/Framework/Controls/Checkbox';
import MultipleSelector from 'src/Framework/Controls/Selectors/MultipleSelector';
import { getReasonByID } from 'src/Framework/Controls/Selectors/ReasonSelector/utils';
import Radio from 'src/Framework/Controls/Radio';
import GroupAppointmentReason from 'src/Framework/Controls/Selectors/GroupAppointmentReason';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import { groupAppointmentReasonById } from 'src/Framework/Controls/Selectors/GroupAppointmentReason/utils';
import { SearchIconSvg } from 'src/Framework/Common/Svg/SearchIconSvg';
import { formattedDate } from 'src/Framework/Shared/Shared';
import store from 'src/store';
import test_ids from 'src/tests-script/pages/Schedule/Scheduling';

import {
  getStartTimeOpts,
  getEndTimeOpts,
  getDurationOptionsFromClinic,
  momentDateHandFormatter,
  weekDayOptions,
  mapClinic
} from 'src/Activities/Schedule/Utils/Utils';
import TimeOptionList from './TimeOptionList/TimeOptionList';

import {
  SelectDateTimeContainer,
  Header,
  Body,
  SetupTimeButtonContainer,
  ButtonContainer,
  RightContainer,
  LeftContainer
} from './SelectDateTime_Styles';
import {
  ProviderBlock,
  ProviderRadio
} from 'src/Activities/Schedule/Popups/AppointmentPopup/AppointmentPopup_Styles';
import { ErrorBlock } from 'src/Activities/Schedule/Popups/BlockReservationPopup/BlockReservationPopup_Styles';

interface ISchedulePayload {
  providerCodes: string[];
  clinicCodes: string[];
  startDate: string | null;
  endDate: string | null;
  appointmentReasonId: number;
  duration: number;
  inReservation: boolean;
  multiProvider: boolean;
  endTime: string | null;
  startTime: string | null;
  weekdays: string[];
  groupIds: number[];
}

interface IState {
  selectedOption: any;
  dateTo: string;
  dateFrom: string;
  duration: any;
  schedulingPayload: ISchedulePayload;
  enableMulti: boolean;
  error: any;
  clinicProviders: any[];
}

interface IProps {
  nextEvents: any;
  isLoading: boolean;
  newEventPayload: any;
  clinics: any;
  providers: any;
  onSaveSelection: (event: any) => void;
  onCancelSelection: () => void;
  selectedClinic: any;
  enableMulti: boolean;
  updateNextAvailable: any;
}

const weekDaysList = weekDayOptions();

class SelectDateTime extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    const { newEventPayload } = this.props;
    const { scheduleEvent } = newEventPayload;
    const providerCodes: string[] = newEventPayload['providerCodes'].map(
      (item: any) => item.code
    );
    this.state = {
      schedulingPayload: {
        providerCodes,
        clinicCodes: [scheduleEvent.clinicCode],
        startDate: scheduleEvent.scheduleEventDateTime
          ? formattedDate(scheduleEvent.scheduleEventDateTime)
          : null,
        endDate: scheduleEvent.scheduleEventDateTime
          ? moment(scheduleEvent.scheduleEventDateTime)
              .add(3, 'days')
              .format('MM/DD/YYYY')
          : null,
        appointmentReasonId: scheduleEvent.reason.id,
        duration: scheduleEvent.reason.duration,
        multiProvider: providerCodes.length > 1 ? true : false,
        inReservation:
          scheduleEvent?.scheduleEventTypeId &&
          scheduleEvent?.scheduleEventTypeId === 1,
        startTime: null,
        endTime: null,
        weekdays: [],
        groupIds: []
      },
      selectedOption: [],
      dateTo:
        scheduleEvent.scheduleEventDateTime &&
        moment(scheduleEvent.scheduleEventDateTime)
          .add(3, 'days')
          .format('MM/DD/YYYY'),
      dateFrom:
        scheduleEvent.scheduleEventDateTime &&
        formattedDate(scheduleEvent.scheduleEventDateTime),
      duration: null,
      enableMulti: this.props.enableMulti,
      error: {
        provider: false
      },
      clinicProviders: store.getState().schedule.clinicProviders.data
    };
  }

  componentDidMount() {
    this.onClickSetupTime();
  }

  onSelectedDate(value: any): any {
    this.props.onSaveSelection(value);
  }

  onDateToChange(value: string) {
    this.setState({
      dateTo: value,
      schedulingPayload: { ...this.state.schedulingPayload, endDate: value }
    });
  }

  onDateFromChange(value: string) {
    let new_date = moment(momentDateHandFormatter(value) + 'T00:00:00')
      .add(3, 'days')
      .format('L');
    this.setState({
      dateFrom: value,
      schedulingPayload: {
        ...this.state.schedulingPayload,
        startDate: value,
        endDate: new_date
      },
      dateTo: new_date
    });
  }

  onSelectGroiupIds(value: number[]) {
    this.setState({
      schedulingPayload: {
        ...this.state.schedulingPayload,
        groupIds: value
      }
    });
  }

  onSelectTimeTo(value: any) {
    this.setState({
      schedulingPayload: {
        ...this.state.schedulingPayload,
        endTime: value + ':00'
      }
    });
  }

  onSelectTimeFrom(value: any) {
    let { schedulingPayload } = this.state;
    let updatedEndTime: any = null;
    if (schedulingPayload.endTime) {
      let date = momentDateHandFormatter(schedulingPayload.startDate || '');
      let clonedStartTime = moment(date + 'T' + value + ':00');
      updatedEndTime = moment(
        clonedStartTime.add(
          moment.duration(
            this.props.newEventPayload.scheduleEvent.reason.duration,
            'minutes'
          )
        )
      ).format('HH:mm');
    }
    this.onChangeSchedulePayload({
      startTime: value,
      endTime: updatedEndTime || null
    });
  }

  onSelectWeekdays(e: any) {
    let selectedWeekdays = e.map((item: any) => {
      return item.value;
    });
    this.setState({
      schedulingPayload: {
        ...this.state.schedulingPayload,
        weekdays: selectedWeekdays
      }
    });
  }

  onClickSetupTime() {
    const data = {
      ...this.state.schedulingPayload
    };
    if (data.weekdays.length === 0) {
      data.weekdays = weekDaysList.map((v: any) => v.value);
    }
    this.props.updateNextAvailable(data);
  }

  setProviderSelection = (providerSelection: boolean) => {
    const { schedulingPayload } = this.state;
    const providerCodes = !providerSelection
      ? schedulingPayload.providerCodes.slice(0, 1)
      : schedulingPayload.providerCodes;
    this.setState({
      enableMulti: providerSelection,
      schedulingPayload: {
        ...this.state.schedulingPayload,
        multiProvider: providerSelection,
        providerCodes
      }
    });
  };
  onSelectProvider = (values: string[]) => {
    const { selectedClinic } = this.props;
    const error = { ...this.state.error };
    error.provider = false;
    const providers: any[] = [];
    values.forEach((code) => {
      const provider = getProviderByCode(code);
      if (provider) {
        providers.push({
          id: provider.providerId,
          code: provider.code,
          page: getClinicConfig(provider, selectedClinic.id)?.maxPages || 1,
          name: provider.lastName
        });
      }
    });
    this.setState({
      error,
      schedulingPayload: {
        ...this.state.schedulingPayload,
        providerCodes: values
      }
    });
  };

  onSelectDuration(value: any) {
    this.setState({
      duration: {
        ...value
      },
      schedulingPayload: {
        ...this.state.schedulingPayload,
        duration: value.value
      }
    });
  }

  onChangeSchedulePayload = (obj: Partial<ISchedulePayload>) => {
    const { schedulingPayload } = this.state;
    this.setState({
      schedulingPayload: {
        ...schedulingPayload,
        ...obj
      }
    });
  };

  render() {
    const { error, enableMulti, schedulingPayload } = this.state;
    const { selectedClinic, clinics, newEventPayload } = this.props;
    const { scheduleEvent } = newEventPayload;
    const isStaffAppt =
      getReasonByID(scheduleEvent.appointmentReasonId)?.reasonType === 'event';
    const date = momentDateHandFormatter(schedulingPayload.startDate || '');
    let endTimeOptions: any = [];
    if (schedulingPayload.startTime) {
      endTimeOptions = getEndTimeOpts(
        selectedClinic,
        moment(date + 'T' + schedulingPayload.startTime)
      );
    }
    if (schedulingPayload['endTime']) {
      endTimeOptions = getEndTimeOpts(
        selectedClinic,
        moment(date + 'T' + schedulingPayload.startTime)
      );
    }

    let startTimeOptions: any = [];
    let durationOptions: any = [];
    if (selectedClinic) {
      startTimeOptions = getStartTimeOpts(selectedClinic);
      durationOptions = getDurationOptionsFromClinic(
        selectedClinic,
        schedulingPayload.startTime
      );
    }

    const clinicOpts: any = clinics.map((c: any) => mapClinic(c));
    return (
      <SelectDateTimeContainer>
        <Body
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              borderRight: '1px solid #e6e6e6',
              flexDirection: 'column',
              flexFlow: 'column',
              height: '100%',
              flex: 1,
              width: '44%'
            }}
          >
            <Header
              style={{
                width: '100%',
                paddingBottom: 0,
                paddingTop: 0,
                paddingRight: '14px'
              }}
            >
              <ProviderBlock style={{ padding: 0 }}>
                <ProviderRadio>
                  <label className={'category-label'}>Provider</label>
                  <Radio
                    name="provider"
                    id={test_ids.options.single}
                    checked={!enableMulti}
                    onClick={() => this.setProviderSelection(false)}
                  >
                    Single
                    <span className="visually-hidden">provider</span>
                  </Radio>
                  <Radio
                    name="provider"
                    id={test_ids.options.multiple}
                    checked={enableMulti}
                    onClick={() => this.setProviderSelection(true)}
                  >
                    Multiple
                    <span className="visually-hidden">providers</span>
                  </Radio>
                </ProviderRadio>
                <Row align="middle" gutter={16}>
                  <Col span={12}>
                    <ProviderSelector
                      enableSelectAll={true}
                      useCode={true}
                      multiple={true}
                      ellipsisMaxWidth={300}
                      isSecureFilterByReasons={true}
                      // filterByReasons={[
                      //   this.state.schedulingPayload.appointmentReasonId
                      // ]}
                      enableFilters={true}
                      roleFilter="appointment"
                      filterByClinics={
                        selectedClinic ? [selectedClinic.id] : []
                      }
                      providerFilter={(provider) => {
                        if (this.state.schedulingPayload.appointmentReasonId) {
                          const providers = this.state.clinicProviders.map(
                            (item: any) => item.providerId
                          );
                          const validProviders = providersValidForReason(
                            this.state.schedulingPayload.appointmentReasonId,
                            providers,
                            this.props.selectedClinic.code
                          );
                          return validProviders
                            .map((item) => item.code)
                            .includes(provider.code);
                        }
                        return true;
                      }}
                      disableMinWidth={true}
                      value={schedulingPayload.providerCodes}
                      onChange={(values: any) => this.onSelectProvider(values)}
                      selectProps={{
                        star: true,
                        bottomMargin: false,
                        id: test_ids.providerSelector
                      }}
                    />
                    <ErrorBlock error={error.provider}>
                      Field is mandatory
                    </ErrorBlock>
                  </Col>
                  <Col span={12}>
                    {!isStaffAppt && (
                      <GroupAppointmentReason
                        value={schedulingPayload.groupIds}
                        onChange={(value: number[]) =>
                          this.onSelectGroiupIds(value)
                        }
                        selectProps={{
                          star: false,
                          inputProps: {
                            mode: 'multiple',
                            menuItemSelectedIcon: '',
                            showArrow: true,
                            tagRender: (props: any) => {
                              if (
                                schedulingPayload.groupIds.length > 0 &&
                                schedulingPayload.groupIds[0] === props.value
                              ) {
                                return (
                                  <div style={{ marginLeft: 8 }}>
                                    <EllipsisText maxWidth={160}>
                                      {schedulingPayload.groupIds
                                        .map(
                                          (id) =>
                                            groupAppointmentReasonById(id)
                                              ?.name || ''
                                        )
                                        .join(', ')}
                                    </EllipsisText>
                                  </div>
                                );
                              }
                              return null;
                            }
                          },
                          checkboxOptions: true,
                          id: test_ids.groupTypeSelector,
                          bottomMargin: true
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </ProviderBlock>
            </Header>
            <LeftContainer>
              <Row align="middle" gutter={16}>
                <Col span={12}>
                  <SelectContainer
                    id={test_ids.dateFrom}
                    label="Date From"
                    type="date"
                    value={this.state.dateFrom}
                    onChange={(value: any) => {
                      this.onDateFromChange(value);
                    }}
                    inputProps={{
                      allowClear: false,
                      disabledDate: (currentDate) => {
                        if (
                          currentDate.format('DD/MM/YYYY') ===
                          moment().format('DD/MM/YYYY')
                        ) {
                          return false;
                        }
                        if (currentDate) {
                          return currentDate < moment();
                        }
                        return false;
                      }
                    }}
                  />
                </Col>
                <Col span={12}>
                  <SelectContainer
                    id={test_ids.dateTo}
                    label="Date To"
                    type="date"
                    value={this.state.dateTo}
                    onChange={(value: any) => {
                      this.onDateToChange(value);
                    }}
                    inputProps={{
                      allowClear: false,
                      disabledDate: (currentDate: any) => {
                        if (currentDate) {
                          return currentDate < moment();
                        }
                        return false;
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row align="middle" gutter={16}>
                <Col span={12}>
                  <SelectContainer
                    id={test_ids.timeFrom}
                    label="Time From"
                    avoidOptionsSorting={true}
                    type="select"
                    value={schedulingPayload.startTime}
                    onChange={(e: any) => this.onSelectTimeFrom(e)}
                    options={startTimeOptions}
                    inputProps={{
                      placeholder: '--',
                      disabled: this.state.dateFrom ? false : true
                    }}
                  />
                </Col>
                <Col span={12}>
                  <SelectContainer
                    id={test_ids.timeTo}
                    label="Time to"
                    avoidOptionsSorting={true}
                    type="select"
                    value={schedulingPayload.endTime}
                    onChange={(value: any) => {
                      this.onChangeSchedulePayload({
                        endTime: value
                      });
                    }}
                    options={endTimeOptions}
                    inputProps={{
                      placeholder: '--',
                      disabled: schedulingPayload.startTime ? false : true
                    }}
                  />
                </Col>
              </Row>
              <Row align="middle" gutter={16}>
                <Col span={12}>
                  <MultipleSelector
                    id={test_ids.days}
                    label="Day"
                    options={weekDaysList}
                    value={schedulingPayload.weekdays}
                    onChange={(value: any) =>
                      this.onChangeSchedulePayload({
                        weekdays: value
                      })
                    }
                    selectProps={{
                      avoidOptionsSorting: true,
                      inputProps: {
                        placeholder: 'All',
                        'aria-label':
                          'Day of week: ' +
                          (schedulingPayload.weekdays.length > 0
                            ? schedulingPayload.weekdays.join(', ')
                            : 'all days')
                      }
                    }}
                  />
                </Col>
                <Col span={12}>
                  <SelectContainer
                    id={test_ids.duration}
                    label="Duration"
                    avoidOptionsSorting={true}
                    options={durationOptions}
                    value={schedulingPayload.duration}
                    onChange={(duration: any) => {
                      this.onChangeSchedulePayload({
                        duration
                      });
                    }}
                    inputProps={{
                      'aria-label':
                        'Duration: ' +
                        durationOptions.find(
                          (item: any) =>
                            item.value === schedulingPayload.duration
                        )?.label
                    }}
                  />
                </Col>
              </Row>
              {scheduleEvent?.scheduleEventTypeId &&
                scheduleEvent?.scheduleEventTypeId === 1 && (
                  <Row>
                    <Checkbox
                      id={test_ids.isReservation}
                      checked={schedulingPayload.inReservation}
                      onChange={() => {
                        this.onChangeSchedulePayload({
                          inReservation: !schedulingPayload.inReservation
                        });
                      }}
                    >
                      Search only in Reservations
                    </Checkbox>
                  </Row>
                )}
              <ButtonContainer>
                <SetupTimeButtonContainer style={{ position: 'relative' }}>
                  <button
                    className="custom-button"
                    id={test_ids.searchButton}
                    type="button"
                    onClick={() => this.onClickSetupTime()}
                  >
                    <SearchIconSvg />
                    <span style={{ margin: '0 0 0 5px' }}>Search</span>
                  </button>
                </SetupTimeButtonContainer>
              </ButtonContainer>
            </LeftContainer>
          </div>
          <RightContainer>
            {this.props.isLoading && <AbsoluteLoader />}
            <TimeOptionList
              nextAvailableOptions={
                this.props.nextEvents.nextAvailableOptions || []
              }
              onSelectedDate={this.onSelectedDate.bind(this)}
              clinicOptions={clinicOpts}
              providers={this.props.providers}
            />
          </RightContainer>
        </Body>
      </SelectDateTimeContainer>
    );
  }
}

export default SelectDateTime;

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  min-height: 110px;

  .ant-empty-normal {
    margin: 0 auto !important;
  }

  .header-attachments {
    display: flex;
    align-items: center;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: ${globalStyleVariables.brandGray};

    .line {
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
      display: flex;
      flex: 1;
      margin: 0 0 0 16px;
    }

    margin-bottom: 16px;
  }

  .attachments-container {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const Item = styled.div`
  position: relative;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  background: #f7f7f7;
  border-radius: 2px;
  margin: 0 10px 10px 0;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  cursor: pointer;

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 112px;
    padding: 0 10px 0 0;
  }

  .close-icon-container {
    padding: 10px 10px;
    cursor: pointer;

    .close-icon {
      height: 6px;
      width: 6px;
      mask-size: contain;
      mask-position: center center;
      mask-repeat: no-repeat no-repeat;
      background: black;
    }
  }

  .type-icon {
    height: 18px;
    width: 18px;
    mask-size: contain;
    mask-position: center center;
    mask-repeat: no-repeat no-repeat;
    background: black;
    margin: 0 10px 0 0;
  }

  &:hover {
    color: ${globalStyleVariables.newBrandingPrimary200};

    .close-icon,
    .type-icon {
      background: ${globalStyleVariables.newBrandingPrimary200};
    }
  }
`;

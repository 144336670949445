import { IScheduleEvent } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import {
  formattedDateTime,
  uppercaseFirstLatter
} from 'src/Framework/Shared/Shared';
import { patientDisplayName } from 'src/Framework/util/format';
import {
  getStatus,
  getGroupStatus,
  getEventStatus,
  getApptIcon
} from 'src/Activities/Schedule/utils';

import { providerDisplayName } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';

export const generateDataItem = (item: IScheduleEvent) => {
  const icon = getApptIcon({
    isBlock: item.isBlock!,
    isOutreach: item.isOutreach!,
    isEvent: item.isEvent!,
    typeAppointmentParticipantId: item.typeAppointmentParticipantId
  });

  return {
    startTime: formattedDateTime(item.startTime),
    endTime: formattedDateTime(item.endTime),
    conflictProps: {
      ...item
    },
    id: item.isOutreach
      ? item.outreachId!
      : item.isEvent
      ? item.eventId!
      : item.appointmentId,
    isOutreach: item.isOutreach!,
    isEvent: item.isEvent!,
    note: item.note,
    iconName: icon.name,
    iconTooltip: icon.text,
    isGroupAppt:
      icon.name === 'groupList' ||
      icon.name === 'family' ||
      icon.name === 'usersGroup',
    client: item.patientInfo
      ? patientDisplayName({
          firstName: item.patientInfo.firstName,
          lastName: item.patientInfo.lastName,
          preferredName: item.patientInfo.preferredName
        })
      : item.patients
      ? Object.values(item.patients)
          .map((item) =>
            patientDisplayName({
              firstName: item.firstName,
              lastName: item.lastName,
              preferredName: item.preferredName
            })
          )
          .join(', ')
      : item.isEvent
      ? 'Schedule Event'
      : 'Outreach',
    type: item.reason.name,
    provider: item.isEvent
      ? item
          .eventMembers!.map((item) => providerDisplayName(item.providerId))
          .join(', ')
      : item.providers && item.providers.length > 0
      ? item.providers.join(', ')
      : item.provider
      ? item.provider.name
      : 'Outreach',
    status: item.isEvent
      ? uppercaseFirstLatter(
          getEventStatus({
            date: item.startTime,
            patients: item.eventMembers!
          })
        )
      : item.patientInfo?.patientStatus
      ? uppercaseFirstLatter(
          getStatus({
            eventStatus: item.patientInfo.patientStatus,
            date: item.startTime
          })
        )
      : item.patients
      ? uppercaseFirstLatter(
          getGroupStatus({
            date: item.startTime,
            patients: item.patients
          })
        )
      : 'Outreach'
  };
};

import React, { FC } from 'react';
import { Row, Col } from 'antd';

import { Container, Description, Label, Value } from './styled';

import { ITodoItem } from '../../../Todos/store/types';
import { getPatientTerm } from '../../../../../Framework/util/patient-term-util';
import { store, useAppSelector } from '../../../../../store';
import Checkbox from '../../../../../Framework/Controls/Table/Checkbox';
import * as dashboardActions from '../../store/Actions';
import {
  clinicDisplayName,
  patientDisplayName
} from 'src/Framework/util/format';
import { providerDisplayName } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';

interface IProps {
  value: ITodoItem;
}

const Component: FC<IProps> = ({ value }: IProps) => {
  const { clinics } = useAppSelector((state) => ({
    clinics: state.adminPanel.clinics.summary
  }));
  const clinic = value.assignedClinicId
    ? clinics[value.assignedClinicId]
    : null;
  const providers = store.getState().schedule.clinicProviders.data;
  const provider = providers.find(
    (provider: any) => provider.providerId === value.assignedProviderId
  );
  const users = store.getState().adminPanel.users.summary;
  const find = users[value.userId];
  let user = '';
  if (find) {
    user = `${find.name}`;
  }
  return (
    <Container>
      <div className="task-icon-container">
        <div style={{ width: '16px' }}>
          <Checkbox
            value={value.isDone}
            onChangeAction={async () => {
              await dashboardActions.patchTodo({
                id: value.id,
                isLegacy: value.isLegacy,
                isDone: !value.isDone
              });
            }}
          />
        </div>
      </div>
      <Col flex="auto">
        <Description>{value.description}</Description>
        <Row gutter={8} align="middle">
          <Col span={4}>
            <Row>
              <Label>Created by</Label>
              <Value>{user}</Value>
            </Row>
          </Col>
          <Col span={7}>
            <Row className="right-item">
              <Label>Clinic</Label>
              <Value>{clinic ? clinicDisplayName(clinic) : ''}</Value>
            </Row>
          </Col>
          <Col span={7}>
            <Row className="right-item">
              <Label>{getPatientTerm('Subject')}</Label>
              <Value>{patientDisplayName(value.assignedPatient)}</Value>
            </Row>
          </Col>
          <Col span={6}>
            <Row className="right-item">
              <Label>Provider</Label>
              {provider && <Value>{providerDisplayName(provider)}</Value>}
            </Row>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default Component;

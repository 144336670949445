const ids = {
  CONTAINER: 'reservation-block-container',
  removeButton: 'remove-button',
  saveButton: 'save-button',
  closeButton: 'close-button',
  clinicSelect: 'clinic-select',
  providerSelect: 'provider-select',
  options: {
    reservationType: 'reservation-type-option',
    reservationGroup: 'reservation-group-option'
  },
  groupSelect: 'reservation-group-select',
  reasonSelect: 'reason-select',
  colorSelect: 'color-select',
  recurringButton: 'recurring-button',
  startDate: 'start-date-picker',
  startTime: 'start-time-select',
  endTime: 'end-time-select',
  noteArea: 'note-area',
  releaseBeforeStartTimeCheckbox: 'release-before-start-time-checkbox',
  releaseBeforeContainer: 'release-before-container',
  days: 'days-select',
  hours: 'hours-select',
  min: 'min-select',
  conflictsContainer: 'conflicts-container',
  overrideButton: 'override-button',
  allDayBlockCheckbox: 'all-day-block-checkbox'
};

export default ids;

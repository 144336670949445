import React from "react";

export const GroupApptSvg = () => (
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5">
<path d="M9 8.66675C9.55228 8.66675 10 8.21903 10 7.66675C10 7.11446 9.55228 6.66675 9 6.66675C8.44772 6.66675 8 7.11446 8 7.66675C8 8.21903 8.44772 8.66675 9 8.66675Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3 8.66675C3.55228 8.66675 4 8.21903 4 7.66675C4 7.11446 3.55228 6.66675 3 6.66675C2.44772 6.66675 2 7.11446 2 7.66675C2 8.21903 2.44772 8.66675 3 8.66675Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5 11.0001V10.6724C5 10.4357 4.876 10.2181 4.67233 10.0974C4.348 9.90575 3.786 9.66675 3 9.66675C2.204 9.66675 1.64533 9.90441 1.32467 10.0961C1.12267 10.2167 1 10.4337 1 10.6691V11.0001H5Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11 11.0001V10.6724C11 10.4357 10.876 10.2181 10.6723 10.0974C10.348 9.90575 9.786 9.66675 9 9.66675C8.204 9.66675 7.64533 9.90441 7.32467 10.0961C7.12267 10.2167 7 10.4337 7 10.6691V11.0001H11Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5 5.33333V5.00567C5 4.769 4.876 4.55133 4.67233 4.43067C4.348 4.239 3.786 4 3 4C2.204 4 1.64533 4.23767 1.32467 4.42933C1.12267 4.55 1 4.767 1 5.00233V5.33333H5Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3 3C3.55228 3 4 2.55228 4 2C4 1.44772 3.55228 1 3 1C2.44772 1 2 1.44772 2 2C2 2.55228 2.44772 3 3 3Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11 5.33333V5.00567C11 4.769 10.876 4.55133 10.6723 4.43067C10.348 4.239 9.786 4 9 4C8.204 4 7.64533 4.23767 7.32467 4.42933C7.12267 4.55 7 4.767 7 5.00233V5.33333H11Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9 3C9.55228 3 10 2.55228 10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3Z" fill="#333333" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</svg>









);

import { IData } from '../components/Tabs';
import { validateClinicOpenByDate } from '../../../../Schedule/Utils/Utils';
import React from 'react';
import { ClosedLabel } from 'src/Activities/Schedule/TopBar/Header/Provider/styled';
import { TabLabel } from './styled';

export const tabsData: IData = [
  {
    label: 'Yesterday',
    value: -1
  },
  {
    label: 'Today',
    value: 0
  },
  {
    label: 'Tomorrow',
    value: 1
  }
];

export const appointmentsTabsData = (open: boolean[]) => [
  {
    label: (
      <TabLabel>
        Yesterday{' '}
        {!open[0] && (
          <ClosedLabel style={{ marginLeft: '5px' }}>Closed</ClosedLabel>
        )}
      </TabLabel>
    ),
    value: -1
  },
  {
    label: (
      <TabLabel>
        Today{' '}
        {!open[1] && (
          <ClosedLabel style={{ marginLeft: '5px' }}>Closed</ClosedLabel>
        )}
      </TabLabel>
    ),
    value: 0
  },
  {
    label: (
      <TabLabel>
        Tomorrow{' '}
        {!open[2] && (
          <ClosedLabel style={{ marginLeft: '5px' }}>Closed</ClosedLabel>
        )}
      </TabLabel>
    ),
    value: 1
  }
];

export const getClinicOpen = (clinicCode: string) => {
  const open: boolean[] = [];
  for (let i = -1; i <= 1; i++) {
    const date = new Date();
    date.setDate(date.getDate() + i);
    const clinicOpen = validateClinicOpenByDate(clinicCode, date.toString());
    open.push(clinicOpen);
  }
  return open;
};

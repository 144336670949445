import React, { useImperativeHandle, useState } from 'react';
import { Button, Row, Col } from 'antd';
import _ from 'lodash';

import Modal from 'src/Framework/Controls/Modal';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import ElementTitle from 'src/Framework/Controls/ElementTitle';
import Checkbox from 'src/Framework/Controls/Checkbox';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import ids from 'src/tests-script/pages/Subject/Contacts';

import { getOptions } from 'src/Activities/Subject/PatientListActions';
import PreferredNumber from 'src/Activities/Subject/Patients/Demographics/Controls/PreferredNumber';
import { newPatientContact } from 'src/Activities/Subject/Patients/PatientInfoConstants';
import GroupTypesSelector from 'src/App/Admin/Pages/GroupTypes/Components/GroupTypesSelector';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

import * as patientActions from 'src/Activities/Subject/Patients/store/Actions';
import {
  IPatientContactsSimpleDto,
  PatientDemographicDto
} from 'src/Activities/Subject/Patients/store/types';

import { Container } from './styled';

// import { ClinicSelector } from 'src/Framework/Controls/Selectors';

interface IProps {
  patientLists: any;
  patientData?: PatientDemographicDto;
  subjectId: string | number;
}

export interface IModalHandles {
  show(obj: any): void;

  close(): void;
}

type IContactType = IPatientContactsSimpleDto & {
  copy?: boolean;
};

const prefix = `oc-modal-`;

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { patientLists, patientData, subjectId },
  ref
) => {
  const getInitData = () => ({
    ..._.cloneDeep(newPatientContact)
  });
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IContactType>(getInitData());

  const [previousData, setPreviousData] = useState<IContactType | null>(null);
  useModalFocus({ active: visible });
  const show = async ({ value }: any) => {
    if (value) {
      setPreviousData({ ...value });
      setData(value);
    }
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setData(getInitData());
    setPreviousData(null);
  };

  useImperativeHandle(ref, () => ({
    show: (obj: any) => show(obj),
    close
  }));

  const apply = async () => {
    setLoading(true);
    if (previousData) {
      const dataToSend: any = _.cloneDeep(data);
      dataToSend.id = previousData.id;
      if (previousData.responsibleParty === data.responsibleParty) {
        delete dataToSend.responsibleParty;
      }
      const res = await patientActions.patchPatientContact(
        +subjectId,
        dataToSend
      );
      if (res) {
        close();
      }
    } else {
      const res = await patientActions.postPatientContact(+subjectId, {
        ...data
      });
      if (res) {
        close();
      }
    }
    setLoading(false);
  };

  const onChangeData = (obj: Partial<IContactType>) => {
    setData({
      ...data,
      ...obj
    });
  };

  const checkDisabled = () => {
    if (!data.otherParty.firstName || !data.otherParty.lastName) {
      return true;
    }
    return false;
  };
  const onChange = (value: any, field: any) => {
    onChangeData({
      otherParty: {
        ...data.otherParty,
        [field]: value
      }
    });
  };

  const onChangeOtherParty = (obj: Partial<IContactType['otherParty']>) => {
    setData((data) => ({
      ...data,
      otherParty: {
        ...data.otherParty,
        ...obj
      }
    }));
  };

  const title = previousData ? 'Modify Contact' : 'Add Contact';

  const sexOptions = patientLists?.gender
    ? getOptions(patientLists.gender, 'code')
    : [];

  const defaultWidth = 680;

  const headerRight = (
    <Button
      id={`${prefix}save`}
      disabled={checkDisabled()}
      loading={loading}
      onClick={apply}
      shape="round"
    >
      Save
    </Button>
  );
  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        headerRight,
        title
      }}
      id={ids.ADD_CONTACT_MODAL}
      width={defaultWidth}
      title={title}
      visible={visible}
      onClose={close}
      afterClose={setToDefault}
      headerBorder={true}
      modalProps={{
        destroyOnClose: true
      }}
      buttons={headerRight}
    >
      <Container className="custom-checkbox">
        {/* <Row align="middle" gutter={16}>
          <Col>
            <Checkbox
              id={`${prefix}emergencyContact`}
              checked={data.emergencyContact}
              marginBottom={true}
              onChange={() => {
                onChangeData({
                  emergencyContact: !data.emergencyContact,
                  clinicIds: data.emergencyContact ? [] : data.clinicIds
                });
              }}
            >
              Emergency Contact
            </Checkbox>
          </Col>
          <Col style={{ width: 250 }}>
            <ClinicSelector
              multiple={true}
              value={data.clinicIds}
              onChange={(clinicIds) => {
                onChangeData({
                  clinicIds
                });
              }}
              selectProps={{
                selectProps: {
                  star: false,
                  inputProps: {
                    disabled: !data.emergencyContact
                  }
                }
              }}
            />
          </Col>
        </Row> */}
        <Row align="middle" gutter={16}>
          <Col>
            <Checkbox
              id={`${prefix}emergencyContact`}
              checked={data.emergencyContact}
              marginBottom={true}
              onChange={() => {
                onChangeData({
                  emergencyContact: !data.emergencyContact,
                  clinicIds: data.emergencyContact ? [] : data.clinicIds
                });
              }}
            >
              Emergency Contact
            </Checkbox>
          </Col>
          <Col>
            <Checkbox
              id={`${prefix}responsibleParty`}
              marginBottom={true}
              checked={data.responsibleParty}
              onChange={() => {
                onChangeData({
                  responsibleParty: !data.responsibleParty
                });
              }}
            >
              Responsible Party
            </Checkbox>
          </Col>
          <Col>
            <Checkbox
              id={`${prefix}preventMppLogin`}
              marginBottom={true}
              checked={data.otherParty.preventMppLogin}
              onChange={() => {
                onChangeOtherParty({
                  preventMppLogin: !data.otherParty.preventMppLogin
                });
              }}
            >
              Prevent Medicat Patient Portal (MPP) Login
            </Checkbox>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={14}>
            <SelectContainer
              star={false}
              type="input"
              label="Email"
              value={data.otherParty.email}
              onChange={(email: string) => {
                onChangeOtherParty({
                  email
                });
              }}
              inputProps={{
                id: `${prefix}email`
              }}
            />
          </Col>
          <Col span={10}>
            <ElementTitle name="Preferred Number" />
            <PreferredNumber
              onChangeHandler={onChange}
              info={{
                cellPhone: data.otherParty.cellPhone,
                homePhone: data.otherParty.homePhone,
                pager: data.otherParty.pager,
                workPhone: data.otherParty.workPhone,
                typePreferredPhoneId: `${data.otherParty.typePreferredPhoneId}`
              }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <SelectContainer
              star={true}
              type="input"
              label="First Name"
              value={data.otherParty.firstName}
              onChange={(firstName: string) => {
                onChangeData({
                  otherParty: {
                    ...data.otherParty,
                    firstName
                  }
                });
              }}
              inputProps={{
                id: `${prefix}firstName`
              }}
            />
          </Col>
          <Col span={12}>
            <SelectContainer
              star={true}
              type="input"
              label="Last Name"
              value={data.otherParty.lastName}
              onChange={(lastName: string) => {
                onChangeData({
                  otherParty: {
                    ...data.otherParty,
                    lastName
                  }
                });
              }}
              inputProps={{
                id: `${prefix}lastName`
              }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <GroupTypesSelector
              type="single"
              labelField="relationship"
              id={`${prefix}relationshipId`}
              name="Relationship"
              groupId="typeRelationship"
              value={data.relationshipId}
              onChange={(relationshipId) => {
                onChangeData({
                  relationshipId
                });
              }}
              selectProps={{
                star: false
              }}
            />
          </Col>
          <Col span={8}>
            <SelectContainer
              star={false}
              type="date"
              label="Birth Date"
              value={data.otherParty.birthDate}
              onChange={(birthDate) => {
                onChangeOtherParty({
                  birthDate: birthDate ? birthDate : ''
                });
              }}
              inputProps={{
                id: `${prefix}birthDate`
              }}
            />
          </Col>
          <Col span={8}>
            <SelectContainer
              star={false}
              type="select"
              label="Sex"
              value={data.otherParty.sex}
              options={sexOptions}
              onChange={(sex: string) => {
                onChangeOtherParty({
                  sex
                });
              }}
              inputProps={{
                id: `${prefix}sex`
              }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <SelectContainer
              star={false}
              type="input"
              label="Address"
              value={data.otherParty.address}
              onChange={(address: string) => {
                onChangeOtherParty({
                  address
                });
              }}
              inputProps={{
                id: `${prefix}address`
              }}
            />
          </Col>
          <Col span={12}>
            <SelectContainer
              star={false}
              type="input"
              label="Address 2"
              value={data.otherParty.address2}
              onChange={(address2: string) => {
                onChangeOtherParty({
                  address2
                });
              }}
              inputProps={{
                id: `${prefix}address2`
              }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <SelectContainer
              star={false}
              type="input"
              label="City"
              value={data.otherParty.city}
              onChange={(city: string) => {
                onChangeOtherParty({
                  city
                });
              }}
              inputProps={{
                id: `${prefix}city`
              }}
            />
          </Col>
          <Col span={8}>
            <SelectContainer
              star={false}
              type="input"
              label="State"
              value={data.otherParty.state}
              onChange={(state: string) => {
                onChangeOtherParty({
                  state
                });
              }}
              inputProps={{
                id: `${prefix}state`
              }}
            />
          </Col>
          <Col span={8}>
            <SelectContainer
              star={false}
              type="input"
              label="Zip"
              value={data.otherParty.zip}
              onChange={(zip: string) => {
                onChangeOtherParty({
                  zip
                });
              }}
              inputProps={{
                id: `${prefix}zip`
              }}
              inputMask="zip"
            />
          </Col>
        </Row>
        <Checkbox
          id={`${prefix}copy`}
          checked={data.copy}
          onChange={() => {
            if (data.copy) {
              const values = {
                address: '',
                address2: '',
                city: '',
                state: '',
                zip: ''
              };
              onChangeData({
                otherParty: {
                  ...data.otherParty,
                  ...values
                },
                copy: false
              });
            } else {
              if (patientData) {
                const values = {
                  address: patientData.address,
                  address2: patientData.address2,
                  city: patientData.city,
                  state: patientData.state,
                  zip: patientData.zip
                };
                onChangeData({
                  otherParty: {
                    ...data.otherParty,
                    ...values
                  },
                  copy: true
                });
              }
            }
          }}
        >
          Copy from {getPatientTerm('Subject')} address
        </Checkbox>
      </Container>
    </Modal>
  );
};

export default React.forwardRef(Component);

import React from "react";

interface IProps {
    stroke?: string;
    height?: string;
    width?: string;
}
export const DischargedSvg = ({ stroke, height, width }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg"   width={width || "20"} height={height || "20"}viewBox="0 0 20 20" fill="none">
        <circle cx="10" cy="10" r="7.5" stroke={stroke || "#666666"} />
        <path d="M6.7 10.8998L8.5 12.6998L13.3 7.2998" stroke={stroke || "#666666"} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

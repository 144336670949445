export const defaultValueField = 'defaultCustomValue'

export const getComponentState = (root: any) => {
    const isBuilder = root.options.attachMode === 'builder';
    const isPreview = root.options.renderMode === 'html';
    const isDataTab = root.components?.[0]?.path === "tabs" ? true : false;
    const isRender = isBuilder || isPreview || isDataTab ? false : true;
    return {
        isBuilder,
        isPreview,
        isDataTab,
        isRender
    };
};

export const getValidatedValue = <T>(data: T & { $values?: T }) => {
    if (data && typeof data === 'object' && data.$values) {
        return data.$values
    }
    return data
}
import React, { FC } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';

import { IMessage } from 'src/Activities/Home/Messages/store/types';
import { loadActivityTab } from 'src/App/ActivityPanel/Store/ActivityActions';

import store from 'src/store';

import { Container, Message, Name, Date, Avatar } from './styled';

interface IProps {
  value: IMessage;
}

const Component: FC<IProps> = ({ value }: IProps) => {
  return (
    <Container
      onClick={() => {
        const url = `/activity/messages?messageId=${value.id}&type=${value.type}&channel=${value.channel}`;
        loadActivityTab(url, store.dispatch);
      }}
    >
      <Row style={{ flexWrap: 'unset' }}>
        <Col flex={'45px'}>
          <Avatar>{value.actors.sender.displayName[0]}</Avatar>
        </Col>
        <Col flex={'auto'}>
          <Row align="middle" justify="space-between">
            <Name>{value.actors.sender.displayName}</Name>
            <Date>{moment(value.dts).format('MM.DD.YYYY, HH:mm')}</Date>
          </Row>
          <Message>{value.subject}</Message>
        </Col>
      </Row>
    </Container>
  );
};

 

export default React.memo(Component);

import React from 'react';

interface IProps {
  fill?: string;
  stroke?: string;
}

export const RecurreSvg = ({ fill, stroke }: IProps) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.3" cx="16" cy="16" r="16" fill={fill || '#E6E6E6'} />
    <path
      d="M15.5 22.5C16.63 22.5 17.698 22.232 18.644 21.756"
      stroke={stroke || '#3A5B8B'}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3066 20.193C10.9226 20.875 11.6716 21.435 12.5126 21.832"
      stroke={stroke || '#3A5B8B'}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 15.5C8.5 16.381 8.663 17.224 8.96 18"
      stroke={stroke || '#3A5B8B'}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.797 20.076C21.858 18.849 22.5 17.25 22.5 15.5C22.5 11.634 19.366 8.5 15.5 8.5C12.864 8.5 10.672 10.141 9.5 12.5"
      stroke={stroke || '#3A5B8B'}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 7.5V12.5H14.5"
      stroke={stroke || '#3A5B8B'}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

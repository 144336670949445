import _ from 'lodash'
import store from 'src/store';
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"

import { actionsTypes } from './index'

import { IFilter, IGenericTypes, AppointmentReasonSimpleDto } from './types'
import { ISort } from 'src/Framework/Controls/Table/types';

const setLoading = (payload: boolean) => {
    store.dispatch({
        type: actionsTypes.SET_LOADING,
        payload
    })
}

export const setSort = (payload: ISort) => {
    store.dispatch({
        type: actionsTypes.SET_SORT,
        payload
    })
}

const setData = (payload: any) => {
    store.dispatch({
        type: actionsTypes.SET,
        payload
    })
}

const setGenericTypes = (payload: IGenericTypes) => {
    store.dispatch({
        type: actionsTypes.SET_GENERIC_TYPES,
        payload
    })
}

const onChangeData = (payload: any) => {
    const summary = store.getState().adminPanel.appointmentTypes.summary
    summary[payload.id] = {
        ...payload
    }
    setData(summary)
}

export const getGenericTypes = async () => {
    try {
        const response1 = await APIHandler.AxiosInstance.get(ApiUrl.CCMHAppointmentCategory, { withCredentials: true })
        const response2 = await APIHandler.AxiosInstance.get(ApiUrl.GroupAppointmentReason, { withCredentials: true })
        if (response1.data.success && response2.data.success) {
            setGenericTypes({
                CCMHAppointmentCategory: response1.data.result.summary,
                groupAppointmentReason: response2.data.result.summary
            })
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const get = async () => {
    try {
        const summary = store.getState().adminPanel.appointmentTypes.summary
        if (Object.values(summary).length === 0) {
            setLoading(true)
        }
        const response = await APIHandler.AxiosInstance.get(ApiUrl.APPOINTMENT_REASONS, { withCredentials: true })
        if (response.data) {
            if (response.data.success) {
                const data: any = response.data.result.summary
                setData(data)
                return data
            }
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
        setLoading(false)
    }
}

export const post = async (body: Partial<AppointmentReasonSimpleDto>): Promise<AppointmentReasonSimpleDto | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(ApiUrl.APPOINTMENT_REASONS, body, { withCredentials: true, interceptError: true })
        if (response.data) {
            if (response.data.success) {
                const items: any = Object.values(response.data.result.summary)
                items.forEach((v: any) => {
                    onChangeData(v)
                })
                return items[0]
            }
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const patch = async (body: Partial<AppointmentReasonSimpleDto>): Promise<AppointmentReasonSimpleDto | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.patch(`${ApiUrl.APPOINTMENT_REASONS}/${body.id}`, body, { withCredentials: true, interceptError: true })
        if (response.data) {
            if (response.data.success) {
                const items: any = Object.values(response.data.result.summary)
                items.forEach((v: any) => {
                    onChangeData(v)
                })
                return items[0]
            }
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const onChangeFilter = (payload: Partial<IFilter>) => {
    store.dispatch({
        type: actionsTypes.SET_FILTER,
        payload
    })
}
import styled from "styled-components";

export const TimeOptionListLayout = styled.div`
  display: flex;
  flex:1;
         height: 100%;
         width: 100%;
         background: white;
`;

export const ButtonContainer = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
`;
import * as React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { IActivity } from "../../App/ActivityPanel/Models/Activity";
import { IStore } from "src/store";

class Screen extends React.Component<any, any> {
  _key: any = 1;

  static contextTypes = {
    content: PropTypes.any,
    router: () => {
      /* do nothing */
    }
  };

  static childContextTypes = {
    currentActivity: PropTypes.any
  };

  getChildContext(): any {
    return {
      currentActivity: this.currentActivity(),
    };
  }

  currentActivity(): IActivity | undefined {
    let activity: IActivity | undefined = this.props.activities.find(
      (a: IActivity) => a.isCurrent === true
    );
    if (!activity) return undefined;
    return activity;
  }

  getKey(): any {
    // set the key as current location to re-render the component even for the route param change.
    let location: any = this.context && this.context.router && this.context.router.history && this.context.router.history.location;
    if (location && location.state && location.state.screenChange) {
      this._key = window.location.pathname;
    }
    return this._key;
  }

  render(): any {
    return <Container key={this.getKey()}>{this.props.children}</Container>;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    activities: state.activity
  };
};

export default connect(mapStateToProps)(Screen as any) as any;


class Container extends React.Component {
  render(): any {
    return this.props.children;
  }
}

import React, { FC } from 'react';

import MultipleSelector from '../MultipleSelector';
import { IOption, IMultiple } from './types';

interface IProps extends IMultiple {
  options: IOption[];
}

const Component: FC<IProps> = ({
  id,
  value,
  onChange,
  selectProps,
  options
}: IProps) => {
  return (
    <MultipleSelector
      label={`${selectProps?.label || ''} Categories`.trim()}
      id={id}
      selectProps={{
        ...selectProps
      }}
      options={options}
      value={value}
      onChange={(value) => {
        onChange(value);
      }}
    />
  );
};

export default Component;

import React, { FC } from 'react';
import { Row, Col } from 'antd';

import Icon from 'src/Framework/Controls/Icon';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import { providerDisplayName } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { formattedDate, formattedTime } from 'src/Framework/Shared/Shared';
import { patientDisplayName } from 'src/Framework/util/format';

import {
  IApptResponse,
  IScheduleEvent
} from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import Preview from 'src/App/ConflictResolutionManager/Preview';
import Editor from 'src/App/ConflictResolutionManager/Editor';
import { generateDataItem } from 'src/App/ConflictResolutionManager/utils';

import { columns } from '../index';
import GroupAppt from './GroupAppt';

import { Container } from './styled';
import { getUserByCode } from 'src/Framework/Controls/Selectors/UserSelector/utils';

interface IProps {
  value: IScheduleEvent;
  getEvent: (
    appointmentId: number,
    scheduleEventTypeId?: number
  ) => Promise<IApptResponse | undefined>;
  refreshList: () => Promise<any>;
  enableEdit: boolean;
}

const Component: FC<IProps> = ({
  value,
  getEvent,
  refreshList,
  enableEdit
}) => {
  const isCancelled = value.cancellation?.isCancelled === true;
  const item = generateDataItem(value);

  const autoStyle = {
    flex: '1 1 92px',
    minWidth: 92,
    overflow: 'hidden',
    padding: '0 8px'
  };

  const centerStyle = {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 8px'
  };

  const textStyle = {
    overflow: 'hidden',
    padding: '0 8px'
  };

  const getColStyle = (colNumber: number, isIcon?: boolean) => {
    return columns[colNumber] === 'auto'
      ? autoStyle
      : isIcon
      ? centerStyle
      : textStyle;
  };

  return (
    <Container cancelled={isCancelled}>
      <Row align="middle" justify="start" wrap={false}>
        <Col flex={columns[1]} style={getColStyle(1, true)}>
          <Icon name="calendarAdd" size={18} />
        </Col>
        <Col style={getColStyle(2)}>
          <EllipsisText>{`${formattedDate(value.startTime)}, ${formattedTime(
            value.startTime
          )} - ${formattedTime(value.endTime)}`}</EllipsisText>
        </Col>
        <Col style={getColStyle(3)}>
          <EllipsisText>{value.reason?.name}</EllipsisText>
        </Col>
        <Col flex={columns[4]} style={getColStyle(4)}>
          <EllipsisText>{value.clinicName}</EllipsisText>
        </Col>
        {value.providers && value.providers.length > 1 ? (
          <Col flex={columns[5]} style={getColStyle(5)}>
            <Icon
              tooltip={value.providers
                .map((v) => providerDisplayName(v.id))
                .join(', ')}
              name="groupList"
            />
          </Col>
        ) : (
          <Col flex={columns[5]} style={getColStyle(5)}>
            <EllipsisText>
              {value.waitingList && value.waitingList?.id !== 0
                ? value.waitingList?.name
                : value.provider?.name}
            </EllipsisText>
          </Col>
        )}
        {value.groupAppointment ? (
          <Col style={getColStyle(6)}>
            <GroupAppt value={value} getEvent={getEvent} />
          </Col>
        ) : (
          <Col style={getColStyle(6)}>
            <EllipsisText>{patientDisplayName(value.patientInfo)}</EllipsisText>
          </Col>
        )}
        <Col flex={columns[7]} style={getColStyle(7, true)}>
          {value.note && (
            <Icon
              name="warning"
              tooltip={value.note}
              tooltipProps={{
                showArrow: false
              }}
            />
          )}
        </Col>
        {enableEdit && (
          <Col flex={columns[8]} style={getColStyle(8, true)}>
            {value.scheduleEventTypeId !== 5 && !isCancelled && (
              <Preview value={value} />
            )}
          </Col>
        )}
        {enableEdit && (
          <Col flex={columns[9]} style={getColStyle(9, true)}>
            {value.scheduleEventTypeId !== 5 && !isCancelled && (
              <Editor
                id={item.id}
                isOutreach={item.isOutreach}
                isGroupAppt={item.isGroupAppt}
                setRecordChanged={refreshList}
              />
            )}
          </Col>
        )}
      </Row>
      {isCancelled && value.cancellation && (
        <Row justify="start" wrap={false} style={{ paddingTop: '10px' }}>
          <Col flex={columns[1]} style={getColStyle(1, true)}>
            <Icon name="cancelled" size={18} color={'rgb(153, 153, 153)'} />
          </Col>
          <Col style={getColStyle(2)}>
            <EllipsisText>{`${formattedDate(
              value.cancellation.cancelledDate
            )}, ${formattedTime(
              value.cancellation.cancelledDate
            )}`}</EllipsisText>
          </Col>
          <Col style={getColStyle(3)}>
            <EllipsisText>{value.cancellation.cancelReasonName}</EllipsisText>
          </Col>
          <Col flex={columns[4]} style={getColStyle(4)}></Col>
          <Col flex={columns[5]} style={getColStyle(5)}></Col>
          <Col style={getColStyle(6)}>
            <EllipsisText>
              Cancelled by:{' '}
              {getUserByCode(value.cancellation.cancelUserCode)?.name || ''}
            </EllipsisText>
          </Col>
          <Col flex={columns[7]} style={getColStyle(7)}></Col>
          {enableEdit && <Col flex={columns[8]} style={getColStyle(8)}></Col>}
          {enableEdit && <Col flex={columns[9]} style={getColStyle(9)}></Col>}
        </Row>
      )}
    </Container>
  );
};

export default React.memo(Component);

import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import Tooltip from 'src/Framework/Controls/Tooltip';

import ElementTitle from '../ElementTitle';

import SelectComponent, {
  ISelect as ISelectComponent
} from './Components/Select';
import InputComponent, { IInput } from './Components/Input';
import AreaComponent from './Components/Area';
import Time, { ITime } from './Components/Time';
import AutoComplete, { IAutoComplete } from './Components/AutoComplete';
import DateRange from './Components/DateRange';
import Date, { IDate } from './Components/Date';
import Number, { IInputNumber } from './Components/Number';
import TableSelect, {
  IProps as ITableSelect
} from 'src/Framework/Controls/SelectContainer/Components/Table';
import ColorPicker, { IColoPicker } from './Components/ColorPicker';
import TimePicker, { ITimePicker } from './Components/TimePicker';
import { IProps as ILoaderProps } from 'src/Framework/Controls/AbsoluteLoader';

import { Container } from './styled';

import IssueMessage from './IssueMessage';

export type ISelect = ISelectComponent;

const types = ['area', 'dateRange'] as const;

export interface ICommon {
  tooltip?: string;
  id?: string;
  /**
   * @param label  shows under input element
   * */
  label: string | React.ReactNode;
  boldLabel?: boolean;
  /** Hide label element  */
  hideLabel?: boolean;
  /**
   *  @param star Require icon near label
   *  @default {true}
   */
  star?: boolean;
  bottomMargin?: boolean;
  containerClassName?: string;
  helpTooltip?: string;
  loading?: boolean;
  loaderProps?: ILoaderProps;
  /**
   *  @param gray background color of your input element will be gray
   */
  gray?: boolean;
  containerMargin?: boolean;
  /**
   *  @param issueMessage show issue message on bottom of element
   */
  issueMessage?: string;
  connectToContainerOnScroll?: boolean;
  containerRef?: React.RefObject<any>;
  labelInRow?: boolean;
  labelClassName?: string;
}

export interface IInputEditConfirm {
  approveText: string;
}

export interface IOtherSelects extends ICommon {
  type: typeof types[number];
  value?: any;
  range?: Array<string>;
  options?: Array<any>;
  onChange?: Function;
  onBlur?: Function;
  format?: string;
  inputProps?: any;
  className?: string;
  disabled?: boolean;
  onSearch?: Function;
  hasError?: boolean;
  disablePlaceholder?: boolean;
  getPopupContainer?: Function;
  inputEditConfirm?: IInputEditConfirm;
  areaAutoReplace?: boolean;
}

export type IProps =
  | ISelectComponent
  | IInput
  | ITimePicker
  | IColoPicker
  | ITime
  | IInputNumber
  | IAutoComplete
  | IDate
  | ITableSelect
  | IOtherSelects;

/**
 *
 * @requires type
 * @returns
 */
const Component = (props: IProps) => {
  const {
    label,
    star,
    type,
    hideLabel,
    helpTooltip,
    loading,
    gray,
    containerMargin,
    issueMessage,
    containerClassName,
    tooltip,
    boldLabel,
    labelInRow,
    labelClassName,
    bottomMargin
  } = props;
  const selectContainerRef = useRef<any>(null);
  useEffect(() => {
    if (type === 'select' || type === 'table') {
      if (selectContainerRef.current) {
        const element = selectContainerRef.current;
        const inputs = element.getElementsByTagName('input');
        for (let i = 0; i < inputs.length; i++) {
          const input = inputs[i];
          input.setAttribute('autocomplete', 'none');
        }
      }
    }
  }, []);
  const [asyncLoading, setAsyncLoading] = useState(false);
  const Main = () => {
    return (
      <Container
        ref={selectContainerRef}
        className={cn({
          [`${containerClassName}`]: containerClassName ? true : false,
          containerMargin: containerMargin
        })}
        gray={gray}
        labelInRow={labelInRow}
      >
        {(loading || asyncLoading) && <AbsoluteLoader {...props.loaderProps} />}
        {!hideLabel && (
          <ElementTitle
            name={boldLabel ? <b>{label}</b> : label}
            star={star}
            helpTooltip={helpTooltip}
            labelInRow={labelInRow}
            labelClassName={labelClassName}
            bottomMargin={bottomMargin}
          />
        )}
        {type === 'dateRange' && (
          <DateRange
            {...(props as IOtherSelects)}
            type="dateRange"
            containerRef={selectContainerRef}
          />
        )}
        {type === 'date' && (
          <Date
            {...(props as IDate)}
            type="date"
            containerRef={selectContainerRef}
          />
        )}
        {type === 'time' && (
          <Time
            {...(props as ITime)}
            type="time"
            setAsyncLoading={setAsyncLoading}
            containerRef={selectContainerRef}
          />
        )}
        {type === 'select' && (
          <SelectComponent
            {...(props as ISelectComponent)}
            type="select"
            containerRef={selectContainerRef}
            setAsyncLoading={setAsyncLoading}
          />
        )}
        {type === 'table' && (
          <TableSelect
            {...(props as ITableSelect)}
            containerRef={selectContainerRef}
          />
        )}
        {type === 'input' && (
          <InputComponent {...(props as IInput)} type="input" />
        )}
        {type === 'area' && (
          <AreaComponent {...(props as IOtherSelects)} type="area" />
        )}
        {type === 'auto-complete' && (
          <AutoComplete
            {...(props as IAutoComplete)}
            type="auto-complete"
            containerRef={selectContainerRef}
          />
        )}
        {type === 'color-picker' && (
          <ColorPicker {...(props as IColoPicker)} type="color-picker" />
        )}
        {type === 'number' && (
          <Number {...(props as IInputNumber)} type="number" />
        )}
        {type === 'time-picker' && (
          <TimePicker {...(props as ITimePicker)} type="time-picker" />
        )}
        <IssueMessage message={issueMessage} />
      </Container>
    );
  };
  if (tooltip) {
    return (
      <Tooltip title={tooltip} mouseEnterDelay={0.5}>
        {Main()}
      </Tooltip>
    );
  }
  return Main();
};

Component.defaultProps = {
  star: true,
  type: 'select',
  bottomMargin: true,
  onChange: () => {},
  format: 'MM/DD/YYYY',
  selectProps: {},
  loaderProps: {},
  inputProps: {},
  className: '',
  onSearch: () => {},
  hasError: false
};
export default React.memo(Component);

import hexToRgba from 'hex-to-rgba';
import React, { FC } from 'react';
import { defaultCalendarOpacityLevel } from 'src/Activities/Schedule/TopBar/FilterBar/OpacityLevel';
import { defaultApptBackgroundColor } from 'src/App/UserPreferences/store';
import { useAppSelector } from 'src/store';
import { DefaultItemWidth } from '../../Main_Styles';
import { ScheduleGroupBackground } from './styled';

interface IProps {
  groupTitle: string;
  itemHeight: number;
  index: number;
  heightDivider: number;
  color?: string | null;
  isReservation: boolean;
  groupUIOffset: number;
}

const Component: FC<IProps> = ({
  itemHeight,
  index,
  groupTitle,
  heightDivider,
  color,
  isReservation,
  groupUIOffset
}: IProps) => {
  const calendarOpacityLevel = useAppSelector(
    (state) =>
      state.user.preferences.calendarOpacityLevel?.parsedValue ||
      defaultCalendarOpacityLevel
  );
  const groupStyle: React.CSSProperties = {
    position: `absolute`,
    height: `${itemHeight * heightDivider}px`,
    background: isReservation
      ? hexToRgba(
          color ?? defaultApptBackgroundColor,
          calendarOpacityLevel.value ?? 0.2
        )
      : 'none',
    top: itemHeight * groupUIOffset + itemHeight * index,
    left: 0,
    width: `${DefaultItemWidth - 13}px`,
    zIndex: isReservation ? 0 : 1
  };
  if (groupTitle == 'Block') {
    groupStyle.background = '#F7F7F7';
    groupStyle.cursor = 'no-drop';
    groupStyle.borderLeft = 'none';
  }
  return (
    <ScheduleGroupBackground
      key={index}
      style={{
        ...groupStyle
      }}
    />
  );
};

export default React.memo(Component);

import React, { FC, useState } from 'react';
import { Row, Col } from 'antd';

import Icon from 'src/Framework/Controls/Icon';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { IFileSimpleDto } from 'src/Framework/Controls/FileUploader';
import { handleDownload } from 'src/Framework/Controls/FilesDownloader';

import { FileContainer, Link, AttachmentContainer } from './styled';

interface IProps {
  file: IFileSimpleDto;
  isLast?: boolean;
  buttonRef?: React.MutableRefObject<any>;
}

const Component: FC<IProps> = ({ file, isLast, buttonRef }: IProps) => {
  const [loading, setLoading] = useState(false);
  const onKeyDownAccessibility = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab' && !e.shiftKey && isLast) {
      e.preventDefault();
      e.stopPropagation();
      if (buttonRef?.current) {
        //@ts-ignore
        buttonRef.current.focus();
      }
    }
    if (e.key === 'Tab' && e.shiftKey) {
      e.stopPropagation();
    }
    if (
      e.key === 'Enter' ||
      (e.code === 'Space' && (e.target as HTMLElement).tagName === 'DIV')
    ) {
      if (e.currentTarget) {
        e.preventDefault();
        e.stopPropagation();
        // @ts-ignore
        e.currentTarget.click();
      }
    }
  };
  return (
    <AttachmentContainer
      onClick={async () => {
        setLoading(true);
        await handleDownload(file);
        setLoading(false);
      }}
      tabIndex={0}
      role="link"
      onKeyDown={onKeyDownAccessibility}
    >
      {loading && <AbsoluteLoader />}
      <Row align="middle" gutter={8} style={{ flexWrap: 'nowrap' }}>
        <Col>
          <FileContainer>
            <Icon name="attachment" size={12} />
          </FileContainer>
        </Col>
        <Col>
          <span className="visually-hidden">link to attachment file:</span>
          <Link>{file.originalFileName || file.fileName}</Link>
        </Col>
      </Row>
    </AttachmentContainer>
  );
};

export default Component;

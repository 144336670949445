import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  background-color: white;
  padding: 0 24px;
  flex-basis: 352px;
  flex-shrink: 0;
  flex-grow: 1;
`;

export const PopupHeader = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-top: solid ${globalStyleVariables.newBrandingPrimary200} 4px;

  &.handleDrag {
    cursor: grab;
  }
`;

export const PatientHistoryContainer = styled.div`
  margin: 0 20px;
`;

export const PopupTitle = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-basis: 488px;
  flex-grow: 1;
  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 40px;
  padding-top: 24px;
  color: #000000;

  svg > circle {
    fill: rgb(230, 230, 230, 0.3);
  }
`;

export const CancelPopupContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  top: 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const CancelContentBlock = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

export const CancelConfirmBlock = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  align-items: center;
  border-top: 1px solid #e6e6e6;
`;
export const AppointmentRemovalBlock = styled.div`
  height: 100%;
  width: 50%;
  border-right: 1px solid #e6e6e6;
  padding: 24px;
  display: flex;
  flex-direction: column;
`;
export const AppointmentInfoBlock = styled.div`
  width: 50%;
`;
export const HighRiskContainer = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */

  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffeaea;
  border-radius: 2px;
  width: 58px;
  height: 20px;
  color: #ff2828;
`;

export const AppointmentRemovalTitle = styled.span`
  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 25px;
  line-height: 28px;
  /* or 200% */

  text-transform: uppercase;

  /* Black / 60% */

  color: #666666;
`;
export const MessageBlock = styled.span`
  color: red;
`;

export const DeleteIconContainer = styled.div`
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: rgb(230, 230, 230, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  svg > path {
    stroke: ${(props: { active: any }) =>
      props.active === 'true' ? 'rgb(255, 40, 40)' : '#cccccc'};
  }
`;

export const PatientIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 304px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: rgb(230, 230, 230, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const PatientChartIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 208px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: rgb(230, 230, 230, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const PatientFormsIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 256px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: rgb(230, 230, 230, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditIconContainer = styled.div`
  cursor: pointer;

  svg > path {
    fill: ${(props: { showRClickPopup: any }) =>
      props.showRClickPopup === 'Edit'
        ? globalStyleVariables.newBrandingPrimary200
        : '#f7f7f7'};
  }
`;
export const CreateNoteIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 160px;
  cursor: pointer;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: rgb(230, 230, 230, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClosePopupContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 16px;
  cursor: pointer;

  svg > path {
    stroke: ${globalStyleVariables.newBrandingPrimary200};
  }
`;

export const PopupBody = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AgeContainer = styled.div`
  display: inline-block;
  width: 76px;
`;

export const SexContainer = styled.div`
  display: inline-block;
  width: 104px;
`;

export const AccountNumberContainer = styled.div`
  display: inline-block;
  width: 130px;
`;
export const PronounsContainer = styled.div`
  display: inline-block;
  width: auto;
  margin-right: 16px;
`;

export const BlockSelfCheckIn = styled.div`
  display: inline-block;
  width: auto;
`;

export const PreferedPhoneContainer = styled.div`
  display: inline-block;
  //width: 180px;
  margin-bottom: 2rem;
`;

export const InsuranceContainer = styled.div`
  display: inline-block;
  width: 110px;
`;

export const OtherIDContainer = styled.div`
  display: inline-block;
  width: 164px;
`;

export const TimeSpan = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  color: #333333;
  word-break: break-all;
`;

export const ProviderSpan = styled.span`
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 16px;
  display: flex;
  height: 25px;
  width: fit-content;

  svg {
    width: 25px;
    height: 21px;
  }

  background: #f7f7f7;
  border-radius: 4px;
`;

export const EligibilityContainer = styled.div`
  display: inline-block;
`;

export const SvgDescContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 80px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

export const PopupContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;

  .text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
export const SvgContainer = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;

  &.active {
    background: ${globalStyleVariables.newBrandingPrimary200};
  }
`;

export const ExtendIconContainer = styled.div`
  position: absolute;
  top: ${(props: { top?: number }) => (props.top ? props.top + 'px' : '10px')};
  right: 16px;
  cursor: pointer;
`;
export const HistoryColumnTitle = styled.div`
  width: 140px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  cursor: default;
`;

export const NoteContainer = styled.div`
  padding-top: 1.2rem;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
`;
export const HistoryColumnItem = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* identical to box height, or 18px */
  display: block;
  align-items: center;
  width: 140px;
  padding: 0 10px 0 0;
  /* Black / 80% */
  color: #333333;
  cursor: default;
`;

export const MainBody = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

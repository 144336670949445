const ids = {
  editOutreachModal: 'edit_outreach_modal',
  outreachTypeAnonymous: 'outreach_type_anonymous',
  outreachTypeRegistered: 'outreach_type_registered',
  defineGuestsDialog: 'outreach_define_guests_dialog',
  outreachRegistrants: 'outreach-registrants',
  outreachNotesPopup: 'outreach-notes-popup',
  outreachRegistrationAttendancePopup: 'outreach-registration-attendance-popup'
};

export default ids;

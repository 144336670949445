import store from 'src/store';
import { IPermissionResult } from 'src/App/Admin/permissions';
import { IMedicatSystemPrefs } from './store/types';
import moment from 'moment';

type Key = keyof IMedicatSystemPrefs

export let serverTimeOffset: number | null = null

export const setServerTimeOffset = (date: string) => {
  serverTimeOffset = moment.parseZone(date).utcOffset()
}

export const getServerOffsetDate = (date?: string) => {
  const currentDate = date || new Date().toUTCString()
  if (serverTimeOffset) {
    const d = moment(currentDate)
    const str = moment(d.add(moment.duration(serverTimeOffset / 60, 'hours')).utc(false), moment.ISO_8601).toISOString()
    return str.substring(0, str.length - 1)
  }
  return currentDate
}

export const checkIsOptionEnabled = <T extends Key>(optionName: T) => {
  const settingsData = store.getState().settings.data?.medicatSystemPrefs || {};
  if (settingsData.hasOwnProperty(optionName)) {
    return settingsData[optionName] as IMedicatSystemPrefs[T];
  }
  return false;
};

export const isRiskModuleEnabled = () => {
  const value = checkIsOptionEnabled('m1EnableRisks');
  return value
};

export const isPointOfCareEnabled = () => {
  const value = checkIsOptionEnabled('hidePointOfCare');
  return !value
};

export const isLabsEnabled = () => {
  const value = checkIsOptionEnabled('hideLabs');
  return !value
};

export const checkRisksAvailability = (): IPermissionResult => {
  if (!isRiskModuleEnabled()) {
    return {
      success: false,
      accessMessage: 'Risks module is hidden in Medicat Demo'
    };
  }
  return {
    success: true,
    accessMessage: ''
  };
};

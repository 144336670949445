import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    width: fit-content;
`


export const RemoveIcon = styled.div`
  position: absolute;
  top: -2px;
  right: -5px;
  cursor: pointer;
  z-index: 2;
`;
import React, { FC, useState, ReactChild, useCallback } from 'react';

import Popover from '../Popover';
import Item from '../AttachmentsPopoverButton/Item';

interface IProps {
  item: any;
  children: ReactChild;
  patientId?: string | number;
  traineeId?: string | number;
}

const FileViewerPopover: FC<IProps> = ({
  item,
  children,
  patientId,
  traineeId
}: IProps) => {
  const [open, setOpen] = useState(false);
  const closePopup = useCallback(() => {
    setOpen(false);
  }, []);
  return (
    <Popover
      id=""
      open={open}
      removePaddings={true}
      destroyTooltipOnHide={false}
      onOpenChange={(open) => setOpen(open)}
      content={
        <Item
          item={item}
          patientId={patientId}
          traineeId={traineeId}
          closePopup={closePopup}
        />
      }
    >
      {children}
    </Popover>
  );
};

export default React.memo(FileViewerPopover);

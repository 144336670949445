import React from 'react';

interface IProps {
  fill?: string;
  stroke?: string;
}

export const AddIndividualApptSvg = ({ fill, stroke }: IProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.3" cx="16" cy="16" r="16" fill={fill || '#E6E6E6'} />
    <g transform="translate(8, 8)">
      <path
        d="M8.5 10.5H5.5C4.17392 10.5 2.90215 11.0268 1.96447 11.9645C1.02678 12.9021 0.5 14.1739 0.5 15.5H9.5"
        stroke="#3A5B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7.5C8.933 7.5 10.5 5.933 10.5 4C10.5 2.067 8.933 0.5 7 0.5C5.067 0.5 3.5 2.067 3.5 4C3.5 5.933 5.067 7.5 7 7.5Z"
        stroke="#3A5B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 10.5V14.5"
        stroke="#3A5B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 12.5H14.5"
        stroke="#3A5B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

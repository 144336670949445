import React, { FC, useState } from 'react';
import { Row, Col, AutoComplete, Input } from 'antd';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';

import useTags from 'src/App/Tags/Hooks/useTags';
import * as tagsActions from 'src/App/Tags/store/Actions';

import { Container, TagContainer } from './styled';

interface IProps {
  tags: number[];
  tagDefinitionId: number;
  onChange: (data: number[]) => Promise<any>;
  disableAddButton?: boolean;
}

const Component: FC<IProps> = ({
  tags,
  tagDefinitionId,
  onChange,
  disableAddButton
}: IProps) => {
  const { values: existedTags } = useTags({
    tagDefinitionId
  });
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [newTag, setNewTag] = useState(false);
  const onClickEnter = async (value: string) => {
    const newTag = value.trim();
    const listTags = Object.values(existedTags).filter((v) => v.isActive);
    const find = listTags.find((v) => v.value === newTag);
    if (find) {
      if (!tags.includes(find.id)) {
        onChange([...tags, find.id]);
      }
      return;
    }
    setLoading(true);
    const res = await tagsActions.postTagsValues(tagDefinitionId, newTag);
    if (res) {
      const tag = Object.values(res)[0];
      onChange([...tags, tag.id]);
      setNewTag(false);
    }
    setLoading(false);
  };
  const allOptions = Object.values(existedTags)
    .map((v) => ({
      value: v.id,
      label: v.value,
      isActive: v.isActive
    }))
    .filter((v) => !tags.includes(v.value))
    .filter((v) => v.label?.toLowerCase().includes(searchValue?.toLowerCase()))
    .sort((a, b) => a.label.localeCompare(b.label));
  return (
    <Container>
      <Row
        gutter={[8, 8]}
        role="list"
        tabIndex={tags && tags.length > 0 ? 0 : -1}
        onKeyDown={onKeyDownAccessibility}
      >
        {tags.map((id) => {
          const item = existedTags[id];
          if (!item) return null;
          return (
            <Col key={id} tabIndex={0} role="listitem" aria-label={item?.value}>
              <TagContainer>
                <Row align="top" gutter={8} wrap={false}>
                  <Col>{item?.value}</Col>
                  <Col>
                    <ButtonIcon
                      isButton={true}
                      label="remove item"
                      name={ICONS_LIST.thinClose}
                      size={20}
                      disableBackground={true}
                      onClick={() => {
                        const arr = [...tags];
                        const i = arr.indexOf(id);
                        arr.splice(i, 1);
                        onChange(arr);
                      }}
                    />
                  </Col>
                </Row>
              </TagContainer>
            </Col>
          );
        })}
        {!disableAddButton && (
          <Col>
            <ButtonIcon
              isButton={true}
              label="add new tag"
              disabled={newTag}
              name={ICONS_LIST.add}
              size={30}
              onClick={() => {
                setNewTag(true);
                setSearchValue('');
              }}
            />
          </Col>
        )}
      </Row>
      {newTag && (
        <Row align="middle" gutter={16} style={{ marginTop: 10 }}>
          <Col flex={1}>
            <AutoComplete
              value={searchValue}
              onChange={(searchString: string) => {}}
              options={allOptions.filter((v) => v.isActive)}
              style={{ width: '100%' }}
              onSelect={(value: any) => {
                if (!tags.includes(+value)) {
                  onChange([...tags, +value]);
                }
                setNewTag(false);
              }}
              onSearch={(value: string) => {
                setSearchValue(value);
              }}
              placeholder=""
              dropdownStyle={{
                zIndex: 10000
              }}
            >
              <Input
                placeholder="Add new tag"
                suffix={
                  <ButtonIcon
                    isButton={true}
                    label="create new tag"
                    size={26}
                    loading={loading}
                    disabled={searchValue.length === 0}
                    name={ICONS_LIST.add}
                    onClick={() => {
                      onClickEnter(searchValue);
                    }}
                  />
                }
              />
            </AutoComplete>
          </Col>
          <Col>
            <ButtonIcon
              isButton={true}
              label="cancel adding a tag"
              iconScale={2.3}
              name={ICONS_LIST.thinClose}
              onClick={() => setNewTag(false)}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default React.memo(Component);

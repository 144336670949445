import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import _ from 'lodash';

import { ClinicSelector } from 'src/Framework/Controls/Selectors';
import { getClinicById } from 'src/Framework/Controls/Selectors/ClinicSelector';
import ProviderSelector from 'src/Framework/Controls/Selectors/ProviderSelector';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import ReasonSelector from 'src/Framework/Controls/Selectors/ReasonSelector';
import { getReasonByID } from 'src/Framework/Controls/Selectors/ReasonSelector/utils';
import ColorSelector from 'src/Framework/Controls/Selectors/ColorSelector';
import {
  getProviderByCode,
  getProviderById,
  providerDisplayName
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import ConditionalButton from 'src/Framework/Controls/ConditionalButton';
import { useAppSelector } from 'src/store';
import test_ids from 'src/tests-script/pages/Schedule/Event/addEdit';

import SeriesEditor from 'src/Activities/Schedule/Common/Editors/SeriesEditor';
import SchedulingContainer from 'src/Activities/Schedule/Common/Editors/SchedulingEditor';
import {
  getDateTimeSelection,
  getEndTime,
  getProviderSelection,
  validateStartTimeRule
} from 'src/Activities/Schedule/Popups/AppointmentPopup/utils';
import { eventName } from 'src/Activities/Schedule/utils';
import {
  findOpt,
  getTypeFrequencyNumber,
  mapClinic
} from 'src/Activities/Schedule/Utils/Utils';
import {
  IAppointmentSeries,
  IProviderEvent
} from 'src/Activities/Schedule/Store/Events/types';

import { PopupContent } from 'src/Activities/Schedule/Common/Classes';
import { Content, Block, BlockHeader } from './styled';
import { RecurrenceDisplay } from '../../Common/Editors/RecurrenceDisplay';

interface IProps {
  eventData: Partial<IProviderEvent>;
  onChangeEventData: (data: Partial<IProviderEvent>) => void;
  isSeriesOpened: boolean;
  setSeriesOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isScheduling: boolean;
  setScheduling: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultColor = '#CCCCCC';
const defaultSeries: IAppointmentSeries = {
  occurrences: 0,
  typeFrequencyId: 0,
  dayOfMonthFilter: '',
  dayOfWeekFilter: ''
};

const Component: FC<IProps> = ({
  eventData,
  onChangeEventData,
  setSeriesOpened,
  isSeriesOpened,
  isScheduling,
  setScheduling
}) => {
  const clinics = useAppSelector((state) => state.scheduleDetails.clinics);
  const providers = useAppSelector(
    (state) => state.schedule.clinicProviders.data
  );

  // // const [seriesData, setSeriesData] =
  //   useState<IAppointmentSeries>(defaultSeries);
  const clinicOpts = useMemo(() => clinics.map((c) => mapClinic(c)), [clinics]);
  const selectedClinic = useMemo(
    () => findOpt(clinicOpts, getClinicById(eventData.clinicId)?.code),
    [eventData.clinicId, clinicOpts]
  );

  const dateTimeSelection = useMemo(
    () =>
      getDateTimeSelection(selectedClinic, {
        scheduleEvent: {
          scheduleEventDateTime: eventData?.startTime!.replace('Z', ''),
          endTime: eventData.endTime || null
        }
      }),
    [
      eventData.clinicId,
      eventData.startDate,
      eventData.startTime,
      eventData.endTime
    ]
  );

  const disableDateTimeSelect = useMemo(
    () =>
      (eventData.providerIds && eventData.providerIds.length === 0) ||
      !eventData.clinicId ||
      !eventData.appointmentReasonId,
    [eventData]
  );

  const selectedReason = useMemo(
    () => getReasonByID(eventData?.appointmentReasonId),
    [eventData.appointmentReasonId]
  );

  const isInPast = useMemo(() => {
    if (eventData?.startDate) {
      return moment(eventData?.startDate?.slice(0, 10), 'YYYY-MM-DD').isBefore(
        moment().startOf('day')
      );
    }
    return true;
  }, [eventData.startDate]);

  const providerCodes = useMemo(
    () =>
      eventData.providerIds?.map((item) => {
        const provider = getProviderById(item);
        return {
          id: item,
          code: provider?.code,
          page: 1,
          name: providerDisplayName(item)
        };
      }),
    [eventData.providerIds]
  );

  const providerSelection = useMemo(
    () => getProviderSelection(providers, selectedClinic, providerCodes),
    [eventData.providerIds, providers]
  );

  const eventHasArrived = useMemo(() => {
    if (eventData.action === 'edit') {
      return eventData?.eventMembers?.some((item) => item.status === 2);
    }
    return false;
  }, [eventData?.eventMembers]);

  const arrivedMembers = useMemo(() => {
    if (eventData.action === 'edit') {
      return (
        eventData?.eventMembers
          ?.filter((item) => item.status === 2)
          .map((item) => item.providerId) || []
      );
    }
    return [];
  }, [eventData?.eventMembers]);

  let { startTimeOptions, endTimeOptions } = dateTimeSelection;

  const onFinishEditingSeries = () => {
    // setSeriesData(_.cloneDeep(eventData.appointmentSeries) || defaultSeries);
    setSeriesOpened(false);
  };

  const onScheduledEvent = (option: any) => {
    let newEventPayload = _.cloneDeep(eventData);
    const providerIds = option.PROVIDER.map(
      (item: string) => getProviderByCode(item)?.providerId
    );
    newEventPayload.endTime = option.Event.endTime;
    newEventPayload.startTime = option.Event.startTime;
    newEventPayload.startDate =
      option.Event.startTime.slice(0, 11) + '00:00:00';
    newEventPayload.providerIds = providerIds;
    onChangeEventData(newEventPayload);
    setScheduling(false);
  };

  const onUpdateSeries = (data: any) => {
    const newSeriesData = data.scheduleEvent.series;
    const tempSeries = {
      ...newSeriesData,
      startDate: newSeriesData.startDate.slice(0, 10),
      endDate: newSeriesData.endDate,
      startTime: newSeriesData.startTime,
      endTime: newSeriesData.endTime.slice(0, 19)
    };
    const tempConflictsResponses = data.conflictsResponses.map((item: any) => ({
      ...item,
      endTime: item.endTime.slice(0, 19),
      scheduleEventDateTime: item.scheduleEventDateTime.slice(0, 19)
    }));

    onChangeEventData({
      startTime: tempSeries.startTime.slice(0, 19),
      endTime: tempSeries.endTime,
      conflictsResponses: tempConflictsResponses,
      appointmentSeries: {
        ...tempSeries
      }
    });
    setSeriesOpened(false);
  };

  const onReasonChange = (selectedReason: any, selectedClinic: any) => {
    const newEvent = {
      endTime: eventData.endTime ?? null,
      startTime: eventData.startTime,
      color: eventData.color,
      appointmentReasonId: eventData.appointmentReasonId,
      scheduleEventDateTime: eventData.startTime,
      conflictsResponses: undefined
    };
    const onScheduleReasonChange = (
      selectedReason: any,
      selectedClinic: any,
      newEvent: any
    ) => {
      if (selectedReason) {
        newEvent.color = selectedReason.color || defaultColor;
        newEvent.appointmentReasonId = selectedReason.id;
        newEvent.scheduleEventDateTime = validateStartTimeRule(
          selectedClinic,
          newEvent.scheduleEventDateTime.replace('Z', '')
        );
        const tempEndTime = getEndTime(
          selectedClinic,
          newEvent.endTime,
          newEvent.scheduleEventDateTime,
          selectedReason.duration
        ).slice(0, 19);
        newEvent.endTime = tempEndTime;
      }
    };

    onScheduleReasonChange(selectedReason, selectedClinic, newEvent);
    delete newEvent.scheduleEventDateTime;
    onChangeEventData({ ...newEvent });
  };
  const isRecurring =
    !!eventData?.appointmentSeries &&
    !!eventData?.appointmentSeries?.typeFrequencyId &&
    eventData?.appointmentSeries?.typeFrequencyId !== 0;
  return (
    <PopupContent>
      <Content>
        {!isSeriesOpened && (
          <>
            <Block bottomBorder={true}>
              <BlockHeader>{eventName} info</BlockHeader>
              <Row gutter={24}>
                <Col span={8}>
                  <SelectContainer
                    type="input"
                    star={true}
                    label={`${eventName} name`}
                    inputProps={{
                      id: test_ids.nameInput,
                      onKeyDown: (e) => {
                        e.stopPropagation();
                      }
                    }}
                    value={eventData.name}
                    onChange={(name) => onChangeEventData({ name })}
                    bottomMargin={false}
                  />
                </Col>
                <Col span={8}>
                  <ClinicSelector
                    multiple={false}
                    value={eventData.clinicId}
                    onChange={(clinicId) => {
                      onChangeEventData({
                        clinicId: +clinicId,
                        providerIds: [],
                        appointmentReasonId: null,
                        conflictsResponses: undefined
                      });
                    }}
                    mandatory={true}
                    selectProps={{
                      id: test_ids.clinicSelect,
                      label: 'Clinic',
                      inputProps: {
                        allowClear: false,
                        disabled: eventHasArrived
                      },
                      bottomMargin: false
                    }}
                  />
                </Col>
                <Col span={8}>
                  <ProviderSelector
                    enableFilters={true}
                    multiple={true}
                    roleFilter="appointment"
                    filterByClinics={[eventData.clinicId!]}
                    value={eventData.providerIds!}
                    onChange={(values: string[], e: any) => {
                      if (
                        !arrivedMembers.every((item) =>
                          values.includes(item + '')
                        )
                      ) {
                        return;
                      }
                      onChangeEventData({
                        providerIds: values.map((item) => +item),
                        conflictsResponses: undefined
                      });
                    }}
                    selectProps={{
                      id: test_ids.providerSelect,
                      star: true,
                      bottomMargin: false
                    }}
                  />
                </Col>
              </Row>
            </Block>
            <Block bottomBorder={true}>
              <BlockHeader>{eventName} type</BlockHeader>
              <Row gutter={24} wrap={false}>
                <Col flex="1 0 0">
                  <ReasonSelector
                    clinicCodes={
                      eventData.clinicId
                        ? [getClinicById(eventData.clinicId)?.code!]
                        : []
                    }
                    name="Type"
                    reasonTypes={['event']}
                    useCode={false}
                    value={eventData.appointmentReasonId}
                    onChange={(reasinId: number) => {
                      const selectedReason = getReasonByID(reasinId);
                      onReasonChange(selectedReason, selectedClinic);
                    }}
                    selectProps={{
                      inputProps: {
                        disabled: eventHasArrived
                      },
                      star: true,
                      id: test_ids.reasonSelect
                    }}
                  />
                </Col>
                <Col>
                  <ColorSelector
                    value={eventData.color}
                    onChange={(color: string) =>
                      onChangeEventData({ color: color.toUpperCase() })
                    }
                    selectProps={{
                      inputProps: {
                        disabled: eventHasArrived
                      },
                      id: test_ids.colorSelect,
                      star: false,
                      bottomMargin: false,
                      label: 'Type Color'
                    }}
                  />
                </Col>
              </Row>
            </Block>

            <Block>
              <Row align="top">
                <BlockHeader>{eventName} time</BlockHeader>
                <ConditionalButton
                  id={test_ids.schedulingButton}
                  iconName="emptyCalendar"
                  text="Scheduling"
                  onClick={() => {
                    setScheduling(true);
                  }}
                  isFilled={false}
                  isDisabled={disableDateTimeSelect}
                />
                {(eventData.updateSeries ||
                  !(eventData.action && eventData.action === 'edit')) && (
                  <ConditionalButton
                    tooltip={
                      isInPast
                        ? 'You can not create a series of Staff Appointments from the past'
                        : undefined
                    }
                    id={test_ids.recurringButton}
                    iconName="recurrence"
                    text="Recurring"
                    onClick={() => {
                      setSeriesOpened(true);
                    }}
                    isFilled={isRecurring}
                    isDisabled={disableDateTimeSelect || isInPast}
                  />
                )}
              </Row>
              {isRecurring && (
                <div
                  onClick={() => {
                    if (disableDateTimeSelect) return;
                    if (
                      eventData.updateSeries ||
                      !(eventData.action && eventData.action === 'edit')
                    ) {
                      setSeriesOpened(true);
                    }
                  }}
                >
                  {RecurrenceDisplay({
                    ...eventData.appointmentSeries,
                    startTime: eventData.startTime,
                    endTime: eventData.endTime
                  })}
                </div>
              )}
              {!eventData.updateSeries && (
                <Row gutter={24} style={{ height: 76 }}>
                  <Col span={5}>
                    <SelectContainer
                      id={test_ids.startDate}
                      type="date"
                      label="Start Date"
                      value={eventData.startDate}
                      onChange={(value: string) => {
                        onChangeEventData({
                          conflictsResponses: undefined,
                          startDate: moment(value, 'MM/DD/YYYY')
                            .startOf('day')
                            .utc(true)
                            .toISOString()
                            .slice(0, 19),
                          startTime: moment(value, 'MM/DD/YYYY')
                            .startOf('day')
                            .utc(true)
                            .hours(moment(eventData.startTime).hours())
                            .minutes(moment(eventData.startTime).minutes())
                            .toISOString()
                            .slice(0, 19),
                          endTime: eventData.endTime
                            ? moment(value, 'MM/DD/YYYY')
                                .startOf('day')
                                .utc(true)
                                .hours(moment(eventData.endTime).hours())
                                .minutes(moment(eventData.endTime).minutes())
                                .toISOString()
                                .slice(0, 19)
                            : null
                        });
                      }}
                      bottomMargin={true}
                      inputProps={{
                        allowClear: false
                      }}
                    />
                  </Col>
                  <Col span={5}>
                    <SelectContainer
                      type="select"
                      label="Start Time"
                      avoidOptionsSorting={true}
                      id={test_ids.startTime}
                      value={
                        eventData.startTime
                          ? moment(eventData.startTime).format('HH:mm')
                          : null
                      }
                      options={startTimeOptions}
                      onChange={(value: string) => {
                        onChangeEventData({
                          startTime:
                            eventData.startDate?.slice(0, 11) + value + ':00',
                          endTime: !!getReasonByID(
                            eventData.appointmentReasonId
                          )
                            ? moment(eventData.startDate?.slice(0, 11) + value)
                                .utc(true)
                                .add(
                                  +getReasonByID(eventData.appointmentReasonId)
                                    ?.duration!,
                                  'm'
                                )
                                .toISOString()
                                .slice(0, 19)
                            : eventData.endTime,
                          conflictsResponses: undefined
                        });
                      }}
                      bottomMargin={true}
                      inputProps={{
                        allowClear: false,
                        placeholder: '--',
                        disabled: eventHasArrived
                      }}
                    />
                  </Col>
                  <Col span={5}>
                    <SelectContainer
                      id={test_ids.endTime}
                      type="select"
                      label="End Time"
                      avoidOptionsSorting={true}
                      value={
                        eventData.endTime
                          ? moment(eventData.endTime).format('HH:mm')
                          : null
                      }
                      options={endTimeOptions}
                      onChange={(value: string) => {
                        onChangeEventData({
                          endTime:
                            eventData.startDate?.slice(0, 11) + value + ':00',
                          conflictsResponses: undefined
                        });
                      }}
                      bottomMargin={true}
                      inputProps={{
                        allowClear: false,
                        placeholder: '--',
                        disabled: eventHasArrived
                      }}
                    />
                  </Col>
                  <Col span={9}></Col>
                </Row>
              )}

              <Row>
                <SelectContainer
                  id={test_ids.noteArea}
                  label={'Comment'}
                  star={false}
                  type="area"
                  value={eventData.note}
                  onChange={(note: string) =>
                    onChangeEventData({
                      note
                    })
                  }
                  bottomMargin={false}
                  areaAutoReplace={true}
                  inputProps={{
                    maxLength: 4000,
                    placeholder: 'Write a comment here'
                  }}
                />
              </Row>
            </Block>
          </>
        )}
        {isSeriesOpened && (
          <SeriesEditor
            type="event"
            onSubmit={onFinishEditingSeries}
            onCancel={onFinishEditingSeries}
            top={'88px'}
            originalPayload={{
              ...eventData,
              providerCodes: providerCodes,
              scheduleEvent: {
                scheduleEventTypeId: 7,
                clinicCode: getClinicById(eventData.clinicId)?.code,
                scheduleEventDateTime: eventData.startTime,
                series: {
                  ...defaultSeries,
                  ...eventData.appointmentSeries,
                  endTime: eventData.endTime,
                  startTime: eventData.startTime,
                  startDate: eventData.startDate,
                  endDate: eventData.appointmentSeries?.endDate,
                  typeFrequencyId: getTypeFrequencyNumber(
                    eventData.appointmentSeries?.typeFrequencyId as string
                  )
                }
              }
            }}
            providers={providers}
            clinics={clinics}
            onSeriesUpdated={onUpdateSeries}
          />
        )}
        {isScheduling && (
          <SchedulingContainer
            newEventPayload={{
              scheduleEvent: {
                ...eventData,
                clinicCode: selectedClinic.code,
                scheduleEventDateTime: eventData.startTime,
                reason: {
                  id: eventData.appointmentReasonId,
                  duration: selectedReason?.duration
                }
              },
              providerCodes
            }}
            enableMulti={false}
            onSaveSelection={(event: any) => {
              onScheduledEvent(event);
            }}
            onCancelSelection={() => setScheduling(false)}
            selectedClinic={selectedClinic}
            clinics={clinics}
            providers={providerSelection.selectedProviders}
          />
        )}
      </Content>
    </PopupContent>
  );
};

export default React.memo(Component);

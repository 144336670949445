import { useState, useEffect, useCallback } from 'react';
import { useAppSelector } from 'src/store';

import * as tagActions from '../store/Actions';

interface IProps {
  tagDefinitionId: number;
}

const loadingChecker: Record<string, boolean> = {};

const useTags = ({ tagDefinitionId }: IProps) => {
  const values = useAppSelector(
    (state) => state.tags.values[tagDefinitionId] || {}
  );
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    if (loadingChecker[tagDefinitionId]) return;
    setLoading(true);
    loadingChecker[tagDefinitionId] = true;
    await tagActions.getTagsValues(tagDefinitionId);
    loadingChecker[tagDefinitionId] = false;
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  const findById = useCallback(
    (id: number | null | undefined | string) => {
      if (id) {
        const item = values[id];
        if (item?.isActive) {
          return item;
        }
      }
      return null;
    },
    [values]
  );

  return {
    loading,
    values,
    findById,
    searchByIds: (search: string, tags: number[]) => {
      const strings = tags.map((v) => values[v]?.value || '');
      const find = strings.find((v) =>
        v.toLowerCase().includes(search.toLowerCase())
      );
      return find ? true : false;
    }
  };
};

export default useTags;

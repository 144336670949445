import _ from 'lodash'

import store from 'src/store';
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { IControls } from "src/App/Admin/Pages/NoteTypes/store/types";
import { getNotesV2 } from '../../../Notes/EditNote/Store/NoteContentActions';

import { NoteDetailsPlanControlContentEntry, ValidationsStatuses } from "../types";
import { constructDetailsRequest } from "../utils";
import { actionsTypes } from '.'

import { NoteFullDto, NoteDistributeResult, NoteSimpleDto, INoteAppend } from './types'

import * as notesActions from '../../../Notes/NoteList/Store/PatientNoteListActions'
import { ISort } from 'src/Framework/Controls/Table/types';

export const initializePlanEntry = async ({ noteId, txnCodeId }: { noteId: string | number, txnCodeId: string | number }): Promise<NoteDetailsPlanControlContentEntry | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            `${ApiUrl.GET_NOTESV2}/${noteId}/initializePlanEntry`,
            {
                withCredentials: true, params: {
                    txnCodeId
                }
            }
        )
        if (response.data.success) {
            return response.data.result
        }
        return
    } catch (e) {
        return
    }
}

export const noteV4Preview = async (noteId: string | number): Promise<any | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            `${ApiUrl.NOTES_V4}/${noteId}/preview`,
            { withCredentials: true }
        )
        if (response.data.success) {
            return Object.values(response.data.result.componentData)
        }
        return
    } catch (e) {
        return
    }
}

export const noteV4Publish = async (noteId: string | number): Promise<boolean | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            `${ApiUrl.NOTES_V4}/${noteId}/sync`, {},
            { withCredentials: true }
        )
        if (response.data.success) {
            return true
        }
        return
    } catch (e) {
        return
    }
}

export const noteV4Details = async (noteId: string | number) => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            `${ApiUrl.NOTES_V4}/${noteId}/details`,
            { withCredentials: true }
        )
        if (response.data.success) {
            return response.data.result.details
        }
    } catch (e) {
        return
    }
}

export const putNoteV4Details = async (noteId: string | number, control: IControls, details: any) => {
    try {
        const detailsRequest = constructDetailsRequest(control, details)
        const response = await APIHandler.AxiosInstance.put(
            `${ApiUrl.NOTES_V4}/${noteId}/details`,
            {
                details: detailsRequest
            },
            { withCredentials: true }
        )
        if (response.data.success) {
            return response.data.result
        }
    } catch (e) {
        return
    }
}

export const attachAppointmentToNote = (subjectId: string, includeCancellation = false) => {
    return APIHandler.AxiosInstance.post(
        ApiUrl.GET_APPOINTMENT_HX,
        {
            subjectId: subjectId,
            includeCancellation: includeCancellation,
            onlyAdmitted: true,
            includeFuture: false
        },
        { withCredentials: true }
    )
}


export const setApptEvent = (payload: any, patientId: any, noteId: any) => {
    store.dispatch({
        type: actionsTypes.SET_APPT_EVENT,
        payload,
        patientId,
        noteId
    })
}

const setAppends = (payload: any, patientId: number | string, noteId: number | string) => {
    store.dispatch({
        type: actionsTypes.SET_APPENDS,
        payload,
        patientId,
        noteId
    })
}

const patchAppend = (payload: INoteAppend, patientId: number | string, noteId: number | string) => {
    const appends = store.getState().client.noteDetails[patientId]?.[noteId]?.appends || {}
    appends[payload.id] = payload
    store.dispatch({
        type: actionsTypes.SET_APPENDS,
        payload: appends,
        patientId,
        noteId
    })
}

export const postSoapNoteAppend = async (patientId: number | string, {
    noteId,
    appendText
}: {
    noteId: number
    appendText: string
}): Promise<INoteAppend | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(ApiUrl.SOAP_NOTE_APPEND, {
            noteId,
            appendText
        }, {
            withCredentials: true
        })
        if (response.data.success) {
            const data: any = Object.values(response.data.result.summary)
            data.forEach((v: any) => {
                patchAppend(v, patientId, noteId)
            })
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    }
}

export const getSoapNoteAppend = async (noteId: number, patientId: number | string): Promise<INoteAppend | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.SOAP_NOTE_APPEND, {
            withCredentials: true,
            params: {
                noteId
            }
        })
        if (response.data.success) {
            const data: any = response.data.result.summary
            setAppends(data, patientId, noteId)
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    }
}

export const getApptEvent = async ({
    appointmentId, patientId, id
}: {
    appointmentId: string | number, patientId: string | number, id: string | number
}) => {
    try {
        const response = await APIHandler.AxiosInstance.post(ApiUrl.GET_SCHEDULE_EVENT, {
            appointmentId, subjectId: patientId
        }, {
            withCredentials: true,
        })
        if (response.data.success) {
            setApptEvent(response.data.result, patientId, id)
        }
    } catch (e) {
        console.log({ e })
    }
}

export const setNotesWithCurrentAppt = (payload: any, patientId: any, noteId: any) => {
    store.dispatch({
        type: actionsTypes.SET_NOTES_WITH_CURRENT_APPT,
        payload,
        patientId,
        noteId
    })
}

export const getNotesV2AttachedToAppt = async ({
    patientId,
    appointmentId
}: {
    patientId: number | string,
    appointmentId: number,
}): Promise<Record<string, NoteSimpleDto> | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            ApiUrl.GET_NOTESV2,
            {
                withCredentials: true, params: {
                    patientId,
                    appointmentId,
                    format: 'simple'
                }
            }
        );
        if (response.data.success) {
            const data = response.data.result.summary
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    }
}

export const getNotesV2Appt = async ({
    id,
    patientId,
    appointmentId
}: {
    id: number,
    patientId: number | string,
    appointmentId: number,
}) => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            ApiUrl.GET_NOTESV2,
            {
                withCredentials: true, params: {
                    patientId,
                    appointmentId,
                    format: 'simple'
                }
            }
        );
        if (response.data.success) {
            const data = response.data.result.summary
            delete data[id]
            setNotesWithCurrentAppt(data, patientId, id)
            return data
        }
    } catch (e) {
        console.log({ e })
    }
}

export const setValidationsStatus = (noteId: string, patientId: string, control: IControls, validations: ValidationsStatuses) => {
    store.dispatch({
        type: actionsTypes.SET_CONTROL_VALIDATION_STATUS,
        noteId,
        patientId,
        control,
        payload: validations
    })
}

export const postDistribution = async (body: any): Promise<NoteDistributeResult[] | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(`${ApiUrl.GET_NOTESV2}/distribute`, body, { withCredentials: true, interceptError: true })
        if (response.data) {
            if (response.data.success) {
                return response.data.result
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const saveNote = async (noteId: string, patientId: string, control: IControls, details: any) => {
    const detailsRequest = constructDetailsRequest(control, details)
    try {
        const response = await APIHandler.AxiosInstance.post(`${ApiUrl.GET_NOTESV2}/${noteId}/details`, { details: detailsRequest }, { withCredentials: true, interceptError: true })
        if (response.data) {
            if (response.data.success) {
                return response.data
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const patchNote = (patientId: string | number, noteId: string | number, payload: any) => {
    store.dispatch({
        type: actionsTypes.PATCH_NOTE,
        patientId,
        noteId,
        payload
    })
    notesActions.addNewNote({
        ...payload,
        id: noteId
    }, patientId)
}


export const setNote = (patientId: string | number, noteId: string | number, payload: any) => {
    store.dispatch({
        type: actionsTypes.SET_NOTE,
        patientId,
        noteId,
        payload
    })
    notesActions.addNewNote({
        ...payload,
        id: noteId
    }, patientId)
}

export const onSwitchAttachToNoteEnable = async (patientId: string | number, noteId: string | number, seedId: string | number) => {
    try {
        const response = await APIHandler.AxiosInstance.put(`${ApiUrl.GET_NOTESV2}/${noteId}/forms/${seedId}`, {}, { withCredentials: true, interceptError: true })
        if (response.data.success) {
            const note = _.cloneDeep(store.getState().client.noteDetails[patientId]?.[noteId]?.note)
            if (note) {
                const i = note.linkedFormSeedIds.findIndex((v) => v == seedId)
                if (i === -1) {
                    note.linkedFormSeedIds.push(+seedId)
                    setNote(patientId, noteId, note)
                }
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const onChangeNote = async (noteId: number | string, body: Partial<NoteFullDto>) => {
    try {
        const response = await APIHandler.AxiosInstance.patch(`${ApiUrl.GET_NOTESV2}/${noteId}`, body, {
            withCredentials: true, interceptError: true
        })
        if (response.data) {
            if (response.data.success) {
                const data = Object.values(response.data.result.summary)[0]
                return data
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const getCurrentNote = async (noteId: number | string, patientId: number | string) => {
    const response: any = await getNotesV2(noteId, patientId);
    if (response) {
        setNote(patientId, response.id, response);
    }
}

export const onSwitchAttachToNoteDisable = async (patientId: string | number, noteId: string | number, seedId: string | number) => {
    try {
        const response = await APIHandler.AxiosInstance.delete(`${ApiUrl.GET_NOTESV2}/${noteId}/forms/${seedId}`, { withCredentials: true, interceptError: true })
        if (response.data.success) {
            const note = _.cloneDeep(store.getState().client.noteDetails[patientId]?.[noteId]?.note)
            if (note) {
                const i = note.linkedFormSeedIds.findIndex((v) => v == seedId)
                if (i !== -1) {
                    note.linkedFormSeedIds.splice(i, 1)
                    setNote(patientId, noteId, note)
                }
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const onSwitchAttachToNoteM1Enable = async (patientId: string | number, noteId: string | number, id: string | number) => {
    try {
        const response = await APIHandler.AxiosInstance.put(`${ApiUrl.GET_NOTESV2}/${noteId}/m1forms/${id}`, {}, { withCredentials: true, interceptError: true })
        if (response.data.success) {
            const note = _.cloneDeep(store.getState().client.noteDetails[patientId]?.[noteId]?.note)
            if (note) {
                const i = note.linkedFormRecordIds.findIndex((v) => +v === +id)
                if (i === -1) {
                    note.linkedFormRecordIds.push(+id)
                    setNote(patientId, noteId, note)
                }
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const onSwitchAttachToNoteM1Disable = async (patientId: string | number, noteId: string | number, id: string | number) => {
    try {
        const response = await APIHandler.AxiosInstance.delete(`${ApiUrl.GET_NOTESV2}/${noteId}/m1forms/${id}`, { withCredentials: true, interceptError: true })
        if (response.data.success) {
            const note = _.cloneDeep(store.getState().client.noteDetails[patientId]?.[noteId]?.note)
            if (note) {
                const i = note.linkedFormRecordIds.findIndex((v) => +v === +id)
                if (i !== -1) {
                    note.linkedFormRecordIds.splice(i, 1)
                    setNote(patientId, noteId, note)
                }
            }
        }
        return;
    } catch (e) {
        return
    } finally {
    }
}

export const onChangeSort = (patientId: string | number, noteId: string | number, payload: Partial<ISort>) => {
    store.dispatch({
        type: actionsTypes.CHANGE_FORM_SORT,
        patientId,
        noteId,
        payload
    })
}

export const onChangeSelectedForm = (patientId: string | number, noteId: string | number, payload: any) => {
    store.dispatch({
        type: actionsTypes.ON_CHANGE_SELECTED_FORM,
        patientId,
        noteId,
        payload
    })
}
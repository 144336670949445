const ids = {
    CONTAINER: 'appointment-members-container',
    tableContainer: 'table-container',
    statuses: {
        late: 'late',
        arrived: 'arrived',
        ready: 'ready',
        admitted: 'admitted',
        discharged: 'discharged'
    },
    removeButton: 'remove-patient-button',
    patientLink: 'patient-link',
    createNote: 'create-note'
}
export default ids
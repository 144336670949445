const ids = {
  FOLLOW_UP_MODAL: 'follow_up_modal',
  FILTER_MODAL: 'filter_modal',
  MESSAGE_FORMS_MODAL: 'message_forms_modal',
  MESSAGE_ATTACHMENTS_MODAL: 'message_attachments_modal',
  FOLLOW_UP_TOOLTIP_LIST: 'follow_up_tooltip_list',
  STUDENTS_POPUP: 'students_popup',
  CREATE_INTERNAL_MESSAGE_BUTTON: 'create_internal_message_button',
  INTERNAL_MESSAGE_TO: 'internal_message_to',
  INTERNAL_MESSAGE_PERSON_SEARCH: 'internal_message_person_search',
  INTERNAL_MESSAGE_PERSON_CONFIRM_BUTTON:
    'internal_message_person_confirm_button',
  INTERNAL_MESSAGE_ASSIGN_SEARCH: 'internal_message_assign_search',
  INTERNAL_MESSAGE_ASSIGN_CONFIRM_BUTTON:
    'internal_message_assign_confirm_button',
  INTERNAL_MESSAGE_PERSON_CLOSE_BUTTON: 'internal_message_person_close_button',
  INTERNAL_MESSAGE_ASSIGN_CLOSE_BUTTON: 'internal_message_assign_close_button',
  INTERNAL_MESSAGE_STATUS: 'internal_message_status',
  INTERNAL_MESSAGE_PRIORITY: 'internal_message_priority',
  INTERNAL_MESSAGE_FOLLOW_DATE: 'internal_message_follow_date',
  INTERNAL_MESSAGE_SUBJECT: 'internal_message_subject',
  INTERNAL_MESSAGE_MESSAGE: 'internal_message_message',
  INTERNAL_MESSAGE_ASSIGN_CLIENT_BUTTON:
    'internal_message_assign_client_button',
  INTERNAL_MESSAGE_ATTACHMENT_BUTTON: 'internal_message_attachment_button',
  INTERNAL_MESSAGE_SEND_BUTTON: 'internal_message_send_button',
  SECURE_MESSAGE_SEND_BUTTON: 'secure_message_send_button',
  INTERNAL_MESSAGE_STUDENTS_BUTTON: 'internal_message_students_button'

  // Search" field and "Send" button on "Assign client" modal
  // Assigned client button
};

export default ids;

import React, { useImperativeHandle, useState } from 'react';

import Modal from 'src/Framework/Controls/Modal';

import { IAlertsData } from 'src/Activities/Schedule/Popups/AppointmentPopup/AppointmentPopup';
import ViewNotifications from 'src/Activities/Schedule/Popups/AppointmentPopup/ViewNotifications';
import Main, { IProps } from './Main';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

import { Container } from './styled';

interface IShow {}

export interface IModalHandles {
  show(obj: IShow): void;

  close(): void;
}

const modalProps = {
  destroyOnClose: true,
  mask: false
};

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  props,
  ref
) => {
  const [alertsData, setAlertsData] = useState<IAlertsData | null>(null);
  const [visible, setVisible] = useState(false);
  useModalFocus({ active: visible });
  const show = async ({}: IShow) => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setAlertsData(null);
  };

  useImperativeHandle(ref, () => ({
    show: (obj: IShow) => show(obj),
    close
  }));

  const title = alertsData ? 'Appointment / Alerts' : '';
  const defaultWidth = 1080;

  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        hideHeader: !alertsData,
        onClickBack: () => setAlertsData(null),
        backButton: !!alertsData,
        title: title
      }}
      width={defaultWidth}
      visible={visible}
      onClose={() => {
        props.onClosePopup();
        close();
      }}
      afterClose={setToDefault}
      modalProps={modalProps}
      title={title}
      onClickBack={() => setAlertsData(null)}
      back={alertsData ? true : false}
      hideHeader={alertsData ? false : true}
      headerProps={
        alertsData
          ? {
              style: { paddingRight: 24 }
            }
          : undefined
      }
    >
      <Container style={{ display: alertsData ? 'none' : 'block' }}>
        <Main
          {...props}
          onClosePopup={() => {
            props.onClosePopup();
            close();
          }}
          onClickAlertsView={(data) => setAlertsData(data)}
        />
      </Container>
      {alertsData && <ViewNotifications data={alertsData} />}
    </Modal>
  );
};

export default React.forwardRef(Component);

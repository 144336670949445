import React, { FC } from 'react';
import cn from 'classnames';

import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';

import { Container, Item } from './styled';

export type IData = Array<{
  label: any;
  value: string | number;
}>;

interface IProps {
  data: IData;
  selected: string | number;
  onChange: (value: string | number) => void;
}

const Component: FC<IProps> = ({ data, selected, onChange }: IProps) => {
  return (
    <Container>
      {data.map((value, index: number) => (
        <Item
          key={index}
          className={cn({
            active: selected === value.value
          })}
          role="button"
          tabIndex={0}
          aria-label={value?.label?.props?.children[0] || ''}
          onClick={() => onChange(value.value)}
          onKeyDown={onKeyDownAccessibility}
        >
          {value.label}
        </Item>
      ))}
    </Container>
  );
};

export default Component;

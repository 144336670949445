import React from 'react';

import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';
import tests_ids from 'src/tests-script/pages/Schedule/SingleApptPopup';

import { AddIndividualApptSvg } from 'src/Activities/Schedule/Svg/AddIndividualApptSvg';
import { RecurreSvg } from 'src/Activities/Schedule/Svg/RecurreSvg';

import {
  EditPopupContainer,
  PopupContainer
} from '../RClickPopup/RClickPopup_Styles';
import {
  Line,
  // Container,
  TextItem
} from './styled';

interface IState {}

interface IProps {
  left?: number;
  top?: number;
  type: string;
  onClosePopup: () => any;
  editSeries: () => void;
  editSingle: () => void;
  eventType?: string;
  buttonRef?: React.MutableRefObject<any>;
}

class EditRClickPopup extends React.Component<IProps, IState> {
  public seriesPopupRef: React.RefObject<any>;

  constructor(props: IProps) {
    super(props);
    this.seriesPopupRef = React.createRef();
  }

  componentDidMount() {
    if (this.seriesPopupRef.current) {
      //@ts-ignore
      this.seriesPopupRef.current.focus();
    }
  }

  render() {
    var { type, eventType, onClosePopup, buttonRef } = this.props;
    const onKeyDownLastItem = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && e.target === e.currentTarget) {
        //@ts-ignore
        e.currentTarget.click();
      }
      if (e.key === 'Tab' && !e.shiftKey && e.target === e.currentTarget) {
        e.preventDefault();
        onClosePopup();
        if (buttonRef?.current) {
          //@ts-ignore
          buttonRef.current.focus();
        }
      }
    };
    const onKeyDownWrapper = (e: React.KeyboardEvent) => {
      if (e.key === 'Tab' && e.shiftKey && e.target === e.currentTarget) {
        e.preventDefault();
        onClosePopup();
        if (buttonRef?.current) {
          //@ts-ignore
          buttonRef.current.focus();
        }
      }
      if (e.code === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        onClosePopup();
        if (buttonRef?.current) {
          //@ts-ignore
          buttonRef.current.focus();
        }
      }
    };

    return (
      <PopupContainer
        id={tests_ids.removePopup}
        onClick={this.props.onClosePopup}
      >
        <EditPopupContainer
          top=""
          left=""
          onClick={(e) => e.stopPropagation()}
          tabIndex={0}
          role="menu"
          ref={this.seriesPopupRef}
          onKeyDown={onKeyDownWrapper}
        >
          {/*<Container>*/}
          <Line
            style={{ marginBottom: 5 }}
            id={tests_ids.removeSingle}
            onClick={() => this.props.editSingle()}
            tabIndex={0}
            onKeyDown={onKeyDownAccessibility}
            role="menuitem"
          >
            <AddIndividualApptSvg fill={'#ffffff'} />
            <TextItem>
              {type} this single {eventType ? eventType : 'event'}
            </TextItem>
          </Line>
          <Line
            style={{ marginBottom: 5 }}
            id={tests_ids.removeAll}
            onClick={() => this.props.editSeries()}
            tabIndex={0}
            onKeyDown={onKeyDownLastItem}
            role="menuitem"
          >
            <div style={{ height: 32, width: 32 }}>
              <RecurreSvg fill={'#ffffff'} />
            </div>
            <TextItem>
              {type} all {eventType ? eventType : 'event'}s in this series
            </TextItem>
          </Line>
          {/*</Container>*/}
        </EditPopupContainer>
      </PopupContainer>
    );
  }
}

export default EditRClickPopup;

import React, { FC, useState, useEffect } from 'react';

import SelectContainer from 'src/Framework/Controls/SelectContainer';
import TESTS_IDS from 'src/tests-script/pages/Schedule/CreateAppt';

interface IProps {
  defaultValue: string | null;
  onChange: (value: string) => any;
}

const Component: FC<IProps> = ({ onChange, defaultValue }) => {
  const [data, setData] = useState(defaultValue || '');
  useEffect(() => {
    setData(defaultValue || '');
  }, [defaultValue]);

  return (
    <SelectContainer
      label={'Comment'}
      star={false}
      type="area"
      value={data}
      onChange={(value: string) => {
        onChange(value);
        setData(value);
      }}
      bottomMargin={false}
      areaAutoReplace={true}
      inputProps={{
        maxLength: 4000,
        placeholder: 'Write a comment here',
        id: TESTS_IDS.COMMENT_AREA
      }}
    />
  );
};

export default React.memo(Component);

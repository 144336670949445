import React, { useImperativeHandle, useState } from 'react';
import { Tag, Row, Button, List } from 'antd';

import Modal from 'src/Framework/Controls/Modal';
import ClinicSelector, {
  getCurrentClinicId
} from 'src/Framework/Controls/Selectors/ClinicSelector';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { searchHighlighter } from 'src/Framework/Controls/Table/searchHightLight';
import Loader from 'src/Framework/Controls/Loader';
import { filterByClinicTypes } from 'src/Framework/Controls/Selectors/ClinicSelector';
import { getCurrentProvider } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';
import { useAppSelector } from 'src/store';
import test_ids from 'src/tests-script/pages/Subject/Forms';

import { getFormTemplate } from 'src/Activities/Forms/FormsActions';
import { permissionChecking } from 'src/App/User/Permission';
import useTags from 'src/App/Tags/Hooks/useTags';
import * as m1FormRecordsActions from 'src/App/M1FormRecords/store/Actions';
import { M1FormSubjectType } from 'src/App/M1FormRecords/store/types';
import {
  ASSIGNMENT_TAGS,
  IBuildingForm
} from 'src/App/Admin/Pages/FormBuilder/Lists/Forms/store/types';
import { assignmenttagDefinitionId } from 'src/App/Admin/Pages/FormBuilder/Lists/Forms';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

import {
  Container,
  ListContainer,
  FormNameContainer,
  TagsContainer,
  ModalContainer,
  ListHeader
} from './styled';
import DebounceSearch from 'src/Framework/Controls/DebounceSearch';

export type ITemplateForm = ILegacyForm | IM1Form;

interface IProps {
  patientId: string;
  onClickAddButton: (form: ITemplateForm, clinicId: string) => Promise<any>;
  clinicId?: number;
  clinicTypes: number[];
  onM1formAdded?: (m1FormId: number) => any;
  sliderMode?: boolean;
}

export enum FormCategory {
  treatmentPlan = 'Treatment Plan',
  mentalStatusExam = 'Mental Status Exam'
}

interface IValue {
  category?: FormCategory;
}

export interface IModalHandles {
  show(obj: IValue): void;

  close(): void;
}

export enum IType {
  LEGACY = 'legacy',
  M1 = 'm1'
}

type ILegacyForm = {
  categories: string[];
  clinicIds: number[];
  code: string;
  id: number;
  isActive: boolean;
  name: string;
  seedId: number;
  type: IType.LEGACY;
};

interface IM1Form extends IBuildingForm {
  type: IType.M1;
}

const defaultWidth = 550;

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  props,
  ref
) => {
  const {
    onClickAddButton,
    clinicTypes,
    patientId,
    onM1formAdded,
    sliderMode
  } = props;
  const { values: assignmentValues } = useTags({
    tagDefinitionId: assignmenttagDefinitionId
  });
  const [loadingList, setLoadingList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [permissionIssue, setPermissionIssue] = useState(false);

  const [visible, setVisible] = useState(false);
  const [clinicId, setClinicId] = useState<null | string>(
    () => getCurrentClinicId() + ''
  );

  const [search, setSearch] = useState<string>('');
  const [forms, setForms] = useState<ITemplateForm[]>([]);
  const [selectedForms, setSelectedForms] = useState<ITemplateForm[]>([]);
  const [category, setCategory] = useState<FormCategory | null>(null);
  const m1Forms = useAppSelector(
    (state) => state.adminPanel.formBuilder.FormsReducer.summary
  );
  useModalFocus({ active: visible });
  const show = async ({ category }: IValue) => {
    const init = async () => {
      setLoadingList(true);
      if (category) {
        setCategory(category);
      }
      const res: Record<string, ILegacyForm> = await getFormTemplate();
      if (res) {
        let permissionIssue = false;
        const list = Object.values(res).filter((template) => {
          if (category) {
            if (template.categories.indexOf(category) === -1) {
              return false;
            }
          }
          const supportedClinics = template.clinicIds.filter((clinicId) => {
            return props.clinicId
              ? clinicId === props.clinicId
              : filterByClinicTypes(clinicId, clinicTypes);
          });
          const permissionsList = supportedClinics.map(
            (clinicId) =>
              permissionChecking({
                anyClinic: false,
                clinicId,
                moduleId: 40,
                functionId: 98
              }).success
          );
          if (
            permissionIssue === false &&
            permissionsList.some((permission) => permission === false)
          ) {
            permissionIssue = true;
          }
          const find = permissionsList.find(
            (permission) => permission === true
          );
          return find;
        });
        if (list.length === 0) {
          setPermissionIssue(permissionIssue);
        }
        setForms(
          list.map((v) => ({
            ...v,
            type: IType.LEGACY
          }))
        );
      }
      setLoadingList(false);
    };
    init();
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setSearch('');
    setForms([]);
    setSelectedForms([]);
    setPermissionIssue(false);
    setClinicId(getCurrentClinicId() + '');
  };

  useImperativeHandle(ref, () => ({
    show: (obj: any) => show(obj),
    close
  }));

  const checkDisabled = () => {
    if (selectedForms.length === 0) {
      return true;
    }
    return false;
  };

  const apply = async () => {
    if (clinicId) {
      setLoading(true);
      const form = selectedForms[0];
      if (form.type === IType.M1) {
        const currentProvider = getCurrentProvider();
        const res = await m1FormRecordsActions.post(
          {
            formTemplateId: form.id,
            assignedProviderId: currentProvider,
            formTemplateVersion: form.latestPublishedVersion,
            clinicId: +clinicId,
            subject: {
              id: +patientId,
              subjectType: M1FormSubjectType.Patient
            }
          },
          patientId
        );
        if (res) {
          if (onM1formAdded) {
            onM1formAdded(res.result?.id!);
          }
          close();
        }
      }
      if (form.type === IType.LEGACY) {
        await onClickAddButton(form, clinicId);
        close();
      }
      setLoading(false);
    }
  };

  const title = `Select Form`;

  const headerRight = (
    <Button
      id={test_ids.formsSaveButton}
      disabled={checkDisabled()}
      onClick={apply}
      shape="round"
      loading={loading}
    >
      Save
    </Button>
  );

  const m1FormsList = Object.values(m1Forms)
    .map((v) => ({
      ...v,
      type: IType.M1
    }))
    .filter((v) => {
      if (!v.latestPublishedVersion) return false;

      if (category) {
        if (category === FormCategory.mentalStatusExam) {
          if (assignmentValues[ASSIGNMENT_TAGS.MentalStatusExam]?.isActive) {
            if (!v.tags.includes(ASSIGNMENT_TAGS.MentalStatusExam)) {
              return false;
            }
          }
        }
        if (category === FormCategory.treatmentPlan) {
          if (assignmentValues[ASSIGNMENT_TAGS.TreatmentPlan]?.isActive) {
            if (!v.tags.includes(ASSIGNMENT_TAGS.TreatmentPlan)) {
              return false;
            }
          }
        }
      } else {
        // if (assignmentValues[ASSIGNMENT_TAGS.Patient]?.isActive) {
        //   if (!v.tags.includes(ASSIGNMENT_TAGS.Patient)) {
        //     return false;
        //   }
        // }
      }

      return true;
    });
  const filteredForms = [...forms, ...m1FormsList]
    .filter((v) =>
      clinicId
        ? v.clinicIds.includes(+clinicId) &&
          v.name.toLowerCase().includes(search.toLowerCase()) &&
          v.isActive
        : false
    )
    .sort((a, b) => a.name?.localeCompare(b.name));
  return (
    <Modal
      isModalSlider={sliderMode}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        headerRight,
        title
      }}
      id={test_ids.formsAddFormModal}
      width={defaultWidth}
      title={title}
      visible={visible}
      headerBorder={true}
      onClose={close}
      afterClose={setToDefault}
      modalProps={{
        destroyOnClose: true
      }}
      buttons={headerRight}
    >
      <ModalContainer>
        <Container>
          <FormNameContainer>
            <ClinicSelector
              selectProps={{
                star: true,
                id: test_ids.formsClinicSelector
              }}
              filterItem={(clinic) =>
                permissionChecking({
                  anyClinic: false,
                  clinicId: clinic.id,
                  moduleId: 40,
                  functionId: 97
                }).success
              }
              multiple={false}
              value={clinicId}
              onChange={(clinicId) => {
                setClinicId(clinicId);
              }}
            />
            <DebounceSearch
              label="Search form"
              labelInRow={false}
              value={search}
              onChange={(value: string) => setSearch(value)}
              star={true}
              bottomMargin={false}
              id={test_ids.formsSearchField}
              searchIcon={false}
            />
            <TagsContainer
              tabIndex={selectedForms.length > 0 ? 0 : -1}
              aria-label="selected forms"
              role="list"
            >
              {selectedForms.map((value: any) => {
                return (
                  <Tag
                    key={value.id}
                    style={{
                      marginBottom: 5
                    }}
                    role="listitem"
                    tabIndex={0}
                    closable
                    onClose={() => {
                      setSelectedForms([]);
                    }}
                    closeIcon={
                      <ButtonIcon
                        isButton={true}
                        label="remove selected form"
                        name={ICONS_LIST.thinClose}
                        size={12}
                        containerProps={{
                          style: {
                            display: 'inline-block'
                          }
                        }}
                      />
                    }
                  >
                    {value.name}
                  </Tag>
                );
              })}
            </TagsContainer>
          </FormNameContainer>
          <ListContainer>
            {forms.length === 0 && permissionIssue && (
              <Row align="middle" justify="center" style={{ margin: '10px 0' }}>
                <span>Permissions not found</span>
              </Row>
            )}
            <ListHeader>Name</ListHeader>
            <List
              id="form-select-list"
              loading={{
                indicator: <Loader />,
                spinning: loadingList
              }}
              className="modal-forms-list"
              dataSource={filteredForms.map((value) => ({
                ...value,
                typeValue:
                  value.type === IType.LEGACY ? (
                    <ButtonIcon name={ICONS_LIST.letterL} size={22} />
                  ) : null
              }))}
              renderItem={(item: any) => (
                <List.Item
                  id={'form-select-item-' + item.id}
                  key={`${item.id}-${item.type}`}
                  tabIndex={0}
                  role="button"
                  className="modal-forms-list-item"
                  onClick={() => {
                    setSelectedForms([item]);
                  }}
                  onKeyDown={onKeyDownAccessibility}
                >
                  <span>
                    {
                      searchHighlighter({
                        data: {
                          name: item.name
                        },
                        search
                      }).name_HIGHLIGHT
                    }
                  </span>
                  {item.type === IType.LEGACY ? (
                    <ButtonIcon
                      name={ICONS_LIST.letterL}
                      size={22}
                      containerProps={{
                        tabIndex: -1,
                        style: {
                          display: 'inline-block'
                        }
                      }}
                    />
                  ) : (
                    <span />
                  )}
                </List.Item>
              )}
            />
          </ListContainer>
        </Container>
      </ModalContainer>
    </Modal>
  );
};

export default React.forwardRef(Component);

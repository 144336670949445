import styled from 'styled-components'

export const Circle = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 12px;
    margin: 0 0 0 10px;
`

export const TitleRow = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden;
`
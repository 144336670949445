import React, { useImperativeHandle, useState } from 'react';

import Modal from 'src/Framework/Controls/Modal';

import Appointment, { IActionOptions } from './index';
import { IAlertsData } from './AppointmentPopup';
import ViewNotifications from './ViewNotifications';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

interface IProps {
  onEventSaved?: (newEventPayload: any) => void;
  onClosePopup?: () => void;
  callerButton?: React.MutableRefObject<any>;
  modalId?: string;
  onCreateTriage?: (payload: any) => any;
}

interface IShow {
  options: IActionOptions;
}

export interface IModalHandles {
  show(obj: IShow): void;

  close(): void;
}

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { onEventSaved, onClosePopup, modalId, callerButton, onCreateTriage },
  ref
) => {
  const [options, setOptions] = useState<IActionOptions | null>(null);
  const [alertsData, setAlertsData] = useState<IAlertsData | null>(null);
  const [visible, setVisible] = useState(false);
  useModalFocus({ active: visible });
  const show = ({ options }: IShow) => {
    setOptions(options);
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
    if (callerButton && callerButton.current) {
      callerButton.current.focus();
    }
  };

  const setToDefault = () => {
    setOptions(null);
    setAlertsData(null);
  };

  useImperativeHandle(ref, () => ({
    show,
    close
  }));
  const title = alertsData ? 'Appointment / Alerts' : '';
  const defaultWidth = 1320;
  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        hideHeader: !alertsData,
        onClickBack: () => setAlertsData(null),
        backButton: !!alertsData,
        title: title
      }}
      id={modalId}
      width={defaultWidth}
      title={title}
      visible={visible}
      onClose={close}
      afterClose={setToDefault}
      onClickBack={() => setAlertsData(null)}
      back={alertsData ? true : false}
      isDragable={true}
      modalProps={{
        centered: true,
        destroyOnClose: true,
        mask: false
      }}
      hideHeader={alertsData ? false : true}
      headerProps={
        alertsData
          ? {
              style: { paddingRight: 24 }
            }
          : undefined
      }
    >
      {options && (
        <div style={{ display: alertsData ? 'none' : 'block', flexGrow: 1 }}>
          <Appointment
            onCreateTriage={onCreateTriage}
            isTriage={options.isTriage}
            onEventSaved={onEventSaved}
            actionOption={options}
            onClosePopup={() => {
              close();
              onClosePopup && onClosePopup();
            }}
            onClickAlertsView={(data) => setAlertsData(data)}
          />
        </div>
      )}
      {alertsData && <ViewNotifications data={alertsData} />}
    </Modal>
  );
};

export default React.forwardRef(Component);

import store from 'src/store';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from '../ScheduleActions/ScheduleDetailActions/ApiUrl';
import { IApptResponse, IPatientStatus } from './types';

import { actionsTypes } from './index'


interface IRequestDeleteGroupPerson {
  subjectId: number;
  appointmentId: number;
  cancelDate: string;
}

interface IDeleteGroupPatientResponse {
  saveScheduleEventInfo: any;
  scheduleEvents: {
    cancelledPatients: Record<string, any>; // TODO: need to check it interface
  }[];
}

export const deleteGroupPersonMember = async (
  data: IRequestDeleteGroupPerson
): Promise<IDeleteGroupPatientResponse | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.DELETE_SCHEDULE_EVENT,
      {
        scheduleEventTypeId: 1,
        cancelReasonCode: '24Notice',
        cancelSeries: false,
        cancelGroup: false,
        ...data
      },
      { withCredentials: true }
    );
    if (response.data.success) {
      return response.data.result;
    }
    return;
  } catch (e) {
    console.log(e);
    return;
  }
};

interface IUpdatePatientStatusResponse {
  appointmentId: number;
  subjectId: number;
  status: IPatientStatus;
}

export const updatePatientStatus = async (
  payload: any
): Promise<IUpdatePatientStatusResponse | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.UPDATE_APPT_STATUS,
      {
        ...payload
      },
      { withCredentials: true }
    );
    if (response.data.success) {
      return response.data.result;
    }
    return;
  } catch (e) {
    console.log({ e });
    return;
  }
};

export const getScheduleBlockAppointment = async (
  { appointmentBlockId, startTime }: {
    appointmentBlockId: number | string
    startTime: string
  },
  scheduleEventTypeId?: number
): Promise<IApptResponse | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.GET_SCHEDULE_EVENT,
      {
        appointmentBlockId,
        startTime,
        scheduleEventTypeId
      },
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const result = response.data.result
      return result;
    }
    return;
  } catch (e) {
    console.log({ e });
    return;
  }
};

export const getScheduleAppointment = async (
  appointmentId: number | string,
  scheduleEventTypeId?: number
): Promise<IApptResponse | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.GET_SCHEDULE_EVENT,
      {
        appointmentId,
        scheduleEventTypeId
      },
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const result = response.data.result
      return result;
    }
    return;
  } catch (e) {
    console.log({ e });
    return;
  }
};



export const getAppointment = async (
  appointmentId: number,
  useDispatch = true
): Promise<IApptResponse | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.GET_SCHEDULE_EVENT,
      {
        appointmentId
      },
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const result = response.data.result
      if (useDispatch) {
        store.dispatch(addScheduleEvent(result));
      }
      return result;
    }
    return;
  } catch (e) {
    console.log({ e });
    return;
  }
};

export const getScheduleEvent = async (
  appointmentId: any,
  subjectId: any,
  isAppt: boolean,
  newEvent: any,
  patient?: any
) => {
  const payload = isAppt
    ? { appointmentId, subjectId }
    : { appointmentBlockId: appointmentId, subjectId };
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.GET_SCHEDULE_EVENT,
      payload,
      {
        withCredentials: true
      }
    );
    let result = response.data.result;
    if (response.data.success) {
      if (patient) {
        result.scheduleEvent = {
          ...result.scheduleEvent,
          scheduleEventDateTime: patient.startTime,
          endTime: patient.endTime
        };
      }
      if (newEvent) {
        result.scheduleEvent = { ...result.scheduleEvent, ...newEvent };
      }
      store.dispatch(addScheduleEvent(result));
    }
  } catch (error) {
    console.log({ error });
  }
};

export const addScheduleEvent = (eventInfo: any) => {
  return {
    type: actionsTypes.ADD_SCHEDULE_EVENT,
    payload: eventInfo
  };
};

export const removeScheduleEvent = (uid: any) => {
  return {
    type: actionsTypes.REMOVE_SCHEDULE_EVENT,
    payload: uid
  };
};

export const removeSingleSubjectFromEvent = (
  sliceId: any,
  uid: any,
  subjectId: any
) => {
  return {
    type: actionsTypes.REMOVE_SINGLE_SUBJECT_FROM_EVENT,
    payload: {
      sliceId: sliceId,
      uid: uid,
      subjectId: subjectId
    }
  };
};

import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
`

export const LoadingContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`
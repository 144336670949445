import React, { FC, useEffect, useState } from 'react';
import { getPreferredPhoneNumber } from 'src/Activities/Subject/Patients/Contacts';

import { IPatientContactsSimpleDto } from 'src/Activities/Subject/Patients/store/types';
import { useGenericType } from 'src/App/GenericTypes/store/Actions/hooks';
import Table from 'src/Framework/Controls/Table';

import { getPatientContacts } from './Actions';

import { Container } from './styled';

interface IProps {
  patientId: number;
}

const Component: FC<IProps> = ({ patientId }: IProps) => {
  const typeRelationship = useGenericType('typeRelationship');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Record<string, IPatientContactsSimpleDto>>(
    {}
  );
  const getData = async () => {
    setLoading(true);
    const res = await getPatientContacts(patientId);
    if (res) {
      setData(res);
    } else {
      setData({});
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [patientId]);
  return (
    <Container>
      <Table
        absoluteContainer={true}
        absoluteLoading={loading}
        data={Object.values(data).map((value) => ({
          ...value,
          firstName: value.otherParty.firstName,
          lastName: value.otherParty.lastName,
          relationship: typeRelationship.loadingFinder(
            value.relationshipId,
            'relationship'
          ),
          phoneNumber: getPreferredPhoneNumber(value)
        }))}
        columns={[
          {
            title: 'Last Name',
            flex: '120px',
            key: 'lastName',
            ellipsis: true
          },
          {
            title: 'First Name',
            flex: '120px',
            key: 'firstName',
            ellipsis: true
          },
          {
            title: 'Relationship',
            flex: 'auto',
            key: 'relationship'
          },
          {
            title: 'Phone number',
            flex: '220px',
            key: 'phoneNumber'
          }
        ]}
      />
    </Container>
  );
};

export default Component;

import React, { useImperativeHandle, useState } from 'react';
import _ from 'lodash';

import Modal from 'src/Framework/Controls/Modal';

import Form from './Form';

import { Container } from './styled';
import { IM1FormRecord } from 'src/App/M1FormRecords/store/types';

interface IProps {
  onRemoveForm: () => any;
}

const defaultWidth = 1100;
const title = '';

interface IShow {
  value: IData;
}

interface IData {
  m1FormRecordId: number;
  patientId: number;
  defaultForm?: IM1FormRecord;
}

export interface IModalHandles {
  show(obj: IShow): void;
  close(): void;
}

const modalProps = {
  destroyOnClose: true
};

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { onRemoveForm },
  ref
) => {
  const [data, setData] = useState<IData | null>(null);
  const [visible, setVisible] = useState(false);

  const show = async ({ value }: IShow) => {
    setData(value);
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setData(null);
  };

  useImperativeHandle(ref, () => ({
    show: (obj: IShow) => show(obj),
    close
  }));

  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        title,
        hideHeader: true
      }}
      width={defaultWidth}
      title={title}
      visible={visible}
      onClose={close}
      headerBorder={true}
      hideHeader={true}
      afterClose={setToDefault}
      modalProps={modalProps}
    >
      <Container>
        {data && (
          <Form
            defaultForm={data.defaultForm}
            patientId={data.patientId}
            data={data}
            onRemoveForm={onRemoveForm}
            closeModal={close}
          />
        )}
      </Container>
    </Modal>
  );
};

export default React.forwardRef(Component);

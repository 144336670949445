import React, { FC } from 'react';
import { Button, Space } from 'antd';

import Icon, { IconNames } from 'src/Framework/Controls/Icon';
import Tooltip from 'src/Framework/Controls/Tooltip';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import { ButtonContainer, Label } from './styled';

export interface IProps {
  iconName: IconNames;
  text: string;
  color?: string;
  isDisabled: boolean;
  isFilled: boolean;
  id: string;
  infoNumber?: number;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  tooltip?: string;
}

const Component: FC<IProps> = ({
  iconName,
  isDisabled,
  isFilled,
  text,
  color = globalStyleVariables.newBrandingPrimary200,
  id,
  onClick,
  infoNumber,
  tooltip
}) => {
  return (
    <Tooltip title={tooltip ? tooltip : undefined}>
      <ButtonContainer isDisabled={isDisabled} color={color} filled={isFilled}>
        <Button
          style={{ minWidth: 84 }}
          disabled={isDisabled}
          aria-disabled={isDisabled}
          type="text"
          id={id}
          className="conditional-button"
          onClick={(e) => {
            if (isDisabled) {
              return;
            }
            onClick(e);
          }}
        >
          <Space>
            <Icon
              size={16}
              color={isDisabled ? globalStyleVariables.middleGrey : color}
              name={iconName}
            />
            <Label isDisabled={isDisabled} color={color}>
              {text}
            </Label>
            {infoNumber !== undefined && (
              <Label isDisabled={isDisabled} color={color}>
                {infoNumber}
              </Label>
            )}
          </Space>
        </Button>
      </ButtonContainer>
    </Tooltip>
  );
};

export default React.memo(Component);

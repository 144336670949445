import React from 'react';
import { Empty } from 'antd';

import File from './File';
import Form from './Form';

import { Container } from './styled';

interface IProps {
  files: Array<any>;
  forms?: Array<any>;
  onRemove?: Function;
  onRemoveForm?: Function;
  type?: string;
}

const Component: React.FC<IProps> = ({
  files,
  onRemove,
  type,
  forms,
  onRemoveForm
}: IProps) => {
  return (
    <Container tabIndex={0} role="list">
      <div className="header-attachments">
        Attachments <div className="line" />
      </div>
      <div className="attachments-container">
        {files.length === 0 && forms?.length === 0 && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {files.map((value, index: number) => (
          <File
            key={index}
            index={index}
            onRemove={onRemove}
            value={value}
            type={type}
          />
        ))}
        {forms?.map((value, index: number) => (
          <Form
            key={index}
            index={index}
            onRemove={onRemoveForm}
            value={value}
          />
        ))}
      </div>
    </Container>
  );
};

export default Component;

import styled from 'styled-components';
import {
  input_disabled_text_color,
  input_active_text_color,
  blue_color,
  input_normal_bg_color,
  input_placeholder_text_color
} from 'src/CommonStyles/styles';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

interface IStyle {
  disabled?: boolean;
  valid?: string;
  isFilled?: boolean;
  view?: boolean;
}

export const NotificationInput: any = styled.input`
  width: 80rem;
  min-width: 200px;
  height: 40px;
  background-color: ${input_normal_bg_color};
  color: ${(props: IStyle) =>
    props.disabled ? input_disabled_text_color : input_active_text_color};
  border: ${(props: IStyle) =>
    props.valid ? '1px solid transparent' : '1px solid red'};
  outline: 0;
  font-family: Lato;
  font-style: normal;
  padding: 10px;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 0.5vh;

  &::placeholder {
    color: ${(props: IStyle) =>
      props.disabled
        ? input_disabled_text_color
        : input_placeholder_text_color};
  }

  &:hover {
    border-color: ${(props: IStyle) =>
      props.view
        ? ' #e6e6e6'
        : props.isFilled
        ? input_active_text_color
        : blue_color};
  }

  &:focus {
    border-color: ${(props: IStyle) => (props.view ? '#e6e6e6' : blue_color)};
    background-color: #fff;
  }

  &:focus::placeholder {
    color: ${input_disabled_text_color};
  }
`;

export const NotificationLargeInput = styled.textarea`
  width: 80rem;
  min-width: 200px;
  height: 100px;
  min-height: 200px;
  background-color: #f7f7f7;
  color: ${(props: IStyle) => (props.disabled ? '#CCCCCC' : '#333333')};
  border: 1px solid #e6e6e6;
  outline: 0;
  font-family: Lato;
  font-style: normal;
  padding: 10px;
  font-weight: normal;
  font-size: 16px;
  resize: none;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(24, 144, 255, 0.2);
    display: block;
  }

  &::placeholder {
    color: ${(props: IStyle) => (props.disabled ? '#CCCCCC' : '#666666')};
  }

  &:hover {
    border-color: ${(props: IStyle) =>
      props.view
        ? '#e6e6e6'
        : props.isFilled
        ? '#333333'
        : globalStyleVariables.newBrandingPrimary200};
  }

  &:focus {
    border-color: ${(props: IStyle) =>
      props.view ? '#e6e6e6' : globalStyleVariables.newBrandingPrimary200};
    background-color: #fff;
  }

  &:focus::placeholder {
    color: #cccccc;
  }
`;

export const NNContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 2.4rem 2rem;
`;
export const NNTopWrapper: any = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
`;
export const NNHeading: any = styled.div`
  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  font-size: 32px;
  color: #333333;
`;
export const ButtonWrapper: any = styled.div`
  .ant-btn-round {
    font-size: 13px;
    /* border-radius: 50px; */
  }
`;
export const Label: any = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 14px;
  color: ${(props: { disabled: boolean }) =>
    props.disabled ? input_disabled_text_color : '#666666'};
  margin-bottom: 5px;
  display: flex;
`;

export const ModalBoxButtonContainer: any = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px 0px 10px;
  align-items: center;
`;

// export const ADRulesArea: any = styled.div`
//   padding: 10px 18px;
//   overflow: auto;
//   overflow-x: hidden;
//   max-height: 30vh;
// `;
// export const NameChangeArea: any = styled.div`
//   display: flex;
//   padding: 10px 18px;
//   font-size: 17px;
// `;
// export const RadioButtonArea: any = styled.div`
//   padding: 10px 18px;
// `;
// export const TextBoxArea: any = styled.div`
//   padding: 10px 18px;
//   margin-top: 21px;
// `;
// export const SubmitDecision: any = styled.button`
//   border: 2px solid #45b4ec;
//   color: #1984cb;
//   background-color: #cee9fb;
//   font-size: 14px;
//   border-radius: 21px;
//   width: 16vh;
//   height: 40px;
//   margin-top: 10px;
// `;
// export const NewDecision: any = styled.button`
//   border: 2px solid #45b4ec;
//   color: #1984cb;
//   background-color: #cee9fb;
//   font-size: 14px;
//   border-radius: 21px;
//   width: 16vh;
//   height: 40px;
//   margin-top: 10px;
// `;

import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"

import * as Setters from './Setters'
import { IOutreachSimpleDto, IRegistrant, ISearchFilter } from "../types";

export const getById = async (id: number | string): Promise<Record<string, IOutreachSimpleDto> | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.OUTREACH, {
            withCredentials: true,
            params: {
                id
            }
        })
        if (response.data.success) {
            const data = response.data.result.summary
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const get = async (): Promise<Record<string, IOutreachSimpleDto> | undefined> => {
    try {
        Setters.setLoading(true)
        const response = await APIHandler.AxiosInstance.get(ApiUrl.OUTREACH, {
            withCredentials: true,
        })
        if (response.data.success) {
            const data = response.data.result.summary
            Setters.setSummary(data)
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
        Setters.setLoading(false)
    }
}

export const post = async (body: Omit<IOutreachSimpleDto, "id">): Promise<IOutreachSimpleDto | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(ApiUrl.OUTREACH, body, {
            withCredentials: true,
        })
        if (response.data.success) {
            const list: any = Object.values(response.data.result.summary)
            const data: IOutreachSimpleDto | undefined = list[0]
            if (data) {
                Setters.setItem(data)
            }
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const patch = async (body: Partial<IOutreachSimpleDto> & { id: number }): Promise<IOutreachSimpleDto | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.patch(`${ApiUrl.OUTREACH}/${body.id}`, body, {
            withCredentials: true,
        })
        if (response.data.success) {
            const list: any = Object.values(response.data.result.summary)
            const data: IOutreachSimpleDto | undefined = list[0]
            if (data) {
                Setters.setItem(data)
            }
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const getOutreach = async (id: number): Promise<IOutreachSimpleDto | undefined> => {
    try {
        Setters.setLoading(true)
        const response = await APIHandler.AxiosInstance.get(ApiUrl.OUTREACH, {
            withCredentials: true,
            params: {
                id
            }
        })
        if (response.data.success) {
            const list: IOutreachSimpleDto[] = Object.values(response.data.result.summary)
            const data = list[0]
            return data
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const getDefineGuestList = async (data: ISearchFilter): Promise<number | undefined> => {
    try {
        const params: Partial<ISearchFilter> = {}
        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                params[key] = value.join(',')
            } else {
                params[key] = value
            }
        })
        const response = await APIHandler.AxiosInstance.get(`${ApiUrl.PATIENTS}`, {
            withCredentials: true,
            params: {
                ...params,
                format: 'empty'
            }
        })
        if (response.data.success) {
            return response.data.result.total
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const putRegistrant = async (outreachId: number, patientId: number | string): Promise<IRegistrant | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.put(`${ApiUrl.OUTREACH}/${outreachId}/registrants/${patientId}`, {}, {
            withCredentials: true,
        })
        if (response.data.success) {
            const list: any = Object.values(response.data.result.summary)
            const data = list[0]
            if (data) {
                return data
            }
            return
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const getRegistrants = async (outreachId: number): Promise<Record<string, IRegistrant> | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(`${ApiUrl.OUTREACH_REGISTRANTS}`, {
            withCredentials: true,
            params: {
                outreachId,
            }
        })
        if (response.data.success) {
            return response.data.result.summary
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const deleteAllRegistrants = async (outreachId: number): Promise<boolean | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.delete(`${ApiUrl.OUTREACH_REGISTRANTS}`, {
            withCredentials: true,
            params: {
                outreachId,
            }
        })
        if (response.data.success) {
            return true
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const deleteRegistrant = async (outreachId: number, patientId: number | string): Promise<boolean | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.delete(`${ApiUrl.OUTREACH_REGISTRANTS}`, {
            withCredentials: true,
            params: {
                outreachId,
                patientId
            }
        })
        if (response.data.success) {
            return true
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}
import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`

export const TableContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
`

export const ResultContainer = styled.div`
    padding: 20px;
`

export const FormRecordContainer = styled.div`
    margin-bottom: 20px;
`
import { useCallback } from 'react';
import _ from 'lodash';

import { useAppSelector } from 'src/store';

import { IUserGeneralSettings, IScannerSettings, IScannerInfo } from 'src/App/UserPreferences/store/types';
import { onChangePreferenceItem } from '../../UserPreferences/store/Actions';
import { PrefEnumKeys } from '../utils';

const defaultSettings: IScannerSettings = {
  saveFileFormat: 'jpg',
  scannerId: '',
  deviceSettings: {
    currentScanner: 'Looking for devices..',
    currentCamera: 'Looking for devices..',
    bShowUI: false,
    bADF: false,
    bDuplex: false,
    nPixelType: '0',
    nResolution: '100',
    isVideoOn: false
  }
};

export const generateScannerId = (scannerInfo: IScannerInfo) => {
  const id = `${scannerInfo.DriverType || ''}-${scannerInfo.Manufacturer || ''}-${scannerInfo.ProductFamily || ''}-${scannerInfo.ProductName || ''}`;
  if (id === '---') {
    return '';
  }
  return id;
};

export const defaultUserGeneralSettings = (): IUserGeneralSettings => {
  return {
    disableRiskWidget: false,
    scannerSettings: { ...defaultSettings }
  };
};

export const defaultScannerSettings = (): IScannerSettings => {
  return {
    ...defaultSettings
  };
};

export const useUserGeneralSettingsPref = () => {
  const data = useAppSelector(
    (state) => {
      const currentState = state.user.preferences.userGeneralSettings?.parsedValue ||
        defaultUserGeneralSettings();
      if (!currentState?.scannerSettings) {
        currentState.scannerSettings = defaultScannerSettings();
      }
      return currentState;
    }
  );

  const updateScannerSettings = useCallback(async (newSettings: Partial<IScannerSettings>) => {
    const settings = _.cloneDeep(data);
    if (settings.scannerSettings) {
      settings.scannerSettings = {
        ...settings.scannerSettings,
        ...newSettings
      };
    } else {
      settings.scannerSettings = { ...defaultScannerSettings(), ...newSettings };
    }
    await onChangePreferenceItem(PrefEnumKeys.userGeneralSettings,
      settings
    );
  }, [data]);


  return {
    data: {
      ...data,
      disableRiskWidget: data.disableRiskWidget || false
    },
    actions: {
      updateScannerSettings
    }
  };
};
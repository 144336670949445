import styled from "styled-components";
import globalStyleVariables from "src/Framework/Styles/variables.module.scss";

interface IItem {
  outOfTimeRange: boolean
  position: {
    x: number
    y: number
    height: number
    width: number
  }
}

export const Item = styled.div<IItem>`
  position: absolute;
  top: ${({ position }) => position.y}px;
  left: ${({ position }) => position.x}px;
  width: ${({ position }) => position.width}px;
  height: ${({ position }) => position.height}px;
  z-index: 3;
  .range-time {
    opacity: 0;
    transition: all 0.15s linear;
    margin: 0 0 0 20px;
    font-size: 14px;
    pointer-events: none;
  }
  &:hover {
    background: #33333310;
    .range-time{
        opacity: 1;
    }
  }
  &.isHovering {
    border: 2px solid ${globalStyleVariables.newBrandingPrimary200};
  }
  ${({ outOfTimeRange }) =>
    outOfTimeRange &&
    `
    cursor: not-allowed;
     background-color: rgba(0,0,0,0.05);
     > div {
      pointer-events: none;
        
     }
   
    `}
`
import React, { FC, useState } from 'react';

import { Row, Col } from 'antd';
import _ from 'lodash';

import SelectContainer from 'src/Framework/Controls/SelectContainer';
import Checkbox from 'src/Framework/Controls/Checkbox';
import Button from 'src/Framework/Controls/Button';

import test_ids from 'src/tests-script/pages/Schedule/CreateAppt/Telehealth';

import { Container, Footer } from './styled';

import { ITelehealth } from '../types';
import { IApptResponse } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';

interface IProps {
  scheduleEvent: IApptResponse['scheduleEvent'];
  onBack: Function;
  onChange: (obj: Partial<ITelehealth>) => any;
  prevData: ITelehealth;
  onChangeIsTelehealth: (value: boolean) => any;
}

const Component: FC<IProps> = ({
  onBack,
  onChange,
  prevData,
  scheduleEvent,
  onChangeIsTelehealth
}: IProps) => {
  const [isTelehealth, setIsTelehealth] = useState(scheduleEvent.isTelehealth);
  const [data, setData] = useState<ITelehealth>(_.cloneDeep(prevData));
  const onChangeData = (obj: Partial<ITelehealth>) => {
    setData({
      ...data,
      ...obj
    });
  };
  return (
    <Container id={test_ids.CONTAINER}>
      <Checkbox
        id={test_ids.enableCheckbox}
        style={{ marginBottom: 15 }}
        checked={isTelehealth}
        onChange={() => {
          setIsTelehealth(!isTelehealth);
        }}
      >
        Enable telehealth
      </Checkbox>
      <SelectContainer
        helpTooltip="Enter connection link and instructions."
        label="Telehealth Patient Connection Info"
        type="area"
        value={data.patientConnectionInfo}
        star={false}
        onChange={(patientConnectionInfo: string) => {
          onChangeData({
            patientConnectionInfo
          });
        }}
        inputProps={{
          id: test_ids.patientConnectionInfoArea,
          placeholder: 'Paste link',
          rows: 5,
          maxLength: 500
        }}
      />
      <SelectContainer
        helpTooltip="Enter connection link and instructions."
        label="Telehealth Provider Connection Info"
        type="area"
        value={data.providerConnectionInfo}
        star={false}
        onChange={(providerConnectionInfo: string) => {
          onChangeData({
            providerConnectionInfo
          });
        }}
        inputProps={{
          id: test_ids.providerConnectionInfoArea,
          placeholder: 'Paste link',
          rows: 5,
          maxLength: 500
        }}
      />
      <Footer>
        <Row gutter={16} align="middle">
          <Col>
            <Button id={test_ids.cancel} onClick={() => onBack()}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              id={test_ids.confirmButton}
              className="green-button"
              onClick={() => {
                onChange({
                  ...data
                });
                if (isTelehealth !== scheduleEvent.isTelehealth) {
                  onChangeIsTelehealth(isTelehealth);
                }
                onBack();
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
};

export default Component;

import React, { FC, useMemo } from 'react';

import { Row } from 'antd';

import store from 'src/store';
import NoteButton from 'src/Framework/Controls/NoteButton';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import {
  goToPatientLegacyForm,
  goToPatientM1Form,
  goToProviderM1Form,
  loadActivityTab
} from 'src/App/ActivityPanel/Store/ActivityActions';
import ChartButton from 'src/Framework/Controls/PatientPagesButtons/ChartButton';

import { ITaskItem } from '../store/types';

import { getTaskParams } from '../utils';

import ReferralResultButton from './ReferralResultButton';
import { ButtonContainer } from './styled';
import { permissionChecking } from 'src/App/User/Permission';
import Summary, {
  ILegacyFormSummary,
  IM1FormSummary
} from 'src/Activities/Forms/Summary';
import { Type } from 'src/Activities/Forms/Forms';
import ManageResultsButton from 'src/App/ReqOrderResults/Components/ManageResultsButton';

interface IProps {
  value: ITaskItem;
  containerStyles?: any;
  color?: string;
  clinicId: number;
  afterFormSummaryUpdate?: (formData: any) => void;
}

const Component: FC<IProps> = ({
  value,
  containerStyles,
  color,
  clinicId,
  afterFormSummaryUpdate
}) => {
  const {
    note,
    patientId,
    createApptNoteURL,
    referralId,
    templateFormRecordId,
    m1FormRecordId,
    providerId,
    reqOrderId
  } = getTaskParams(value);

  const currentClinicsPermission = useMemo(
    () =>
      permissionChecking({
        anyClinic: false,
        clinicId: clinicId,
        moduleId: 40,
        functionId: 1205
      }),
    [clinicId]
  );

  const currentForm = () => {
    if (templateFormRecordId) {
      return {
        seedId: templateFormRecordId,
        summary: value?.summary || '',
        type: Type.LEGACY
      } as ILegacyFormSummary;
    } else if (m1FormRecordId) {
      return {
        id: m1FormRecordId,
        summary: value?.summary || '',
        type: Type.M1
      } as IM1FormSummary;
    }
    return;
  };

  return (
    <Row align="middle" style={{ ...containerStyles }}>
      {patientId && reqOrderId && (
        <ButtonContainer>
          <ManageResultsButton patientId={patientId} reqOrderId={reqOrderId} />
        </ButtonContainer>
      )}
      {patientId && (
        <ButtonContainer>
          <ChartButton patientId={patientId} color={color} />
        </ButtonContainer>
      )}
      {note && patientId && (
        <ButtonContainer>
          <NoteButton
            buttonProps={{
              color: color
            }}
            patientId={patientId}
            inNewTab={true}
            checkType={false}
            note={{ ...note, subject: 'Open note' }}
          />
        </ButtonContainer>
      )}
      {templateFormRecordId && patientId && (
        <ButtonContainer>
          <ButtonIcon
            isButton={true}
            label="Form"
            changeColorOnHover={true}
            color={color}
            name={ICONS_LIST.document}
            tooltip="Form"
            onClick={() => {
              goToPatientLegacyForm(patientId, templateFormRecordId);
            }}
          />
        </ButtonContainer>
      )}
      {createApptNoteURL && (
        <ButtonContainer>
          <ButtonIcon
            disabled={!currentClinicsPermission.success}
            tooltip={
              currentClinicsPermission.success
                ? `Create Note`
                : currentClinicsPermission.message
            }
            isButton={true}
            label="Create note"
            changeColorOnHover={true}
            color={color}
            name={ICONS_LIST.apptNote}
            onClick={() => {
              if (createApptNoteURL) {
                loadActivityTab(createApptNoteURL, store.dispatch);
              }
            }}
          />
        </ButtonContainer>
      )}
      {patientId && referralId && (
        <ButtonContainer>
          <ReferralResultButton
            patientId={patientId}
            referralId={referralId}
            color={color}
          />
        </ButtonContainer>
      )}
      {patientId && m1FormRecordId && (
        <ButtonContainer>
          <ButtonIcon
            isButton={true}
            label="M1Form"
            changeColorOnHover={true}
            name={ICONS_LIST.document}
            tooltip="M1Form"
            onClick={() => {
              goToPatientM1Form(patientId, m1FormRecordId);
            }}
          />
        </ButtonContainer>
      )}
      {providerId && m1FormRecordId && (
        <ButtonContainer>
          <ButtonIcon
            isButton={true}
            label="M1Form"
            changeColorOnHover={true}
            name={ICONS_LIST.document}
            tooltip="M1Form"
            onClick={() => {
              goToProviderM1Form(providerId, m1FormRecordId);
            }}
          />
        </ButtonContainer>
      )}
      {!!currentForm() && (
        <ButtonContainer>
          <Summary
            currentForm={currentForm()!}
            afterFormUpdate={afterFormSummaryUpdate}
          />
        </ButtonContainer>
      )}
    </Row>
  );
};

export default Component;

import React from 'react';
import { Row as AntRow, Col } from 'antd';
import 'react-tabulator/lib/css/bootstrap/tabulator_bootstrap4.min.css';

import EllipsisText from 'src/Framework/Controls/EllipsisText';
import {
  patientDisplayName,
  currencyFormat,
  percentFormat
} from 'src/Framework/util/format';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { formattedDateTime } from 'src/Framework/Shared/Shared';
import store from 'src/store';

import { CircleDownSvg } from 'src/Activities/Schedule/Svg/CircleDownSvg';
import { CircleUpSvg } from 'src/Activities/Schedule/Svg/CircleUpSvg';
import { phoneNumberMask } from 'src/Activities/Schedule/Utils/Utils';
import {
  getSubjectSummary,
  getSubjectPreferenceFields
} from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import { getPatientImage } from 'src/Activities/Subject/Patients/Demographics/Controls/PatientImageActions';
import { imageFormats } from 'src/Activities/Subject/Patients/Demographics/Controls/PatientImageReducer';
import { TFtoTF } from 'src/Activities/Schedule/InitData';
import { IScheduleEvent } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import { unusedFields } from 'src/App/UserPreferences/PatientInformationAppliance';

import {
  PatientDetailContainer,
  PatientInfoContainer,
  ApptsKeptContainer,
  ApptsCancelledContainer,
  PreferedPhoneContainer,
  ColumnBoxItem
} from 'src/Activities/Schedule/Popups/AppointmentPopup/AppointmentPopup_Styles';
import * as container from 'src/Activities/Subject/PatientHeader/styled';
import { ExtendIconContainer } from 'src/Activities/Schedule/Popups/ScheduleDetailPopup/ScheduleDetailPopup_Styles';
import { Row, Info, Row2 } from 'src/Activities/Schedule/Common/Classes';

interface IOption {
  label: string;
  value: number;
}

interface IState {
  patientDetail: any;
  subjectPreference: { key: string; value: string }[];
  patientFields: string[];
  patientFieldsArr: any[];
  isLoading: boolean;
  isExpand: boolean;
  documentImage: any;
  employmentList: IOption[];
}

interface IProps {
  action: string;
  dispatch: any;
  highRisk: boolean;
  subjectId: any;
  viewMode?: boolean;
  scheduleEvent?: IScheduleEvent;
  defaultCollapsed?: boolean;
}

const renewFieldNames = (oldName: string) =>
  oldName === 'Nick Name'
    ? 'Preferred Name'
    : oldName === 'Student Status'
    ? 'Campus Status'
    : oldName === 'Gender'
    ? 'Sex'
    : oldName === 'Patient Memo'
    ? getPatientTerm('Patient Memo')
    : oldName === 'Patient Percent'
    ? getPatientTerm('Patient Percent')
    : oldName;

const correctFieldNames = (oldName: string) =>
  oldName === 'Client Memo'
    ? 'Patient Memo'
    : oldName === 'Client Percent'
    ? 'Patient Percent'
    : oldName;

class PatientDetail extends React.Component<IProps, IState> {
  public senderRef: any;
  public recieverRef: any;

  constructor(props: any) {
    super(props);
    const employed = store.getState().client.lists.patientLists.employed;
    const emplList: IOption[] = employed ? Object.values(employed) : [];
    const patientFields: string =
      store.getState().user.preferences.patientFieldOrder?.value || '';
    const patientFieldsFiltered = patientFields
      .split(';')
      .filter((item) => item !== '')
      .map((item) => correctFieldNames(item))
      .filter((item) => !unusedFields.includes(item));

    const patientFieldsArrChunk = this.chunkArray(
      patientFieldsFiltered
        .map((item) => renewFieldNames(item))
        .map((item) => ({ key: item, value: '' })),
      3
    );

    this.state = {
      documentImage: {},
      isExpand: !this.props.defaultCollapsed,
      patientDetail: null,
      patientFields: patientFieldsFiltered,
      patientFieldsArr: patientFieldsArrChunk,
      subjectPreference: [],
      isLoading: false,
      employmentList: emplList
    };
  }

  componentDidMount() {
    if (this.props.subjectId) {
      this.setState({
        isLoading: true
      });
      this.updateSubjectSummary(this.props.subjectId);
      this.patientImage();
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.subjectId !== this.props.subjectId) {
      if (this.props.subjectId) {
        this.setState({
          isLoading: true
        });
        this.updateSubjectSummary(this.props.subjectId);
        this.patientImage();
      }
    }
  }

  patientImage() {
    getPatientImage(this.props.subjectId)
      .then((response) => {
        let data: any = response.data;
        if (data.success) {
          this.setState({
            documentImage: data.result
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  chunkArray(fullArray: any[], numberOfParts: number) {
    let arrayToWork = fullArray.map((item) => ({
      ...item,
      key: renewFieldNames(item.key)
    }));
    const rest = arrayToWork.length % numberOfParts;
    if (rest) {
      for (let i = 0; i < numberOfParts - rest; i++) {
        arrayToWork.push({ key: '', value: '' });
      }
    }
    let results = [];

    var chunkSize = arrayToWork.length / numberOfParts;
    while (arrayToWork.length) {
      results.push(arrayToWork.splice(0, chunkSize));
    }
    return results;
  }

  updateSubjectSummary(e: any) {
    getSubjectSummary(e)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            patientDetail: response.data.result,
            isLoading: false
          });
        } else {
          console.log('get subject summary success false');
        }
      })
      .catch((error) => {
        console.log('onSubjectSelection error', error);
      });
    getSubjectPreferenceFields(e, this.state.patientFields)
      .then((response) => {
        if (response.data.success) {
          const result: {
            key: string;
            value: string;
          }[] = response.data.result;
          const { scheduleEvent } = this.props;
          const editionalFieldsSources = {
            'Appt Created': scheduleEvent
              ? formattedDateTime(scheduleEvent?.createdDts)
              : '',
            'Appt Modified': scheduleEvent
              ? formattedDateTime(scheduleEvent?.modifiedDts)
              : ''
          };

          const formattedValue = (key: string, rawValue: string) => {
            let value = rawValue;
            if (key === 'Account Balance' || key === 'Copay') {
              value = currencyFormat(rawValue);
            }
            if (key === getPatientTerm('Patient Percent')) {
              value = percentFormat(rawValue);
            }
            if (key === 'Employed') {
              value =
                this.state.employmentList.find(
                  (item) => item.value === +rawValue
                )?.label || '';
            }
            return value;
          };

          const mappedData = this.state.patientFields
            .map((item) => renewFieldNames(item))
            .map((key) => {
              const item = result
                .map((item) => ({ ...item, key: renewFieldNames(item.key) }))
                .find((v) => v.key === key);
              return {
                key: key,
                value: editionalFieldsSources[key]
                  ? editionalFieldsSources[key]
                  : formattedValue(key, item ? item.value : '')
              };
            });
          this.setState({
            subjectPreference: mappedData,
            patientFieldsArr: this.chunkArray(mappedData, 3)
          });
        } else {
          console.log('get subject preference fields success false');
        }
      })
      .catch((error) => {
        console.log('getSubjectPreferenceFields error', error);
      });
  }

  render() {
    let {
      patientDetail,
      patientFieldsArr,
      isLoading,
      isExpand,
      documentImage
    } = this.state;
    let ext =
      documentImage && documentImage.extension
        ? documentImage.extension
        : '.jpg';
    let f = imageFormats.find((s: any) => s.type === ext);
    let documentFormat = f ? f.format : '';
    const image =
      documentImage && documentImage.thumbContent
        ? documentFormat + documentImage.thumbContent
        : '';
    let hasImage = documentImage && documentImage.thumbContent;
    return (
      <div>
        {patientDetail && (
          <div>
            {!isLoading ? (
              <>
                {!this.props.viewMode && (
                  <Row2 borderTop={true} paddingBottom={true}>
                    <label className={'preview-tab-style'}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <container.Picture style={{ marginRight: '2rem' }}>
                          {hasImage ? (
                            <img src={image} alt={documentImage.fileName} />
                          ) : (
                            patientDetail['firstName'] &&
                            patientDetail['firstName'][0] +
                              patientDetail['lastName'][0]
                          )}
                        </container.Picture>
                        <PatientInfoContainer
                          style={{ paddingTop: 0, fontWeight: 'bold' }}
                        >
                          <EllipsisText maxWidth={800}>
                            {patientDisplayName(patientDetail)}
                          </EllipsisText>

                          {/* {highRisk && (
                      <HighRiskContainer>
                        {highRisk ? 'High Risk' : ''}
                      </HighRiskContainer>
                    )} */}
                        </PatientInfoContainer>
                      </div>
                    </label>
                    <ExtendIconContainer
                      top={20}
                      onClick={() => {
                        this.setState({
                          isExpand: !isExpand
                        });
                      }}
                    >
                      {isExpand ? <CircleUpSvg /> : <CircleDownSvg />}
                    </ExtendIconContainer>
                  </Row2>
                )}
                <div
                  style={
                    isExpand || this.props.viewMode
                      ? {
                          display: 'block',
                          marginBottom: '8px'
                        }
                      : { display: 'none' }
                  }
                >
                  <PatientDetailContainer viewMode={this.props.viewMode}>
                    <Row
                      paddingBottom={true}
                      style={{
                        display: 'flex',
                        paddingTop: this.props.viewMode ? 0 : undefined
                      }}
                    >
                      <ApptsKeptContainer>
                        <label>Appts Kept</label>
                        {patientDetail['appointmentCheckedInCount'] &&
                        patientDetail['appointmentCount'] ? (
                          <Info>
                            {patientDetail['appointmentCheckedInCount']}/
                            {patientDetail['appointmentCount']} (
                            {Math.round(
                              (patientDetail['appointmentCheckedInCount'] /
                                patientDetail['appointmentCount']) *
                                100
                            )}
                            %)
                          </Info>
                        ) : (
                          <Info>
                            {patientDetail['appointmentCheckedInCount']}/
                            {patientDetail['appointmentCount']}
                          </Info>
                        )}
                      </ApptsKeptContainer>
                      <ApptsCancelledContainer>
                        <label>Appts Cancelled</label>
                        {patientDetail['appointmentCancelledCount'] &&
                        patientDetail['appointmentCount'] ? (
                          <Info>
                            {patientDetail['appointmentCancelledCount']}/
                            {patientDetail['appointmentCount']} (
                            {Math.round(
                              (patientDetail['appointmentCancelledCount'] /
                                patientDetail['appointmentCount']) *
                                100
                            )}
                            %)
                          </Info>
                        ) : (
                          <Info>
                            {patientDetail['appointmentCancelledCount']}/
                            {patientDetail['appointmentCount']}
                          </Info>
                        )}
                      </ApptsCancelledContainer>
                      <PreferedPhoneContainer>
                        <label>Preferred Phone</label>
                        <Info title={patientDetail['mobile']}>
                          {patientDetail &&
                            patientDetail.preferredPhone &&
                            phoneNumberMask(patientDetail.preferredPhone)}
                        </Info>
                      </PreferedPhoneContainer>
                      <PreferedPhoneContainer style={{ borderRight: 'none' }}>
                        <label>Email</label>
                        <Info
                          style={{ width: 'auto' }}
                          title={patientDetail['email']}
                        >
                          {patientDetail['email']}
                        </Info>
                      </PreferedPhoneContainer>
                    </Row>
                    <AntRow
                      style={{ borderTop: 'solid #e6e6e6 0.5px' }}
                      gutter={20}
                    >
                      <Col
                        span={8}
                        style={{ borderRight: 'solid #e6e6e6 0.5px' }}
                      >
                        {patientFieldsArr[0].map(
                          (field: any, index: number) => (
                            <ColumnBoxItem key={`${field.key}${index}`}>
                              <label>{field.key}</label>
                              <Info>
                                <EllipsisText>
                                  {TFtoTF[field.value]
                                    ? TFtoTF[field.value]
                                    : field.value}
                                </EllipsisText>
                              </Info>
                            </ColumnBoxItem>
                          )
                        )}
                      </Col>
                      <Col
                        span={8}
                        style={{ borderRight: 'solid #e6e6e6 0.5px' }}
                      >
                        {patientFieldsArr[1].map(
                          (field: any, index: number) => (
                            <ColumnBoxItem key={`${field.key}${index}`}>
                              <label>{field.key}</label>
                              <Info>
                                <EllipsisText>
                                  {TFtoTF[field.value]
                                    ? TFtoTF[field.value]
                                    : field.value}
                                </EllipsisText>
                              </Info>
                            </ColumnBoxItem>
                          )
                        )}
                      </Col>
                      <Col span={8}>
                        {patientFieldsArr[2].map(
                          (field: any, index: number) => (
                            <ColumnBoxItem key={`${field.key}${index}`}>
                              <label>{field.key}</label>
                              <Info>
                                <EllipsisText>
                                  {TFtoTF[field.value]
                                    ? TFtoTF[field.value]
                                    : field.value}
                                </EllipsisText>
                              </Info>
                            </ColumnBoxItem>
                          )
                        )}
                      </Col>
                    </AntRow>
                  </PatientDetailContainer>
                </div>
              </>
            ) : (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  className={'loader'}
                  style={{ height: '35px', width: '35px' }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default PatientDetail;

import React, { FC } from 'react';

import PatientHistory from 'src/Activities/Schedule/PatientHistory';

interface IProps {
  patientId: number;
}

interface IProps {}

const Component: FC<IProps> = ({ patientId }: IProps) => {
  return <PatientHistory patientId={patientId} pageLength={12} />;
};

export default Component;

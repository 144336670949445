import { store } from 'src';
import {
    getPatientsInsurances
} from '../../Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import { expirateInsurances } from './AppointmentPopup';
import * as patientsAlertsActions from 'src/Activities/Counseling/Dashboard/Alerts/store/Actions';
import { IAlert } from 'src/Activities/Counseling/Dashboard/Alerts/store/types';
import _ from 'lodash';
import moment from 'moment';

export const getAlerts = async (patients: any[], locationIndex?: number) => {
    const [insurances, patientAlerts] = await Promise.all([getInsurancesData(patients), getPatientAlertsData(patients, locationIndex)])
    return {
        insurances: insurances || {},
        patientAlerts,
        isUnderAge: calculateIsUnderAge(patients),
        patients
    }
}

export const getPatientAlertsData = async (patients: any[], locationIndex?: number) => {
    const patientIds = patients.map((v: any) => v.subjectId);
    const alertTypes = store.getState().patientsAlerts.patientAlertTypes;
    if (_.isEmpty(alertTypes) || _.isNull(alertTypes)) {
        await patientsAlertsActions.getAlertTypes();
    }
    const alertsData = await patientsAlertsActions.get(
        patientIds,
        false
    );
    const data = patientsAlertsActions.returnAlertsByLocation(
        patientIds,
        locationIndex || 1,
        alertsData
    );
    const grouped = _.groupBy(data, (v: IAlert) => v.patient.id);
    Object.entries(grouped).forEach(([key, value]) => {
        grouped[key] = _.sortBy(value, 'patientAlertTypeId')
    });
    return grouped
}

export const getInsurancesData = async (patients: any[]) => {
    const patientIds = patients.map((v: any) => v.subjectId);
    const res = await getPatientsInsurances(patientIds);
    if (res) {
        const { isPrimaries, isSecondaries } = expirateInsurances(res);
        if (isPrimaries) {
            const content = isPrimaries.filter((v) => {
                if (!v || !v.insuranceCompanyId) return false;
                const patient = patients.find(
                    (val) => `${val.subjectId}` === `${v.patientId}`
                );
                if (!patient) return false;
                return true;
            });
            if (content.length !== 0) {
                const grouped = _.groupBy(content, (v) => v.patientId);
                return grouped;
            }
        }
        if (isSecondaries) {
            const content = isSecondaries.filter((v) => {
                if (!v || !v.insuranceCompanyId) return false;
                const patient = patients.find(
                    (val) => `${val.subjectId}` === `${v.patientId}`
                );
                if (!patient) return false;
                return true;
            });
            if (content.length !== 0) {
                const grouped = _.groupBy(content, (v) => v.patientId);
                return grouped;
            }
        }
    }
    return;
};

const ageUnderRule = (dob: any, age: number) => {
    let ageStr = moment(dob, 'MM/DD/YYYY').fromNow().split(' ')[0];
    return parseInt(ageStr) < age;
};

type PatientId = string

export const calculateIsUnderAge = (patients: any[]): Record<PatientId, boolean> => {
    const minorAlert = store.getState().user.preferences.minorAlert;
    if (!minorAlert || minorAlert?.value === 'True') {
        const data = {}
        patients.forEach((v: any) => {
            if (ageUnderRule(v.dob, 18)) {
                data[v.subjectId] = true
            }
        })
        return data
    }
    return {}
}
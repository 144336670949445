import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { Tooltip, Row } from 'antd';

import StatusLabel from 'src/Framework/Controls/StatusLabel';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import Table from 'src/Framework/Controls/Table';
import {
  IColumn,
  ColumnIdentifier,
  ColumnGlobalTitle
} from 'src/Framework/Controls/Table/types';
import RemoveButton from 'src/Framework/Controls/Table/RemoveButton';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { patientDisplayName } from 'src/Framework/util/format';
import { Alert } from 'src/Framework/Common/Notification';
import { formattedLTTime } from 'src/Framework/Shared/Shared';
import test_ids from 'src/tests-script/pages/Schedule/GroupApptPopup/ApptMembers';

import {
  ActivityPath,
  gotoClient
} from 'src/App/ActivityPanel/Store/ActivityActions';
import StatusIcon from 'src/App/Hippa/Components/StatusIcon';
import { isPrivacyPolicyEnabled } from 'src/App/Hippa/Components/utils';
import { isEventLate } from 'src/Activities/Schedule/Utils/Utils';
import { CompletedForm } from 'src/Activities/Schedule/Svg/CompletedForm';
import { IncompleteForm } from 'src/Activities/Schedule/Svg/IncompleteForm';
import {
  IApptResponse,
  IPatientInfo
} from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';

import RenderColumn from './RenderColumn';
import Form from '../Form';
import RemovePatient from '../RemovePatient';

import { HighRiskContainer } from 'src/Activities/Schedule/Popups/ScheduleDetailPopup/ScheduleDetailPopup_Styles';
import { TextEllipsisSpan } from 'src/Activities/Schedule/Main/ScheduleCol/ScheduleOne/styled';

import {
  GroupMembersTable,
  DPContainer,
  FormSpan,
  // CloseSpan,
  FormIconsSpan,
  FormsLabel,
  EllipsisText
} from './styled';

// import { CloseSvg } from '../../../Svg/CloseSvg';
// import { APIHandler } from 'src/Framework/Communication/ServerProxy';
// import { ApiUrl } from '../../../Store/ScheduleActions/ScheduleDetailActions/ApiUrl';

interface IProps {
  appointment: IApptResponse;
  onStatusUpdate: any;
  date: any;
  setSelectedPatient: (patient: number, value: any) => void;
  currentPatient?: IPatientInfo | null;
  onDeleteMember: (patient: IPatientInfo, otherDetails?: any) => Promise<any>;
}

export const containerId = 'group-members';

// const onClickRemoveForm = async (f: any) => {
//   if (f.record) {
//     const form = {
//       ...f,
//       inactive: true,
//       record: {
//         ...f.record,
//         inactive: true
//       }
//     };
//     try {
//       const response = await APIHandler.AxiosInstance.post(
//         ApiUrl.REMOVE_FORM,
//         {
//           form
//         },
//         { withCredentials: true }
//       );
//       let data: any = response.data;
//       if (data.success) {
//         //TODO: need to check this functionality
//         // let newEventPayload = eventInfo;
//         // store.dispatch(addScheduleEvent(newEventPayload));
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }
// };

export const formsContent = (subjectId: any, appointment: IApptResponse) => {
  const patientForms = appointment.patientForms;
  const formsList =
    patientForms?.[subjectId]?.filter((v: any) => !v.inactive) || [];

  const isFormFilled = (formRecord: any) => {
    return formRecord.viaPortal
      ? formRecord.patientComplete
      : formRecord.completed;
  };

  return (
    <div>
      <FormsLabel>FORMS</FormsLabel>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '8px'
        }}
      >
        {formsList && formsList.length !== 0 ? (
          formsList.map((item: any) => {
            return (
              <FormSpan completed={isFormFilled(item.record)}>
                <FormIconsSpan>
                  {isFormFilled(item.record) ? (
                    <CompletedForm />
                  ) : (
                    <IncompleteForm />
                  )}
                </FormIconsSpan>
                <TextEllipsisSpan
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'auto',
                    paddingRight: '8px'
                  }}
                >
                  {item.name}
                </TextEllipsisSpan>
                {/*disabling removing functionality even for unfilled forms*/}
                {/*{!isFormFilled(item.record) && (*/}
                {/*  <CloseSpan onClick={() => onClickRemoveForm(item)}>*/}
                {/*    <CloseSvg />*/}
                {/*  </CloseSpan>*/}
                {/*)}*/}
              </FormSpan>
            );
          })
        ) : (
          <span>No forms</span>
        )}
      </div>
    </div>
  );
};

const GroupMembersComponent = ({ ...props }: IProps) => {
  const [isPolicyEnabled] = useState(isPrivacyPolicyEnabled());
  const { currentPatient, onDeleteMember, appointment } = props;
  const { scheduleEvent } = appointment;
  const [removeLoader, setRemoveLoader] = useState(false);
  const patientList = Object.values(appointment.patientsInfo);
  const patients = patientList
    .filter((v) => !appointment.cancelledPatientsInfo[v.subjectId])
    .map((item) => item);

  const date =
    moment(scheduleEvent.scheduleEventDateTime).format('YYYY-MM-DD') +
    'T00:00:00';

  const renderSvgFromAction = (
    action: string,
    subjectId: any,
    patient: IPatientInfo
  ) => {
    const commonProps = {
      iconScale: 1.4,
      disableBackground: true,
      size: 28,
      tooltipProps: {
        getPopupContainer: () => document.getElementById(containerId)!
      }
    };
    const status = appointment.patientStatus[subjectId];
    switch (action) {
      case 'late':
        return (
          <ButtonIcon
            isButton={true}
            label="late"
            toggled={!status.checkedIn && isEventLate(scheduleEvent)}
            id={test_ids.statuses.late}
            name={ICONS_LIST.late}
            active={!status.checkedIn && isEventLate(scheduleEvent)}
            {...commonProps}
          />
        );
      case 'arrived':
        return (
          <ButtonIcon
            isButton={true}
            label="arrived"
            toggled={status.checkedIn}
            id={test_ids.statuses.arrived}
            name={ICONS_LIST.arrived}
            active={status.checkedIn}
            {...commonProps}
          />
        );
      case 'ready':
        return (
          <ButtonIcon
            isButton={true}
            label="ready"
            toggled={status.ready}
            id={test_ids.statuses.ready}
            name={ICONS_LIST.ready}
            active={status.ready}
            {...commonProps}
          />
        );
      case 'admitted':
        return (
          <ButtonIcon
            isButton={true}
            label="admitted"
            toggled={status.admit}
            id={test_ids.statuses.admitted}
            name={ICONS_LIST.admitted}
            active={status.admit}
            {...commonProps}
          />
        );
      case 'discharged':
        return (
          <ButtonIcon
            isButton={true}
            label="discharged"
            toggled={status.discharged}
            id={test_ids.statuses.discharged}
            name={ICONS_LIST.check}
            active={status.discharged}
            {...commonProps}
          />
        );
      case 'delete':
        return (
          <RemovePatient
            appointment={appointment}
            patientId={subjectId}
            onRemove={onClickDeleteSubject}
          >
            {({ open, setOpen }) => (
              <RemoveButton
                id={test_ids.removeButton}
                tooltip={'Remove ' + getPatientTerm('Student')}
                removeAction={async () => {
                  if (status.checkedIn === false) {
                    setOpen(true);
                  } else {
                    Alert(
                      'warning',
                      'Warning',
                      'You cannot remove checked in participant from appointment.'
                    );
                  }
                }}
                buttonIconProps={{
                  changeColorOnHover: false,
                  disableBackground: true,
                  active: open
                }}
              />
            )}
          </RemovePatient>
        );
      case 'link':
        return (
          <Tooltip
            trigger="hover"
            getPopupContainer={() => document.getElementById(containerId)!}
            placement="bottom"
            title={'' + getPatientTerm('Subject') + ' Info'}
            overlayStyle={{ zIndex: 30000 }}
          >
            <ButtonIcon
              isButton={true}
              label={getPatientTerm('Subject') + ' Info'}
              id={test_ids.patientLink}
              name={ICONS_LIST.user}
              disableBackground={true}
              size={24}
              onClick={() =>
                gotoClient(subjectId, ActivityPath.CLIENT_DEMOGRAPHICS, {})
              }
            />
          </Tooltip>
        );
      case 'note':
        return (
          <Tooltip
            getPopupContainer={() => document.getElementById(containerId)!}
            trigger="hover"
            placement="bottom"
            title="Create Note"
            overlayStyle={{ zIndex: 30000 }}
          >
            <ButtonIcon
              isButton={true}
              label="Create Note"
              id={test_ids.createNote}
              name={ICONS_LIST.apptNote}
              disableBackground={true}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                gotoClient(subjectId, ActivityPath.CREATE_NOTE, {
                  appointmentId: scheduleEvent.appointmentId
                });
              }}
            />
          </Tooltip>
        );
      case 'forms':
        return (
          <Form
            appointment={appointment}
            patient={{ ...patient, patientId: patient.subjectId }}
            formsContent={formsContent}
          />
        );
      default:
        return <div />;
    }
  };

  const getInfoAction = (action: string, patient: IPatientInfo) => {
    const patientStatus = appointment.patientStatus[patient.subjectId];
    if (!patientStatus) return null;
    switch (action) {
      case 'name':
        return (
          <span style={{ display: 'flex', flexDirection: 'row' }}>
            <DPContainer style={{ marginRight: '8px' }}>
              {patient.firstName && patient.firstName[0]}
              {patient.lastName && patient.lastName[0]}
            </DPContainer>
            <span style={{ fontSize: '12px', lineHeight: '24px' }}>
              {patient && patient.firstName + ' ' + patient.lastName}
            </span>
            {/* {patient && patient.highRisk && (
              <HighRiskContainer style={{ marginLeft: '5px' }}>
                {patient && patient.highRisk ? 'High Risk' : ''}
              </HighRiskContainer>
            )} */}
          </span>
        );
      case 'arrived':
        return patientStatus.checkedIn
          ? formattedLTTime(patientStatus.checkedInTime)
          : 'noshow';
      case 'ready':
        return patientStatus.ready
          ? formattedLTTime(patientStatus.readyTime)
          : 'noshow';
      case 'admitted':
        return patientStatus.admit
          ? formattedLTTime(patientStatus.admitTime)
          : 'noshow';
      case 'discharged':
        return patientStatus.discharged
          ? formattedLTTime(patientStatus.dischargeTime)
          : 'noshow';
      default:
        return 'noshow';
    }
  };

  const onClickDeleteSubject = async (
    patient: IPatientInfo,
    otherDetails?: any
  ) => {
    setRemoveLoader(true);
    const res = await onDeleteMember(patient, otherDetails);
    setRemoveLoader(false);
    return res;
  };
  const columns = useMemo(() => {
    const data: IColumn[] = [
      {
        title: ColumnGlobalTitle.accountNumber,
        key: 'patientId',
        flex: '80px',
        identifier: ColumnIdentifier.patientId
      },
      {
        title: 'Name',
        key: 'name',
        flex: 'auto',
        style: {
          padding: '6px 24px'
        }
      },
      {
        title: <StatusLabel statusCode={'Late'} />,
        key: 'late',
        flex: '85px',
        centered: true
      },
      {
        title: <StatusLabel statusCode={'Arrived'} />,
        key: 'arrived',
        flex: '85px',
        centered: true
      },
      {
        title: <StatusLabel statusCode={'Ready'} />,
        key: 'ready',
        flex: '85px',
        centered: true
      },
      {
        title: <StatusLabel statusCode={'Admitted'} />,
        key: 'admitted',
        flex: '85px',
        centered: true
      },
      {
        title: <StatusLabel statusCode={'Discharged'} />,
        key: 'discharged',
        flex: '85px',
        centered: true
      },
      {
        title: '',
        key: 'delete',
        flex: '42px',
        centered: true
      },
      {
        title: '',
        key: 'hippo',
        flex: '42px',
        centered: true
      },
      {
        title: '',
        key: 'note',
        flex: '42px',
        centered: true
      },
      {
        title: '',
        key: 'forms',
        flex: '42px',
        centered: true
      },
      {
        title: '',
        key: 'link',
        flex: '42px',
        centered: true
      }
    ];
    if (!isPolicyEnabled) {
      return data.filter((v) => v.key !== 'hippo');
    }
    return data;
  }, [isPolicyEnabled]);
  return (
    <div id={containerId}>
      {removeLoader && (
        <AbsoluteLoader backgroundColor="rgba(255,255,255,0.6)" />
      )}
      <GroupMembersTable style={{ height: '100%' }} id={test_ids.CONTAINER}>
        <Table
          containerId={test_ids.tableContainer}
          bodyContainerId={test_ids.tableContainer}
          dynamicClassNameRow={{
            className: 'selected-patient',
            onEnabled: (value: any) =>
              value.patient.subjectId === currentPatient?.subjectId
          }}
          onClickRow={(i: number, value: any) => {
            props.setSelectedPatient(value.patientId, value.patient);
          }}
          data={patients.map((patient) => {
            const renderProps = {
              getInfoAction,
              renderSvgFromAction,
              date,
              onStatusUpdate: props.onStatusUpdate
            };
            const arr = [
              'late',
              'arrived',
              'ready',
              'admitted',
              'discharged',
              'delete',
              'note',
              'forms',
              'link'
            ];
            const obj = {};
            arr.forEach((colId) => {
              obj[colId] = (
                <RenderColumn
                  appointment={appointment}
                  params={{
                    column: {
                      colId
                    },
                    data: patient
                  }}
                  {...renderProps}
                />
              );
            });
            return {
              ...patient,
              patientId: patient.subjectId,
              name: (
                <Row align="middle">
                  <DPContainer style={{ marginRight: '8px' }}>
                    {patient.firstName && patient.firstName[0]}
                    {patient.lastName && patient.lastName[0]}
                  </DPContainer>
                  <EllipsisText>{patientDisplayName(patient)}</EllipsisText>
                  {patient.highRisk && (
                    <HighRiskContainer style={{ marginLeft: '5px' }}>
                      High Risk
                    </HighRiskContainer>
                  )}
                </Row>
              ),
              ...obj,
              hippo: (
                <div onClick={(e) => e.stopPropagation()}>
                  <StatusIcon
                    statusModal={true}
                    patientId={patient.subjectId}
                    useIcon={true}
                    clinicId={appointment.scheduleEvent.clinicId}
                  />
                </div>
              ),
              patient,
              id: patient.subjectId
            };
          })}
          verticalItemPadding={6}
          columns={columns}
        />
      </GroupMembersTable>
    </div>
  );
};
export default GroupMembersComponent;

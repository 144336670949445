import React from 'react';
import { Button, Checkbox } from 'antd';
import Popover from 'src/Framework/Controls/Popover';

import Table from 'src/Framework/Controls/Table';
import Icon from 'src/Framework/Controls/Icon';

import { TimeOptionListLayout, ButtonContainer } from './TimeOptionList_Styles';

import { EyeIconSnapShotSvg } from 'src/Framework/Common/Svg/EyeIconSnapShotSvg';

import CalendarSnap from '../../CalendarSnapShot/CalendarSnap';
import { ClosedLabel } from '../../ScheduleEditor_Styles';
import test_ids from 'src/tests-script/pages/Schedule/Scheduling';
import {
  getProviderByCode,
  providerDisplayName
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { IColumn } from 'src/Framework/Controls/Table/types';
import { getReasonByCode } from 'src/Framework/Controls/Selectors/ReasonSelector/utils';

interface IState {
  selectedOption: string;
  openClinicFilter: boolean;
  currentPage: number;
}

interface IProps {
  nextAvailableOptions: {
    Event: {
      uid: string;
      startTime: string;
      endTime: string;
      startTimeString: string;
      endTimeString: string;
      appointmentDateString: string;
      appointmentTimeString: string;
      providerCode: string;
      providerTitle: string;
      providerCodes: string[] | null;
      providerName: string;
      clinicName: string;
      clinicCode: string;
      reasonCode: string;
      weekDay: string;
      clinicIsOpen: boolean;
      inReservation: boolean;
    };
  }[];
  providers: any;
  clinicOptions: any;
  onSelectedDate: (event: any) => void;
}

const pageLength = 15;

class TimeOptionList extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedOption: '',
      openClinicFilter: false,
      currentPage: 0
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.nextAvailableOptions !== prevProps.nextAvailableOptions) {
      this.setState({
        currentPage: 0
      });
    }
  }

  onChange(idx: any) {
    this.props.onSelectedDate(this.props.nextAvailableOptions[idx]);
  }

  onChangeOpenClinicFilter = () => {
    this.setState({
      openClinicFilter: !this.state.openClinicFilter
    });
  };

  popOverContent(searchLine: any) {
    return (
      <CalendarSnap
        clinicOptions={this.props.clinicOptions}
        providers={searchLine.PROVIDER.map((item: string) => {
          const currentProvider = getProviderByCode(item);
          return {
            value: item,
            gender: currentProvider?.gender,
            label: providerDisplayName(currentProvider?.providerId!),
            provider: currentProvider
          };
        })}
        // providers={this.props.providers}
        searchLine={searchLine}
        timeLayerMode={true}
      />
    );
  }

  render() {
    const { nextAvailableOptions } = this.props;
    const { openClinicFilter } = this.state;
    const data = openClinicFilter
      ? nextAvailableOptions.filter((v) => v.Event.clinicIsOpen)
      : nextAvailableOptions;
    const list = data.map((v) => v.Event);
    const columns: IColumn[] = [
      {
        title: 'DATE',
        key: 'date',
        flex: '112px'
      },
      {
        title: 'TIME',
        key: 'time',
        flex: '140px'
      },
      {
        title: 'APPT TYPE',
        key: 'apptType',
        flex: '90px',
        ellipsis: true
      },
      {
        title: 'CLINIC',
        key: 'clinic',
        flex: '100px',
        ellipsis: true
      },
      {
        title: 'PROVIDER',
        key: 'providerCodes',
        flex: 'auto',
        ellipsis: true
      },
      {
        title: '',
        key: 'reserved',
        flex: '55px',
        centered: true
      },
      {
        title: '',
        key: 'eye',
        flex: '45px',
        centered: true
      },
      {
        title: (
          <Checkbox
            id={test_ids.clinicOpenCheckbox}
            checked={this.state.openClinicFilter}
            onChange={() => this.onChangeOpenClinicFilter()}
          >
            Clinic Open
          </Checkbox>
        ),
        key: 'select',
        flex: '105px',
        centered: true
      }
    ];
    return (
      <TimeOptionListLayout className="custom-checkbox">
        <Table
          containerId={test_ids.schedulingTable}
          bodyContainerId={test_ids.schedulingTable}
          innerPagination={{
            pageLength: pageLength,
            pageNumber: this.state.currentPage + 1
          }}
          paginationControls={{
            total: list.length,
            pageLength: pageLength,
            startIndex: this.state.currentPage * pageLength,
            onChangePage: (newPageNumber) => {
              this.setState({
                currentPage: newPageNumber
              });
            }
          }}
          absoluteContainer={true}
          horizontalItemPadding={4}
          data={list.map((v, index) => ({
            ...v,
            reserved: v.inReservation && (
              <Icon name="reservation" tooltip="Reserved" />
            ),
            select: (
              <Button
                className="custom-button"
                id={test_ids.selectButton(v.uid)}
                onClick={() => this.onChange(index)}
              >
                Select
              </Button>
            ),
            date:
              v.appointmentDateString.split(' ')[0] +
              ' ' +
              v.appointmentDateString.split(' ')[1].substring(0, 3),
            time: v.appointmentTimeString,
            apptType: getReasonByCode(v.reasonCode)?.name,
            clinic: (
              <div>
                <span>{v.clinicName}</span>
                {!v.clinicIsOpen && (
                  <ClosedLabel style={{ margin: 0, fontSize: '10px' }}>
                    Clinic closed
                  </ClosedLabel>
                )}
              </div>
            ),
            providerCodes: v.providerCodes
              ? v.providerCodes.join(', ')
              : v.providerCode,
            eye: (
              <ButtonContainer>
                <Popover
                  id="popover-content"
                  trigger={'click'}
                  content={
                    <div
                      onMouseDown={(e: any) => e.preventDefault()}
                      style={
                        v.providerCodes
                          ? {
                              width: `${
                                (v.providerCodes.length - 1) * 200 + 350
                              }px`,
                              height: '290px'
                            }
                          : { width: '350px', height: '290px' }
                      }
                    >
                      {this.popOverContent(data[index])}
                    </div>
                  }
                >
                  <span
                    style={{
                      paddingRight: '1rem',
                      cursor: 'pointer'
                    }}
                  >
                    <EyeIconSnapShotSvg />
                  </span>
                </Popover>
              </ButtonContainer>
            )
          }))}
          columns={columns}
        />
      </TimeOptionListLayout>
    );
  }
}

export default TimeOptionList;

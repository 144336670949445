import _ from 'lodash';
import { defaultPatientInfoData } from './store';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { IPatientContactsSimpleDto } from './store/types';

export const newPatient = _.cloneDeep(defaultPatientInfoData);

export const newPatientContact: IPatientContactsSimpleDto = {
  id: 0,
  contactTypeId: null,
  emergencyContact: false,
  clinicIds: [],
  otherParty: {
    id: 0,
    lastName: '',
    firstName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    birthDate: '',
    sex: '',
    email: '',
    mi: '',
    suffix: '',
    comment: '',
    zip: '',
    preventMppLogin: false,
    preferredName: '',
    typePreferredPhoneId: null,
    homePhone: '',
    workPhone: '',
    cellPhone: '',
    pager: ''
  },
  otherPartyId: null,
  patientId: null,
  relationshipId: null,
  responsibleParty: false
};

export const patientInfoUI = {
  id: {
    title: 'Account #',
    required: false,
    value: ''
  },
  otherId: {
    title: 'Other ID',
    required: false,
    value: '',
    maxlength: 20
  },
  typePreferredPhoneId: {
    title: 'Preferred Phone Number',
    required: false,
    value: '2'
  },
  email: {
    title: 'Email',
    required: true,
    value: '',
    maxlength: 150
  },
  firstName: {
    title: 'First Name',
    required: true,
    value: '',
    maxlength: 30,
    hasValidation: false,
    validationError: ''
  },
  suffix: {
    title: 'Suffix',
    required: false,
    value: '',
    maxlength: 10
  },
  county: {
    title: 'County',
    required: false,
    value: '',
    maxlength: 20
  },
  middleInitial: {
    title: 'MI',
    required: false,
    value: '',
    maxlength: 1
  },
  lastName: {
    title: 'Last Name',
    required: true,
    value: '',
    maxlength: 30
  },
  nickName: {
    title: 'Preferred Name',
    required: false,
    value: '',
    maxlength: 80
  },
  address: {
    title: 'Address',
    required: true,
    value: '',
    maxlength: 40
  },
  address2: {
    title: 'Address 2',
    required: false,
    value: '',
    maxlength: 40
  },
  city: {
    title: 'City',
    required: true,
    value: '',
    maxlength: 60
  },
  state: {
    title: 'State',
    required: true,
    value: '',
    maxlength: 2
  },
  zip: {
    title: 'Zip',
    required: true,
    value: '',
    maxlength: 20
  },
  isActive: {
    title: 'Active',
    required: false,
    value: false
  },
  deceased: {
    title: 'Deceased',
    required: false,
    value: false
  },
  exempt: {
    title: 'Exempt from Reporting',
    required: false,
    value: false
  },
  high: {
    title: 'High Risk',
    required: false,
    value: false
  },
  birth: {
    title: 'Birth Date',
    required: true,
    value: undefined
  },
  genderIdentity: {
    title: 'Gender Identity',
    required: false,
    value: []
  },
  sex: {
    title: 'Sex at Birth',
    required: true,
    value: undefined
  },
  typePreferredPronounId: {
    title: 'Pronoun',
    required: false,
    value: undefined
  },
  ethnicity: {
    title: 'Ethnicity',
    required: false,
    value: undefined
  },
  race: {
    title: 'Race',
    required: false,
    value: []
  },
  typeMaritalStatusId: {
    title: 'Marital Status',
    required: true,
    value: 2
  },
  typeEmployedId: {
    title: 'Employment',
    required: true,
    value: 3
  },
  employerCode: {
    title: 'Company Name',
    required: true,
    value: undefined
  },
  department: {
    title: 'Department',
    required: false,
    value: '',
    maxlength: 100
  },
  providerId: {
    title: 'Provider',
    required: false,
    value: undefined
  },
  typeBillingId: {
    title: 'Billing Method',
    required: true,
    value: 2
  },
  feeScheduleId: {
    title: 'Fee Schedule',
    required: true,
    value: 1
  },
  chartNo: {
    title: 'Chart Number',
    required: false,
    value: '',
    maxlength: 20
  },
  cellPhone: {
    title: 'Cell',
    value: '',
    required: false
  },
  homePhone: {
    title: 'Home',
    value: '',
    required: false
  },
  pager: {
    title: 'Alt',
    value: '',
    required: false
  },
  workPhone: {
    title: 'Work',
    value: '',
    required: false
  },
  billTo: {
    title: 'Bill to',
    value: 'Patient',
    required: true
  },
  typeSexualOrientationId: {
    title: 'Sexual orientation',
    value: undefined,
    required: false
  },
  otherInfo: {
    title: 'Sexual orientation other info',
    value: '',
    required: false
  },
  comment: {
    title: 'Memo',
    value: '',
    required: false
  }
};

export const studentInfoUI = {
  typeStudentStatusId: {
    title: 'Campus Status',
    required: false,
    value: undefined
  },
  major: {
    title: 'Major',
    required: false,
    value: undefined,
    maxlength: null
  },
  standing: {
    title: 'Standing',
    required: false,
    value: undefined
  },
  typeEligibilityId: {
    title: 'Eligibility',
    required: true,
    value: 2
  },
  schoolName: {
    title: 'School Name',
    required: false,
    value: undefined
  },
  typeClassId: {
    title: 'Class',
    required: false,
    value: undefined
  },
  enrolmentDate: {
    title: 'Enrollment Date',
    required: false,
    value: undefined
  },
  typeResidencyId: {
    title: 'Residence',
    required: false,
    value: undefined
  },
  typeSportId: {
    title: 'Primary Sport',
    required: false,
    value: undefined
  },
  groupId: {
    title: 'Group',
    required: false,
    value: undefined
  },
  belongsTo: {
    title: 'Belongs To',
    required: false,
    value: []
  },
  permAddress: {
    title: 'Permanent Address',
    required: false,
    value: '',
    maxlength: 40
  },
  permAddress2: {
    title: 'Permanent Address 2',
    required: false,
    value: '',
    maxlength: 40
  },
  permCity: {
    title: 'City',
    required: false,
    value: '',
    maxlength: 60
  },
  permState: {
    title: 'State',
    required: false,
    value: '',
    maxlength: 2
  },
  permZip: {
    title: 'Zip',
    required: false,
    value: '',
    maxlength: 20
  },
  campusAddress: {
    title: 'Campus Address 1',
    required: false,
    value: '',
    maxlength: 40
  },
  campusAddress2: {
    title: 'Campus Address 2',
    required: false,
    value: '',
    maxlength: 40
  },
  languages: {
    title: getPatientTerm('Patient Languages'),
    required: false,
    value: []
  },
  preferredLanguage: {
    title: 'Preferred Language',
    required: false,
    value: undefined
  },
  foreignStudent: {
    title: 'International Student',
    required: false,
    value: undefined
  },
  visaType: {
    title: 'Visa Type',
    required: false,
    value: '',
    maxlength: 3
  },
  campusHousingId: {
    title: 'Residence Hall',
    required: false,
    value: null
  },
  campusHousingRoom: {
    title: 'Room ',
    required: false,
    value: ''
  },
  veteranStatus: {
    title: 'Veteran status',
    required: false,
    value: false
  },
  citizenship: {
    title: 'Citizenship',
    required: false,
    value: false
  },
  refugeeStatus: {
    title: 'Refugee Status',
    required: false,
    value: false
  }
};

import React from 'react';

import MultipleSelector from 'src/Framework/Controls/Selectors/MultipleSelector';

import {
  monthlyRecurrancePayloadBuilder,
  monthlySelectedDates,
  monthlyRecurranceStateBuilder
} from 'src/Activities/Schedule/Utils/Utils';

import { ErrorBlock } from 'src/Activities/Schedule/Popups/BlockReservationPopup/BlockReservationPopup_Styles';

interface IState {
  showDayPicker: any;
  days: any;
}

interface IProps {
  onMonthDaySelect: any;
  monthlyString: string;
  error: boolean;
  id?: string;
}

class MonthlyRecurranceDaySelector extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showDayPicker: false,
      days: monthlyRecurranceStateBuilder(this.props.monthlyString)
    };
  }

  onChange = (values: string[]) => {
    const { days } = this.state;
    days.forEach((day: any) => {
      const val = values.find((v) => v === day.value);
      day.isSelected = val ? 1 : 0;
    });
    this.setState({
      days: days
    });
    let payloadString = monthlyRecurrancePayloadBuilder(days);
    this.props.onMonthDaySelect(
      payloadString,
      days[31].isSelected ? true : false
    );
  };

  render() {
    let selectedDates = monthlySelectedDates(this.state.days);
    return (
      <>
        <MultipleSelector
          id={this.props.id || ''}
          label="On Day"
          selectProps={{
            star: false,
            avoidOptionsSorting: true
          }}
          ellipsisTextMaxWidth={160}
          value={selectedDates}
          options={this.state.days}
          onChange={(days) => {
            this.onChange(days);
          }}
        />
        {this.props.error && (
          <ErrorBlock error={this.props.error}>Please Select a day</ErrorBlock>
        )}
      </>
    );
  }
}

export default MonthlyRecurranceDaySelector;

import React, { FC } from 'react';
import { Row, Col } from 'antd';

import Icon from 'src/Framework/Controls/Icon';
import Table from 'src/Framework/Controls/Table';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import Collapse from 'src/Framework/Controls/CollapseA11y';
import { getClinicById } from 'src/Framework/Controls/Selectors/ClinicSelector';
import { providerDisplayName } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import {
  formattedDate,
  formattedTime,
  uppercaseFirstLatter
} from 'src/Framework/Shared/Shared';

import {
  IOutreachSimpleDto,
  IRegistrant
} from 'src/Activities/Home/Outreach/store/types';
import Attachment from 'src/Activities/Home/Outreach/Notes/Attachment';
import { useGroupType } from 'src/App/Admin/Pages/GroupTypes/store/Hooks/useGroupType';

import {
  Container,
  InfoContainer,
  InfoTitle,
  InfoData,
  MainContainer,
  Title,
  TableContainer
} from './styled';

const { Panel } = Collapse;

export interface IProps {
  id: number;
  registrants?: IRegistrant[];
  data?: IOutreachSimpleDto | null;
  onClose?: () => any;
  onEdit?: () => any;
}

const Component: FC<IProps> = ({ registrants, data }: IProps) => {
  const classes = useGroupType('typeClass');
  return (
    <Container>
      {data && registrants && (
        <>
          <InfoContainer>
            <Row gutter={24} style={{ marginBottom: 10 }}>
              <Col>
                <InfoTitle>Audience</InfoTitle>
                <InfoData>{data.audience}</InfoData>
              </Col>
              <Col>
                <InfoTitle>Date</InfoTitle>
                <InfoData>{formattedDate(data.startDateTime)}</InfoData>
              </Col>
              <Col>
                <InfoTitle>Time</InfoTitle>
                <InfoData>{`${formattedTime(
                  data.startDateTime
                )} - ${formattedTime(data.endDateTime)}`}</InfoData>
              </Col>
              <Col>
                <InfoTitle>Location</InfoTitle>
                <InfoData>{data.location}</InfoData>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col>
                <InfoTitle>Type</InfoTitle>
                <InfoData>
                  {uppercaseFirstLatter(data.registrationType)}
                </InfoData>
              </Col>
              <Col>
                <InfoTitle>Hosting Clinic(s)</InfoTitle>
                <InfoData>
                  {data.hostingClinicsIds
                    .map((v) => getClinicById(v)?.name)
                    .join(', ')}
                </InfoData>
              </Col>
              <Col>
                <InfoTitle>Presenting provider(s)</InfoTitle>
                <InfoData>
                  {data.presentingProviderIds
                    .map((v) => providerDisplayName(v))
                    .join(', ')}
                </InfoData>
              </Col>
            </Row>
          </InfoContainer>
          <MainContainer>
            <Collapse uppercaseHeader={true} icon="roundIcon">
              <Panel key="attendance" header={<Title>Attendance</Title>}>
                <Row gutter={16}>
                  <Col tabIndex={0}>
                    <InfoTitle>Expected</InfoTitle>
                    <InfoData>{data.expectedAttendance}</InfoData>
                  </Col>
                  <Col tabIndex={0}>
                    <InfoTitle>Actual</InfoTitle>
                    <InfoData>{data.actualAttendance}</InfoData>
                  </Col>
                </Row>
              </Panel>
              <Panel key="notes" header={<Title>Comments</Title>}>
                <Row style={{ marginBottom: 10 }}>
                  <Col tabIndex={0}>
                    <InfoTitle>Pre-event Info for Portal</InfoTitle>
                    <InfoData>{data.preEventDescription}</InfoData>
                    {data.preEventFiles.map((v) => (
                      <Attachment key={v.id} file={v} />
                    ))}
                  </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Col tabIndex={0}>
                    <InfoTitle>Post-event Info for Portal</InfoTitle>
                    <InfoData>{data.postEventDescription}</InfoData>
                    {data.postEventFiles.map((v) => (
                      <Attachment key={v.id} file={v} />
                    ))}
                  </Col>
                </Row>
                <Row>
                  <Col tabIndex={0}>
                    <InfoTitle>Comment</InfoTitle>
                    <InfoData>{data.internalDescription}</InfoData>
                  </Col>
                </Row>
              </Panel>
              {data.registrationType === 'registered' && (
                <Panel
                  header={
                    <Title>
                      Registrants{' '}
                      <span>
                        <span style={{ color: '#4eca2e' }}>
                          {registrants.length}
                        </span>
                        /{data.maxParticipant}
                      </span>
                    </Title>
                  }
                  key="registrants"
                  className="no-side-paddings"
                >
                  <TableContainer>
                    <Table
                      absoluteContainer={true}
                      data={registrants?.map((value) => ({
                        lastName: (
                          <EllipsisText>{value.patient.lastName}</EllipsisText>
                        ),
                        nickName: (
                          <EllipsisText>
                            {value.patient.nickName || value.patient.firstName}
                          </EllipsisText>
                        ),
                        dob: formattedDate(value.patient.birthDate),
                        class: classes.loadingFinder(
                          value.patient.patientStudentInfo.typeClassId,
                          'name'
                        ),
                        registrationDate: formattedDate(value.dts),
                        checkIn: value.isCheckedIn ? (
                          <Icon name="check" color="#53C82E" />
                        ) : (
                          ''
                        )
                      }))}
                      columns={[
                        {
                          title: 'Last Name',
                          key: 'lastName',
                          flex: '160px'
                        },
                        {
                          title: 'Preferred Name',
                          key: 'nickName',
                          flex: '160px'
                        },
                        {
                          title: 'DOB',
                          key: 'dob',
                          flex: '120px'
                        },
                        {
                          title: 'Class',
                          key: 'class',
                          flex: 'auto'
                        },
                        {
                          title: 'Registration date',
                          key: 'registrationDate',
                          flex: '120px'
                        },
                        {
                          title: 'Check-in',
                          key: 'checkIn',
                          flex: '80px',
                          centered: true
                        }
                      ]}
                      headerBackground="#F7F7F7"
                      horizontalItemPadding={24}
                    />
                  </TableContainer>
                </Panel>
              )}
            </Collapse>
          </MainContainer>
        </>
      )}
    </Container>
  );
};

export default Component;

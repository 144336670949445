import React, { useEffect, useRef, useState } from "react"

interface IInteractionObserverProps {
    targetRef: React.RefObject<HTMLDivElement>
    root?: React.RefObject<HTMLDivElement>
}

export const useInteractionObserver = ({ targetRef, root }: IInteractionObserverProps) => {
    const visibleRef = useRef(false)
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (data) => {
                const entries = data
                const isIntersecting = entries.some((v) => v.isIntersecting)
                if (visibleRef.current !== isIntersecting) {
                    visibleRef.current = isIntersecting
                    setIsVisible(isIntersecting);
                }
            },
            {
                root: root?.current || null, // viewport
                rootMargin: '50px', // no margin
                threshold: 0, // 0% of target visible
            }
        );

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        // Clean up the observer
        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, [])
    return {
        isVisible
    }
}
import { permissionChecking } from "src/App/User/Permission"
import { clinicIsCounseling, getCurrentClinicId } from "src/Framework/Controls/Selectors/ClinicSelector"

export const supervisorDashboardPermission = () => {
    const clinicId = getCurrentClinicId()
    const isCounseling = clinicIsCounseling(clinicId)
    const permission = permissionChecking({
        anyClinic: false,
        clinicId,
        moduleId: 105,
        functionId: 1204
    })
    return {
        success: permission.success && isCounseling,
        isCounseling,
        permission
    }
}

export const supervisionTraineePermission = () => {
    return permissionChecking({
        anyClinic: true,
        moduleId: 108,
        functionId: 23
    })
}
import React from 'react';
import { OptionContainer } from './Option_Styles';

export const Option: any = (props: any) => (
  <OptionContainer>
    <input
      onKeyDown={props.onKeyDown && props.onKeyDown}
      ref={props.inputRef ? props.inputRef : undefined}
      id={props.id}
      type="radio"
      name={props.name}
      value={props.value}
      checked={props.checked}
      onChange={() => {}}
      onClick={props.onClick}
    />
    <span>{props.label}</span>
  </OptionContainer>
);

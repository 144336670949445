import React, { FC, useEffect } from 'react';
import { Row, Col } from 'antd';

import { useAppSelector } from 'src/store';
import EllipsisText from 'src/Framework/Controls/EllipsisText';

import SelectContainer from '../../SelectContainer';
import { ISelect } from 'src/Framework/Controls/SelectContainer/Components/Select';

import { Circle, TitleRow } from './styled';
import { getClinicById } from '../ClinicSelector';

import * as appointmentTypesActions from 'src/App/Admin/Pages/AppointmentTypes/store/Actions';

import {
  AppointmentReasonSimpleDto,
  ScheduleReasonType
} from 'src/App/Admin/Pages/AppointmentTypes/store/types';

import Multiple from './Multiple';
import { useProviderTypes } from 'src/App/Admin/Pages/ProviderTypes/store/Hooks/useProviderTypes';
import { ApptReasonLabel } from 'src/App/UserPreferences/ApptReasonDisplay';
import TableGroupMultiSelect from '../TableGroupMultiSelect';
import { ITableGroupMultiSelect } from '../TableGroupMultiSelect/types';
import { clinicDisplayName } from 'src/Framework/util/format';

interface ICommon {
  name: string;
  value: any;
  onChange: Function;
  clinicCodes?: string[];
  clinicIds?: number[];
  useCode?: boolean;
  reasonTypes?: ScheduleReasonType[];
  selectProps?: Partial<ISelect>;
  clinicTypes?: number[];
  providerTypes?: number[];
  filter?: (value: AppointmentReasonSimpleDto) => boolean;
}

interface ISingle extends ICommon {
  type?: 'single';
  id?: string;
  isGroupByClinic?: boolean;
}

interface IMultiple extends ICommon {
  type: 'multiple';
  id: string;
  isGroupByClinic?: boolean;
}

type SelectProps = ISingle | IMultiple;

type IOwnProps = SelectProps;

type IProps = IOwnProps;
export type IMultipleType = IMultiple;
const Component: FC<IProps> = (props: IProps) => {
  const clinics = useAppSelector((state) => state.adminPanel.clinics.summary);
  const apptReasonDisplay = useAppSelector(
    (state) => state.user.preferences.apptReasonDisplay?.parsedValue
  );
  const appointmentTypes = useAppSelector(
    (state) => state.adminPanel.appointmentTypes
  );
  const {
    name,
    value,
    onChange,
    clinicCodes,
    useCode,
    reasonTypes,
    selectProps,
    clinicTypes,
    type,
    providerTypes,
    id,
    clinicIds,
    filter,
    isGroupByClinic
  } = props;
  const providerTypesData = useProviderTypes();
  useEffect(() => {
    if (Object.keys(appointmentTypes.summary).length === 0) {
      appointmentTypesActions.get();
    }
  }, []);

  const filteredList = Object.values(appointmentTypes.summary).filter((v) => {
    // if (!v.clinicId) return false;
    if (v.isEnabled && v.isActive) {
      const clinic = getClinicById(v.clinicId);
      if (v.reasonType !== 'cancellation') {
        if (!clinic) {
          return false;
        }
      }

      if (clinicIds && v.reasonType !== 'cancellation') {
        if (!clinicIds.includes(v.clinicId)) {
          return false;
        }
      }
      if (clinicCodes && v.reasonType !== 'cancellation') {
        if (clinic && !clinicCodes.includes(clinic.code)) {
          return false;
        }
      }
      if (clinicTypes) {
        if (
          clinic &&
          (!clinic.typeId || !clinicTypes.includes(clinic.typeId))
        ) {
          return false;
        }
      }
      if (reasonTypes && v.reasonType && !reasonTypes.includes(v.reasonType)) {
        return false;
      }
      if (filter) {
        return filter(v);
      }
      return true;
    }
    return false;
  });
  let list: AppointmentReasonSimpleDto[] = filteredList;
  if (providerTypes) {
    const tempList = filteredList.filter((v) => {
      const find = providerTypes.find((val) => {
        const type = providerTypesData.data[val];
        if (type && type.isActive) {
          if (type.suggestedAppointmentReasons[v.id]) {
            return true;
          }
        }
        return false;
      });
      if (!find) {
        return false;
      }
      return true;
    });
    if (tempList.length !== 0) {
      list = tempList;
    }
  }
  const nameFormat = (v: AppointmentReasonSimpleDto) => {
    if (apptReasonDisplay) {
      if (apptReasonDisplay === ApptReasonLabel.code) {
        return v.code || '';
      }
      if (apptReasonDisplay === ApptReasonLabel.both) {
        return `${`(${v.code}) ` || ''}${v.name}`;
      }
    }
    return v.name || '';
  };
  let options = list
    .map((v) => ({
      ...v,
      value: useCode ? v.code : v.id,
      label: nameFormat(v)
    }))
    .sort((a, b) => a.label?.localeCompare(b.label));
  const allOptions = Object.values(appointmentTypes.summary).map((v) => ({
    ...v,
    value: useCode ? v.code : v.id,
    label: nameFormat(v)
  }));
  if (type === 'multiple') {
    const multipleProps = props as IMultipleType;
    if (isGroupByClinic) {
      const clinicsOptions: ITableGroupMultiSelect['groupsOptions'] =
        Object.values(clinics).map((v) => ({
          id: v.id,
          label: clinicDisplayName(v),
          isActive: !v.inactive
        }));
      const childrenOptions: ITableGroupMultiSelect['childrenOptions'] =
        Object.values(appointmentTypes.summary).map((v) => ({
          parentId: v.clinicId,
          id: v.id,
          label: `(${v.code}) ${v.name || ''}`,
          isActive: list.some((val) => val.id === v.id)
        }));
      return (
        <TableGroupMultiSelect
          label={name}
          value={value}
          groupsOptions={clinicsOptions}
          childrenOptions={childrenOptions}
          onChange={onChange as any}
        />
      );
    }
    return (
      <Multiple
        {...multipleProps}
        type="multiple"
        id={multipleProps.id}
        options={options}
        allOptions={allOptions}
      />
    );
  }
  return (
    <SelectContainer
      id={id}
      label={name}
      value={value}
      onChange={(value: string) => {
        const option = options.find((v: any) => v.value === value);
        onChange(value, option);
      }}
      bottomMargin={false}
      allOptions={allOptions.map((v) => ({
        ...v,
        option: v,
        optionSearch: v.label,
        title: (
          <Row style={{ width: '100%', flexWrap: 'nowrap' }}>
            <TitleRow>
              <EllipsisText>{v.label}</EllipsisText>
            </TitleRow>
            {v.reasonType !== 'cancellation' && (
              <Row
                align={'middle'}
                justify="end"
                style={{ margin: '0 0 0 10px', flexWrap: 'nowrap' }}
              >
                <Col>{`${v.duration} Min`}</Col>
                <Col>
                  <Circle
                    style={{
                      background: v.color ? v.color : ''
                    }}
                  />
                </Col>
              </Row>
            )}
          </Row>
        )
      }))}
      options={options.map((v: any) => ({
        ...v,
        option: v,
        label: v.label + ` duration ${v.duration} min`,
        optionSearch: v.label,
        title: (
          <Row style={{ width: '100%', flexWrap: 'nowrap' }}>
            <TitleRow>
              <EllipsisText>{v.label}</EllipsisText>
            </TitleRow>
            {v.reasonType !== 'cancellation' && (
              <Row
                align={'middle'}
                justify="end"
                style={{ margin: '0 0 0 10px', flexWrap: 'nowrap' }}
              >
                <Col>{`${v.duration} Min`}</Col>
                <Col>
                  <Circle
                    style={{
                      background: v.color ? v.color : ''
                    }}
                  />
                </Col>
              </Row>
            )}
          </Row>
        )
      }))}
      {...selectProps}
    />
  );
};

export default Component;

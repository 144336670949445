import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"
import { IMajor } from "./types";


export const get = async (searchString: string): Promise<IMajor[] | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.MAJORS, {
            withCredentials: true, params: {
                searchString
            }
        })
        if (response.data) {
            if (response.data.success) {
                return response.data.result.summary
            }
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Container = styled.div``;

export const ContainerModal = styled.div`
  position: relative;
  padding: 20px;
`;

export const TagContainer = styled.div`
  padding: 5px 8px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
`;

export const NewContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${globalStyleVariables.newBrandingPrimary200};
`;

import React, { FC } from 'react';
import Highlighter from 'react-highlight-words';

import Collapse from 'src/Framework/Controls/CollapseA11y';
import ThreeStateCheckbox from 'src/Framework/Controls/ThreeStateCheckbox';

import Checkbox from '../../../Checkbox';

import { CheckboxesList, Item } from './styled';

import NoData from '../../../Table/NoData';
import { ITableGroupMultiSelect } from '../types';

const { Panel } = Collapse;

interface IProps extends ITableGroupMultiSelect {
  search: string;
  group: ITableGroupMultiSelect['groupsOptions'][number];
}

const Component: FC<IProps> = ({
  group,
  childrenOptions,
  onChange,
  value,
  search
}: IProps) => {
  const groupOptions = childrenOptions
    .filter((v) => v.parentId === group.id)
    .sort((a, b) => a.label.localeCompare(b.label));

  const innerValues = value.filter((v) =>
    childrenOptions.find((val) => val.id === v && val.parentId === group.id)
  );
  const checkedAll = groupOptions.length === innerValues.length;
  const uncheckedAll = innerValues.length === 0;

  const onChangePanelCheckbox = () => {
    if (checkedAll) {
      const arr = [...value];
      innerValues.forEach((val) => {
        const i = arr.findIndex((v) => v === val);
        if (i !== -1) {
          arr.splice(i, 1);
        }
      });
      onChange(arr);
      return;
    }
    if (uncheckedAll) {
      const arr = [...value];
      groupOptions.forEach((v) => {
        arr.push(v.id);
      });
      onChange(arr);
      return;
    }
    const arr = [...value];
    groupOptions.forEach((option) => {
      const i = arr.findIndex((v) => option.id === v);
      if (i === -1) {
        arr.push(option.id);
      }
    });
    onChange(arr);
  };
  return (
    <Panel
      key={group.id}
      header={
        <div
          style={{ width: 'fit-content' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ThreeStateCheckbox
            id=""
            checkedAll={checkedAll}
            uncheckedAll={uncheckedAll}
            onChange={onChangePanelCheckbox}
          >
            <b>
              <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={search.split(' ')}
                autoEscape={true}
                textToHighlight={group.label || ''}
              />
            </b>
          </ThreeStateCheckbox>
        </div>
      }
    >
      <CheckboxesList>
        {groupOptions.length === 0 && <NoData removeVerticalPaddings={true} />}
        {groupOptions.map((option) => {
          const index = value.findIndex((v) => option.id == v);
          const onChangeCheckbox = () => {
            const arr = [...value];
            if (index !== -1) {
              arr.splice(index, 1);
            } else {
              arr.push(option.id);
            }
            onChange(arr);
          };
          return (
            <Item key={option.id}>
              <Checkbox
                id={`${option.id}`}
                checked={index !== -1}
                onChange={onChangeCheckbox}
              >
                <Highlighter
                  highlightClassName="YourHighlightClass"
                  searchWords={search.split(' ')}
                  autoEscape={true}
                  textToHighlight={option.label}
                />
              </Checkbox>
            </Item>
          );
        })}
      </CheckboxesList>
    </Panel>
  );
};

export default Component;

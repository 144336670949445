import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const DashletTableContainer = styled.div`
  padding-left: 18px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const NavigationContainer = styled.div`
  width: 220px;
  height: 100%;
`;

export const ContentContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const WrapperTable = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;

  header {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #f7f7f7;
    padding: 0 8px 0 24px;
    border-bottom: 1px solid #e6e6e6;
    align-items: center;

    h4 {
      margin: 0;
      font-family: Encode Sans Expanded;
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      color: #333333;
    }

    article {
      display: flex;
      height: 100%;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 24px;
        margin-right: 28px;
        border-right: 1px solid #e6e6e6;
        cursor: pointer;

        &:last-child {
          margin: 0;
          border: 0;
        }

        span {
          color: ${globalStyleVariables.newBrandingPrimary200};
          margin-left: 12px;
          font-family: Lato;
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
  }
`;

export const EmptyListPlaceholder = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 15px;
  font-size: 12px;
`;

export const TableRisks = styled.div`
  flex-grow: 1;

  . infinity-container {
    height: calc(100vh - 195px);
    overflow: auto;
  }
`;

export const TableContainer: any = styled.div`
  display: flex;
  flex: 1;
  width: 100%;

  > div {
    width: 100%;
  }

  .ag-header-cell:first-of-type {
    padding-left: 24px !important;
  }

  .ag-cell:first-of-type {
    padding-left: 24px !important;
  }
`;

export const ColorStatus: any = styled.div`
  display: flex;
  width: 100%;
  padding: 2px 8px;
  background-color: ${(props: any) =>
    (props.color && props.color) || '#f7f7f7'};
  border-radius: 2px;
  align-items: center;
  justify-content: center;

  span {
    font-family: Lato;
    font-weight: normal;
    font-size: 12px;
  }
`;

export const TableLink = styled.div`
  cursor: pointer;
  color: ${globalStyleVariables.newBrandingPrimary200};
  width: 100%;
`;

export const TableLargeText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EllipsisStyle = styled.div`
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

export const Dashboard = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  .border-right {
    border-right: 1px solid #e6e6e6;
  }
`;

export const DashboardAbsolute = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  .border-right {
    border-right: 1px solid #e6e6e6;
  }
`;

export const DashboardColumn = styled.div``;

export const DashboardBox = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${globalStyleVariables.newBrandingPrimary200};
  border-bottom: 1px solid #e6e6e6;

  h2 {
    font-family: Encode Sans Expanded;
    font-weight: 800;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #333333;
    background: #f7f7f7;
    padding: 5px 12px 5px 24px;
    cursor: pointer;
  }

  article {
    padding: 5px 24px;
    display: flex;
    background-color: white;

    > div {
      flex: 10;
      font-family: Lato;
      font-weight: normal;
      font-size: 12px;
      color: #333333;
      display: flex;
      flex-flow: column;
      justify-content: center;

      &.reason-container,
      &.left-side {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: black;

        span {
          margin: 0 5px 0 0;
        }
      }

      &.admission-date,
      &.right-side {
        flex: 0;
        color: black;

        span {
          width: 90px;
        }

        .files-container {
          display: flex;
          align-items: center;
        }
      }

      &.discharge-date {
        flex: 0;
        color: black;

        span {
          width: 90px;
        }
      }

      &:last-child {
        text-align: right;
      }

      &.text-align-right {
        text-align: right;
      }

      &.text-align-center {
        text-align: center;
        align-items: center;
      }

      h4 {
        opacity: 0.8;
        margin: 0;
      }

      span {
        opacity: 0.6;
      }
    }
  }
`;

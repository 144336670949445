import store from 'src/store';

export const outreachEventName = 'Outreach Event';

export const showM1FormsInAppts = () => {
  const medicatDemo = store.getState().genericTypes.summary.medicatDemo;

  const demoMode = medicatDemo.find((v) => v.key === 'Appointment:HideM1Forms');
  if (demoMode && demoMode.value === 'False') {
    return true;
  }
  return false;
};

import _ from 'lodash';
import { ISite } from './types';

export interface IStateReducer {
    sites: ISite[]
}

export interface IAction {
    type: typeof actionsTypes[keyof typeof actionsTypes];
    payload: any;
}


const prefix = 'TABLEAU_PUBLISHER_';

export const actionsTypes = {
    SET_SITES: `${prefix}SET_SITES`,
} as const;

type IActions = Record<typeof actionsTypes[keyof typeof actionsTypes], () => IStateReducer>

export const defaultData: IStateReducer = {
    sites: []
};

const Reducer = (
    state: IStateReducer = _.cloneDeep(defaultData),
    action: IAction
): IStateReducer => {
    const actions: IActions = {
        [actionsTypes.SET_SITES]: () => {
            return {
                ...state,
                sites: action.payload
            };
        },
    }
    if (actions[action.type]) {
        return actions[action.type]()
    }
    return state
};

export default Reducer;

import React, { FC, useRef } from 'react';

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import Modal, { IModalHandles } from './Modal';
import ButtonIcon, { ICONS_LIST } from '../ButtonIcon';

import { Container } from './styled';

interface IProps {
  onGetImage: (file: File[]) => any;
  showButton?: boolean;
  iconColor?: string;
  singleFileMode?: boolean;
}

const Component: FC<IProps> = ({
                                 onGetImage,
                                 showButton,
                                 iconColor,
                                 singleFileMode
                               }: IProps) => {
  const modalRef = useRef<IModalHandles>(null);
  return (
    <Container>
      <Modal ref={modalRef} onGetImage={onGetImage} singleFileMode={singleFileMode} />
      {showButton ? (
        <ButtonIcon
          isButton={true}
          label="Scanner"
          tooltip="Scanner"
          haspopup="dialog"
          controlsId="scaner_modal"
          changeColorOnHover={true}
          name={ICONS_LIST.scanner}
          onClick={(e) => {
            modalRef.current?.show({});
          }}
        />
      ) : (
        <ButtonIcon
          isButton={true}
          label="Scanner"
          tooltip="Scanner"
          haspopup="dialog"
          controlsId="scaner_modal"
          size={16}
          iconScale={1}
          changeColorOnHover={false}
          color={iconColor || globalStyleVariables.newBrandingPrimary200}
          name={ICONS_LIST.scanner}
          onClick={(e) => {
            modalRef.current?.show({});
          }}
        />
      )}
    </Container>
  );
};

export default Component;

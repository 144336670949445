import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const TitleContainer = styled.div`
  max-width: 350px;
`

export const TxnCodeContainer = styled.div`
  padding: 2px 5px;
  border: 1px solid ${globalStyleVariables.borderColor};
  border-radius: 4px;
  margin: 6px 0px;
`

export const CreateTaskRenderingProvider = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const AutoCompleteContainer: any = styled.div`
  position: relative;
  width: 100%;
  margin: 12px 0 15px 0;

  ${(props: { error?: boolean }) =>
    props.error &&
    `
            border: 1px solid red !important;
    `}
`;

export const InlineFields = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const InlineField = styled.div`
  display: flex;
  width: 47%;
`;

export const InlineFieldOption = styled.div`
  display: flex;
  width: 40%;
`;
export const InlineFieldRadio = styled.div`
  display: flex;
  width: 5%;
`;

import React, { FC } from 'react';
import { Popover } from 'antd';

import Table from 'src/Framework/Controls/Table';
import {
  ColumnGlobalTitle,
  ColumnIdentifier,
  IColumn
} from 'src/Framework/Controls/Table/types';
import Breadcrumbs from 'src/Framework/Controls/Breadcrumbs';
import ChartButton from 'src/Framework/Controls/PatientPagesButtons/ChartButton';
import { patientDisplayName } from 'src/Framework/util/format';
import { pickTextColorBasedOnBgColorSimple } from 'src/Framework/util/helps';
import { useAppSelector } from 'src/store';

import { typeNames } from 'src/App/Admin/Pages/TypeRisks/constants';
import { IDashboardPatientDto, IPatient } from '../store/types';
import { func } from './utils';
import { isRiskModuleEnabled } from 'src/App/Settings/utils';

import {
  Container,
  ListRow,
  ListCol,
  TypeName,
  TableTitle,
  PatientsLength,
  InnerContainer,
  TableauContainer,
  OuterContainer,
  HeaderWrapper,
  OuterWrapper
} from './styled';

const breadcrumbs = [
  {
    id: 0,
    title: 'Risks'
  }
];

interface IProps {
  data: IDashboardPatientDto[];
}

const Component: FC<IProps> = ({ data }: IProps) => {
  const typeRisks = useAppSelector((state) => state.typeRisks.summary);
  const obj: {
    [key: string]: IPatient[];
  } = {};
  data.forEach((v) => {
    v.typeRiskIds.forEach((id) => {
      if (obj[id]) {
        obj[id].push(v.patient);
      } else {
        obj[id] = [v.patient];
      }
    });
  });
  const structure = func(
    typeRisks.filter((v) => v.isDashboardShown),
    obj,
    []
  );
  if (!isRiskModuleEnabled()) {
    return null;
  }
  return (
    <OuterWrapper>
      <HeaderWrapper>
        <Breadcrumbs data={breadcrumbs} />
      </HeaderWrapper>
      <OuterContainer>
        <Container>
          {structure.map((v) => (
            <ListRow>
              {v.map((val) => {
                const color = val
                  ? pickTextColorBasedOnBgColorSimple(
                      val.risk.riskColor,
                      'white',
                      'black'
                    )
                  : 'black';
                return (
                  <ListCol
                    color={val ? val?.risk?.riskColor : 'transparent'}
                    tabIndex={val ? 0 : -1}
                  >
                    {val ? (
                      <Popover
                        placement="topRight"
                        content={
                          <TableauContainer>
                            <TableTitle>
                              <b>Risk type:</b> {val.risk.name}
                            </TableTitle>
                            <Table
                              verticalItemPadding={8}
                              hideHeader={true}
                              containerStyleProps={{
                                overflowY: 'auto'
                              }}
                              data={
                                val?.patients.map((v) => ({
                                  ...v,
                                  fullName: patientDisplayName(v),
                                  chart: (
                                    <ChartButton
                                      patientId={v.id}
                                      buttonProps={{ size: 28 }}
                                    />
                                  )
                                })) || []
                              }
                              columns={columns}
                            />
                          </TableauContainer>
                        }
                        trigger="hover"
                        overlayClassName="risk-report-popover"
                      >
                        <InnerContainer>
                          {val && (
                            <>
                              <TypeName style={{ color }}>
                                {`${typeNames[val.risk.riskGroup]}`}
                              </TypeName>
                              <PatientsLength style={{ color }}>
                                {val?.patients.length}
                              </PatientsLength>
                            </>
                          )}
                        </InnerContainer>
                      </Popover>
                    ) : (
                      <InnerContainer />
                    )}
                  </ListCol>
                );
              })}
            </ListRow>
          ))}
        </Container>
      </OuterContainer>
    </OuterWrapper>
  );
};

const columns: IColumn[] = [
  {
    key: 'id',
    flex: '60px',
    title: ColumnGlobalTitle.accountNumber,
    centered: true,
    identifier: ColumnIdentifier.patientId
  },
  {
    key: 'fullName',
    flex: '200px',
    title: 'Name'
  },
  {
    key: 'chart',
    flex: '65px',
    title: '',
    centered: true
  }
];

export default React.memo(Component);

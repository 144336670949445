import React, { FC, useEffect } from 'react';

import { useAppSelector } from 'src/store';

import RiskReport from '../RiskReport';
import * as dashboardActions from '../store/Actions';

interface IProps {}

const Component: FC<IProps> = ({}: IProps) => {
  const { caseManagerProviderId, risks } = useAppSelector((state) => {
    const { caseManagementDashboard, filters } = state.dashboard;
    return {
      caseManagerProviderId: filters.caseManagerProviderId,
      risks: caseManagementDashboard.risks
    };
  });
  useEffect(() => {
    if (caseManagerProviderId) {
      dashboardActions.getCaseManagementRisks(caseManagerProviderId);
    }
  }, [caseManagerProviderId]);
  return <RiskReport data={risks} />;
};

export default Component;

import React from "react";

export const DragSvg = () => (
<svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.5" cx="1" cy="1" r="1" fill="#333333"/>
<circle opacity="0.5" cx="1" cy="5" r="1" fill="#333333"/>
<circle opacity="0.5" cx="1" cy="9" r="1" fill="#333333"/>
<circle opacity="0.5" cx="5" cy="1" r="1" fill="#333333"/>
<circle opacity="0.5" cx="5" cy="5" r="1" fill="#333333"/>
<circle opacity="0.5" cx="5" cy="9" r="1" fill="#333333"/>
</svg>






);

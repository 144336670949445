import React from 'react';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { Row, Col } from 'antd';

import ProviderSelector from 'src/Framework/Controls/Selectors/ProviderSelector';
import { getClinicByCode } from 'src/Framework/Controls/Selectors/ClinicSelector';
import {
  getClinicConfig,
  getProviderByCode,
  providersValidForReason
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import Radio from 'src/Framework/Controls/Radio';
import Checkbox from 'src/Framework/Controls/Checkbox';
import store from 'src/store';
import test_ids from 'src/tests-script/pages/Schedule/Recurring';

import {
  getStandartTimeFromMilitaryTime,
  getStartTimeOpts,
  getEndTimeOpts,
  findOpt,
  getDurationOptionsFromClinic,
  getTypeFrequencyView as getTypeFrequency,
  calcDurationDateTime,
  setSeriesDaysOfWeek,
  setSeriesDaysOfMonth,
  momentDateHandFormatter,
  calcDurationTimeValue,
  getMomentFromTimeString,
  validateClinicClosedOnWeekDays,
  mapClinic
} from 'src/Activities/Schedule/Utils/Utils';
import {
  initWeekdayOptions,
  recurringViewOptions,
  weekArray,
  weekNumberOptions
} from 'src/Activities/Schedule/InitData';

import { WeeklyRecurrenceDaySelector } from './WeeklyRecurrenceDaySelector';
import ConflictsEditor, { IConflict, IConflictState } from './ConflictsEditor';
import ConflictsFooter from './ConflictsFooter';
import MonthlyRecurranceDaySelector from './MonthlyRecurranceDaySelector';
import {
  getProviderSelection,
  validateEndTimeRule
} from '../../Popups/AppointmentPopup/utils';

import { ProviderBlock } from 'src/Activities/Schedule/Popups/AppointmentPopup/AppointmentPopup_Styles';
import {
  TitleContainer,
  ErrorBlock,
  ProviderSelectContainer
} from 'src/Activities/Schedule/Popups/BlockReservationPopup/BlockReservationPopup_Styles';
import {
  SelectDateTimeContainer,
  Body,
  SettingsContainer,
  RepeatContainer
} from 'src/Activities/Schedule/Common/Editors/ScheduleEditor_Styles';

interface IState {
  endOption: any;
  enableMulti: boolean;
  error: any;
  payload: any;
  showConflicts: boolean;
  confirmConflicts: boolean;
  message: string;
  selectedRecurrence: any;
  enabledDaysOfWeek: string[];
  clinicProviders: any[];
}

interface IProps {
  onSubmit: () => void;
  onCancel: () => void;
  onSeriesUpdated: any;
  top: any;
  originalPayload: any;
  type: string;
  clinics: any;
  providers: any;
  isShowingCurentConflicts?: boolean;
}

function setSeriesDuration(
  clinic: any,
  event: any,
  startDateTime: any,
  duration: any
) {
  let newEndTimeValue = getSeriesDurationEndTime(duration, startDateTime);
  setSeriesEndTime(clinic, event, startDateTime, newEndTimeValue);
  return event;
}

function getSeriesDurationEndTime(duration: any, startDateTime: any) {
  let newEndTime = getMomentFromTimeString(
    moment(startDateTime).format('HH:mm')
  );
  newEndTime.add(moment.duration(duration, 'minutes'));
  return newEndTime.format('HH:mm');
}

function setSeriesEndTime(
  clinic: any,
  event: any,
  startDateTime: any,
  endTimeValue: any
) {
  var endDateValue: Moment =
    event.scheduleEvent.series.endDate &&
    moment(event.scheduleEvent.series.endDate);
  event.scheduleEvent.series.endDateIsMidnight = false;
  if (endTimeValue === '24:00') {
    event.scheduleEvent.series.endDateIsMidnight = true;
  }
  if (!endDateValue) {
    endDateValue = moment('1900-01-01');
  }

  let newEndTime =
    endDateValue.format('YYYY-MM-DD') + 'T' + endTimeValue + ':00.000';

  event.scheduleEvent.series.endTime = validateEndTimeRule(
    clinic,
    startDateTime,
    newEndTime
  );

  return event;
}

function getSeriesDateTimeSelection(clinic: any, event: any) {
  let duration: any = null;

  let startDateTime =
    event.scheduleEvent.series.startTime &&
    moment(event.scheduleEvent.series.startTime);
  let endDateTime =
    event.scheduleEvent.series.endTime &&
    moment(event.scheduleEvent.series.endTime);

  let startTimeOpt = startDateTime && {
    value: startDateTime.format('HH:mm'),
    label: getStandartTimeFromMilitaryTime(startDateTime.format('HH:mm'))
  };

  var endTimeValue = endDateTime.format('HH:mm');
  var label = getStandartTimeFromMilitaryTime(endDateTime.format('HH:mm'));

  let endDateVal: Moment =
    event.scheduleEvent.series.endDate &&
    moment(event.scheduleEvent.series.endDate);

  if (endTimeValue === '00:00') {
    endTimeValue = '24:00';
    label = '(Midnight)';
  }

  let endTimeOpt = endDateTime && {
    value: endTimeValue,
    label: label
  };

  let startDate =
    event.scheduleEvent.scheduleEventDateTime &&
    moment(event.scheduleEvent.scheduleEventDateTime).format('MM/DD/YYYY');

  let endDate = endDateVal && endDateVal.format('MM/DD/YYYY');
  if (endDateVal?.get('year') > 3000) {
    endDate = '';
  }

  if (startDateTime && endDateTime) {
    let minutes = calcDurationTimeValue(startTimeOpt?.value, endTimeOpt?.value);
    duration = { value: minutes, label: minutes + ' Minutes' };
  }

  let startTimeOptions = getStartTimeOpts(clinic);
  let endTimeOptions = getEndTimeOpts(clinic, startDateTime);
  let durationOptions = getDurationOptionsFromClinic(
    clinic,
    event.scheduleEvent.series.startTime
  );

  return {
    startDate,
    endDate,
    startTimeOpt,
    endTimeOpt,
    startTimeOptions,
    endTimeOptions,
    duration,
    durationOptions
  };
}

const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

const endOptions = [
  { value: 'by', label: 'by' },
  { value: 'after', label: 'after' }
];

class SeriesEditor extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    let payload = _.cloneDeep(props.originalPayload);
    payload.scheduleEvent.series.startDate =
      payload.scheduleEvent.scheduleEventDateTime;
    if (payload.scheduleEvent.series.typeFrequencyId === 0) {
      payload.scheduleEvent.series.typeFrequencyId = 1;
    }
    this.state = {
      enableMulti:
        payload.providerCodes && payload.providerCodes.length > 1
          ? true
          : false,
      showConflicts: false,
      message: 'NO CONFLICTS FOUND',
      confirmConflicts: false,
      error: {
        provider: false,
        weekDay: false,
        monthly: false,
        monthlyByWeek: false,
        monthlyByWeekDay: false,
        clinicClosed: false
      },
      endOption:
        payload.scheduleEvent.series.occurrences === null ||
        moment(payload.scheduleEvent.series.endDate).year() !== 9999
          ? 'by'
          : 'after',
      selectedRecurrence: null,
      payload: payload,
      enabledDaysOfWeek: [],
      clinicProviders: store.getState().schedule.clinicProviders.data
    };
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (!_.isEqual(this.state.payload, prevState.payload)) {
      this.validateSeriesBasedOnClinic();
    }
  }

  componentDidMount() {
    this.validateSeriesBasedOnClinic();
    if (this.props.isShowingCurentConflicts) {
      this.onCheckForConflicts();
    }
  }

  validateSeriesBasedOnClinic() {
    const { series, clinicCode } = this.state.payload.scheduleEvent;
    const clinic = this.props.clinics.find((c: any) => c.code === clinicCode);
    this.setState({
      enabledDaysOfWeek: [...clinic.dayWeek]
    });
    const clinicOpenAllWeek = clinic.dayWeek.length === 7;
    const closedOnDays = daysOfWeek.filter((v) => !clinic.dayWeek.includes(v));
    if (series.typeFrequencyId === 1 || series.typeFrequencyId === 4) {
      this.setState({
        error: {
          ...this.state.error,
          clinicClosed: clinicOpenAllWeek
            ? false
            : `The clinic is closed on ${closedOnDays.join(', ')}.`
        }
      });
    } else if (series.typeFrequencyId === 2 || series.typeFrequencyId === 3) {
      //weekly biweekly
      const translatedWeekDays: string[] = [];
      [...series.dayOfWeekFilter].forEach((c) =>
        translatedWeekDays.push(weekArray[Number(c) - 1])
      );
      const clinicClosedOnDays = validateClinicClosedOnWeekDays(
        clinic.dayWeek,
        translatedWeekDays
      );
      // old logic
      // const closedOnDays = clinicClosedOnDays
      //   ? daysOfWeek.filter((v) => !clinicClosedOnDays.includes(v))
      //   : null;
      this.setState({
        error: {
          ...this.state.error,
          clinicClosed: clinicClosedOnDays
            ? `The clinic is closed on ${closedOnDays?.join(', ')}.`
            : false
        }
      });
    } else if (series.typeFrequencyId === 5 && series.dayOfWeekFilter !== '') {
      //Month by Week day
      const translatedWeekDay = weekArray[Number(series.dayOfWeekFilter) - 1];
      const clinicClosedOnDays = validateClinicClosedOnWeekDays(
        clinic.dayWeek,
        [translatedWeekDay]
      );
      if (translatedWeekDay) {
        this.setState({
          error: {
            ...this.state.error,
            clinicClosed: clinicClosedOnDays
              ? `The clinic is closed on ${closedOnDays?.join(', ')}.`
              : false
          }
        });
      }
    } else {
      this.setState({
        error: { ...this.state.error, clinicClosed: false }
      });
    }
  }

  onChangeSeries = (obj: object) => {
    this.setState({
      payload: {
        ...this.state.payload,
        scheduleEvent: {
          ...this.state.payload.scheduleEvent,
          series: {
            ...this.state.payload.scheduleEvent.series,
            ...obj
          }
        }
      }
    });
  };

  onTypeFrequencyChange = (value: any) => {
    const { payload } = this.state;
    const checkingDate = moment(
      this.props.originalPayload.scheduleEvent.scheduleEventDateTime
    );
    const weekday = checkingDate.day();
    const isCheckingDayValid = getClinicByCode(
      this.props.originalPayload.scheduleEvent.clinicCode
    )!.workingDays.includes(weekArray[weekday]?.toLowerCase());
    const frequency = value;
    const data = _.cloneDeep(payload);
    data.scheduleEvent.series.typeFrequencyId = frequency;
    data.scheduleEvent.series.dayOfMonthFilter =
      value !== 4 ? '' : data.scheduleEvent.series.dayOfMonthFilter;
    data.scheduleEvent.series.dayOfWeekFilter =
      value === 2 || value === 3
        ? isCheckingDayValid
          ? moment(
              this.props.originalPayload.scheduleEvent.scheduleEventDateTime
            ).day() +
            1 +
            ''
          : ''
        : '';
    // : data.scheduleEvent.series.dayOfWeekFilter;
    this.setState({
      payload: data,
      confirmConflicts: false,
      showConflicts: false
    });
  };
  onMonthDaySelect = (payloadString: any, lastDay: boolean) => {
    let { payload, error } = this.state;
    payload.scheduleEvent.series = setSeriesDaysOfMonth(
      payloadString,
      payload.scheduleEvent.series,
      lastDay
    );
    this.setState({
      payload: {
        ...payload,
        conflictsResponses: []
      },
      error: {
        ...error,
        monthly: false
      }
    });
  };

  onStartTimeChange(e: any, clinic: any) {
    let { payload } = this.state;
    let startDateTime =
      payload.scheduleEvent.series.startTime &&
      moment(payload.scheduleEvent.series.startTime);
    let endDateTime =
      payload.scheduleEvent.series.endTime &&
      moment(payload.scheduleEvent.series.endTime);
    let duration = calcDurationDateTime(startDateTime, endDateTime);
    let newStartTime =
      startDateTime.format('YYYY-MM-DD') + 'T' + e.value + ':00.000';
    payload.scheduleEvent.series.startTime = newStartTime;
    payload = setSeriesDuration(clinic, payload, newStartTime, duration);
    this.setState({
      payload: {
        ...payload,
        conflictsResponses: []
      },
      confirmConflicts: false
    });
  }

  onEndTimeChange(e: any, clinic: any) {
    let { payload } = this.state;

    setSeriesEndTime(
      clinic,
      payload,
      payload.scheduleEvent.series.startTime,
      e.value
    );

    this.setState({
      payload: {
        ...payload,
        conflictsResponses: []
      },
      confirmConflicts: false
    });
  }

  setProviderSelection(enableMulti: boolean) {
    let { payload } = this.state;
    const selectedProviders = !enableMulti
      ? payload.providerCodes.slice(0, 1)
      : payload.providerCodes;
    payload['providerCodes'] = selectedProviders;
    this.setState({
      payload: {
        ...payload,
        conflictsResponses: []
      },
      enableMulti,
      confirmConflicts: false
    });
  }

  onProvidersChange = (values: string[]) => {
    const { error, payload } = this.state;
    const providerCodes: any[] = [];
    values.forEach((code) => {
      const provider = getProviderByCode(code);
      const currentClinic = getClinicByCode(payload.scheduleEvent.clinicCode);
      if (provider && currentClinic) {
        providerCodes.push({
          id: provider.providerId,
          code: provider.code,
          page: getClinicConfig(provider, currentClinic.id)?.maxPages || 1,
          name: provider.lastName
        });
      }
    });
    payload['providerCodes'] = providerCodes;
    error.provider = false;
    this.setState({
      payload: {
        ...payload,
        conflictsResponses: []
      },
      error: error,
      confirmConflicts: false
    });
  };

  onSeriesUpdated() {
    let { payload, endOption } = this.state;
    const newPayload = _.cloneDeep(payload);
    if (endOption === 'by') {
      newPayload.scheduleEvent.series.occurrences = null;
    }
    this.setState({
      payload: newPayload
    });
    this.props.onSeriesUpdated(newPayload);
  }

  noErrorCheck(obj: any) {
    return Object.keys(obj)
      .filter((key) => key !== 'clinicClosed')
      .every((item: any) => obj[item] === false);
  }

  formValidation() {
    let { payload, error } = this.state;
    let { typeFrequencyId, dayOfWeekFilter, dayOfMonthFilter, weekNumber } =
      payload.scheduleEvent.series;
    let view = getTypeFrequency(typeFrequencyId);
    if (
      (view === 'weekly' || view === 'biweekly') &&
      dayOfWeekFilter.length === 0
    ) {
      error.weekDay = true;
    } else if (view === 'monthly' && dayOfMonthFilter.length === 0) {
      error.monthly = true;
    } else if (view === 'monthly-week') {
      if (dayOfWeekFilter.length !== 1) {
        error.monthlyByWeekDay = true;
      }
      if (weekNumber === 0) {
        error.monthlyByWeek = true;
      }
    }
    return error;
  }

  onCheckForConflicts() {
    let { error, showConflicts } = this.state;
    showConflicts = false;
    if (this.state.confirmConflicts) {
      /// return to block editing screen from recurrence editing
      this.onSeriesUpdated();
      this.props.onSubmit();
    } else {
      if (this.noErrorCheck(this.formValidation())) {
        showConflicts = true;
      }
      this.setState({
        showConflicts,
        error: error
      });
    }
  }

  onWeekdaySelect = (weekdayOptions: any) => {
    let { payload, error } = this.state;
    payload.scheduleEvent.series = setSeriesDaysOfWeek(
      weekdayOptions,
      payload.scheduleEvent.series
    );
    this.setState({
      payload: {
        ...payload,
        conflictsResponses: []
      },
      error: {
        ...error,
        weekDay: false
      },
      confirmConflicts: false,
      showConflicts: false
    });
  };
  onMonthlyByWeekdaySelect = (weekday: any) => {
    let { payload, error } = this.state;
    payload.scheduleEvent.series.dayOfWeekFilter = `${weekday}`;
    this.setState({
      payload: {
        ...payload
      },
      error: {
        ...error,
        monthlyByWeekDay: false
      }
    });
  };

  onSelectStartDate(value: any) {
    let { payload } = this.state;
    let startDate = momentDateHandFormatter(value);
    let currentStartTime =
      payload.scheduleEvent.series.startTime &&
      moment(payload.scheduleEvent.series.startTime);
    let newStartTime =
      startDate + 'T' + currentStartTime.format('HH:mm') + ':00.000';
    payload.scheduleEvent.series.startDate = startDate;
    payload.scheduleEvent.series.startTime = newStartTime;
    payload.scheduleEvent.scheduleEventDateTime = newStartTime;
    this.setState({
      payload: {
        ...payload,
        conflictsResponses: []
      },
      confirmConflicts: false,
      showConflicts: false
    });
  }

  onSelectEndDate(value: any) {
    let { payload } = this.state;
    const endDate = momentDateHandFormatter(value);
    const currentEndTime =
      payload.scheduleEvent.series.endTime &&
      moment(payload.scheduleEvent.series.endTime);
    const timeVal = currentEndTime.format('HH:mm');

    var endDateVal = endDate;
    if (timeVal == '00:00') {
      endDateVal = moment(endDate).add(1, 'd').format('YYYY-MM-DD');
    }
    let newEndTime = endDateVal + 'T' + timeVal + ':00.000';
    payload.scheduleEvent.series.endDate = endDate;
    payload.scheduleEvent.series.endTime = newEndTime;
    payload.scheduleEvent.endTime = newEndTime;
    this.setState({
      payload: {
        ...payload,
        conflictsResponses: []
      },
      confirmConflicts: false,
      showConflicts: false
    });
  }

  setConfirmConflicts(value: boolean, resolvedConflicts: IConflictState[]) {
    const data: IConflict[] = [];
    if (resolvedConflicts) {
      resolvedConflicts.forEach((item, indexL: any) => {
        if (item.resolved) {
          resolvedConflicts[indexL]['dialogResponse'] = 'override';
        }
        if (item.skipped) {
          resolvedConflicts[indexL]['dialogResponse'] = 'skip';
        }
        const { skipped, resolved, ...other } = item;
        data.push(other);
      });
    }
    this.setState({
      confirmConflicts: value,
      payload: { ...this.state.payload, conflictsResponses: data }
    });
  }

  onClickRemoveRecurrance() {
    let { originalPayload } = this.props;
    this.setState(
      {
        payload: { ...originalPayload, typeFrequencyId: 0, endDate: null }
      },
      () => this.onSeriesUpdated()
    );
    this.props.onSubmit();
  }

  onSelectWeekNumber = (weekNumber: any) => {
    let { error } = this.state;
    this.setState({
      payload: {
        ...this.state.payload,
        scheduleEvent: {
          ...this.state.payload.scheduleEvent,
          series: {
            ...this.state.payload.scheduleEvent.series,
            weekNumber
          },
          conflictsResponses: []
        }
      },
      error: {
        ...error,
        monthlyByWeek: false
      }
    });
  };

  setShowConflicts(value: boolean) {
    this.setState({
      showConflicts: value
    });
  }

  onDurationChange(value: any, clinic: any) {
    let { payload } = this.state;
    let startDateTime =
      payload.scheduleEvent.series.startTime &&
      moment(payload.scheduleEvent.series.startTime);

    let duration = value.value;
    payload = setSeriesDuration(clinic, payload, startDateTime, duration);

    this.setState({
      payload: { ...this.state.payload, conflictsResponses: [] },
      confirmConflicts: false,
      showConflicts: false
    });
  }

  render() {
    const { error, endOption, confirmConflicts, payload } = this.state;
    const { typeFrequencyId, dayOfWeekFilter, weekNumber, dayOfMonthFilter } =
      payload.scheduleEvent.series;
    const view = getTypeFrequency(typeFrequencyId);
    const weekdayOptions = initWeekdayOptions.map((item: any) => {
      return {
        ...item,
        value: `${item.code}`,
        disabled: !this.state.enabledDaysOfWeek.includes(item.value)
      };
    });
    const { providers, clinics, type } = this.props;
    const clinicOpts = clinics.map((c: any) => mapClinic(c));
    const selectedClinic = findOpt(
      clinicOpts,
      payload.scheduleEvent.clinicCode
    );
    const providerSelection = getProviderSelection(
      providers,
      selectedClinic,
      payload.providerCodes
    );
    const { providerOpts, selectedProviders } = providerSelection;
    const seriesDateTimeSelection = getSeriesDateTimeSelection(
      selectedClinic,
      payload
    );
    const {
      startDate,
      endDate,
      startTimeOpt,
      endTimeOpt,
      startTimeOptions,
      endTimeOptions,
      duration,
      durationOptions
    } = seriesDateTimeSelection;
    const occurrences = payload.scheduleEvent?.series?.occurrences;

    const disableConfirm = !(
      selectedProviders.length !== 0 &&
      startDate &&
      startTimeOpt?.value &&
      (((payload.scheduleEvent.scheduleEventTypeId === 1 ||
        payload.scheduleEvent.scheduleEventTypeId === 7) &&
        (endOption === 'by' ? endDate : occurrences)) ||
        (payload.scheduleEvent.scheduleEventTypeId !== 1 &&
          payload.scheduleEvent.scheduleEventTypeId !== 7))
    );
    const currentClinic = getClinicByCode(payload.scheduleEvent.clinicCode);
    const isBlockOrReservation =
      type === 'block' || type === 'reservation' || type === 'event';
    return (
      <SelectDateTimeContainer id={test_ids.CONTAINER}>
        <Body
          style={{
            display: 'flex',
            flex: 1,
            borderTop: '1px solid #e6e6e6'
          }}
        >
          <div style={{ width: '45%', borderRight: '1px solid #e6e6e6' }}>
            {type !== 'event' && (
              <ProviderBlock
                style={{
                  paddingLeft: '2.5rem',
                  paddingTop: '1rem',
                  paddingRight: '2.5rem'
                }}
              >
                <div style={{ paddingBottom: '1rem', display: 'flex' }}>
                  <Radio
                    name="provider"
                    id={test_ids.options.single}
                    checked={!this.state.enableMulti}
                    onClick={this.setProviderSelection.bind(this, false)}
                  >
                    Single
                    <span className="visually-hidden">provider</span>
                  </Radio>
                  <Radio
                    name="provider"
                    id={test_ids.options.multiple}
                    checked={this.state.enableMulti}
                    onClick={this.setProviderSelection.bind(this, true)}
                  >
                    Multiple
                    <span className="visually-hidden">providers</span>
                  </Radio>
                </div>
                <ProviderSelectContainer style={{ marginLeft: 0 }}>
                  <ProviderSelector
                    useCode={true}
                    multiple={true}
                    enableFilters={true}
                    enableSelectAll={true}
                    filterByClinics={currentClinic ? [currentClinic.id] : []}
                    roleFilter="appointment"
                    isSecureFilterByReasons={true}
                    // filterByReasons={[
                    //   this.state.payload.scheduleEvent.reason.id
                    // ]}
                    providerFilter={(provider) => {
                      if (this.state.payload.scheduleEvent.reason.id) {
                        const providers = this.state.clinicProviders.map(
                          (item: any) => item.providerId
                        );
                        const validProviders = providersValidForReason(
                          this.state.payload.scheduleEvent.reason.id,
                          providers,
                          this.state.payload.scheduleEvent.clinicCode
                        );
                        return validProviders
                          .map((item) => item.code)
                          .includes(provider.code);
                      }
                      return true;
                    }}
                    value={payload.providerCodes.map((v: any) => v.code)}
                    onChange={(values: any) => this.onProvidersChange(values)}
                    selectProps={{
                      star: true,
                      bottomMargin: false,
                      id: test_ids.providerSelector
                    }}
                  />
                  <ErrorBlock error={error.provider}>
                    Field is mandatory
                  </ErrorBlock>
                </ProviderSelectContainer>
              </ProviderBlock>
            )}
            <SettingsContainer style={{ paddingBottom: '0', paddingTop: 0 }}>
              <TitleContainer
                style={{
                  display: 'block',
                  marginRight: '1rem',
                  fontWeight: 'bold',
                  paddingLeft: '2.5rem'
                }}
              >
                <label className={'category-label'}>Date and Time</label>
              </TitleContainer>
              <div
                style={{
                  display: 'flex',
                  paddingRight: '2.5rem'
                }}
              >
                {view ? (
                  <RepeatContainer>
                    <SelectContainer
                      id={test_ids.repeats}
                      type="select"
                      label="Repeats"
                      star={false}
                      value={typeFrequencyId}
                      options={recurringViewOptions}
                      avoidOptionsSorting={true}
                      onChange={(value: any) =>
                        this.onTypeFrequencyChange(value)
                      }
                      inputProps={{
                        'aria-label':
                          'Repeats: ' +
                          recurringViewOptions.find(
                            (item) => item.value === typeFrequencyId
                          )?.label
                      }}
                      bottomMargin={false}
                      selectProps={{}}
                    />
                    <ErrorBlock style={{ alignItems: 'start' }} error>
                      {error.clinicClosed && <span>{error.clinicClosed}</span>}
                    </ErrorBlock>
                  </RepeatContainer>
                ) : null}
                {view === 'monthly' ? (
                  <RepeatContainer>
                    <MonthlyRecurranceDaySelector
                      id={test_ids.dayOfMonthFilter}
                      error={error.monthly}
                      monthlyString={dayOfMonthFilter}
                      onMonthDaySelect={(
                        payloadString: any,
                        lastDay: boolean
                      ) => this.onMonthDaySelect(payloadString, lastDay)}
                    />
                  </RepeatContainer>
                ) : null}
                {view === 'monthly-week' ? (
                  <RepeatContainer style={{ width: '50%' }}>
                    <SelectContainer
                      id={test_ids.weekNumber}
                      type="select"
                      label="Week"
                      star={false}
                      avoidOptionsSorting={true}
                      value={weekNumber ? weekNumber : undefined}
                      options={weekNumberOptions}
                      onChange={(e: any) => this.onSelectWeekNumber(e)}
                      bottomMargin={false}
                    />
                    {error.monthlyByWeek && (
                      <ErrorBlock error={error.monthlyByWeek}>
                        Please Select a week
                      </ErrorBlock>
                    )}
                  </RepeatContainer>
                ) : null}
                {view === 'monthly-week' ? (
                  <RepeatContainer style={{ width: '60%' }}>
                    <SelectContainer
                      id={test_ids.dayOfWeekFilter}
                      type="select"
                      label="Week Day"
                      avoidOptionsSorting={true}
                      star={false}
                      value={
                        dayOfWeekFilter
                          ? dayOfWeekFilter.split('')[0]
                          : undefined
                      }
                      options={weekdayOptions}
                      onChange={(e: any) => this.onMonthlyByWeekdaySelect(e)}
                      bottomMargin={false}
                    />
                    {error.monthlyByWeekDay && (
                      <ErrorBlock error={error.monthlyByWeekDay}>
                        Please Select a day
                      </ErrorBlock>
                    )}
                  </RepeatContainer>
                ) : null}
                {view === 'weekly' || view === 'biweekly'
                  ? WeeklyRecurrenceDaySelector(
                      weekdayOptions,
                      this.onWeekdaySelect.bind(this),
                      error.weekDay,
                      dayOfWeekFilter
                    )
                  : null}
              </div>
            </SettingsContainer>
            <SettingsContainer style={{ paddingTop: '12px', paddingBottom: 0 }}>
              <Row align="middle" gutter={16} style={{ margin: '0 18px' }}>
                <Col span={8}>
                  <SelectContainer
                    id={test_ids.startDate}
                    type="date"
                    star={false}
                    label="Start Date"
                    value={startDate}
                    onChange={(startDate) => {
                      this.onSelectStartDate(startDate);
                    }}
                    bottomMargin={false}
                    inputProps={{
                      placeholder: '',
                      allowClear: false,
                      disabledDate(date) {
                        return (
                          date.isBefore(moment(), 'day') ||
                          (endOption !== 'after' && endDate
                            ? date > moment(endDate)
                            : false)
                        );
                      }
                    }}
                  />
                </Col>
                {isBlockOrReservation && (
                  <Col
                    style={{
                      marginTop: '25px',
                      display: 'flex',
                      alignItems: 'flex-end'
                    }}
                  >
                    <span>by</span>
                  </Col>
                )}
                {!isBlockOrReservation && (
                  <Col span={8}>
                    <SelectContainer
                      label="End"
                      id={test_ids.endOption}
                      star={false}
                      value={endOption}
                      options={endOptions.map((v) => ({
                        ...v,
                        title: v.label
                      }))}
                      onChange={(endOption: any) => {
                        const newSeries = _.cloneDeep(
                          payload.scheduleEvent.series
                        );
                        if (endOption === 'by') {
                          newSeries.occurrences = null;
                        }
                        this.setState({
                          payload: {
                            ...payload,
                            conflictsResponses: [],
                            scheduleEvent: {
                              ...payload.scheduleEvent,
                              series: newSeries
                            }
                          },
                          confirmConflicts: false,
                          showConflicts: false,
                          endOption
                        });
                      }}
                      bottomMargin={false}
                      inputProps={{
                        placeholder: '',
                        allowClear: false
                      }}
                    />
                  </Col>
                )}
                <Col span={8}>
                  {endOption === 'after' ? (
                    <SelectContainer
                      type="number"
                      star={false}
                      id={test_ids.occurrences}
                      label="Occurrences"
                      value={occurrences}
                      onChange={(occurrences) => {
                        this.onChangeSeries({
                          occurrences
                        });
                      }}
                      inputProps={{
                        placeholder: '',
                        style: {
                          width: '100%'
                        },
                        min: 1
                      }}
                    />
                  ) : (
                    <SelectContainer
                      id={test_ids.endDate}
                      type="date"
                      star={false}
                      label="End Date"
                      value={endDate}
                      onChange={(value) => this.onSelectEndDate(value)}
                      bottomMargin={false}
                      inputProps={{
                        placeholder: '',
                        allowClear: false,
                        disabledDate(date) {
                          return (
                            date.isBefore(moment(), 'day') ||
                            date.isBefore(moment(startDate), 'day')
                          );
                        }
                      }}
                    />
                  )}
                </Col>
                {this.props.type === 'block' && (
                  <Col>
                    <Checkbox
                      id={test_ids.allDayBlockCheckBox}
                      checked={
                        this.state.payload.scheduleEvent?.scheduleEventBlock
                          ?.allDay || false
                      }
                      onChange={() => {
                        this.onStartTimeChange(
                          {
                            value: selectedClinic.dayStartTime.substring(11, 16)
                          },
                          selectedClinic
                        );
                        this.onEndTimeChange(
                          {
                            value: selectedClinic.dayEndTime.substring(11, 16)
                          },
                          selectedClinic
                        );
                        const newScheduleEvent = _.cloneDeep(
                          this.state.payload.scheduleEvent
                        );
                        newScheduleEvent.scheduleEventBlock.allDay =
                          !newScheduleEvent.scheduleEventBlock.allDay;
                        this.setState({
                          payload: {
                            ...this.state.payload,
                            scheduleEvent: newScheduleEvent
                          }
                        });
                      }}
                      style={{
                        marginTop: 20
                      }}
                    >
                      All Day
                    </Checkbox>
                  </Col>
                )}
              </Row>
              {!this.state.payload.scheduleEvent?.scheduleEventBlock
                ?.allDay && (
                <Row
                  align="middle"
                  gutter={16}
                  style={{ margin: '12px 18px 0 18px' }}
                >
                  <Col span={8}>
                    <SelectContainer
                      id={test_ids.startTime}
                      type="select"
                      star={false}
                      label="Start Time"
                      avoidOptionsSorting={true}
                      value={startTimeOpt?.value}
                      onChange={(e: any) =>
                        this.onStartTimeChange({ value: e }, selectedClinic)
                      }
                      options={startTimeOptions}
                      inputProps={{
                        placeholder: '',
                        allowClear: false,
                        disabled: startDate ? false : true
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <SelectContainer
                      id={test_ids.endTime}
                      type="select"
                      star={false}
                      label="End Time"
                      avoidOptionsSorting={true}
                      value={endTimeOpt?.value}
                      onChange={(e: any) =>
                        this.onEndTimeChange({ value: e }, selectedClinic)
                      }
                      options={endTimeOptions}
                      inputProps={{
                        placeholder: '',
                        allowClear: false,
                        disabled: startTimeOpt ? false : true
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <SelectContainer
                      id={test_ids.duration}
                      type="select"
                      star={false}
                      label="Duration"
                      avoidOptionsSorting={true}
                      value={duration?.value}
                      onChange={(e: any) =>
                        this.onDurationChange({ value: e }, selectedClinic)
                      }
                      options={durationOptions}
                      inputProps={{
                        placeholder: '',
                        allowClear: false,
                        disabled: startTimeOpt ? false : true
                      }}
                    />
                  </Col>
                </Row>
              )}
            </SettingsContainer>
          </div>
          <ConflictsEditor
            providers={providerOpts}
            clinicOptions={clinicOpts}
            providerOptions={providerOpts}
            payload={payload}
            setConfirmConflicts={(value: boolean, conflictsResponses: any) =>
              this.setConfirmConflicts(value, conflictsResponses)
            }
            confirmConflicts={confirmConflicts}
            type={this.props.type}
            setShowConflicts={(value: boolean) => this.setShowConflicts(value)}
            showConflicts={this.state.showConflicts}
            regularMeetingTime={selectedClinic.visitDuration}
            formValidation={() => this.formValidation()}
          />
        </Body>
        <ConflictsFooter
          inSeries={payload.scheduleEvent.inSeries}
          onClickRemoveRecurrance={() => this.onClickRemoveRecurrance()}
          confirmConflicts={confirmConflicts}
          disableConfirm={disableConfirm}
          onSubmit={() => this.props.onSubmit()}
          onCancel={() => this.props.onCancel()}
          onCheckForConflicts={() => this.onCheckForConflicts()}
        />
      </SelectDateTimeContainer>
    );
  }
}

export default SeriesEditor;

import styled, { css } from 'styled-components';
import { box_shadow } from 'src/CommonStyles/styles';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  background: rgb(245, 245, 245);
`;

export const ListContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ListInnerContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  padding: 0 0 20px 0;
`;

export const ListInnerContainerTasks = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
`;

export const Main = styled.div`
  position: relative;
  flex: 1;
`;

export const Footer = styled.div`
  height: 52px;
  padding-right: 20px;
  border-top: 1px solid ${globalStyleVariables.borderColor};
`;

export const RisksContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: white;
  height: 100%;
  max-height: 100%;
`;

export const RisksOuterContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: white;
`;

export const RisksLeft = styled.div`
  min-height: 10rem;
  width: 10%;
  position: relative;
  flex-direction: column;
  display: flex;
`;

interface RisksTabProps {
  active?: boolean;
  first?: boolean;
  last?: boolean;
}

export const RisksTab = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
  ${(props: RisksTabProps) =>
    !props.active &&
    css`
      border-right: 2px solid ${globalStyleVariables.newBrandingPrimary200};
    `}
  ${(props: RisksTabProps) =>
    props.active &&
    css`
      background-color: ${globalStyleVariables.newBrandingActiveMenuItemBG};
      color: ${globalStyleVariables.newBrandingActiveMenuItemColor};
      border-left: 2px solid ${globalStyleVariables.newBrandingPrimary200};
      border-top: 2px solid ${globalStyleVariables.newBrandingPrimary200};
      border-bottom: 2px solid ${globalStyleVariables.newBrandingPrimary200};
    `}
  ${(props: RisksTabProps) =>
    props.last &&
    css`
      border-bottom: none;
    `}
  ${(props: RisksTabProps) =>
    props.first &&
    css`
      border-top: none;
    `}
`;

export const TabName = styled.div`
  display: flex;
  width: 70%;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  justify-content: center;
`;

export const CountLabel = styled.div`
  display: flex;
  font-weight: 800;
  font-size: 32px;
  text-align: center;
`;

export const RisksRight = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  background-color: ${box_shadow};
  height: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .right-spaced {
    margin-left: 8px;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const DPContainer = styled.div`
  display: flex;
  border-radius: 50%;
  background-color: ${globalStyleVariables.newBrandingPrimary200};
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  font-size: 10px !important;
`;

export const HighRiskContainer = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */

  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffeaea;
  border-radius: 2px;
  width: 58px;
  height: 20px;
  color: #ff2828;
`;

import { BucketsList, generateSubscriptionFilter } from "src/Framework/ApiSubscription";
import { RequestHelper } from "src/Framework/ApiSubscription/RequestHelper";
import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { setSummary, onCreateUpdateItem } from "./Setters";
import { ITicketDetailHipaa } from "../types";

type IData = ITicketDetailHipaa

type TypeGetArgs = [ids: (string | number)[], key: keyof IData]

export const SubscriptionData = (...args: TypeGetArgs) => {
    const [ids, key] = args
    return ({
        bucket: BucketsList.ticketDetailHipaa,
        filter: generateSubscriptionFilter(BucketsList.ticketDetailHipaa, ids, key)
    })
}

export const { post, get, patch, findAndUnsubscribe } = new RequestHelper<IData, TypeGetArgs>({
    link: ApiUrl.Hippa,
    onCreateUpdateItem,
    subscription: {
        subscriptionData: SubscriptionData,
        setSummary
    }
})

import store from "src/store";
import { actionsTypes } from "..";
import { ITicketDetailHipaa } from "../types";
import { TempFieldsForTable } from "src/Framework/Controls/Table/types";
import _ from "lodash";

type IItem = ITicketDetailHipaa

export const filterUnusedHippo = (payload: Record<string, IItem>) => {
    const data: Record<string, IItem> = {}
    const group = _.groupBy(payload, (v) => v.patientId)
    Object.entries(group).forEach(([key, value]) => {
        const list = [...value].sort((a, b) => a.id - b.id)
        const item = list[0]
        if (item) {
            data[item.id] = item
        }
    })
    return data
}

export const setSummary = (payload: Record<string, IItem>) => {
    store.dispatch({
        type: actionsTypes.SET,
        payload: filterUnusedHippo(payload),
    })
}

export const onCreateUpdateItem = (payload: IItem & Partial<TempFieldsForTable>) => {
    const data = { ...store.getState().hippa.summary }
    data[payload.id] = { ...payload }
    setSummary(data)
}

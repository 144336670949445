import React, { useImperativeHandle, useState, useRef } from 'react';
import { Button } from 'antd';
import axios from 'axios';

import Modal from 'src/Framework/Controls/Modal';
import { useUserGeneralSettingsPref } from 'src/App/Preferences/Hooks/useUserGeneralSettingsPref';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

import DWT from './DWT';

import { ModalBody } from './styled';

interface IData {
}

interface IProps {
  onGetImage: (file: File[]) => any;
  sliderMode?: boolean;
  singleFileMode?: boolean;
}

export interface IModalHandles {
  show(obj: IData): void;

  close(): void;
}

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { onGetImage, sliderMode, singleFileMode },
  ref
) => {
  const dwtRef = useRef<DWT>(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  useModalFocus({ active: visible });
  const { data: userGeneralSettings, actions: userGeneralSettingsActions } = useUserGeneralSettingsPref();
  const show = ({}: IData) => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
  };

  useImperativeHandle(ref, () => ({
    show: (obj: IData) => show(obj),
    close
  }));

  const apply = async () => {
    setLoading(true);
    const current = dwtRef.current;
    const DWObject: any = current?.DWObject;
    if (DWObject && current) {
      const { state } = current;
      const { saveFileName, saveFileFormat, buffer } = state;
      const arr = [];
      for (let i = 0; i < buffer.count; i++) {
        arr.push(i);
      }
      switch (saveFileFormat) {
        case 'tif':
        case 'pdf': {
          DWObject.ConvertToBlob(
            arr,
            saveFileFormat === 'pdf' ? 4 : 2,
            function(result: any, indices: any, type: any) {
              const file = new File(
                [result],
                `${saveFileName}.${saveFileFormat}`,
                {
                  type:
                    saveFileFormat === 'pdf' ? 'application/pdf' : 'image/tiff'
                }
              );
              onGetImage([file]);
              close();
            },
            function(errorCode: any, errorString: any) {
              console.log(errorString);
            }
          );
          break;
        }
        case 'bmp':
        case 'jpg':
        case 'png': {
          const files: File[] = [];
          for (let i = 0; i < buffer.count; i++) {
            const url = DWObject.GetImageURL(i);
            if (url) {
              const response = await axios.get(url, { responseType: 'blob' });
              const file = new File(
                [response.data],
                `${saveFileName}${i !== 0 ? `-${i}` : ''}.${saveFileFormat}`,
                {
                  type: response.headers['content-type']
                }
              );
              files.push(file);
            }
          }
          onGetImage(files);
          close();
          break;
        }
      }
    } else {
      close();
    }

    setLoading(false);
  };

  const checkValidation = (): boolean => {
    return true;
  };

  const title = 'Scanner';
  const defaultWidth = 1040;
  const headerRight = (
    <Button
      loading={loading}
      disabled={!checkValidation()}
      shape="round"
      onClick={apply}
    >
      Save
    </Button>
  );
  return (
    <Modal
      isModalSlider={sliderMode}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        headerRight,
        title
      }}
      id="scaner_modal"
      width={defaultWidth}
      title={title}
      visible={visible}
      afterClose={setToDefault}
      onClose={close}
      headerBorder={true}
      modalProps={{
        wrapClassName: 'scanner-modal',
        destroyOnClose: true
      }}
      buttons={headerRight}
    >
      <ModalBody>
        <DWT ref={dwtRef} features={['scan', 'save']} settings={userGeneralSettings.scannerSettings}
             actions={userGeneralSettingsActions} singleFileMode={singleFileMode} />
      </ModalBody>
    </Modal>
  );
};

export default React.forwardRef(Component);

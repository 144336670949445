import React, { FC } from 'react';
import { Tooltip } from 'antd';
import cn from 'classnames';

import StatusLabel from 'src/Framework/Controls/StatusLabel';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import tests_ids from 'src/tests-script/pages/Schedule/SingleApptPopup';

import { SvgDescContainer, SvgContainer } from '../ScheduleDetailPopup_Styles';

interface IProps {
  onStatusChange: (val: string) => Promise<any>;
  value: {
    tooltip: string | null;
    name: string;
    active: boolean;
    icon: React.ReactNode;
    time: string | false;
  };
  isDisabled: boolean;
}

const Component: FC<IProps> = ({
  onStatusChange,
  value,
  isDisabled
}: IProps) => {
  return (
    <Tooltip
      title={value.tooltip}
      color={globalStyleVariables.newBrandingPrimary200}
      overlayStyle={{ zIndex: 10000 }}
      overlayInnerStyle={{ marginBottom: 20 }}
    >
      <SvgDescContainer
        role={value.name === 'Late' ? undefined : 'button'}
        aria-disabled={isDisabled}
        aria-label={value.name}
        tabIndex={value.name === 'Late' || isDisabled ? -1 : 0}
        id={tests_ids.statusItem(value.name.toLowerCase())}
        style={{
          cursor: value.name === 'Late' ? 'unset' : 'pointer'
        }}
        onKeyDown={onKeyDownAccessibility}
        onClick={() => {
          value.name !== 'Late' && onStatusChange(value.name);
        }}
      >
        <SvgContainer
          className={cn({
            active: value.active
          })}
        >
          {value.icon}
        </SvgContainer>
        <StatusLabel statusCode={value.name} maxWidth={120} />
        <span>{value.time}</span>
      </SvgDescContainer>
    </Tooltip>
  );
};

export default Component;

import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/store';


import * as actions from '../Actions';

export const useProviderTypes = () => {
    const [loading, setLoading] = useState(false);
    const data = useAppSelector((state) => {
        const summary = state.adminPanel.providerTypes.summary;
        return summary;
    });
    useEffect(() => {
        const init = async () => {
            if (Object.values(data).length === 0) {
                setLoading(true);
                await actions.getProvidersTypes();
                setLoading(false);
            }
        };
        init();
    }, []);
    return {
        /** Loading status */
        loading,
        data,
    };
}
import moment from "moment";
import { store } from "src"

export const isHippoExpired = (clinicId: number, data?: string | null): boolean | undefined => {
    if (!data) return
    const selfChecking = store.getState().selfCheckins.clinics
    const checking = Object.values(selfChecking).find((v) => v.clinicId === clinicId)
    if (checking) {
        if (checking.expirationDays === null) return false
        const new_date = moment(data, "DD-MM-YYYY").add(checking.expirationDays, 'days');
        return new_date < moment(new Date(), "DD-MM-YYYY")
    }
    return
}
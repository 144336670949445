import React from 'react';
import { connect } from 'react-redux';

import { formattedDateTime } from 'src/Framework/Shared/Shared';
import FilesDownloader from 'src/Framework/Controls/FilesDownloader';
import { IStore } from 'src/store';

import {
  ITypeReqPrioritySimpleDto,
  ITypeReqStatusSimpleDto
} from '../store/types';

import {
  Data,
  DataContainer,
  DataSection,
  DividingHeaderContainer,
  Label,
  ResultComments,
  ResultContent,
  Divider,
  ActivityFlagsContainer,
  Column,
  VerticalDivider,
  InlineData
} from './styled';

interface IOwnProps {
  result: any;
  priority: ITypeReqPrioritySimpleDto;
  status: ITypeReqStatusSimpleDto;
  user: any;
  onClick: () => void;
}

interface IProps extends IOwnProps {
  providers: IStore['user']['providers'];
}

interface LabeledDataProps {
  data: any;
  name: string;
  width?: string;
  style?: any;
}

interface DividingHeaderProps {
  header: string;
}

const LabeledData = ({ name, data, width }: LabeledDataProps) => (
  <DataContainer width={width}>
    <Label>{name}</Label>
    <Data>{data}</Data>
  </DataContainer>
);

const DividingHeader = ({ header }: DividingHeaderProps) => (
  <DividingHeaderContainer>
    <span className="title">{header}</span>
    <div className="sep">
      <span className="line"></span>
    </div>
  </DividingHeaderContainer>
);

const ResultLogCard = (props: IProps) => {
  const { result, priority, status, user, providers } = props;
  const findProvider = providers.summary[result.providerId];
  const provider = findProvider
    ? `${findProvider.firstName} ${findProvider.lastName}`
    : result.from;
  return (
    <ResultContent tabIndex={0} role="listitem">
      <DataSection divider={false}>
        <LabeledData
          name="Date & Time"
          width={'23%'}
          data={
            <span style={{ margin: '8px 0', display: 'block' }}>
              {formattedDateTime(result.activityDate)}
            </span>
          }
        />
        <LabeledData
          name="Status"
          data={
            <span style={{ margin: '8px 0', display: 'block' }}>
              {status?.name}
            </span>
          }
        />
        <LabeledData
          name="Priority"
          width={'15%'}
          data={
            <span style={{ margin: '8px 0', display: 'block' }}>
              {priority?.name}
            </span>
          }
        />
        <LabeledData
          name="Source"
          data={
            <span style={{ margin: '8px 0', display: 'block' }}>
              {provider}
            </span>
          }
        />
      </DataSection>
      <ResultComments>{result.comment}</ResultComments>
      {result.files && Object.values(result.files).length > 0 && (
        <>
          <DividingHeader header="Attachments" />
          <FilesDownloader
            tooltip
            type="squared"
            files={Object.values(result.files).map((file: any) => ({
              id: file.id,
              name: file.originalFileName,
              type: file.contentType
            }))}
          />
        </>
      )}
      <Divider />
      <ActivityFlagsContainer>
        <Column>
          <InlineData>
            Activity added by <strong>{user?.name}</strong>
          </InlineData>
        </Column>
        <VerticalDivider />
        <Column></Column>
      </ActivityFlagsContainer>
    </ResultContent>
  );
};

ResultLogCard.defaultProps = {
  onClick: () => {}
};

const mapStateToProps = (state: IStore, ownProps: IOwnProps) => {
  return {
    providers: state.user.providers
  };
};

export default connect(mapStateToProps)(ResultLogCard);

import React, { useState, useImperativeHandle, useMemo } from 'react';
import {
  Button,
  // Popover,
  List
} from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';

import Checkbox from 'src/Framework/Controls/Table/Checkbox';
import Modal from 'src/Framework/Controls/Modal';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { handleOpenNewTabFiles } from 'src/Framework/Controls/FilesDownloader';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import { handleNoteClick } from 'src/Framework/Controls/NoteButton';
import { formattedDate } from 'src/Framework/Shared/Shared';
import { patientDisplayName } from 'src/Framework/util/format';
import { callActionIfCondition } from 'src/Framework/util/content-management';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';
import { IStore } from 'src/store';
import ids from 'src/tests-script/pages/counseling/Dashboard/Referrals';

// import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';
import { getCurrentClinicProviders } from 'src/Activities/Subject/PatientListActions';
import {
  getReqPriorities,
  getReqStatuses,
  createReferralResult,
  getReqOrderItem,
  updateReqOrder
} from '../store/ReferralsActions';
import { IReferral, IReferralResult } from '../store/types';

import NewResultForm from '../NewResultForm';
// import HistoryTable from './HistoryTable';
import ResultLogCard from './ResultLogCard';

import {
  ModalBody,
  ReferralResults,
  ResultsContainer,
  IconsContainer,
  SubHeader,
  ModalTitle,
  TitleHeader,
  Footer,
  Label,
  NoteColumn
} from './styled';

interface IOwnProps {
  subjectId?: any;
  openNoteInNewTab?: boolean;
}

interface IProps extends IOwnProps {
  users: IStore['adminPanel']['users']['summary'];
  statuses: IStore['reqOrders']['reqStatuses'];
  priorities: IStore['reqOrders']['reqPriorities'];
  providers: any;
  allProviders: IStore['user']['providers']['summary'];
  patientProviders: IStore['patientsProviders']['patients']['']['summary'];
  sliderMode?: boolean;
}

interface IShow {
  value: number;
  patientId?: number;
}

export interface IModalHandles {
  show(obj: IShow): void;

  close(): void;
}

const ReferralResultModal: React.ForwardRefRenderFunction<
  IModalHandles,
  IProps
> = (props, ref) => {
  const {
    users,
    statuses,
    priorities,
    providers,
    patientProviders,
    allProviders,
    sliderMode,
    openNoteInNewTab
  } = props;
  const [subjectId, setSubjectId] = useState(props.subjectId);
  const [reqOrderId, setReqOrderId] = useState<number | null>(null);
  const [referral, setReferral] = useState<IReferral | null>(null);
  const [results, setResult] = useState<any[]>([]);
  const [visible, setVisible] = useState(false);
  const [loadingResults, setLoadingResults] = useState(true);
  const [isCreatingResult, setIsCreatingResult] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [submitResult, setSubmitResult] = useState(false);
  // const [resultsIds, setResultsIds] = useState<number[]>([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  // useModalFocus({ active: visible });

  const resultsFiles = useMemo(() => {
    return results.reduce((acc, item) => {
      if (!_.isEmpty(item.files)) {
        return { ...acc, ...item.files };
      }
      return acc;
    }, {});
  }, [results]);

  // const [historyPopupOpen, setHistoryPopupOpen] = useState(false);
  // const buttonRef = useRef(null);
  // const popupRef = useRef(null);

  const show = async ({ value, patientId }: IShow) => {
    if (!value) return;
    if (patientId) {
      setSubjectId(patientId);
    }
    setVisible(true);
    const init = async () => {
      setLoadingResults(true);
      setReqOrderId(value);
      const res: any = await getReqOrderItem(patientId, value);
      if (res) {
        const results = Object.values(res.referralResults).sort(
          (a: any, b: any) => b.id - a.id
        );
        onChangeResults(results);
        setReferral(res);
      }
      setLoadingResults(false);
    };
    init();

    callActionIfCondition(!providers, getCurrentClinicProviders);
    callActionIfCondition(Object.values(statuses).length === 0, getReqStatuses);
    callActionIfCondition(
      Object.values(priorities).length === 0,
      getReqPriorities
    );
  };

  const onChangeResults = (results: any[]) => {
    setResult(results);
    // setResultsIds(results.map((result: any) => result.id));
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setReqOrderId(null);
    setReferral(null);
    setResult([]);
    setLoadingResults(true);
    setIsCreatingResult(false);
    setCreateLoading(false);
    setSubmitResult(false);
    // setResultsIds([]);
    setSubmitDisabled(true);
  };

  useImperativeHandle(ref, () => ({
    show: (obj: IShow) => show(obj),
    close
  }));

  const onSubmitNewResult = async (result?: any) => {
    if (result) {
      const res: any = await createReferralResult(result, subjectId);
      if (res) {
        const referralResults: any = res[0]?.referralResults;
        if (referralResults) {
          const data = Object.values(referralResults);
          onChangeResults(data);
        }
        setIsCreatingResult(false);
      }
    }
    setSubmitResult(false);
    setCreateLoading(false);
  };

  const handleSubmit = () => {
    setSubmitResult(true);
  };

  const handleOpenFiles = async () => {
    await handleOpenNewTabFiles({
      ...referral?.files,
      ...resultsFiles
    });
  };

  // const onWrapperKeyDown = (e: React.KeyboardEvent) => {
  //   if (
  //     (e.key === 'Tab' && e.shiftKey && e.target === e.currentTarget) ||
  //     e.key === 'Escape'
  //   ) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     if (buttonRef.current) {
  //       //@ts-ignore
  //       buttonRef.current.focus();
  //     }
  //   }
  // };
  // const onButtonKeyDown = (e: React.KeyboardEvent) => {
  //   if (
  //     (e.key === 'Tab' && e.shiftKey && historyPopupOpen) ||
  //     (e.key === 'Escape' && historyPopupOpen)
  //   ) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     setHistoryPopupOpen(false);
  //   }
  //   if (e.key === 'Tab' && !e.shiftKey && historyPopupOpen) {
  //     e.preventDefault();
  //     if (popupRef.current) {
  //       //@ts-ignore
  //       popupRef.current.focus();
  //     }
  //   }
  //   if (
  //     e.key === 'Enter' ||
  //     (e.code === 'Space' && (e.target as HTMLElement).tagName === 'DIV')
  //   ) {
  //     if (e.currentTarget) {
  //       // @ts-ignore
  //       e.currentTarget.click();
  //     }
  //   }
  // };

  const title = () => {
    if (!referral) return null;
    if (isCreatingResult) {
      return (
        <EllipsisText
          maxWidth={460}
        >{`Add Activity to ${referral.name}`}</EllipsisText>
      );
    }
    const creator = users[referral.userId];
    const patient = referral.patient;
    return (
      <ModalTitle>
        <div>
          <TitleHeader>
            <EllipsisText maxWidth={384}>
              {`${patientDisplayName(patient)} - ${patient.id}`}
            </EllipsisText>
          </TitleHeader>

          <TitleHeader>{referral.name}</TitleHeader>
          <SubHeader>
            Created <strong>{formattedDate(referral.dts)}</strong> by{' '}
            <strong>{creator?.name}</strong>
          </SubHeader>
          <SubHeader>
            Last modified on{' '}
            <strong>
              {results.length === 0
                ? formattedDate(referral.dts)
                : formattedDate(
                    new Date(
                      results
                        .map((e: IReferralResult) => new Date(e.dts).getTime())
                        .sort((a: number, b: number) => (a > b ? -1 : 1))[0]
                    ).toISOString()
                  )}
            </strong>
          </SubHeader>
        </div>
        <IconsContainer>
          {(!_.isEmpty(referral.files) || !_.isEmpty(resultsFiles)) && (
            <ButtonIcon
              isButton={true}
              label="Open files"
              haspopup="dialog"
              containerProps={{
                style: {
                  margin: '0px 8px'
                }
              }}
              tooltip="Open files"
              iconStyle={{ margin: '2px 0 0 2px' }}
              name={ICONS_LIST.attachment}
              onClick={() => handleOpenFiles()}
            />
          )}
          {/*<Popover*/}
          {/*  id={ids.REFERRALS_HISTORY_POPUP}*/}
          {/*  open={historyPopupOpen}*/}
          {/*  onOpenChange={(open) => setHistoryPopupOpen(open)}*/}
          {/*  placement="bottom"*/}
          {/*  trigger="click"*/}
          {/*  arrowPointAtCenter*/}
          {/*  autoAdjustOverflow*/}
          {/*  mouseLeaveDelay={1}*/}
          {/*  destroyTooltipOnHide*/}
          {/*  overlayClassName="history-grid"*/}
          {/*  overlayStyle={{*/}
          {/*    zIndex: 30000,*/}
          {/*    width: '500px'*/}
          {/*  }}*/}
          {/*  content={*/}
          {/*    <div*/}
          {/*      tabIndex={0}*/}
          {/*      ref={popupRef}*/}
          {/*      onKeyDown={(e: React.KeyboardEvent) => onWrapperKeyDown(e)}*/}
          {/*    >*/}
          {/*      <HistoryTable*/}
          {/*        dataTable={*/}
          {/*          referral.log !== null && referral.log !== undefined*/}
          {/*            ? Object.values(referral.log)*/}
          {/*            : referral.log*/}
          {/*        }*/}
          {/*        subjectId={subjectId}*/}
          {/*        reqOrderId={reqOrderId}*/}
          {/*        resultsIds={resultsIds}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  }*/}
          {/*>*/}
          {/*  <ButtonIcon*/}
          {/*    isButton={true}*/}
          {/*    label="History"*/}
          {/*    haspopup="listbox"*/}
          {/*    controlsId={ids.REFERRALS_HISTORY_POPUP}*/}
          {/*    tooltip="History"*/}
          {/*    name={ICONS_LIST.history}*/}
          {/*    onClick={() => {*/}
          {/*      setHistoryPopupOpen((open) => !open);*/}
          {/*    }}*/}
          {/*    containerProps={{*/}
          {/*      ref: buttonRef,*/}
          {/*      onKeyDown: (e: React.KeyboardEvent) => onButtonKeyDown(e)*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</Popover>*/}
          {referral.note && (
            <NoteColumn>
              <Label>Note</Label>
              <ButtonIcon
                isButton={true}
                label="Go to note"
                tooltip="Go to note"
                name={ICONS_LIST.noteLink}
                onClick={() => {
                  handleNoteClick(
                    referral.note.id,
                    subjectId,
                    openNoteInNewTab || false,
                    referral.note.typeNote
                  );
                }}
              />
            </NoteColumn>
          )}
          {!!referral.followUpNoteId && (
            <NoteColumn>
              <Label
                style={{
                  textAlign: 'center',
                  lineHeight: '14px',
                  wordBreak: 'keep-all'
                }}
              >
                Follow-up Note
              </Label>
              <ButtonIcon
                isButton={true}
                label="Go to Follow-up Note"
                tooltip="Go to Follow-up Note"
                name={ICONS_LIST.noteLink}
                onClick={() => {
                  handleNoteClick(
                    referral?.followUpNoteId!,
                    subjectId,
                    openNoteInNewTab || false
                  );
                }}
              />
            </NoteColumn>
          )}
        </IconsContainer>
      </ModalTitle>
    );
  };

  const lastResultStatus =
    results?.[0] && (statuses?.[results[0].typeReqStatusId] || null);
  const lastResultPriority =
    results?.[0] && (priorities?.[results[0].typeReqPriorityId] || null);

  const caseManagers = _.union(
    Object.values(patientProviders)
      .filter((v) => v.typeProviderId === 9 && v.isEnabled)
      .map((v) => v.providerId)
  );
  const renderingProvider = referral
    ? allProviders[referral.renderingProviderId]
    : null;
  const renderingProviderName = renderingProvider
    ? `${renderingProvider.firstName} ${renderingProvider.lastName}`
    : '';
  const caseManagersList = caseManagers.filter((v) => allProviders[v]);

  const defaultWidth = 700;
  const headerRight = !loadingResults && (
    <Button
      onKeyDown={onKeyDownAccessibility}
      disabled={isCreatingResult ? submitDisabled : false}
      loading={isCreatingResult ? createLoading : false}
      onClick={() =>
        isCreatingResult ? handleSubmit() : setIsCreatingResult(true)
      }
      shape="round"
    >
      {isCreatingResult ? 'Save' : 'Add'}
    </Button>
  );

  return (
    <Modal
      isModalSlider={sliderMode}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        headerRight,
        title: title(),
        backButton: isCreatingResult,
        onClickBack: () => setIsCreatingResult(false),
        noWrap: true
      }}
      id={ids.REFERRALS_ACTIVITY_LOG_MODAL}
      visible={visible}
      title={title()}
      onClose={close}
      width={defaultWidth}
      headerBorder={true}
      modalProps={{
        destroyOnClose: true
      }}
      back={isCreatingResult}
      onClickBack={() => setIsCreatingResult(false)}
      afterClose={setToDefault}
      buttons={headerRight}
    >
      <ModalBody className="custom-checkbox">
        {loadingResults && <AbsoluteLoader />}
        {!referral ? (
          <></>
        ) : isCreatingResult ? (
          <NewResultForm
            subjectId={subjectId}
            reqOrderId={reqOrderId}
            submit={submitResult}
            setLoading={(loading) => setCreateLoading(loading)}
            setSubmitDisabled={(disabled) => setSubmitDisabled(disabled)}
            onSubmit={onSubmitNewResult}
            goBack={() => setIsCreatingResult(false)}
            lastResultStatus={lastResultStatus}
            lastResultPriority={lastResultPriority}
            providers={providers}
          />
        ) : (
          <ReferralResults>
            <ResultsContainer
              role="list"
              tabIndex={0}
              aria-label="results"
              sliderMode={sliderMode}
            >
              {results.length === 0 && <List dataSource={[]} />}
              {results
                .sort(
                  (a, b) =>
                    new Date(b.dts).getTime() - new Date(a.dts).getTime()
                )
                .map((result) => {
                  const user = users[result.userId];
                  const status = statuses?.[result.typeReqStatusId] || null;
                  const priority =
                    priorities?.[result.typeReqPriorityId] || null;

                  return (
                    <ResultLogCard
                      key={`result-${result.id}`}
                      result={result}
                      user={user}
                      status={status}
                      priority={priority}
                    />
                  );
                })}
            </ResultsContainer>
            <Footer>
              <div style={{ marginBottom: 10 }}>
                <Checkbox
                  checkboxProps={{
                    children: `Create task for rendering provider: ${renderingProviderName}`
                  }}
                  value={referral.createTaskRenderingProvider}
                  onChangeAction={async () => {
                    const res = await updateReqOrder(subjectId, referral.id, {
                      createTaskRenderingProvider:
                        !referral.createTaskRenderingProvider
                    });
                    if (res) {
                      setReferral(res);
                    }
                  }}
                />
              </div>
              {caseManagersList.length > 0 && (
                <div>
                  <Checkbox
                    checkboxProps={{
                      children: `Create task for case manager(s):
                      ${caseManagersList
                        .map(
                          (v) =>
                            `${allProviders[v].firstName} ${allProviders[v].lastName}`
                        )
                        .join(', ')}`
                    }}
                    value={referral.createTaskCaseManager}
                    onChangeAction={async () => {
                      const res = await updateReqOrder(subjectId, referral.id, {
                        createTaskCaseManager: !referral.createTaskCaseManager
                      });
                      if (res) {
                        setReferral(res);
                      }
                    }}
                  />
                </div>
              )}
            </Footer>
          </ReferralResults>
        )}
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state: IStore, ownProps: IOwnProps) => {
  const users = state.adminPanel.users.summary;
  const providers = state.client.lists.clinicProviders;
  return {
    patientProviders:
      state.patientsProviders.patients[ownProps.subjectId]?.summary || {},
    allProviders: state.user.providers.summary,
    users,
    providers,
    statuses: state.reqOrders.reqStatuses,
    priorities: state.reqOrders.reqPriorities
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  React.forwardRef(ReferralResultModal)
);

import React, { FC, useMemo } from 'react';

import { useGroupType } from 'src/App/Admin/Pages/GroupTypes/store/Hooks/useGroupType';

import { Info } from 'src/Activities/Schedule/Common/Classes';
import { Container } from './styled';

interface IProps {
  typeAppointmentLocationId: number;
}

const Component: FC<IProps> = ({ typeAppointmentLocationId }) => {
  const typeAppointmentLocation = useGroupType('typeAppointmentLocation');

  const apptLocation = useMemo(() => {
    return (
      typeAppointmentLocation.finder(typeAppointmentLocationId)?.name || ''
    );
  }, [typeAppointmentLocationId, typeAppointmentLocation.allGenericTypes]);

  return (
    <Container>
      <label className={'preview-label-style'}>Location</label>
      <Info>{apptLocation}</Info>
    </Container>
  );
};

export default React.memo(Component);

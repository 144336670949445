import React, { FC } from 'react';
import { Tooltip, List } from 'antd';

import store from 'src/store';
import { AppUrl } from 'src/Framework/Common/AppUrl';
import {
  updateCurrentActivity,
  loadActivityTab
} from 'src/App/ActivityPanel/Store/ActivityActions';
import ButtonIcon, {
  ICONS_LIST,
  IIcon
} from 'src/Framework/Controls/ButtonIcon';

import { Container } from './styled';
import {
  getNoteInListLink,
  getNoteM1Link
} from 'src/Activities/Counseling/NewNotes/utils';

interface INote {
  id: number;
  subject?: string;
  summary?: string;
  typeNote?: string;
  patientId?: number;
}

interface IProps {
  patientId?: string | number;
  checkType?: boolean;
  inNewTab?: boolean;
  note?: INote;
  multipleNotes?: INote[];
  callback?: (note: INote) => any;
  hideNotCounselingNote?: boolean;
  buttonProps?: Partial<IIcon>;
}

export const generateLinkToNoteInList = (
  patientId: string | number,
  noteId: string | number
) => `${AppUrl.CLIENT}/${patientId}${AppUrl.PATIENT_NOTES}?noteId=${noteId}`;
export const generateLinkToNoteEdit = (
  patientId: string | number,
  noteId: string | number
) => `${AppUrl.CLIENT}/${patientId}${AppUrl.PATIENT_NOTES}/edit/${noteId}`;

export const handleNoteClick = (
  noteId: number | string,
  patientId: number | string,
  inNewTab?: boolean,
  typeNote?: string
) => {
  let targetURL;
  if (typeNote && (typeNote === 'soap' || typeNote === 'progress')) {
    targetURL = getNoteInListLink(patientId, noteId);
  } else {
    targetURL = getNoteM1Link(patientId, noteId);
  }
  if (!inNewTab) {
    updateCurrentActivity({
      targetURL
    });
  } else {
    loadActivityTab(targetURL, store.dispatch);
  }
};

const Component: FC<IProps> = ({
  note,
  patientId,
  checkType,
  inNewTab,
  multipleNotes,
  callback,
  hideNotCounselingNote,
  buttonProps
}: IProps) => {
  if (multipleNotes) {
    if (multipleNotes.length === 0) return null;
    return (
      <Tooltip
        //@ts-ignore
        color="white"
        title={
          multipleNotes.length > 1 && (
            <Container>
              <List
                bordered
                dataSource={multipleNotes}
                renderItem={(item) => (
                  <List.Item
                    className="list-item"
                    onClick={() => {
                      const id = patientId ? patientId : item.patientId;
                      if (id) {
                        handleNoteClick(item.id, id, inNewTab, item.typeNote);
                        if (callback) {
                          callback(item);
                        }
                      }
                    }}
                  >
                    <span className="note-name">{item.subject}</span>
                  </List.Item>
                )}
              />
            </Container>
          )
        }
        trigger="click"
        overlayStyle={{ zIndex: 10000 }}
      >
        <ButtonIcon
          isButton={true}
          {...buttonProps}
          changeColorOnHover={true}
          tooltip={
            multipleNotes.length === 1
              ? multipleNotes[0].subject
              : `(${multipleNotes.length}) Notes`
          }
          label={
            multipleNotes.length === 1
              ? multipleNotes[0].subject
              : `(${multipleNotes.length}) Notes`
          }
          containerProps={
            multipleNotes.length === 1
              ? {
                  onClick: () => {
                    if (multipleNotes.length === 1) {
                      const id = patientId
                        ? patientId
                        : multipleNotes[0].patientId;
                      if (id) {
                        handleNoteClick(
                          multipleNotes[0].id,
                          id,
                          inNewTab,
                          multipleNotes[0].typeNote
                        );
                        if (callback) {
                          callback(multipleNotes[0]);
                        }
                      }
                    }
                  }
                }
              : {}
          }
          name={ICONS_LIST.noteLink}
        />
      </Tooltip>
    );
  }
  if (note) {
    if (note.typeNote !== 'counseling' && hideNotCounselingNote) return null;
    return (
      <ButtonIcon
        isButton={true}
        {...buttonProps}
        tooltip={note.subject || note.summary}
        label={note.subject || note.summary}
        name={ICONS_LIST.noteLink}
        changeColorOnHover={true}
        onClick={() => {
          if (patientId) {
            handleNoteClick(note.id, patientId, inNewTab, note.typeNote);
            if (callback) {
              callback(note);
            }
          }
        }}
      />
    );
  }
  return null;
};

Component.defaultProps = {
  checkType: true
};

export default Component;

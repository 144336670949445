import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { IComment } from "../../../types";

export const getAppointmentComments = async (id: number) => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.AppointmentComments, {
            withCredentials: true,
            params: {
                id
            }
        });
        if (response.data.success) {
            return response.data.result
        }
    } catch (e) {
        return
    }
}

export const postAppointmentComments = async (data: Partial<IComment>): Promise<IComment | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(ApiUrl.AppointmentComments, data, {
            withCredentials: true,
        });
        if (response.data.success) {
            const list = response.data.result.summary
            const item = Object.values(list)[0] as IComment
            return item
        }
        return
    } catch (e) {
        return
    }
}

export const patchAppointmentComments = async (data: Partial<IComment> & { id: number }): Promise<IComment | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.patch(`${ApiUrl.AppointmentComments}/${data.id}`, data, {
            withCredentials: true,
        });
        if (response.data.success) {
            const list = response.data.result.summary
            const item = Object.values(list)[0] as IComment
            return item
        }
        return
    } catch (e) {
        return
    }
}
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'antd';

import Collapse from 'src/Framework/Controls/CollapseA11y';
import ThreeStateCheckbox from 'src/Framework/Controls/ThreeStateCheckbox';

import SelectContainer from '../../../SelectContainer';

import {
  DropdownContainer,
  Header,
  TitleContainer,
  CollapseContainer
} from './styled';

import { ITableGroupMultiSelect } from '../types';
import NoData from '../../../Table/NoData';
import InnerPanel from './InnerPanel';

interface IProps extends ITableGroupMultiSelect {}

const Component: FC<IProps> = ({
  groupsOptions,
  childrenOptions,
  onChange,
  value
}: IProps) => {
  const [search, setSearch] = useState('');

  const onChangeSearch = useCallback((search: string) => {
    setSearch(search);
  }, []);

  const filteredChildren = useMemo(() => {
    const options = childrenOptions.filter((v) => {
      if (!v.isActive) {
        if (!value.includes(v.id)) return false;
      }
      return true;
    });
    if (search.length === 0) return { all: options, filtered: options };
    return {
      all: options,
      filtered: options.filter((v) =>
        v.label.toLowerCase().includes(search.toLowerCase())
      )
    };
  }, [childrenOptions, search, value]);

  const filteredGroups = useMemo(() => {
    if (search.length === 0)
      return [...groupsOptions].sort((a, b) => a.label.localeCompare(b.label));
    return groupsOptions
      .filter((v) => {
        if (v.label.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        // Check if inner option has valid label
        const innerOptions = childrenOptions.filter(
          (option) => option.parentId === v.id
        );
        if (
          innerOptions.some((v) =>
            v.label.toLowerCase().includes(search.toLowerCase())
          )
        ) {
          return true;
        }
        // ---
        return false;
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [groupsOptions, search, childrenOptions]);

  // Logic for all checkbox
  const innerValues = value.filter((v) =>
    filteredChildren.filtered.some((val) => val.id === v)
  );
  const checkedAll = filteredChildren.filtered.length === innerValues.length;
  const uncheckedAll = innerValues.length === 0;
  const onChangeAll = () => {
    if (checkedAll) {
      const arr = [...value];
      innerValues.forEach((val) => {
        const i = arr.findIndex((v) => v === val);
        if (i !== -1) {
          arr.splice(i, 1);
        }
      });
      onChange(arr);
      return;
    }
    if (uncheckedAll) {
      const arr = [...value];
      filteredChildren.filtered.forEach((v) => {
        arr.push(v.id);
      });
      onChange(arr);
      return;
    }
    const arr = [...value];
    filteredChildren.filtered.forEach((v) => {
      const i = arr.findIndex((val) => val === v.id);
      if (i === -1) {
        arr.push(v.id);
      }
    });
    onChange(arr);
  };
  // ----

  return (
    <DropdownContainer>
      <Header>
        <SelectContainer
          label="Search"
          type="input"
          star={false}
          value={search}
          onChange={onChangeSearch}
          searchIcon={true}
          bottomMargin={false}
        />
      </Header>
      <TitleContainer>
        <Row align="middle" gutter={16} justify="space-between">
          <Col>
            <ThreeStateCheckbox
              id=""
              checkedAll={checkedAll}
              uncheckedAll={uncheckedAll}
              onChange={onChangeAll}
            >
              <b>All</b>
            </ThreeStateCheckbox>
          </Col>
          <Col></Col>
        </Row>
      </TitleContainer>
      <CollapseContainer>
        <Collapse
          uppercaseHeader={false}
          icon="cleanAnimIcon"
          className="TypeMultiselect-slider"
          defaultActiveKey={groupsOptions.map((v) => v.id)}
        >
          {filteredGroups.length === 0 && (
            <NoData removeVerticalPaddings={true} />
          )}
          {filteredGroups.map((group) => {
            const groupOptions = filteredChildren.all.filter(
              (v) => v.parentId === group.id
            );
            if (!group.isActive) {
              if (!groupOptions.some((v) => value.includes(v.id))) {
                return null;
              }
            }
            if (groupOptions.length === 0) return null;

            return InnerPanel({
              group,
              value,
              groupsOptions,
              childrenOptions:
                search.length > 0
                  ? group.label.toLowerCase().includes(search.toLowerCase())
                    ? filteredChildren.all
                    : filteredChildren.filtered
                  : filteredChildren.filtered,
              onChange,
              search
            });
          })}
        </Collapse>
      </CollapseContainer>
    </DropdownContainer>
  );
};

export default React.memo(Component);

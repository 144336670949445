import React from 'react';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

interface IProps {
  stroke?: string;
}

export const CalendarSvg = ({ stroke }: IProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33398 0.666748V3.33341"
      stroke={globalStyleVariables.brandGray}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.667 0.666748V3.33341"
      stroke={globalStyleVariables.brandGray}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0003 3.3335H2.00033C1.26395 3.3335 0.666992 3.93045 0.666992 4.66683V14.0002C0.666992 14.7365 1.26395 15.3335 2.00033 15.3335H14.0003C14.7367 15.3335 15.3337 14.7365 15.3337 14.0002V4.66683C15.3337 3.93045 14.7367 3.3335 14.0003 3.3335Z"
      stroke={globalStyleVariables.brandGray}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.914062 7.8335H15.0908V6.8335H0.914062V7.8335Z"
      fill={globalStyleVariables.brandGray}
    />
  </svg>
);

export interface IAction {
  type: string;
  payload: any;
}

export interface IStateReducer {
  apptSubscription: Record<string, IApptSubscription>;
  facilities: Record<string, FacilitySimpleDto>;
}

export interface IApptSubscription {
  ClinicId: number;
  Email: string;
  Id: number;
  ProviderId: number;
  UserId: number;
  IsActive: boolean;
  ModifiedDate: string;
}

export interface FacilitySimpleDto {
  id: number;
  clinicId: number;
  name: string;
  code: string;
  isActive: boolean;
}

export interface IApptBackgroundSettings {
  radio: 'apptType' | 'apptStatus';
  colors: {
    late: string;
    arrived: string;
    ready: string;
    admitted: string;
    discharged: string;
  };
}

export enum TypeReservationType {
  flag = 'flag',
  fillBackground = 'fillBackground',
  border = 'border'
}

export interface ICellSettings {
  order: string[];
  noteSettings: {
    icon: boolean;
    text: boolean;
  };
  show: {
    [key: string]: boolean;
  };
  reservationSettings?: {
    overlap: boolean;
  };
  reservationType?: TypeReservationType;
}

type StatusName = string;

export interface IStatusesSound {
  sounds: {
    [status: string]: string | null;
  };
  disableSound: boolean;
  disableStatusSound?: Record<StatusName, boolean>;
  alerts?: {
    [status: string]: IStatusAlert;
  };
}

export interface IStatusAlert {
  onlyForSelected: boolean;
  selectedProviders: IProviderClinics;
}

interface IProviderClinics {
  [providerId: string]: number[];
}

export const defaultStatusAlert: IStatusAlert = {
  onlyForSelected: false,
  selectedProviders: {}
};

export interface IScannerSettings {
  saveFileFormat?: string;
  scannerId?: string;
  deviceSettings?: {
    currentScanner: string;
    currentCamera: string;
    bShowUI: boolean;
    bADF: boolean;
    bDuplex: boolean;
    nPixelType: string;
    nResolution: string;
    isVideoOn: boolean;
  };
}

export interface IUserGeneralSettings {
  disableRiskWidget?: boolean;
  scannerSettings?: IScannerSettings;
}

export interface IScannerInfo {
  DriverType: string;
  Manufacturer: string;
  ProductFamily: string;
  ProductName: string;
}

import React, { FC, useRef } from 'react';

import { useAppSelector } from 'src/store';

import { timeDiff } from 'src/Activities/Schedule/Utils/Utils';
import { ScheduleTimeProps } from 'src/Activities/Schedule/Common/Interfaces';
import { TypeReservationType } from 'src/App/UserPreferences/store/types';
import { DefaultItemHeight } from '../ScheduleCol_Styles';
import ReservationItem from './ReservationItem';
import { checkClinicOutOfRange } from '../utils';

type IProps = {
  scheduleGroup: any;
  idx: number;
  scheduleTimeProps: ScheduleTimeProps;
};

export const Component: FC<IProps> = (props: IProps) => {
  const itemHeight = useRef(DefaultItemHeight());
  const reservationType = useAppSelector(
    (state) =>
      state.user.preferences.calendarCellSettings?.parsedValue
        ?.reservationType || TypeReservationType.flag
  );
  const { scheduleGroup, scheduleTimeProps } = props;
  const { regularMeetingTime, dayStartTime } = scheduleTimeProps;
  const {
    color,
    isBlock,
    duration,
    startTime,
    isReservation,
    appointmentFor,
    reservation
  } = scheduleGroup;
  let groupTitle = appointmentFor;
  const parts = parseInt(duration, 10) / regularMeetingTime;
  const groupUIOffset =
    (timeDiff(startTime, dayStartTime) / regularMeetingTime) *
    scheduleTimeProps.slotHeight;
  if (isBlock && !isReservation) {
    groupTitle = appointmentFor;
  }
  if (groupTitle == 'Blank' || groupTitle == 'None') {
    return <div />;
  }

  if (reservationType !== TypeReservationType.fillBackground) {
    return null;
  }
  const sizeToCheck = Math.round(parts);
  const size = isNaN(sizeToCheck) || sizeToCheck < 0 ? 0 : sizeToCheck;
  const roundParts = Array.from(Array(size).keys());
  const isInteger = Number.isInteger(parts);

  if (
    !checkClinicOutOfRange({
      clinicCode: reservation.clinicCode,
      endTime: reservation.endTime
    })
  ) {
    return null;
  }

  return (
    <>
      {roundParts.map((v, index) => {
        let heightDivider = 1;
        if (!isInteger && index === roundParts.length - 1) {
          heightDivider = roundParts.length - parts;
        }
        return (
          <ReservationItem
            key={index}
            index={index}
            isReservation={isReservation}
            heightDivider={heightDivider}
            color={color}
            groupTitle={groupTitle}
            groupUIOffset={groupUIOffset}
            itemHeight={itemHeight.current}
          />
        );
      })}
    </>
  );
};

export const ReservationBackground = React.memo(Component);

import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { defaultApptBackgroundColor } from 'src/App/UserPreferences/store';
import { DefaultItemHeight, DefaultItemWidth } from '../Main_Styles';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { CALENDAR_ZINDEX } from './utils';

export { DefaultItemHeight };

export const ScheduleLayoutPadding = 25;

export const ScheduleGroupPopupContainer = styled.div`
  position: relative;
  width: 840px;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const ScheduleColContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;
export const ReservationBackgroundContainer = styled.div``;

interface EventItemProps {
  color: any;
  hasConflict: boolean;
  isBlock: boolean;
  count: number;
  index: number;
}

export const EventItem = styled.div`
  width: 185px;
  height: ${DefaultItemHeight()}px;
  background-color: ${(props: EventItemProps) =>
    props.isBlock
      ? '#f7f7f7'
      : hexToRgba(props.color || defaultApptBackgroundColor, 0.2)};
  border: ${(props: EventItemProps) =>
    props.hasConflict ? '2px dashed red' : 'none'};
  text-align: start;
  padding: 1rem;
  border-radius: 4px;
  position: relative;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  margin-bottom: ${(props: EventItemProps) =>
    props.count !== props.index + 1 ? '0.5rem' : '0'};

  &.selected {
    border: ${(props: EventItemProps) => {
    if (!props.hasConflict) {
      return `2px solid ${props.color}`;
    } else if (props.hasConflict) {
      return '2px solid #FF2828';
    } else {
      return '2px solid transparent';
    }
  }};
  }
`;

export const TimeBlock = styled.div`
  position: absolute;
  bottom: 4px;
  left: 20px;
  color: ${globalStyleVariables.brandGray};
  font-size: 11px;
  font-weight: 400;
`;

interface ScheduleGroupsLayoutProps {
  heightByUnit: number;
}

export const ScheduleGroupsLayout = styled.div`
  /* padding: 25px 0 0 0;
  height: ${(props: ScheduleGroupsLayoutProps) =>
    `${DefaultItemHeight() * props.heightByUnit + ScheduleLayoutPadding}px`};
  width: 100%; */
`;

interface ScheduleOnesLayoutProps {
  heightByUnit: number;
}

export const ScheduleOnesLayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 25px 0 0 0;
  width: 100%;
  border-right: 2px solid rgb(230, 230, 230);
  height: calc(${(props: ScheduleOnesLayoutProps) => `${DefaultItemHeight() * props.heightByUnit - ScheduleLayoutPadding}px`} + 0.7rem);

  .emptycells-container {
    z-index: 3;
  }

  #events-cells {
    z-index: 4;
  }
`;
export const ScheduleOnesLayoutSnapCol = styled.div`
  position: relative;
  width: 100%;
  height: ${(props: ScheduleOnesLayoutProps) => {
    return `${DefaultItemHeight() * props.heightByUnit + ScheduleLayoutPadding
      }px`;
  }};

  .emptycells-container {
    z-index: 3;
  }

  #events-cells {
    z-index: 4;
  }
`;

export function TotalDragSourceStyle(
  x: number,
  y: number,
  width: number,
  height: number,
  patient?: any,
  firstInReservation?: boolean,
  lastInReservation?: boolean
): any {
  return {
    position: `absolute`,
    top: `${DefaultItemHeight() * y + 2}px`,
    left: `${DefaultItemWidth * x + 1}px`,
    width: `${DefaultItemWidth * width - 1}px`,
    height: `${DefaultItemHeight() * height - (patient && patient.inReservation ? 4 : 2)
      }px`,
    transition: 'width 0.5s ease-out',
    zIndex: 2,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    background: `${(patient && patient.inReservation) ||
        firstInReservation ||
        lastInReservation
        ? 'transparent'
        : 'white'
      }`
  };
}

export function DragSourceStyle(): any {
  return {
    position: `relative`,
    width: `100%`,
    height: `100%`,
    transition: 'width 0.5s ease-out',
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  };
}

export function DropTargetStyle(
  numHeight: number,
  highlightHeight: number,
  highlightX: number,
  highlightWidth: number
): any {
  return {
    position: `absolute`,
    top: `${DefaultItemHeight() * numHeight}px`,
    left: `${DefaultItemWidth * highlightX}px`,
    width: `${DefaultItemWidth * highlightWidth}px`,
    height: `${DefaultItemHeight() * highlightHeight}px`,
    border: `solid red 1px`,
    backgroundColor: `red`,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    opacity: 0
  };
}

interface EmptyRegionProps {
  x: number;
  y: number;
  width: number;
  height: number;
  disableHover: Boolean;
  onDrop?: boolean;
  dragInProgress?: boolean;
  outOfTimeRange?: boolean;
  popoverOpen: boolean;
}

export const EmptyRegionComponent = styled.div<EmptyRegionProps>`
  position: absolute;
  top: ${(props) => DefaultItemHeight() * props.y + 1}px;
  left: ${(props) => DefaultItemWidth * props.x + 1}px;
  width: ${(props) => DefaultItemWidth * props.width - 1}px;
  height: ${(props) => DefaultItemHeight() * props.height - 2}px;
  cursor: ${(props) => (props.disableHover ? 'no-drop' : 'initial')};
  background: transparent;
  border: 2px solid transparent;
  transition: background 0.15s linear;
  z-index: ${CALENDAR_ZINDEX.emptyCells};

  .range-time {
    opacity: 0;
    transition: all 0.15s linear;
    margin: 0 0 0 20px;
    font-size: 14px;
    pointer-events: none;
  }

  &:hover {
    .range-time {
      opacity: 1;
    }

    background: ${(props) =>
    props.disableHover ? `transparent` : `#33333310`};
  }

  ${({ popoverOpen, disableHover }) =>
    popoverOpen &&
    `
  .range-time {
      opacity: 1;
    }
    background: ${disableHover ? `transparent` : `#33333310`};
  `}
  &.selected {
    border: ${(props) => (props.disableHover ? `none` : `dashed 1px #666666`)};
    background: ${(props) => (props.disableHover ? `transparent` : `#F7F7F7`)};
  }

  &.isHovering {
    border: 2px solid ${globalStyleVariables.newBrandingPrimary200};
  }

  /* ${(props) => props.dragInProgress && `z-index: 10;`} */
  ${({ outOfTimeRange }) =>
    outOfTimeRange &&
    `
      cursor: not-allowed;
      background-color: rgba(0,0,0,0.05);
    `}
`;

export const RangeTime = styled.div``;

export const RangeTimeContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
`;

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Container = styled.div<IListContainer>`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: ${({ listHeight }) => listHeight || '100%'};
  min-height: 330px !important;
  padding: 0 10px;

  .appts-history-list {
    min-height: 330px !important;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .appt-list-item {
    padding: 0 !important;
    border-bottom: none !important;
  }
`;

export const HeaderContainer = styled.div`
  flex: 0 0 auto;

  .sort-icon-container {
    height: 10px;
    width: 10px;

    .sortIcon {
      height: 10px;
      width: 10px;
      background-color: ${globalStyleVariables.brandGray};
      mask-size: contain;
      mask-position: center center;
      mask-repeat: no-repeat no-repeat;
      margin: 0 0 0 5px;

      &.up {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
  }
`;

export const PaginationContainer = styled.div`
  flex: 0 0 40px;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

interface IListContainer {
  listHeight?: string;
}

export const MainContainer = styled.div`
  position: relative;
  height: 100%;
  min-height: 360px !important;
  display: flex;
  flex-direction: column;
`;

import React, { FC } from 'react';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import {
  ActivityPath,
  gotoClient
} from 'src/App/ActivityPanel/Store/ActivityActions';

interface IProps {
  patientId: string | number;
  appointmentId?: number;
  id: string;
}

const Component: FC<IProps> = ({ patientId, appointmentId, id }: IProps) => {
  return (
    <ButtonIcon
      isButton={true}
      label="Create Note"
      id={id}
      changeColorOnHover={true}
      name={ICONS_LIST.apptNote}
      tooltip={'Create Note'}
      onClick={() =>
        gotoClient(`${patientId}`, ActivityPath.CREATE_NOTE, {
          appointmentId
        })
      }
    />
  );
};

export default Component;

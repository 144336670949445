import _ from 'lodash';
import store from 'src/store';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

import { IType } from './types';

import { actionsTypes } from './index';
import { ISort } from 'src/Framework/Controls/Table/types';

export const onCreateUpdateItem = (payload: IType) => {
  const data = { ...store.getState().adminPanel.providerTypes.summary };
  if (data[payload.id]) {
    data[payload.id] = { ...data[payload.id], ...payload };
  } else {
    data[payload.id] = { ...payload };
  }
  setProvidersTypes(data);
};

const setOrChangeItem = (data: IType) => {
  const types = { ...store.getState().adminPanel.providerTypes.summary };
  types[data.id] = data;
  setProvidersTypes(types);
};

const onDeleteApptType = (
  typeId: number | string,
  appointmentReasonsId: number | string
) => {
  const types = { ...store.getState().adminPanel.providerTypes.summary };
  const type = _.cloneDeep(types[typeId]);
  if (type) {
    const appointmentReasons = { ...type.appointmentReasons };
    delete appointmentReasons[appointmentReasonsId];
    types[typeId] = {
      ...type,
      appointmentReasons
    };
  }
  setProvidersTypes(types);
};

export const setSorting = (payload: ISort) => {
  store.dispatch({
    type: actionsTypes.SET_TABLE_SORT,
    payload
  });
};

const onDeleteSuggestedApptType = (
  typeId: number | string,
  appointmentReasonsId: number | string
) => {
  const types = { ...store.getState().adminPanel.providerTypes.summary };
  const type = _.cloneDeep(types[typeId]);
  if (type) {
    const suggestedAppointmentReasons = { ...type.suggestedAppointmentReasons };
    delete suggestedAppointmentReasons[appointmentReasonsId];
    types[typeId] = {
      ...type,
      suggestedAppointmentReasons
    };
  }
  setProvidersTypes(types);
};

export const patch = async (body: Partial<IType>) => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.TYPE_PROVIDERS}/${body.id}`,
      body,
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data) {
      if (response.data.success) {
        const data: any = Object.values(response.data.result.summary)[0];
        setOrChangeItem(data);
        return data;
      }
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const post = async (body: Partial<IType>) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.TYPE_PROVIDERS}`,
      body,
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data) {
      if (response.data.success) {
        const data: any = Object.values(response.data.result.summary)[0];
        setOrChangeItem(data);
        return data;
      }
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const deleteAppointmentReason = async (
  id: string | number,
  appointmentReasonsId: string | number
) => {
  try {
    const response = await APIHandler.AxiosInstance.delete(
      `${ApiUrl.TYPE_PROVIDERS}/${id}/appointmentReasons/${appointmentReasonsId}`,
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data) {
      if (response.data.success) {
        onDeleteApptType(id, appointmentReasonsId);
        return true;
      }
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const putAppointmentReason = async (
  id: string | number,
  appointmentReasonsId: string | number
) => {
  try {
    const response = await APIHandler.AxiosInstance.put(
      `${ApiUrl.TYPE_PROVIDERS}/${id}/appointmentReasons/${appointmentReasonsId}`,
      {},
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data) {
      if (response.data.success) {
        const data: any = Object.values(response.data.result.summary)[0];
        setOrChangeItem(data);
        return data;
      }
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

//
export const deleteSuggestedAppointmentReason = async (
  id: string | number,
  appointmentReasonsId: string | number
) => {
  try {
    const response = await APIHandler.AxiosInstance.delete(
      `${ApiUrl.TYPE_PROVIDERS}/${id}/suggestedAppointmentReasons/${appointmentReasonsId}`,
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data) {
      if (response.data.success) {
        onDeleteSuggestedApptType(id, appointmentReasonsId);
        return true;
      }
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const putSuggestedAppointmentReason = async (
  id: string | number,
  appointmentReasonsId: string | number
) => {
  try {
    const response = await APIHandler.AxiosInstance.put(
      `${ApiUrl.TYPE_PROVIDERS}/${id}/suggestedAppointmentReasons/${appointmentReasonsId}`,
      {},
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data) {
      if (response.data.success) {
        const data: any = Object.values(response.data.result.summary)[0];
        setOrChangeItem(data);
        return data;
      }
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const setProvidersTypes = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET,
    payload
  });
};

export const getProvidersTypes = async () => {
  try {
    const response = await APIHandler.AxiosInstance.get(ApiUrl.TYPE_PROVIDERS, {
      withCredentials: true
    });
    if (response.data) {
      if (response.data.success) {
        const data = response.data.result.summary;
        setProvidersTypes(data);
        return data;
      }
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

import React from 'react';
import moment from 'moment';
import { Col, notification, Row } from 'antd';
import _ from 'lodash';
import cn from 'classnames';
import 'react-tabulator/lib/css/bootstrap/tabulator_bootstrap4.min.css';

import AlertPopUp, { IModalHandles } from 'src/Framework/Controls/AlertPopUp';
import ElementTitle from 'src/Framework/Controls/ElementTitle';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import Button from 'src/Framework/Controls/Button';
import Checkbox from 'src/Framework/Controls/Checkbox';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import ReasonSelector from 'src/Framework/Controls/Selectors/ReasonSelector';
import CategorySelector from 'src/Framework/Controls/Selectors/CategorySelector';
import {
  getReasonByCode,
  getReasonByID
} from 'src/Framework/Controls/Selectors/ReasonSelector/utils';
import ProviderSelector from 'src/Framework/Controls/Selectors/ProviderSelector';
import { getClinicByCode } from 'src/Framework/Controls/Selectors/ClinicSelector';
import {
  getClinicConfig,
  getProviderByCode,
  getProviderById,
  getProviderClinicAssignment,
  providersValidForReason
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { ClinicSelector } from 'src/Framework/Controls/Selectors';
import ConditionalButton from 'src/Framework/Controls/ConditionalButton';
import Radio from 'src/Framework/Controls/Radio';
import { formattedPrefName } from 'src/Framework/Shared/Shared';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { patientDisplayName } from 'src/Framework/util/format';
import store from 'src/store';
import TESTS_IDS from 'src/tests-script/pages/Schedule/CreateAppt';

import {
  EApptTypeCode,
  EEventTypeCode,
  eventPermission
} from 'src/Activities/Schedule/utils';

import {
  findOpt,
  getScheduleEventTypeName,
  getTimeObject,
  mapClinic,
  momentDateHandFormatter,
  validateClinicOpenByDate
} from 'src/Activities/Schedule/Utils/Utils';
import {
  getFormsFromClinic,
  getPatientsInCategory
} from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import PatientsGroupItem from 'src/Activities/Schedule/Common/Editors/PatientGroupItem/PatientsGroupItem';
import AppointmentHistoryContainer from 'src/Activities/Schedule/Common/Editors/AppointmentHistoryEditor/AppointmentHistory';
import SubjectSearch from 'src/Activities/Schedule/Common/Select/Tabulators/SubjectSearch';
import PatientDetail from 'src/Activities/Schedule/Common/Editors/PatientDetail';
import SettingsAppointment from 'src/Activities/Schedule/Common/Editors/SettingsAppointment';
import SeriesEditor from 'src/Activities/Schedule/Common/Editors/SeriesEditor';
import {
  IOccurrence,
  RecurrenceDisplay
} from 'src/Activities/Schedule/Common/Editors/RecurrenceDisplay';
import { addApptUIModel } from 'src/Activities/Schedule/Store/Models/ScheduleDefinition';
import SchedulingContainer from 'src/Activities/Schedule/Common/Editors/SchedulingEditor';
import ConflictsBar from 'src/Activities/Schedule/ConflictsBar/ConflictsBar';
import { IAlert } from 'src/Activities/Counseling/Dashboard/Alerts/store/types';
import * as insuranceCompaniesActions from 'src/App/Admin/Pages/InsuranceCompanies/store/Actions';
import {
  generateCodesByProvidersCodes,
  getDateTimeSelection,
  getProviderSelection,
  IProviderCode,
  setEventDuration,
  setEventEndTime,
  setEventStartTime,
  validateEndTimeRule,
  validateStartTimeRule
} from './utils';
import { ITelehealth } from './types';
import { IProps as IContainerProps } from './index';
import { IApptResponse } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import { IPatientInsurance } from 'src/Activities/Subject/Patients/Insurance/store/types';
import * as hippoActions from 'src/App/Hippa/store/Actions';
import * as hippoSetters from 'src/App/Hippa/store/Actions/Setters';
import { TicketDetailHippaAction } from 'src/App/Hippa/store/types';
import PrivacyPolicyGroupStatusModal, {
  IModalHandles as IPolicyModal
} from 'src/App/Hippa/Components/PrivacyPolicyGroupStatusModal';
import { isHippoExpired } from 'src/App/Hippa/store/utils';
import { isPrivacyPolicyEnabled } from 'src/App/Hippa/Components/utils';
import { ListTypeNames } from 'src/Activities/Schedule/WaitingListSettings/store/utils';
import { TypeListEnum } from 'src/Activities/Schedule/WaitingListSettings/store/types';
import GroupTypesSelector from 'src/App/Admin/Pages/GroupTypes/Components/GroupTypesSelector';

import AlertsBar, { calculateAlertsCount } from './AlertsBar';
import AssignedProviders from './AssignedProviders';
import Telehealth from './Telehealth';
import AlertsManageContainer from './AlertsManageContainer';
import Comment from './Comment';

import {
  AlignRight,
  OuterContainer,
  PopupContent
} from 'src/Activities/Schedule/Common/Classes';
import {
  AbsoluteContainer,
  AddButtonContainer,
  AppointmentTypeContainer,
  BlockSelfCheckinContainer,
  BtnContainer,
  ClinicInfo,
  ColumnWrapper,
  DateAndTimeBlock,
  LeftColumn,
  MaxParticipantsContainer,
  NoteContainer,
  PatientCategoryContainer,
  PatientContainer,
  PatientInfo,
  PatientNameBlock,
  PatientSelectContainer,
  PatientsGroupContainer,
  PopupBody,
  PopupHeader,
  PopupTitle,
  RightColumn,
  StartDate,
  GroupPatientsInfo
} from './AppointmentPopup_Styles';
import {
  ErrorBlock,
  SelectionContainer,
  TitleContainer
} from '../BlockReservationPopup/BlockReservationPopup_Styles';

export interface IAlertsData {
  insurances: Record<string, IPatientInsurance[]>;
  patientAlerts: Record<string, IAlert[]>;
  isUnderAge: Record<string, boolean>;
  patients: any[];
}

interface IState {
  isSelectingRecurringAppt: boolean;
  newEventPayload: IApptResponse;
  ui: any;
  option: string;
  error: any;
  conflicts: any;
  selectedGroupIds: any;
  filteredProviderOptions: any[];
  isAdding: boolean;
  currentAlerts: IAlert[];
  loadingPatients: boolean;
  telehealth: boolean;
  isShowingCurentConflicts: boolean;
  alertsData: IAlertsData;
  isFormValid: boolean;
  forms: { clinicForms: any; appointmentReasonForms: any } | null;
}

interface IProps extends IContainerProps {
  resetConflicts: any;
  categories: any;
  canAdd: boolean;
  getForms: any;
  forms: any;
  conflicts: any;
  onSaveEvent: any;
  eventInfo: IApptResponse;
}

const maxFamilyLimit = 4;

export const expirateInsurances = (res: Record<string, IPatientInsurance>) => {
  const data = Object.values(res);
  const isPrimaries = data.filter(
    (v) =>
      v.isPrimary &&
      v.expireDate &&
      new Date(v.expireDate).getTime() < new Date().getTime()
  );
  if (isPrimaries.length !== 0) {
    const content = isPrimaries.filter((v) => {
      if (!v.insuranceCompanyId) return false;
      return true;
    });
    return {
      isPrimaries: content
    };
  } else {
    const isSecondaries = data.filter(
      (v) =>
        v.isSecondary &&
        v.expireDate &&
        new Date(v.expireDate).getTime() < new Date().getTime()
    );
    const content = isSecondaries.filter((v) => {
      if (!v.insuranceCompanyId) return false;
      return true;
    });
    return {
      isSecondaries: content
    };
  }
};

class AppointmentPopup extends React.Component<IProps, IState> {
  public oldEventPayload: any;
  public telehealthManualChange = false;
  AlertPopUp = React.createRef<IModalHandles>();
  PolicyModal = React.createRef<IPolicyModal>();
  titleRef = React.createRef<any>();

  constructor(props: IProps) {
    super(props);
    const { eventInfo, subjectSummary, conflicts, actionOption, forms } =
      this.props;
    let newEventPayload: IApptResponse = _.cloneDeep(eventInfo);
    if (actionOption.updateSeries) {
      newEventPayload.updateSeries = true;
    }
    const ui: any = {
      ..._.cloneDeep(addApptUIModel),
      maxParticipants: newEventPayload.scheduleEvent.maxParticipants,
      date: moment(newEventPayload.scheduleEvent.scheduleEventDateTime).format(
        'MM/DD/YYYY'
      ),
      enableMultiProvider:
        newEventPayload?.scheduleEvent?.isMultiProvider ?? false,
      popups: {
        ..._.cloneDeep(addApptUIModel.popups),
        history: false
      }
    };
    const action = actionOption.action;
    if (action !== 'edit') {
      const providerCode = newEventPayload.providerCodes[0]?.code;
      const provider = getProviderByCode(providerCode);
      if (provider) {
        newEventPayload.scheduleEvent.telehealth = {
          id: 0,
          patientConnectionInfo:
            provider.defaultTelehealthPatientConnectionInfo,
          providerConnectionInfo:
            provider.defaultTelehealtProviderConnectionInfo
        };
      }
    }
    if (subjectSummary && !_.isEmpty(subjectSummary) && action === 'edit') {
      ui.selectedSubjects = [subjectSummary];
    }
    if (newEventPayload.subjectSummary) {
      ui.selectedSubjects = [newEventPayload.subjectSummary];
      delete newEventPayload.subjectSummary;
    }
    this.state = {
      alertsData: {
        patientAlerts: {},
        insurances: {},
        isUnderAge: {},
        patients: []
      },
      telehealth: false,
      selectedGroupIds: [],
      newEventPayload: { ...newEventPayload },
      ui,
      isSelectingRecurringAppt: false,
      filteredProviderOptions: [],
      option: 'type',
      isAdding: false,
      conflicts: _.cloneDeep(conflicts),
      error: {
        clinic: false,
        provider: false,
        reason: false,
        startTime: false,
        duration: false,
        startDate: false,
        addCategory: false,
        subjectLimit: false,
        clinicClosed: false
      },
      currentAlerts: [],
      loadingPatients: false,
      isShowingCurentConflicts: false,
      isFormValid: false,
      forms: _.cloneDeep(forms)
    };
  }

  componentDidMount() {
    insuranceCompaniesActions.get();
    if (this.props.eventInfo) {
      this.oldEventPayload = _.cloneDeep(this.props.eventInfo);
    }
    this.validateClinicOpen();

    const fillClinicForms = async (clinicCode: string) => {
      const clinicId = getClinicByCode(clinicCode)?.id;
      let newForms: { clinicForms: any; appointmentReasonForms: any } | null =
        null;
      try {
        const response = await getFormsFromClinic(
          this.props.actionOption.reason?.id,
          clinicId
        );
        let result = response.data.result;
        if (response.data.success) {
          newForms = {
            clinicForms: result.clinicForms,
            appointmentReasonForms: undefined
          };

          newForms.appointmentReasonForms = _.pickBy(
            result.appointmentReasonForms,
            (item) => !item.inactive
          );
        }
      } catch (error) {
        console.log('getScheduleReasons error', error);
      }
      this.setState({
        forms: newForms ? newForms : this.state.forms
      });
    };

    const actionOption = this.props.actionOption;
    if (
      actionOption.action === 'reservedAppt' &&
      actionOption.reason &&
      actionOption.clinicCode
    ) {
      fillClinicForms(actionOption.clinicCode);
      const reason = getReasonByID(actionOption.reason.id);
      if (reason && reason.telehealth) {
        this.onChangScheduleEvent({
          isTelehealth: reason.telehealth
        });
      }
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      JSON.stringify(prevProps.conflicts) !==
      JSON.stringify(this.props.conflicts)
    ) {
      const clinicClosedConflict =
        !!this.props.conflicts?.conflictResponse[0]?.clinicClosed;
      this.setState({
        conflicts: this.props.conflicts,
        error: clinicClosedConflict
          ? {
              ...this.state.error,
              clinicClosed: 'The clinic is closed on the selected date'
            }
          : this.state.error
      });
    }
    if (
      this.props.eventInfo &&
      !_.isEqual(this.props.eventInfo, prevProps.eventInfo)
    ) {
      this.setState({
        newEventPayload: { ...this.props.eventInfo }
      });
    }
    if (
      this.props.conflicts &&
      !_.isEqual(this.props.conflicts, prevProps.conflicts)
    ) {
      this.setState({
        conflicts: { ...this.props.conflicts }
      });
    }

    if (!_.isEqual(this.state.newEventPayload, prevState.newEventPayload)) {
      this.validateClinicOpen();
      this.validationCheck();
    }
  }

  validationCheck() {
    let error = this.formValidation();
    let subjectLimitReached = false;
    let { maxParticipants } = this.state.newEventPayload.scheduleEvent;
    let { selectedSubjects } = this.state.ui;
    if (maxParticipants && maxParticipants > 0) {
      subjectLimitReached =
        selectedSubjects && selectedSubjects.length > maxParticipants;
    }
    this.setState({
      error,
      isFormValid: this.noErrorCheck(error) && !subjectLimitReached
    });
  }

  onRemoveGroupSubject = (
    selectedSubjects: any,
    newEventPayload: any,
    patientIdx: any
  ) => {
    const arr = _.cloneDeep(selectedSubjects);
    arr.splice(patientIdx, 1);
    const data = _.cloneDeep(newEventPayload);
    data.patients.splice(patientIdx, 1);
    data.patientsInfo.splice(patientIdx, 1);
    this.setState({
      ui: {
        ...this.state.ui,
        selectedSubjects: arr
      },
      newEventPayload: data
    });
  };

  onClickAddAppointment = (canAdd: boolean) => {
    const { isTriage, actionOption } = this.props;
    const { newEventPayload } = this.state;
    if (isTriage) {
      this.onClickSave();
      if (actionOption.triageUpdateCallback) {
        const dataToSend = {
          ...newEventPayload.scheduleEvent,
          allDay: false,
          appointmentFor: newEventPayload.scheduleEvent.reason.name,
          visitReason: newEventPayload.scheduleEvent.reason.name,
          blockReservationExpireTime: null,
          cancelledPatients: null,
          color: newEventPayload.scheduleEvent.reason.color,
          eventMembers: null,
          groupId: null,
          inReservation: false,
          isBlock: false,
          isReservation: false,
          subjectId: newEventPayload.scheduleEvent.patient.id,
          note: newEventPayload.scheduleEvent.comment,
          patientInfo: {
            ...newEventPayload.scheduleEvent.patient,
            patientBirthDate: newEventPayload.scheduleEvent.patient.birthDate,
            patientEmail: newEventPayload.scheduleEvent.patient.email,
            patientNotes: null,
            patientPreferredPhone:
              newEventPayload.scheduleEvent.patient.preferredPhone,
            patientSex: newEventPayload.scheduleEvent.patient.sex,
            patientName:
              newEventPayload.scheduleEvent.patient.firstName +
              ' ' +
              newEventPayload.scheduleEvent.patient.lastName,

            patientId: newEventPayload.scheduleEvent.patient.id,
            patientStatus: newEventPayload.scheduleEvent.status
          },
          providers: null,
          releaseBeforeStartTime: false,
          startTime: newEventPayload.scheduleEvent.scheduleEventDateTime
        };
        actionOption.triageUpdateCallback(dataToSend);
      }
      return;
    }
    const { patients, scheduleEvent, providerCodes } = newEventPayload;
    const type = scheduleEvent.typeAppointmentParticipantId;
    if (type === 2) {
      if (patients?.length > 2) {
        return notification.error({
          message: 'Max Participants',
          description: 'Couple appointment should have 2 or less participants'
        });
      }
    }
    if (type === 3) {
      if (patients?.length > maxFamilyLimit) {
        return notification.error({
          message: 'Max Participants',
          description: `Family appointment should have ${maxFamilyLimit} or less participants`
        });
      }
    }
    if (!providerCodes || providerCodes.length === 0) {
      this.setState({
        error: {
          ...this.state.error,
          provider: true
        }
      });
    } else {
      if (!canAdd) {
        this.onClickSave();
      }
    }
  };

  cleanPreviousAppointmentTypeForms = () => {
    const cleanedForms: any = {
      cleanedAppointmentForms: [],
      cleanedSeriesForms: []
    };
    if (
      this.state.newEventPayload.appointmentForms &&
      this.state.newEventPayload.appointmentForms.length > 0
    ) {
      cleanedForms.cleanedAppointmentForms = _.cloneDeep(
        this.state.newEventPayload.appointmentForms
      ).filter((form) => !form.reasonCode);
    }
    if (
      this.state.newEventPayload.seriesForms &&
      this.state.newEventPayload.seriesForms.length > 0
    ) {
      cleanedForms.cleanedSeriesForms = _.cloneDeep(
        this.state.newEventPayload.seriesForms
      ).filter((form: any) => !form.reasonCode);
    }
    return cleanedForms;
  };

  onReasonChange = async (value: string, clinic?: any, callback?: Function) => {
    const { option } = this.state;
    const { isTriage } = this.props;
    const cleanedForms = this.cleanPreviousAppointmentTypeForms();
    let selectedReason: any = null;
    if (option === 'type') {
      selectedReason = getReasonByID(value);
      if (selectedReason && isTriage) {
        const newTriageEvent = _.cloneDeep(this.state.newEventPayload);
        newTriageEvent.scheduleEvent.reason = selectedReason as any;
        this.setState({
          newEventPayload: newTriageEvent
        });
        return;
      }
    }
    let newForms: { clinicForms: any; appointmentReasonForms: any } | null =
      null;
    let newEvent = _.cloneDeep(this.state.newEventPayload);
    newEvent.scheduleEvent.reason = {} as any;
    if (selectedReason) {
      try {
        const response = await getFormsFromClinic(
          selectedReason?.id,
          clinic?.id
        );
        newEvent = _.cloneDeep(this.state.newEventPayload);
        newEvent.appointmentForms = cleanedForms.cleanedAppointmentForms;
        newEvent.seriesForms = cleanedForms.cleanedSeriesForms;
        newEvent.scheduleEvent.reason = selectedReason as any;
        if (newEvent.scheduleEvent.scheduleEventDateTime) {
          newEvent = setEventDuration(
            clinic,
            newEvent,
            newEvent.scheduleEvent.scheduleEventDateTime,
            selectedReason.duration
          );
        } else {
          newEvent.scheduleEvent.reason = {} as any;
        }
        let result = response.data.result;
        if (response.data.success) {
          newForms = {
            clinicForms: result.clinicForms,
            appointmentReasonForms: undefined
          };
          newForms.appointmentReasonForms = _.pickBy(
            result.appointmentReasonForms,
            (item) => !item.inactive
          );
          this.mergeSelectionWithExistingForms(
            newEvent,
            _.pickBy(newForms.appointmentReasonForms, (item) => item.selected)
          );
        }
      } catch (error) {
        console.log('getScheduleReasons error', error);
      }
    }

    let inSeries = newEvent.scheduleEvent.series?.typeFrequencyId > 0;
    if (!newEvent.scheduleEvent.series.endTime || !inSeries) {
      newEvent.scheduleEvent.series.endTime = newEvent.scheduleEvent.endTime;
    }
    this.setState(
      {
        newEventPayload: newEvent,
        error: {
          ...this.state.error,
          reason: false
        },
        conflicts: {
          ...this.state.conflicts,
          hasConflicts: false
        },
        forms: newForms ? newForms : this.state.forms
      },
      () => {
        callback && callback();
      }
    );
  };

  validateClinicOpen() {
    const clinicOpen = validateClinicOpenByDate(
      this.state.newEventPayload.scheduleEvent.clinicCode,
      this.state.newEventPayload.scheduleEvent.seriesId
        ? this.state.newEventPayload.scheduleEvent.series.startDate
        : this.state.newEventPayload.scheduleEvent.scheduleEventDateTime
    );
    const error = {
      ...this.state.error,
      clinicClosed: clinicOpen
        ? false
        : 'The clinic is closed on the selected date'
    };

    if (!_.isEqual(this.state.error, error)) {
      this.setState({
        error
      });
    }
  }

  onStartTimeChange(e: any, clinic: any) {
    let { newEventPayload, error } = this.state;
    let updatedPayload = setEventStartTime(newEventPayload, clinic, e.value);

    this.setState({
      newEventPayload: {
        ...updatedPayload,
        conflictsResponses: []
      },
      error: {
        ...error,
        startTime: false
      },
      conflicts: {
        ...this.state.conflicts,
        hasConflicts: false
      }
    });
  }

  onLocationChange(typeAppointmentLocationId: number | null) {
    const { newEventPayload } = this.state;
    this.setState({
      newEventPayload: {
        ...newEventPayload,
        scheduleEvent: {
          ...newEventPayload.scheduleEvent,
          typeAppointmentLocationId
        }
      }
    });
  }

  formValidation() {
    let { newEventPayload } = this.state;
    let error = _.cloneDeep(this.state.error);
    if (!newEventPayload?.scheduleEvent?.clinicCode) error.clinic = true;
    if (
      newEventPayload.providerCodes &&
      newEventPayload.providerCodes.length > 0
    )
      error.provider = false;
    if (
      newEventPayload.scheduleEvent.providerCode &&
      newEventPayload.scheduleEvent.providerCode.length > 0
    )
      error.provider = false;
    if (
      !newEventPayload.scheduleEvent.reason ||
      !newEventPayload?.scheduleEvent?.reason?.id
    ) {
      error.reason = true;
    } else {
      error.reason = false;
    }
    if (!newEventPayload.scheduleEvent.scheduleEventDateTime) {
      error.date = true;
    } else {
      error.date = false;
    }
    if (!newEventPayload.scheduleEvent.scheduleEventDateTime) {
      error.startTime = true;
    } else {
      error.startTime = false;
    }
    if (!newEventPayload.scheduleEvent.endTime) {
      error.endTime = true;
    } else {
      error.endTime = false;
    }
    if (!newEventPayload.patients || newEventPayload.patients.length === 0) {
      error.subject = true;
    } else {
      error.subject = false;
    }
    return error;
  }

  onEndTimeChange(e: any, clinic: any) {
    let { newEventPayload, error } = this.state;

    let updatedPayload = setEventEndTime(newEventPayload, clinic, e.value);

    this.setState({
      newEventPayload: {
        ...updatedPayload,
        conflictsResponses: []
      },
      error: {
        ...error,
        endTime: false,
        duration: false
      },
      conflicts: {
        ...this.state.conflicts,
        hasConflicts: false
      }
    });
  }

  noErrorCheck(obj: any) {
    let check = Object.keys(obj).every(
      (item: any) => obj[item] === false || item === 'clinicClosed'
    );
    return check;
  }

  onSeriesUpdated(payload: any) {
    const incomingPayload = _.cloneDeep(payload);
    let { newEventPayload } = this.state;
    if (
      this.props.actionOption.action !== 'edit' &&
      !newEventPayload.scheduleEvent.series?.typeFrequencyId
    ) {
      incomingPayload['seriesForms'] = _.cloneDeep(payload['appointmentForms']);
      incomingPayload['appointmentForms'] = [];
    }
    const newSeriesAppointments: IOccurrence[] = (
      incomingPayload.scheduleEvent.series?.seriesAppointments || []
    ).map((item: IOccurrence) => ({
      ...item,
      isSkipped: false
    }));
    if (
      incomingPayload?.conflictsResponses &&
      incomingPayload?.conflictsResponses.length > 0
    ) {
      incomingPayload?.conflictsResponses.forEach((conflict: any) => {
        if (conflict.dialogResponse === 'skip') {
          const itemToSkip = newSeriesAppointments.find(
            (item) =>
              item.endTime.slice(0, 10) === conflict.endTime.slice(0, 10)
          );
          if (itemToSkip) {
            itemToSkip.isSkipped = true;
          }
        }
      });
    }
    incomingPayload.scheduleEvent.series.seriesAppointments =
      newSeriesAppointments;
    this.setState({
      newEventPayload: incomingPayload
    });
  }

  onSaveEvent() {
    let { newEventPayload } = this.state;
    if (newEventPayload?.scheduleEvent['typeAppointmentParticipantId'] === 2) {
      newEventPayload.scheduleEvent.maxParticipants = 2;
    } else if (
      newEventPayload?.scheduleEvent['typeAppointmentParticipantId'] === 3
    ) {
      newEventPayload.scheduleEvent.maxParticipants = maxFamilyLimit;
    }
    this.props.onSaveEvent(newEventPayload);
  }

  onStartDateChange(value: string) {
    let error = _.cloneDeep(this.state.error);
    error.startDate = false;
    let newEventPayload = _.cloneDeep(this.state.newEventPayload);
    let scheduleEvent = newEventPayload.scheduleEvent;
    let date = momentDateHandFormatter(value);
    if (scheduleEvent['scheduleEventDateTime']) {
      scheduleEvent['scheduleEventDateTime'] =
        date +
        scheduleEvent['scheduleEventDateTime'].substring(
          scheduleEvent['scheduleEventDateTime'].indexOf('T'),
          scheduleEvent['scheduleEventDateTime'].length
        );
      if (this.props.actionOption.action !== 'edit') {
        scheduleEvent['series'] = {
          ...scheduleEvent['series'],
          startDate: date + 'T00:00:00',
          startTime: scheduleEvent['scheduleEventDateTime']
        };
      }
    }

    if (scheduleEvent['endTime']) {
      scheduleEvent['endTime'] =
        date +
        scheduleEvent['endTime'].substring(
          scheduleEvent['endTime'].indexOf('T'),
          scheduleEvent['endTime'].length
        );
      if (this.props.actionOption.action !== 'edit') {
        scheduleEvent['series'] = {
          ...scheduleEvent['series'],
          endTime: scheduleEvent['endTime']
        };
      }
    }
    const clinicOpen = validateClinicOpenByDate(
      this.state.newEventPayload.scheduleEvent.clinicCode,
      scheduleEvent.series.startDate
    );
    error.clinicClosed = clinicOpen
      ? false
      : 'The clinic is closed on the selected date';

    this.setState({
      newEventPayload: {
        ...newEventPayload,
        conflictsResponses: []
      },
      ui: { ...this.state.ui, date: value },
      error: error,
      conflicts: {
        ...this.state.conflicts,
        hasConflicts: false
      }
    });
  }

  onGroupProviderChange(
    goodOptions: any,
    selectedGroupIds: any,
    enableMulti?: boolean
  ) {
    let { ui } = this.state;
    ui['enableMultiProvider'] = enableMulti;
    this.setState({
      ui: { ...ui },
      filteredProviderOptions: goodOptions,
      selectedGroupIds: selectedGroupIds,
      conflicts: {
        ...this.state.conflicts,
        hasConflicts: false
      }
    });
  }

  onSelectRecurring = (open: boolean) => {
    if (open) {
      this.setState({
        isSelectingRecurringAppt: true,
        conflicts: {
          ...this.state.conflicts,
          hasConflicts: false
        }
      });
    }
  };

  onEditCurrentConflicts = () => {
    this.setState({
      isSelectingRecurringAppt: true,
      isShowingCurentConflicts: true,
      conflicts: {
        ...this.state.conflicts,
        hasConflicts: false
      }
    });
  };

  onChangeMaxParticipants = (maxParticipants: any) => {
    const temporaryScheduleEvent = this.state.newEventPayload.scheduleEvent;
    temporaryScheduleEvent.maxParticipants = maxParticipants;
    this.setState({
      ui: { ...this.state.ui, maxParticipants },
      newEventPayload: {
        ...this.state.newEventPayload,
        scheduleEvent: temporaryScheduleEvent
      }
    });
  };

  onReleaseBeforeStartTimeChange(releaseBeforeStartTime: boolean) {
    let { newEventPayload } = this.state;
    newEventPayload.scheduleEvent.scheduleEventBlock.releaseBeforeStartTime =
      releaseBeforeStartTime;
    this.setState({
      newEventPayload
    });
  }

  onEndSelectRecurring() {
    this.setState({
      isSelectingRecurringAppt: false,
      isShowingCurentConflicts: false
    });
  }

  onCancelSelectRecurring() {
    this.setState({
      isSelectingRecurringAppt: false,
      isShowingCurentConflicts: false,
      newEventPayload: {
        ...this.state.newEventPayload,
        conflictsResponses: []
      }
    });
  }

  onClickOverride = () => {
    let { conflicts, canAdd, onSaveEvent } = this.props;
    if (conflicts && !canAdd) {
      let conflictResponse = conflicts.conflictResponse;
      conflictResponse.forEach((item: any, indexL: any) => {
        conflictResponse[indexL]['dialogResponse'] = 'override';
      });
      onSaveEvent({
        ...this.state.newEventPayload,
        conflictsResponses: conflictResponse
      });
    }
  };

  onChangScheduleEvent = (obj: Partial<IApptResponse['scheduleEvent']>) => {
    this.setState(({ newEventPayload }) => ({
      newEventPayload: {
        ...newEventPayload,
        scheduleEvent: {
          ...newEventPayload.scheduleEvent,
          ...obj
        }
      }
    }));
  };

  onProvidersChange = (codes: string[], callback?: Function) => {
    const { error, newEventPayload, conflicts } = this.state;
    const clinic = getClinicByCode(newEventPayload.scheduleEvent.clinicCode);
    if (clinic) {
      newEventPayload.providerCodes = generateCodesByProvidersCodes(
        codes,
        clinic?.id
      );
      error.provider = false;
      let newTelehealthInfo = {
        patientConnectionInfo: '',
        providerConnectionInfo: ''
      };
      if (codes.length) {
        const providerForTelehealth = getProviderByCode(codes[0]);
        if (providerForTelehealth) {
          newTelehealthInfo = {
            patientConnectionInfo:
              providerForTelehealth.defaultTelehealthPatientConnectionInfo,
            providerConnectionInfo:
              providerForTelehealth.defaultTelehealtProviderConnectionInfo
          };
        }
      }
      newEventPayload.scheduleEvent.telehealth = {
        ...newEventPayload.scheduleEvent.telehealth,
        ...newTelehealthInfo
      };
      this.setState(
        {
          newEventPayload: {
            ...newEventPayload,
            conflictsResponses: []
          },
          error: error,
          conflicts: {
            ...conflicts,
            hasConflicts: false
          }
        },
        () => callback && callback()
      );
    }
  };

  setAppointmentType(
    appointmentType: 'individual' | 'group' | 'couple' | 'family'
  ) {
    const ui = _.cloneDeep(this.state.ui);
    const newEventPayload = _.cloneDeep(this.state.newEventPayload);
    let scheduleEvent = newEventPayload.scheduleEvent;
    if (appointmentType === 'group') {
      scheduleEvent['typeAppointmentParticipantId'] = 4;
      ui['popups']['scheduling'] = false;
    } else if (appointmentType === 'couple') {
      scheduleEvent['typeAppointmentParticipantId'] = 2;
    } else if (appointmentType === 'family') {
      scheduleEvent['typeAppointmentParticipantId'] = 3;
    } else {
      scheduleEvent['typeAppointmentParticipantId'] = 1;
    }
    scheduleEvent['groupAppointment'] =
      scheduleEvent['typeAppointmentParticipantId'] > 1;
    if (appointmentType === 'individual') {
      if (ui['selectedSubjects'] && ui['selectedSubjects'].length > 0) {
        ui['selectedSubjects'] = [ui['selectedSubjects'][0]];
        newEventPayload['patients'] = [ui['selectedSubjects'][0].subjectId];
      }
    }
    if (appointmentType === 'couple') {
      if (ui['selectedSubjects'] && ui['selectedSubjects'].length > 1) {
        ui['selectedSubjects'] = ui['selectedSubjects'].slice(0, 2);
      }
      if (
        newEventPayload['patientsInfo'] &&
        newEventPayload['patientsInfo'].length > 1
      ) {
        newEventPayload['patientsInfo'] = newEventPayload['patientsInfo'].slice(
          0,
          2
        );
        newEventPayload['patients'] = newEventPayload['patientsInfo'].map(
          (item: any) => item.subjectId
        );
      }
      if (newEventPayload['patientStatus']) {
        newEventPayload['patientStatus'] = _.pick(
          newEventPayload['patientStatus'],
          newEventPayload['patients'].map((item: number) => item + '')
        );
      }
    }
    this.setState({
      newEventPayload: {
        ...newEventPayload,
        scheduleEvent: scheduleEvent,
        conflictsResponses: []
      },
      ui: { ...ui }
    });
  }

  onClickSave() {
    this.onSaveEvent();
  }

  setProviderSelection(providerSelection: any) {
    let { ui, newEventPayload } = this.state;
    let { selectedProviders } = ui;
    ui['enableMultiProvider'] = providerSelection;
    let providerCodes = newEventPayload['providerCodes'];
    if (providerCodes && providerCodes.length > 0) {
      newEventPayload['providerCodes'] = !providerSelection
        ? [providerCodes[0]]
        : providerCodes;
    }
    this.setState({
      ui: {
        ...ui,
        selectedProviders: selectedProviders,
        newEventPayload: { ...newEventPayload }
      }
    });
  }

  getPrivacyPolicy = async (patients: any[]) => {
    const isPolicyEnabled = isPrivacyPolicyEnabled();
    if (!isPolicyEnabled) {
      return;
    }
    const { newEventPayload } = this.state;
    const clinic = getClinicByCode(newEventPayload.scheduleEvent.clinicCode);
    if (clinic) {
      const patientIds = patients.map((v: any) => v.subjectId);
      const response = await hippoActions.findAndUnsubscribe(
        patientIds,
        'patientId'
      );
      if (response?.success) {
        const data = hippoSetters.filterUnusedHippo(response.data);
        hippoSetters.setSummary(data);
        const expired = Object.values(data).filter(
          (v) =>
            isHippoExpired(clinic.id, v.dosFrom) ||
            v.action === TicketDetailHippaAction.Decline
        );
        if (expired.length > 0) {
          this.PolicyModal.current?.show({ value: data, clinicId: clinic.id });
        }
      }
    }
  };

  onClickFinishSelection = (selectedPatients: any) => {
    let { newEventPayload, ui, error } = this.state;
    const uiData = _.cloneDeep(ui);
    uiData['selectedSubjects'] = selectedPatients;
    const newEventPayloadData = _.cloneDeep(newEventPayload);
    newEventPayloadData['patients'] = selectedPatients.map(
      (item: any) => item.subjectId
    );
    newEventPayloadData['patientsInfo'] = selectedPatients;
    const errorData = _.cloneDeep(error);
    this.getPrivacyPolicy(selectedPatients);
    errorData.subject = false;
    if (uiData['appointmentType'] === 'individual') {
      this.setState({
        newEventPayload: newEventPayloadData,
        error: errorData,
        ui: uiData
      });
    } else {
      this.setState({
        newEventPayload: {
          ...newEventPayloadData,
          conflictsResponses: []
        },
        error: errorData,
        ui: uiData
      });
    }
  };

  onCancelSelectDateAndTime() {
    this.setState({
      ui: {
        ...this.state.ui,
        popups: { ...this.state.ui['popups'], scheduling: false }
      }
    });
  }

  onClickAddCategoryToAppointment = async () => {
    const request = async () => {
      const ui = _.cloneDeep(this.state.ui);
      const error = _.cloneDeep(this.state.error);
      const newEventPayload = _.cloneDeep(this.state.newEventPayload);
      let scheduleEvent = newEventPayload.scheduleEvent;
      try {
        this.setState({ loadingPatients: true });
        const response = await getPatientsInCategory(
          scheduleEvent.categoryCode
        );
        if (response.data.success) {
          let updatedPatients: any = [];
          let result = response.data.result;
          let existingPatientsIds = newEventPayload.patients || [];
          let existingPatientsInfo = newEventPayload.patientsInfo || [];
          if (!result || result.length === 0) {
            result = [...existingPatientsIds];
            error.addCategory = true;
          } else {
            updatedPatients = [...existingPatientsInfo];
            result.forEach((item: any) => {
              let index = existingPatientsInfo.findIndex(
                (subject: any) => item.subjectId === subject.subjectId
              );
              if (index === -1) {
                updatedPatients.push(item);
              }
            });
            error.addCategory = false;
          }
          let selectedPatients = updatedPatients.map(
            (item: any) => item.subjectId
          );
          newEventPayload.patients = selectedPatients;
          newEventPayload.patientsInfo = updatedPatients;
          this.getPrivacyPolicy(updatedPatients);
          ui.selectedSubjects = updatedPatients;
          this.setState({
            error: { ...error },
            ui: { ...ui },
            newEventPayload: { ...newEventPayload, conflictsResponses: [] }
          });
        }
      } catch (error) {
        console.log('createScheduleEvent error', error);
      } finally {
        this.setState({ loadingPatients: false });
      }
    };
    const selectedCategory: any = this.selectedCategory();
    if (
      selectedCategory &&
      this.state.ui.maxParticipants &&
      selectedCategory.count > this.state.ui.maxParticipants
    ) {
      this.AlertPopUp.current?.show({
        content: `You have specified more patients than maximum allowed. (${
          selectedCategory.count
        }/${this.state.ui.maxParticipants ?? 0})`,
        hideClose: true,
        callerRef: this.titleRef,
        onConfirm: async () => {
          this.AlertPopUp.current?.show({
            loading: true
          });
          await request();
          this.AlertPopUp.current?.close();
        }
      });
      return;
    }
    request();
  };

  onClickCloseAppointment() {
    this.setState({
      ui: {
        ...this.state.ui,
        popups: { ...this.state.ui['popups'], history: false }
      }
    });
  }

  onSelectCategoryCode = (categoryCode: string) => {
    const { newEventPayload } = this.state;
    const scheduleEvent = newEventPayload.scheduleEvent;
    this.setState({
      newEventPayload: {
        ...newEventPayload,
        scheduleEvent: { ...scheduleEvent, categoryCode },
        conflictsResponses: []
      }
    });
  };

  onBlockSelfCheckinChange(value: any) {
    let { newEventPayload } = this.state;
    let scheduleEvent = newEventPayload.scheduleEvent;
    this.setState({
      newEventPayload: {
        ...newEventPayload,
        scheduleEvent: { ...scheduleEvent, blockSelfCheckin: value }
      }
    });
  }

  onAppointmentNoteChange = (comment: string) => {
    let { newEventPayload } = this.state;
    let scheduleEvent = newEventPayload.scheduleEvent;
    this.setState({
      newEventPayload: {
        ...newEventPayload,
        scheduleEvent: { ...scheduleEvent, comment }
      }
    });
  };

  onChangeComment = (comment: string) => {
    let { newEventPayload } = this.state;
    let scheduleEvent = newEventPayload.scheduleEvent;
    if (this.props.actionOption.action === 'edit' && !this.state.isFormValid) {
      this.setState({
        newEventPayload: {
          ...newEventPayload,
          scheduleEvent: { ...scheduleEvent, comment }
        }
      });
    } else {
      scheduleEvent.comment = comment;
    }
  };

  onMaxParticipantsBlur = () => {
    let { newEventPayload } = this.state;
    let scheduleEvent = newEventPayload.scheduleEvent;
    this.setState({
      newEventPayload: {
        ...newEventPayload,
        scheduleEvent: {
          ...scheduleEvent,
          maxParticipants: this.state.ui.maxParticipants
        }
      }
    });
  };

  onEndSelectDateAndTime(option: any) {
    let providerCodes: any[] = [];
    let { ui, newEventPayload } = this.state;
    const clinic = getClinicByCode(option.Event.clinicCode);
    if (clinic) {
      option.PROVIDER.forEach((providerCode: string) => {
        const provider = getProviderByCode(providerCode);
        if (provider) {
          const pages = getClinicConfig(provider, clinic.id)?.maxPages || 1;
          providerCodes.push({
            id: provider.providerId,
            code: provider.code,
            page: pages,
            name: provider.firstName + ' ' + provider.lastName
          });
        }
      });
    }
    let event = option.Event;
    let error = this.state.error;
    error.date = false;
    let startTime = event.startTime;
    let endTime = event.endTime;
    newEventPayload.scheduleEvent['scheduleEventDateTime'] = getTimeObject(
      moment(startTime).format()
    );
    newEventPayload.scheduleEvent['endTime'] = getTimeObject(
      moment(endTime).format()
    );
    newEventPayload['providerCodes'] = providerCodes;
    ui['popups']['scheduling'] = false;
    ui['popups']['settings'] = false;
    ui['enableMultiProvider'] = providerCodes.length > 1 ? true : false;
    if (providerCodes.length >= 1) {
      const provider = getProviderByCode(providerCodes[0].code);
      if (provider) {
        newEventPayload.scheduleEvent.telehealth = {
          ...newEventPayload.scheduleEvent.telehealth,
          patientConnectionInfo:
            provider.defaultTelehealthPatientConnectionInfo,
          providerConnectionInfo:
            provider.defaultTelehealtProviderConnectionInfo
        };
      }
    }
    let callback: any = () => {};
    if (option.Event.reasonCode !== newEventPayload.scheduleEvent.reason.code) {
      const newReason = getReasonByCode(option.Event.reasonCode);
      if (newReason && clinic) {
        newEventPayload.scheduleEvent.isTelehealth = newReason.telehealth;
        const newReasonId = newReason.id + '';
        callback = () => this.onReasonChange(newReasonId, clinic);
      }
    }

    this.setState(
      {
        newEventPayload: {
          ...newEventPayload,
          conflictsResponses: []
        },
        ui: { ...ui }
      },
      callback
    );
    this.props.resetConflicts();
  }

  mergeSelectionWithExistingForms(
    newEventPayload: IApptResponse,
    appointmentReasonForms: any
  ) {
    newEventPayload['seriesForms'] = newEventPayload['seriesForms']
      ? newEventPayload['seriesForms']
      : [];
    newEventPayload['appointmentForms'] = newEventPayload['appointmentForms']
      ? newEventPayload['appointmentForms']
      : [];
    let data: any = [];
    if (newEventPayload.scheduleEvent?.series?.typeFrequencyId > 0) {
      data = newEventPayload['seriesForms']
        ? newEventPayload['seriesForms']
        : [];
    } else {
      data = newEventPayload['appointmentForms']
        ? newEventPayload['appointmentForms']
        : [];
    }
    Object.values(appointmentReasonForms).map((form: any) => {
      if (form.isM1Form) {
        let index = data.findIndex(
          (item: any) => item.m1FormId === form.m1FormId
        );
        if (index === -1) {
          if (newEventPayload.scheduleEvent?.series?.typeFrequencyId > 0) {
            newEventPayload['seriesForms'].push(form);
          } else {
            if (newEventPayload.appointmentForms) {
              newEventPayload.appointmentForms.push(form);
            }
          }
        }
      } else {
        let index = data.findIndex(
          (item: any) => item.seedFormId === form.seedFormId
        );
        if (index === -1) {
          if (newEventPayload.scheduleEvent?.series?.typeFrequencyId > 0) {
            newEventPayload['seriesForms'].push(form);
          } else {
            if (newEventPayload.appointmentForms) {
              newEventPayload.appointmentForms.push(form);
            }
          }
        }
      }
    });
    return newEventPayload;
  }

  onClickFinishSelectingForms = (payload: any) => {
    this.setState({
      newEventPayload: { ...payload },
      ui: {
        ...this.state.ui,
        popups: {
          ...this.state.ui['popups'],
          settings: false,
          scheduling: false,
          recurring: false
        }
      }
    });
  };

  onChangeSelectedForms = (payload: any) => {
    this.setState({
      newEventPayload: { ...payload }
    });
  };

  onSelectDateAndTime() {
    this.setState({
      ui: {
        ...this.state.ui,
        popups: { ...this.state.ui['popups'], scheduling: true }
      },
      conflicts: {
        ...this.state.conflicts,
        hasConflicts: false
      }
    });
  }

  onClickTelehealth = () => {
    const { telehealth } = this.state;
    this.setState({
      telehealth: !telehealth
    });
  };

  onSelectSettings = () => {
    let settings = this.state.ui['popups']['settings'];
    this.setState({
      ui: {
        ...this.state.ui,
        popups: {
          ...this.state.ui['popups'],
          settings: !settings,
          scheduling: false,
          recurring: false
        }
      }
    });
  };
  // Will be a little bit later
  // onClinicChange2 = (value: any) => {
  //   const { newEventPayload } = this.state;
  //   let newEvent = _.cloneDeep(newEventPayload);
  //   const selectedClinic = getClinicByCode(value);
  //   newEvent.scheduleEvent.scheduleEventDateTime = validateStartTimeRule(
  //     selectedClinic,
  //     newEvent.scheduleEvent.scheduleEventDateTime
  //   );
  //   newEvent.scheduleEvent.endTime = validateEndTimeRule(
  //     selectedClinic,
  //     newEvent.scheduleEvent.scheduleEventDateTime,
  //     newEvent.scheduleEvent.endTime,
  //     true
  //   );
  //   newEvent.scheduleEvent.clinicCode = selectedClinic?.code;
  //   this.setState({
  //     newEventPayload: {
  //       ...newEvent,
  //       appointmentForms: [],
  //       seriesForms: [],
  //       conflictsResponses: []
  //     },
  //     conflicts: {
  //       ...this.state.conflicts,
  //       hasConflicts: false
  //     }
  //   });
  // };

  onClinicChange = (value: string) => {
    const currentClinic = getClinicByCode(value);
    if (currentClinic) {
      const newEventPayload = _.cloneDeep(this.state.newEventPayload);
      newEventPayload.providerCodes = newEventPayload.providerCodes.filter(
        (v: IProviderCode) => {
          const providerExist = getProviderById(v.providerId);
          if (providerExist) {
            const inCurrentClinic = getProviderClinicAssignment(
              providerExist,
              currentClinic.id
            );
            return inCurrentClinic;
          }
          return false;
        }
      );
      newEventPayload.scheduleEvent.scheduleEventDateTime =
        validateStartTimeRule(
          currentClinic,
          newEventPayload.scheduleEvent.scheduleEventDateTime
        );
      newEventPayload.scheduleEvent.endTime = validateEndTimeRule(
        currentClinic,
        newEventPayload.scheduleEvent.scheduleEventDateTime,
        newEventPayload.scheduleEvent.endTime,
        true
      );
      newEventPayload.scheduleEvent.clinicCode = currentClinic.code;
      newEventPayload.scheduleEvent.reason = {} as any;
      this.setState({
        newEventPayload: {
          ...newEventPayload,
          appointmentForms: [],
          seriesForms: [],
          conflictsResponses: []
        },
        conflicts: {
          ...this.state.conflicts,
          hasConflicts: false
        }
      });
    }
  };

  onClickAppointmentHistory = () => {
    let { ui } = this.state;
    ui['popups']['history'] = true;
    this.setState({
      ui
    });
  };

  onClickClose() {
    const ui = _.cloneDeep(addApptUIModel);
    this.setState(
      {
        ui
      },
      () => this.props.onClosePopup()
    );
  }

  selectedCategory = () => {
    const { categories } = this.props;
    const { newEventPayload } = this.state;
    if (
      newEventPayload?.scheduleEvent?.categoryCode?.length > 0 &&
      categories?.length > 0
    ) {
      return categories.find(
        (item: any) =>
          item.value === newEventPayload?.scheduleEvent?.categoryCode
      );
    }
    return null;
  };

  onChangeAlerts = (currentAlerts: IAlert[]) => {
    this.setState({
      currentAlerts
    });
  };

  onChangeTelehealth = (obj: Partial<ITelehealth>) => {
    const { newEventPayload } = this.state;
    const {
      scheduleEvent: { telehealth }
    } = newEventPayload;
    this.onChangScheduleEvent({
      telehealth: {
        ...telehealth,
        ...obj
      }
    });
  };

  onCheckValidReasonProviders = (
    reasonId: number | undefined,
    providerCodes: any[]
  ) => {
    const { actionOption } = this.props;
    if (reasonId) {
      const providers = providerCodes.map(
        (v: any) => getProviderByCode(v.code)?.providerId
      );
      const validProviders = providersValidForReason(
        reasonId,
        providers as any,
        this.state.newEventPayload.scheduleEvent.clinicCode
      );
      if (!actionOption.default) {
        if (validProviders.length === 0) {
          this.onReasonChange('');
        }
      } else {
        this.onProvidersChange(validProviders.map((v) => v.code));
      }
    }
  };

  onChangeAlertsData = (alertsData: any) => {
    this.setState({ alertsData });
  };

  render() {
    const {
      providers,
      clinics,
      canAdd,
      actionOption,
      onClickAlertsView,
      isTriage
    } = this.props;
    const action = actionOption.action;
    let t = document.getElementById('pcontainer');
    let p: any = {};
    if (t) p = t.getBoundingClientRect();
    const {
      option,
      isSelectingRecurringAppt,
      newEventPayload,
      error,
      ui,
      loadingPatients,
      telehealth,
      alertsData
    } = this.state;
    const isCreation = action !== 'edit';
    let { enableMultiProvider, selectedSubjects } = ui;
    let { scheduling, history, settings } = ui.popups;
    let scheduleEvent = newEventPayload.scheduleEvent;

    const clinicOpts = clinics.map((c) => mapClinic(c));
    const selectedClinic = findOpt(
      clinicOpts,
      newEventPayload.scheduleEvent.clinicCode
    );
    const selectedReason = getReasonByID(
      newEventPayload.scheduleEvent.reason?.id
    );

    const providersIds = providers.map((item: any) => item.providerId);

    const providersValidForSelectedReason = selectedReason
      ? providersValidForReason(
          selectedReason?.id,
          providersIds,
          this.state.newEventPayload.scheduleEvent.clinicCode
        ).map((item) => item.code)
      : null;

    let providerSelection = getProviderSelection(
      providers,
      selectedClinic,
      newEventPayload.providerCodes
    );
    let { selectedProviders } = providerSelection;

    let disableDateTimeSelect =
      (selectedProviders && selectedProviders.length == 0) ||
      !selectedClinic ||
      !selectedReason;

    const dateTimeSelection = getDateTimeSelection(
      selectedClinic,
      newEventPayload
    );

    const {
      startTimeOptions,
      endTimeOptions,
      startDateTime,
      startTimeOpt,
      endTimeOpt
    } = dateTimeSelection;

    let startDate = ui.date;
    if (startDateTime) {
      startDate = startDateTime.format('MM/DD/YYYY');
    }

    let chosenColor = newEventPayload.scheduleEvent.scheduleEventBlock?.color;
    if (!chosenColor && selectedReason?.color) {
      chosenColor = selectedReason?.color;
    }
    if (!chosenColor) {
      chosenColor = '#CCCCCC';
    }

    let { typeAppointmentParticipantId, blockSelfCheckin, maxParticipants } =
      newEventPayload.scheduleEvent;

    if (!typeAppointmentParticipantId) {
      typeAppointmentParticipantId = eventPermission(
        EEventTypeCode.appointment
      ).firstAvailableOption;
    }

    let showRecurringButton = true;
    let isEventRecurring =
      newEventPayload.scheduleEvent?.series?.typeFrequencyId > 0;
    showRecurringButton =
      action !== 'edit' ||
      (action === 'edit' &&
        (actionOption.updateSeries || false) &&
        isEventRecurring);
    let showRecurringText = false;
    let updateSeries = actionOption.updateSeries;
    if (action === 'edit') {
      showRecurringText = (updateSeries || false) && isEventRecurring;
    } else {
      showRecurringText = isEventRecurring;
    }

    let eventType = getScheduleEventTypeName(
      newEventPayload.scheduleEvent.scheduleEventTypeId
    );

    let popUpTitle = eventType;
    if (isEventRecurring) {
      popUpTitle = `${eventType} Series`;
      if (action === 'edit' && !actionOption.updateSeries) {
        popUpTitle = `${eventType} Series - Editing single`;
      }
    }

    if (isTriage) {
      popUpTitle = ListTypeNames()[TypeListEnum.triage];
    }

    let pastDay = false;
    let pastTime = false;
    if (scheduleEvent.scheduleEventDateTime && action !== 'edit') {
      let today = moment(new Date()).format('YYYY-MM-DD') + 'T' + '00:00:00';
      let selectedDate =
        moment(scheduleEvent.scheduleEventDateTime).format('YYYY-MM-DD') +
        'T' +
        '00:00:00';
      if (moment(selectedDate).isBefore(today)) {
        pastDay = true;
      }
      if (moment(scheduleEvent.scheduleEventDateTime).isBefore(new Date())) {
        pastTime = true;
      }
    }

    if (
      action === 'edit' ||
      (action === 'new' && newEventPayload.patientsInfo.length > 0)
    ) {
      selectedSubjects = newEventPayload.patientsInfo;
    }

    let maxParticipantsLabel = 'Max Participants';
    let categoryAddAlert = false;
    let categoryAddAlertMessage = '';
    let subjectLimitReached = false;
    if (maxParticipants && maxParticipants > 0) {
      subjectLimitReached =
        selectedSubjects && selectedSubjects.length > maxParticipants;
      maxParticipantsLabel = `Max Participants (${
        selectedSubjects?.length ?? 0
      }/${maxParticipants})`;
    } else {
      maxParticipantsLabel = `Max Participants (${
        selectedSubjects?.length ?? 0
      })`;
    }
    if (error.addCategory) {
      categoryAddAlert = true;
      categoryAddAlertMessage = 'The selected group has no patients';
    } else {
      categoryAddAlert = false;
    }

    let settingsCount = isEventRecurring
      ? action === 'edit' && !actionOption.updateSeries //editing single in series
        ? newEventPayload.appointmentForms?.filter((form) => !form.inactive)
            .length ?? 0
        : newEventPayload.seriesForms?.filter((form: any) => !form.inactive)
            .length ?? 0
      : newEventPayload.appointmentForms?.filter((form) => !form.inactive)
          .length ?? 0;

    const categoryCode = newEventPayload?.scheduleEvent?.categoryCode;

    const getgroupTitle = (appt: IApptResponse) => {
      switch (appt.scheduleEvent.typeAppointmentParticipantId) {
        case 4:
          return 'Group Appointment';
        case 2:
          return 'Couple Appointment';
        case 3:
          return 'Family Appointment';
        default:
          return 'Group Appointment';
      }
    };
    const providerDisabled = actionOption.default
      ? !selectedReason?.id
        ? true
        : false
      : false;
    const providerTypes = (newEventPayload.providerCodes || [])
      .map((v: any) => getProviderById(v.id))
      .filter((v) => v)
      .map((v) => v?.providerTypeIds);

    const concattedTypes = [
      ...new Set([].concat.apply([], providerTypes as any))
    ];
    return (
      <OuterContainer className="popup">
        <AlertsManageContainer
          selectedSubjects={selectedSubjects}
          onChangeAlertsData={this.onChangeAlertsData.bind(this)}
        />
        <PrivacyPolicyGroupStatusModal ref={this.PolicyModal} />
        <AlertPopUp ref={this.AlertPopUp} />
        <PopupContent id={TESTS_IDS.CONTAINER}>
          <PopupHeader>
            <div className="top-container">
              <PopupTitle
                tabIndex={0}
                ref={this.titleRef}
                editSeries={action === 'edit' && !actionOption.updateSeries}
              >
                {scheduling || settings || history || telehealth ? (
                  <ButtonIcon
                    id={TESTS_IDS.BACK_BUTTON}
                    name={ICONS_LIST.back}
                    size={48}
                    disableBackground={true}
                    isButton={true}
                    label="Back"
                    tooltip="Back"
                    containerProps={{
                      style: {
                        marginRight: '2.4rem'
                      }
                    }}
                    onClick={() => {
                      this.setState({
                        telehealth: false,
                        ui: {
                          ...this.state.ui,
                          popups: {
                            ...this.state.ui['popups'],
                            recurring: false,
                            scheduling: false,
                            settings: false,
                            history: false
                          }
                        }
                      });
                    }}
                  />
                ) : null}
                {telehealth ? 'Telehealth' : popUpTitle}
                {scheduling ||
                settings ||
                isSelectingRecurringAppt ||
                telehealth ? (
                  <PatientInfo>
                    <PatientNameBlock>
                      {newEventPayload.scheduleEvent
                        .typeAppointmentParticipantId &&
                      newEventPayload.scheduleEvent
                        .typeAppointmentParticipantId !== 1
                        ? newEventPayload['patientsInfo'] &&
                          getgroupTitle(newEventPayload)
                        : newEventPayload.patientsInfo &&
                          formattedPrefName(newEventPayload.patientsInfo[0])}
                    </PatientNameBlock>
                    <ClinicInfo>
                      <span style={{ display: 'flex' }}>
                        <TitleContainer
                          style={{
                            marginRight: '1rem',
                            fontWeight: 'bold'
                          }}
                        >
                          <label className={'category-label'}>Clinic:</label>
                        </TitleContainer>
                        <span className={'value-label'}>
                          {selectedClinic.label}
                        </span>
                      </span>
                      <span style={{ display: 'flex' }}>
                        <TitleContainer
                          style={{
                            marginRight: '1rem',
                            fontWeight: 'bold'
                          }}
                        >
                          <label className={'category-label'}>Type:</label>
                        </TitleContainer>
                        <span className={'value-label'}>
                          {selectedReason?.name}
                        </span>
                      </span>
                    </ClinicInfo>
                  </PatientInfo>
                ) : null}
              </PopupTitle>
              {!scheduling &&
              !isSelectingRecurringAppt &&
              !settings &&
              !telehealth ? (
                <div className={'popup-buttons-container'}>
                  <BlockSelfCheckinContainer>
                    <AlignRight
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        id={TESTS_IDS.BLOCK_SELF_CHECKING}
                        checked={blockSelfCheckin}
                        onChange={() =>
                          this.onBlockSelfCheckinChange(!blockSelfCheckin)
                        }
                      >
                        Block Self Check-In
                      </Checkbox>
                    </AlignRight>
                  </BlockSelfCheckinContainer>
                  <Button
                    onClick={() => {
                      this.onClickAddAppointment(canAdd);
                    }}
                    className={'popup-save-button'}
                    id={TESTS_IDS.SAVE_BUTTON}
                    loading={canAdd}
                    disabled={
                      !this.state.isFormValid ||
                      this.state.conflicts.hasConflicts
                    }
                  >
                    Save
                  </Button>
                  <ButtonIcon
                    name={ICONS_LIST.thinClose}
                    isButton={true}
                    label="close dialog"
                    id={TESTS_IDS.CLOSE_BUTTON}
                    onClick={() => this.onClickClose()}
                    disableBackground={true}
                    size={28}
                  />
                </div>
              ) : null}
            </div>
            {onClickAlertsView && calculateAlertsCount(alertsData) > 0 && (
              <AlertsBar
                data={alertsData}
                onClickAlertsView={onClickAlertsView}
              />
            )}
          </PopupHeader>
          {this.state.conflicts.hasConflicts && !isSelectingRecurringAppt && (
            <ConflictsBar
              message={this.props.conflicts.message}
              disableOverride={!this.props.conflicts.allowOverride}
              conflictsOverride={this.onClickOverride.bind(this)}
              onEditConflicts={() => this.onEditCurrentConflicts()}
              isInSeries={!!actionOption.updateSeries}
              conflictsProps={{
                conflicts: this.props.conflicts,
                clinicOpts,
                selectedProviders,
                selectedClinic
              }}
            />
          )}
          <PopupBody sliderMode={true}>
            <AbsoluteContainer>
              {telehealth && (
                <Telehealth
                  scheduleEvent={newEventPayload.scheduleEvent}
                  prevData={newEventPayload.scheduleEvent.telehealth}
                  onBack={this.onClickTelehealth.bind(this)}
                  onChange={this.onChangeTelehealth.bind(this)}
                  onChangeIsTelehealth={(isTelehealth: boolean) => {
                    this.telehealthManualChange = true;
                    this.onChangScheduleEvent({
                      isTelehealth
                    });
                  }}
                />
              )}
              {settings && (
                <SettingsAppointment
                  forms={this.state.forms || this.props.forms}
                  selectedClinic={selectedClinic}
                  selectedReason={selectedReason}
                  newEventPayload={newEventPayload}
                  onChangeSelectedForms={this.onChangeSelectedForms}
                  onSelectionChanged={this.onClickFinishSelectingForms}
                  onSelectSettings={this.onSelectSettings}
                  recurrence={isEventRecurring}
                  editSingleSeries={
                    action === 'edit' && !actionOption.updateSeries
                  }
                />
              )}
              {history && (
                <AppointmentHistoryContainer
                  patientId={newEventPayload.patients[0]}
                />
              )}
              {scheduling && (
                <SchedulingContainer
                  newEventPayload={this.state.newEventPayload}
                  enableMulti={enableMultiProvider}
                  onSaveSelection={(event: any) =>
                    this.onEndSelectDateAndTime(event)
                  }
                  onCancelSelection={this.onCancelSelectDateAndTime.bind(this)}
                  selectedClinic={selectedClinic}
                  clinics={clinics}
                  providers={selectedProviders}
                />
              )}
              {isSelectingRecurringAppt && (
                <SeriesEditor
                  type={eventType?.toLowerCase()}
                  onSubmit={this.onEndSelectRecurring.bind(this)}
                  onCancel={this.onCancelSelectRecurring.bind(this)}
                  top={'0px'}
                  originalPayload={newEventPayload}
                  providers={providers}
                  clinics={clinics}
                  onSeriesUpdated={this.onSeriesUpdated.bind(this)}
                  isShowingCurentConflicts={this.state.isShowingCurentConflicts}
                />
              )}
              {!history &&
                !scheduling &&
                !isSelectingRecurringAppt &&
                !settings &&
                !telehealth && (
                  <div className="box__content">
                    <ColumnWrapper>
                      {!isTriage && (
                        <AppointmentTypeContainer id={'patient-select-parent'}>
                          <div className="box-main">
                            <TitleContainer
                              style={{
                                display: 'block',
                                marginRight: '1rem',
                                fontWeight: 'bold'
                              }}
                            >
                              <span className={'category-label'}>
                                Appointment
                              </span>
                            </TitleContainer>
                            <Radio
                              disabled={
                                !eventPermission(
                                  EEventTypeCode.appointment,
                                  EApptTypeCode.individual
                                ).success
                              }
                              name="appointment"
                              id={TESTS_IDS.OPTIONS.INDIVIDUAL}
                              checked={typeAppointmentParticipantId === 1}
                              onClick={this.setAppointmentType.bind(
                                this,
                                'individual'
                              )}
                            >
                              Individual
                              <span className="visually-hidden">
                                appointment
                              </span>
                            </Radio>
                            <Radio
                              disabled={
                                !eventPermission(
                                  EEventTypeCode.appointment,
                                  EApptTypeCode.couple
                                ).success
                              }
                              name="appointment"
                              id={TESTS_IDS.OPTIONS.COUPLE}
                              checked={typeAppointmentParticipantId === 2}
                              onClick={this.setAppointmentType.bind(
                                this,
                                'couple'
                              )}
                            >
                              Couple
                              <span className="visually-hidden">
                                appointment
                              </span>
                            </Radio>
                            <Radio
                              disabled={
                                !eventPermission(
                                  EEventTypeCode.appointment,
                                  EApptTypeCode.family
                                ).success
                              }
                              name="appointment"
                              id={TESTS_IDS.OPTIONS.FAMILY}
                              checked={typeAppointmentParticipantId === 3}
                              onClick={this.setAppointmentType.bind(
                                this,
                                'family'
                              )}
                            >
                              Family
                              <span className="visually-hidden">
                                appointment
                              </span>
                            </Radio>
                            <Radio
                              disabled={
                                !eventPermission(
                                  EEventTypeCode.appointment,
                                  EApptTypeCode.group
                                ).success
                              }
                              name="appointment"
                              id={TESTS_IDS.OPTIONS.GROUP}
                              checked={typeAppointmentParticipantId === 4}
                              onClick={this.setAppointmentType.bind(
                                this,
                                'group'
                              )}
                            >
                              Group
                              <span className="visually-hidden">
                                appointment
                              </span>
                            </Radio>
                          </div>
                          <div className="box-main">
                            <TitleContainer
                              style={{
                                display: 'block',
                                marginRight: '1rem',
                                fontWeight: 'bold'
                              }}
                            >
                              <span className={'category-label'}>Provider</span>
                            </TitleContainer>
                            <Radio
                              name="provider"
                              id={TESTS_IDS.PROVIDER_OPTIONS.SINGLE}
                              checked={!enableMultiProvider}
                              onClick={this.setProviderSelection.bind(
                                this,
                                false
                              )}
                            >
                              Single
                              <span className="visually-hidden">provider</span>
                            </Radio>
                            <Radio
                              name="provider"
                              id={TESTS_IDS.PROVIDER_OPTIONS.MULTIPLE}
                              checked={enableMultiProvider}
                              onClick={this.setProviderSelection.bind(
                                this,
                                true
                              )}
                            >
                              Multiple
                              <span className="visually-hidden">providers</span>
                            </Radio>
                          </div>
                        </AppointmentTypeContainer>
                      )}

                      <LeftColumn
                        active={subjectLimitReached ? 'false' : 'true'}
                      >
                        <Row
                          gutter={16}
                          style={{ flex: 1 }}
                          id="firstSelectorsBar"
                        >
                          <Col span={6}>
                            <PatientContainer id={'pcontainer'}>
                              <PatientSelectContainer style={{ width: '100%' }}>
                                <ElementTitle
                                  star={true}
                                  name={getPatientTerm('Subject')}
                                />
                                <SubjectSearch
                                  disabled={
                                    action === 'edit' &&
                                    typeAppointmentParticipantId === 1
                                  }
                                  modalHomeId={'appointment-popup-search'}
                                  required={true}
                                  disableAutoAdjustOverflow={true}
                                  parentId={'patient-select-parent'}
                                  newInput={true}
                                  selected={selectedSubjects}
                                  onSelectionChanged={(
                                    selectedSubjects: any
                                  ) => {
                                    this.onClickFinishSelection(
                                      selectedSubjects
                                    );
                                  }}
                                  align={'start'}
                                  isMulti={typeAppointmentParticipantId !== 1}
                                  maxSelectionCount={
                                    typeAppointmentParticipantId === 4
                                      ? newEventPayload.scheduleEvent[
                                          'maxParticipants'
                                        ]
                                      : typeAppointmentParticipantId === 3
                                      ? maxFamilyLimit
                                      : typeAppointmentParticipantId
                                  }
                                  contentLocation={{
                                    top: p && p.top + 60,
                                    left: p && p.left
                                  }}
                                  autoSearch={false}
                                  showSelectionName={true}
                                />
                                <ErrorBlock error={error.subject}>
                                  Field is mandatory
                                </ErrorBlock>
                              </PatientSelectContainer>
                            </PatientContainer>
                          </Col>
                          <Col span={6}>
                            <ClinicSelector
                              multiple={false}
                              value={selectedClinic?.code}
                              onChange={(value: string) => {
                                this.onClinicChange(value);
                              }}
                              useClinicCode={true}
                              mandatory={true}
                              selectProps={{
                                getPopupContainer: () =>
                                  document.getElementById('firstSelectorsBar'),
                                label: 'Clinic',
                                inputProps: {
                                  allowClear: false,
                                  placeholder: 'Select Clinic',
                                  id: TESTS_IDS.CLINIC_SELECTOR,
                                  disabled: isTriage
                                },
                                bottomMargin: false
                              }}
                            />
                            <ErrorBlock error={error.clinic}>
                              Field is mandatory
                            </ErrorBlock>
                          </Col>
                          <Col span={6}>
                            <ReasonSelector
                              providerTypes={
                                actionOption.default
                                  ? undefined
                                  : enableMultiProvider
                                  ? providerTypes.find(
                                      (v) => !v || v.length === 0
                                    )
                                    ? undefined
                                    : concattedTypes
                                  : concattedTypes
                              }
                              clinicCodes={[
                                newEventPayload.scheduleEvent.clinicCode
                              ]}
                              name={
                                option === 'type' ? 'Type' : 'Group Of Type'
                              }
                              value={selectedReason?.id}
                              onChange={(v: any) => {
                                this.onReasonChange(v, selectedClinic, () => {
                                  this.onCheckValidReasonProviders(
                                    v,
                                    newEventPayload.providerCodes || []
                                  );
                                  if (!this.telehealthManualChange) {
                                    const reason = getReasonByID(v);
                                    if (reason) {
                                      this.onChangScheduleEvent({
                                        isTelehealth: reason.telehealth
                                      });
                                    }
                                  }
                                });
                              }}
                              reasonTypes={['appointment']}
                              selectProps={{
                                getPopupContainer: () =>
                                  document.getElementById('firstSelectorsBar'),
                                inputProps: {
                                  id: TESTS_IDS.REASON_SELECTOR,
                                  disabled: isTriage
                                    ? false
                                    : actionOption.default
                                    ? false
                                    : (
                                        newEventPayload.providerCodes?.map(
                                          (v) => v.code
                                        ) || []
                                      ).length === 0
                                }
                              }}
                            />
                            <ErrorBlock error={error.reason}>
                              Field is mandatory
                            </ErrorBlock>
                          </Col>
                          {!isTriage && (
                            <Col span={6}>
                              {enableMultiProvider ? (
                                <ProviderSelector
                                  enableFilters={true}
                                  multiple={true}
                                  roleFilter="appointment"
                                  filterByClinics={
                                    selectedClinic ? [selectedClinic.id] : []
                                  }
                                  value={
                                    newEventPayload.providerCodes?.map(
                                      (v) => v.code
                                    ) || []
                                  }
                                  isSecureFilterByReasons={true}
                                  filterByReasons={
                                    actionOption.default
                                      ? selectedReason?.id
                                        ? [selectedReason?.id]
                                        : undefined
                                      : undefined
                                  }
                                  useCode={true}
                                  providerFilter={(provider) => {
                                    if (
                                      selectedReason &&
                                      providersValidForSelectedReason
                                    ) {
                                      return providersValidForSelectedReason.includes(
                                        provider.code
                                      );
                                    }
                                    return true;
                                  }}
                                  onChange={(values: string[], e: any) => {
                                    this.onProvidersChange(
                                      values
                                      // ,() => {
                                      // this.onCheckValidReasonProviders(
                                      //   selectedReason?.id,
                                      //   values
                                      // );
                                      // }
                                    );
                                  }}
                                  disabled={providerDisabled}
                                  selectProps={{
                                    getPopupContainer: () =>
                                      document.getElementById(
                                        'firstSelectorsBar'
                                      ),
                                    tooltip: providerDisabled
                                      ? 'Please select an appointment type'
                                      : undefined,
                                    star: true,
                                    bottomMargin: false,
                                    inputProps: {
                                      id: TESTS_IDS.MULTIPLE_PROVIDER_SELECTOR
                                    }
                                  }}
                                />
                              ) : (
                                <ProviderSelector
                                  filterByReasons={
                                    actionOption.default
                                      ? selectedReason?.id
                                        ? [selectedReason?.id]
                                        : undefined
                                      : undefined
                                  }
                                  isSecureFilterByReasons={true}
                                  useCode={true}
                                  roleFilter="appointment"
                                  filterByClinics={
                                    selectedClinic ? [selectedClinic.id] : []
                                  }
                                  providerFilter={(provider) => {
                                    if (
                                      selectedReason &&
                                      providersValidForSelectedReason
                                    ) {
                                      return providersValidForSelectedReason.includes(
                                        provider.code
                                      );
                                    }
                                    return true;
                                  }}
                                  value={newEventPayload.providerCodes[0]?.code}
                                  onChange={(value: any, e: any) => {
                                    this.onProvidersChange(
                                      [value]
                                      // ,() => {
                                      // this.onCheckValidReasonProviders(
                                      //   selectedReason?.id,
                                      //   [value]
                                      // );
                                      // }
                                    );
                                  }}
                                  disabled={providerDisabled}
                                  selectProps={{
                                    getPopupContainer: () =>
                                      document.getElementById(
                                        'firstSelectorsBar'
                                      ),
                                    tooltip: providerDisabled
                                      ? 'Please select an appointment type'
                                      : undefined,
                                    star: true,
                                    bottomMargin: false,
                                    inputProps: {
                                      allowClear: false,
                                      id: TESTS_IDS.PROVIDER_SELECTOR
                                    }
                                  }}
                                />
                              )}
                              <ErrorBlock error={error.provider}>
                                Field is mandatory
                              </ErrorBlock>
                            </Col>
                          )}
                        </Row>
                      </LeftColumn>
                      {typeAppointmentParticipantId === 4 ? (
                        <div style={{ display: 'flex' }}>
                          <PatientCategoryContainer>
                            {loadingPatients && <AbsoluteLoader />}
                            <AddButtonContainer>
                              <ButtonIcon
                                id={TESTS_IDS.ADD_CATEGORY}
                                name={ICONS_LIST.add}
                                size={22}
                                onClick={() => {
                                  this.onClickAddCategoryToAppointment();
                                }}
                              />
                            </AddButtonContainer>
                            <CategorySelector
                              type="single"
                              id={TESTS_IDS.GROUP_SELECTOR}
                              useCode={true}
                              value={categoryCode}
                              onChange={(value: any) => {
                                this.onSelectCategoryCode(value);
                              }}
                              selectProps={{
                                star: false,
                                label: 'Group',
                                inputProps: {
                                  placeholder: 'Start typing category',
                                  id: TESTS_IDS.GROUP_SELECTOR
                                },
                                bottomMargin: false
                              }}
                            />
                            <ErrorBlock error={categoryAddAlert}>
                              {categoryAddAlertMessage}
                            </ErrorBlock>
                          </PatientCategoryContainer>
                          <MaxParticipantsContainer
                            show={!!this.state.ui.maxParticipants}
                          >
                            <SelectContainer
                              type="number"
                              star={false}
                              value={this.state.ui.maxParticipants}
                              label={maxParticipantsLabel}
                              bottomMargin={false}
                              onChange={(value) =>
                                this.onChangeMaxParticipants(value)
                              }
                              inputProps={{
                                id: TESTS_IDS.MAX_PARTICIPANTS,
                                onBlur: () => this.onMaxParticipantsBlur(),
                                style: {
                                  width: 'unset'
                                },
                                min: 1
                              }}
                            />
                            <ErrorBlock
                              error={subjectLimitReached}
                            >{`You have specified more patients than maximum allowed. (${selectedSubjects?.length}/${maxParticipants})`}</ErrorBlock>
                          </MaxParticipantsContainer>
                          <Checkbox
                            style={{
                              width: 196,
                              height: '100%',
                              marginLeft: 24,
                              marginTop: 24
                            }}
                            checked={this.state.ui.maxParticipants}
                            id="maxParticipantsEnabled"
                            value={this.state.ui.maxParticipants}
                            onChange={(value) => {
                              if (!this.state.ui.maxParticipants) {
                                this.onChangeMaxParticipants(
                                  this.state.newEventPayload.patients?.length ??
                                    10
                                );
                              } else {
                                this.onChangeMaxParticipants(0);
                              }
                            }}
                          >
                            Enable Max Participants
                          </Checkbox>
                        </div>
                      ) : null}
                      {!isTriage && (
                        <RightColumn>
                          <DateAndTimeBlock>
                            <div className="box-main">
                              <TitleContainer
                                style={{
                                  display: 'block',
                                  marginRight: '1rem',
                                  fontWeight: 'bold'
                                }}
                              >
                                <label className={'category-label'}>
                                  Date and Time
                                </label>
                              </TitleContainer>
                              <ConditionalButton
                                id={TESTS_IDS.SCHEDULING_BUTTON}
                                iconName="emptyCalendar"
                                text="Scheduling"
                                onClick={() => {
                                  if (!disableDateTimeSelect) {
                                    !disableDateTimeSelect &&
                                      this.onSelectDateAndTime();
                                  }
                                }}
                                isFilled={false}
                                isDisabled={
                                  disableDateTimeSelect ||
                                  scheduleEvent['recurring']
                                }
                              />
                              {showRecurringButton ? (
                                <ConditionalButton
                                  id={TESTS_IDS.RECURRING_BUTTON}
                                  iconName="recurrence"
                                  text="Recurring"
                                  onClick={() => {
                                    if (!disableDateTimeSelect) {
                                      this.onSelectRecurring(
                                        !disableDateTimeSelect
                                      );
                                    } else {
                                      notification.error({
                                        message: 'Error',
                                        description: `Please select appt type`
                                      });
                                    }
                                  }}
                                  isFilled={isEventRecurring}
                                  isDisabled={disableDateTimeSelect}
                                />
                              ) : null}
                            </div>
                            <div className="box-main">
                              <TitleContainer
                                style={{
                                  display: 'block',
                                  marginRight: '1rem',
                                  fontWeight: 'bold'
                                }}
                              >
                                <label className={'category-label'}>
                                  Forms
                                </label>
                              </TitleContainer>
                              <ConditionalButton
                                id={TESTS_IDS.SETTINGS_BUTTON}
                                iconName="document"
                                text=""
                                infoNumber={
                                  settingsCount && settingsCount > 0
                                    ? settingsCount
                                    : 0
                                }
                                onClick={() => {
                                  this.onSelectSettings();
                                }}
                                isFilled={settingsCount && settingsCount > 0}
                                isDisabled={disableDateTimeSelect}
                              />
                            </div>
                          </DateAndTimeBlock>
                        </RightColumn>
                      )}

                      {isEventRecurring && (
                        <div
                          style={{
                            paddingLeft: 24,
                            cursor:
                              isCreation || updateSeries
                                ? disableDateTimeSelect
                                  ? 'auto'
                                  : 'pointer'
                                : 'auto'
                          }}
                          onClick={() => {
                            if (!(isCreation || updateSeries)) return;
                            if (disableDateTimeSelect) return;
                            this.onSelectRecurring(!disableDateTimeSelect);
                          }}
                        >
                          {RecurrenceDisplay(
                            newEventPayload.scheduleEvent.series
                          )}
                        </div>
                      )}
                      {!showRecurringText && (
                        <div id="secondSelectorsBar">
                          <SelectionContainer
                            style={{
                              width: '240px',
                              marginRight: '24px',
                              marginLeft: '24px'
                            }}
                          >
                            <ElementTitle star={true} name="Start Date" />
                            <StartDate
                              className={error.startDate ? 'error-border' : ''}
                            >
                              <SelectContainer
                                type="date"
                                hideLabel={true}
                                label="Start Date"
                                value={startDate}
                                onChange={(value: any) => {
                                  this.onStartDateChange(value);
                                }}
                                bottomMargin={false}
                                inputProps={{
                                  getPopupContainer: () =>
                                    document.getElementById(
                                      'secondSelectorsBar'
                                    )!,
                                  id: TESTS_IDS.START_DATE,
                                  allowClear: false
                                }}
                              />
                            </StartDate>
                            <ErrorBlock
                              style={{
                                flexDirection: 'column',
                                alignItems: 'start',
                                minHeight: 22
                              }}
                              error={
                                error.startDate || pastDay || error.clinicClosed
                              }
                            >
                              {error.startDate ? (
                                <span>Field is mandatory</span>
                              ) : pastDay ? (
                                <span>
                                  The selected date has already passed
                                </span>
                              ) : null}
                              {error.clinicClosed && (
                                <span>{error.clinicClosed}</span>
                              )}
                            </ErrorBlock>
                          </SelectionContainer>
                          {!isTriage && (
                            <SelectionContainer style={{ width: '240px' }}>
                              <SelectContainer
                                type="select"
                                label="Start Time"
                                disableDefaultSort={true}
                                value={startTimeOpt?.value}
                                options={startTimeOptions.map((v: any) => ({
                                  value: v.value,
                                  title: v.label,
                                  option: v
                                }))}
                                avoidOptionsSorting={true}
                                inputProps={{
                                  allowClear: false,
                                  disabled: startDate ? false : true,
                                  virtual: false,
                                  id: TESTS_IDS.START_TIME
                                }}
                                getPopupContainer={() =>
                                  document.getElementById('secondSelectorsBar')
                                }
                                onChange={(value: any) => {
                                  const option = startTimeOptions.find(
                                    (v: any) => v.value === value
                                  );
                                  this.onStartTimeChange(
                                    option,
                                    selectedClinic
                                  );
                                }}
                                bottomMargin={false}
                              />
                              <ErrorBlock
                                error={
                                  error.startDate || (pastTime && !pastDay)
                                }
                              >
                                {!(pastTime && !pastDay)
                                  ? 'Field is mandatory'
                                  : 'The selected time has already passed'}
                              </ErrorBlock>
                            </SelectionContainer>
                          )}
                          {!isTriage && (
                            <SelectionContainer style={{ width: '240px' }}>
                              <SelectContainer
                                type="select"
                                label="End Time"
                                avoidOptionsSorting={true}
                                getPopupContainer={() =>
                                  document.getElementById('secondSelectorsBar')
                                }
                                value={endTimeOpt?.value}
                                disableDefaultSort={true}
                                options={endTimeOptions.map((v: any) => ({
                                  value: v.value,
                                  title: v.label,
                                  option: v
                                }))}
                                inputProps={{
                                  allowClear: false,
                                  disabled: startTimeOpt ? false : true,
                                  virtual: false,
                                  id: TESTS_IDS.END_TIME
                                }}
                                onChange={(value: any) => {
                                  const option = endTimeOptions.find(
                                    (v: any) => v.value === value
                                  );
                                  this.onEndTimeChange(option, selectedClinic);
                                }}
                                bottomMargin={false}
                              />
                              <ErrorBlock error={error.duration}>
                                Field is mandatory
                              </ErrorBlock>
                            </SelectionContainer>
                          )}
                          {!isTriage && (
                            <SelectionContainer style={{ width: '240px' }}>
                              <GroupTypesSelector
                                type="single"
                                id="typeAppointmentLocationId"
                                groupId="typeAppointmentLocation"
                                name={'Location'}
                                value={scheduleEvent?.typeAppointmentLocationId}
                                onChange={(value) => {
                                  this.onLocationChange(value ? value : null);
                                }}
                                selectProps={{
                                  inputProps: {
                                    allowClear: true
                                  },
                                  getPopupContainer: () =>
                                    document.getElementById(
                                      'secondSelectorsBar'
                                    ),
                                  star: false
                                }}
                              />
                            </SelectionContainer>
                          )}
                        </div>
                      )}

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <NoteContainer>
                          <Comment
                            defaultValue={scheduleEvent['comment']}
                            onChange={this.onChangeComment}
                          />
                        </NoteContainer>
                        {selectedSubjects && (
                          <Row
                            gutter={16}
                            align="middle"
                            style={{ paddingRight: 24 }}
                          >
                            {typeAppointmentParticipantId === 1 && (
                              <Col>
                                <BtnContainer
                                  id={TESTS_IDS.APPOINTMENT_HISTORY_BUTTON}
                                  onClick={this.onClickAppointmentHistory}
                                >
                                  <Row gutter={4} align="middle">
                                    <Col>
                                      <ButtonIcon
                                        name={ICONS_LIST.history}
                                        onClick={this.onClickAppointmentHistory}
                                      />
                                    </Col>
                                    <Col>
                                      Appointment
                                      <br />
                                      History
                                    </Col>
                                  </Row>
                                </BtnContainer>
                              </Col>
                            )}
                            <Col>
                              <Button
                                id={TESTS_IDS.TELEHEALTH_BUTTON}
                                className={cn({
                                  'full-blue-button':
                                    newEventPayload.scheduleEvent.isTelehealth,
                                  'grey-button':
                                    !newEventPayload.scheduleEvent.isTelehealth
                                })}
                                shape="round"
                                onClick={this.onClickTelehealth.bind(this)}
                              >
                                Telehealth
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </div>
                      {!isTriage && typeAppointmentParticipantId === 1
                        ? newEventPayload.patients &&
                          newEventPayload.patients[0] && (
                            <AssignedProviders
                              patientId={
                                newEventPayload.patients &&
                                newEventPayload.patients[0]
                              }
                            />
                          )
                        : null}
                      {typeAppointmentParticipantId === 1 ? (
                        <PatientDetail
                          highRisk={newEventPayload.patientsInfo?.[0]?.highRisk}
                          action={this.props.actionOption.action}
                          scheduleEvent={newEventPayload.scheduleEvent}
                          dispatch={store.dispatch}
                          subjectId={
                            newEventPayload.patients &&
                            newEventPayload.patients[0]
                          }
                        />
                      ) : null}
                      {typeAppointmentParticipantId > 1 && (
                        <>
                          <PatientsGroupContainer>
                            {selectedSubjects?.map(
                              (patient: any, patientIdx: number) => (
                                <PatientsGroupItem
                                  key={`${patient.uid}${patientIdx}`}
                                  label={patientDisplayName(patient)}
                                  onRemove={() =>
                                    this.onRemoveGroupSubject(
                                      selectedSubjects,
                                      newEventPayload,
                                      patientIdx
                                    )
                                  }
                                />
                              )
                            )}
                          </PatientsGroupContainer>
                          <GroupPatientsInfo>
                            {selectedSubjects &&
                              selectedSubjects.map((item: any) => {
                                return (
                                  <PatientDetail
                                    highRisk={item?.highRisk}
                                    action={''}
                                    dispatch={undefined}
                                    scheduleEvent={scheduleEvent}
                                    subjectId={item.subjectId}
                                    defaultCollapsed={true}
                                  />
                                );
                              })}
                          </GroupPatientsInfo>
                        </>
                      )}
                    </ColumnWrapper>
                  </div>
                )}
            </AbsoluteContainer>
          </PopupBody>
        </PopupContent>
      </OuterContainer>
    );
  }
}

export default AppointmentPopup;

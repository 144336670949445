import styled from 'styled-components';

export const SubTitle = styled.div`
  color: #666666;
  font-size: 12px;
`;

export const TextTelehealth = styled.div`
  color: #333333;
  margin: 0 0 24px 0;
  white-space: pre-wrap;
`;

export const ContainerBlock = styled.div`
  position: relative;
  padding: 0 24px;
`;

import React, { FC, useMemo } from 'react';
import { Row, Col } from 'antd';

import Table from 'src/Framework/Controls/Table';
import { getReasonByID } from 'src/Framework/Controls/Selectors/ReasonSelector/utils';
import NoteButton from 'src/Framework/Controls/NoteButton';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';
import { patientDisplayName } from 'src/Framework/util/format';
import { formattedDateTime } from 'src/Framework/Shared/Shared';
import store, { IStore, useAppSelector } from 'src/store';

import { loadActivityTab } from 'src/App/ActivityPanel/Store/ActivityActions';
import { isRiskModuleEnabled } from 'src/App/Settings/utils';
import { ActivityType } from 'src/App/ActivityPanel/Models/Activity';
import TypeRisks from 'src/Activities/Home/components/TypesRisks';
import * as dashboardActions from '../store/Actions';
import { IData } from '../types';
import { Widget } from '../Widgets/Widget';
import TableauReport from '../TableauReport';

import { Container } from './styled';
import {
  ColumnGlobalTitle,
  ColumnIdentifier,
  IColumn
} from 'src/Framework/Controls/Table/types';

interface IProps {}

type ICrisisAppointments =
  IStore['dashboard']['riskManagement']['crisisAppointments'];

const DataTable = ({
  crisisAppointments,
  sort
}: {
  crisisAppointments: ICrisisAppointments;
  sort: any;
}) => {
  const columns = useMemo(() => {
    const columns: (IColumn & { hide?: boolean })[] = [
      {
        title: ColumnGlobalTitle.accountNumber,
        key: 'patientId',
        sortBy: 'patientId',
        flex: '80px',
        identifier: ColumnIdentifier.patientId
      },
      {
        title: getPatientTerm('Subject Name'),
        key: 'fullName',
        ellipsis: true,
        sortBy: 'patientName',
        flex: 'auto'
      },
      {
        title: 'Appt Type',
        key: 'apptType',
        sortBy: 'apptType',
        ellipsis: true,
        flex: '150px'
      },
      {
        title: 'Appt Date',
        key: 'apptDate',
        sortBy: 'apptDateString',
        flex: '180px'
      },
      {
        flex: '95px',
        title: 'AR',
        key: 'ar',
        centered: true,
        hide: !isRiskModuleEnabled()
      },
      {
        flex: '95px',
        title: 'SI',
        key: 'si',
        centered: true,
        hide: !isRiskModuleEnabled()
      },
      {
        flex: '95px',
        title: 'HI',
        key: 'hi',
        centered: true,
        hide: !isRiskModuleEnabled()
      },
      {
        title: '',
        key: 'notes',
        flex: '80px',
        centered: true
      },
      {
        title: '',
        key: 'edit',
        flex: '80px',
        centered: true
      }
    ];
    return columns.filter((v) => (v.hide === true ? false : true));
  }, []);
  return (
    <Table
      infinity={true}
      sort={sort}
      onChangeSort={(value) =>
        dashboardActions.setSort('crisisAppointments', value)
      }
      data={crisisAppointments.map((value) => {
        const reason = value.latestProviderAppointment?.appointmentReasonId
          ? getReasonByID(value.latestProviderAppointment?.appointmentReasonId)
          : null;
        const patientName = patientDisplayName(value.patient);
        const apptTime =
          value.latestProviderAppointment?.appointmentDate &&
          value.latestProviderAppointment?.appointmentTime
            ? value.latestProviderAppointment?.appointmentDate.slice(0, 11) +
              value.latestProviderAppointment?.appointmentTime.slice(11)
            : '';
        return {
          patientId: value.patient?.id,
          patientName: patientName,
          lastName: value.patient.lastName,
          fullName: (
            <strong style={{ marginRight: '5px' }}>{patientName}</strong>
          ),
          ar: <TypeRisks data={value.typeRiskIds} riskType="academic" />,
          si: <TypeRisks data={value.typeRiskIds} riskType="suicide" />,
          hi: <TypeRisks data={value.typeRiskIds} riskType="homicide" />,
          apptType: reason?.name || '',
          apptDate: formattedDateTime(apptTime),
          apptDateString: new Date(apptTime).getTime(),
          // value.latestProviderAppointment?.admitTime &&
          // formattedDateTime(value.latestProviderAppointment?.admitTime),
          lastApptString: value.latestProviderAppointment?.admitTime
            ? new Date(value.latestProviderAppointment?.admitTime).getTime()
            : '',
          notes: value.latestProviderAppointment?.notes && (
            <NoteButton
              patientId={value.patient.id}
              multipleNotes={Object.values(
                value.latestProviderAppointment.notes
              )}
              checkType={false}
              inNewTab={true}
            />
          ),
          edit: (
            <ButtonIcon
              isButton={true}
              label="Go to Chart"
              tooltip="Go to Chart"
              name={ICONS_LIST.folderLink}
              containerClassName="right-spaced"
              onClick={() => {
                loadActivityTab(
                  `/client/${value.patient.id}/counseling/dashboard`,
                  store.dispatch,
                  {
                    activityType: ActivityType.Client
                  }
                );
              }}
            />
          )
        };
      })}
      columns={columns}
    />
  );
};

const Component: FC<IProps> = () => {
  const crisisAppointments = useAppSelector((state) => {
    const { riskManagement } = state.dashboard;
    return riskManagement.crisisAppointments;
  });
  const sort = useAppSelector((state) => {
    const { sort } = state.dashboard;
    return sort.crisisAppointments;
  });
  const filters = useAppSelector((state) => {
    const { riskManagement } = state.dashboard;
    return riskManagement.filters;
  });
  const gridData: IData = [
    {
      height: '50%',
      columns: [
        {
          span: 24,
          type: 'fixed_widget',
          props: {
            content: (
              <TableauReport
                reportName="CrisisAppointments"
                filters={{
                  startTime: filters.dateRange[0],
                  endTime: filters.dateRange[1],
                  clinicId: filters.clinicId,
                  providerIds: filters.providersIds
                }}
              />
            )
          }
        }
      ]
    },
    {
      height: '50%',
      columns: [
        {
          span: 24,
          type: 'fixed_widget',
          props: {
            content: (
              <DataTable crisisAppointments={crisisAppointments} sort={sort} />
            )
          }
        }
      ]
    }
  ];

  return (
    <Container>
      {gridData.map((value) => (
        <Row
          style={{ height: value.height }}
          tabIndex={0}
          onKeyDown={onKeyDownAccessibility}
        >
          {value.columns.map((val) => (
            <Col
              tabIndex={0}
              span={val.span}
              style={{
                overflowX: 'auto',
                height: '100%'
              }}
            >
              <Widget type={val.type} {...val.props} />
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  );
};

export default Component;

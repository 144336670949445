import React, { FC, useEffect, useRef } from 'react';
import { Col, Row } from 'antd';

import Icon from 'src/Framework/Controls/Icon';
import Button from 'src/Framework/Controls/Button';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import { IAlertsData } from '../AppointmentPopup';

import { Container } from './styled';

interface IProps {
  data: IAlertsData;
  onClickAlertsView: (data: IAlertsData) => any;
}

const color = globalStyleVariables.orange;

export const calculateAlertsCount = (data: IAlertsData) => {
  const patientAlerts = Object.values(data.patientAlerts).reduce(
    (prev, value) => prev + value.length,
    0
  );
  const insurances = Object.values(data.insurances).reduce(
    (prev, value) => prev + value.length,
    0
  );
  return patientAlerts + insurances;
};

const Component: FC<IProps> = ({ data, onClickAlertsView }: IProps) => {
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      //@ts-ignore
      containerRef?.current.focus();
    }
  }, []);

  return (
    <Container tabIndex={0} ref={containerRef}>
      <Row align="middle" gutter={16} justify="space-between">
        <Col>
          <Row align="middle" gutter={8}>
            <Col>
              <Icon name="information" color={color} />
            </Col>
            <Col>
              <span
                style={{
                  color
                }}
              >
                {calculateAlertsCount(data)}
              </span>
            </Col>
            <Col>ALERTS HAVE BEEN FOUND</Col>
          </Row>
        </Col>
        <Col>
          <Button
            disableClickLoader={true}
            id="view-button"
            colorStyles={{ orange: true }}
            onClick={() => {
              onClickAlertsView(data);
            }}
          >
            View
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(Component);

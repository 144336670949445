import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .add-container {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 25px;
    cursor: pointer;

    span {
      color: ${globalStyleVariables.newBrandingPrimary200};
      margin-left: 12px;
      font-family: Lato;
      font-weight: normal;
      font-size: 14px;
    }
  }
`;

import * as React from 'react';

import { CellContainer } from 'src/Activities/Schedule/Main/ScheduleCol/DndDragSource';

import {
  ScheduleColContainer,
  ScheduleGroupsLayout,
  ScheduleOnesLayoutSnapCol,
  EventItem,
  DropTargetStyle,
  TimeBlock
} from '../../../Main/ScheduleCol/ScheduleCol_Styles';

import {
  ScheduleGroupTitle,
  ScheduleGroupBk
} from './ScheduleGroup/ScheduleGroup';
import ScheduleOne from '../../../Main/ScheduleCol/ScheduleOne';
import {
  getMinFrom1200AM,
  timeDiff,
  getTimeObjectStringFromSimpleTime
} from 'src/Activities/Schedule/Utils/Utils';
import { renderSchedules } from '../../../Utils/renderer';
import { ScheduleTimeProps } from 'src/Activities/Schedule/Common/Interfaces';
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import {
  PatientNameContainer,
  TextIconContainer,
  PatientStatusMarkContainer,
  TextEllipsisSpan
} from '../../../Main/ScheduleCol/ScheduleOne/styled';
import { GroupApptSvg } from 'src/Framework/Common/Svg/GroupApptSvg';
import { CoupleApptSvg } from 'src/Framework/Common/Svg/CoupleApptSvg';
import { FamilyApptSvg } from 'src/Framework/Common/Svg/FamilyApptSvg';
import { SingleApptSvg } from 'src/Framework/Common/Svg/SingleApptSvg';
import { DragSvg } from 'src/Framework/Common/Svg/DragSvg';
import { TextIconSvg } from '../../../Svg/TextIconSvg';
import { ReadySvg } from '../../../Svg/ReadySvg';
import { DischargedSvg } from '../../../Svg/DischargedSvg';
import { ArrivedSvg } from '../../../Svg/ArrivedSvg';
import { AdmittedSvg } from '../../../Svg/AdmittedSvg';
import { LateSvg } from '../../../Svg/LateSvg';
import { BlockSvg } from 'src/Activities/Schedule/Svg/BlockSvg';
import { BlockSvgContainer } from '../../../Main/ScheduleCol/Reservations/styled';
import { getClinics } from 'src/Framework/Controls/Selectors/ClinicSelector';
import { defaultApptBackgroundColor } from 'src/App/UserPreferences/store';

import { formattedTime24H } from 'src/Framework/Shared/Shared';

interface IState {
  addingDroppableIndex: number;
  doHighlight: Boolean;
  highlightWidth: number;
  highlightHeight: number;
  highlightX: number;
  canDrag: Boolean;
  stretchId: number;
  disableHover: Boolean;
  patientDetail: any;
  initialScrollTop: number;
  overflowPopupIsOpen: boolean;
  patient: any;
  clinicList: any;
  schedules: any[];
  attachedForms: any;
  showDrag: any;
  oveflowId: any;
}

type IProps = ScheduleTimeProps & {
  isShowingPatientName: Boolean;
  providerId: number;
  providerName: string;
  clinicName: string;
  isLoading: boolean;
  overflowPopupIsOpen: boolean;
  providerCode: any;
  event: any;
  clinicCode: any;
  dayEndTime: any;
  dayStartTime: any;
  regularMeetingTime: any;
  pages: number;
  objKey: any;
  schedules: any[];
  scheduleGroups: any[];
  isShowingReservationLabel: boolean;
  scrollToTop: () => void;
  scrollToBottom: () => void;
  stopScroll: () => void;
  getScrollTop: () => number;
  getElementHeight: () => number;
  date: any;
  colEvents: any;
  timeToMarkStart?: string;
  timeToMarkEnd?: string;
  timeLayerMode?: boolean;
};

class CalendarSnapCol extends React.Component<IProps, IState> {
  public mergedSchedules: any[] = [];
  public patient: any = {};
  public highlightRef: any;
  public columnRef: any;
  public prevHighlightY: string = '-1000';
  public prevPosByUnit: number = -1000;
  public prevBottomPosByUnit: number = -1000;

  constructor(props: IProps) {
    super(props);
    this.state = {
      addingDroppableIndex: -1,
      doHighlight: false,
      highlightWidth: 0,
      highlightHeight: 0,
      highlightX: 0,
      canDrag: true,
      stretchId: -1,
      overflowPopupIsOpen: false,
      disableHover: false,
      patientDetail: null,
      schedules: [],
      oveflowId: null,
      showDrag: null,
      initialScrollTop: 0,
      patient: null,
      clinicList: getClinics(),
      attachedForms: null
    };
  }

  getHighlightRef(): any {
    return this.highlightRef;
  }

  onStartStretch(stretchId: number) {}

  onMouseMove(e: any) {}

  onDoubleClick(patient: any, idx: number, event: any) {}

  onClickOverflow(patient: any, idx: number, e: any) {
    this.setState({
      oveflowId: idx,
      overflowPopupIsOpen: true
    });
  }

  onDoubleClickReservation(patient: any, idx: number, event: any) {}

  onHoverEmpty(startTime: any) {
    let clinic = this.state.clinicList.find(
      (clinic: any) =>
        clinic.code.toLowerCase() === this.props.clinicCode.toLowerCase()
    );
    if (clinic) {
      let disableHover = false;
      if (
        getMinFrom1200AM(moment(clinic.startTime).format('HH:MM')) >
        getMinFrom1200AM(startTime) + 5
      ) {
        disableHover = true;
      }
      if (disableHover !== this.state.disableHover) {
        this.setState({
          disableHover: disableHover
        });
      }
    }
  }

  getStrokeColor(
    color: any,
    isBlock: boolean,
    isReservation: boolean,
    inReservation: boolean
  ) {
    {
      if (isBlock && !isReservation) {
        return '#f7f7f7';
      } else if (isReservation) {
        return color;
      } else {
        if (inReservation) {
          return color;
        } else {
          return '#666666';
        }
      }
    }
  }

  checkPatientStatus(patientStatus: any, color: any) {
    let statusIcon: any;
    if (patientStatus.ready) {
      statusIcon = <ReadySvg height={'15'} width={'15'} stroke={color} />;
    } else if (patientStatus.discharged) {
      statusIcon = <DischargedSvg height={'15'} width={'15'} stroke={color} />;
    } else if (patientStatus.checkedIn) {
      statusIcon = <ArrivedSvg height={'15'} width={'15'} stroke={color} />;
    } else if (patientStatus.admitted) {
      statusIcon = <AdmittedSvg height={'15'} width={'15'} stroke={color} />;
    } else if (patientStatus === 'Late') {
      statusIcon = <LateSvg height={'15'} width={'15'} stroke={color} />;
    }

    return statusIcon;
  }

  checkApptType(event: any) {
    let { isBlock, isReservation, typeAppointmentParticipantId } = event;
    let typeIcon: any = <SingleApptSvg />;
    if (!isBlock && !isReservation) {
      if (typeAppointmentParticipantId === 4) {
        typeIcon = <GroupApptSvg />;
      } else if (typeAppointmentParticipantId === 2) {
        typeIcon = <CoupleApptSvg />;
      } else if (typeAppointmentParticipantId === 3) {
        typeIcon = <FamilyApptSvg />;
      } else {
        typeIcon = <SingleApptSvg />;
      }
    }

    return typeIcon;
  }

  popOverContent(patient: any, idx: number, updatedSchedules: any[]) {
    let provider = updatedSchedules[0].provider || patient.provider;
    let schedules = updatedSchedules;
    let row = schedules[patient.index];
    if (row && provider) {
      let pages = provider.page;
      row.patients.map((item: any, index: number) => {
        if (index > pages) {
          row.patients[index]['exceededLimit'] = true;
        }
      });
      row.overflows.map((item: any, index: number) => {
        if (index > pages) {
          row.overflows[index]['exceededLimit'] = true;
        }
      });
    }

    return (
      <div>
        {row &&
          row.overflows.map((item: any, index: number) => {
            let startTime = getTimeObjectStringFromSimpleTime(
              item.startTime,
              item.date
            );
            let endTime = getTimeObjectStringFromSimpleTime(
              item.endTime,
              item.date
            );
            return (
              <EventItem
                isBlock={item.isBlock}
                onMouseEnter={() =>
                  this.setState({
                    showDrag: index
                  })
                }
                onMouseLeave={() =>
                  this.setState({
                    showDrag: null
                  })
                }
                hasConflict={item.hasConflict}
                className="scheduleOne"
                count={row.overflows.length}
                index={index}
                onDoubleClick={(e: any) => this.onDoubleClick(item, idx, e)}
                color={item.color || defaultApptBackgroundColor}
              >
                <PatientNameContainer>
                  {this.state.showDrag !== index ? (
                    <span style={{ paddingRight: '0.5rem' }}>
                      {item.isBlock && !item.isReservation ? (
                        <BlockSvgContainer>
                          <BlockSvg />
                        </BlockSvgContainer>
                      ) : (
                        this.checkApptType(item)
                      )}
                    </span>
                  ) : (
                    <span style={{ paddingRight: '0.5rem' }}>
                      {this.state.showDrag === index && !item.isBlock && (
                        <DragSvg />
                      )}
                    </span>
                  )}

                  {this.props.isShowingPatientName ? (
                    <TextEllipsisSpan
                      style={
                        item.isBlock && !item.isReservation
                          ? { color: item.color }
                          : {}
                      }
                    >
                      {item.patientName || item.appointmentFor}
                    </TextEllipsisSpan>
                  ) : null}
                  {item.note && item.note.length > 0 ? (
                    <TextIconContainer>
                      <TextIconSvg />
                    </TextIconContainer>
                  ) : null}
                  {item.patientStatus && (
                    <PatientStatusMarkContainer>
                      {this.checkPatientStatus(
                        item.patientStatus,
                        this.getStrokeColor(
                          item.color,
                          item.isBlock,
                          item.isReservation,
                          item.inReservation
                        )
                      )}
                    </PatientStatusMarkContainer>
                  )}
                </PatientNameContainer>
                <TimeBlock>
                  {moment(startTime).format('hh:mm a') +
                    ' - ' +
                    moment(endTime).format('hh:mm a')}
                </TimeBlock>
              </EventItem>
            );
          })}
      </div>
    );
  }

  onClose = () => {
    if (this.state.oveflowId) {
      this.setState({
        oveflowId: null,
        overflowPopupIsOpen: false
      });
    }
  };

  scheduleGroupBk(
    scheduleGroup: any,
    key: any,
    objKey: any,
    dayStartTime: any,
    regularMeetingTime: any,
    isShowingReservationLabel: any
  ) {
    return (
      <div key={`${objKey}-${scheduleGroup.uid}-${key}`}>
        <ScheduleGroupBk
          isShowingReservationLabel={isShowingReservationLabel}
          key={`group-bk-${objKey}-${scheduleGroup.uid}-${key}`}
          {...scheduleGroup}
          onOpenReservationPopup={(e: any) =>
            this.onDoubleClickReservation(scheduleGroup, key, e)
          }
          dayStartTime={dayStartTime}
          regularMeetingTime={regularMeetingTime}
        ></ScheduleGroupBk>
      </div>
    );
  }

  scheduleGroupLabel(
    scheduleGroup: any,
    key: any,
    dayStartTime: any,
    regularMeetingTime: any,
    isShowingReservationLabel: any
  ) {
    if (scheduleGroup.isReservation) {
      return (
        <ScheduleGroupTitle
          isShowingReservationLabel={isShowingReservationLabel}
          onOpenReservationPopup={(e: any) =>
            this.onDoubleClickReservation(scheduleGroup, key, e)
          }
          key={`group-title-${scheduleGroup.uid}${key}`}
          {...scheduleGroup}
          dayStartTime={dayStartTime}
          regularMeetingTime={regularMeetingTime}
        />
      );
    } else {
      return null;
    }
  }

  scheduleOneCard(
    patient: any,
    idx: any,
    objKey: any,
    dayStartTime: any,
    regularMeetingTime: any,
    isShowingPatientName: any,
    overflowPopupIsOpen: any,
    updatedSchedules: any
  ) {
    const eventEndTime = moment(patient.date).add(patient.duration, 'm');
    const isEventToMark =
      moment(this.props?.timeToMarkStart).isBetween(
        moment(patient.date),
        eventEndTime,
        undefined,
        '[]'
      ) ||
      moment(patient.date).isBetween(
        moment(this.props?.timeToMarkStart),
        moment(this.props?.timeToMarkEnd),
        undefined,
        '[]'
      );
    const y = timeDiff(patient.startTime, dayStartTime) / regularMeetingTime;
    const height = Number(patient.duration) / regularMeetingTime;
    if (patient.isFake) {
      return (
        <CellContainer
          key={`drag-source-${objKey}-${JSON.stringify(patient)}-${idx}`}
          x={0}
          y={y}
          width={0.925}
          height={height}
          patient={patient}
          id={isEventToMark ? 'event-to-scroll' : undefined}
          isFake={true}
        >
          <div></div>
        </CellContainer>
      );
    }
    return (
      <CellContainer
        key={`drag-source-${objKey}-${JSON.stringify(patient)}-${idx}`}
        x={patient.occupyX}
        y={y}
        width={patient.occupyWidth}
        height={height}
        patient={patient}
        id={isEventToMark ? 'event-to-scroll' : undefined}
      >
        <>
          <ScheduleOne
            onDoubleClick={(e: any) => {}}
            onClickOverflow={() => {}}
            isShowingPatientName={isShowingPatientName}
            appointment={{ ...patient }}
          />
          <Tooltip
            title={'overflowpopup'}
            interactive
            interactiveBorder={0}
            trigger={'click'}
            offset={0}
            onRequestClose={() => {
              if (this.state.oveflowId) {
                this.onClose();
              }
            }}
            open={this.state.oveflowId === idx && overflowPopupIsOpen}
            unmountHTMLWhenHide={true}
            multiple={false}
            html={
              <>
                {this.state.oveflowId === idx && (
                  <div
                    onMouseDown={(e: any) => e.preventDefault()}
                    style={{
                      width: '185px',
                      height: 'auto',
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: '2.5px',
                      paddingBottom: '2.5px'
                    }}
                  >
                    {this.popOverContent(patient, idx, updatedSchedules)}
                  </div>
                )}
              </>
            }
            position="right-end"
            arrow
            theme="light"
          ></Tooltip>
        </>
      </CellContainer>
    );
  }

  render() {
    const { highlightWidth, highlightHeight, highlightX, overflowPopupIsOpen } =
      this.state;
    const {
      isShowingPatientName,
      schedules,
      scheduleGroups,
      regularMeetingTime,
      dayStartTime,
      objKey,
      isShowingReservationLabel
    } = this.props;
    const updatedSchedules = renderSchedules(schedules);
    let eventsBase: any[] = [];
    if (this.props.timeLayerMode) {
      eventsBase = [
        {
          date: this.props.timeToMarkStart,
          startTime: formattedTime24H(this.props.timeToMarkStart),
          duration: 60,
          isFake: true
        }
      ];
    }
    const mergedSchedules = eventsBase.concat(
      ...updatedSchedules.map((schedule: any) => schedule.patients)
    );
    let totalHeightByUnit: number;
    if (schedules.length > 0) {
      var lastSchedule = schedules[schedules.length - 1];
      totalHeightByUnit =
        timeDiff(lastSchedule.endTime, dayStartTime) / regularMeetingTime;
    } else {
      totalHeightByUnit = 0;
    }

    return (
      <ScheduleColContainer ref={(ref) => (this.columnRef = ref)}>
        <ScheduleGroupsLayout
          style={{ paddingTop: '5px' }}
          heightByUnit={totalHeightByUnit}
          className="scheduleGroupsLayout"
        >
          {scheduleGroups.map((scheduleGroup, key) => {
            return this.scheduleGroupBk(
              scheduleGroup,
              key,
              objKey,
              dayStartTime,
              regularMeetingTime,
              isShowingReservationLabel
            );
          })}
        </ScheduleGroupsLayout>
        <ScheduleOnesLayoutSnapCol
          style={{ paddingTop: '5px' }}
          heightByUnit={totalHeightByUnit}
          className="scheduleOnesLayout"
          onMouseMove={this.onMouseMove.bind(this)}
        >
          <div
            className="highlightComponent"
            ref={(ref) => (this.highlightRef = ref)}
            style={DropTargetStyle(
              0,
              highlightHeight,
              highlightX,
              highlightWidth
            )}
          />
          {mergedSchedules.map((patient: any, idx: number) => {
            return this.scheduleOneCard(
              patient,
              idx,
              objKey,
              dayStartTime,
              regularMeetingTime,
              isShowingPatientName,
              overflowPopupIsOpen,
              updatedSchedules
            );
          })}
        </ScheduleOnesLayoutSnapCol>
        <ScheduleGroupsLayout style={{ paddingTop: '5px' }} heightByUnit={0}>
          {scheduleGroups.map((scheduleGroup, key) => {
            return this.scheduleGroupLabel(
              scheduleGroup,
              key,
              dayStartTime,
              regularMeetingTime,
              isShowingReservationLabel
            );
          })}
        </ScheduleGroupsLayout>
      </ScheduleColContainer>
    );
  }
}

export default CalendarSnapCol;

import React, { useImperativeHandle, useState } from 'react';
import _ from 'lodash';

import Modal from 'src/Framework/Controls/Modal';
import Button from 'src/Framework/Controls/Button';

import { Container } from './styled';
import { ITicketDetailHipaa } from '../../store/types';

import Main from './Main';
import { getCurrentClinicId } from 'src/Framework/Controls/Selectors/ClinicSelector';
import { getHipaaColor } from '../utils';

import * as hipaaActions from 'src/App/Hippa/store/Actions';
// import * as hipaaSetters from 'src/App/Hippa/store/Actions/Setters';

interface IProps {
  clinicId?: number;
}

interface IShow {
  value: ITicketDetailHipaa;
}

export interface IModalHandles {
  show(obj: IShow): void;
  close(): void;
}

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { clinicId },
  ref
) => {
  const currentClinicId = clinicId || getCurrentClinicId();
  const [data, setData] = useState<ITicketDetailHipaa | null>(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prevData, setPrevData] = useState<ITicketDetailHipaa | null>(null);
  const [offered, setOffered] = useState(false);

  const show = (data: IShow) => {
    setData(_.cloneDeep(data.value));
    setPrevData(_.cloneDeep(data.value));
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setData(null);
    setPrevData(null);
    setOffered(false);
  };

  useImperativeHandle(ref, () => ({
    show,
    close
  }));

  const apply = async () => {
    setLoading(true);
    if (prevData && data) {
      const { id, ...newData } = data;
      const response = await hipaaActions.post(newData);
      if (response?.success) {
        // hipaaSetters.onCreateUpdateItem(newData);
        close();
      }
    }
    setLoading(false);
  };

  const color = prevData ? getHipaaColor(prevData, currentClinicId) : undefined;

  return (
    <Modal
      width={450}
      title="Privacy Policy Status"
      visible={visible}
      onClose={close}
      headerBorder={true}
      afterClose={setToDefault}
      modalProps={{
        destroyOnClose: true
      }}
      headerProps={{
        style: {
          borderTop: `4px solid ${color}`
        }
      }}
      buttons={
        <Button
          id="save-button"
          loading={loading}
          onClick={apply}
          shape="round"
          className="green-button"
        >
          Save
        </Button>
      }
    >
      <Container>
        {data && prevData ? (
          <Main
            data={data}
            setData={setData}
            prevData={prevData}
            clinicId={currentClinicId}
            offered={offered}
            setOffered={setOffered}
          />
        ) : (
          <div />
        )}
      </Container>
    </Modal>
  );
};

export default React.forwardRef(Component);

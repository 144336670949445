import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

interface ButtonContainerProps {
  isDisabled: boolean;
  filled?: boolean;
  color?: string;
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
  margin: 0 8px;
  cursor: ${(props) =>
    props.isDisabled ? 'not-allowed !important' : 'pointer'};

  button {
    border-radius: 50px;
  }

  .ant-btn-text:hover {
    color: ${(props) => props.color} !important;
    background: ${globalStyleVariables.newBrandingTertiary1};
  }

  .conditional-button {
    cursor: inherit !important;
    border-color: transparent !important;
    display: flex !important;
    place-items: center !important;
    padding: 4px 12px !important;
    background: ${(props) =>
      props.filled ? globalStyleVariables.newBrandingTertiary1 : 'unset'};
  }
`;

export const Label = styled.span<ButtonContainerProps>`
  text-transform: capitalize;
  color: ${(props) =>
    props.isDisabled
      ? globalStyleVariables.middleGrey
      : props.color
      ? props.color
      : 'unset'};
  font-weight: bold;
`;

import React from 'react';

import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';

import { Container } from './styled';

interface IProps {}

const Component = ({ children }: React.PropsWithChildren<IProps>) => {
  const onClick = () => {
    window.location.href = `mailto:${children}`;
  };
  return (
    <Container
      tabIndex={!!children ? 0 : -1}
      onKeyDown={onKeyDownAccessibility}
      onClick={onClick}
      aria-label={!!children ? `send email to ${children}` : undefined}
    >
      {children}
    </Container>
  );
};

export default Component;

const ids = {
  container: 'event-container',
  saveButton: 'event-save-button',
  closeButton: 'event-close-button',
  clinicSelect: 'event-clinic-select',
  nameInput: 'event-name-input',
  providerSelect: 'event-provider-select',
  reasonSelect: 'event-reason-select',
  colorSelect: 'event-color-select',
  recurringButton: 'event-recurring-button',
  startDate: 'event-start-date-picker',
  startTime: 'event-start-time-select',
  endTime: 'event-end-time-select',
  noteArea: 'event-note-area',
  days: 'event-days-select',
  hours: 'event-hours-select',
  min: 'event-min-select',
  schedulingButton: 'event-scheduling-button'
};

export default ids;

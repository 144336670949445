import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  max-height: 700px;
  overflow: auto;
`;

export const Title = styled.div`
  font-family: Encode Sans Expanded;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const BlockContainer = styled.div`
  margin: 0 0 10px 0;
  border-radius: 4px;
  border: 1px solid ${globalStyleVariables.borderColor};
  display: flex;
`;

export const RightPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .alert-icon-container {
    mask-size: contain;
    mask-position: center center;
    mask-repeat: no-repeat no-repeat;
    height: 18px;
    width: 18px;
  }

  width: 50px;
`;

export const LeftPart = styled.div`
  padding: 15px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    height: 70vh;
`

interface IBlock {
    rightBorder?: boolean
}

export const Block = styled.div<IBlock>`
    position: relative;
    display: flex;
    width: 50%;
    flex-direction: column;
    padding: 20px;
    ${({ rightBorder }) => rightBorder && `border-right: 1px solid rgba(0,0,0,0.1);`}
`

export const BlockAbsolute = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: overlay;
`

export const Divider = styled.div`
    width: 100%;
    padding: 0.5px 0;
    background-color: rgba(0,0,0,0.1);
    margin: 20px 0;
`

export const SubTitle = styled.div`
    font-weight: 800;
    font-family: 'Encode Sans Expanded';
    margin: 0 0 16px 0;
`

export const BlockFit = styled.div`
    height: fit-content;
    display: flex;
    flex: 1;
    width: 800px;
`

export const ModalSubTitle = styled.span`
    font-size: 12px;
    font-weight: 400;
`
import { Col, Row } from 'antd';
import _ from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import SelectContainer from 'src/Framework/Controls/SelectContainer';

import * as patientActions from 'src/Activities/Subject/Patients/store/Actions';

interface IProps {
  subjectSummary: {
    mobile: string | null;
    email: string | null;
    subjectId: number;
    homePhone: string | null;
    altPhone: string | null;
    workPhone: string | null;
  };
  getSummary?: (patientId: number) => Promise<any>;
}

const Component: FC<IProps> = ({ subjectSummary, getSummary }: IProps) => {
  const [data, setData] = useState(_.cloneDeep(subjectSummary));

  useEffect(() => {
    setData(_.cloneDeep(subjectSummary));
  }, [subjectSummary]);

  const onChangeData = useCallback(async (value: Partial<typeof data>) => {
    setData((data) => ({
      ...data,
      ...value
    }));
  }, []);

  const onClickClose = useCallback(() => {
    setData(_.cloneDeep(subjectSummary));
  }, [subjectSummary]);

  const onClickSave = async () => {
    const res = await patientActions.patchPatientInfo({
      id: subjectSummary.subjectId,
      patientDemographic: {
        email: data.email || '',
        cellPhone: data.mobile || '',
        homePhone: data.homePhone || '',
        workPhone: data.workPhone || '',
        pager: data.altPhone || ''
      } as any
    });
    if (res) {
      if (getSummary) {
        await getSummary(subjectSummary.subjectId);
      }
    }
  };

  const disabled = _.isEqual(data, subjectSummary);
  return (
    <>
      <Row align="middle" gutter={16} wrap={false}>
        <Col>
          <Row align="middle" gutter={16}>
            <Col flex={'140px'}>
              <SelectContainer
                star={false}
                label="Cell"
                type="input"
                inputMask="phoneHome"
                value={data.mobile}
                onChange={(mobile) => onChangeData({ mobile })}
              />
            </Col>
            <Col flex={'140px'}>
              <SelectContainer
                star={false}
                label="Home"
                type="input"
                inputMask="phoneHome"
                value={data.homePhone}
                onChange={(homePhone) => onChangeData({ homePhone })}
              />
            </Col>
            <Col flex={'220px'}>
              <SelectContainer
                star={false}
                label="Alt"
                type="input"
                inputMask="phone"
                value={data.altPhone}
                onChange={(altPhone) => onChangeData({ altPhone })}
              />
            </Col>
            <Col flex={'220px'}>
              <SelectContainer
                star={false}
                label="Work"
                type="input"
                inputMask="phone"
                value={data.workPhone}
                onChange={(workPhone) => onChangeData({ workPhone })}
              />
            </Col>
            <Col flex={'220px'}>
              <SelectContainer
                star={false}
                label="Email"
                type="input"
                value={data.email}
                onChange={(email) => onChangeData({ email })}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row align="middle" gutter={16} wrap={false}>
            <Col>
              <ButtonIcon
                name={ICONS_LIST.check2}
                disabled={disabled}
                iconScale={1.2}
                asyncOnClick={onClickSave}
              />
            </Col>
            <Col>
              <ButtonIcon
                name={ICONS_LIST.thinClose}
                disabled={disabled}
                iconScale={2.3}
                onClick={onClickClose}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Component);

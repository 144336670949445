import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import {
    TxnCode,
    TypeTxnClass,
    ITxnCodePagination,
    TxnCodeSimple,
    TypeTxnName
} from '../types';
import { ISort } from 'src/Framework/Controls/Table/types';

export const getFullDto = async (id: number): Promise<TxnCode | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            `${ApiUrl.TXN_CODES_V2}/${id}`,
            {
                withCredentials: true
            }
        );
        if (response.data.success) {
            const data = response.data.result;
            return data;
        }
        return;
    } catch (e) {
        return;
    }
};

export interface IGetTxnCodeList extends Partial<ISort> {
    typeTxnClass: TypeTxnClass;
    typeTxnNames: TypeTxnName[];
    count: number;
    startIndex: number;
    search: string;
    isActive?: boolean | null
}

export const getTxnCodeList = async ({
    typeTxnClass,
    typeTxnNames,
    count,
    startIndex,
    search,
    sortBy,
    sortType,
    isActive
}: IGetTxnCodeList): Promise<ITxnCodePagination | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            `${ApiUrl.TXN_CODES_V2}`,
            {
                withCredentials: true,
                params: {
                    typeTxnClasses: typeTxnClass,
                    typeTxnNames: typeTxnNames.join(','),
                    count,
                    startIndex,
                    searchString: search,
                    isActive,
                    sortBy,
                    sortType
                }
            }
        );
        if (response.data.success) {
            const data = response.data.result;
            return data;
        }
        return;
    } catch (e) {
        return;
    }
};

export const post = async (data: Partial<TxnCode>): Promise<any> => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            `${ApiUrl.TXN_CODES_V2}`,
            data,
            {
                withCredentials: true
            }
        );
        if (response.data.success) {
            const data = response.data.result;
            return data;
        }
        return;
    } catch (e) {
        return;
    }
};

export const put = async (
    data: Partial<TxnCode> & { id: number }
): Promise<TxnCodeSimple | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.put(
            `${ApiUrl.TXN_CODES_V2}/${data.id}`,
            data,
            {
                withCredentials: true
            }
        );
        if (response.data.success) {
            const data = response.data.result;
            return data;
        }
        return;
    } catch (e) {
        return;
    }
};

export const patch = async (
    data: Partial<TxnCode> & { id: number }
): Promise<TxnCodeSimple | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.patch(
            `${ApiUrl.TXN_CODES_V2}/${data.id}`,
            data,
            {
                withCredentials: true
            }
        );
        if (response.data.success) {
            const data = response.data.result;
            return data;
        }
        return;
    } catch (e) {
        return;
    }
};

import React, { FC } from 'react';
import { Row, Col } from 'antd';

import SelectContainer from 'src/Framework/Controls/SelectContainer';
import ProviderSelector from 'src/Framework/Controls/Selectors/ProviderSelector';
import ClinicSelector from 'src/Framework/Controls/Selectors/ClinicSelector';

import { setRisksFilters } from '../../store/Actions';
import { IRisksFilter } from '../../store/types';
import { useAppSelector, IStore } from 'src/store';

import { Container, ProviderSelectionContainer } from './styled';
import { permissionChecking } from 'src/App/User/Permission';

interface IOwnProps {}

interface IProps extends IOwnProps {}

const Component: FC<IProps> = () => {
  const { filters, active } = useAppSelector(
    (store: IStore) => store.dashboard.riskManagement
  );
  const { providersIds, dateRange, clinicId } = filters;

  const onChangeFilters = (filterData: Partial<IRisksFilter>) => {
    setRisksFilters({ ...filterData });
  };

  return (
    <Container>
      <Row align="top" justify="start" gutter={[16, 8]}>
        {active === 3 && (
          <Col flex="0 0 256px">
            <SelectContainer
              type="dateRange"
              label="Date range"
              range={[dateRange[0] ?? '', dateRange[1] ?? '']}
              star={false}
              onChange={(range: [string | null, string | null]) => {
                onChangeFilters({
                  dateRange: range
                });
              }}
              bottomMargin={false}
            />
          </Col>
        )}
        <Col flex="0 0 256px">
          <ClinicSelector
            multiple={false}
            value={filters.clinicId}
            filterItem={(clinic) => {
              const arr = [
                permissionChecking({
                  anyClinic: false,
                  clinicId: clinic.id,
                  moduleId: 105,
                  functionId: 1200
                }).success,
                permissionChecking({
                  anyClinic: false,
                  clinicId: clinic.id,
                  moduleId: 105,
                  functionId: 1216
                }).success,
                permissionChecking({
                  anyClinic: false,
                  clinicId: clinic.id,
                  moduleId: 105,
                  functionId: 1202
                }).success,
                permissionChecking({
                  anyClinic: false,
                  clinicId: clinic.id,
                  moduleId: 105,
                  functionId: 1203
                }).success
              ];
              if (arr.indexOf(true) === -1) {
                return false;
              }
              return true;
            }}
            onChange={(clinicId: string) => {
              onChangeFilters({ clinicId: +clinicId, providersIds: [] });
            }}
            selectProps={{
              label: 'Clinic',
              bottomMargin: false,
              containerClassName: 'clinic-container',
              inputProps: {
                placeholder: 'Select clinic'
              }
            }}
          />
        </Col>
        {active !== 1 && (
          <Col>
            <ProviderSelectionContainer>
              <ProviderSelector
                multiple={true}
                enableFilters={true}
                blurChange={true}
                value={providersIds}
                filterByClinic={clinicId}
                onChange={(providersIds: number[]) => {
                  onChangeFilters({
                    providersIds: providersIds
                  });
                }}
                selectProps={{
                  bottomMargin: false
                }}
              />
            </ProviderSelectionContainer>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Component;

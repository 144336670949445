import React, { FC, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { v4 } from 'uuid';
import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/es/date-picker';
import IMask from 'imask';
import moment from 'moment';
import classNames from 'classnames';

import { ICommon } from '../index';

export interface IDate extends ICommon {
  type: 'date';
  value: string | null | undefined;
  hasError?: boolean;
  className?: string;
  inputProps: Partial<
    DatePickerProps & {
      disabledTime: undefined;
    }
  >;
  disabled?: boolean;
  format?: any;
  onChange: (dateString: string) => any;
}

const DATE_FORMAT = 'MM/DD/YYYY';
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE }
  },
  format: (date: Date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT
});

const Component: FC<IDate> = (props: IDate) => {
  const linkToProps = useRef<IDate>(props); // !important This variable needs to prevent using wrong instance of function in listeners
  const datePickerRef = useRef<any>(null);
  const labelIdRef = useRef(v4());
  const {
    label,
    inputProps,
    value,
    bottomMargin,
    hasError,
    className,
    disabled,
    format,
    hideLabel,
    id,
    onChange,
    connectToContainerOnScroll,
    containerRef
  } = props;
  useEffect(() => {
    linkToProps.current = props;
  }, [props]);
  useEffect(() => {
    if (datePickerRef.current) {
      const nodeDOM = ReactDOM.findDOMNode(datePickerRef.current);
      const input = nodeDOM?.childNodes[1]?.childNodes[0]?.childNodes[0];
      if (input) {
        const onKeyDown = (event: Event) => {
          const input = event.target as HTMLInputElement;
          if (input) {
            input.value = MASKED.resolve(input.value);
            if (input.value.length === DATE_FORMAT.length) {
              if (linkToProps.current?.inputProps?.disabledDate) {
                const notValid = linkToProps.current.inputProps.disabledDate(
                  moment(input.value)
                );
                if (notValid) {
                  return;
                }
              }
              linkToProps.current?.onChange(input.value);
            }
          }
        };
        input.addEventListener('keyup', onKeyDown);
        return () => {
          input.removeEventListener('keyup', onKeyDown);
        };
      }
    }
    return () => {};
  }, []);
  const areaLabel = typeof label === 'string' ? label : '';

  return (
    <div ref={datePickerRef}>
      <label
        id={labelIdRef.current}
        htmlFor={inputProps.id ? inputProps.id : id}
        className="visually-hidden"
      >
        {label}
      </label>
      <DatePicker
        aria-required={props.star}
        aria-labelledby={labelIdRef.current}
        id={id}
        disabled={disabled}
        {...inputProps}
        getPopupContainer={
          inputProps.getPopupContainer
            ? inputProps.getPopupContainer
            : connectToContainerOnScroll && containerRef
            ? () => containerRef.current
            : undefined
        }
        picker="date"
        value={value ? moment(value) : null}
        className={classNames({
          select: true,
          margin: bottomMargin,
          error: hasError,
          [`${className}`]: className
        })}
        placeholder={
          inputProps?.hasOwnProperty('placeholder')
            ? inputProps?.placeholder
            : hideLabel
            ? areaLabel
            : ''
        }
        popupStyle={{
          zIndex: 10000
        }}
        format={format}
        onChange={(value) => {
          if (onChange) onChange(value !== null ? value.format(format) : '');
        }}
      />
    </div>
  );
};

export default Component;

const ids = {
  popover: 'appointment-popover',
  statusesContainer: 'status-container',
  statusItem: (value: string) => `${`${value}-status`}`,
  headerButtons: 'header-buttons',
  buttons: {
    userInfo: 'user-info',
    forms: 'forms',
    chart: 'chart',
    createNote: 'create-note',
    edit: 'edit',
    remove: 'remove',
    close: 'close'
  },
  cancellationNoteArea: 'cancellation-note-area',
  cancellationReason: 'cancellation-reason',
  cancelRemovePopupButton: 'cancel-remove-popup-button',
  removeButton: 'remove-appointment-button',
  removePopup: 'remove-popup',
  removeSingle: 'remove-single-appt',
  removeAll: 'remove-all-appt',
  editSingle: 'edit-single-appt',
  editAll: 'edit-all-future-appt',
  apptLogPanel: 'appointment-log-panel'
};

export default ids;

import React, { FC, useEffect } from 'react';

import { useAppSelector } from 'src/store';

import * as appointmentTypesActions from 'src/App/Admin/Pages/AppointmentTypes/store/Actions';

import SelectContainer from '../../SelectContainer';
import { ISelect } from 'src/Framework/Controls/SelectContainer/Components/Select';

interface IProps {
  onChange: Function;
  value: any;
  selectProps?: Partial<ISelect>;
}

const Component: FC<IProps> = ({ onChange, value, selectProps }: IProps) => {
  const appointmentTypes = useAppSelector(
    (state) => state.adminPanel.appointmentTypes
  );
  useEffect(() => {
    if (appointmentTypes.genericTypes.groupAppointmentReason.length === 0) {
      appointmentTypesActions.getGenericTypes();
    }
  }, []);
  const options = appointmentTypes.genericTypes.groupAppointmentReason.map(
    (v) => ({
      value: v.id,
      label: v.name
    })
  );
  return (
    <SelectContainer
      label="Appointment Group"
      value={value}
      onChange={onChange}
      options={options}
      inputProps={{
        placeholder: 'Select Appointment Group'
      }}
      {...selectProps}
    />
  );
};

export default Component;

import React, { FC, useMemo } from 'react';
import { Row, Col } from 'antd';

import SelectContainer from 'src/Framework/Controls/SelectContainer';
import test_ids from 'src/tests-script/pages/Schedule/CreateReservationOrBlock';

interface IProps {
  onChange: any;
  releaseBefore: any;
}

const maxTimeInMinutes = 32760;

const MinuteHourDayEditor: FC<IProps> = ({
  onChange,
  releaseBefore
}: IProps) => {
  const maxDays = useMemo(
    () =>
      Math.floor(
        (maxTimeInMinutes -
          releaseBefore['hour'] * 60 -
          releaseBefore['minute']) /
          (60 * 24)
      ),
    [releaseBefore]
  );
  const maxHours = useMemo(
    () =>
      Math.floor(
        (maxTimeInMinutes -
          releaseBefore['day'] * 24 * 60 -
          releaseBefore['minute']) /
          60
      ),
    [releaseBefore]
  );
  const maxMinutes = useMemo(
    () =>
      maxTimeInMinutes -
      releaseBefore['day'] * 24 * 60 -
      releaseBefore['hour'] * 60,
    [releaseBefore]
  );
  return (
    <Row
      id={test_ids.releaseBeforeContainer}
      align="middle"
      gutter={16}
      style={{ marginBottom: 22 }}
      className={'release-before-selector'}
    >
      <Col>
        <SelectContainer
          star={false}
          id={test_ids.days}
          type="number"
          label="Days"
          value={releaseBefore ? releaseBefore['day'] : undefined}
          onChange={(value) => {
            onChange(value, 'day');
          }}
          inputProps={{
            min: 0,
            max: maxDays
          }}
        />
      </Col>
      <Col>
        <SelectContainer
          star={false}
          id={test_ids.hours}
          type="number"
          label="Hours"
          value={releaseBefore ? releaseBefore['hour'] : undefined}
          onChange={(value) => {
            onChange(value, 'hour');
          }}
          inputProps={{
            min: 0,
            max: maxHours
          }}
        />
      </Col>
      <Col>
        <SelectContainer
          star={false}
          id={test_ids.min}
          type="number"
          label="Min"
          value={releaseBefore ? releaseBefore['minute'] : undefined}
          onChange={(value) => {
            onChange(value, 'minute');
          }}
          inputProps={{
            min: 0,
            max: maxMinutes
          }}
        />
      </Col>
    </Row>
  );
};

export default React.memo(MinuteHourDayEditor);

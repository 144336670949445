import React, { FC, useState } from 'react';
import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/es/tooltip';

import ButtonIcon, {
  ICONS_LIST,
  IIcon
} from 'src/Framework/Controls/ButtonIcon';

import Content, { IProps as IContentProps } from './Content';

interface IProps {
  buttonProps?: Partial<IIcon>;
  tooltipProps?: Partial<TooltipProps>;
  contentProps?: IContentProps;
  isLast?: boolean;
  parentRef?: React.MutableRefObject<any>;
}

const Component: FC<IProps> = ({
  buttonProps,
  tooltipProps,
  contentProps,
  isLast,
  parentRef
}: IProps) => {
  const [visible, setVisible] = useState(false);
  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab' && visible) {
      setVisible(false);
    }
    if (!e.shiftKey && e.key === 'Tab') {
      if (isLast && parentRef?.current) {
        e.preventDefault();
        (parentRef?.current as HTMLElement).focus();
      }
    }
    if (e.key === 'Enter') {
      //@ts-ignore
      e.target.click();
    }
  };

  return (
    <Tooltip
      {...tooltipProps}
      open={visible}
      //@ts-ignore
      color="white"
      title={contentProps ? <Content {...contentProps} /> : null}
      overlayClassName="history-popup"
      trigger="click"
      overlayStyle={{ zIndex: 10000 }}
      onOpenChange={(value: boolean) => setVisible(value)}
    >
      <ButtonIcon
        isButton={true}
        label="appointment changes"
        haspopup="dialog"
        toggled={visible}
        controlsId={'history-popup'}
        active={visible}
        iconScale={1.4}
        onClick={() => setVisible((visible) => !visible)}
        name={ICONS_LIST.history}
        {...buttonProps}
        containerProps={{
          onKeyDown: onKeyDown
        }}
      />
    </Tooltip>
  );
};

export default Component;

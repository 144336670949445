import * as React from 'react';
import { Row, Col } from 'antd';
import cn from 'classnames';

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import test_ids from 'src/tests-script/pages/Schedule/Recurring';

import {
  SaveRecurringButtonContainer,
  CancelRecurringButtonContainer
} from 'src/Activities/Schedule/Common/Editors/ScheduleEditor_Styles';
import { Footer } from './SchedulingEditor/SelectDateTime_Styles';

interface IState {
  selectedRecurrence: any;
}

interface IProps {
  disableConfirm: boolean;
  onCancel: any;
  onSubmit: any;
  onCheckForConflicts: any;
  confirmConflicts: boolean;
  inSeries: boolean;
  onClickRemoveRecurrance: any;
}

class ConflictsFooter extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedRecurrence: null
    };
  }

  handleCheckConflictsHandler = () => {
    if (!this.props.disableConfirm) {
      this.props.onCheckForConflicts();
    }
  };

  render() {
    let { disableConfirm, confirmConflicts, inSeries } = this.props;
    return (
      <Footer style={{ boxShadow: '7px -8px 19px rgba(19,65,98,0.06)' }}>
        <Row gutter={16} align="middle" justify="center">
          <Col>
            <CancelRecurringButtonContainer
              id={test_ids.cancel}
              onClick={() => this.props.onCancel()}
            >
              <button className="custom-button">Cancel</button>
            </CancelRecurringButtonContainer>
          </Col>
          {!inSeries && confirmConflicts && (
            <Col>
              <SaveRecurringButtonContainer
                id={test_ids.removeRecurrence}
                onClick={() => this.props.onClickRemoveRecurrance()}
                style={{ margin: 0 }}
              >
                <button className="custom-button">Remove Recurrence</button>
              </SaveRecurringButtonContainer>
            </Col>
          )}
          <Col>
            <SaveRecurringButtonContainer
              style={
                confirmConflicts
                  ? { width: '95px', margin: 0 }
                  : { width: '160px', margin: 0 }
              }
              onClick={this.handleCheckConflictsHandler}
              id={test_ids.confirmOrCheckConflicts}
            >
              <button
                disabled={disableConfirm}
                style={
                  disableConfirm
                    ? { border: '1px solid #cccccc', cursor: 'not-allowed' }
                    : confirmConflicts
                    ? {
                        border: `1px solid ${globalStyleVariables.newBrandingSuccessText}`,
                        color: globalStyleVariables.newBrandingSuccessText
                      }
                    : {}
                }
                className={cn({
                  disabled: disableConfirm,
                  'custom-button': true
                })}
              >
                {confirmConflicts ? 'Confirm' : 'Check for Conflicts'}
              </button>
            </SaveRecurringButtonContainer>
          </Col>
        </Row>
      </Footer>
    );
  }
}

export default ConflictsFooter;

import React, { Suspense } from 'react';
import Dynamsoft from 'dwt';
import { v4 as uuidv4 } from 'uuid';

const DWTUserInterface = React.lazy(() => import('./dwt/DWTUserInterface'));

export default class DWT extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.features) {
      this.features = 0;
      this.props.features.map((value) => {
        if (this.featureSet[value]) this.features += this.featureSet[value];
        return this.features;
      });
      this.initialStatus = 255 - (this.features & 0b11100011);
    }
    this.state = {
      startTime: new Date().getTime(),
      unSupportedEnv: false,
      dwt: null,
      /** status
       * 0:  "Initializing..."
       * 1:  "Core Ready..." (scan)
       * 2:  "Camera Ready..."
       * 32: "BarcodeReader Ready..."
       * 64: "OCR engine Ready..."
       * 128:"Uploader Ready..."
       */
      status: this.initialStatus,
      selected: [],
      buffer: {
        updated: false,
        count: 0,
        current: -1
      },
      saveFileName: new Date().getTime().toString(),
      saveFileFormat: this.props.settings.saveFileFormat,
      zones: [],
      runtimeInfo: {
        curImageTimeStamp: null,
        showAbleWidth: 0,
        showAbleHeight: 0,
        ImageWidth: 0,
        ImageHeight: 0
      }
    };
  }

  DynamsoftObject = Dynamsoft;
  featureSet = {
    scan: 0b1,
    camera: 0b10,
    load: 0b100,
    save: 0b1000,
    upload: 0b10000,
    barcode: 0b100000,
    ocr: 0b1000000,
    uploader: 0b10000000
  };
  features = 0b11111111;
  initialStatus = 0;
  DWObject = null;
  containerId = `dwtcontrolContainer`;
  width = 583;
  height = 513;
  errorCode = null;

  modulizeInstallJS() {
    let _DWT_Reconnect = this.DynamsoftObject.DWT_Reconnect;
    this.DynamsoftObject.DWT_Reconnect = (...args) =>
      _DWT_Reconnect.call({ Dynamsoft: this.DynamsoftObject }, ...args);
    let __show_install_dialog = this.DynamsoftObject._show_install_dialog;
    this.DynamsoftObject._show_install_dialog = (...args) =>
      __show_install_dialog.call({ Dynamsoft: this.DynamsoftObject }, ...args);
    let _OnWebTwainOldPluginNotAllowedCallback =
      this.DynamsoftObject.OnWebTwainOldPluginNotAllowedCallback;
    this.DynamsoftObject.OnWebTwainOldPluginNotAllowedCallback = (...args) =>
      _OnWebTwainOldPluginNotAllowedCallback.call(
        { Dynamsoft: this.DynamsoftObject },
        ...args
      );
    let _OnWebTwainNeedUpgradeCallback =
      this.DynamsoftObject.OnWebTwainNeedUpgradeCallback;
    this.DynamsoftObject.OnWebTwainNeedUpgradeCallback = (...args) =>
      _OnWebTwainNeedUpgradeCallback.call(
        { Dynamsoft: this.DynamsoftObject },
        ...args
      );
    let _OnWebTwainPostExecuteCallback =
      this.DynamsoftObject.OnWebTwainPostExecuteCallback;
    this.DynamsoftObject.OnWebTwainPostExecuteCallback = (...args) =>
      _OnWebTwainPostExecuteCallback.call(
        { Dynamsoft: this.DynamsoftObject },
        ...args
      );
    let _OnRemoteWebTwainNotFoundCallback =
      this.DynamsoftObject.OnRemoteWebTwainNotFoundCallback;
    this.DynamsoftObject.OnRemoteWebTwainNotFoundCallback = (...args) =>
      _OnRemoteWebTwainNotFoundCallback.call(
        { Dynamsoft: this.DynamsoftObject },
        ...args
      );
    let _OnRemoteWebTwainNeedUpgradeCallback =
      this.DynamsoftObject.OnRemoteWebTwainNeedUpgradeCallback;
    this.DynamsoftObject.OnRemoteWebTwainNeedUpgradeCallback = (...args) =>
      _OnRemoteWebTwainNeedUpgradeCallback.call(
        { Dynamsoft: this.DynamsoftObject },
        ...args
      );
    let _OnWebTWAINDllDownloadFailure =
      this.DynamsoftObject.OnWebTWAINDllDownloadFailure;
    this.DynamsoftObject.OnWebTWAINDllDownloadFailure = (...args) =>
      _OnWebTWAINDllDownloadFailure.call(
        { Dynamsoft: this.DynamsoftObject },
        ...args
      );
  }

  componentWillUnmount() {
    if (this.DWObject) {
      this.DynamsoftObject.DWT.DeleteDWTObject(this.DWObject._id);
    }
    this.DynamsoftObject.DWT.RemoveAllAuthorizations();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.state.buffer.count !== prevState.buffer.count) && (this.state.buffer.count > 1) && this.props.singleFileMode) {
      this.setState({ saveFileFormat: 'pdf' });
    }
    if (
      (this.state.saveFileFormat !== prevState.saveFileFormat) && !((this.state.buffer.count > 1) && this.props.singleFileMode)
    ) {
      this.props.actions.updateScannerSettings({
        saveFileFormat: this.state.saveFileFormat
      });
    }
  }

  componentDidMount() {
    var _this = this;
    _this.DynamsoftObject.Ready(function() {
      _this.loadDWT(true);
      // Checking func on MacOS
      // if (
      //   !_this.DynamsoftObject.Lib.env.bWin ||
      //   !_this.DynamsoftObject.Lib.product.bChromeEdition
      // ) {
      //   _this.setState({ unSupportedEnv: true });
      //   return;
      // } else {
      //   _this.loadDWT(true);
      // }
    });
  }

  loadDWT(UseService) {
    this.DynamsoftObject.DWT.ResourcesPath = '/dwt-resources';
    this.DynamsoftObject.DWT.ProductKey =
      'f0068dAAAAKDY4UbfYFTIJSVOGCDWvgp8F2DMhCQgddHzaj2B1tBxwe6f8a4u2pkuW2iufQhEmW3IdkLIjs2Ch3tXfMlu2tM=';

    let innerLoad = (UseService) => {
      this.innerLoadDWT(UseService).then(
        (_DWObject) => {
          this.DWObject = _DWObject;
          // this.DWObject.MaxImagesInBuffer = 1;
          if (
            this.DWObject.Viewer.bind(document.getElementById(this.containerId))
          ) {
            this.DWObject.Viewer.width = this.width;
            this.DWObject.Viewer.height = this.height;
            this.DWObject.Viewer.setViewMode(1, 1);
            this.DWObject.Viewer.show();
            this.handleStatusChange(1);
            this.setState({
              dwt: this.DWObject
            });
            this.DWObject = this.state.dwt;

            if (this.DWObject) {
              /**
               * NOTE: RemoveAll doesn't trigger bitmapchanged nor OnTopImageInTheViewChanged!!
               */
              this.DWObject.RegisterEvent(
                'OnBitmapChanged',
                (changedIndex, changeType) =>
                  this.handleBufferChange(changedIndex, changeType)
              );
              this.DWObject.Viewer.on(
                'topPageChanged',
                (index, bByScrollBar) => {
                  if (bByScrollBar || this.DWObject.isUsingActiveX()) {
                    this.go(index);
                  }
                }
              );
              this.DWObject.RegisterEvent('OnPostTransfer', () =>
                this.handleBufferChange()
              );
              this.DWObject.RegisterEvent('OnPostLoad', () =>
                this.handleBufferChange()
              );
              this.DWObject.RegisterEvent('OnPostAllTransfers', () =>
                this.DWObject.CloseSource()
              );
              this.DWObject.Viewer.on(
                'pageAreaSelected',
                (nImageIndex, rect) => {
                  if (rect.length > 0) {
                    let currentRect = rect[rect.length - 1];
                    let oldZones = this.state.zones;
                    if (rect.length === 1) oldZones = [];
                    oldZones.push({
                      x: currentRect.x,
                      y: currentRect.y,
                      width: currentRect.width,
                      height: currentRect.height
                    });
                    this.setState({ zones: oldZones });
                  }
                }
              );
              this.DWObject.Viewer.on('pageAreaUnselected', () =>
                this.setState({ zones: [] })
              );
              this.DWObject.Viewer.on('click', () => {
                this.handleBufferChange();
              });
              if (this.DynamsoftObject.Lib.env.bWin)
                this.DWObject.MouseShape = false;
              this.handleBufferChange();
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    };
    /**
     * ConnectToTheService is overwritten here for smoother install process.
     */
    this.DynamsoftObject.DWT.ConnectToTheService = () => {
      innerLoad(UseService);
    };
    innerLoad(UseService);
  }

  innerLoadDWT(UseService) {
    return new Promise((res, rej) => {
      if (UseService !== undefined)
        this.DynamsoftObject.DWT.UseLocalService = UseService;
      this.modulizeInstallJS();
      let dwtInitialConfig = {
        WebTwainId: uuidv4()
      };
      this.DynamsoftObject.DWT.CreateDWTObjectEx(
        dwtInitialConfig,
        (_DWObject) => {
          if (this.errorCode === 2) {
            this.errorCode = null;
            this.loadDWT(true);
          } else {
            res(_DWObject);
          }
        },
        (errorString) => {
          this.errorCode = errorString?.code;
          rej(errorString);
        }
      );
    });
  }

  go(index) {
    this.DWObject.CurrentImageIndexInBuffer = index;
    this.handleBufferChange();
  }

  handleBufferChange(changedIndex, changeType) {
    let _updated = false;
    if (changeType === 4) {
      // Modified
      _updated = true;
    }

    let selection = this.DWObject.SelectedImagesIndices;
    this.setState(
      {
        //zones: [],
        selected: selection,
        buffer: {
          updated: _updated,
          current: this.DWObject.CurrentImageIndexInBuffer,
          count: this.DWObject.HowManyImagesInBuffer
        }
      },
      () => {
        if (this.state.buffer.count > 0) {
          this.setState({
            runtimeInfo: {
              curImageTimeStamp: new Date().getTime(),
              showAbleWidth:
                this.DWObject.HowManyImagesInBuffer > 1
                  ? this.width - 16
                  : this.width,
              showAbleHeight: this.height,
              ImageWidth: this.DWObject.GetImageWidth(
                this.state.buffer.current
              ),
              ImageHeight: this.DWObject.GetImageHeight(
                this.state.buffer.current
              )
            }
          });
        }
      }
    );
  }

  handleStatusChange(value) {
    this.setState((state) => {
      return { status: state.status + value };
    });
  }

  handleViewerSizeChange(viewSize) {
    this.width = viewSize.width;
    this.height = viewSize.height;
  }

  render() {
    return this.state.unSupportedEnv ? (
      <div>Please use Chrome, Firefox or Edge on Windows!</div>
    ) : (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <DWTUserInterface
            Dynamsoft={this.DynamsoftObject}
            features={this.features}
            containerId={this.containerId}
            startTime={this.state.startTime}
            dwt={this.state.dwt}
            status={this.state.status}
            buffer={this.state.buffer}
            selected={this.state.selected}
            zones={this.state.zones}
            runtimeInfo={this.state.runtimeInfo}
            handleViewerSizeChange={(viewSize) =>
              this.handleViewerSizeChange(viewSize)
            }
            settings={this.props.settings}
            scannerSettingsActions={this.props.actions}
            handleStatusChange={(value) => this.handleStatusChange(value)}
            handleBufferChange={() => this.handleBufferChange()}
            saveOptions={{
              saveFileName: this.state.saveFileName,
              saveFileFormat: this.state.saveFileFormat,
              onChangeFileName: (saveFileName) =>
                this.setState({ saveFileName }),
              onChangeFileFormat: (saveFileFormat) =>
                this.setState({ saveFileFormat })
            }}
            singleFileMode={this.props.singleFileMode}
          />
        </Suspense>
      </div>
    );
  }
}

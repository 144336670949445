import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const GridWrapper = styled.div`
  &.ag__theme__custom {
    &.history__grid {
      .ag-root-wrapper {
        .ag-body-viewport {
          .ag-row {
            white-space: unset !important;

            .ag-cell {
              white-space: unset !important;
              font-weight: 500;
              font-size: 14px;
              color: #333333;

              &:last-child {
                color: ${globalStyleVariables.brandGray};
              }
            }
          }
        }

        .ag-react-container {
          width: 100% !important;
        }

        .ag-header {
          height: 43px;
          min-height: 43px;
          border-bottom: 1px solid #e6e6e6 !important;

          .ag-header-row {
            .ag-header-cell {
              .ag-header-cell-label {
                .ag-header-cell-text {
                  font-weight: bold;
                  font-size: 12px !important;
                }
              }
            }
          }
        }
      }
    }

    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 34px;
    }

    .ag-root-wrapper {
      border: none;
      border-radius: 0;

      .ag-header {
        background-color: #f7f7f7 !important;
        height: 40px;
        min-height: 40px;
        border-bottom: 1px solid #dee2e6 !important;

        .ag-header-row {
          height: 100% !important;

          .ag-header-cell {
            padding: 0 0.75rem;

            .ag-header-cell-resize {
              display: none;
            }

            .ag-header-cell-label {
              .ag-header-cell-text {
                font-size: 13px;
                overflow: inherit;
                color: #000000;
                width: 100%;
                font-weight: 700;
                font-family: Lato;
              }

              .ag-header-icon {
                span {
                  display: none;
                }

                &.ag-header-cell-menu-button {
                  color: #bbb;

                  &:hover {
                    color: #bbb;
                  }
                }

                &.ag-sort-ascending-icon {
                  display: inline-block;
                  width: 0;
                  height: 0;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  border-top: none;
                  border-bottom: 6px solid #bbb;
                }

                &.ag-sort-descending-icon {
                  display: inline-block;
                  width: 0;
                  height: 0;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  border-bottom: none;
                  border-top: 6px solid #bbb;
                }
              }
            }
          }
        }
      }

      .ag-body-viewport {
        .ag-row {
          white-space: unset !important;
          font-size: inherit;
          border: 0;
          background: transparent;
          height: 34px !important;
          cursor: pointer;

          &:hover,
          &:active,
          &:focus {
            background-color: ${globalStyleVariables.newBrandingHoverMenuItemBG};
          }

          .ag-cell {
            overflow: visible !important;
            white-space: unset !important;
            //border-right-width: 0;
            font-size: 12.5px;
            border: 0;
            line-height: normal;
            padding: 0 0.75rem;
            display: flex;
            align-items: center;
            font-family: Lato !important;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);

            &.ag-cell-inline-editing {
              background: transparent;
              box-shadow: none;
              border: 0;
              height: 34px !important;
            }

            .ag-select {
              width: auto;

              .ag-picker-field-wrapper {
                background: #e6e6e6;
                border-radius: 2px;
                height: 24px;
                box-shadow: none;
                border: none;
                min-height: inherit;
                display: flex;
                width: auto;

                .ag-picker-field-display {
                  margin: 0;
                  padding: 0 8px;
                  flex: none;
                }

                .ag-icon.ag-icon-small-down {
                  font-weight: 900;
                  font-family: 'Font Awesome 5 Free';
                  font-size: 10px;
                  line-height: 10px;
                  padding-right: 14px;

                  &:before {
                    content: ' 078 ';
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const TableReferralsContainer: any = styled.div`
  display: flex;
  width: 100%;

  > div {
    width: 100%;
  }

  .ag__theme__custom {
    width: 100%;

    .ag-root-wrapper {
      .ag-header {
        height: 38px !important;
        min-height: 38px !important;
        border-bottom: none !important;

        .ag-header-row {
          .ag-header-cell {
            justify-content: flex-start;
            padding: 0 24px;

            .ag-header-cell-label {
              .ag-header-cell-text {
                width: auto;
                margin-right: 8px;
              }
            }
          }
        }
      }

      .ag-react-container {
        width: 100% !important;

        > div {
          margin: 0;
        }
      }

      .ag-body-viewport {
        .ag-row {
          .ag-cell {
            padding: 0 24px;

            a {
              color: ${globalStyleVariables.newBrandingPrimary200};
            }
          }

          .icon {
            display: flex;
            align-items: center;

            &:hover {
              svg {
                circle {
                  fill: ${globalStyleVariables.newBrandingPrimary200};
                }

                path {
                  stroke: #fff;
                }
              }
            }

            &.icon-delete {
              svg {
                circle {
                  fill: transparent;
                }

                path {
                  stroke: ${globalStyleVariables.brandGray};
                }
              }

              &:hover {
                svg {
                  circle {
                    fill: ${globalStyleVariables.newBrandingPrimary200};
                  }

                  path {
                    stroke: #fff;
                  }
                }
              }
            }

            &.icon-edit {
              svg {
                width: 12px;
                height: 12px;
              }

              &:hover {
                svg {
                  path {
                    stroke: ${globalStyleVariables.newBrandingPrimary200};
                  }
                }
              }
            }

            &.icon-attachment {
              &:hover {
                svg {
                  g {
                    path {
                      stroke: #fff;

                      &.square {
                        stroke: transparent;
                        fill: ${globalStyleVariables.newBrandingPrimary200};
                      }
                    }
                  }
                }
              }
            }

            span {
              color: #666;
              margin: 0 0 0 8px;
            }

            a {
              margin: 0 0 0 8px;
            }
          }
        }
      }
    }
  }

  .centeredHeader {
    .ag-header-cell-text {
      text-align: center;
    }
  }
`;

export const ReferralsGridWrapper = styled.div`
  &.ag__theme__custom {
    &.history__grid {
      .ag-root-wrapper {
        .ag-body-viewport {
          .ag-row {
            .ag-cell {
              font-weight: 500;
              font-size: 14px;
              color: #333333;

              &:last-child {
                color: ${globalStyleVariables.brandGray};
              }
            }
          }
        }

        .ag-header {
          height: 43px;
          min-height: 43px;
          border-bottom: 1px solid #e6e6e6 !important;

          .ag-header-row {
            .ag-header-cell {
              .ag-header-cell-label {
                .ag-header-cell-text {
                  font-weight: bold;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }

    .ag-root-wrapper {
      border: none;
      border-radius: 0;

      .ag-header {
        background-color: #f7f7f7 !important;
        height: 40px;
        min-height: 40px;
        border-bottom: 1px solid #dee2e6 !important;

        .ag-header-row {
          height: 100% !important;

          .ag-header-cell {
            padding: 0 0.75rem;

            .ag-header-cell-resize {
              display: none;
            }

            .ag-header-cell-label {
              .ag-header-cell-text {
                font-size: 13px;
                overflow: inherit;
                color: #000000;
                width: 100%;
                font-weight: 700;
                font-family: Lato;
              }

              .ag-header-icon {
                span {
                  display: none;
                }

                &.ag-header-cell-menu-button {
                  color: #bbb;

                  &:hover {
                    color: #bbb;
                  }
                }

                &.ag-sort-ascending-icon {
                  display: inline-block;
                  width: 0;
                  height: 0;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  border-top: none;
                  border-bottom: 6px solid #bbb;
                }

                &.ag-sort-descending-icon {
                  display: inline-block;
                  width: 0;
                  height: 0;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  border-bottom: none;
                  border-top: 6px solid #bbb;
                }
              }
            }
          }
        }
      }

      .ag-body-viewport {
        .ag-row {
          font-size: inherit;
          border: 0;
          background: transparent;
          cursor: pointer;

          &:hover,
          &:active,
          &:focus {
            background-color: ${globalStyleVariables.newBrandingHoverMenuItemBG};
          }

          .ag-cell {
            overflow: visible !important;
            //border-right-width: 0;
            font-size: 12.5px;
            border: 0;
            line-height: normal;
            padding: 0 0.75rem;
            display: flex;
            align-items: center;
            font-family: Lato !important;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);

            &.ag-cell-inline-editing {
              background: transparent;
              box-shadow: none;
              border: 0;
              height: 34px !important;
            }

            .ag-select {
              width: auto;

              .ag-picker-field-wrapper {
                background: #e6e6e6;
                border-radius: 2px;
                height: 24px;
                box-shadow: none;
                border: none;
                min-height: inherit;
                display: flex;
                width: auto;

                .ag-picker-field-display {
                  margin: 0;
                  padding: 0 8px;
                  flex: none;
                }

                .ag-icon.ag-icon-small-down {
                  font-weight: 900;
                  font-family: 'Font Awesome 5 Free';
                  font-size: 10px;
                  line-height: 10px;
                  padding-right: 14px;

                  &:before {
                    content: ' 078 ';
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

import React from 'react';

import { permissionChecking } from 'src/App/User/Permission';
import { AppUrl } from 'src/Framework/Common/AppUrl';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import store from 'src/store';

import {
  supervisionTraineePermission,
  supervisorDashboardPermission
} from '../utils';
import { riskManagementGlobalPermissions } from '../Dashboards/RiskManagement';

import * as settingsGetters from 'src/App/Settings/store/Actions/Getters';
import { permittedClasses } from '../OrderManager/utils';

const LazySupervisorDashboard = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SupervisorDashboard" */ '../Dashboards/SupervisorDashboard'
    )
);

const LazyProviderDashboard = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProviderDashboard" */ '../Dashboards/ProviderDashboard'
    )
);

const LazySupervision = React.lazy(
  () => import(/* webpackChunkName: "Supervision" */ '../Supervision')
);

const LazyCohort = React.lazy(
  () => import(/* webpackChunkName: "Cohort" */ '../Cohort')
);

const LazyOutreach = React.lazy(
  () => import(/* webpackChunkName: "Outreach" */ '../Outreach')
);

// const LazyReports = React.lazy(
//   () => import(/* webpackChunkName: "Reports" */ '../Reports')
// );

const LazyTodos = React.lazy(
  () => import(/* webpackChunkName: "Todos" */ '../Todos')
);

const LazyTasks = React.lazy(
  () => import(/* webpackChunkName: "Tasks" */ '../Tasks')
);

const LazyAppointments = React.lazy(
  () => import(/* webpackChunkName: "Appointments" */ '../Appointments')
);

const LazyRiskManagementDashboard = React.lazy(
  () =>
    import(
      /* webpackChunkName: "RiskManagementDashboard" */ '../Dashboards/RiskManagement'
    )
);

const LazyCaseManagementDashboard = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CaseManagementDashboard" */ '../Dashboards/CaseManagementDashboard'
    )
);

const LazyMyClients = React.lazy(
  () => import(/* webpackChunkName: "MyClients" */ '../MyClients')
);

const LazyUnassignedClients = React.lazy(
  () =>
    import(/* webpackChunkName: "UnassignedClients" */ '../UnassignedClients')
);

const LazyMessagesContainer = React.lazy(
  () =>
    import(/* webpackChunkName: "MessagesContainer" */ './MessagesContainer')
);

const LazyBlaster = React.lazy(
  () => import(/* webpackChunkName: "Blaster" */ '../Blaster')
);

const PatientList = React.lazy(
  () => import(/* webpackChunkName: "PatientList" */ '../PatientList')
);

const Rcopia = React.lazy(
  () => import(/* webpackChunkName: "Rcopia" */ '../Rcopia')
);

const Referrals = React.lazy(
  () => import(/* webpackChunkName: "Referrals" */ '../Referrals')
);

const Orders = React.lazy(
  () => import(/* webpackChunkName: "Orders" */ '../OrderManager')
);

const Documents = React.lazy(
  () => import(/* webpackChunkName: "Orders" */ '../DocumentManager')
);

export const navigationPermissions = {
  providerDashboard: () => {
    const data = permissionChecking({
      anyClinic: true,
      moduleId: 105,
      functionId: 1210
    });
    return data;
  },
  CaseManagementDashboard: () => {
    const data = permissionChecking({
      anyClinic: true,
      moduleId: 105,
      functionId: 1211
    });
    return data;
  },
  appointment: () => {
    const data = permissionChecking({
      anyClinic: true,
      moduleId: 105,
      functionId: 1212
    });
    return data;
  },
  myClients: () => {
    const data = permissionChecking({
      anyClinic: true,
      moduleId: 105,
      functionId: 1213
    });
    return data;
  },
  outreach: () => {
    const data = permissionChecking({
      anyClinic: true,
      moduleId: 105,
      functionId: 1214
    });
    return data;
  },
  unassignedClients: () => {
    const data = permissionChecking({
      anyClinic: true,
      moduleId: 105,
      functionId: 1215
    });
    return data;
  },
  cohort: () => {
    const data = permissionChecking({
      anyClinic: true,
      moduleId: 60,
      functionId: 23
    });
    return data;
  },
  referrals: () => {
    const data = permissionChecking({
      anyClinic: true,
      moduleId: 105,
      functionId: 1232
    });
    return data;
  },
  patientsList: () => {
    const patientListAccessEnabled =
      settingsGetters.data().medicatSystemPrefs?.patientListAccessEnabled;
    if (patientListAccessEnabled) {
      const data = permissionChecking({
        anyClinic: true,
        moduleId: 105,
        functionId: 1230
      });
      return data;
    }
    return {
      success: false,
      message: 'Patient List are hidden in Medicat Demo'
    };
  },
  blaster: () => checkBlasterAccess(),
  rcopia: () => {
    const data = permissionChecking({
      anyClinic: true,
      moduleId: 78,
      functionId: 23
    });
    return data;
  },
  documentManager: () => {
    const data = permissionChecking({
      anyClinic: true,
      moduleId: 84,
      functionId: 23
    });
    return data;
  }
};

const checkBlasterAccess = () => {
  const medicatDemo = store.getState().genericTypes.summary.medicatDemo;
  const item = medicatDemo.find((v) => v.key === 'Activity:HideBlaster');
  const message = 'Blaster is hidden in Medicat Demo';
  if (item) {
    if (item.value === 'False') {
      const permission = permissionChecking({
        anyClinic: true,
        moduleId: 105,
        functionId: 1231
      });
      return {
        success: permission.success,
        message: permission.message
      };
    }
  }
  return {
    success: false,
    message: message
  };
};
export const getNavigationList = () => {
  const supervisorPermission = supervisorDashboardPermission();
  const trainee = supervisionTraineePermission();
  const providerDashboarPermission = navigationPermissions.providerDashboard();
  const cohortPermission = navigationPermissions.cohort();
  const caseManagementDashboardPermission =
    navigationPermissions.CaseManagementDashboard();
  const appointmentPermission = navigationPermissions.appointment();
  const myClientsPermission = navigationPermissions.myClients();
  const outreachPermision = navigationPermissions.outreach();
  const unassignedClientsPermission = navigationPermissions.unassignedClients();
  const riskPermission = riskManagementGlobalPermissions();
  const patientList = navigationPermissions.patientsList();
  const blasterPermission = navigationPermissions.blaster();
  const referralsPermission = navigationPermissions.referrals();
  const rcopiaPermission = navigationPermissions.rcopia();
  const documentManagerPermission = navigationPermissions.documentManager();

  const list = [
    {
      permission: () => providerDashboarPermission.success,
      accessReason: () => providerDashboarPermission.message,
      path: '/activity/provider-dashboard',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/provider-dashboard`,
      name: 'ProviderDashboard',
      component: LazyProviderDashboard,
      itemName: 'providerDashboard',
      description: 'Provider Dashboard',
      information: ''
    },
    {
      permission: () => supervisorPermission.success,
      accessReason: () => {
        if (!supervisorPermission.isCounseling) {
          return 'Current clinic is not counseling';
        }
        return supervisorPermission.permission.message;
      },
      path: '/activity/supervisor-dashboard',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/supervisor-dashboard`,
      name: 'supervisor-dashboard',
      component: LazySupervisorDashboard,
      itemName: 'supervisorDashboard',
      description: 'Supervisor Dashboard',
      information: ''
    },
    {
      permission: () => cohortPermission.success,
      accessReason: () => cohortPermission.message,
      path: '/activity/cohort',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/cohort`,
      name: 'cohort',
      component: LazyCohort,
      itemName: 'cohort',
      description: 'Compliance',
      information: ''
    },
    {
      permission: () => caseManagementDashboardPermission.success,
      accessReason: () => caseManagementDashboardPermission.message,
      path: '/activity/case-management-dashboard',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/case-management-dashboard`,
      name: 'CaseManagementDashboard',
      component: LazyCaseManagementDashboard,
      itemName: 'caseManagementDashboard',
      description: 'Case Management',
      information: ''
    },
    {
      permission: () => riskPermission.success,
      accessReason: () => riskPermission.message,
      path: '/activity/risk-management-dashboard',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/risk-management-dashboard`,
      name: 'risk-management-dashboard',
      component: LazyRiskManagementDashboard,
      itemName: 'riskManagementDashboard',
      description: 'Risk Management',
      information: ''
    },
    {
      permission: () => appointmentPermission.success,
      accessReason: () => appointmentPermission.message,
      path: '/activity/appointment',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/appointment`,
      name: 'appointment',
      component: LazyAppointments,
      itemName: 'appointment',
      description: 'Appointments',
      information: ''
    },
    {
      permission: () => true,
      accessReason: () => '',
      path: `/activity${AppUrl.ACTIVITYMESSAGES}`,
      pageUrl: `${AppUrl.PAGE_ACTIVITY}${AppUrl.ACTIVITYMESSAGES}`,
      name: 'messages',
      component: LazyMessagesContainer,
      itemName: 'messages',
      description: 'Messages',
      information: ''
    },
    {
      permission: () => myClientsPermission.success,
      accessReason: () => myClientsPermission.message,
      path: `/activity${AppUrl.MYCLIENTS}`,
      pageUrl: `${AppUrl.PAGE_ACTIVITY}${AppUrl.MYCLIENTS}`,
      name: 'myClients',
      component: LazyMyClients,
      itemName: 'myClients',
      description: 'My Clients',
      information: ''
    },
    {
      permission: () => true,
      accessReason: () => '',
      path: '/activity/tasks',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/tasks`,
      name: 'tasks',
      component: LazyTasks,
      itemName: 'tasks',
      description: 'Tasks',
      information: ''
    },
    {
      permission: () => true,
      accessReason: () => '',
      path: '/activity/todos',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/todos`,
      name: 'todos',
      component: LazyTodos,
      itemName: 'todos',
      description: 'To Dos',
      information: ''
    },
    {
      permission: () => outreachPermision.success,
      accessReason: () => outreachPermision.message,
      path: '/activity/outreach',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/outreach`,
      name: 'outreach',
      component: LazyOutreach,
      itemName: 'outreach',
      description: 'Outreach',
      information: ''
    },

    {
      permission: () => unassignedClientsPermission.success,
      accessReason: () => unassignedClientsPermission.message,
      path: `/activity${AppUrl.UNASSIGNEDCLIENTS}`,
      pageUrl: `${AppUrl.PAGE_ACTIVITY}${AppUrl.UNASSIGNEDCLIENTS}`,
      name: 'unassignedClients',
      component: LazyUnassignedClients,
      itemName: 'unassignedClients',
      description: `Unassigned ${getPatientTerm('Clients')}`,
      information: ''
    },
    // {
    //   permission: () => true,
    //   accessReason: () => '',
    //   path: '/activity/reports',
    //   pageUrl: `${AppUrl.PAGE_ACTIVITY}/reports`,
    //   name: 'reports',
    //   component: LazyReports,
    //   itemName: 'reports',
    //   description: 'Reports',
    //   information: ''
    // },
    {
      permission: () => trainee.success,
      accessReason: () => trainee.message,
      path: '/activity/supervision-training',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/supervision-training`,
      name: 'supervision-training',
      component: LazySupervision,
      itemName: 'supervision',
      description: 'Supervision-Training',
      information: ''
    },
    {
      permission: () => blasterPermission.success,
      accessReason: () => blasterPermission.message,
      path: '/activity/blaster',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/blaster`,
      name: 'blaster',
      component: LazyBlaster,
      itemName: 'blaster',
      description: 'Blaster',
      information: ''
    },
    {
      permission: () => patientList.success,
      accessReason: () => patientList.message,
      path: '/activity/patients-list',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/patients-list`,
      name: 'patients-list',
      component: PatientList,
      itemName: 'patients-list',
      description: `${getPatientTerm('Patient')} List`,
      information: ''
    },
    {
      permission: () => rcopiaPermission.success,
      accessReason: () => rcopiaPermission.message,
      path: '/activity/rcopia',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/rcopia`,
      name: 'rcopia',
      component: Rcopia,
      itemName: 'rcopia',
      description: `Rcopia`,
      information: ''
    }, {
      permission: () => referralsPermission.success,
      accessReason: () => referralsPermission.message,
      path: '/activity/referrals',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/referrals`,
      name: 'referrals',
      component: Referrals,
      itemName: 'referrals',
      description: `Referrals`,
      information: ''
    },
    {
      permission: () => permittedClasses().length !== 0,
      accessReason: () => '',
      path: '/activity/order-manager',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/order-manager`,
      name: 'orderManager',
      component: Orders,
      itemName: 'orderManager',
      description: `Order Manager`,
      information: ''
    },
    {
      permission: () => documentManagerPermission.success,
      accessReason: () => documentManagerPermission.message,
      path: '/activity/document-manager',
      pageUrl: `${AppUrl.PAGE_ACTIVITY}/document-manager`,
      name: 'documentManager',
      component: Documents,
      itemName: 'documentManager',
      description: 'Document Manager',
      information: ''
    }
  ];
  return list;
};

const ids = Object.freeze({
  REFERRALS_CREATE_BUTTON: 'referrals-create-button',
  REFERRALS_MODAL_SAVE_BUTTON: 'referrals-modal-save-button',
  REFERRALS_MODAL_CLOSE_BUTTON: 'referrals-modal-close-button',
  REFERRALS_MODAL_CLINIC_SELECTOR: 'referrals-modal-clinic-selector',
  REFERRALS_MODAL_REFERRAL_SELECTOR: 'referrals-modal-referral-selector',
  REFERRALS_MODAL_SEARCH_CODES: 'referrals-modal-search-codes',
  REFERRALS_MODAL_PROVIDER_SELECTOR: 'referrals-modal-provider-selector',
  REFERRALS_MODAL_DATE_SELECTOR: 'referrals-modal-date-selector',
  REFERRALS_MODAL_TIME_SELECTOR: 'referrals-modal-time-selector',
  REFERRALS_MODAL_COMMENTS: 'referrals-modal-comments',
  REFERRALS_MODAL_TASK_PROVIDER_CHECKBOX:
    'referrals-modal-task-provider-checkbox',
  REFERRALS_MODAL_TASK_MANAGER_CHECKBOX:
    'referrals-modal-task-manager-checkbox',
  REFERRALS_MODAL_DASHBOARD_CHECKBOX: 'referrals-modal-dashboard-checkbox',
  REFERRALS_ACTIVITY_LOG_MODAL: 'referrals_activity_log_modal',
  REFERRALS_ACTIVITY_LOG_BUTTON: 'referrals_activity_log_button',
  REFERRALS_HISTORY_POPUP: 'referrals_history_popup',
  REFERRALS_PRIORITY_SELECTOR: 'referrals-priority-selector',
  REFERRALS_STATUS_SELECTOR: 'referrals-status-selector',
  REFERRALS_TABLE: 'referrals-table',
  REFERRALS_ROW: 'referrals-row'
});

export default ids;

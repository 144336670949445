import styled from "styled-components";

export const NotesLayout = styled.div`
    overflow-y: auto;
    margin: 0 24px 24px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
`;  

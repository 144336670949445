import { ISort } from "src/Framework/Controls/Table/types";
import { ETypeTxnClassId } from 'src/App/Admin/Pages/TransactionCodes/store/types';
import { IFileSimpleDto } from "src/Framework/Controls/FileUploader";
import { permissionChecking } from "src/App/User/Permission";

export enum OrderManagerSettingsEntries {
  firstName = 'firstName',
  lastName = 'lastName',
  clinic = 'clinic',
  ticketId = 'ticketId',
  renderingProvider = 'renderingProvider',
  user = 'user',
  status = 'status',
  orderDate = 'orderDate',
  accountNumber = 'accountNumber',
  chartNumber = 'chartNumber',
  comment = 'comment',
  orderName = 'orderName',
  priority = 'priority',
  read = 'read',
  attachment = 'attachment',
  diagnosis = 'diagnosis',
  accessionCode = 'accessionCode',
  otherId = 'otherId',
  orderNumber = 'orderNumber',
  statusDts = 'statusDts',
  class = 'class',
  socialSecurity = 'socialSecurity',
  birthDate = 'birthDate',
  specimenType = 'specimenType',
  temperature = 'temperature',
  // Rx Number ???
  billingProvider = 'billingProvider',
  readBy = 'readBy',
  abnormal = 'abnormal',
  structuredNote = 'structuredNote',
  batchNumber = 'batchNumber',
  schoolName = 'schoolName',
  room = 'room',
  statusUser = 'statusUser',
  received = 'received',
  receivedBy = 'receivedBy',
  receivedDts = 'receivedDts',
  followUpNote = 'followUpNote',
  preferredName = 'preferredName',
  specimenCollected = 'specimenCollected'
}

export const OrderManagerSettingsEntriesPermissions = (): {
  [key in OrderManagerSettingsEntries]?: () => boolean
} => ({
  [OrderManagerSettingsEntries.read]: () => {
    return permissionChecking({
      anyClinic: true,
      moduleId: 40,
      functionId: 117
    }).success
  }
})

export interface IInitFavorite {
  id: number | null
}

export interface IOrderManagerSettingsPreference {
  initFavorite?: IInitFavorite
  columnSettings?: IOrderManagerSettings
}

export interface IOrderManagerSettings {
  entries: OrderManagerSettingsEntries[];
  disabled: OrderManagerSettingsEntries[];
}

export interface IOrderManagerBasicFilters extends ISort {
  startIndex: number
  count: number
  ids?: number[]
}

export interface IOrderManagerFilter {
  patientId: number | null
  clinicIds: number[]
  priorityIds: number[]
  statusIds: number[]
  renderingProviderIds: number[]
  txnCodeClassIds: ETypeTxnClassId[]
  orderDateFrom: string | null
  orderDateTo: string | null
  unread: TypeReadFlag
  reqBatchIds: number[]
  txnCodeIds: number[]
}

export interface IOrderManagerItem {
  receivedUserName: string | null
  reqOrderId: number;
  historical: boolean;
  accessionCode: string;
  typeReqStatusId: number | null;
  typeReqPriorityId: number | null;
  clinicId: number;
  patientId: number;
  txnCodeId: number;
  billingProviderId: number;
  renderingProviderId: number;
  statusChanged: boolean;
  ticketDetailId?: null;
  fileDefinition?: null | IFileSimpleDto;
  fileDefinitionId: null | number
  dts: string;
  facilityId?: null;
  reqBatchId: number;
  unread: TypeReadFlag;
  orderDate: string;
  abnormal: boolean;
  templateFormRecordId?: null | number;
  received: boolean;
  receivedUserId?: null;
  receivedDts?: null;
  followUpSoapSeedId?: null;
  postResultsToNote: boolean;
  name: string;
  patientLastName: string;
  patientFirstName: string;
  patientNickName: string;
  patientFullName: string;
  patientChartNo: string;
  patientBirthDate: string;
  patientSocialSecurityNo?: null;
  patientOtherId: string;
  patientSchoolName: string;
  reqComment: string;
  reqCommentId: number;
  txnCode: string;
  txnDescription: string;
  status: string;
  priority: string;
  billingProvider: string;
  renderingProvider: string;
  userId: number;
  clinicCode: string;
  typeTxnClassId: ETypeTxnClassId;
  typeTxnClassName: string;
  userCode: string;
  statusDts?: null;
  statusUserName?: null;
  readUserId?: null;
  readDts?: null;
  readUserIdFirst?: null;
  readDtsFirst?: null;
  readUserCode?: null;
  readUserCodeFirst?: null;
  soapNoteId?: null;
  specimenType: string;
  temperature: string;
  prescriptionNumber: number;
  ticketId?: null;
  routeToProvider?: null;
  room?: null;
  diagnosis?: null;
  appointmentId?: null;
  inpatientAdmitProviderCode?: null;
  inpatientNurseCode?: null;
  inpatientAdmitClinicCode?: null;
  specimenCollected?: null;
  soapNoteStatus?: null;
  templateFormRecordSummary: null | string
  m1TemplateFormRecordId: null | number
  m1TemplateFormRecordName: null | string
}



export interface IOrderManagerPagination {
  count: number;
  startIndex: number;
  total: number;
  result: IOrderManagerItem[];
}

export interface IView {
  id?: number | string;
  name: string;
  typeId?: number;
  condition: string;
}

export enum TypeReadFlag {
  read = 0,
  needsReading = 1,
  notResulted = -1
}


export interface ReqResultDto {
  id: number;
  orderDts: string
  orderDate: string | null
  reqOrderId: number;
  reqComponentId: number | null;
  reqResultId: number | null;
  reqCommentId: number | null;
  typeReqStatusId: number | null;
  typeReqPriorityId: number | null;
  attachmentPath: string | null;
  results: string | null;
  ticketDetailId: number | null;
  reqBatchId: number | null;
  patientId: number | null;
  name: string | null;
  comment: string | null;
  commentLength: number | null;
  status: string | null;
  priority: string | null;
  reqResultReqBatchId: number | null;
  reqResultReqOrderId: number | null;
  reqResultReqOrderDetailId: number | null;
  reqResultStatusId: number | null;
  reqResultAbnormalId: number | null;
  reqResultReqCommentId: number | null;
  reqResultRenderingProviderId: number | null;
  probability: number | null;
  units: string | null;
  minRange: number | null;
  maxRange: number | null;
  interfaceName: string | null;
  rangeValue: string | null;
  resultValue: string | null;
  lastNormalDate: string | null;
  observationDate: string | null;
  reqResultDts: string | null;
  reqResultAbnormalCode: string | null;
  reqResultAbnormalDescription: string | null;
  reqResultAbnormalFlag: boolean | null;
  reqResultStatusCode: string | null;
  reqResultStatusName: string | null;
  testCode: string | null;
  testDescription: string | null;
  reqResultUnitId: number | null;
  reqResultUnitName: string | null;
  fileDefinition: IFileSimpleDto | null;
  fileDefinitionId: number | null
}

export interface IStatusLog {
  id: number;
  reqOrderId: number;
  typeReqStatusName: string;
  dts: string;
  userName: string;
  userCode: string;
}

export enum TypeLabBilling {
  Unspecified = 0,
  Client = 1,
  Patient = 2,
  ThirdParty = 3
}

export interface ReqBatchDto {
  id: number;
  providerId: number;
  typeLabAccountId: number;
  typeLabBillingId: number;
  clinicalInfoComment: string;
  fasting: boolean;
  dts: string;
  userId: number;
}

export interface IOrderManagerFavoriteSimpleDto {
  id: number;
  name: string;
  isActive: boolean;
  dts: string;
}

export interface IOrderManagerFavoriteExtendedDto extends IOrderManagerFavoriteSimpleDto {
  query: Partial<IOrderManagerFilter>
}
export const columns: any = [
    {
        key: 'name',
        title: 'Name',
        flex: 'auto'
    },
    {
        flex: '140px',
        key: 'highestLevelMark',
        title: 'Highest level mark',
        centered: true
    },
    {
        flex: '100px',
        key: 'enabled',
        title: 'Enabled',
        centered: true
    },
    {
        flex: '100px',
        key: 'color',
        title: 'Color'
    },
    {
        flex: '100px',
        key: 'dashboard',
        title: 'Dashboard',
        centered: true
    },
    {
        flex: '80px',
        key: 'edit',
        title: 'Modify',
        centered: true
    },
    {
        flex: '80px',
        key: 'remove',
        title: 'Delete',
        centered: true
    },
    {
        flex: '130px',
        key: '',
        title: '',
        centered: true,
        type: 'change-order'
    },
]

export const typeNames = {
    academic: 'Academic Risk',
    homicide: 'HI',
    suicide: 'SI'
};
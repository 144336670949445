const ids = {
  options: {
    single: 'single-option',
    multiple: 'multiple-option'
  },
  providerSelector: 'provider-selector',
  dateFrom: 'date-from-picker',
  dateTo: 'date-to-picker',
  timeFrom: 'time-from-select',
  timeTo: 'time-to-select',
  days: 'days-multiple-select',
  duration: 'duration-select',
  isReservation: 'is-reservation-checkbox',
  searchButton: 'search-button',
  schedulingTable: 'scheduling-table',
  clinicOpenCheckbox: 'clinic-open-checkbox',
  groupTypeSelector: 'group-type-selector',
  selectButton: (scheduleUID: string) => `select-button-${scheduleUID}`
};

export default ids;

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const PopupHeader = styled.div`
  width: 100%;
  position: relative;
  border-top: solid ${globalStyleVariables.newBrandingPrimary200} 4px;
  border-bottom: solid #e6e6e6 0.5px;
  box-shadow: 0 4px 12px rgba(19, 65, 98, 0.06);

  .top-container {
    display: flex;
    justify-content: space-between;
  }
`;

interface PopupTitleProps {
  editSeries?: boolean;
}

export const PopupTitle = styled.div`
  padding: 24px;
  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 800;
  font-size: ${(props: PopupTitleProps) => `${props.editSeries ? 30 : 27}px`};
  line-height: 40px;
  display: flex;
  color: #000000;
`;

export const FrequencyWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px !important;

  input[type='radio'] {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    outline: none;
    border: 1px solid #cccccc;
    vertical-align: middle;
  }

  input[type='radio']:checked {
    border: 4px solid ${globalStyleVariables.newBrandingPrimary200};
  }
`;

export const BlockSelfCheckinContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 2rem;
`;

export const PatientInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2.5rem;
  border-left: 1px solid #e6e6e6;
  padding-left: 2.5rem;
`;

export const ClinicInfo = styled.div`
  display: flex;

  .value-label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    display: flex;
    align-items: center;
    color: ${globalStyleVariables.newBrandingPrimary200};
    margin-right: 2rem;
  }
`;

export const PatientNameBlock = styled.div`
  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #333333;
`;

export const LeftColumn = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  width: 100%;

  .left-table {
    margin-bottom: 0 !important;
    margin-right: 3rem;
    box-shadow: 0 1px 10px -3px;
  }

  .tabulator-selected {
    background-color: rgba(14, 109, 159, 0.3) !important;
    transition: background-color 1000ms linear !important;
  }

  .tabulator-tableHolder {
    overflow-x: hidden !important;
  }

  .ind-table {
    margin-bottom: 0 !important;
    box-shadow: 0 1px 10px -3px;
  }

  .right-table {
    margin-bottom: 0 !important;
    box-shadow: 0 1px 10px -3px;
  }

  .tabulator-header-filter {
    input {
      border: 1px solid #e6e6e6;
    }
  }

  .disable-add-cell {
    text-align: center !important;
    color: ${globalStyleVariables.newBrandingPrimary200} !important;
    height: 27px !important;
    min-width: 27px;
    line-height: 16px !important;
    cursor: ${(props: { active: any }) =>
      props.active === 'true' ? 'pointer' : 'not-allowed'};
    position: absolute;
    top: 0;
    left: 12px;

    svg {
      width: 32px;
      height: 32px;
    }

    svg > path {
      stroke-width: 2px;
      stroke: ${(props: { active: any }) =>
        props.active === 'true'
          ? globalStyleVariables.newBrandingPrimary200
          : globalStyleVariables.brandGray};
    }
  }

  .tabulator-placeholder {
    span {
      display: inline-block;
      margin: 0 auto;
      padding: 10px;
      color: ${globalStyleVariables.brandGray} !important;
      font-weight: 400 !important;
      font-size: 19px !important;
    }
  }

  .remove-cell {
    text-align: center !important;
    color: ${globalStyleVariables.newBrandingPrimary200} !important;
    height: 32px !important;
    min-width: 32px;
    line-height: 16px !important;
    position: absolute;
    top: 0;
    left: 15px;

    svg {
      width: 30px;
      height: 30px;
    }

    svg > circle {
      fill: #ffffff;
    }

    svg > path {
      stroke: red;
    }
  }
`;
export const RightColumn = styled.div`
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

interface IPopupBody {
  sliderMode?: boolean;
}

export const PopupBody = styled.div<IPopupBody>`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  flex-grow: 1;
  height: ${(props) => (props.sliderMode ? 'auto' : '590px')};
  display: flex;
  flex-direction: column;
  position: relative;

  .error-border {
    border: 1px solid #f9b40b;
  }

  .box__content {
    padding: 22px 0 0;
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;

interface OptionContainerProps {
  action?: string;
  disableDateTimeSelect?: any;
  typeAppointmentParticipantId?: number;
}

export const AppointmentTypeContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: space-between;

  .box-main {
    display: flex;
  }
`;

export const OccurenceInputWrapper = styled.input`
  width: 72px;
  outline: none;
  border: 1px solid #e6e6e6;
  padding: 0.5rem;
  margin-right: 4px;
`;

export const OptionContainer = styled.div`
  width: 115px;
  display: inline-block;
  cursor: pointer;
  pointer-events: ${(props: OptionContainerProps) =>
    props.action === 'edit' &&
    (props.typeAppointmentParticipantId === 1 ||
      props.typeAppointmentParticipantId === 0)
      ? 'none'
      : 'all'};

  &.btn__active {
    text-align: center;
    justify-content: center;
    height: 33px;
    align-items: center;
    position: relative;
    display: flex;
    //background: rgba(83, 200, 46, 0.15);
    background: ${globalStyleVariables.newBrandingPrimary20010};
    color: ${globalStyleVariables.newBrandingPrimary200};
    border-radius: 50px;
  }

  &.btn__hover {
    text-align: center;
    justify-content: center;
    height: 33px;
    align-items: center;
    position: relative;
    display: flex;

    &:hover {
      background: #e7f0f5;
      border-radius: 50px;
    }
  }

  opacity: ${(props: OptionContainerProps) => {
    if (
      props.action === 'edit' &&
      (props.typeAppointmentParticipantId === 1 ||
        props.typeAppointmentParticipantId === 0)
    ) {
      return 0.7;
    } else {
      return 1;
    }
  }};

  label {
    cursor: pointer;
  }

  .scheduleRecurr {
    padding-right: 8px;
    padding-bottom: 6px;
    display: flex;
    height: auto;

    svg > circle {
      fill: #ffffff;
    }

    svg > path {
      stroke: ${(props: OptionContainerProps) =>
        props.disableDateTimeSelect === 'true' ? '#666666' : 'rgb(6, 86, 128)'};
    }
  }

  .scheduleRecurrActive {
    padding-right: 8px;
    padding-bottom: 6px;
    display: flex;
    height: auto;

    svg > circle {
      fill: #ffffff;
    }

    svg > path {
      stroke: ${globalStyleVariables.newBrandingPrimary200} !important;
    }
  }

  .scheduleMark {
    padding-right: 8px;
    display: flex;
    height: auto;

    svg path {
      stroke: ${(props: OptionContainerProps) =>
        props.disableDateTimeSelect === 'true' ? '#666666' : 'rgb(6, 86, 128)'};
    }
  }

  .scheduleMarkActive {
    padding-right: 8px;
    display: flex;
    height: auto;
    color: ${globalStyleVariables.newBrandingPrimary200};

    path {
      stroke: ${globalStyleVariables.newBrandingPrimary200} !important;
    }
  }

  .recurrActive {
    display: flex;
    align-items: center;
    background: rgb(83, 200, 46, 0.1);
    border-radius: 16px;
    padding-left: 0.5rem;

    .scheduleRecurr {
      svg > circle {
      }

      svg > path {
        stroke: ${globalStyleVariables.newBrandingPrimary200} !important;
      }
    }
  }
`;

interface PatientContainerProps {
  action?: string;
  typeAppointmentParticipantId?: number;
}

export const PatientContainer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;
  pointer-events: ${(props: PatientContainerProps) =>
    props.action === 'edit' && props.typeAppointmentParticipantId === 1
      ? 'none'
      : 'all'};
  opacity: ${(props: PatientContainerProps) => {
    if (props.action === 'edit' && props.typeAppointmentParticipantId === 1) {
      return 0.7;
    } else {
      return 1;
    }
  }};

  .ant-select-selection--single {
    background: #f7f7f7;
    height: 38px;
    border: none;
    border-radius: 0;
    padding: 2px;
  }

  .ant-select-search__field {
    z-index: 0 !important;
  }

  .medicat-popover {
    left: 340px;
  }
`;

export const PatientSelectContainer = styled.div`
  display: inline-block;
  width: 320px;
  cursor: pointer;

  .waiting-subject-autocomplete {
    width: 100%;
    text-align: left;
  }
`;

export const AddButtonContainer = styled.div`
  position: absolute;
  top: 25px;
  right: -8px;
  z-index: 10;
`;
export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const PatientCategoryContainer = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  display: inline-block;
  width: 35%;
  cursor: pointer;

  .ant-select-arrow {
    top: 35%;
    width: unset;

    .arrow-down {
      display: none;
    }
  }
`;

export const MaxParticipantsContainer = styled.div`
  display: ${(props: { show: boolean }) =>
    props.show ? 'inline-block' : 'none'};
  width: 21%;
  margin-left: 24px;

  .maxParticipants {
    height: 40px;
    line-height: 40px;
    background: #f7f7f7;
    color: #333333;
    padding: 0 12px;
    border: none;
    width: 100%;
  }
`;

export const ReasonSelectContainer = styled.div`
  display: inline-block;
  width: 100%;

  .error-border {
    border: 1px solid #f9b40b;
  }

  .disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

export const ProviderBlock = styled.div`
  display: inline-block;
  width: 100%;
`;
export const ProviderRadio = styled.div`
  padding: 16px 0;
  display: flex;
  gap: 16px;
`;

export const DateAndTimeBlock = styled.div`
  display: flex;
  padding-bottom: 20px;
  width: 100%;

  .box-main {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      margin-left: auto;
      padding-right: 70px;
    }
  }
`;

export const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 24px 24px 24px;

  .Note {
    height: 40px;
    line-height: 40px;
    background: #f7f7f7;
    padding: 0 12px;
  }
`;

interface OptionSelectorProps {
  disableDateTimeSelect: boolean;
  recurrActive?: boolean;
}

export const OptionSelector = styled.div`
  font-weight: bold;
  font-size: 14px;
  font-family: Lato;
  font-style: normal;
  color: ${(props: OptionSelectorProps) => {
    if (props.recurrActive) {
      return globalStyleVariables.newBrandingPrimary200;
    } else {
      if (!props.disableDateTimeSelect) {
        return globalStyleVariables.newBrandingPrimary200;
      } else {
        return '#666666';
      }
    }
  }};
`;

interface IPatientDetailContainer {
  viewMode?: boolean;
}

export const PatientDetailContainer = styled.div<IPatientDetailContainer>`
  padding: ${({ viewMode }) => (viewMode ? '0' : '0 24px')};
`;

export const ContainerWithPaddings = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`;

export const PatientInfoContainer = styled.div`
  padding: 24px 0 0 0;
  display: block;

  font-family: Encode Sans Expanded;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;

  color: #333333;
`;

export const ApptsKeptContainer = styled.div`
  margin-left: 8px;
  width: 112px;
  border-right: solid #e6e6e6 0.5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const ApptsCancelledContainer = styled.div`
  margin-left: 8px;
  width: 126.5px;
  border-right: solid #e6e6e6 0.5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const PreferedPhoneContainer = styled.div`
  margin-left: 8px;
  width: 169.5px;
  border-right: solid #e6e6e6 0.5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const ColumnBoxItem = styled.div`
  margin-top: 8px;
  padding-bottom: 6px;
  display: flex;
  gap: 16px;
  justify-content: space-between;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${globalStyleVariables.borderColor};
  }

  label {
    flex-grow: 1;
    text-wrap: nowrap;
  }
`;

export const PatientsGroupContainer = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const RecurrContainer = styled.div`
  background: rgb(247, 247, 247);
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  padding-left: 2rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 64px;
  padding: 0 24px;
`;
export const StartDate: any = styled.div`
  .rc-calendar-body {
    height: 170px;
  }

  .rc-calendar-date {
    width: 19px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rc-calendar-year-panel-decade-select,
  .rc-calendar-month-panel-year-select {
    width: 120px !important;
  }

  .rc-calendar-decade-panel-decade {
    font-size: 8px;
  }
`;
export const BtnContainer = styled.div`
  cursor: pointer !important;
  font-family: Lato;
  font-weight: bold;
  font-size: 14px;
  color: ${globalStyleVariables.newBrandingPrimary200};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  /* padding: 0 24px; */
  padding: 0 8px;
  text-align: center;
  font-weight: 500;
  line-height: 14px;
  background-color: #f7f7f7;
  height: 40px;
  ${(props: { disabled?: boolean }) =>
    props.disabled &&
    `
    pointer-events: none;
    filter: grayscale(100%);
    opacity: 0.6;
  `}
`;

export const Container = styled.div`
  position: relative;
  min-height: 500px;
  height: 100%;
  background-color: white;
`;

export const GroupPatientsInfo = styled.div``;

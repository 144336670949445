import React, { FC } from 'react';

import Collapse from 'src/Framework/Controls/CollapseA11y';
import { getClinicById } from 'src/Framework/Controls/Selectors/ClinicSelector';

import { IApptResponse } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';

import Main from './Main';
import { ContainerBlock } from '../styled';

interface IProps {
  data: IApptResponse;
}

const { Panel } = Collapse;

const defaultCalendarListLabel = 'LISTS';
const defaultWaitingListName = 'List';

const Component: FC<IProps> = ({ data }: IProps) => {
  const clinic = getClinicById(data.scheduleEvent.clinicId);
  return (
    <Panel
      key={'clinic-list'}
      header={`${clinic?.calendarListLabel || defaultCalendarListLabel} - ${
        data.scheduleEvent.waitingList?.name || defaultWaitingListName
      } INFO`}
    >
      <ContainerBlock>
        <Main data={data} />
      </ContainerBlock>
    </Panel>
  );
};

export default Component;

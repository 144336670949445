import * as React from 'react';
import moment from 'moment';
import { Provider } from 'react-redux';
import { Tooltip } from 'react-tippy';
import { Row, Col } from 'antd';
import _ from 'lodash';

import { ConflictsSvg } from 'src/Framework/Common/Svg/ConflictsSvg';
import { EyeIconSnapShotSvg } from 'src/Framework/Common/Svg/EyeIconSnapShotSvg';
import DragAndDrop from 'src/Framework/Layout/DragAndDrop';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import Button from 'src/Framework/Controls/Button';
import Icon from 'src/Framework/Controls/Icon';
import Checkbox from 'src/Framework/Controls/Checkbox';
import {
  getProviderByCode,
  providerDisplayName
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { store } from 'src/index';
import test_ids from 'src/tests-script/pages/Schedule/Recurring';

import {
  ConflictsBody,
  ConflictsContainer,
  ConflictsHeader,
  ConflictMessage,
  ListItemContainer,
  DateSpan,
  ButtonBlock,
  OverrideButtonContainer,
  ClosedLabel,
  SetAllContainer
} from 'src/Activities/Schedule/Common/Editors/ScheduleEditor_Styles';
import { CalendarSvg } from 'src/Activities/Counseling/Notes/EditNote/Svg/CalendarSvg';
import { ClockSvg } from 'src/Activities/Counseling/Notes/EditNote/Svg/ClockSvg';
import {
  conflictsCheckForRecurring,
  conflictsCheckForEvents
} from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import CalendarSnap from 'src/Activities/Schedule/Common/Editors/CalendarSnapShot/CalendarSnap';
import { getTimeObject } from 'src/Activities/Schedule/Utils/Utils';
import ConflictsList from 'src/Activities/Schedule/ConflictsBar/ConflictsList';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { IOccurrence } from 'src/Activities/Schedule/Common/Editors/RecurrenceDisplay';

export interface IConflict {
  allowOverride: boolean;
  allowSkip: boolean;
  appointmentBlockId: number;
  appointmentId: number;
  clinicClosed: boolean;
  clinicCode: string;
  clinicName: string;
  conflicts: any[];
  endTime: string;
  eventConflictHandled: boolean;
  isBlock: boolean;
  isReservation: boolean;
  permitOverrideAppointment: boolean;
  permitOverrideBlock: boolean;
  permitOverrideReservation: boolean;
  providerCode: string[];
  reason: number;
  scheduleEventDateTime: string;
  scheduleEventKey: string;
  scheduleEventTypeId: number;
  eventId: number;
}

export interface IConflictState extends IConflict {
  resolved?: boolean;
  skipped?: boolean;
  dialogResponse?: string;
}

interface IState {
  // This field need for fast solving issue with infinity requests on error from backend side
  responseError: boolean;
  error: any;
  hasConflicts: boolean;
  conflicts: IConflictState[];
  conflictsResponses: IConflict[] | null;
  resolvedConflicts: IConflictState[];
  confirmConflicts: boolean;
  message: string;
  isLoading: boolean;
  isBlock: boolean;
  //   showConflicts: boolean;
  seriesAppointments: IOccurrence[];
}

interface IProps {
  payload: any;
  type: string;
  confirmConflicts: boolean;
  formValidation: any;
  showConflicts: boolean;
  setConfirmConflicts: any;
  setShowConflicts: any;
  clinicOptions?: any;
  providerOptions?: any;
  regularMeetingTime?: any;
  providers?: any;
}

// const mergeSameDays = (conflicts: IConflict[]) => {
//   let newConflicts: IConflictState[] = [];
//   let updatedConflicts = _.cloneDeep(conflicts);
//   conflicts.forEach((item: any, index: number) => {
//     if (updatedConflicts.length > 0) {
//       let spliced = updatedConflicts.splice(0, 1)[0];
//       // @ts-ignore
//       spliced.providerCode = [spliced.providerCode];
//       updatedConflicts.map((conflict, indexL) => {
//         if (
//           moment(conflict.scheduleEventDateTime).format('MM-DD-YYYY') ===
//           moment(spliced.scheduleEventDateTime).format('MM-DD-YYYY')
//         ) {
//           // @ts-ignore
//           spliced.providerCode.push(conflict.providerCode);
//           updatedConflicts.splice(indexL, 1);
//         }
//       });
//       newConflicts.push(spliced);
//     }
//   });
//   return newConflicts;
// };
class ConflictsEditor extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      responseError: false,
      hasConflicts: false,
      message: '',
      conflictsResponses: null,
      confirmConflicts: false,
      conflicts: [],
      resolvedConflicts: [],
      isLoading: false,
      seriesAppointments: [],
      error: {
        provider: false
      },
      isBlock: this.props.type === 'block' || this.props.type === 'reservation'
    };
  }

  noErrorCheck(obj: any) {
    let check = Object.keys(obj)
      .filter((key) => key !== 'clinicClosed')
      .every((item: any) => obj[item] === false);
    return check;
  }

  componentDidUpdate() {
    if (
      this.props.showConflicts &&
      !this.props.confirmConflicts &&
      !this.state.isLoading &&
      !this.state.responseError
    ) {
      if (this.noErrorCheck(this.props.formValidation()))
        this.checkForConflicts();
    }
  }

  popOverContent(conflict: any) {
    let firstEvent = conflict;
    let isAppt = !firstEvent.isBlock && !firstEvent.isReservation;
    let searchLine = {
      Event: {
        startTime: firstEvent.scheduleEventDateTime
          ? getTimeObject(moment(firstEvent.scheduleEventDateTime).format())
          : null,
        endTime: firstEvent.endTime
          ? getTimeObject(moment(firstEvent.endTime).format())
          : null,
        providerCodes: firstEvent.providerCode,
        clinicCode: firstEvent.clinicCode
      }
    };
    if (!isAppt) {
      searchLine.Event.endTime =
        moment(firstEvent.conflicts[0].scheduleEventDateTime).format(
          'YYYY-MM-DD'
        ) +
        'T' +
        moment(firstEvent.series.endTime).format('HH:mm:ss');
      searchLine.Event.startTime =
        moment(firstEvent.scheduleEventDateTime).format('YYYY-MM-DD') +
        'T' +
        moment(firstEvent.series.startTime).format('HH:mm:ss');
    }
    return (
      <DragAndDrop>
        <Provider store={store}>
          <CalendarSnap
            clinicOptions={this.props.clinicOptions}
            providers={this.props.payload.providerCodes.map((item: any) => ({
              label: providerDisplayName(item.id),
              page: item.page,
              value: item.code
            }))}
            searchLine={searchLine}
          />
        </Provider>
      </DragAndDrop>
    );
  }

  getConflictMessage(conflicts: any[], resolvedConflicts: any[]) {
    let conflictCount: number = conflicts.length;
    let resolvedCount: number = 0;
    if (resolvedConflicts) {
      resolvedCount = resolvedConflicts.length;
    }
    if (conflictCount > resolvedCount) {
      conflictCount = conflictCount - resolvedCount;
    }
    let message = `${conflictCount} CONFLICTS , PLEASE RESOLVE TO CONTINUE`;
    return message;
  }

  checkForConflicts = async () => {
    let payload = _.cloneDeep(this.props.payload);
    let { hasConflicts, conflictsResponses, message, resolvedConflicts } =
      this.state;
    let conflicts: IConflictState[] = [];
    this.setState({
      isLoading: true
    });
    try {
      let response;
      if (payload.scheduleEvent.scheduleEventTypeId === 7) {
        payload.appointmentSeries = _.cloneDeep(payload.scheduleEvent.series);
        response = await conflictsCheckForEvents(payload);
      } else {
        if (payload.scheduleEvent.series.occurrences) {
          payload.scheduleEvent.series.endTime = '';
          payload.scheduleEvent.series.endDate = '';
        }
        response = await conflictsCheckForRecurring(payload);
      }
      if (response?.data.success) {
        if (response.data.result.hasConflicts) {
          const temporaryConflictResponse: IConflict[] =
            response.data.result.conflicts;
          const cleanedConflictResponse = _.uniqBy(
            temporaryConflictResponse,
            'scheduleEventKey'
          );
          cleanedConflictResponse.forEach((resultItem) => {
            conflicts.push(resultItem);
          });
          hasConflicts = true;
          conflictsResponses = cleanedConflictResponse;
          message = this.getConflictMessage(
            response.data.result.conflicts,
            resolvedConflicts
          );
          this.props.setShowConflicts(false);
        } else {
          hasConflicts = false;
          if (response.data.result.conflicts) {
            const temporaryConflictResponse: IConflict[] =
              response.data.result.conflicts;
            const cleanedConflictResponse = _.uniqBy(
              temporaryConflictResponse,
              'scheduleEventKey'
            );
            cleanedConflictResponse.forEach((resultItem) => {
              conflicts.push({ ...resultItem, resolved: true });
            });
            conflictsResponses = cleanedConflictResponse;
          }
          message = 'NO CONFLICTS FOUND';
          this.props.setConfirmConflicts(true);
        }
        this.setState({
          hasConflicts,
          conflicts,
          conflictsResponses,
          message,
          seriesAppointments: response.data.result?.seriesAppointments || [],
          responseError: false
        });
        this.props.payload.scheduleEvent.series.seriesAppointments =
          response.data.result?.seriesAppointments || [];
      } else {
        this.setState({ responseError: true });
        console.log('createScheduleEvent response success false');
      }
    } catch (e) {
      this.setState({ responseError: true });
    } finally {
      this.setState({
        isLoading: false
      });
    }
  };

  onClickOverrideAll = () => {
    let {
      resolvedConflicts,
      hasConflicts,
      confirmConflicts,
      conflictsResponses,
      message
    } = this.state;
    const updatedList = this.state.conflicts.map((value) => ({
      ...value,
      resolved: true
    }));
    resolvedConflicts = updatedList;
    if (updatedList.length - resolvedConflicts.length === 0) {
      hasConflicts = false;
      confirmConflicts = true;
      message = 'ALL CONFLICTS RESOLVED';
    }

    this.setState({
      conflicts: [],
      resolvedConflicts: [],
      hasConflicts: hasConflicts,
      confirmConflicts: confirmConflicts,
      message: message
    });
    const data = (conflictsResponses || []).map((v) => ({
      ...v,
      resolved: true
    }));
    this.props.setConfirmConflicts(confirmConflicts, data);
  };

  onCheckState = (
    conflicts: IConflictState[],
    resolvedConflicts: IConflictState[]
  ) => {
    let hasConflicts = false;
    let confirmConflicts = false;
    let message = '';
    if (conflicts.length - resolvedConflicts.length === 0) {
      hasConflicts = false;
      confirmConflicts = true;
      message = 'ALL CONFLICTS RESOLVED';
      this.props.setConfirmConflicts(confirmConflicts, conflicts);
    } else {
      hasConflicts = true;
      confirmConflicts = false;
      message = `${
        conflicts.length - resolvedConflicts.length
      } CONFLICTS , PLEASE RESOLVE TO CONTINUE`;
      this.props.setConfirmConflicts(confirmConflicts);
    }
    this.setState({
      conflicts,
      resolvedConflicts,
      hasConflicts,
      message
    });
  };

  onClickSkip = (conflict: IConflictState, index: number) => {
    const { resolvedConflicts } = this.state;
    const conflicts = _.cloneDeep(this.state.conflicts);
    const indexL = resolvedConflicts.findIndex(
      (event) => event.scheduleEventKey === conflict.scheduleEventKey
    );
    if (!conflict.skipped) {
      conflicts[index].skipped = true;
      conflicts[index].resolved = false;
      if (indexL === -1) {
        resolvedConflicts.push(conflict);
      }
    } else {
      conflicts[index].skipped = false;
      if (indexL !== -1) {
        resolvedConflicts.splice(indexL, 1);
      }
    }
    this.onCheckState(conflicts, resolvedConflicts);
  };

  onClickOverride = (conflict: IConflictState, index: number) => {
    let { resolvedConflicts } = this.state;
    const conflicts = _.cloneDeep(this.state.conflicts);
    const indexL = resolvedConflicts.findIndex(
      (event) => event.scheduleEventKey === conflict.scheduleEventKey
    );
    if (!conflict.resolved) {
      conflicts[index].skipped = false;
      conflicts[index].resolved = true;
      if (indexL === -1) {
        resolvedConflicts.push(conflict);
      }
    } else {
      conflicts[index].resolved = false;
      if (indexL !== -1) {
        resolvedConflicts.splice(indexL, 1);
      }
    }
    this.onCheckState(conflicts, resolvedConflicts);
  };

  onChangeCheckboxOverrideAll = (value: boolean) => {
    let resolvedConflicts = _.cloneDeep(this.state.resolvedConflicts);
    const conflicts = _.cloneDeep(this.state.conflicts);
    if (!value) {
      resolvedConflicts = [];
      conflicts.forEach((item) => {
        delete item.resolved;
        delete item.dialogResponse;
      });
      this.setState({
        conflicts,
        resolvedConflicts
      });
    } else {
      conflicts.forEach((v) => {
        if (value === true) {
          v.skipped = false;
        }
        v.resolved = value;
        const i = resolvedConflicts.findIndex(
          (event) => event.scheduleEventKey === v.scheduleEventKey
        );
        if (i === -1) {
          resolvedConflicts.push(v);
        }
      });
    }
    this.onCheckState(conflicts, resolvedConflicts);
  };
  onChangeCheckboxSkipAll = (value: boolean) => {
    let resolvedConflicts = _.cloneDeep(this.state.resolvedConflicts);
    const conflicts = _.cloneDeep(this.state.conflicts);
    if (!value) {
      resolvedConflicts = [];
      conflicts.forEach((item) => {
        delete item.skipped;
        delete item.dialogResponse;
      });
      this.setState({
        conflicts,
        resolvedConflicts
      });
    } else {
      conflicts.forEach((v) => {
        v.skipped = value;
        if (value === true) {
          v.resolved = false;
        }
        const i = resolvedConflicts.findIndex(
          (event) => event.scheduleEventKey === v.scheduleEventKey
        );
        if (i === -1) {
          resolvedConflicts.push(v);
        }
      });
    }
    this.onCheckState(conflicts, resolvedConflicts);
  };

  blockConflictCard(conflict: any) {
    var title: string = '';
    if (conflict.conflicts && conflict.conflicts[0]) {
      const conflictCount: number = conflict.conflicts.length;
      const apptDate: string =
        conflict.conflicts[0].scheduleEventDateTime &&
        moment(conflict.conflicts[0].scheduleEventDateTime).format(
          'MM/DD/YYYY'
        );
      const apptEndDate: string =
        conflict.conflicts[conflictCount - 1].scheduleEventDateTime &&
        moment(
          conflict.conflicts[conflictCount - 1].scheduleEventDateTime
        ).format('MM/DD/YYYY');
      title = `${conflictCount} conflicts found starting from ${apptDate} to ${apptEndDate}`;
    }
    return (
      <ListItemContainer key={`${conflict.scheduleEventDateTime}`}>
        <div style={{ display: 'flex' }}>
          <DateSpan style={{ marginRight: '2rem' }}>
            <span style={{ marginRight: '1rem' }}>
              <CalendarSvg />
            </span>
            {title}
          </DateSpan>
        </div>
        <ButtonBlock style={{ alignItems: 'center' }}>
          <Tooltip
            title="calendar-snap"
            interactive
            trigger={'click'}
            position={'left'}
            delay={500}
            html={
              <div
                onMouseDown={(e: any) => e.preventDefault()}
                style={{
                  width: 576,
                  height:
                    conflict.conflicts.length < 6
                      ? conflict.conflicts.length * 48
                      : 304
                }}
              >
                {
                  <ConflictsList
                    data={conflict.conflicts.map((item: any) => {
                      const provider = getProviderByCode(item.providerCode);
                      return {
                        startDate: item.scheduleEventDateTime,
                        endDate: item.endTime,
                        providerName: provider
                          ? provider.firstName +
                            (provider.lastName ? ' ' + provider.lastName : '')
                          : ''
                      };
                    })}
                  />
                }
              </div>
            }
            arrow
            style={{
              display: 'flex',
              cursor: 'pointer',
              borderRadius: '50%',
              marginRight: '1rem',
              minWidth: '30px',
              maxWidth: '800px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            popperOptions={{
              modifiers: {
                preventOverflow: {
                  enabled: false
                },
                flip: {
                  enabled: false
                }
              }
            }}
            unmountHTMLWhenHide={true}
            theme="light"
          >
            <span
              style={{
                paddingRight: '1rem',
                cursor: 'pointer'
              }}
            >
              <EyeIconSnapShotSvg />
            </span>
          </Tooltip>
        </ButtonBlock>
      </ListItemContainer>
    );
  }

  appointmentConflictCard(conflictItem: IConflictState, index: any) {
    const conflict = _.cloneDeep(conflictItem);
    conflict.scheduleEventDateTime = conflict.scheduleEventDateTime.replace(
      'Z',
      ''
    );
    conflict.endTime = conflict.endTime.replace('Z', '');
    let dateOfConflict =
      conflict.scheduleEventDateTime &&
      moment(conflict.scheduleEventDateTime).format('MM/DD/YYYY');
    let timeOfConflict =
      conflict.scheduleEventDateTime &&
      moment(conflict.scheduleEventDateTime).format('hh:mm a');
    return (
      <ListItemContainer key={`${conflict.scheduleEventDateTime}${index}`}>
        <div style={{ display: 'flex' }}>
          <DateSpan style={{ marginRight: '2rem' }}>
            <span style={{ marginRight: '1rem' }}>
              <CalendarSvg />
            </span>
            {dateOfConflict}
          </DateSpan>
          <DateSpan>
            <span style={{ marginRight: '1rem' }}>
              <ClockSvg />
            </span>
            {timeOfConflict}
          </DateSpan>
          {conflict.clinicClosed && <ClosedLabel>Clinic closed</ClosedLabel>}
        </div>
        <ButtonBlock style={{ alignItems: 'center' }}>
          {!conflict.clinicClosed && (
            <Tooltip
              title="calendar-snap"
              interactive
              trigger={'click'}
              position={'left'}
              delay={500}
              html={
                <div
                  onMouseDown={(e: any) => e.preventDefault()}
                  style={
                    conflict.providerCode
                      ? {
                          width: `${
                            (conflict.providerCode.length - 1) * 200 + 350
                          }px`,
                          height: '300px'
                        }
                      : { width: '350px', height: '350px' }
                  }
                >
                  {this.popOverContent(conflict)}
                </div>
              }
              arrow
              style={{
                display: 'flex',
                cursor: 'pointer',
                borderRadius: '50%',
                marginRight: '1rem',
                minWidth: '30px',
                maxWidth: '800px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              popperOptions={{
                modifiers: {
                  preventOverflow: {
                    enabled: false
                  },
                  flip: {
                    enabled: false
                  }
                }
              }}
              unmountHTMLWhenHide={true}
              theme="light"
            >
              <span
                style={{
                  paddingRight: '1rem',
                  cursor: 'pointer'
                }}
              >
                <EyeIconSnapShotSvg />
              </span>
            </Tooltip>
          )}
          <Row align="middle" gutter={8}>
            {conflict.allowOverride && (
              <Col>
                <Button
                  id={test_ids.resolveItem(conflict.scheduleEventKey)}
                  disableClickLoader={true}
                  onClick={() => this.onClickOverride(conflict, index)}
                  colorStyles={{
                    blueBorder: !conflict.resolved,
                    successBorder:
                      conflict.resolved &&
                      (!conflict.dialogResponse ||
                        (!!conflict.dialogResponse &&
                          conflict.dialogResponse === 'override'))
                  }}
                >
                  {conflict.resolved &&
                  (!conflict.dialogResponse ||
                    (!!conflict.dialogResponse &&
                      conflict.dialogResponse === 'override'))
                    ? 'Overridden'
                    : 'Override'}
                </Button>
              </Col>
            )}
            {conflict.allowSkip && (
              <Col>
                <Button
                  id={test_ids.skipItem(conflict.scheduleEventKey)}
                  disableClickLoader={true}
                  onClick={() => this.onClickSkip(conflict, index)}
                  colorStyles={{
                    blueBorder: !conflict.skipped && !conflict.dialogResponse,
                    successBorder:
                      conflict.skipped ||
                      (conflict.resolved &&
                        !!conflict.dialogResponse &&
                        conflict.dialogResponse === 'skip')
                  }}
                >
                  {conflict.skipped ||
                  (conflict.resolved &&
                    !!conflict.dialogResponse &&
                    conflict.dialogResponse === 'skip')
                    ? 'Skipped'
                    : 'Skip'}
                </Button>
              </Col>
            )}
          </Row>
        </ButtonBlock>
      </ListItemContainer>
    );
  }

  render() {
    const { message, isLoading, conflicts } = this.state;
    const filteredConflicts = this.state.isBlock
      ? conflicts.reduce((acc: any[], item) => {
          return acc.concat(item.conflicts);
        }, []) || []
      : (conflicts || []).filter((v) => v.allowOverride || v.allowSkip);
    const canBeOverrided = filteredConflicts.filter((v) => v.allowOverride);
    const canBeSkipped = filteredConflicts.filter((v) => v.allowSkip);

    const overrried = canBeOverrided.filter(
      (v) =>
        v.resolved &&
        (!v.dialogResponse ||
          (v.dialogResponse && v.dialogResponse === 'override'))
    );
    const overridedAll = canBeOverrided.length - overrried.length === 0;

    const skipped = canBeSkipped.filter(
      (v) => v.skipped || (v.dialogResponse && v.dialogResponse === 'skip')
    );
    const skippedAll = canBeSkipped.length - skipped.length === 0;
    const notResolved =
      filteredConflicts.length - (overrried.length + skipped.length);
    const hasConflicts = notResolved !== 0;

    const eventsList: IOccurrence[] = this.state.seriesAppointments.map(
      (item) => ({
        ...item,
        date: item?.scheduleEventDateTime?.slice(0, 11) + '00:00:00',
        startTime: item?.scheduleEventDateTime
      })
    );
    return (
      <ConflictsContainer>
        {isLoading ? (
          <AbsoluteLoader />
        ) : (
          message.length > 0 && (
            <>
              <ConflictsHeader hasConflicts={hasConflicts ? 'true' : 'false'}>
                {hasConflicts ? (
                  <ConflictsSvg />
                ) : (
                  <Icon
                    color={globalStyleVariables.newBrandingSuccessText}
                    name="checkCircle"
                  />
                )}
                <ConflictMessage>
                  {hasConflicts
                    ? `${notResolved} CONFLICTS , PLEASE RESOLVE TO CONTINUE`
                    : 'ALL CONFLICTS RESOLVED'}
                  {'. '}
                  {skipped &&
                    skipped.length > 0 &&
                    `${eventsList.length - skipped.length}/`}
                  {eventsList &&
                    eventsList.length > 0 &&
                    `${eventsList.length} OCCURENCES`}
                </ConflictMessage>
                {this.props.type !== 'appointment' &&
                this.props.type !== 'event' &&
                hasConflicts ? (
                  <ButtonBlock style={{ marginLeft: '2rem' }}>
                    <OverrideButtonContainer
                      style={{ height: '30px' }}
                      onClick={() => this.onClickOverrideAll()}
                    >
                      <button
                        className="custom-button"
                        id={test_ids.overrideAllButton}
                        style={
                          !hasConflicts
                            ? {
                                border: `1px solid ${globalStyleVariables.newBrandingSuccess}`,
                                color: globalStyleVariables.newBrandingSuccess
                              }
                            : {
                                border: `1px solid ${globalStyleVariables.red}`,
                                color: globalStyleVariables.red,
                                backgroundColor: '#FFEAEA'
                              }
                        }
                      >
                        {!hasConflicts
                          ? 'All conflicts Overrided'
                          : 'Override All'}
                      </button>
                    </OverrideButtonContainer>
                  </ButtonBlock>
                ) : null}
              </ConflictsHeader>
              {(this.props.type === 'appointment' ||
                this.props.type === 'event') && (
                <SetAllContainer>
                  <Row align="middle" gutter={16} justify="end">
                    <Col>
                      <Checkbox
                        id={test_ids.overrideAll}
                        checked={overridedAll}
                        onClick={() => {
                          this.onChangeCheckboxOverrideAll(!overridedAll);
                        }}
                      >
                        Override all
                      </Checkbox>
                    </Col>
                    <Col>
                      <Checkbox
                        id={test_ids.skipAll}
                        checked={skippedAll}
                        onClick={() => {
                          this.onChangeCheckboxSkipAll(!skippedAll);
                        }}
                      >
                        Skip all
                      </Checkbox>
                    </Col>
                  </Row>
                </SetAllContainer>
              )}

              {hasConflicts && (
                <ConflictsBody>
                  {this.state.isBlock
                    ? this.blockConflictCard({
                        conflicts: conflicts?.reduce((acc: any[], item) => {
                          return acc.concat(item.conflicts);
                        }, [])
                      })
                    : conflicts?.map((conflict, index: number) =>
                        this.appointmentConflictCard(conflict, index)
                      )}
                </ConflictsBody>
              )}
            </>
          )
        )}
      </ConflictsContainer>
    );
  }
}

export default ConflictsEditor;

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const TableContainer = styled.div`
  position: relative;
  display: flex;
  width: 750px;
  border-right: 1px solid ${globalStyleVariables.borderColor};
`;

export const TabulatorWrapper = styled.div`
  flex-grow: 1;

  .left-table {
    margin-bottom: 0 !important;
    width: 750px;
    border-right: 1px solid #e6e6e6;

    .tabulator-header {
      background-color: #f7f7f7;
      height: 40px;
      border-top: 0;
    }

    .tabulator-col {
      background-color: #f7f7f7 !important;
    }
  }

  .tabulator-selected {
    background-color: rgba(14, 109, 159, 0.3) !important;
    transition: background-color 500ms linear !important;
  }

  .tabulator-row {
    border-bottom: none !important;
    display: flex;
    height: 35px;
    align-items: center;

    .tabulator-cell {
      display: flex;
      align-items: center;
      padding: 0 0.75rem;
      height: 100% !important;
    }
  }

  .tabulator-col-title {
    font-size: 13px !important;
    overflow: inherit !important;
    color: #000000;
  }

  .tabulator-tableHolder {
    overflow-x: hidden !important;
  }

  .tabulator-header {
    border-bottom: 1px solid #dee2e6 !important;
  }

  .ind-table {
    margin-bottom: 0 !important;
    box-shadow: 0 1px 10px -3px;
    width: 750px;
  }

  .right-table {
    margin-bottom: 0 !important;
    width: 300px;

    .tabulator-row {
      font-size: 12px !important;
    }
  }

  .tabulator-header-filter {
    input {
      border: 1px solid #e6e6e6;
    }
  }

  .disable-add-cell {
    color: ${globalStyleVariables.newBrandingPrimary200} !important;
    height: 28px;
    width: 28px;
    cursor: ${(props: { disable: any; visibility: any }) =>
      props.disable === 'false' ? 'pointer' : 'not-allowed'};

    svg {
      width: 32px;
      height: 32px;

      > path {
        stroke-width: 2px;
        stroke: ${(props: { disable: any; visibility: any }) =>
          props.disable === 'false'
            ? globalStyleVariables.newBrandingPrimary200
            : globalStyleVariables.brandGray};
      }
    }
  }

  .tabulator-placeholder {
    span {
      display: inline-block;
      margin: 0 auto;
      padding: 10px;
      color: ${globalStyleVariables.brandGray} !important;
      font-weight: 400 !important;
      font-size: 19px !important;
    }
  }

  .remove-cell {
    background: #f7f7f7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${globalStyleVariables.brandGray};
    height: 30px !important;
    width: 30px;

    svg {
      width: 30px;
      height: 30px;

      > circle {
        fill: #ffffff;
      }

      > path {
        stroke: red;
      }
    }
  }
`;

interface IContainer {
  width?: number;
}

export const SingleTabulatorWrapper = styled.div<IContainer>`
  margin-bottom: 0 !important;
  width: ${({ width }) => (width ? width + 'px' : '748px')};

  .tabulator-header {
    background-color: #f7f7f7 !important;
    height: 40px !important;
    border-bottom: 1px solid #dee2e6 !important;
  }

  .tabulator-col {
    background-color: #f7f7f7 !important;
  }

  .tabulator-selected {
    background-color: rgba(14, 109, 159, 0.3) !important;
    transition: background-color 500ms linear !important;
  }

  .tabulator-row {
    border-bottom: none !important;
  }

  .tabulator-col-title {
    font-size: 13px !important;
    overflow: inherit !important;
    color: #000000;
  }

  .tabulator-tableHolder {
    overflow-x: hidden !important;
  }

  .tabulator-header-filter {
    input {
      border: 1px solid #e6e6e6;
    }
  }

  .tabulator-placeholder {
    span {
      display: inline-block;
      margin: 0 auto;
      padding: 10px;
      color: ${globalStyleVariables.brandGray} !important;
      font-weight: 400 !important;
      font-size: 19px !important;
    }
  }
`;

export const ReceiverOptionsContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 300px;
`;

export const EmptyPlacHolderSpan = styled.div`
  color: ${globalStyleVariables.brandGray} !important;
  font-weight: 400 !important;
  font-size: 19px !important;
`;

export const HeaderMultiselect = styled.div`
  height: 39px;
  background: #f7f7f7;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 1rem;

    &__left {
      span {
        font-size: 13px;
        font-family: Lato;
        font-weight: bolder;
        color: #000000;

        &.selected {
          color: ${globalStyleVariables.newBrandingPrimary200};
        }
      }
    }

    &__right {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
`;
export const Container = styled.div`
  display: flex;
  background: #ffffff;
  align-items: stretch;
  height: 100%;
  min-height: 400px;
`;

import React, { FC, useEffect, useState } from 'react';
import { List, Row } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import Container from '../components/Container';
import Header from '../components/Header';

import { ListContainer, ListInnerContainer } from '../../styled';

import { IStore } from 'src/store';

import Item from './Item';
import * as dashboardActions from '../../store/Actions';

import { LoadingContainer } from '../../../../Subject/Patients/PatientInfo_Styles';
import { tabsData } from '../Appointments/utils';
import Tabs from '../components/Tabs';

interface IOwnProps {}

interface IProps extends IOwnProps {
  dashboard: IStore['dashboard'];
}

const Component: FC<IProps> = ({ dashboard }: IProps) => {
  const [selectedDay, setSelectedDay] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const day = moment().add(selectedDay, 'days').format('YYYY-MM-DD');
  const sortedList = _.orderBy(
    dashboard.todos.filter(
      (todo) => moment(todo.followUpDate).format('YYYY-MM-DD') === day
    ),
    'dts',
    'asc'
  );

  useEffect(() => {
    setLoading(true);
    dashboardActions.getTodos().then(() => {
      setLoading(false);
    });
  }, [dashboard.filters.provider]);

  return (
    <Container>
      <Header borderBottom={true}>
        <Row align="middle" justify="space-between">
          To-do's
        </Row>
      </Header>
      <Tabs
        data={tabsData}
        selected={selectedDay}
        onChange={(value) => {
          setSelectedDay(Number(value));
        }}
      />
      {loading ? (
        <LoadingContainer>
          <LoadingContainer>
            <div>
              <div className="loader" />
            </div>
          </LoadingContainer>
        </LoadingContainer>
      ) : (
        <ListContainer>
          <ListInnerContainer>
            {sortedList.length === 0 ? (
              <List dataSource={[]} />
            ) : (
              sortedList.map((value) => (
                <Item
                  key={`${value.id}-${value.assignedClinicId}`}
                  value={value}
                />
              ))
            )}
          </ListInnerContainer>
        </ListContainer>
      )}
    </Container>
  );
};

 

const mapStateToProps = (state: IStore, ownProps: IOwnProps) => {
  return {
    dashboard: state.dashboard
  };
};

export default connect(mapStateToProps)(Component);

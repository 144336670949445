import styled from 'styled-components';

export const PopoverTitleContainer: any = styled.span`
  width: 100%;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #333333;

  .waiting-reason {
    width: 276px;
    text-align: left;
  }
`;

export const SearchPatientContainer = styled.div`
  position: relative;
  width: 100%;
  height: 45px;
  margin-top: 5px;
  visibility: inherit;
`;

export const SearchPatientInput = styled.input`
  position: absolute;
  left: 0;
  height: 32px;
  width: 176px;
  color: black;
  opacity: 1;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 6px 14px 6px 34px;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  letter-spacing: 0.1px;
  visibility: inherit;

  &::-webkit-input-placeholder {
    color: #666666;
  }
`;

export const DeleteWarning = styled.div`
  display: flex;
  padding: 8px;
  width: 265px;
  left: 16px;
  top: 78px;
  background: #ffeaea;
  border-radius: 4px;
  gap: 5px;

  div {
    word-break: break-word;
  }
`;

import React, { FC, useCallback, useRef, useState } from 'react';
import { ReqOrderResult } from '../../store/types';
import Header from 'src/Framework/Controls/ModalSlider/Header';
import Button from 'src/Framework/Controls/Button';
import Radio from 'src/Framework/Controls/Radio';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import { Col, Row } from 'antd';
import UploaderAttachments from 'src/Framework/Controls/UploaderAttachments';
import _ from 'lodash';
import { useReqOrderResult } from '../../store/Hooks/useReqOrderResults';
import { FormRecordContainer, ResultContainer } from './styled';
import { uploadMultipartFiles } from 'src/Framework/Controls/FileUploader';
import { useValidatorV2 } from 'src/Framework/util/formValidatorV2';
import TemplatesForm from 'src/Framework/Controls/TemplatesForm';
import M1FormRecordButton from 'src/Activities/Forms/M1FormModal/M1FormRecordButton';
import { TagValuesIds } from 'src/App/Tags/store/utils';
import AlertPopUp, {
  IModalHandles as IAlertPopUp
} from 'src/Framework/Controls/AlertPopUp';
import { patch as M1FormRecordPatch } from 'src/App/M1FormRecords/store/Actions/index';

interface IProps {
  reqOrderId: number;
  currentResult: ReqOrderResult | null;
  onClose?: () => any;
  onBack: () => any;
  actions: ReturnType<typeof useReqOrderResult>['actions'];
  patientId: number;
}

const Component: FC<IProps> = ({
  currentResult,
  onClose,
  onBack,
  actions,
  reqOrderId,
  patientId
}: IProps) => {
  const AlertPopUpRef = useRef<IAlertPopUp>(null);
  const [prevData] = useState(
    currentResult ? _.cloneDeep(currentResult) : null
  );
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [radio, setRadio] = useState<{
    type: 'attachment' | 'forms';
  }>({
    type: currentResult?.m1FormRecordId ? 'forms' : 'attachment'
  });
  const [data, setData] = useState<Partial<ReqOrderResult>>(
    currentResult ? _.cloneDeep(currentResult) : {}
  );

  const onChangeData = useCallback((obj: typeof data) => {
    setData((data) => ({
      ...data,
      ...obj
    }));
  }, []);

  const onSave = async () => {
    let fileDefinitionId =
      radio.type === 'attachment' ? data.fileDefinitionId || null : null;
    if (radio.type === 'attachment' && filesToUpload.length > 0) {
      const res = await uploadMultipartFiles(filesToUpload);
      if (res[0]) {
        fileDefinitionId = res[0].id;
      }
    }
    if (prevData) {
      const newData: ReqOrderResult = {
        ...prevData,
        ...data,
        m1FormRecordId:
          radio.type === 'attachment' ? null : data.m1FormRecordId || null,
        m1FormTemplateId:
          radio.type === 'attachment' ? null : data.m1FormTemplateId || null,
        fileDefinitionId
      };
      const onEdit = async () => {
        const res = await actions.put(newData);
        if (res) {
          onBack();
        }
      };
      if (prevData.m1FormRecordId !== newData.m1FormRecordId) {
        AlertPopUpRef.current?.show({
          action: 'delete',
          content:
            'Do you want to delete previously attached form to this result?',
          onConfirmAsync: async () => {
            await Promise.all([
              M1FormRecordPatch(
                {
                  isActive: false,
                  id: prevData.m1FormRecordId!
                },
                ''
              ),
              onEdit()
            ]);
          },
          onCancel: () => onEdit()
        });
      } else {
        onEdit();
      }
    } else {
      const res = await actions.post({
        ...data,
        reqOrderId,
        m1FormRecordId:
          radio.type === 'attachment' ? null : data.m1FormRecordId,
        m1FormTemplateId:
          radio.type === 'attachment' ? null : data.m1FormTemplateId,
        fileDefinitionId
      });
      if (res) {
        onBack();
      }
    }
  };

  const validation = useValidatorV2(
    [
      {
        value: data.title,
        fieldName: 'Title'
      },
      {
        value:
          data.text ||
          (radio.type === 'attachment'
            ? data.fileDefinitionId || filesToUpload.length > 0
            : data.m1FormRecordId || data.m1FormTemplateId),
        fieldName: 'Description/Attachment/Form'
      }
    ],
    [data, filesToUpload, radio]
  );

  return (
    <>
      <Header
        backButton={true}
        onClickBack={onBack}
        title={prevData ? 'Edit Result' : 'Add Result'}
        onClose={onClose}
        headerRight={
          <Button
            id=""
            disabled={!validation.isValid}
            tooltip={{
              title: validation.errorMessage
            }}
            onClick={onSave}
          >
            Save
          </Button>
        }
      />
      <ResultContainer>
        <AlertPopUp ref={AlertPopUpRef} />
        <SelectContainer
          label="Title"
          star={true}
          type="input"
          value={data.title}
          onChange={(title) => onChangeData({ title })}
          inputProps={{
            maxLength: 256
          }}
        />
        <Row align="middle" gutter={16} style={{ marginBottom: 20 }}>
          <Col>
            <Radio
              checked={radio.type === 'attachment'}
              id=""
              onChange={() => setRadio({ type: 'attachment' })}
            >
              Attachment
            </Radio>
          </Col>
          <Col>
            <Radio
              checked={radio.type === 'forms'}
              id=""
              onChange={() => setRadio({ type: 'forms' })}
            >
              Forms
            </Radio>
          </Col>
        </Row>
        {radio.type === 'attachment' && (
          <UploaderAttachments
            singleFile={true}
            fileIds={data?.fileDefinitionId ? [data?.fileDefinitionId] : []}
            filesToUpload={filesToUpload}
            files={data?.fileDefinition ? [data.fileDefinition] : []}
            onChangeFiles={({ files, fileIds }) => {
              onChangeData({
                fileDefinition: files[0],
                fileDefinitionId: fileIds[0]
              });
            }}
            onChangeUploadFiles={setFilesToUpload}
          />
        )}
        {}
        {radio.type === 'forms' &&
          (data.m1FormRecordId ? (
            <FormRecordContainer>
              <M1FormRecordButton
                onRemoveForm={() => onChangeData({ m1FormRecordId: null })}
                onClickRemoveIcon={() => onChangeData({ m1FormRecordId: null })}
                m1FormRecordId={data.m1FormRecordId}
                patientId={patientId}
                showRemoveIcon={true}
              />
            </FormRecordContainer>
          ) : (
            <TemplatesForm
              label="Forms"
              star={false}
              hideLegacyForms={true}
              filter={(v) =>
                v.m1?.tags.includes(TagValuesIds.Category.PointOfCare) || false
              }
              valueLegacy={null}
              valueM1={data?.m1FormTemplateId}
              onChange={(value, type) => {
                if (type === 'm1') {
                  onChangeData({ m1FormTemplateId: value });
                }
              }}
            />
          ))}
        <SelectContainer
          label="Description"
          type="area"
          star={false}
          value={data.text}
          onChange={(text: string) => onChangeData({ text })}
          areaAutoReplace={true}
          inputProps={{
            rows: 6,
            maxLength: 4000
          }}
        />
      </ResultContainer>
    </>
  );
};

export default React.memo(Component);

import React from 'react';

interface IProps {
  conflict?: boolean;
}

export const AlertsBarIcon = ({ conflict }: IProps) => {
  const color = conflict ? '#FF2828' : '#E67E22';
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.252 7V10.2M10.252 12.3999V12.4999"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8403 10C16.8403 13.584 13.8966 16.5 10.2522 16.5C6.60775 16.5 3.66406 13.584 3.66406 10C3.66406 6.41603 6.60775 3.5 10.2522 3.5C13.8966 3.5 16.8403 6.41603 16.8403 10Z"
        stroke={color}
      />
    </svg>
  );
};

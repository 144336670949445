import React, { FC, useState, useEffect } from 'react';
import _ from 'lodash';
import { Row2 as Row } from 'src/Activities/Schedule/Common/Classes';
import Table from 'src/Framework/Controls/Table';
import { providerDisplayName } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { getClinics } from 'src/Framework/Controls/Selectors/ClinicSelector';

import { useAppSelector } from 'src/store';
import { initialData } from 'src/Activities/Counseling/Dashboard/PatientsProviders/store/Reducer';
import * as providersActions from 'src/Activities/Counseling/Dashboard/PatientsProviders/store/Actions';

import { ExtendIconContainer } from 'src/Activities/Schedule/Popups/ScheduleDetailPopup/ScheduleDetailPopup_Styles';
import { CircleDownSvg } from 'src/Activities/Schedule/Svg/CircleDownSvg';
import { CircleUpSvg } from 'src/Activities/Schedule/Svg/CircleUpSvg';

interface IProps {
  patientId: number;
}

const Component: FC<IProps> = ({ patientId }) => {
  const [clinics] = useState(() => {
    const clinics = {};
    const data = getClinics();
    data.forEach((v: any) => {
      clinics[v.id] = v;
    });
    return clinics;
  });
  const [isExpand, setExpand] = useState<boolean>(false);
  const { providers, providerTypes } = useAppSelector((state) => {
    const providers =
      state.patientsProviders.patients[patientId + ''] ||
      _.cloneDeep(initialData);
    return {
      providers,
      providerTypes: state.adminPanel.providerTypes.summary
    };
  });
  useEffect(() => {
    if (patientId) {
      providersActions.get(patientId);
    }
  }, [patientId]);

  const values = Object.values(providers.summary).filter((v) => v.isEnabled);
  const data = values.map((value) => {
    const referral = value.referral;
    let fullName = '';
    if (referral) {
      fullName = `${referral.firstName} ${referral.lastName}`;
    } else {
      fullName = providerDisplayName(value.providerId);
    }
    return {
      ...value,
      fullName,
      role: providerTypes[value.typeProviderId]?.name,
      clinic: clinics[value.clinicId]?.name
    };
  });

  const columns = [
    {
      title: 'Name',
      key: 'fullName',
      sortBy: 'fullName',
      flex: '196px'
    },
    {
      title: 'Role',
      key: 'role',
      sortBy: 'role',
      flex: '196px'
    },
    {
      title: 'Clinic',
      key: 'clinic',
      sortBy: 'clinic',
      flex: '196px'
    },
    {
      title: 'Comment',
      key: 'comment',
      flex: 'auto'
    }
  ];
  const sort = providers.sort;

  return (
    <>
      <Row borderTop={true} paddingBottom>
        <label className={'preview-tab-style'}>PREFERRED PROVIDERS</label>
        <ExtendIconContainer
          onClick={() => {
            setExpand(!isExpand);
          }}
        >
          {isExpand ? <CircleUpSvg /> : <CircleDownSvg />}
        </ExtendIconContainer>
      </Row>
      <div
        style={
          isExpand
            ? {
                display: 'block',
                marginBottom: '8px'
              }
            : { display: 'none' }
        }
      >
        <Table
          sort={sort}
          onChangeSort={(value) =>
            providersActions.onChangeSort(patientId + '', value)
          }
          verticalItemPadding={8}
          data={data}
          columns={columns}
        />
      </div>
    </>
  );
};
export default Component;

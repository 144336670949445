import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'antd';

import Table from 'src/Framework/Controls/Table';
import EditButton from 'src/Framework/Controls/Table/EditButton';
import RemoveButton from 'src/Framework/Controls/Table/RemoveButton';
import { ITableRenderItemData } from 'src/Framework/Controls/Table/types';
// import Checkbox from 'src/Framework/Controls/Checkbox';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { getMaskOptions } from 'src/Framework/Controls/SelectContainer/Components/Input';
import Breadcrumbs from 'src/Framework/Controls/Breadcrumbs';
import { getClinicById } from 'src/Framework/Controls/Selectors/ClinicSelector';
import Icon from 'src/Framework/Controls/Icon';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { clinicDisplayName } from 'src/Framework/util/format';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { IStore } from 'src/store';
import ids from 'src/tests-script/pages/Subject/Contacts';

import { findOpt, getOptions } from 'src/Activities/Subject/PatientListActions';
import { PlusSvg } from 'src/Activities/Counseling/Notes/EditNote/Svg/PlusSvg';
import { phoneIds } from 'src/Activities/Subject/Patients/Demographics/Controls/PreferredNumber';

import Modal, { IModalHandles } from './Modal';
import { defaultPatientInfoData } from '../store';
import * as patientActions from '../store/Actions';
import {
  IPatientContactsSimpleDto,
  PatientDemographicDto
} from '../store/types';

import { Container } from './styled';

interface IState {}

interface IOwnProps {
  subjectId?: any;
}

interface IProps extends IOwnProps {
  data: IStore['client']['patientInfo']['']['contacts'];
  patientLists?: any;
  patientData?: PatientDemographicDto;
}

const breadcrumbs = [
  {
    id: 0,
    title: `${getPatientTerm('Student')} Chart`
  },
  {
    id: 1,
    title: 'Personal Details'
  },
  {
    id: 2,
    title: 'Other Contacts'
  }
];

const prefix = `oc-`;

export const getPreferredPhoneNumber = (value: IPatientContactsSimpleDto) => {
  const type = Object.keys(phoneIds).find(
    (key) => phoneIds[key] === value.otherParty.typePreferredPhoneId
  );
  return type
    ? value.otherParty.typePreferredPhoneId === 1 ||
      value.otherParty.typePreferredPhoneId === 2
      ? getMaskOptions('phoneHome', value.otherParty[type])?.value
      : getMaskOptions('phone', value.otherParty[type])?.value
    : '';
};

class Contacts extends React.Component<IProps, IState> {
  public tableRef: any;
  public componentRef: React.RefObject<any>;
  modalRef: React.RefObject<IModalHandles>;

  constructor(props: IProps) {
    super(props);
    this.modalRef = React.createRef<IModalHandles>();
    this.componentRef = React.createRef();
  }

  componentDidMount = () => {
    patientActions.getPatientContacts(this.props.subjectId);
    if (this.componentRef.current) {
      this.componentRef.current.focus();
    }
  };

  deleteSubject = (subjectIdToInactive: any) => {};

  render() {
    const { patientLists, patientData, data, subjectId } = this.props;
    if (!data && !patientLists) return null;

    const dataSource = Object.values(data);

    const renderItemData = (
      v: typeof dataSource[number]
    ): ITableRenderItemData<typeof v> => {
      let relationship = '';
      if (v.relationshipId && patientLists) {
        const options = getOptions(patientLists.relationship);
        const relation: any = findOpt(options, v.relationshipId);
        relationship = relation?.label;
      }
      const itemPrefix = `${prefix}${v.id}-`;
      return {
        ...v.otherParty,
        clinics: v.emergencyContact && (
          <div>
            {v.clinicIds.map((v) => (
              <div key={v}>{clinicDisplayName(getClinicById(v))}</div>
            ))}
          </div>
        ),
        mouseOverRow: {
          component: ({ onOpenPopoverChange, active }) => (
            <Row wrap={false} align="middle" gutter={16}>
              <Col>
                <EditButton
                  buttonProps={{
                    isButton: active,
                    id: `${itemPrefix}edit-button`,
                    controlsId: ids.ADD_CONTACT_MODAL,
                    haspopup: 'dialog',
                    containerProps: {
                      style: {
                        visibility: active ? 'visible' : 'hidden'
                      }
                    }
                  }}
                  onClick={() => {
                    this.modalRef.current?.show({ value: v });
                  }}
                />
              </Col>
              <Col>
                <RemoveButton
                  id={`${itemPrefix}remove-last-in-hover-popup`}
                  approve={true}
                  approveText="Are you sure you want to delete the Contact"
                  removeAction={async () => {
                    await patientActions.removeOtherContact(
                      this.props.subjectId,
                      v.id
                    );
                  }}
                  buttonIconProps={{
                    isButton: active,
                    containerProps: {
                      style: {
                        visibility: active ? 'visible' : 'hidden'
                      }
                    }
                  }}
                />
              </Col>
            </Row>
          )
        },
        relationship,
        preferredNumber: getPreferredPhoneNumber(v),
        preventMppLogin: v.otherParty.preventMppLogin ? (
          <Icon name="check2" color={globalStyleVariables.darkGrey} />
        ) : null,
        // <Checkbox
        //   id={`${itemPrefix}preventMppLogin`}
        //   checked={v.otherParty.preventMppLogin}
        //   asyncChange={true}
        //   onKeyDown={onKeyDownAccessibility}
        //   onChange={async () => {
        //     await patientActions.patchPatientContact(this.props.subjectId, {
        //       id: v.id,
        //       otherParty: {
        //         id: v.otherParty.id,
        //         preventMppLogin: !v.otherParty.preventMppLogin
        //       }
        //     });
        //   }}
        // >
        //   <span className="visually-hidden">prevent Mpp login</span>
        // </Checkbox>
        // ),
        emergencyContact: v.emergencyContact ? (
          <Icon name="check2" color={globalStyleVariables.darkGrey} />
        ) : null,
        // <Checkbox
        //   id={`${itemPrefix}emergencyContact`}
        //   checked={v.emergencyContact}
        //   asyncChange={true}
        //   onKeyDown={onKeyDownAccessibility}
        //   onChange={async () => {
        //     await patientActions.patchPatientContact(this.props.subjectId, {
        //       id: v.id,
        //       emergencyContact: !v.emergencyContact
        //     });
        //   }}
        // >
        //   <span className="visually-hidden">emergency contact</span>
        // </Checkbox>
        responsibleParty: v.responsibleParty ? (
          <Icon name="check2" color={globalStyleVariables.darkGrey} />
        ) : null
        // <Checkbox
        //   id={`${itemPrefix}responsibleParty`}
        //   asyncChange={true}
        //   onKeyDown={onKeyDownAccessibility}
        //   checked={v.responsibleParty}
        //   onChange={async () => {
        //     await patientActions.patchPatientContact(this.props.subjectId, {
        //       id: v.id,
        //       responsibleParty: !v.responsibleParty
        //     });
        //   }}
        // >
        //   <span className="visually-hidden">responsible party</span>
        // </Checkbox>
      };
    };

    // const onKeyDownAccessibility = (e: React.KeyboardEvent<HTMLDivElement>) => {
    //   if (e.key === 'Enter' || e.code === 'Space') {
    //     e.preventDefault();
    //     if (e.currentTarget) {
    //       // @ts-ignore
    //       e.currentTarget.click();
    //     }
    //   }
    // };

    return (
      <Container tabIndex={0} ref={this.componentRef}>
        <Modal
          ref={this.modalRef}
          patientLists={patientLists}
          patientData={patientData}
          subjectId={subjectId}
        />
        {!patientLists || Object.keys(patientLists).length === 0 ? (
          <AbsoluteLoader />
        ) : (
          <>
            <Breadcrumbs
              data={breadcrumbs}
              rightComponent={
                <Button
                  type="text"
                  id={`${prefix}add-button`}
                  className="add-container"
                  onClick={() => {
                    this.modalRef.current?.show({});
                  }}
                  aria-controls={ids.ADD_CONTACT_MODAL}
                  aria-haspopup={'dialog'}
                >
                  <PlusSvg />
                  <span>Add Contact</span>
                </Button>
              }
            />
            <Table
              tableCaption={'Added Contacts'}
              absoluteContainer={true}
              columns={columns}
              rowHover={true}
              dataSource={dataSource}
              renderItemData={renderItemData}
            />
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: IOwnProps) => {
  const subjectId = ownProps.subjectId;
  const patientInfo =
    state.client.patientInfo[subjectId] || _.cloneDeep(defaultPatientInfoData);
  return {
    patientLists: state.client.lists.patientLists,
    patientData: patientInfo.patientDemographic,
    data: patientInfo.contacts
  };
};

export default connect(mapStateToProps)(Contacts);

const columns = [
  {
    title: 'Last Name',
    key: 'lastName',
    flex: '200px'
  },
  {
    title: 'First Name',
    key: 'firstName',
    flex: '200px'
  },
  {
    title: 'Relationship',
    key: 'relationship',
    flex: 'auto'
  },
  // {
  //   title: 'Clinics',
  //   key: 'clinics',
  //   flex: '245px'
  // },
  {
    title: 'Preferred Number',
    key: 'preferredNumber',
    flex: '140px'
  },
  {
    title: 'Prevent from MPP login',
    key: 'preventMppLogin',
    flex: '160px',
    centered: true
  },
  {
    title: 'Emergency Contact',
    key: 'emergencyContact',
    flex: '145px',
    centered: true
  },
  {
    title: 'Resp. Party',
    key: 'responsibleParty',
    flex: '100px',
    centered: true
  }
  // {
  //   title: '',
  //   key: 'edit',
  //   flex: '80px',
  //   centered: true
  // },
  // {
  //   title: '',
  //   key: 'remove',
  //   flex: '80px',
  //   centered: true
  // }
];

import { IFormItem } from "src/Activities/Forms/FormList/types";
import { IM1FormRecord } from "src/App/M1FormRecords/store/types";
import { IReqOrder } from "./ControlTypes/Referrals/types";
import { IFileSimpleDto } from "src/Framework/Controls/FileUploader";
import { NoteDetailsFormViewV1ControlContent } from "./ControlTypes/FormView/V1/types";
import { IPlanControlV2 } from "./ControlTypes/Plan/V2/types";
import { ControlType } from "src/App/Admin/Pages/NoteTypes/store/types";

export type IControl =
    | INarrativeControl
    | IRiskControl
    | IDiagnosisControl
    | ITicketDetailControl
    | IReferralControl
    | IFormControl
    | IM1FormControl
    | IPlanControl
    | IDiagnosisControlV2
    | IRiskControlV2
    | IMedicationControl
    | IFormViewControl
    | IPlanControlV2;


export interface IFormViewControl extends NoteDetailsFormViewV1ControlContent {
    controlType: ControlType.FormView;

}
export interface IMedicationControl {
    controlType: ControlType.medication;
    reqBatchIds: number[]
}

export interface IPlanControl {
    controlType: ControlType.plan;
    entries: NoteDetailsPlanControlContentEntry[];
    controlTypeVersion: 1
}

export interface NoteDetailsPlanControlContentEntry {
    id: string;
    isActive: boolean;
    txnCodeId: number
    dts?: string
    preventNoteLock: boolean;
    diagnosisPtr: string | null;
    ticketDetailId: number | null;
    ticketDetailCreationParams: NoteDetailsPlanControlContentEntryTicketDetailCreationParams | null;
    ticketDetailPreviewData: NoteDetailsPlanControlContentEntryTicketDetailPreviewData | null;
    reqOrderId: number | null;
    reqOrderCreationParams: NoteDetailsPlanControlContentEntryReqOrderCreationParams | null;
    reqOrderPreviewData: NoteDetailsPlanControlContentEntryReqOrderPreviewData | null;
    relatedForms: NoteDetailsPlanControlContentEntryRelatedForm[];
}

export interface NoteDetailsPlanControlContentEntryTicketDetailCreationParams {
    clinicId: number | null;
    txnCodeCode: string | null;
    amount: number | null;
    units: number | null;
    description: string | null;
    dosFrom: string | null
    fileIds: number[];
    renderingProviderId: number | null;
    txnCodeId: number | null;
    inventoryId: number | null
    typeReqPriorityId: number | null
    modifier1: string | null
    modifier2: string | null
    typeImmEligibilityId: number | null
    typeAdministrationMethodId: number | null
    comment: string | null
    facilityId: number | null;
    approvalCode: string | null
}

export interface NoteDetailsPlanControlContentEntryTicketDetailPreviewData {

}

export interface NoteDetailsPlanControlContentEntryReqOrderCreationParams {
    clinicId: number | null;
    facilityId: number | null;
    comment: string | null;
    fileIds: number[];
    isDashboardShown: boolean;
    name: string;
    noteId: number | null;
    orderDate: string | null;
    renderingProviderId: number | null;
    txnCodeId: number | null;
    typeReqPriorityId: number | null;
    typeReqStatusId: number | null;
    typeSourceOfSpecimenId: number | null;
    createTaskCaseManager: boolean
    createTaskRenderingProvider: boolean
    files?: IFileSimpleDto[]
}

export interface NoteDetailsPlanControlContentEntryReqOrderPreviewData {
    comment: string;
    name: string;
    orderDate: string;
    renderingProviderName: number;
}

export interface NoteDetailsPlanControlContentEntryRelatedForm {
    id: string;
    formType: FormType;
    relationType: NotePlanFormRelationType;
    templateFormRecordSeedId: number | null;
    formCreationParams: NoteDetailsPlanControlContentEntryLegacyFormCreationParams | null;
    formPreviewData: NoteDetailsPlanControlContentEntryLegacyFormPreviewData | null;
    m1FormRecordId: number | null;
    m1FormCreationParams: NoteDetailsPlanControlContentEntryM1FormCreationParams | null;
    m1FormPreviewData: NoteDetailsPlanControlContentEntryM1FormPreviewData | null;
}

export enum FormType {
    Legacy = 'legacy',
    M1 = 'm1'
}

export enum NotePlanFormRelationType {
    Attachment = 'attachment',
    Result = 'result'
}


export interface NoteDetailsPlanControlContentEntryLegacyFormCreationParams {
    templateFormId: number;
    submission: any;
    isRestricted: boolean;
    providerId: number;
    clinicId: number;
    dts: string;
    userId: number;
    signatures: NoteDetailsPlanControlContentEntryLegacyFormCreationParamsSignature[] | null;
    appendages: NoteDetailsPlanControlContentEntryLegacyFormCreationParamsAppendage[] | null;
    redirections: NoteDetailsPlanControlContentEntryLegacyFormCreationParamsRedirection[] | null;
}

export enum TypeFormStatus {
    InProgress = 'inProgress',
    Signed = 'signed',
    Locked = 'locked',
    Invalid = 2
}

export interface NoteDetailsPlanControlContentEntryLegacyFormCreationParamsSignature {
    signatureType: TypeFormStatus;
    userId: number;
    dts: string;
}

export interface NoteDetailsPlanControlContentEntryLegacyFormCreationParamsAppendage {
    text: string;
    userId: number;
    dts: string;
}

export interface NoteDetailsPlanControlContentEntryLegacyFormCreationParamsRedirection {
    fromProviderId: number;
    fromClinicId: number;
    toProviderId: number;
    toClinicId: number;
    userId: number;
    dts: string;
}

export interface NoteDetailsPlanControlContentEntryLegacyFormPreviewData {
    schema: string;
    submission: string;
}


export interface NoteDetailsPlanControlContentEntryM1FormCreationParams {
    formTemplateId: number;
    formTemplateVersion: number;
    clinicId: number;
    assignedProviderId: number;
    submission: any;
    dts: string;
    userId: number;
    signatures: NoteDetailsPlanControlContentEntryM1FormCreationParamsSignature[];
    appendages: NoteDetailsPlanControlContentEntryM1FormCreationParamsAppendage[];
    redirections: NoteDetailsPlanControlContentEntryM1FormCreationParamsRedirection[];
}

export enum M1FormRecordStatus {
    Invalid = 0,
    InProgress = 'inProgress',
    Signed = 'signed',
    Locked = 'locked'
}

export interface NoteDetailsPlanControlContentEntryM1FormCreationParamsSignature {
    signatureType: M1FormRecordStatus;
    userId: number;
    dts: string;
}

export interface NoteDetailsPlanControlContentEntryM1FormCreationParamsAppendage {
    text: string;
    userId: number;
    dts: string;
}

export interface NoteDetailsPlanControlContentEntryM1FormCreationParamsRedirection {
    fromProviderId: number;
    fromProviderName: string;
    toProviderId: number;
    toProviderName: string;
    userId: number;
    dts: string;
}

export interface NoteDetailsPlanControlContentEntryM1FormPreviewData {
    schema: string;
    submission: string;
}

export interface INarrativeControl {
    controlType: ControlType.narrative;
    text: string;
    code: string;
    controlTypeVersion: number
}

export interface IRiskControl {
    controlType: ControlType.risk;
    controlTypeVersion: number
    risks: {
        [key: string]: {
            typeRiskId: number;
            userId: number;
        }
    };
}

export interface IRiskControlV2 {
    controlType: ControlType.risk;
    controlTypeVersion: 2
    risks: {
        [key in TypeRiskGroup]: NoteDetailsRiskControlContentEntryV2
    };
}

export enum TypeRiskGroup {
    Suicide = 'suicide',
    Homicide = 'homicide',
    Academic = 'academic'
}

export interface NoteDetailsRiskControlContentEntryV2 {
    id: string;
    isActive: boolean
    noteRiskId: number | null;
    noteRiskCreationParams: NoteDetailsRiskControlContentEntryNoteRiskCreationParams;
    noteRiskPreviewData: NoteDetailsRiskControlContentEntryNoteRiskPreviewData;
}

export interface NoteDetailsRiskControlContentEntryNoteRiskCreationParams {
    typeRiskId: number | null;
    userId: number;
}

export interface NoteDetailsRiskControlContentEntryNoteRiskPreviewData {
    typeRiskId: number | null;
    userId: number;
}

export interface IDiagnosisControl {
    controlType: ControlType.diagnosis;
    controlTypeVersion: 1
    diagnoses: {
        id: string;
        icd10: string;
        comment: string;
    }[];
    ruleOut: string;
    provisionalDiagnosis: string;
    isDeferred: boolean;
    hasNoDiagnosis: boolean;
}

export interface IDiagnosisControlV2 {
    controlType: ControlType.diagnosis;
    diagnoses: NoteDetailsDiagnosisControlContentEntryV2[];
    ruleOut: string;
    provisionalDiagnosis: string;
    isDeferred: boolean;
    hasNoDiagnosis: boolean;
    controlTypeVersion: 2
}

export interface NoteDetailsDiagnosisControlContentEntryV2 {
    id: string;
    diagnosisId: number;
    order: number;
    isActive: boolean;
    ticketDiagnosisId: number | null;
    creationParams: NoteDetailsDiagnosisControlContentEntryCreationParams;
    previewData: NoteDetailsDiagnosisControlContentEntryPreviewData | null;
}

export interface NoteDetailsDiagnosisControlContentEntryCreationParams {
    description: string;
    isFinal: boolean;
    comment: string;
    icd9: string;
    icd10: string;
    code: string;
    name: string;
    typeDiagnosisStatusId: number | null;
    snomedcui: null | string
}

export interface NoteDetailsDiagnosisControlContentEntryPreviewData {
    comment: string;
    icd9: string;
    icd10: string;
    code: string;
    name: string;
    orderCharacter: string;
}

export interface ITicketDetailControl {
    controlType: ControlType.ticketDetailGeneric;
    ticketDetails: ITicketDetail[]
    controlTypeVersion: number
}

export interface ITicketDetail {
    clinicId: number;
    description: string;
    txnCodeId: number;
    txnCodeCode: string;
    fileIds: any[];
    renderingProviderId: number;
    userId: number;
}

export interface IReferralControl {
    controlType: ControlType.referral
    reqOrders: IReqOrder[]
    controlTypeVersion: number
}

export interface IM1FormControl {
    controlId: string
    controlType: ControlType.m1form
    submission: any
    controlTypeVersion: number
}

export interface IFormControl {
    controlId: string
    controlType: ControlType.form
    id: string
    submission: any;
    templateFormId: number
    controlTypeVersion: number
}

export interface IDividerProps {
    title: string
}

interface ValidationStatus {
    valid: boolean;
    message: string;
}

export interface ControlsValidations {
    [controlId: string]: ValidationsStatuses
}

export interface ValidationsStatuses {
    [key: string]: ValidationStatus
}


export enum ReferralsValidations {
    renderingProviderIsNotDisabled = "renderingProviderIsNotDisabled",
    typeReqStatusIsNotDisabled = "typeReqStatusIsNotDisabled"
}

export enum Type {
    M1 = 'm1',
    LEGACY = 'legacy'
}

export interface ILegacyForm extends IFormItem {
    type: Type.LEGACY;
}

export interface IM1Form extends IM1FormRecord {
    type: Type.M1;
}
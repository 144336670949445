import store from 'src/store';

export const reducer = () => store.getState().templateForms

export const getTemplateFormBySeedId = (seedId: number) => {
    const data = reducer()
    const item = data[seedId]
    if (item) {
        return item
    }
    return
}
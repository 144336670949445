import React, { FC, useEffect, useRef } from 'react';

import NoData from 'src/Framework/Controls/Table/NoData';
import ElementTitle from 'src/Framework/Controls/ElementTitle';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';

import Item from './Item';
import { IOnChangeComment } from '.';
import { IComment } from 'src/Activities/Schedule/RightSideBar/WaitingList/Group/types';

import { ListContainer } from './styled';

interface IProps {
  data: IComment[];
  viewMode?: boolean;
  onChangeComment?: IOnChangeComment;
  popoverMode?: boolean;
  removeItemHorizontalMargins?: boolean;
}

const Component: FC<IProps> = ({
  data,
  viewMode,
  onChangeComment,
  popoverMode,
  removeItemHorizontalMargins
}: IProps) => {
  const listRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (listRef.current) {
        const listElement = listRef.current;
        if (listElement) {
          const lastItemOffset =
            ((listElement as HTMLElement)?.lastChild as HTMLElement)
              ?.offsetTop || 0;
          (listElement as HTMLElement).scrollTop = lastItemOffset;
        }
      }
    }, 100);
  }, [data]);

  const filteredList = data.filter((v) => v.isActive);

  return (
    <>
      {!viewMode && (
        <div style={{ padding: '0 25px' }}>
          <ElementTitle name={' Comments:'} />
        </div>
      )}
      <ListContainer
        popoverMode={popoverMode}
        ref={listRef}
        role="list"
        tabIndex={filteredList.length > 0 ? 0 : -1}
        onKeyDown={onKeyDownAccessibility}
      >
        {filteredList.length !== 0 ? (
          filteredList.map((v) => (
            <Item
              key={v.id}
              value={v}
              viewMode={viewMode}
              onChangeComment={onChangeComment}
              removeItemHorizontalMargins={removeItemHorizontalMargins}
            />
          ))
        ) : (
          <NoData />
        )}
      </ListContainer>
    </>
  );
};

export default React.memo(Component);

import * as React from 'react';

import Table from 'src/Framework/Controls/Table';
import { ISort } from 'src/Framework/Controls/Table/types';

import { SingleTabulatorWrapper } from './TabSelect_styles';

interface IState {
  sort: ISort;
}

interface IProps {
  onSelection?: any;
  dispatch?: any;
  selected: any;
  data: any;
  columns: any;
  containerWidth?: number;
}

class TabSelect extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      sort: {
        sortBy: null,
        sortType: null
      }
    };
  }

  render() {
    const onKeyDownRow = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        //@ts-ignore
        e.target.click();
      }
    };
    const onClickRow = (index: number, value: any) => {
      this.props.onSelection([value.fullObj]);
    };

    return (
      <SingleTabulatorWrapper
        id="singleSelect"
        width={this.props.containerWidth}
      >
        <Table
          onKeyDownRow={(e: React.KeyboardEvent) => onKeyDownRow(e)}
          onClickRow={(index, value) => onClickRow(index, value)}
          rowHover={true}
          sort={this.state.sort}
          onChangeSort={(sort) =>
            this.setState({
              sort
            })
          }
          data={this.props.data}
          columns={this.props.columns}
          headerBackground="#F7F7F7"
          absoluteContainer={true}
          containerStyleProps={{
            height: 400
          }}
        />
      </SingleTabulatorWrapper>
    );
  }
}

export default TabSelect;

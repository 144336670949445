import _ from 'lodash'

import { IAction, IStateReducer } from './types'

const prefix = 'OUTREACH_'

export const actionsTypes = {
    SET: `${prefix}SET`,
    SET_FILTER: `${prefix}SET_FILTER`,
    SET_LOADING: `${prefix}SET_LOADING`,
    SET_SORT: `${prefix}SET_SORT`
}

const initialState: IStateReducer = {
    summary: {},
    loading: false,
    filters: {
        providers: [],
        dateFrom: null,
        dateTo: null,
        search: '',
        showInactive: false,
        clinicId: null,
        pageNumber: 0
    },
    sort: {
        sortBy: 'dateTimeTimestamp',
        sortType: 'desc'
    }
};

const Reducer = (state: IStateReducer = _.cloneDeep(initialState), action: IAction): IStateReducer => {
    switch (action.type) {
        case actionsTypes.SET: {
            return { ...state, summary: action.payload }
        }
        case actionsTypes.SET_LOADING: {
            return { ...state, loading: action.payload }
        }
        case actionsTypes.SET_FILTER: {
            return { ...state, filters: { ...state.filters, ...action.payload } }
        }
        case actionsTypes.SET_SORT: {
            return {
                ...state,
                sort: action.payload
            }
        }
        default:
            return state;
    }
};

export default Reducer;

import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import Table from 'src/Framework/Controls/Table';
import { ISort } from 'src/Framework/Controls/Table/types';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import StatusLabel from 'src/Framework/Controls/StatusLabel';
import { getClinicById } from 'src/Framework/Controls/Selectors/ClinicSelector';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { providerDisplayName } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';

import { getEventsHistory } from 'src/Activities/Schedule/Store/Events/Actions';

import { MemberLine, MembersContainer, HistoryContainer } from './styled';

interface IEventHistoryMember {
  id: number;
  providerId: number;
  status: number;
  userId: number;
  arriveTime: string | null;
}

interface IEventHistory {
  id: number;
  name: string;
  clinicId: number;
  startDate: string;
  startTime: string;
  endTime: string;
  note: string | null;
  eventMembers: IEventHistoryMember[];
  appointmentReason: {
    id: number;
    code: string;
    name: string;
  };
}

interface IProps {
  providerId: number;
}

const pageLength = 5;
const Component: FC<IProps> = ({ providerId }) => {
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState<ISort>({ sortBy: null, sortType: null });
  const [data, setData] = useState<IEventHistory[]>([]);

  const getData = async () => {
    setLoading(true);
    const res = await getEventsHistory(+providerId);
    if (res) {
      setData(Object.values(res.data.result.summary));
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [providerId]);

  const renderItemData = (item: IEventHistory) => {
    return {
      ...item,
      name: <EllipsisText maxWidth={160}>{item.name}</EllipsisText>,
      nameSort: item.name,
      dateTime: `${moment(
        item.startDate.slice(0, 10) + 'T' + item.startTime.slice(11, 16)
      ).format('MM/DD/YYYY, hh:mm A')} - ${moment(item.endTime).format(
        'hh:mm A'
      )}`,
      dateSort: moment(
        item.startDate.slice(0, 10) + 'T' + item.startTime.slice(11, 16)
      ).unix(),
      clinic: getClinicById(item.clinicId)?.name,
      note: item?.note && (
        <ButtonIcon
          tooltip={
            item.note.length > 255 ? item.note.slice(0, 255) + '...' : item.note
          }
          name={ICONS_LIST.warning}
          iconScale={1.2}
          disableBackground={true}
        />
      ),
      members: (
        <ButtonIcon
          isButton={true}
          size={32}
          label="Staff appointment members"
          onClick={() => {}}
          changeColorOnHover={false}
          disableBackground={true}
          name={ICONS_LIST.groupList}
          tooltipProps={{
            overlayInnerStyle: {
              color: 'black',
              width: 270,
              padding: 16
            },
            color: 'white'
          }}
          tooltip={
            <MembersContainer>
              {item.eventMembers.map((member) => (
                <MemberLine>
                  <EllipsisText maxWidth={160}>
                    {providerDisplayName(member.providerId)}
                  </EllipsisText>
                  {member.status === 2 && (
                    <StatusLabel statusCode={'Arrived'} maxWidth={60} />
                  )}
                </MemberLine>
              ))}
            </MembersContainer>
          }
        />
      )
    };
  };

  return (
    <HistoryContainer>
      {loading && <AbsoluteLoader />}
      <Table
        sort={sort}
        onChangeSort={(value) => setSort(value)}
        rowClassname="provider-history-row"
        verticalItemPadding={8}
        absoluteContainer={true}
        enablePagination={true}
        innerPagination={{
          pageNumber: 1,
          pageLength
        }}
        containerStyleProps={{
          borderTop: '1px solid #0000001f',
          padding: '0 8px'
        }}
        headerBackground="#ffffff"
        dataSource={data}
        columns={eventHistoryColumns}
        renderItemData={renderItemData}
        rowIdFieldname={'id'}
      />
    </HistoryContainer>
  );
};

const eventHistoryColumns = [
  {
    title: 'Name',
    key: 'name',
    flex: 'auto',
    sortBy: 'nameSort'
  },
  {
    title: 'Date & Time',
    key: 'dateTime',
    flex: '240px',
    sortBy: 'dateSort'
  },
  {
    title: 'Clinic',
    key: 'clinic',
    flex: '180px',
    sortBy: 'clinic'
  },
  {
    title: 'Note',
    key: 'note',
    flex: '40px',
    centered: true
  },
  {
    title: 'Members',
    key: 'members',
    flex: '80px',
    centered: true
  }
];

export default Component;

import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import {
    IOrderManagerBasicFilters,
    IOrderManagerFavoriteExtendedDto,
    IOrderManagerFavoriteSimpleDto,
    IOrderManagerFilter,
    IOrderManagerItem,
    IOrderManagerPagination,
    IStatusLog,
    ReqBatchDto,
    ReqResultDto
} from '../types';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import _ from 'lodash';
import QueryString from 'qs';

export const get = async ({
    basic,
    filters
}: {
    basic: IOrderManagerBasicFilters;
    filters: Partial<IOrderManagerFilter>;
}): Promise<IOrderManagerPagination | undefined> => {
    try {
        const data = {
            ...basic,
            ...filters
        };
        const params: any = _.cloneDeep(data);

        // Validations
        Object.entries(data).forEach(([key, value]) => {
            if (value === null || value === undefined || value === '') {
                delete params[key];
                return;
            }
            if (Array.isArray(value)) {
                if (value.length === 0) {
                    delete params[key];
                    return;
                }
            }
        });
        // -----------------
        const response = await APIHandler.AxiosInstance.get(
            `${ApiUrl.OrderManager}`,
            {
                withCredentials: true,
                params,
                paramsSerializer: function (params) {
                    return QueryString.stringify(params, { indices: false }); // param=value1&param=value2
                }
            }
        );
        if (response.data.success) {
            const data = response.data.result;
            return data;
        }
        return;
    } catch (e) {
        return;
    }
};

export const patch = async (
    data: Partial<IOrderManagerItem> & {
        reqOrderId: number;
    }
): Promise<IOrderManagerItem | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.patch(
            `${ApiUrl.OrderManager}/${data.reqOrderId}`,
            data,
            {
                withCredentials: true
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result;
                return data;
            }
        }
        return;
    } catch (e) {
        return;
    }
};

export const getReqOrderResults = async (
    data: Partial<{
        reqOrderId: number;
        patientId: number
        name: string
    }>
): Promise<ReqResultDto[] | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            `${ApiUrl.OrderResults}`,
            {
                withCredentials: true,
                params: {
                    ...data
                }
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result;
                return data;
            }
        }
        return;
    } catch (e) {
        return;
    }
};

export const getStatusLog = async ({ reqOrderId }: { reqOrderId: number }): Promise<undefined | IStatusLog[]> => {
    try {
        const response = await await APIHandler.AxiosInstance.get(
            `${ApiUrl.ReqOrders}/${reqOrderId}/statusLog`,
            {
                withCredentials: true,
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result;
                return data;
            }
        }
        return
    } catch (e) {
        return
    }
}

export const putReqOrderDetails = async (data: Partial<ReqResultDto> & { id: number }): Promise<ReqResultDto | undefined> => {
    try {
        const response = await await APIHandler.AxiosInstance.put(
            `${ApiUrl.reqOrderDetails}/${data.id}/`,
            data,
            {
                withCredentials: true,
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result;
                return data;
            }
        }
        return
    } catch (e) {
        return
    }
}

export const getReqBatch = async (data: { id: number }): Promise<ReqBatchDto | undefined> => {
    try {
        const response = await await APIHandler.AxiosInstance.get(
            `${ApiUrl.reqBatch}/${data.id}/`,
            {
                withCredentials: true,
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result[0];
                return data;
            }
        }
        return
    } catch (e) {
        return
    }
}

export const putReqBatch = async (data: ReqBatchDto): Promise<boolean | undefined> => {
    try {
        const response = await await APIHandler.AxiosInstance.put(
            `${ApiUrl.reqBatch}/${data.id}/`,
            data,
            {
                withCredentials: true,
            }
        );
        if (response.data) {
            if (response.data.success) {
                return true;
            }
        }
        return
    } catch (e) {
        return
    }
}

export const getFavorites = async (): Promise<IOrderManagerFavoriteSimpleDto[] | undefined> => {
    try {
        const response = await await APIHandler.AxiosInstance.get(
            `${ApiUrl.OrderManager}/favorites/`,
            {
                withCredentials: true,
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result;
                return data;
            }
        }
        return
    } catch (e) {
        return
    }
}

export const getFavoriteById = async ({ id }: { id: number }): Promise<IOrderManagerFavoriteExtendedDto | undefined> => {
    try {
        const response = await await APIHandler.AxiosInstance.get(
            `${ApiUrl.OrderManager}/favorites/${id}`,
            {
                withCredentials: true,
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result as IOrderManagerFavoriteExtendedDto;
                const query = _.cloneDeep(data.query);
                // Validations (removing unnessassary fields)
                ['ids', 'reqBatchIds', 'sortBy', 'sortType', 'startIndex'].forEach((key) => {
                    // @ts-ignore
                    delete query[key]
                })
                const validationData = _.cloneDeep(query);
                Object.entries(validationData).forEach(([key, value]) => {
                    if (value === null || value === undefined || value === '') {
                        delete query[key as keyof typeof query];
                        return;
                    }
                    if (Array.isArray(value)) {
                        if (value.length === 0) {
                            delete query[key as keyof typeof query];
                            return;
                        }
                    }
                });
                data.query = query
                // -----------------
                return data;
            }
        }
        return
    } catch (e) {
        return
    }
}

export const postFavorites = async (data: Partial<IOrderManagerFavoriteExtendedDto>): Promise<IOrderManagerFavoriteSimpleDto | undefined> => {
    try {
        const response = await await APIHandler.AxiosInstance.post(
            `${ApiUrl.OrderManager}/favorites/`,
            data,
            {
                withCredentials: true,
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result;
                return data;
            }
        }
        return
    } catch (e) {
        return
    }
}

export const putFavorites = async (data: IOrderManagerFavoriteSimpleDto): Promise<IOrderManagerFavoriteSimpleDto | undefined> => {
    try {
        const response = await await APIHandler.AxiosInstance.put(
            `${ApiUrl.OrderManager}/favorites/${data.id}`,
            data,
            {
                withCredentials: true,
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result;
                return data;
            }
        }
        return
    } catch (e) {
        return
    }
}
import moment from 'moment';
import _ from 'lodash';

import store from 'src/store';
import { actionsTypes, initialFilter } from './Reducer';
import { IFolderItem, IMessage, IPendingMessage } from './types';

import { IPagination } from 'src/Framework/Controls/Pagination/types';
import { setPreference } from 'src/Activities/Compliance/Store/ComplianceActions';
import { updateCurrentUserPreference } from 'src/App/Preferences/Actions';

import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { toISOLocalString } from 'src/Framework/util/format';

import * as utils from '../utils';
import { Alert } from 'src/Framework/Common/Notification';

const debounce = utils.debouncer(1000);

export const getCurrentFolderItem = (): IFolderItem | undefined => {
  const { messages, selectedCategory } = store.getState().messages;
  const item = messages.find((v: IFolderItem) => v.id === selectedCategory);
  return item;
};

export const checkEmptyFilter = (filter: any): boolean => {
  return _.isEqual(filter, initialFilter);
};

export const isFilterEmpty = (): boolean => {
  const filter = store.getState().messages.filter;
  return _.isEqual(filter, initialFilter);
};

export const disabledFilter = (field: string) => {
  const item = getCurrentFolderItem();
  if (Array.isArray(item?.filters?.[field])) {
    return item?.filters?.[field].length !== 0;
  }
  return item?.filters?.[field];
};

export const onChangeFilter = (payload: any) => {
  setFilter(payload);
  setPagination({ pageNumber: 0 });
};

export const onChangeCategory = (payload: string) => {
  setSelectedCategory(payload);
  setFilter(_.cloneDeep(initialFilter));
  setPagination({ pageNumber: 0 });
};

export const deleteLabels = async (messageLabelIds: Array<number>) => {
  try {
    const response = await APIHandler.AxiosInstance.delete(
      `${ApiUrl.GET_MESSAGES_LIST}labels`,
      {
        withCredentials: true,
        params: {
          messageLabelIds: messageLabelIds.join()
        }
      }
    );
    if (response.data.success) {
    }
  } catch (e) {
    console.log({ e });
  } finally {
  }
};

export const setReadReceipt = async (messageId: number) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.GET_MESSAGES_LIST}${messageId}/readReceipt`,
      {
        dts: toISOLocalString(new Date())
      },
      { withCredentials: true, interceptError: true }
    );
    if (response.data.success) {
      const message = response.data.result.summary.find(
        (v: any) => v.id == messageId
      );
      if (message) {
        putMessage(message);
      }
      return response.data;
    }
    return;
  } catch (e) {
    console.log({ e });
    return;
  } finally {
  }
};

export const getMessageStatuses = async () => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.GET_MESSAGE_STATUSES,
      { withCredentials: true }
    );
    const summary = response.data.result.summary;
    Object.keys(summary).map((key) => {
      if (!summary[key].isActive) {
        delete summary[key];
      }
    });
    setStatuses(summary);
    return response.data;
  } catch (e) {
    console.log({ e });
  } finally {
  }
};

const updateSelectedProvidersPreferences = async (value: any) => {
  const userId = localStorage.getItem('userId');
  const userPreferenceInfo = {
    userId,
    preferences: [
      {
        id: 3021,
        userId,
        name: 'MessagesSelectedProviders',
        value: JSON.stringify(value),
        defaultValue: ''
      }
    ]
  };
  try {
    const response = await setPreference(userPreferenceInfo);
    const data: any = response.data;
    if (data.success && data.result.success) {
      updateCurrentUserPreference(userPreferenceInfo.preferences);
    }
  } catch (error) {
    console.log({ error });
  }
};

export const setSelectedProviders = (payload: object[]) => {
  const data = payload.filter((v: any) => v && Object.keys(v).length !== 0);
  setProviders(data);
  debounce(() => {
    updateSelectedProvidersPreferences(data);
    setOpenedRows([]);
    getSecureMessages();
  });
};

export const getMessagesFolders = async () => {
  try {
    // const providerIds =
    //   store.getState().user?.preferences?.userProviderId?.value;
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.GET_MESSAGES_FOLDERS,
      {
        withCredentials: true,
        params: {
          // providerIds
        }
      }
    );
    if (response.data.success) {
      setMessages(response.data.result);
    }
  } catch (e) {
    console.log({ e });
  } finally {
  }
};

export const mainFilter = (
  array: Array<IMessage>,
  item: IFolderItem | undefined,
  filter: any,
  filterEmpty: boolean
) => {
  if (item) {
    return array.filter((value) => {
      // Message didn't delete
      if (value.isActive) {
        // In current folder
        const inCurrentFolder = value.folderIds.includes(item.id);
        if (inCurrentFolder) {
          // If filter enabled
          if (!filterEmpty) {
            let enable = true;
            // Search for each selected field
            Object.keys(filter).forEach((key: string) => {
              if (!enable) return;
              if (key === 'recipient' && filter.recipient) {
                enable = value.actors.receivers.find((v) => `${v.id}` === `${filter.recipient}`) ? true : false;
              }
              if (key === 'statuses' && filter.statuses.length !== 0) {
                enable = filter.statuses.includes(value.statusId);
              }
              if (key === 'priorities' && filter.priorities.length !== 0) {
                enable = filter.priorities.includes(value.priority);
              }
              if (key === 'text' && filter.text.length !== 0) {
                const sender = value.actors.sender.displayName.toLowerCase();
                const search = filter.text.toLowerCase();
                const receivers = value.actors.receivers.map((v) =>
                  v.displayName.toLowerCase()
                );
                enable =
                  value.subject.toLowerCase().includes(search) ||
                  sender.includes(search) ||
                  receivers.some((v) => v.includes(search));
              }
              if (key === 'after' && filter.after && value.dts) {
                const formattedValue = moment(value.dts).format('MM/DD/YYYY');
                enable = moment(formattedValue).isSameOrAfter(filter.after);
              }
              if (key === 'before' && filter.before && value.dts) {
                const formattedValue = moment(value.dts).format('MM/DD/YYYY');
                enable = moment(formattedValue).isSameOrBefore(filter.before);
              }
            });
            return enable;
          }
          return true;
        }
      }
      return false;
    });
  }
  return [...array];
};

export const getCurrentMessage = async (id: number) => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.GET_MESSAGES_LIST,
      {
        withCredentials: true,
        params: {
          'filters.Id': id,
          modelType: 'extended'
        }
      }
    );
    if (response.data.success) {
      const result = response.data.result.summary[0];
      if (result) {
        putMessage(result);
        return result;
      }
    }
  } catch (e) {
  } finally {
  }
};

export const getInternalMessages = async () => {
  try {
    setLoading(true);
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.GET_MESSAGES_LIST,
      {
        withCredentials: true,
        params: {
          'filters.messageType': 'internal',
          'filters.userId': store.getState().user.current.userId,
          modelType: 'simple' // extended
        }
      }
    );
    if (response.data.success) {
      setInternalMessages(
        response.data.result.summary.filter((v: any) => v.isActive)
      );
    }
  } catch (e) {
  } finally {
    setLoading(false);
  }
};

export const getSecureMessages = async (includeMyId: boolean = false) => {
  try {
    setLoadingSecure(true);
    let providerIds = store
      .getState()
      .messages.selectedProviders.map((v) => v.providerId)
      .join();
    if (includeMyId && providerIds.length === 0) {
      providerIds = store.getState().user.preferences.userProviderId?.value;
    }
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.GET_MESSAGES_LIST,
      {
        withCredentials: true,
        params: {
          'filters.providerIds': providerIds,
          'filters.messageType': 'secure',
          modelType: 'simple' // extended
        }
      }
    );
    if (response.data.success) {
      setSecureMessages(
        response.data.result.summary.filter((v: any) => v.isActive)
      );
    } else {
      setSecureMessages([])
    }
  } catch (e) {
  } finally {
    setLoadingSecure(false);
  }
};

export const replyMessage = async (id: number, body: any) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.POST_MESSAGE}${id}/reply`,
      body,
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data.success) {
      const data = response.data.result;
      if (data.type === utils.MESSAGE_TYPES.INTERNAL) {
        const messages = _.cloneDeep(
          store.getState().messages.internalMessages
        );
        messages.unshift(data);
        setInternalMessages(messages);
      } else {
        const messages = _.cloneDeep(store.getState().messages.secureMessages);
        messages.unshift(data);
        setSecureMessages(messages);
      }
      Alert('success', 'Success', 'Message(s) were sent successfully');
      return data;
    }
  } catch (e) {
    console.log({ e });
  } finally {
  }
};

export const postMessage = async (body: any) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.POST_MESSAGE,
      [{ ...body, dts: toISOLocalString(new Date()) }],
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data.success) {
      const prevData = [...store.getState().messages.internalMessages];
      const result = response.data.result.summary;
      setInternalMessages([...result, ...prevData]);
      const message = result[0];
      Alert('success', 'Success', 'Message(s) were sent successfully');
      return message;
    }
  } catch (e) {
    console.log({ e });
  } finally {
  }
};

export const patchMessage = async (id: any, body: any, prevMessageId?: number) => {
  try {
    const { selectedCategory } = store.getState().messages;
    const split = selectedCategory.split(':');
    const data = {
      dts: toISOLocalString(new Date()),
      channel: split[0],
      ...body
    };
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.PATCH_MESSAGE}${id}`,
      data,
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data.success) {
      const message: IMessage = response.data.result
      putMessage(message, prevMessageId);
      return message;
    }
    return
  } catch (e) {
    console.log({ e });
  } finally {
  }
  return
};

export const putMessage = (result: IMessage, prevIndex?: number) => {
  if (result.type === utils.MESSAGE_TYPES.INTERNAL) {
    const messages = _.cloneDeep(store.getState().messages.internalMessages);
    const index = messages.findIndex((v) => v.id === result.id);
    if (index !== -1) {
      messages[index] = result;
      if (prevIndex !== undefined) {
        const pendingMessage: IPendingMessage = {
          ...result,
          prevIndex,
        }
        setInternalMessages(messages, pendingMessage);
      } else {
        setInternalMessages(messages);
      }
    }
  } else {
    const messages = _.cloneDeep(store.getState().messages.secureMessages);
    const index = messages.findIndex((v) => v.id === result.id);
    if (index !== -1) {
      messages[index] = result;
      if (prevIndex !== undefined) {
        const pendingMessage: IPendingMessage = {
          ...result,
          prevIndex: prevIndex,
        }
        setSecureMessages(messages, pendingMessage);
      } else {
        setSecureMessages(messages);
      }
    }
  }
};

export const removeMessage = async (id: any) => {
  const response = await patchMessage(id, {
    isEnabled: false
  });
  if (response) {
    return response;
  }
  return
};

export const putFollowUp = async (messageId: number, data: any) => {
  try {
    const response = await APIHandler.AxiosInstance.put(
      `${ApiUrl.PUT_FOLLOW_UP}${messageId}/followup`,
      {
        dts: toISOLocalString(new Date()),
        ...data
      },
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data.success) {
      putMessage(response.data.result);
    }
  } catch (e) {
    console.log({ e });
  }
};

export const setPagination = (payload: Partial<IPagination>) => {
  store.dispatch({
    type: actionsTypes.SET_PAGINATION,
    payload
  });
};

const setSecureMessages = (payload: IMessage[], pendingMessage?: IPendingMessage) => {
  store.dispatch({
    type: actionsTypes.SET_SECURE_MESSAGES,
    payload,
    pendingMessage
  });
};

const setInternalMessages = (payload: IMessage[], pendingMessage?: IPendingMessage) => {
  store.dispatch({
    type: actionsTypes.SET_INTERNAL_MESSAGES,
    payload,
    pendingMessage
  });
};

const setMessages = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET,
    payload
  });
};

export const setProviders = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_SELECTED_PROVIDERS,
    payload
  });
};

const setFilter = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_FILTER,
    payload
  });
};

const setSelectedCategory = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_SELECTED_CATEGORY,
    payload
  });
};

export const setAllProviders = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_PROVIDERS,
    payload
  });
};

export const setLoading = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_LOADING,
    payload
  });
};

export const setLoadingSecure = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_LOADING_SECURE,
    payload
  });
};

export const setLoadingMore = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_LOADING_MORE,
    payload
  });
};

export const setFilteredList = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_FILTERED_LIST,
    payload
  });
};

export const setOpenedRows = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_OPENED_ROWS,
    payload
  });
};

export const setStatuses = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_STATUSES,
    payload
  });
};

import React, { FC, useState } from 'react';
import { ITableGroupMultiSelect } from './types';
import DropdownRender from './DropdownRender';
import SelectContainer, { ISelect } from '../../SelectContainer';

import { Tag } from './styled';
import EllipsisText from '../../EllipsisText';

interface IProps
  extends Omit<ISelect, 'onChange' | 'options' | 'value' | 'type'>,
    ITableGroupMultiSelect {}

const Component: FC<IProps> = ({
  groupsOptions: groups,
  childrenOptions: children,
  onChange,
  value,
  ...props
}: IProps) => {
  const [isPopupVisible, setPopupVisible] = useState<boolean>(false);
  const selectedOptions = value
    .map((v) => {
      return children.find((val) => val.id === v)?.label;
    })
    .filter((v) => v);
  return (
    <SelectContainer
      {...props}
      type="select"
      value={value}
      onChange={() => {}}
      options={
        children
          .filter((v) => v.isActive)
          .map((v) => ({
            value: v.id,
            label: v.label
          })) as any
      }
      allOptions={
        children.map((v) => ({
          value: v.id,
          label: v.label
        })) as any
      }
      checkboxOptions={true}
      inputProps={{
        ...props?.inputProps,
        showArrow: true,
        mode: 'multiple',
        showSearch: false,
        menuItemSelectedIcon: '',
        open: isPopupVisible,
        tagRender: (props: any) => {
          if (value.length > 0 && value[0] === props.value) {
            return (
              <Tag>
                <EllipsisText>{selectedOptions.join(', ')}</EllipsisText>
              </Tag>
            );
          }
          return null;
        },
        onDropdownVisibleChange: (open: boolean) => {
          setPopupVisible(open);
        },
        dropdownRender: () => (
          <DropdownRender
            value={value}
            groupsOptions={groups}
            childrenOptions={children}
            onChange={onChange}
          />
        )
      }}
    />
  );
};

export default React.memo(Component);

import { store } from 'src';
import { permissionChecking } from '../User/Permission';
import * as medicatSystemPrefsGetters from 'src/App/Settings/store/Actions/Getters';
import { PortalOptionGroup } from './Pages/PatientPortalSetup/store/types';
import { getDisclaimerStatus } from './Pages/PatientPortalSetup/store/utils';
import { checkIsOptionEnabled, isLabsEnabled, isPointOfCareEnabled } from '../Settings/utils';
import { transactionCodes } from './utils';

export interface IPermissionResult {
  success: boolean;
  accessMessage: string;
}

export const securityPermission = (): IPermissionResult => {
  if (checkIsOptionEnabled('m1EnableSecurity')) {
    return {
      success: true,
      accessMessage: ''
    }
  }
  return {
    success: false,
    accessMessage: 'Security hidden'
  }
}

export const counselingPermission = (): IPermissionResult => {
  const { clinicId } = store.getState().user.current;
  const permission = permissionChecking({
    anyClinic: false,
    clinicId: +clinicId,
    moduleId: 103,
    functionId: 23
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const planPermission = (): IPermissionResult => {
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 8,
    functionId: 1
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const providerTypesPermission = (): IPermissionResult => {
  const permission1 = permissionChecking({
    anyClinic: true,
    moduleId: 15,
    functionId: 1
  });
  const permission2 = permissionChecking({
    anyClinic: true,
    moduleId: 15,
    functionId: 2
  });
  return {
    success: permission1.success || permission2.success,
    accessMessage: permission1.message || permission2.message
  };
};

export const supervisionTrainee = (): IPermissionResult => {
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 108,
    functionId: 23
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const supervisionNoteTypes = (): IPermissionResult => {
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 108,
    functionId: 1206
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const formBuilderPermission = (): IPermissionResult => {
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 107,
    functionId: 23
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const ordersManagerPermission = (): IPermissionResult => {
  if (!pointOfCarePermission().success && !labsPermission().success) {
    return {
      success: false,
      accessMessage: ''
    }
  }
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 48,
    functionId: 1
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const ordersManagerEditPermission = (): IPermissionResult => {
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 48,
    functionId: 2
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const ruleManagerPermission = (): IPermissionResult => {
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 88,
    functionId: 23
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const dropdownManagement = (): IPermissionResult => {
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 81,
    functionId: 2
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };

  // const medicatDemo = store.getState().genericTypes.summary.medicatDemo
  // const item = medicatDemo.find((v) => v.key === "Admin:HideDropdownManagement")
  // const message = 'Dropdown Management hidded in medicat demo'
  // if (item) {
  //   return {
  //     success: item.value === "False",
  //     accessMessage: message
  //   }
  // }
  // return {
  //   success: false,
  //   accessMessage: message
  // }
};

export const providersPermission = (): IPermissionResult => {
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 15,
    functionId: 1
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const usersPermission = (): IPermissionResult => {
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 2,
    functionId: 12
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const txnCodePermission = (): IPermissionResult => {
  // const medicatDemo = store.getState().genericTypes.summary.medicatDemo;
  // const item = medicatDemo.find((v) => v.key === 'Admin:TxnCodes');
  // const message = 'TxnCodes hidded in medicat demo';
  // if (item) {
  //   const success = item.value === 'False';
  //   if (!success) {
  //     return {
  //       success: success,
  //       accessMessage: message
  //     };
  //   }
  // }
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 8,
    functionId: 1
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};
export const txnSetsPermission = (): IPermissionResult => {
  const needToHide =
    store.getState().settings?.data?.medicatSystemPrefs
      ?.hideAdminTransactionSets;
  if (needToHide) {
    return {
      success: false,
      accessMessage: 'Transaction Sets are hidden in Medicat Demo'
    };
  }
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 8,
    functionId: 1
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const diagnosisCodesPermission = (): IPermissionResult => {
  // const medicatDemo = store.getState().genericTypes.summary.medicatDemo;
  // const item = medicatDemo.find((v) => v.key === 'Admin:Diagnosis');
  // const message = 'Diagnosis hidded in medicat demo';
  // if (item) {
  //   return {
  //     success: item.value === 'False',
  //     accessMessage: message
  //   };
  // }
  // return {
  //   success: false,
  //   accessMessage: message
  // };
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 7,
    functionId: 1
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const patientPortalSetupPermission = (): IPermissionResult => {
  const success =
    medicatSystemPrefsGetters.data().medicatSystemPrefs?.m1EnableMppSetup ||
    false;
  if (!success) {
    return {
      success: false,
      accessMessage: 'Patient Portal Setup are hidden in Medicat Demo'
    };
  }
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 36,
    functionId: 1
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};
export const limitChartAccessPermission = (): IPermissionResult => {
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 2,
    functionId: 235
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const diagnosisCodesByClinicPermission = (): IPermissionResult => {
  // const medicatDemo = store.getState().genericTypes.summary.medicatDemo;
  // const item = medicatDemo.find((v) => v.key === 'Admin:Diagnosis');
  // const message = 'Diagnosis hidded in medicat demo';
  // if (item) {
  //   return {
  //     success: item.value === 'False',
  //     accessMessage: message
  //   };
  // }
  // return {
  //   success: false,
  //   accessMessage: message
  // };
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 7,
    functionId: 1300
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const additionalInfoPermission = () => {
  const permission = permissionChecking({
    anyClinic: true,
    moduleId: 4,
    functionId: 318
  });
  return {
    success: permission.success,
    accessMessage: permission.message
  };
};

export const disclaimerPermission = () => {
  const data = getDisclaimerStatus();
  if (data?.isAccepted) {
    return {
      success: false,
      accessMessage: 'Disclaimer accepted'
    };
  }
  return {
    success: true,
    accessMessage: ''
  };
};

export const pointOfCarePermission = (): IPermissionResult => {
  const success = isPointOfCareEnabled();
  if (!success) {
    return {
      success: false,
      accessMessage: 'Point of care are hidden in Medicat Demo'
    };
  }
  return txnCodePermission()
};

export const labsPermission = (): IPermissionResult => {
  const success = isLabsEnabled();
  if (!success) {
    return {
      success: false,
      accessMessage: 'Labs are hidden in Medicat Demo'
    };
  }
  return txnCodePermission()
};

export const pptSetupPermissions: {
  [key in PortalOptionGroup]?: () => IPermissionResult;
} = {
  [PortalOptionGroup.LabResults]: () => {
    const success =
      medicatSystemPrefsGetters.data().medicatSystemPrefs?.mppLabResults ||
      false;
    if (!success) {
      return {
        success: false,
        accessMessage: 'Lab Results are hidden in Medicat Demo'
      };
    }
    return {
      success: true,
      accessMessage: ''
    };
  },
  [PortalOptionGroup.Immunization]: () => {
    const success =
      medicatSystemPrefsGetters.data().medicatSystemPrefs
        ?.mppImmunizationManagement || false;
    if (!success) {
      return {
        success: false,
        accessMessage: 'Immunization are hidden in Medicat Demo'
      };
    }
    return {
      success: true,
      accessMessage: ''
    };
  },
  [PortalOptionGroup.Insurance]: () => {
    const success =
      medicatSystemPrefsGetters.data().medicatSystemPrefs
        ?.mppInsuranceManagement || false;
    if (!success) {
      return {
        success: false,
        accessMessage: 'Insurance are hidden in Medicat Demo'
      };
    }
    return {
      success: true,
      accessMessage: ''
    };
  },
  [PortalOptionGroup.Education]: () => {
    const success =
      medicatSystemPrefsGetters.data().medicatSystemPrefs
        ?.mppPatientEducation || false;
    if (!success) {
      return {
        success: false,
        accessMessage: 'Education are hidden in Medicat Demo'
      };
    }
    return {
      success: true,
      accessMessage: ''
    };
  },
  [PortalOptionGroup.SecureMessage]: () => {
    const success =
      medicatSystemPrefsGetters.data().medicatSystemPrefs?.mppSecureMessaging ||
      false;
    if (!success) {
      return {
        success: false,
        accessMessage: 'Secure Message are hidden in Medicat Demo'
      };
    }
    return {
      success: true,
      accessMessage: ''
    };
  },
  [PortalOptionGroup.Statements]: () => {
    const success =
      medicatSystemPrefsGetters.data().medicatSystemPrefs?.mppStatements ||
      false;
    if (!success) {
      return {
        success: false,
        accessMessage: 'Statements are hidden in Medicat Demo'
      };
    }
    return {
      success: true,
      accessMessage: ''
    };
  },
  [PortalOptionGroup.VisitPublish]: () => {
    const success =
      medicatSystemPrefsGetters.data().medicatSystemPrefs?.mppVisitPublish ||
      false;
    if (!success) {
      return {
        success: false,
        accessMessage: 'Visit Publish are hidden in Medicat Demo'
      };
    }
    return {
      success: true,
      accessMessage: ''
    };
  }
};

export const getAdminTxnCodeClasses = () => {
  const arr = Object.entries(transactionCodes).sort((a, b) => a[0].localeCompare(b[0])).filter(([key, v]) => v.permission && v.permission().success).map(([key, v]) => v.typeTxnClass)
  return arr
}
import React, { FC, Fragment, useRef, useState, useMemo } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import Tooltip from 'src/Framework/Controls/Tooltip';
import { getClinicById } from 'src/Framework/Controls/Selectors/ClinicSelector';
import { debouncer } from 'src/Framework/util/helps';
import { getMilitaryTimeFromStandart } from 'src/Framework/Shared/Shared';

import { ScheduleTimeProps } from 'src/Activities/Schedule/Common/Interfaces';
import RClickPopup from 'src/Activities/Schedule/Popups/RClickPopup';
import {
  getTimeObjectStringFromSimpleTime,
  timeDiff
} from 'src/Activities/Schedule/Utils/Utils';
import AppointmentPopup, {
  IModalHandles
} from 'src/Activities/Schedule/Popups/AppointmentPopup/Container';
import EventPopup, {
  IEventModalHandles
} from 'src/Activities/Schedule/Popups/EventPopup';
import BlockReservationPopup, {
  IModalHandles as IBlockReservation
} from 'src/Activities/Schedule/Popups/BlockReservationPopup/Container';
import Item from './Item';
import OutreachModal, {
  IModalHandles as IModalOutreachHandles
} from 'src/Activities/Home/Outreach/Modal';
import {
  eventPermission,
  EEventTypeCode,
  EApptTypeCode
} from 'src/Activities/Schedule/utils';
import { permissionChecking } from 'src/App/User/Permission';

import { RangeTime, RangeTimeContainer } from '../ScheduleCol_Styles';

interface IProps {
  value: any;
  scheduleTimeProps: ScheduleTimeProps;
  scheduleCol: any;
  isWidget?: boolean;
  onDropWithWaitingPatient: Function;
  onDrop: Function;
}

//@ts-ignore
const moment = extendMoment(Moment);

export const isInTimeRange = (
  clinicId: number,
  startTime: string,
  endTime: string
) => {
  const clinic = getClinicById(clinicId);
  if (clinic) {
    const start = moment(clinic.startTime).format('HH:mm');
    const end = moment(clinic.endTime).format('HH:mm');
    const beginningTime = moment(startTime, 'HH:mm');
    const clinicBeginningTime = moment(start, 'HH:mm');
    const endingTime = moment(endTime, 'HH:mm');
    const clinicEndingTime = moment(end, 'HH:mm');
    if (
      (beginningTime.isBetween(clinicBeginningTime, clinicEndingTime) ||
        beginningTime.isSame(clinicBeginningTime)) &&
      (endingTime.isBetween(clinicBeginningTime, clinicEndingTime) ||
        endingTime.isSame(clinicEndingTime))
    ) {
      return true;
    }
  }
  return false;
};

const Component: FC<IProps> = (props: IProps) => {
  const debounce = useRef(debouncer(150));

  // If popover state will be inside than we don't have ability to control it in this component, we need to control it for doubleClick logic
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverOpenLittle, setPopoverOpenLittle] = useState(false);

  const OutreachPopupRef = useRef<IModalOutreachHandles>(null);
  const ApptPopupRef = useRef<IModalHandles>(null);
  const EventPopupRef = useRef<IEventModalHandles>(null);
  const BlockReservationRef = useRef<IBlockReservation>(null);
  const {
    value,
    scheduleTimeProps,
    scheduleCol,
    isWidget,
    onDropWithWaitingPatient
  } = props;

  const anyEventPermission = useMemo(
    () =>
      eventPermission(EEventTypeCode.appointment, EApptTypeCode.individual)
        .success ||
      eventPermission(EEventTypeCode.appointment, EApptTypeCode.couple)
        .success ||
      eventPermission(EEventTypeCode.appointment, EApptTypeCode.family)
        .success ||
      eventPermission(EEventTypeCode.appointment, EApptTypeCode.group)
        .success ||
      eventPermission(EEventTypeCode.staffAppointment).success ||
      eventPermission(EEventTypeCode.outreach).success ||
      permissionChecking({
        anyClinic: false,
        clinicId: scheduleCol.clinicId,
        moduleId: 1,
        functionId: 4
      }).success,
    [scheduleCol.clinicId]
  );

  const { dayStartTime, regularMeetingTime } = scheduleTimeProps;
  const emptyCell =
    value &&
    value.spaceExists[1] &&
    value.spaceExists[2] &&
    value.patients.length === 0;
  const y =
    (timeDiff(value.startTime, dayStartTime) / regularMeetingTime) *
    scheduleTimeProps.slotHeight;
  const emptyRegion = {
    ...value,
    duration: regularMeetingTime
  };
  const getReservation = (emptyRegion: any) => {
    const { date, scheduleGroups, providerCode } = scheduleCol;
    return scheduleGroups.find((s: any) => {
      if (providerCode.toLowerCase() === s.provider.code.toLowerCase()) {
        const resStartTime = moment(
          getTimeObjectStringFromSimpleTime(s.startTime, date)
        );
        const resEndTime = moment(
          getTimeObjectStringFromSimpleTime(s.endTime, date)
        );
        const resRange = moment.range(resStartTime, resEndTime);
        const regionStartTime = moment(
          getTimeObjectStringFromSimpleTime(emptyRegion.startTime, date)
        );
        if (
          resRange.contains(regionStartTime, {
            excludeStart: false,
            excludeEnd: true
          })
        ) {
          return s;
        }
      }
      return false;
    });
  };
  const reservation = getReservation(emptyRegion);

  const onOpenSingleAppt = () => {
    const apptPermission = eventPermission(EEventTypeCode.appointment);
    if (!apptPermission.success) {
      return;
    }

    const options: any = {
      action: inReservaton ? 'reservedAppt' : '',
      providerCode: scheduleCol.providerCode,
      providerName: scheduleCol.providerName,
      clinicName: scheduleCol.clinicName,
      clinicCode: scheduleCol.clinicCode,
      startTime: emptyRegion.startTime,
      endTime: emptyRegion.endTime,
      scheduleEventTypeId: 1,
      typeAppointmentParticipantId: apptPermission.firstAvailableOption,
      uid: '',
      reason: reservation?.reason,
      date: emptyRegion.date
    };
    ApptPopupRef.current?.show({
      options
    });
  };
  const onOpenEventPopup = () => {
    const options: any = {
      action: '',
      providerCode: scheduleCol.providerCode,
      providerName: scheduleCol.providerName,
      clinicName: scheduleCol.clinicName,
      clinicCode: scheduleCol.clinicCode,
      startTime: emptyRegion.startTime,
      endTime: emptyRegion.endTime,
      scheduleEventTypeId: 7,
      typeAppointmentParticipantId: 1,
      uid: '',
      reason: reservation?.reason,
      date: emptyRegion.date
    };
    EventPopupRef.current?.show({
      options
    });
  };
  const commons = {
    title: '',
    y: y,
    height: scheduleTimeProps.slotHeight,
    onDoubleClick: () => onOpenSingleAppt(),
    regularMeetingTime,
    startTime: value.startTime,
    endTime: value.endTime,
    clinicId: scheduleCol.clinicId
  };
  const onDrop = (value: any, delta: any, clientOffset: any) => {
    props.onDrop(value, commons.startTime, clientOffset);
  };
  const inReservaton = reservation ? true : false;
  const outOfTimeRange = !isInTimeRange(
    commons.clinicId,
    commons.startTime,
    commons.endTime
  );

  const inner = ({ littlePart }: { littlePart?: boolean }) => (
    <RClickPopup
      popoverOpen={littlePart ? popoverOpenLittle : popoverOpen}
      onVisibleChanged={(value) => {
        if (!value) {
          if (littlePart) {
            setPopoverOpenLittle(anyEventPermission && value);
          } else {
            setPopoverOpen(anyEventPermission && value);
          }
        }
      }}
      onOpenOutreach={() => {
        OutreachPopupRef.current?.show({
          calendar: {
            clinicId: commons.clinicId,
            startTime: commons.startTime,
            endTime: commons.endTime,
            date: emptyRegion.date
          }
        });
      }}
      outOfTimeRange={outOfTimeRange}
      isWidget={isWidget}
      scheduleCol={scheduleCol}
      inReservaton={reservation ? true : false}
      reservedReason={reservation}
      startTime={emptyRegion.startTime}
      onClosePopup={() => {}}
      onOpenBlockPopup={() => {
        const options: any = {
          action: '',
          providerCode: scheduleCol.providerCode,
          providerName: scheduleCol.providerName,
          clinicName: scheduleCol.clinicName,
          clinicCode: scheduleCol.clinicCode,
          startTime: emptyRegion.startTime,
          endTime: emptyRegion.endTime,
          scheduleEventTypeId: 2,
          uid: '',
          date: emptyRegion.date,
          reason: reservation?.reason
        };
        BlockReservationRef.current?.show({
          options
        });
      }}
      onOpenGroupApptPopup={(typeAppointmentParticipantId: number) => {
        const options: any = {
          scheduleEventTypeId: 1,
          action: inReservaton ? 'reservedAppt' : '',
          providerCode: scheduleCol.providerCode,
          providerName: scheduleCol.providerName,
          clinicName: scheduleCol.clinicName,
          clinicCode: scheduleCol.clinicCode,
          startTime: emptyRegion.startTime,
          endTime: emptyRegion.endTime,
          typeAppointmentParticipantId,
          uid: '',
          reason: reservation?.reason,
          date: emptyRegion.date
        };
        ApptPopupRef.current?.show({
          options
        });
      }}
      onOpenIndividualApptPopup={() => onOpenSingleAppt()}
      onOpenEventPopup={() => onOpenEventPopup()}
      onOpenReservationPopup={() => {
        const options: any = {
          action: '',
          providerCode: scheduleCol.providerCode,
          providerName: scheduleCol.providerName,
          clinicName: scheduleCol.clinicName,
          clinicCode: scheduleCol.clinicCode,
          startTime: emptyRegion.startTime,
          endTime: emptyRegion.endTime,
          scheduleEventTypeId: 3,
          date: emptyRegion.date,
          uid: '',
          reason: reservation?.reason
        };
        BlockReservationRef.current?.show({
          options
        });
      }}
    >
      <Tooltip
        mouseEnterDelay={0.6}
        title={outOfTimeRange ? 'Out of clinic working time' : undefined}
      >
        <RangeTimeContainer
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            debounce.current(() => {
              if (e?.detail === 1) {
                if (littlePart) {
                  setPopoverOpenLittle(anyEventPermission && true);
                } else {
                  setPopoverOpen(anyEventPermission && true);
                }
              }
              if (e?.detail === 2) {
                onOpenSingleAppt();
              }
            });
          }}
        >
          {!littlePart && (
            <RangeTime className="range-time">{`${getMilitaryTimeFromStandart(
              commons.startTime
            )} - ${getMilitaryTimeFromStandart(commons.endTime)}`}</RangeTime>
          )}
        </RangeTimeContainer>
      </Tooltip>
    </RClickPopup>
  );
  return (
    <Fragment>
      {emptyCell && (
        <Item
          popoverOpen={popoverOpen}
          x={0}
          width={0.925}
          commons={commons}
          onDropWithWaitingPatient={onDropWithWaitingPatient}
          onDrop={onDrop}
          inner={inner({})}
          outOfTimeRange={outOfTimeRange}
        />
      )}
      <Item
        popoverOpen={popoverOpen}
        x={0.925}
        width={0.075}
        commons={commons}
        onDropWithWaitingPatient={onDropWithWaitingPatient}
        onDrop={onDrop}
        inner={inner({ littlePart: true })}
        outOfTimeRange={outOfTimeRange}
      />
      <AppointmentPopup ref={ApptPopupRef} />
      <EventPopup ref={EventPopupRef} />
      <BlockReservationPopup ref={BlockReservationRef} />
      <OutreachModal ref={OutreachPopupRef} />
    </Fragment>
  );
};

export default React.memo(Component);

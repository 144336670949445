import styled from 'styled-components';

export const gridGap = 18;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 250px;
  max-width: 100%;
  overflow: hidden;
  padding: ${gridGap}px ${gridGap / 2}px 0 ${gridGap / 2}px;
`;

export const InnerContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  border: 0.5px solid #e6e6e6;
  position: relative;
  overflow: hidden;
  background-color: white;
`;

import React from 'react';
import moment from 'moment';
import { Tooltip, Row as AntdRow, Col, Space } from 'antd';

import EditButton from 'src/Framework/Controls/Table/EditButton';
import RemoveButton from 'src/Framework/Controls/Table/RemoveButton';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import ReasonSelector from 'src/Framework/Controls/Selectors/ReasonSelector';
import { providerDisplayName } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import FWTooltip from 'src/Framework/Controls/Tooltip';
import Button from 'src/Framework/Controls/Button';
import Collapse from 'src/Framework/Controls/CollapseA11y';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import Icon from 'src/Framework/Controls/Icon';
import NoData from 'src/Framework/Controls/Table/NoData';
import { formattedDate, formattedDateTime } from 'src/Framework/Shared/Shared';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { patientDisplayName } from 'src/Framework/util/format';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import store from 'src/store';
import test_ids from 'src/tests-script/pages/Schedule/GroupApptPopup';

import { Row2 as Row, Info } from 'src/Activities/Schedule/Common/Classes';
import { buildRecurrenceString } from 'src/Activities/Schedule/Utils/Utils';
import * as scheduleEventActions from 'src/Activities/Schedule/Store/ScheduleEventsReducer/Actions';
import { RecurrenceDisplay } from 'src/Activities/Schedule/Common/Editors/RecurrenceDisplay';
import {
  IApptResponse,
  IPatientInfo
} from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import AdditionalContacts from 'src/Activities/Schedule/AdditionalContacts';
import { permissionChecking } from 'src/App/User/Permission';
import SecureMessage, {
  IModalHandles
} from 'src/Activities/Schedule/RightSideBar/WaitingList/Group/GroupItem/SecureMessage';

import { IAlertsData } from '../AppointmentPopup/AppointmentPopup';
import AlertsManageContainer from '../AppointmentPopup/AlertsManageContainer';
import AlertsBar, { calculateAlertsCount } from '../AppointmentPopup/AlertsBar';
import PatientDetail from 'src/Activities/Schedule/Common/Editors/PatientDetail';

import AppointmentHistoryDetail from './AppointmentHistoryDetail';
import EditRClickPopup from '../ScheduleDetailPopup/EditRClickPopup';
import TelehealthDetails from './TelehealthDetails';
import GroupNotesDetail from './GroupNotesDetail';
import GroupMembersDetail from './GroupMembersDetail';
import CancelledMembers from './CancelledMembers';
import { EEventTypeCode, eventPermission } from 'src/Activities/Schedule/utils';

import {
  PopupHeader,
  PopupBody,
  PopupTitle,
  DeleteIconContainer,
  EditIconContainer,
  FieldValue,
  IconGroupContainer,
  MainBody,
  InfoWrapper
} from './ScheduleGroupPopup_Styles';
import {
  ButtonContainer,
  ButtonSpan
} from 'src/Activities/Subject/Facts/Facts/styles';
import { ButtonWrapper } from 'src/Activities/SystemSetup/NotificationManager/NotificationViewer/NotificationViewer_Styles';
import { ReasonSelectContainer } from '../AppointmentPopup/AppointmentPopup_Styles';
import { ErrorBlock } from '../BlockReservationPopup/BlockReservationPopup_Styles';
import {
  CancelConfirmBlock,
  CancelContentBlock,
  CancelPopupContainer,
  NoteContainer,
  AppointmentRemovalBlock,
  PreferedPhoneContainer,
  MessageBlock,
  AppointmentInfoBlock,
  InsuranceContainer,
  OtherIDContainer,
  AppointmentRemovalTitle,
  ProviderSpan,
  PopupContainer
} from '../ScheduleDetailPopup/ScheduleDetailPopup_Styles';
import { TextEllipsisSpan } from 'src/Activities/Schedule/Main/ScheduleCol/ScheduleOne/styled';
import { FormSpan } from './GroupMembersDetail/styled';
import { ContainerBlock } from 'src/Activities/Schedule/Popups/ScheduleDetailPopup/styled';
import LocationInfo from 'src/Activities/Schedule/Common/LocationInfo';

const { Panel } = Collapse;

interface IState {
  showRClickPopup: string;
  isLoading: boolean;
  cancelPopup: boolean;
  editSeries: boolean;
  cancellationReasonCode: any;
  cancellationError: boolean;
  cancellationNote: string;
  currentPatient: IPatientInfo | null;
  data: IApptResponse;
  activePanels: string[] | undefined;
  alertsData: IAlertsData;
  statusUpdating: boolean;
}

export interface IProps {
  onDelete: any;
  onClosePopup: () => void;
  onEditSchedule: (appointmentId: number, series?: boolean) => void;
  data: IApptResponse;
  setRecordChanged?: () => void;
  callerRef?: React.MutableRefObject<any>;
  onClickAlertsView?: (data: IAlertsData) => any;
}

class ScheduleGroupPopup extends React.Component<IProps, IState> {
  componentRef: React.RefObject<any>;
  secureMessageRef: React.RefObject<IModalHandles>;
  editButtonRef: React.RefObject<any>;
  deleteButtonRef: React.RefObject<any>;

  constructor(props: IProps) {
    super(props);
    this.componentRef = React.createRef();
    this.secureMessageRef = React.createRef();
    this.editButtonRef = React.createRef();
    this.deleteButtonRef = React.createRef();
    this.state = {
      data: props.data,
      isLoading: false,
      showRClickPopup: '',
      cancelPopup: false,
      cancellationReasonCode: null,
      cancellationError: false,
      editSeries: false,
      cancellationNote: '',
      currentPatient: null,
      activePanels: ['appt_members', 'appointment_providers'],
      alertsData: {
        patientAlerts: {},
        insurances: {},
        isUnderAge: {},
        patients: []
      },
      statusUpdating: false
    };
  }

  componentDidMount = () => {
    if (this.componentRef.current) {
      (this.componentRef.current as HTMLElement).focus();
    }
  };

  onClickEditIcon = () => {
    const { data } = this.state;
    if (data.scheduleEvent.inSeries) {
      this.showRClickPopup('Edit');
    } else {
      this.props.onEditSchedule(data.scheduleEvent.appointmentId);
      this.props.onClosePopup();
    }
  };
  onClickDeleteIcon = () => {
    const { data } = this.state;
    if (data.scheduleEvent.inSeries) {
      this.showRClickPopup('Remove');
    } else {
      this.deleteConfirmation();
    }
  };
  showRClickPopup = (type: 'Edit' | 'Remove') => {
    this.setState({
      showRClickPopup: type
    });
  };

  deleteConfirmation = () => {
    const { data } = this.state;
    const { comment } = data.scheduleEvent;
    this.setState({
      cancelPopup: true,
      cancellationNote: comment
    });
  };

  onClose = () => {
    if (this.state.showRClickPopup === 'Edit') {
      if (this.editButtonRef.current) {
        //@ts-ignore
        this.editButtonRef.current.focus();
      }
    } else if (this.state.showRClickPopup === 'Remove') {
      if (this.deleteButtonRef.current) {
        //@ts-ignore
        this.deleteButtonRef.current.focus();
      }
    }
    this.setState({
      showRClickPopup: ''
    });
  };

  editSeries = () => {
    let { showRClickPopup, data } = this.state;
    // const eventInfo =
    //   store.getState().scheduleEvents[data.scheduleEvent.appointmentId];
    // if (eventInfo) {
    //   eventInfo.updateSeries = true;
    // }
    if (showRClickPopup === 'Remove') {
      this.deleteConfirmation();
    } else if (showRClickPopup === 'Edit') {
      this.props.onEditSchedule(data.scheduleEvent.appointmentId, true);
      this.props.onClosePopup();
    }
    this.setState({
      showRClickPopup: '',
      editSeries: true
    });
  };

  onClickDelete = async () => {
    const { data } = this.state;
    let { scheduleEvent } = data;
    let { editSeries, cancellationNote, cancellationReasonCode } = this.state;
    let details = {
      cancelNote: cancellationNote,
      cancelReasonCode: cancellationReasonCode?.value,
      appointmentId: scheduleEvent.appointmentId,
      subjectId: data.patients[0],
      scheduleEventTypeId: 1,
      cancelSeries: editSeries,
      cancelGroup: true,
      cancelDate:
        moment(scheduleEvent.scheduleEventDateTime).format('YYYY-MM-DD') +
        'T00:00:00.000'
    };
    if (details.cancelReasonCode) {
      await this.props.onDelete(details);
      this.props.onClosePopup();
    } else {
      this.setState({
        cancellationError: true
      });
    }
  };
  editSingle = () => {
    let { showRClickPopup, data } = this.state;
    const eventInfo =
      store.getState().scheduleEvents[data.scheduleEvent.appointmentId];
    if (eventInfo) {
      eventInfo.updateSeries = false;
    }
    if (showRClickPopup === 'Remove') {
      this.deleteConfirmation();
    } else if (showRClickPopup === 'Edit') {
      this.props.onEditSchedule(data.scheduleEvent.appointmentId);
      this.props.onClosePopup();
    }
    this.setState({
      showRClickPopup: '',
      editSeries: false
    });
  };

  onSelectCancellationReason = (value: any) => {
    this.setState({
      cancellationReasonCode: { value },
      cancellationError: false
    });
  };

  canDeleteGroup = () => {
    const { data } = this.state;
    return !Object.values(data.patientStatus).some((v) => v.checkedIn);
  };

  selectPatient = (patient: number, currentPatient: IPatientInfo) => {
    this.setState({
      currentPatient,
      activePanels: this.state.activePanels?.concat(['appt_history'])
    });
  };

  onDeleteMember = async (patient: IPatientInfo, otherDetails?: any) => {
    const { data } = this.state;
    const details = {
      subjectId: patient.subjectId,
      appointmentId: data.scheduleEvent.appointmentId,
      cancelDate:
        moment(data.scheduleEvent.scheduleEventDateTime).format('YYYY-MM-DD') +
        'T00:00:00.000',
      ...otherDetails
    };
    const res = await scheduleEventActions.deleteGroupPersonMember(details);
    if (res) {
      const event = res.scheduleEvents[0];
      if (event) {
        this.setState(({ data }) => {
          const patientsInfo = data.patientsInfo.filter(
            (v) => !event.cancelledPatients[v.subjectId]
          );
          return {
            data: {
              ...data,
              patientsInfo,
              cancelledPatientsInfo: [
                ...data.cancelledPatientsInfo,
                ...Object.values(event.cancelledPatients)
              ]
            }
          };
        });
        return res;
      }
    }
    return;
  };

  onUpdateStatus = async (payload: any) => {
    const { statusUpdating } = this.state;
    if (statusUpdating) return;
    this.setState({ statusUpdating: true });
    this.props.setRecordChanged && this.props.setRecordChanged();
    const response = await scheduleEventActions.updatePatientStatus(payload);
    if (response) {
      const { data } = this.state;
      this.setState({
        data: {
          ...data,
          patientStatus: {
            ...data.patientStatus,
            [response.subjectId]: response.status
          }
        },
        statusUpdating: false
      });
    } else {
      this.setState({ statusUpdating: false });
    }
  };

  getIconAndName = () => {
    const { data } = this.state;
    const { scheduleEvent } = data;
    switch (scheduleEvent.typeAppointmentParticipantId) {
      case 4: {
        return {
          appointmentType: 'Group Appointment',
          icon: <Icon name="groupList" />
        };
      }
      case 3: {
        return {
          appointmentType: 'Family Appointment',
          icon: <Icon name="family" />
        };
      }
    }
    return {
      appointmentType: 'Couple Appointment',
      icon: <Icon name="usersGroup" />
    };
  };

  onChangeAlertsData = (alertsData: any) => {
    this.setState({ alertsData });
  };

  render() {
    const { onClickAlertsView } = this.props;
    const { showRClickPopup, isLoading, currentPatient, data, alertsData } =
      this.state;
    const scheduleEvent = data.scheduleEvent;
    const { appointmentType, icon } = this.getIconAndName();
    const canDeleteGroup = this.canDeleteGroup();

    const formFillingInfo = (formRecord: any) => {
      return {
        filled: formRecord.viaPortal
          ? formRecord.patientComplete
          : formRecord.completed,
        filledBy: formRecord.viaPortal ? getPatientTerm('student') : 'provider',
        fullName: formRecord.viaPortal
          ? patientDisplayName(
              this.props.data.patientsInfo.find(
                (item) => formRecord.patientId === item.subjectId
              )
            )
          : providerDisplayName(formRecord.providerId),
        filledDate: formRecord.viaPortal
          ? formattedDateTime(formRecord.patientCompleteDate)
          : formattedDateTime(formRecord.signedDate)
      };
    };

    const onKeyDownContainer = (e: React.KeyboardEvent) => {
      if (e.shiftKey && e.code === 'Tab' && e.target === e.currentTarget) {
        if (this.props.callerRef?.current) {
          e.preventDefault();
          (this.props.callerRef.current as HTMLElement).focus();
        }
      }
      if (e.code === 'Escape') {
        this.props.onClosePopup();
        if (this.props.callerRef?.current) {
          e.preventDefault();
          (this.props.callerRef.current as HTMLElement).focus();
        }
      }
      if (e.key === 'Enter') {
        e.stopPropagation();
      }
      if (
        !e.shiftKey &&
        e.code === 'Tab' &&
        (e.target as HTMLElement).className.includes('ant-collapse-header') &&
        (e.target as HTMLElement).parentElement?.nextElementSibling === null
      ) {
        if (this.componentRef?.current) {
          e.preventDefault();
          (this.componentRef?.current as HTMLElement).focus();
        }
      }
    };
    const permission = permissionChecking({
      anyClinic: true,
      moduleId: 40,
      functionId: 149
    });

    const apptPermission = eventPermission(
      EEventTypeCode.appointment,
      scheduleEvent.typeAppointmentParticipantId
    );

    return (
      <>
        <AlertsManageContainer
          locationIndex={3}
          selectedSubjects={data.patientsInfo}
          onChangeAlertsData={this.onChangeAlertsData.bind(this)}
        />
        <SecureMessage
          ref={this.secureMessageRef}
          providerIds={data.providerCodes.map((v) => v.id)}
          title={`Group Appointment / ${scheduleEvent.reason?.name || ''}`}
        />
        {!isLoading ? (
          <>
            {showRClickPopup && showRClickPopup.length > 0 ? (
              <EditRClickPopup
                key="edit-popup"
                onClosePopup={() => this.onClose()}
                type={showRClickPopup}
                editSeries={() => this.editSeries()}
                editSingle={() => this.editSingle()}
                eventType="appointment"
                buttonRef={
                  this.state.showRClickPopup === 'Edit'
                    ? this.editButtonRef
                    : this.deleteButtonRef
                }
              />
            ) : null}
            <PopupContainer
              tabIndex={0}
              ref={this.componentRef}
              onKeyDown={onKeyDownContainer}
              id={'appt-popup-container'}
            >
              <PopupHeader key="header-popup">
                <AntdRow
                  style={{ paddingRight: 24 }}
                  align="middle"
                  justify="space-between"
                >
                  <Col>
                    <PopupTitle
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <IconGroupContainer>{icon}</IconGroupContainer>
                      <span>{appointmentType}</span>
                    </PopupTitle>
                  </Col>
                  <Col>
                    <AntdRow align="middle" gutter={16}>
                      {permission.success && (
                        <Col>
                          <ButtonIcon
                            isButton={true}
                            label="Secure message"
                            haspopup="dialog"
                            controlsId="new-secure-message-modal"
                            tooltip="Secure message"
                            changeColorOnHover={true}
                            name={ICONS_LIST.secureMessages}
                            onClick={() => {
                              this.secureMessageRef.current?.show({
                                value: {
                                  groupName: 'Group Appointment',
                                  clinicCode: scheduleEvent.clinicCode,
                                  providerId: data.providerCodes[0]?.id,
                                  patients: data.patientsInfo.map((v) => ({
                                    patientInfo: {
                                      ...v,
                                      patientId: v.subjectId
                                    }
                                  }))
                                }
                              });
                            }}
                          />
                        </Col>
                      )}

                      <Col>
                        <Tooltip
                          getPopupContainer={(element) =>
                            element.parentElement!
                          }
                          title="Edit Appointment"
                        >
                          <EditIconContainer showRClickPopup={showRClickPopup}>
                            <EditButton
                              buttonProps={{
                                disabled: !apptPermission.success,
                                tooltip: apptPermission.success
                                  ? 'Edit Appointment'
                                  : apptPermission.message,
                                id: test_ids.editButton,
                                containerProps: {
                                  ref: this.editButtonRef
                                },
                                asyncOnClick: async () => {
                                  await scheduleEventActions.getAppointment(
                                    scheduleEvent.appointmentId
                                  );
                                  this.onClickEditIcon();
                                }
                              }}
                              onClick={() => {}}
                            />
                          </EditIconContainer>
                        </Tooltip>
                      </Col>
                      <Col>
                        <DeleteIconContainer>
                          <RemoveButton
                            buttonIconProps={{
                              containerProps: {
                                ref: this.deleteButtonRef
                              },
                              id: test_ids.removeEventButton,
                              disabled: !canDeleteGroup,
                              tooltip: canDeleteGroup
                                ? ''
                                : "Can't cancel after Client check-in"
                            }}
                            removeAction={async () => {
                              if (canDeleteGroup) {
                                this.onClickDeleteIcon();
                              }
                            }}
                          />
                        </DeleteIconContainer>
                      </Col>
                      <Col>
                        <ButtonIcon
                          isButton={true}
                          label="Close dialog"
                          id={test_ids.cancelEventButton}
                          changeColorOnHover={true}
                          name={ICONS_LIST.thinClose}
                          iconScale={1.9}
                          tooltip="Close dialog"
                          onClick={() => {
                            this.props.onClosePopup();
                            if (this.props.callerRef?.current) {
                              (
                                this.props.callerRef.current as HTMLElement
                              ).focus();
                            }
                          }}
                        />
                      </Col>
                    </AntdRow>
                  </Col>
                </AntdRow>
                {onClickAlertsView && calculateAlertsCount(alertsData) > 0 && (
                  <AlertsBar
                    data={alertsData}
                    onClickAlertsView={onClickAlertsView}
                  />
                )}
              </PopupHeader>
              <MainBody key="main-body">
                {this.state.cancelPopup ? (
                  <CancelPopupContainer id={test_ids.cancelEventContainer}>
                    <CancelContentBlock>
                      <AppointmentRemovalBlock>
                        <AppointmentRemovalTitle>
                          {this.state.editSeries
                            ? 'Appointment Series Removal'
                            : 'Single Appointment Removal'}
                        </AppointmentRemovalTitle>
                        <ReasonSelectContainer>
                          <ReasonSelector
                            id={test_ids.removeReason}
                            clinicCodes={[scheduleEvent.clinicCode]}
                            name="Reason for Appointment removal"
                            reasonTypes={['cancellation']}
                            useCode={true}
                            value={this.state.cancellationReasonCode?.value}
                            onChange={(value: any) => {
                              this.onSelectCancellationReason(value);
                            }}
                          />
                          <ErrorBlock error={this.state.cancellationError}>
                            Field is mandatory
                          </ErrorBlock>
                        </ReasonSelectContainer>
                        <NoteContainer style={{ paddingTop: 0 }}>
                          <div className="Note">
                            <SelectContainer
                              id={test_ids.removeNoteArea}
                              hideLabel={true}
                              label="Note will be here"
                              type="area"
                              value={this.state.cancellationNote}
                              onChange={(cancellationNote: string) =>
                                this.setState({ cancellationNote })
                              }
                              inputProps={{
                                rows: 5,
                                maxLength: 255
                              }}
                              areaAutoReplace={true}
                            />
                          </div>
                          <ErrorBlock error={false}>
                            Field is mandatory
                          </ErrorBlock>
                        </NoteContainer>
                      </AppointmentRemovalBlock>
                      <AppointmentInfoBlock>
                        <Row
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            overflowY: 'scroll',
                            paddingTop: '24px',
                            height: '100%',
                            background: '#F7F7F7'
                          }}
                        >
                          <InsuranceContainer style={{ width: '260px' }}>
                            <label>Clinic</label>
                            <Info>{scheduleEvent.clinicName}</Info>
                          </InsuranceContainer>
                          <PreferedPhoneContainer
                            style={{ paddingTop: '2rem' }}
                          >
                            <label>Provider</label>
                            <Info>
                              {data?.providerCodes
                                ? data?.providerCodes
                                    .map((v: any) => v.name)
                                    .join(', ')
                                : scheduleEvent.provider.name}
                            </Info>
                          </PreferedPhoneContainer>
                          {scheduleEvent.inSeries ? (
                            <>
                              <OtherIDContainer
                                style={{ paddingTop: '2rem', width: 'unset' }}
                              >
                                <label>Recurrence</label>
                                <Info>
                                  {buildRecurrenceString(scheduleEvent.series)}
                                </Info>
                              </OtherIDContainer>
                            </>
                          ) : (
                            <>
                              <OtherIDContainer style={{ paddingTop: '2rem' }}>
                                <label>Date</label>
                                <Info>
                                  {formattedDate(
                                    scheduleEvent.scheduleEventDateTime
                                  )}
                                </Info>
                              </OtherIDContainer>
                              <OtherIDContainer style={{ paddingTop: '2rem' }}>
                                <label>Time</label>
                                <Info>
                                  {moment(
                                    scheduleEvent.scheduleEventDateTime
                                  ).format('LT')}
                                </Info>
                              </OtherIDContainer>
                            </>
                          )}

                          <PreferedPhoneContainer
                            style={{ paddingTop: '2rem' }}
                          >
                            <label>Reason Code</label>
                            <Info>{scheduleEvent.reason.name}</Info>
                          </PreferedPhoneContainer>
                        </Row>
                      </AppointmentInfoBlock>
                    </CancelContentBlock>
                    <CancelConfirmBlock>
                      <MessageBlock>
                        Are you sure you want to remove appointment?
                      </MessageBlock>
                      <ButtonContainer
                        style={{ padding: '14px', width: 'auto' }}
                      >
                        <ButtonSpan style={{ alignItems: 'center' }}>
                          <ButtonWrapper
                            onClick={(e: any) => {
                              this.setState({ cancelPopup: false });
                            }}
                          >
                            <Button
                              id={test_ids.cancelButton}
                              className={'ant-btn'}
                              style={{ border: 'none', boxShadow: 'none' }}
                            >
                              Cancel
                            </Button>
                          </ButtonWrapper>
                          <ButtonWrapper>
                            <Button
                              id={test_ids.removeButton}
                              onClick={async (e: any) =>
                                await this.onClickDelete()
                              }
                              style={{ minWidth: '80px   ' }}
                              className="active-button-red focused-red-button"
                            >
                              Remove
                            </Button>
                          </ButtonWrapper>
                        </ButtonSpan>
                      </ButtonContainer>
                    </CancelConfirmBlock>
                  </CancelPopupContainer>
                ) : (
                  <PopupBody>
                    <Row
                      paddingBottom={true}
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    >
                      <FieldValue>
                        <label className={'preview-label-style'}>Clinic</label>
                        <Info>{scheduleEvent.clinicName}</Info>
                      </FieldValue>
                      <FieldValue>
                        <label className={'preview-label-style'}>Type</label>
                        <Info>{scheduleEvent.reason?.name}</Info>
                      </FieldValue>
                      <FieldValue>
                        <label className={'preview-label-style'}>Date</label>
                        <Info>
                          {formattedDate(scheduleEvent.scheduleEventDateTime)}
                        </Info>
                      </FieldValue>
                      <FieldValue>
                        <label className={'preview-label-style'}>Time</label>
                        <Info>
                          {moment(scheduleEvent.scheduleEventDateTime).format(
                            'hh:mm a'
                          ) +
                            ' - ' +
                            moment(scheduleEvent.endTime).format('hh:mm a')}
                        </Info>
                      </FieldValue>
                      <FieldValue>
                        <label className={'preview-label-style'}>
                          Block self check-in
                        </label>
                        <Info>
                          {scheduleEvent.blockSelfCheckin === true
                            ? 'Yes'
                            : 'No'}
                        </Info>
                      </FieldValue>
                      {data.scheduleEvent.typeAppointmentLocationId ? (
                        <LocationInfo
                          typeAppointmentLocationId={
                            data.scheduleEvent.typeAppointmentLocationId
                          }
                        />
                      ) : null}
                    </Row>
                    {scheduleEvent.inSeries && (
                      <Row style={{ padding: '8px 24px 0px 24px' }}>
                        {RecurrenceDisplay(scheduleEvent.series, true)}
                      </Row>
                    )}
                    <Collapse
                      uppercaseHeader={true}
                      icon="roundIcon"
                      noSidePaddings={true}
                      activeKey={this.state.activePanels}
                      onChange={(panels) => {
                        this.setState({
                          activePanels: panels as string[]
                        });
                      }}
                    >
                      <Panel
                        key={'appt_members'}
                        header={
                          <Space size="small">
                            <span>Appointment members</span>
                            <span>
                              <span
                                style={{ color: globalStyleVariables.green }}
                              >
                                {`${data.patientsInfo.length}`}
                              </span>
                              <span>{`/${
                                scheduleEvent.maxParticipants ||
                                data.patientsInfo.length +
                                  data.cancelledPatientsInfo.length
                              }`}</span>
                            </span>
                          </Space>
                        }
                      >
                        <GroupMembersDetail
                          onDeleteMember={this.onDeleteMember.bind(this)}
                          onStatusUpdate={this.onUpdateStatus.bind(this)}
                          date={scheduleEvent.scheduleEventDateTime}
                          appointment={data}
                          setSelectedPatient={this.selectPatient.bind(this)}
                          currentPatient={currentPatient}
                        />
                      </Panel>
                      <Panel
                        key={'cancelled_members'}
                        header={
                          <Space size="small">
                            <span>Cancelled members</span>
                            <span>
                              <span style={{ color: globalStyleVariables.red }}>
                                {`${data.cancelledPatientsInfo.length}`}
                              </span>
                              <span>{`/${
                                scheduleEvent.maxParticipants ||
                                data.patientsInfo.length +
                                  data.cancelledPatientsInfo.length
                              }`}</span>
                            </span>
                          </Space>
                        }
                      >
                        <CancelledMembers appointment={data} />
                      </Panel>
                      <Panel key={'comment'} header={'Comment'}>
                        <GroupNotesDetail notes={scheduleEvent.comment} />
                      </Panel>
                      <Panel
                        key={'telehealth'}
                        header={
                          <Tooltip
                            title={
                              scheduleEvent.isTelehealth
                                ? ''
                                : 'Please enable Telehealth to attach links'
                            }
                            overlayStyle={{ zIndex: 10000 }}
                          >
                            Telehealth
                          </Tooltip>
                        }
                        collapsible={
                          scheduleEvent.isTelehealth ? undefined : 'disabled'
                        }
                      >
                        <TelehealthDetails data={scheduleEvent.telehealth} />
                      </Panel>
                      <Panel
                        key={'forms'}
                        header={'Forms'}
                        collapsible={
                          !this.state.currentPatient ? 'disabled' : undefined
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: ' 0 24px'
                          }}
                        >
                          {data.patientForms?.[
                            this.state.currentPatient?.subjectId + ''
                          ]?.filter((item: any) => !item.inactive).length >
                          0 ? (
                            data.patientForms?.[
                              this.state.currentPatient?.subjectId + ''
                            ]
                              ?.filter((item: any) => !item.inactive)
                              .map((item: any) => {
                                const fillingInfo = formFillingInfo(
                                  item.record
                                );
                                return (
                                  <FormSpan
                                    key={item.formId}
                                    completed={fillingInfo.filled}
                                    style={{ padding: '2px 5px' }}
                                  >
                                    <FWTooltip
                                      title={
                                        fillingInfo.filled
                                          ? `Filled by ${fillingInfo.filledBy}: ${fillingInfo.fullName}, ${fillingInfo.filledDate} `
                                          : null
                                      }
                                    >
                                      <AntdRow align="middle" gutter={8}>
                                        <Col>
                                          {fillingInfo.filled ? (
                                            <Icon
                                              size={18}
                                              name="filledCircle"
                                              color={globalStyleVariables.green}
                                            />
                                          ) : (
                                            <Icon
                                              size={18}
                                              name="statusNew"
                                              color={
                                                globalStyleVariables.brandGray
                                              }
                                            />
                                          )}
                                        </Col>
                                        <Col>
                                          <EllipsisText>
                                            {item.name}
                                          </EllipsisText>
                                        </Col>
                                      </AntdRow>
                                    </FWTooltip>
                                  </FormSpan>
                                );
                              })
                          ) : (
                            <ContainerBlock>
                              <NoData removeVerticalPaddings={true} />
                            </ContainerBlock>
                          )}
                        </div>
                      </Panel>
                      <Panel
                        key={'additional_contacts'}
                        header={
                          <Tooltip
                            title={
                              !this.state.currentPatient?.subjectId
                                ? 'Please select a member from the appointment members'
                                : ''
                            }
                            overlayStyle={{ zIndex: 10000 }}
                          >
                            <label>
                              Additional contacts
                              {currentPatient && (
                                <span
                                  style={{
                                    textTransform: 'none'
                                  }}
                                >
                                  {' '}
                                  - {patientDisplayName(currentPatient)}
                                </span>
                              )}
                            </label>
                          </Tooltip>
                        }
                        collapsible={
                          !this.state.currentPatient?.subjectId
                            ? 'disabled'
                            : undefined
                        }
                      >
                        <AdditionalContacts
                          patientId={this.state.currentPatient?.subjectId!}
                        />
                      </Panel>
                      <Panel
                        key={'appt_history'}
                        header={
                          <Tooltip
                            title={
                              !this.state.currentPatient?.subjectId
                                ? 'Please select a member from the appointment members'
                                : ''
                            }
                            overlayStyle={{ zIndex: 10000 }}
                          >
                            <label>
                              Appointment history
                              {currentPatient && (
                                <span
                                  style={{
                                    textTransform: 'none'
                                  }}
                                >
                                  {' '}
                                  - {patientDisplayName(currentPatient)}
                                </span>
                              )}
                            </label>
                          </Tooltip>
                        }
                        collapsible={
                          !this.state.currentPatient?.subjectId
                            ? 'disabled'
                            : undefined
                        }
                      >
                        <AppointmentHistoryDetail
                          patientId={
                            this.state.currentPatient?.subjectId || null
                          }
                        />
                      </Panel>
                      <Panel
                        key={'additional_info'}
                        header={
                          <Tooltip
                            title={
                              !this.state.currentPatient?.subjectId
                                ? 'Please select a member from the appointment members'
                                : ''
                            }
                            overlayStyle={{ zIndex: 10000 }}
                          >
                            <label>
                              Additional Info
                              {currentPatient && (
                                <span
                                  style={{
                                    textTransform: 'none'
                                  }}
                                >
                                  {' '}
                                  - {patientDisplayName(currentPatient)}
                                </span>
                              )}
                            </label>
                          </Tooltip>
                        }
                        collapsible={
                          !this.state.currentPatient?.subjectId
                            ? 'disabled'
                            : undefined
                        }
                      >
                        <InfoWrapper>
                          <PatientDetail
                            highRisk={!!this.state.currentPatient?.highRisk}
                            action={''}
                            dispatch={undefined}
                            scheduleEvent={data.scheduleEvent}
                            subjectId={this.state.currentPatient?.subjectId}
                            viewMode={true}
                          />
                        </InfoWrapper>
                      </Panel>
                      <Panel
                        key={'appointment_providers'}
                        header={'Appointment providers'}
                      >
                        <div
                          style={{
                            maxHeight: `${500}px`,
                            transition: 'max-height 0.2s ease-out',
                            paddingLeft: '24px',
                            marginBottom: '16px'
                          }}
                        >
                          <Info
                            id={'provider-span'}
                            style={{
                              opacity: 1,
                              transition: 'opacity 0.2s',
                              display: 'flex',
                              flexWrap: 'wrap',
                              height: 'auto',
                              justifyContent: 'flex-start',
                              width: '90%'
                            }}
                          >
                            {data.providerCodes?.map((f: any) => {
                              return (
                                <span key={f.id}>
                                  <ProviderSpan>
                                    <TextEllipsisSpan
                                      style={{
                                        paddingRight: '0.5rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      {f.name}
                                    </TextEllipsisSpan>
                                  </ProviderSpan>
                                </span>
                              );
                            })}
                          </Info>
                        </div>
                      </Panel>
                    </Collapse>
                  </PopupBody>
                )}
              </MainBody>
            </PopupContainer>
          </>
        ) : (
          <AbsoluteLoader />
        )}
      </>
    );
  }
}

export default ScheduleGroupPopup;

import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { getNavigationList } from 'src/Activities/Home/Content/utils';

export enum CollapseEnum {
  GeneralInformation = 'GeneralInformation',
  ProviderInformation = 'ProviderInformation',
  StudentChartMenu = 'StudentChartMenu',
  AutoReplaceShortcuts = 'AutoReplaceShortcuts',
  AccessibilityShortcuts = 'AccessibilityShortcuts',
  ProviderOutOfOffice = 'ProviderOutOfOffice',
  Calendar = 'Calendar',
  Note = 'Note',
  Activity = 'Activity'
}

export const CollapseNames: Record<CollapseEnum, string> = {
  [CollapseEnum.GeneralInformation]: 'General information',
  [CollapseEnum.ProviderInformation]: 'Provider information',
  [CollapseEnum.StudentChartMenu]: 'Student chart menu',
  [CollapseEnum.AutoReplaceShortcuts]: 'Auto Replace Shortcuts',
  [CollapseEnum.ProviderOutOfOffice]: 'Provider Out Of Office',
  [CollapseEnum.AccessibilityShortcuts]: 'Accessibility Shortcuts',
  [CollapseEnum.Calendar]: 'Calendar',
  [CollapseEnum.Note]: 'Note',
  [CollapseEnum.Activity]: 'Activity'
};

const fullList = getNavigationList();
const activitiesTopics = Object.fromEntries(
  fullList.map((item) => [item.itemName, item.description])
);

export const searchKeyWordsLibrary = () => {
  return {
    [CollapseEnum.GeneralInformation]: {
      words: {
        key: CollapseNames.GeneralInformation,
        fullName: 'Full Name',
        email: 'Email address',
        userName: 'User Name',
        entryScreen: 'Entry Screen',
        alertForUnder18: 'Alert for Under 18',
        privacyPolicy: 'Privacy Policy',
        defaultMessageFolder: 'Default Message Folder',
        riskWidget: 'Show Risk Widget'
      }
    },
    [CollapseEnum.ProviderInformation]: {
      words: {
        key: CollapseNames.ProviderInformation,
        currentProvider: 'Current user is provider',
        billingClinic: 'Billing Clinic',
        billingProvider: 'Billing Provider',
        billingFacility: 'Billing Facility'
      }
    },
    [CollapseEnum.Calendar]: {
      words: {
        key: CollapseNames.Calendar,
        syncTitle: 'Sync calendar with external email address',
        reservationTitle: 'Reservation label settings',
        backgroundColorTitle: 'Calendar Appt background color',
        calendarCellSettingsTitle: 'Calendar Icon Settings',
        columnNumbers: 'Column number',
        cellSettingsTitle: 'Cell settings',
        syncCheckbox: 'Sync calendar with external email address',
        clinic: 'Clinic',
        provider: 'Provider',
        addCalendar: 'Add Calendar',
        clinicColumn: 'Clinic',
        providerColumn: 'Provider',
        StartSyncFrom: 'Start sync from',
        Delete: 'Delete',
        reservationType: 'Reservation Type:',
        reservationLayout: 'Reservation Layout:',
        apptTypeLabel: 'Appointment Type Label:',
        calendarApptBgColor: 'Calendar Appt background color',
        PatientInformationAppliance: `${getPatientTerm(
          'Student'
        )} Information Appliance`,
        autoDischargeOnNoteLock:
          'Auto discharge when the Note from Appt is locked'
      }
    },
    [CollapseEnum.StudentChartMenu]: {
      words: {
        key: `${getPatientTerm('Student')} chart menu`,
        checkbox: `Show all notes navigation item under the ${getPatientTerm(
          'student'
        )} chart`
      }
    },
    [CollapseEnum.AutoReplaceShortcuts]: {
      words: {
        key: CollapseNames.AutoReplaceShortcuts,
        add: 'Add new shortcut'
      }
    },
    [CollapseEnum.ProviderOutOfOffice]: {
      words: {
        key: CollapseNames.ProviderOutOfOffice,
        provider: 'Provider',
        outOfOffice: 'Out of Office',
        autoReply: 'Auto Reply',
        startTime: 'Start Time',
        endTime: 'End Time',
        startDate: 'Start Date',
        endDate: 'End Date'
      }
    },
    [CollapseEnum.AccessibilityShortcuts]: {
      words: {
        key: CollapseNames.AccessibilityShortcuts
      }
    },
    [CollapseEnum.Note]: {
      words: {
        key: CollapseNames.Note,
        checkbox: 'Show user selected forms in section navigation'
      }
    },
    [CollapseEnum.Activity]: {
      words: {
        key: CollapseNames.Activity,
        ...activitiesTopics
      }
    }
  };
};

export const entryScreenOptionsSorted = () => {
  return [
    {
      label: 'Home',
      value: ''
    },
    {
      label: 'Provider Dashboard',
      value: '/activity/provider-dashboard'
    },
    {
      label: 'Supervisor Dashboard',
      value: '/activity/supervisor-dashboard'
    },
    {
      label: 'Case Management',
      value: '/activity/case-management-dashboard'
    },
    {
      label: 'Risk Management',
      value: '/activity/risk-management-dashboard'
    },
    {
      label: 'Appointments',
      value: '/activity/appointment'
    },
    {
      label: 'Messages',
      value: '/activity/messages'
    },
    {
      label: `My ${getPatientTerm('Clients')}`,
      value: '/activity/my-clients'
    },
    {
      label: 'Reports',
      value: '/reports'
    },
    {
      label: 'Tasks',
      value: '/activity/tasks'
    },
    {
      label: 'To Dos',
      value: '/activity/todos'
    },
    {
      label: 'Outreach',
      value: '/activity/outreach'
    },
    {
      label: `Unassigned ${getPatientTerm('Clients')}`,
      value: '/activity/unassigned-clients'
    },
    {
      label: 'Calendar',
      value: '/schedule'
    },
    {
      label: getPatientTerm('Students'),
      value: '/client'
    },
    {
      label: 'Compliance',
      value: '/activity/cohort'
    }
  ].sort((a, b) => a.label?.localeCompare(b.label));
};

import React, { FC, useState } from 'react';
import { Row, Col } from 'antd';
import _ from 'lodash';

import Table from 'src/Framework/Controls/Table';
import Checkbox from 'src/Framework/Controls/Checkbox';
import Radio from 'src/Framework/Controls/Radio';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { useAppSelector } from 'src/store';

import { ICellSettings, TypeReservationType } from '../store/types';
import { onChangeCellSettings } from '../store/Actions';

import { Container } from './styled';

interface IProps {}

export const reservationTypeNames: Record<TypeReservationType, string> = {
  [TypeReservationType.flag]: 'Flag',
  [TypeReservationType.fillBackground]: 'Fill background',
  [TypeReservationType.border]: 'Border'
};

export const defaultCellSettings: ICellSettings = Object.freeze({
  order: [],
  noteSettings: {
    icon: true,
    text: false
  },
  show: {
    recurring: true,
    participation: true,
    fullName: true,
    telehealth: true,
    note: true,
    status: true,
    appointmentType: true
  },
  reservationSettings: {
    overlap: true
  },
  reservationType: TypeReservationType.flag
});

export const getCellSettings = (value: string): typeof defaultCellSettings => {
  try {
    const data = JSON.parse(value);
    return data;
  } catch (e) {
    return defaultCellSettings;
  }
};
export const defaultData: {
  name: string;
  key: string;
}[] = [
  {
    name: 'Recurring icon',
    key: 'recurring'
  },
  {
    name: 'Participation type icon ',
    key: 'participation'
  },
  {
    name: 'Student Full Name / Category Name / Outreach Title',
    key: 'fullName'
  },
  {
    name: 'Telehealth icon',
    key: 'telehealth'
  },
  {
    name: 'Note',
    key: 'note'
  },
  {
    name: 'Status icon',
    key: 'status'
  },
  {
    name: 'Appointment type',
    key: 'appointmentType'
  }
];

const Component: FC<IProps> = ({}: IProps) => {
  const [loading, setLoading] = useState(false);
  const { cellSettings, data } = useAppSelector((state) => {
    const cellSettings =
      state.user.preferences.calendarCellSettings?.parsedValue ||
      defaultCellSettings;
    const data = defaultData.sort(
      (a, b) =>
        cellSettings.order.indexOf(a.key) - cellSettings.order.indexOf(b.key)
    );
    return {
      cellSettings,
      data
    };
  });
  const onChangeOrder = async (prev: number, next: number) => {
    const arr = _.cloneDeep(data);
    const prevArr = _.cloneDeep(data);
    const element = arr[prev];
    arr.splice(prev, 1);
    arr.splice(next, 0, element);
    setLoading(true);
    await onChangeCellSettings(
      {
        ...cellSettings,
        order: arr.map((v) => v.key)
      },
      {
        ...cellSettings,
        order: prevArr.map((v) => v.key)
      }
    );
    setLoading(false);
  };
  const onKeyDownBlock = (e: React.KeyboardEvent) => {
    e.stopPropagation();
  };
  return (
    <Container onKeyDown={onKeyDownBlock}>
      {loading && <AbsoluteLoader />}
      <Table
        tableCaption={'Calendar cell settings'}
        dragMarginLeft={true}
        onChangeOrder={onChangeOrder}
        dragAndDropProps={{
          rootList: data,
          onChangeOrder: onChangeOrder
        }}
        data={defaultData.map((value, index) => ({
          ...value,
          id: value.key,
          order: index + 1,
          noteSettings:
            value.key === 'note' ? (
              <Row align="middle" gutter={8} onKeyDown={onKeyDownBlock}>
                <Col>
                  <Radio
                    name="note-radio"
                    id="note-radio-icon"
                    checked={cellSettings.noteSettings.icon}
                    onChange={async () => {
                      setLoading(true);
                      await onChangeCellSettings(
                        {
                          ...cellSettings,
                          noteSettings: {
                            ...cellSettings.noteSettings,
                            icon: true,
                            text: false
                          }
                        },
                        cellSettings
                      );
                      setLoading(false);
                    }}
                  >
                    Icon
                  </Radio>
                </Col>
                <Col>
                  <Radio
                    name="note-radio"
                    id="note-radio-text"
                    checked={cellSettings.noteSettings.text}
                    onChange={async () => {
                      setLoading(true);
                      await onChangeCellSettings(
                        {
                          ...cellSettings,
                          noteSettings: {
                            ...cellSettings.noteSettings,
                            icon: false,
                            text: true
                          }
                        },
                        cellSettings
                      );
                      setLoading(false);
                    }}
                  >
                    Text
                  </Radio>
                </Col>
              </Row>
            ) : null,
          show: (
            <Checkbox
              id={'show-checkbox' + value.key}
              checked={cellSettings.show[value.key]}
              asyncChange={true}
              onChange={async () => {
                setLoading(true);
                await onChangeCellSettings(
                  {
                    ...cellSettings,
                    show: {
                      ...cellSettings.show,
                      [value.key]: !cellSettings.show[value.key]
                    }
                  },
                  cellSettings
                );
                setLoading(false);
              }}
            >
              <label className="visually-hidden">show</label>
            </Checkbox>
          )
        }))}
        columns={[
          {
            title: 'Name',
            flex: 'auto',
            key: 'name',
            ellipsis: true
          },
          {
            title: '',
            flex: '190px',
            key: 'noteSettings',
            centered: true
          },
          {
            title: 'Order',
            flex: '80px',
            key: 'order',
            centered: true
          },
          {
            title: 'Show',
            flex: '80px',
            key: 'show',
            centered: true
          },
          {
            flex: '130px',
            key: '',
            title: '',
            centered: true,
            type: 'change-order'
          }
        ]}
      />
    </Container>
  );
};

export default Component;

import React, { Fragment, useImperativeHandle, useState, useMemo } from 'react';
import { Col, Row, Space } from 'antd';

import Modal from 'src/Framework/Controls/Modal';
import { getUserByCode } from 'src/Framework/Controls/Selectors/UserSelector/utils';
import { getMaskOptions } from 'src/Framework/Controls/SelectContainer/Components/Input';
import ChartButton from 'src/Framework/Controls/PatientPagesButtons/ChartButton';
// import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { formattedDateTime } from 'src/Framework/Shared/Shared';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { patientDisplayName } from 'src/Framework/util/format';

import { IAppointmentFull } from 'src/Activities/Schedule/Main/ScheduleCol/ScheduleOne';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

import { Container } from './styled';

interface IProps {}

export type TCancellationInfo = Pick<
  IAppointmentFull,
  'patientInfo' | 'cancelledPatients' | 'cancellation' | 'note'
>;

interface IShow {
  appointment: TCancellationInfo | null;
}

export interface IModalHandles {
  show(obj: IShow): void;

  close(): void;
}

const modalProps = {
  destroyOnClose: true
};

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  {},
  ref
) => {
  const [modalData, setModalData] = useState<TCancellationInfo | null>(null);

  const [visible, setVisible] = useState(false);
  useModalFocus({ active: visible });

  useImperativeHandle(ref, () => ({
    show: (obj: IShow) => show(obj),
    close
  }));

  const show = async ({ appointment }: IShow) => {
    setModalData(appointment);
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setModalData(null);
  };

  const firstCancelledPatient = useMemo(
    () =>
      modalData && modalData.cancelledPatients
        ? Object.values(modalData.cancelledPatients)[0]
        : null,
    [modalData]
  );

  const defaultWidth = 500;
  const title = 'Cancelled Appointment';
  const headerRight = modalData ? (
    <Space>
      <ChartButton
        patientId={
          (modalData?.cancelledPatients
            ? firstCancelledPatient?.patientId
            : modalData.patientInfo.patientId) as number
        }
      />
      {/*<ButtonIcon*/}
      {/*  isButton={true}*/}
      {/*  label="Print Cancellation Info To PDF"*/}
      {/*  tooltip="Print Cancellation Info To PDF"*/}
      {/*  changeColorOnHover={true}*/}
      {/*  name={ICONS_LIST.print}*/}
      {/*  onClick={() => {}}*/}
      {/*/>*/}
    </Space>
  ) : undefined;

  return (
    <Modal
      id={'cancellation-details-modal'}
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        title,
        headerRight
      }}
      width={defaultWidth}
      title={title}
      visible={visible}
      onClose={close}
      headerBorder={true}
      afterClose={setToDefault}
      modalProps={modalProps}
      buttons={headerRight}
    >
      <Container>
        {modalData && (
          <>
            {modalData.cancellation?.cancelReasonName && (
              <Row gutter={8}>
                <Col>Cancelled reason:</Col>
                <Col className={'cancellation-info'}>
                  {modalData.cancellation?.cancelReasonName || ''}
                </Col>
              </Row>
            )}
            {modalData.cancellation?.cancelUserCode && (
              <Row gutter={8}>
                <Col>Cancelled By:</Col>
                <Col className={'cancellation-info'}>
                  {getUserByCode(modalData.cancellation?.cancelUserCode)?.name}
                </Col>
              </Row>
            )}
            {modalData.cancellation?.cancelledDate && (
              <Row gutter={8}>
                <Col>Date:</Col>
                <Col className={'cancellation-info'}>
                  {formattedDateTime(modalData.cancellation?.cancelledDate)}
                </Col>
              </Row>
            )}
            {modalData.cancelledPatients ? (
              <div>
                <Row gutter={8}>
                  <Col>Cancelled reason:</Col>
                  <Col className={'cancellation-info'}>
                    {firstCancelledPatient?.cancellation?.cancelReasonName ||
                      ''}
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col>Cancelled By:</Col>
                  <Col className={'cancellation-info'}>
                    {getUserByCode(
                      firstCancelledPatient?.cancellation?.cancelUserCode || ''
                    )?.name || ''}
                  </Col>
                </Row>
                {firstCancelledPatient?.cancellation?.cancelledDate && (
                  <Row gutter={8}>
                    <Col>Date:</Col>
                    <Col className={'cancellation-info'}>
                      {formattedDateTime(
                        firstCancelledPatient?.cancellation?.cancelledDate || ''
                      )}
                    </Col>
                  </Row>
                )}
                <Row gutter={8}>
                  <Row>{getPatientTerm('Subjects')}:</Row>
                  {Object.values(modalData.cancelledPatients).map((v) => (
                    <Fragment key={v.patientId}>
                      <Row gutter={8}>
                        <Col>Name:</Col>
                        <Col className={'cancellation-info'}>
                          {patientDisplayName(v)}
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col>Preferred number:</Col>
                        <Col className={'cancellation-info'}>
                          {
                            getMaskOptions('phone', v.patientPreferredPhone)
                              .value
                          }
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col>Comment:</Col>
                        <Col className={'cancellation-info'}>{v.comment}</Col>
                      </Row>
                    </Fragment>
                  ))}
                </Row>
              </div>
            ) : (
              <>
                <Row gutter={8}>
                  <Col>{getPatientTerm('Subject')}:</Col>
                  <Col className={'cancellation-info'}>
                    {patientDisplayName(modalData.patientInfo)}
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col>Preferred number:</Col>
                  <Col className={'cancellation-info'}>
                    {
                      getMaskOptions(
                        'phone',
                        modalData.patientInfo.patientPreferredPhone
                      ).value
                    }
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col>Comment:</Col>
                  <Col className={'cancellation-info'}>{modalData.note}</Col>
                </Row>
              </>
            )}
          </>
        )}
      </Container>
    </Modal>
  );
};

export default React.forwardRef(Component);

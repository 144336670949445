import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

const font = `
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
`;

export const TagsContainer = styled.div`
  padding: 0 20px 5px 20px;
`;

export const ModalBodyNewMessage = styled.div``;
export const NewMessageTab = styled.div`
  position: relative;
`;

export const FormNameContainer = styled.div`
  padding: 16px 24px 0 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

`;
export const FormBody = styled.div<{hideTabHeader: boolean}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({hideTabHeader})=>hideTabHeader && `
    .ant-tabs-nav {
      display: none !important;
    }
  `}
  .ant-tabs-nav-wrap{
    margin: 0 20px;
  }
  .ant-tabs  {
        &.ant-tabs-top {
            display: flex;
            flex: 1;
            flex-direction: column;
            .ant-tabs-content-holder {
                display: flex;
                flex: 1;
                flex-direction: column;
                .ant-tabs-content {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    .ant-tabs-tabpane.ant-tabs-tabpane-active {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                    }
                }
            
            }
        }
    }
  .message-forms-list {
    height: 300px;
    overflow: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .message-forms-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    box-sizing: border-box;
    min-height: 58px;
    color: #333333;

    &:hover {
      background-color: #f7f7f7;
      color: ${globalStyleVariables.newBrandingPrimary200};
      cursor: pointer;
    }
  }
`;

export const ModalHeader = styled.div`
  padding: 0 28px;
`;

export const ModalBody = styled.div`
  padding: 20px 28px 20px 28px;
`;

export const Container = styled.div`
  .back-button {
    height: 30px;
    width: 30px;
    mask-size: contain;
    mask-position: center center;
    mask-repeat: no-repeat no-repeat;
    background: rgb(14, 109, 159);
    margin: 0 10px 0 0;
    cursor: pointer;

    &:active {
      transform: scale(0.9);
    }
  }

  &.reply {
    padding-top: 65px;
  }

  .family-column {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 24px;

  .attachment-container {
    display: flex;
    margin: 20px 0 0 0;
  }

  .message-container {
    padding-top: 12px;
    width: 100%;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 24px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 34px 0;

    .right-part {
      display: flex;
      align-items: center;
      margin: 0 20px 0 0;

      .item {
        margin-left: 15px;
      }
    }

    &.reply {
      .right-part {
        margin: 0;
      }
    }
  }

  .follow-up-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 20px 0 0 0;
  }

  .family-messaging-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: rgba(14, 109, 159, 1);
    cursor: pointer;
    margin: 0 0 25px;

    ${font}
    .family-messaging-icon {
      height: 14px;
      width: 14px;
      mask-size: contain;
      mask-position: center center;
      mask-repeat: no-repeat no-repeat;
      background: rgba(14, 109, 159, 1);
      margin: 0 0 0 12px;
    }
  }

  .column {
    position: relative;
    padding: 0 24px 0 0;

    &.disabled {
      pointer-events: none;
      filter: grayscale(100%);
    }
  }

  .save-as-draft {
    color: rgba(83, 200, 46, 1);
    border-color: rgba(83, 200, 46, 1);
    ${font}
  }

  .send-button {
    ${font}
    &.active {
      color: ${globalStyleVariables.newBrandingPrimary200};
      border-color: ${globalStyleVariables.newBrandingPrimary200};
    }
  }
`;

export const Title = styled.div`
  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;

  span {
    &:first-letter {
      text-transform: capitalize;
    }
  }
`;

export const ContentText = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
`;

export const SimpleItemContainer = styled.div`
  margin: 0 0 25px;
`;

export const TabContainer = styled.div`
    display: flex;
    flex:1;
    flex-direction: column;
    .selected-form-row {
      .ant-col.data-column-item {
        background-color: #f2f2f2;
      }
    }
`

export const TabTitle = styled.div`
  padding: 0 10px;
  margin: 0 10px;
`

export const Header = styled.div`
  padding: 0 20px;
`

export const MainHeader = styled.div`
  padding: 10px 20px;
`
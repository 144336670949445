import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { pickTextColorBasedOnBgColorSimple } from 'src/Framework/util/helps';
import { CalendarOpacityLevel } from 'src/Activities/Schedule/TopBar/FilterBar/OpacityLevel';
import { DefaultItemHeight } from '../ScheduleCol_Styles';
import { defaultApptBackgroundColor } from 'src/App/UserPreferences/store';
import { CALENDAR_ZINDEX } from '../utils';

const DefaultItemWidth = 200;

export const ScheduleGroupBackground = styled.div`
  width: 100%;
  position: relative;
  user-select: none;
`;

export const ScheduleGroupContainer = styled.div`
  width: 100%;
  position: relative;
  user-select: none;
`;

export const GroupTitle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 0 4px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
  overflow: hidden;
  color: #ffffff;
  max-width: ${DefaultItemWidth}px;
`;

export const BlockSvgContainer = styled.span`
  padding-left: 0.5rem;
`;

export function GroupTitleStyle(
  groupTitle: string,
  color: string,
  isReservation: boolean,
  calendarOpacityLevel: CalendarOpacityLevel
): any {
  var groupTitleStyle: any = {
    backgroundColor: hexToRgba(
      color || defaultApptBackgroundColor,
      calendarOpacityLevel.value ?? 0.2
    ),
    color: pickTextColorBasedOnBgColorSimple(color, 'white', 'black')
  };
  if (groupTitle.length > 25) {
    groupTitleStyle.width = '100%';
  } else if (groupTitle == 'Block') {
    groupTitleStyle.width = '38px';
  }
  groupTitleStyle.width = `calc(100% - 12px)`;
  return groupTitleStyle;
}

export function GroupBkStyle(
  color: string,
  groupLen: number,
  groupUIOffset: number,
  groupTitle: string,
  isReservation: boolean,
  calendarOpacityLevel: CalendarOpacityLevel
): React.CSSProperties {
  var groupStyle: React.CSSProperties = {
    position: `absolute`,
    height: `${DefaultItemHeight() * groupLen}px`,
    borderLeft: isReservation
      ? `solid ${hexToRgba(
          color ?? defaultApptBackgroundColor,
          calendarOpacityLevel.value ?? 0.2
        )} 2px`
      : 'none',

    top: DefaultItemHeight() * groupUIOffset,
    left: 0,
    width: `${DefaultItemWidth - 13}px`,
    zIndex: isReservation ? 0 : 1
  };
  if (groupTitle == 'Block') {
    groupStyle.background = '#F7F7F7';
    groupStyle.cursor = 'no-drop';
    groupStyle.borderLeft = 'none';
  }
  return groupStyle;
}

export function GroupStyleForTitle(
  groupUIOffset: number,
  isReservation: boolean
): React.CSSProperties {
  var groupStyle: React.CSSProperties = {
    position: `absolute`,
    top: DefaultItemHeight() * groupUIOffset,
    left: 0,
    cursor: isReservation ? 'pointer' : 'initial',
    zIndex: CALENDAR_ZINDEX.reservatonTitle
  };
  return groupStyle;
}

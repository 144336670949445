import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 2px 20px;
    background: #F7F7F7;
`

export const Item = styled.div`
    padding: 2px 8px;
    color: #333333;
    border-radius: 4px;
    margin: 0 15px 0 0;
    font-size: 12px;
    line-height: 130%;
    transition: all 0.25s ease;
    background: rgba(255,255,255,0);
    cursor: pointer;
    &:hover {
        background: rgba(255,255,255,0.8);
    }
    &.active {
        background: #FFFFFF;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    }
    &:active {
        transform: scale(0.95);
    }
`
import { patientDisplayName } from 'src/Framework/util/format';
import { eventName } from '../../utils';
import { getClinicByCode } from 'src/Framework/Controls/Selectors/ClinicSelector';
import moment from 'moment';

export const CALENDAR_ZINDEX = {
  emptyCells: 3,
  events: 4,
  reservatonTitle: 5
};

export const getPatientName = (
  typeAppointmentParticipantId: any,
  patientName: any,
  patients: any,
  categoryCode: any,
  appointmentFor: any
) => {
  let patientNames = '';
  if (typeAppointmentParticipantId !== 4) {
    if (!patientName && patients) {
      Object.values(patients).forEach((item: any, index: number) => {
        patientNames =
          patientNames +
          patientDisplayName(item) +
          (index + 1 < Object.values(patients).length ? ', ' : '');
      });
    } else {
      patientNames = patientName;
    }
    return patientNames;
  } else {
    return categoryCode;
  }
};

export const getEventName = (eventInfo: any) => {
  return eventInfo.isBlock
    ? 'block'
    : eventInfo.isReservation
      ? 'reservation'
      : eventInfo.isEvent
        ? eventName
        : 'appointment';
};


export const checkClinicOutOfRange = ({
  clinicCode,
  endTime
}: {
  clinicCode: string
  endTime: string
}) => {
  return true
  const clinic = getClinicByCode(clinicCode);

  if (clinic) {
    if (
      moment(endTime, 'HH:mm').isAfter(
        moment(clinic?.endTime?.slice(11, 16), 'HH:mm')
      )
    ) {
      return false;
    }
  }
  return true
}
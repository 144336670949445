import store from 'src/store';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

import { setPreference } from 'src/Activities/Compliance/Store/ComplianceActions';
import { updateCurrentUserPreference } from 'src/App/Preferences/Actions';

import { actionsTypes, defaultApptBackgroundSettings } from './index';

import { IApptSubscription, ICellSettings, IStatusesSound } from './types';
import { PrefEnum, PrefEnumKeys } from 'src/App/Preferences/utils';
import { IPatientChartMenuSettings } from 'src/App/Admin/Pages/PatientMenuItems/store/utils';
import { ISystemShortcuts } from 'src/App/UserPreferences/ShortKeys/store/types';

export const getCurrentTenantId = () => {
  return localStorage.getItem('tenantId');
};

const setData = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_APPT,
    payload
  });
};

const setFacilities = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET_FACILITIES,
    payload
  });
};

const onChangeDataItem = (payload: any) => {
  store.dispatch({
    type: actionsTypes.CHANGE_APPT,
    payload
  });
};

export const onChangePreferenceItem = async <T = any>(
  prefKey: PrefEnumKeys,
  value: T,
  options?: {
    disableJsonStrigify?: boolean
  }
) => {
  const userId = localStorage.getItem('userId');
  const userPreferenceInfo = {
    userId,
    preferences: [
      {
        id: PrefEnum[prefKey].id,
        userId,
        name: PrefEnum[prefKey].name,
        value: options?.disableJsonStrigify ? value : JSON.stringify(value),
        defaultValue: ''
      }
    ]
  };
  try {
    const response = await setPreference(userPreferenceInfo);
    const data: any = response.data;
    if (data.success && data.result.success) {
      updateCurrentUserPreference(userPreferenceInfo.preferences);
    }
  } catch (error) {
    console.log({ error });
  }
};

export const onChangeDefaultMessageFolder = async (value: string) => {
  const userId = localStorage.getItem('userId');
  const userPreferenceInfo = {
    userId,
    preferences: [
      {
        id: PrefEnum.DefaultMessageFolder.id,
        userId,
        name: PrefEnum.DefaultMessageFolder.name,
        value: value,
        defaultValue: ''
      }
    ]
  };
  try {
    const response = await setPreference(userPreferenceInfo);
    const data: any = response.data;
    if (data.success && data.result.success) {
      updateCurrentUserPreference(userPreferenceInfo.preferences);
    }
  } catch (error) {
    console.log({ error });
  }
};

export const onChangePatientChartMenuSettings = async (
  value: IPatientChartMenuSettings
) => {
  const userId = localStorage.getItem('userId');
  const userPreferenceInfo = {
    userId,
    preferences: [
      {
        id: PrefEnum.PatientChartMenuSettings.id,
        userId,
        name: PrefEnum.PatientChartMenuSettings.name,
        value: JSON.stringify(value),
        defaultValue: ''
      }
    ]
  };
  try {
    const response = await setPreference(userPreferenceInfo);
    const data: any = response.data;
    if (data.success && data.result.success) {
      updateCurrentUserPreference(userPreferenceInfo.preferences);
    }
  } catch (error) {
    console.log({ error });
  }
};

export const onChangePrivacyPolicy = async (value: 'True' | 'False') => {
  const userId = localStorage.getItem('userId');
  const userPreferenceInfo = {
    userId,
    preferences: [
      {
        id: PrefEnum.HIPAAAlert.id,
        userId,
        name: PrefEnum.HIPAAAlert.name,
        value,
        defaultValue: ''
      }
    ]
  };
  try {
    const response = await setPreference(userPreferenceInfo);
    const data: any = response.data;
    if (data.success && data.result.success) {
      updateCurrentUserPreference(userPreferenceInfo.preferences);
    }
  } catch (error) {
    console.log({ error });
  }
};

export const onChangeScheduleStatusesSound = async (value: IStatusesSound) => {
  const userId = localStorage.getItem('userId');
  const userPreferenceInfo = {
    userId,
    preferences: [
      {
        id: 3038,
        userId,
        name: 'ScheduleStatusesSound',
        value: JSON.stringify(value),
        defaultValue: ''
      }
    ]
  };
  try {
    const response = await setPreference(userPreferenceInfo);
    const data: any = response.data;
    if (data.success && data.result.success) {
      updateCurrentUserPreference(userPreferenceInfo.preferences);
    }
  } catch (error) {
    console.log({ error });
  }
};

export const changeEntryScreen = async (value: string) => {
  const userId = localStorage.getItem('userId');
  const userPreferenceInfo = {
    userId,
    preferences: [
      {
        id: 3030,
        userId,
        name: 'UserEntryScreen',
        value,
        defaultValue: ''
      }
    ]
  };
  try {
    const response = await setPreference(userPreferenceInfo);
    const data: any = response.data;
    if (data.success && data.result.success) {
      updateCurrentUserPreference(userPreferenceInfo.preferences);
    }
  } catch (error) {
    console.log({ error });
  }
};

export const onChangeCellSettings = async (
  value: ICellSettings,
  prevValue: ICellSettings
) => {
  const userId = localStorage.getItem('userId');
  const generate = (val: ICellSettings) => ({
    userId,
    preferences: [
      {
        id: 3036,
        userId,
        name: 'calendarCellSettings',
        value: JSON.stringify(val),
        defaultValue: ''
      }
    ]
  });
  const userPreferenceInfo = generate(value);
  try {
    updateCurrentUserPreference(userPreferenceInfo.preferences);
    const response = await setPreference(userPreferenceInfo);
    const data: any = response.data;
    if (data.success) {
    } else {
      const userPreferenceInfo = generate(prevValue);
      updateCurrentUserPreference(userPreferenceInfo.preferences);
    }
  } catch (error) {
    console.log({ error });
    const userPreferenceInfo = generate(prevValue);
    updateCurrentUserPreference(userPreferenceInfo.preferences);
  }
};

export const changeShowHelpIcon = async (value: string) => {
  const userId = localStorage.getItem('userId');
  const userPreferenceInfo = {
    userId,
    preferences: [
      {
        id: 3028,
        userId,
        name: 'UserShowHelpIcon',
        value,
        defaultValue: ''
      }
    ]
  };
  try {
    const response = await setPreference(userPreferenceInfo);
    const data: any = response.data;
    if (data.success && data.result.success) {
      updateCurrentUserPreference(userPreferenceInfo.preferences);
    }
  } catch (error) {
    console.log({ error });
  }
};

export const toggleMinorAlert = async (value: boolean) => {
  const userId = localStorage.getItem('userId');
  const userPreferenceInfo = {
    userId,
    preferences: [
      {
        id: 517,
        userId,
        name: 'MinorAlert',
        value: value ? 'False' : 'True',
        defaultValue: 'True'
      }
    ]
  };
  try {
    const response = await setPreference(userPreferenceInfo);
    const data: any = response.data;
    if (data.success && data.result.success) {
      updateCurrentUserPreference(userPreferenceInfo.preferences);
    }
  } catch (error) {
    console.log({ error });
  }
};

export const setApptBackground = async (
  type: string,
  colors?: { [key: string]: string }
) => {
  const userId = localStorage.getItem('userId');
  const userPreferenceInfo = {
    userId,
    preferences: [
      {
        id: 3034,
        userId,
        name: 'ApptBackgroundColor',
        value: JSON.stringify({
          radio: type,
          colors: colors ? colors : defaultApptBackgroundSettings.colors
        }),
        defaultValue: JSON.stringify(defaultApptBackgroundSettings)
      }
    ]
  };
  try {
    const response = await setPreference(userPreferenceInfo);
    const data: any = response.data;
    if (data.success && data.result.success) {
      updateCurrentUserPreference(userPreferenceInfo.preferences);
    }
  } catch (error) {
    console.error({ error });
  }
};

export const changePreferenceEmail = async (email: string) => {
  const userId = localStorage.getItem('userId');
  const userPreferenceInfo = {
    userId,
    preferences: [
      {
        id: 3027,
        userId,
        name: 'UserApptSubscriptionEmail',
        value: email,
        defaultValue: ''
      }
    ]
  };
  try {
    const response = await setPreference(userPreferenceInfo);
    const data: any = response.data;
    if (data.success && data.result.success) {
      updateCurrentUserPreference(userPreferenceInfo.preferences);
    }
  } catch (error) {
    console.log({ error });
  }
};

export const getFacilities = async () => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.FACILITIES}`,
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      if (data) {
        setFacilities(data);
      }
      return data;
    }
    return;
  } catch (e) {
    return;
  }
};

export const get = async () => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.GET_APPT_SUBSCRIPTIONS}`,
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const data = response.data.result.summary;
      if (data) {
        setData(data);
      }
      return data;
    }
    return;
  } catch (e) {
    return;
  }
};

export const post = async (body: Partial<IApptSubscription>) => {
  try {
    const UserId = store.getState().user.current.userId;
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.GET_APPT_SUBSCRIPTIONS}`,
      { ...body, UserId },
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const data = Object.values(response.data.result.summary)[0];
      if (data) {
        onChangeDataItem(data);
      }
      return data;
    }
    return;
  } catch (e) {
    return;
  }
};

export const removeItem = async (body: Partial<IApptSubscription>) => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.GET_APPT_SUBSCRIPTIONS}`,
      body,
      {
        withCredentials: true,
        interceptError: true,
        params: {
          id: body.Id
        }
      }
    );
    if (response.data.success) {
      const data = Object.values(response.data.result.summary);
      data.forEach((v) => {
        onChangeDataItem(v);
      });
      return data;
    }
    return;
  } catch (e) {
    return;
  }
};

export const patch = async (body: Partial<IApptSubscription>) => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.GET_APPT_SUBSCRIPTIONS}`,
      body,
      {
        withCredentials: true
      }
    );
    if (response.data.success) {
      const data = Object.values(response.data.result.summary);
      data.forEach((v) => {
        onChangeDataItem(v);
      });
      return data;
    }
    return;
  } catch (e) {
    return;
  }
};

export const onChangeCheckbox = async (value: 'true' | 'false') => {
  const { user } = store.getState();
  const userId = user.current.userId;
  const data = {
    userId,
    preferences: [
      {
        userId,
        id: 3026,
        name: 'UserApptSubscription',
        value,
        defaultValue: ''
      }
    ]
  };
  try {
    await setPreference(data);
    updateCurrentUserPreference(data.preferences);
  } catch (e) { }
};

export const onChangeTimeFormat = async (value: any) => {
  const { user } = store.getState();
  const userId = user.current.userId;
  const data = {
    userId,
    preferences: [
      {
        userId,
        id: 811,
        name: '', // !! NEED TO ADD
        value,
        defaultValue: ''
      }
    ]
  };
  try {
    await setPreference(data);
    updateCurrentUserPreference(data.preferences);
  } catch (e) { }
};

export const onChangeDateFormat = async (value: any) => {
  const { user } = store.getState();
  const userId = user.current.userId;
  const data = {
    userId,
    preferences: [
      {
        userId,
        id: 810,
        name: '', // !! NEED TO ADD
        value,
        defaultValue: ''
      }
    ]
  };
  try {
    await setPreference(data);
    updateCurrentUserPreference(data.preferences);
  } catch (e) { }
};

export const onChangeBillingFacilityId = async (
  DefFacilityID: any,
  DefFacilityCode: any
) => {
  const { user } = store.getState();
  const userId = user.current.userId;
  const data = {
    userId,
    preferences: [
      {
        userId,
        id: 814,
        name: 'DefFacilityID',
        value: DefFacilityID,
        defaultValue: ''
      },
      {
        userId,
        id: 822,
        name: 'DefFacilityCode',
        value: DefFacilityCode,
        defaultValue: ''
      }
    ]
  };
  try {
    await setPreference(data);
    updateCurrentUserPreference(data.preferences);
  } catch (e) { }
};

export const onChangeBillingProvider = async (
  DefProviderID: any,
  DefProviderCode: any
) => {
  const { user } = store.getState();
  const userId = user.current.userId;
  const data = {
    userId,
    preferences: [
      {
        userId,
        id: 818,
        name: 'DefProviderID',
        value: DefProviderID,
        defaultValue: ''
      },
      {
        userId,
        id: 819,
        name: 'DefProviderCode',
        value: DefProviderCode,
        defaultValue: ''
      }
    ]
  };
  try {
    await setPreference(data);
    updateCurrentUserPreference(data.preferences);
  } catch (e) { }
};

export const onChangeCurrentUserProvider = async (
  UserProviderId: any,
  UserProviderCode: any
) => {
  const { user } = store.getState();
  const userId = user.current.userId;
  const data = {
    userId,
    preferences: [
      {
        userId,
        id: 812,
        name: 'ProviderID',
        value: UserProviderId,
        defaultValue: ''
      },
      {
        userId,
        id: 820,
        name: 'ProviderCode',
        value: UserProviderCode,
        defaultValue: ''
      }
    ]
  };
  try {
    await setPreference(data);
    updateCurrentUserPreference(data.preferences);
  } catch (e) { }
};
export const onChangeBillingClinic = async (
  BillingClinicId: any,
  BillingClinicCode: string
) => {
  const { user } = store.getState();
  const userId = user.current.userId;
  const data = {
    userId,
    preferences: [
      {
        userId,
        id: 813,
        name: 'DefClinicID',
        value: BillingClinicId,
        defaultValue: ''
      },
      {
        userId,
        id: 821,
        name: 'DefClinicCode',
        value: BillingClinicCode,
        defaultValue: ''
      }
    ]
  };
  try {
    await setPreference(data);
    updateCurrentUserPreference(data.preferences);
  } catch (e) { }
};
export const onChangeSystemShortcuts = async (settings: ISystemShortcuts) => {
  const { user } = store.getState();
  const userId = user.current.userId;
  const data = {
    userId,
    preferences: [
      {
        userId,
        id: 3049,
        name: 'systemShortcuts',
        value: JSON.stringify(settings),
        defaultValue: ''
      }
    ]
  };
  try {
    await setPreference(data);
    updateCurrentUserPreference(data.preferences);
  } catch (e) { }
};

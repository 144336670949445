import React, { FC, useState } from 'react';
import { Tooltip } from 'antd';
import Icon from 'src/Framework/Controls/Icon';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import {
  IApptResponse,
  IScheduleEvent
} from '../../Store/ScheduleEventsReducer/types';
import { patientDisplayName } from 'src/Framework/util/format';

import { GroupContainer, LoadingContainer } from './styled';

interface IProps {
  value: IScheduleEvent;
  getEvent: (
    appointmentId: number,
    scheduleEventTypeId?: number
  ) => Promise<IApptResponse | undefined>;
}

const getIcon = (typeAppointmentParticipantId: number) => {
  switch (typeAppointmentParticipantId) {
    case 1:
      return <Icon name="user2" />;
    case 2:
      return <Icon name="usersGroup" />;
    case 3:
      return <Icon name="family" />;
    default:
      return <Icon name="groupList" />;
  }
};

const Component: FC<IProps> = ({ value, getEvent }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState<IApptResponse | null>(null);
  const getData = async () => {
    setLoading(true);
    const res = await getEvent(
      value.appointmentId,
      value.scheduleEventTypeId === 4 ? 4 : undefined
    );
    if (res) {
      setEvent(res);
    }
    setLoading(false);
  };
  return (
    <Tooltip
      title={
        <GroupContainer>
          {loading && (
            <LoadingContainer>
              <AbsoluteLoader backgroundColor="transparent" />
            </LoadingContainer>
          )}
          {event?.patientsInfo?.map((v) => patientDisplayName(v)).join(', ')}
        </GroupContainer>
      }
      overlayStyle={{ zIndex: 10000 }}
    >
      <div
        style={{ width: 22 }}
        onMouseOver={() => {
          if (!event && !loading) {
            getData();
          }
        }}
      >
        {getIcon(value.typeAppointmentParticipantId)}
      </div>
    </Tooltip>
  );
};

export default Component;

import store from 'src/store';

import { actionsTypes } from './index';

import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

import { ISummary, IInsuranceCompany, IGenericTypes, IFilter } from './types';
import { ISort } from 'src/Framework/Controls/Table/types';

const setData = (payload: ISummary) => {
  store.dispatch({
    type: actionsTypes.SET,
    payload
  });
};

const setLoading = (payload: boolean) => {
  store.dispatch({
    type: actionsTypes.SET_LOADING,
    payload
  });
};

export const setSorting = (payload: ISort) => {
  store.dispatch({
    type: actionsTypes.SET_TABLE_SORT,
    payload
  });
};

export const setFilter = (payload: IFilter) => {
  store.dispatch({
    type: actionsTypes.SET_FILTER,
    payload
  });
};

const onChangeItem = (data: IInsuranceCompany) => {
  const payload = { ...store.getState().adminPanel.insuranceCompanies.summary };
  payload[data.id] = {
    ...data
  };
  setData(payload);
};

export const patch = async (body: Partial<IInsuranceCompany>) => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.GET_INSURANCE_COMPANIES}/${body.id}`,
      body,
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data) {
      if (response.data.success) {
        const data: any = Object.values(response.data.result.summary)[0];
        onChangeItem(data);
        return data;
      }
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const post = async (body: Partial<IInsuranceCompany>) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.GET_INSURANCE_COMPANIES,
      { ...body },
      { withCredentials: true }
    );
    if (response.data) {
      if (response.data.success) {
        const data: any = Object.values(response.data.result.summary)[0];
        onChangeItem(data);
        return data;
      }
    }
    return;
  } catch (e) {
    return;
  } finally {
  }
};

export const get = async () => {
  try {
    setLoading(true);
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.GET_INSURANCE_COMPANIES,
      { withCredentials: true }
    );
    if (response.data) {
      if (response.data.success) {
        const data = response.data.result.summary;
        setData(data);
        return data;
      }
    }
    return;
  } catch (e) {
    return;
  } finally {
    setLoading(false);
  }
};

const setGenericTypes = (payload: Partial<IGenericTypes>) => {
  store.dispatch({
    type: actionsTypes.SET_GENERIC_TYPES,
    payload
  });
};

const getType = async (link: string, fieldName: keyof IGenericTypes) => {
  try {
    const response = await APIHandler.AxiosInstance.get(link, {
      withCredentials: true
    });
    if (response.data) {
      if (response.data.success) {
        const data = response.data.result.summary;
        setGenericTypes({
          [fieldName]: data
        });
        return data;
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const getGenericTypes = async () => {
  getType(ApiUrl.GroupInsurance, 'groupInsurance');
  getType(ApiUrl.TypeProviderClaimLogic, 'typeProviderClaimLogic');
  getType(ApiUrl.TypeInsurance, 'insuranceType');
  getType(ApiUrl.GroupFacility, 'groupFacility');
  getType(ApiUrl.TypeFee, 'typeFee');
};

import React from 'react';
import moment from 'moment';
import { Button, Row as AntdRow, Col, Space } from 'antd';
import _ from 'lodash';

import SelectContainer from 'src/Framework/Controls/SelectContainer';
import HistoryPopUp from 'src/Framework/Controls/HistoryPopUp';
import ReasonSelector from 'src/Framework/Controls/Selectors/ReasonSelector';
import { providerDisplayName } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import Tooltip from 'src/Framework/Controls/Tooltip';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import Collapse from 'src/Framework/Controls/CollapseA11y';
import RemoveButton from 'src/Framework/Controls/Table/RemoveButton';
import EditButton from 'src/Framework/Controls/Table/EditButton';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import NoData from 'src/Framework/Controls/Table/NoData';
import { patientDisplayName } from 'src/Framework/util/format';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { formattedDateTime, formattedName } from 'src/Framework/Shared/Shared';
import store from 'src/store';
import tests_ids from 'src/tests-script/pages/Schedule/SingleApptPopup';

import { Row2 as Row, Info } from 'src/Activities/Schedule/Common/Classes';
import {
  getActiveStatusClass,
  statusProgressHandler,
  buildRecurrenceString
} from 'src/Activities/Schedule/Utils/Utils';
import { RecurrenceDisplay } from 'src/Activities/Schedule/Common/Editors/RecurrenceDisplay';
import { IApptResponse } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import { getAppointmentLog } from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import * as scheduleEventActions from 'src/Activities/Schedule/Store/ScheduleEventsReducer/Actions';
import PatientHistory from 'src/Activities/Schedule/PatientHistory';
import AdditionalContacts from 'src/Activities/Schedule/AdditionalContacts';
import {
  ActivityPath,
  gotoClient
} from 'src/App/ActivityPanel/Store/ActivityActions';
import PolicyStatusContainer from 'src/App/Hippa/Components/PolicyStatusContainer';
import { isPrivacyPolicyEnabled } from 'src/App/Hippa/Components/utils';
import { permissionChecking } from 'src/App/User/Permission';
import EditAdditionalInfo from './EditAdditionalInfo';
import {
  EApptTypeCode,
  EEventTypeCode,
  eventPermission
} from 'src/Activities/Schedule/utils';

import AlertsManageContainer from '../AppointmentPopup/AlertsManageContainer';
import { IAlertsData } from '../AppointmentPopup/AppointmentPopup';
import AlertsBar, { calculateAlertsCount } from '../AppointmentPopup/AlertsBar';
import PatientDetail from 'src/Activities/Schedule/Common/Editors/PatientDetail';

import EditRClickPopup from './EditRClickPopup';
import Telehealth from './Telehealth';
import AttachedNotes from './AttachedNotes';
import StatusesContainer from './Status';

import {
  PopupHeader,
  PopupBody,
  PopupTitle,
  AgeContainer,
  SexContainer,
  AccountNumberContainer,
  PreferedPhoneContainer,
  InsuranceContainer,
  OtherIDContainer,
  EligibilityContainer,
  HistoryColumnTitle,
  HistoryColumnItem,
  NoteContainer,
  PopupContainer,
  CancelPopupContainer,
  CancelContentBlock,
  CancelConfirmBlock,
  MessageBlock,
  AppointmentRemovalBlock,
  AppointmentInfoBlock,
  AppointmentRemovalTitle,
  TimeSpan,
  PronounsContainer,
  ProviderSpan,
  BlockSelfCheckIn,
  HeaderButtons,
  MainBody
} from './ScheduleDetailPopup_Styles';
import { ButtonWrapper } from 'src/Activities/SystemSetup/NotificationManager/NotificationViewer/NotificationViewer_Styles';
import { DPContainer } from 'src/App/SideNavBar/styled';
import {
  ButtonContainer,
  ButtonSpan
} from 'src/Activities/Subject/Facts/Facts/styles';
import { ErrorBlock } from '../BlockReservationPopup/BlockReservationPopup_Styles';
import { ReasonSelectContainer } from '../AppointmentPopup/AppointmentPopup_Styles';
import { TextEllipsisSpan } from 'src/Activities/Schedule/Main/ScheduleCol/ScheduleOne/styled';
import { FormSpan } from '../ScheduleGroupPopup/GroupMembersDetail/styled';
import { Footer, PopupBodyInnerContainer, ContainerBlock } from './styled';
import ClinicList from './ClinicList';
import { getNoteCreationPermission } from 'src/Activities/Subject/utils';
import LocationInfo from 'src/Activities/Schedule/Common/LocationInfo';

const { Panel } = Collapse;

interface IState {
  showRClickPopup: string;
  cancelPopup: boolean;
  cancellationReasonCode: any;
  cancellationError: boolean;
  editSeries: boolean;
  cancellationNote: string;
  data: IApptResponse;
  additionalContantsExpand: boolean;
  log: any;
  alertsData: IAlertsData;
}

export interface IProps {
  onDelete: any;
  onClosePopup: () => void;
  onEditSchedule: (appointmentId: number, series?: boolean) => void;
  canEdit?: boolean;
  data: IApptResponse;
  subjectSummary: any;
  setRecordChanged?: () => void;
  callerRef?: React.MutableRefObject<any>;
  onClickAlertsView?: (data: IAlertsData) => any;
  getSummary?: (patientId: number) => Promise<any>;
}

class ScheduleDetailPopup extends React.Component<IProps, IState> {
  componentRef: React.RefObject<any>;
  editButtonRef: React.RefObject<any>;
  deleteButtonRef: React.RefObject<any>;

  constructor(props: IProps) {
    super(props);
    this.componentRef = React.createRef();
    this.editButtonRef = React.createRef();
    this.deleteButtonRef = React.createRef();
    this.state = {
      editSeries: false,
      cancelPopup: false,
      cancellationReasonCode: null,
      cancellationError: false,
      showRClickPopup: '',
      cancellationNote: '',
      data: props.data,
      log: [],
      additionalContantsExpand: false,
      alertsData: {
        patientAlerts: {},
        insurances: {},
        isUnderAge: {},
        patients: []
      }
    };
  }

  componentDidMount = () => {
    this.getLogs();
    if (this.componentRef.current) {
      (this.componentRef.current as HTMLElement).focus();
    }
  };

  getLogs = async () => {
    try {
      const { data } = this.state;
      const { scheduleEvent } = data;
      const response = await getAppointmentLog({
        subjectId: scheduleEvent.patient.id,
        appointmentId: scheduleEvent.appointmentId
      });
      if (response.data.success) {
        this.setState({
          log: response.data.result
        });
      }
    } catch (e) {
    } finally {
    }
  };

  onClickDelete = async (subjectId: any) => {
    let { editSeries, cancellationNote, data } = this.state;
    let { scheduleEvent } = data;

    let details = {
      cancelNote: cancellationNote,
      cancelReasonCode: this.state.cancellationReasonCode,
      subjectId,
      appointmentId: scheduleEvent.appointmentId,
      scheduleEventTypeId: 1,
      cancelSeries: editSeries,
      cancelGroup: true,
      cancelDate:
        moment(scheduleEvent.scheduleEventDateTime).format('YYYY-MM-DD') +
        'T00:00:00.000'
    };
    if (details.cancelReasonCode) {
      await this.props.onDelete(details);
      this.props.onClosePopup();
    } else {
      this.setState({
        cancellationError: true
      });
    }
  };

  onClose = () => {
    if (this.state.showRClickPopup === 'Edit') {
      if (this.editButtonRef.current) {
        //@ts-ignore
        this.editButtonRef.current.focus();
      }
    } else if (this.state.showRClickPopup === 'Remove') {
      if (this.deleteButtonRef.current) {
        //@ts-ignore
        this.deleteButtonRef.current.focus();
      }
    }
    this.setState({
      showRClickPopup: ''
    });
  };

  onStatusChange = async (newStatus: any) => {
    this.props.setRecordChanged && this.props.setRecordChanged();
    const { data } = this.state;
    const eventInfo = data;
    if (eventInfo) {
      const patientId = eventInfo.patients[0];
      const status = {
        ...eventInfo.patientStatus[patientId]
      };
      const details: any = statusProgressHandler(newStatus, status);
      const payload = {
        appointmentId: eventInfo.scheduleEvent['appointmentId'],
        subjectId: patientId,
        status: {
          ...details
        },
        dts: new Date()
      };
      const response = await scheduleEventActions.updatePatientStatus(payload);
      if (response) {
        const { data } = this.state;
        this.setState({
          data: {
            ...data,
            patientStatus: {
              ...data.patientStatus,
              [patientId]: response.status
            },
            scheduleEvent: {
              ...data.scheduleEvent,
              status: response.status
            }
          }
        });
      }
    }
  };

  onSelectCancellationReason = (cancellationReasonCode: any) => {
    this.setState({
      cancellationReasonCode,
      cancellationError: false
    });
  };

  deleteConfirmation = () => {
    const { data } = this.state;
    const { status, comment } = data.scheduleEvent;
    const enabled = !(
      status &&
      (status.ready || status.checkedIn || status.admit || status.discharged)
    );
    if (enabled) {
      this.setState({
        cancelPopup: true,
        cancellationNote: comment
      });
    }
  };

  onClickDeleteIcon = (patient: any) => {
    if (patient.inSeries) {
      this.showRClickPopup('Remove');
    } else {
      this.deleteConfirmation();
    }
  };

  onClickEditIcon = () => {
    const { data } = this.state;
    const inSeries = data.scheduleEvent.inSeries;
    if (!inSeries) {
      this.props.onEditSchedule(data.scheduleEvent.appointmentId);
      this.props.onClosePopup();
    } else {
      this.showRClickPopup('Edit');
    }
  };

  editSingle = () => {
    const { showRClickPopup, data } = this.state;
    const eventInfo =
      store.getState().scheduleEvents[data.scheduleEvent.appointmentId];
    if (eventInfo) {
      eventInfo.updateSeries = false;
    }
    if (showRClickPopup === 'Remove') {
      this.deleteConfirmation();
    } else if (showRClickPopup === 'Edit') {
      this.props.onEditSchedule(data.scheduleEvent.appointmentId);
      this.props.onClosePopup();
    }
    this.setState({
      showRClickPopup: '',
      editSeries: false
    });
  };

  editSeries = () => {
    const { showRClickPopup, data } = this.state;
    if (showRClickPopup === 'Remove') {
      this.deleteConfirmation();
    } else if (showRClickPopup === 'Edit') {
      this.props.onEditSchedule(data.scheduleEvent.appointmentId, true);
      this.props.onClosePopup();
    }
    this.setState({
      editSeries: true,
      showRClickPopup: ''
    });
  };

  showRClickPopup = (type: string) => {
    this.setState({
      showRClickPopup: type
    });
  };

  // onClickRemoveForm = (f: any) => {
  //   if (f.record && f.record.patientId > 0) {
  //     let subjectId = f.record?.patientId;
  //     f.inactive = true;
  //     f.record.inactive = true;
  //     APIHandler.AxiosInstance.post(
  //       ApiUrl.REMOVE_FORM,
  //       {
  //         form: { ...f }
  //       },
  //       { withCredentials: true }
  //     )
  //       .then((response) => {
  //         let data: any = response.data;
  //         if (data.success) {
  //           const { data } = this.state;
  //           let forms = data.patientForms[subjectId];
  //           let index = forms.findIndex(
  //             (form: any) => form.seedFormId === f.seedFormId
  //           );
  //           let upatedFormsList = _.cloneDeep(forms);
  //           upatedFormsList.splice(index, 1);
  //           let newEventPayload = data;
  //           newEventPayload.patientForms[subjectId] = upatedFormsList;
  //           store.dispatch(addScheduleEvent(newEventPayload));
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // };

  onChangeAlertsData = (alertsData: any) => {
    this.setState({ alertsData });
  };

  render() {
    const { subjectSummary, onClickAlertsView, getSummary } = this.props;
    const { showRClickPopup, data, alertsData } = this.state;
    const event = data?.scheduleEvent;
    const status = event?.status;
    let date =
      event &&
      moment(event.scheduleEventDateTime).format('YYYY-MM-DD') + 'T00:00:00';
    const currentDate = moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00';
    const eventInfoDrag = event;
    const patientForms = data?.patientForms
      ? data?.patientForms[subjectSummary.subjectId]
      : [];
    let forms = [];
    if (patientForms) {
      forms = _.filter(patientForms, (form: any) => {
        return !form.inactive;
      });
    }
    let providerNames = '';
    data?.providerCodes?.map((item: any) => {
      providerNames =
        providerNames + (providerNames.length === 0 ? '' : ',') + item.name;
    });
    // let history: any = _.orderBy(
    //   this.state.history,
    //   (o: any) => {
    //     return moment(o.scheduleEventDateTime).format('YYYYMMDD');
    //   },
    //   ['desc']
    // );
    let log: any = _.orderBy(this.state.log, (o: any) => o.dateModified, [
      'desc'
    ]);
    if (log && log.length > 0) {
      const createdLogIndex = log.findIndex(
        (logItem: any) => logItem.action === 'Created'
      );
      if (createdLogIndex > 0) {
        log.push(log.splice(createdLogIndex, 1)[0]);
      }
    }
    const formFillingInfo = (formRecord: any) => {
      return {
        filled: formRecord.viaPortal
          ? !!formRecord.patientComplete
          : !!formRecord.completed,
        filledBy: formRecord.viaPortal ? getPatientTerm('student') : 'provider',
        fullName: formRecord.viaPortal
          ? patientDisplayName(
              this.props.data.patientsInfo.find(
                (item) => formRecord.patientId === item.subjectId
              )
            )
          : providerDisplayName(formRecord.providerId),
        filledDate: formRecord.viaPortal
          ? formattedDateTime(formRecord.patientCompleteDate)
          : formattedDateTime(formRecord.signedDate)
      };
    };

    const onKeyDownContainer = (e: React.KeyboardEvent) => {
      if (e.shiftKey && e.code === 'Tab' && e.target === e.currentTarget) {
        if (this.props.callerRef?.current) {
          e.preventDefault();
          (this.props.callerRef.current as HTMLElement).focus();
        }
      }
      if (e.code === 'Escape') {
        this.props.onClosePopup();
        if (this.props.callerRef?.current) {
          e.preventDefault();
          (this.props.callerRef.current as HTMLElement).focus();
        }
      }
      if (e.key === 'Enter') {
        e.stopPropagation();
      }
      if (
        !e.shiftKey &&
        e.code === 'Tab' &&
        (e.target as HTMLElement).className.includes('ant-collapse-header') &&
        (e.target as HTMLElement).parentElement?.nextElementSibling === null &&
        (!(e.target as HTMLElement).parentElement?.className.includes(
          'ant-collapse-item-active'
        ) ||
          log.length === 0)
      ) {
        if (this.componentRef?.current) {
          e.preventDefault();
          (this.componentRef?.current as HTMLElement).focus();
        }
      }
    };

    const enabled = !(
      status &&
      (status.ready || status.checkedIn || status.admit || status.discharged)
    );
    const isLate = getActiveStatusClass(event, 'Late', status);
    const isPolicyEnabled = isPrivacyPolicyEnabled();

    const currentClinicsPermission = permissionChecking({
      anyClinic: false,
      clinicId: eventInfoDrag?.clinicId,
      moduleId: 40,
      functionId: 1205
    });

    forms.sort((a, b) => (a.name || '').localeCompare(b.name || ''));

    const apptPermission = eventPermission(
      EEventTypeCode.appointment,
      EApptTypeCode.individual
    );

    return (
      <>
        {showRClickPopup && showRClickPopup.length > 0 ? (
          <EditRClickPopup
            onClosePopup={() => this.onClose()}
            type={showRClickPopup}
            editSeries={() => this.editSeries()}
            editSingle={() => this.editSingle()}
            eventType="appointment"
            buttonRef={
              this.state.showRClickPopup === 'Edit'
                ? this.editButtonRef
                : this.deleteButtonRef
            }
          />
        ) : null}
        <AlertsManageContainer
          locationIndex={3}
          selectedSubjects={[
            {
              ...subjectSummary,
              subjectId: subjectSummary.subjectId
            }
          ]}
          onChangeAlertsData={this.onChangeAlertsData.bind(this)}
        />
        <PopupContainer
          tabIndex={0}
          ref={this.componentRef}
          onKeyDown={onKeyDownContainer}
          id={'appt-popup-container'}
        >
          <PopupHeader>
            <PopupTitle>
              <DPContainer
                style={{
                  height: '40px',
                  width: '40px',
                  marginRight: '2rem',
                  fontSize: '24px',
                  borderRadius: '4px'
                }}
              >
                <span style={{ fontSize: '17px' }}>
                  {subjectSummary &&
                    subjectSummary.firstName[0] + subjectSummary.lastName[0]}
                </span>
              </DPContainer>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column'
                }}
              >
                <div
                  style={
                    (data.patientsInfo &&
                      data.patientsInfo.length > 0 &&
                      data.patientsInfo[0]?.highRisk) ||
                    (subjectSummary && subjectSummary.nickName.length > 0)
                      ? { lineHeight: 'initial' }
                      : {}
                  }
                >
                  <EllipsisText maxWidth={360}>
                    {formattedName(event.patient)}
                  </EllipsisText>
                </div>
                {subjectSummary && subjectSummary.nickName.length > 0 && (
                  <div
                    style={{
                      fontWeight: 400,
                      paddingRight: '1rem',
                      fontSize: '15px',
                      lineHeight: 'initial'
                    }}
                  >
                    <EllipsisText maxWidth={360}>
                      {subjectSummary && subjectSummary.nickName.length > 0
                        ? `"${subjectSummary.nickName}"`
                        : null}
                    </EllipsisText>
                  </div>
                )}
                <span className="text-ellipsis">
                  {/* {eventInfo.patientsInfo[0]?.highRisk && (
                      <HighRiskContainer>
                        {eventInfo.patientsInfo[0]?.highRisk ? 'High Risk' : ''}
                      </HighRiskContainer>
                    )} */}
                </span>
              </div>
            </PopupTitle>
            <HeaderButtons id={tests_ids.headerButtons}>
              <AntdRow justify="end" align="middle" gutter={16} wrap={false}>
                <Col>
                  <ButtonIcon
                    isButton={true}
                    label={`${getPatientTerm('Subject')} Info`}
                    id={tests_ids.buttons.userInfo}
                    changeColorOnHover={true}
                    name={ICONS_LIST.user}
                    tooltip={`${getPatientTerm('Subject')} Info`}
                    onClick={() =>
                      gotoClient(
                        `${subjectSummary.subjectId}`,
                        ActivityPath.CLIENT_DEMOGRAPHICS,
                        this.state.data.scheduleEvent.patientInfo
                      )
                    }
                  />
                </Col>
                <Col>
                  <ButtonIcon
                    isButton={true}
                    label={`${getPatientTerm('Subject')} Forms`}
                    id={tests_ids.buttons.forms}
                    changeColorOnHover={true}
                    name={ICONS_LIST.document}
                    tooltip={`Forms`}
                    onClick={() =>
                      gotoClient(
                        `${subjectSummary.subjectId}`,
                        ActivityPath.COUNSELING_FORMS,
                        this.state.data.scheduleEvent.patientInfo
                      )
                    }
                  />
                </Col>
                <Col>
                  <ButtonIcon
                    isButton={true}
                    label="Go to Chart"
                    id={tests_ids.buttons.chart}
                    changeColorOnHover={true}
                    tooltip="Go to Chart"
                    name={ICONS_LIST.folder}
                    onClick={() => {
                      gotoClient(
                        `${subjectSummary.subjectId}`,
                        ActivityPath.COUNSELING_DASHBOARD2,
                        this.state.data.scheduleEvent.patientInfo
                      );
                    }}
                  />
                </Col>
                {getNoteCreationPermission().success && (
                  <Col>
                    <ButtonIcon
                      disabled={!currentClinicsPermission.success}
                      isButton={true}
                      label={`Create Note`}
                      id={tests_ids.buttons.createNote}
                      changeColorOnHover={true}
                      name={ICONS_LIST.apptNote}
                      tooltip={
                        currentClinicsPermission.success
                          ? `Create Note`
                          : currentClinicsPermission.message
                      }
                      onClick={() =>
                        gotoClient(
                          `${subjectSummary.subjectId}`,
                          ActivityPath.CREATE_NOTE,
                          {
                            appointmentId: data.scheduleEvent.appointmentId
                          }
                        )
                      }
                    />
                  </Col>
                )}

                {this.props.canEdit !== false && (
                  <Col>
                    <EditButton
                      buttonProps={{
                        disabled: !apptPermission.success,
                        containerProps: {
                          ref: this.editButtonRef
                        },
                        id: tests_ids.buttons.edit,
                        tooltip: apptPermission.success
                          ? 'Edit Appointment'
                          : apptPermission.message,
                        asyncOnClick: async () => {
                          // await scheduleEventActions.getAppointment(
                          //   data.scheduleEvent.appointmentId
                          // );
                          this.onClickEditIcon();
                        }
                      }}
                      onClick={() => {}}
                    />
                  </Col>
                )}
                <Col>
                  <RemoveButton
                    id={tests_ids.buttons.remove}
                    buttonIconProps={{
                      containerProps: {
                        ref: this.deleteButtonRef
                      },
                      disabled: !enabled,
                      tooltip: enabled
                        ? 'Cancel Appointment'
                        : "Can't cancel after Client check-in"
                    }}
                    removeAction={async (e: any) => {
                      if (enabled) {
                        this.onClickDeleteIcon(event);
                      }
                    }}
                  />
                </Col>
                <Col>
                  <ButtonIcon
                    isButton={true}
                    label="Close dialog"
                    id={tests_ids.buttons.close}
                    changeColorOnHover={true}
                    name={ICONS_LIST.thinClose}
                    iconScale={1.9}
                    tooltip="Close dialog"
                    onClick={() => {
                      this.props.onClosePopup();
                      if (this.props.callerRef?.current) {
                        (this.props.callerRef.current as HTMLElement).focus();
                      }
                    }}
                  />
                </Col>
              </AntdRow>
            </HeaderButtons>
          </PopupHeader>
          <MainBody>
            {this.state.cancelPopup ? (
              <CancelPopupContainer>
                <CancelContentBlock>
                  <AppointmentRemovalBlock>
                    <AppointmentRemovalTitle>
                      {this.state.editSeries
                        ? 'Appointment Series Removal'
                        : 'Single Appointment Removal'}
                    </AppointmentRemovalTitle>
                    <ReasonSelectContainer style={{ width: '290px' }}>
                      <ReasonSelector
                        id={tests_ids.cancellationReason}
                        clinicCodes={[eventInfoDrag?.clinicCode]}
                        name="Reason for Appointment removal"
                        reasonTypes={['cancellation']}
                        useCode={true}
                        value={this.state.cancellationReasonCode}
                        onChange={(value: any) => {
                          this.onSelectCancellationReason(value);
                        }}
                      />
                      <ErrorBlock error={this.state.cancellationError}>
                        Field is mandatory
                      </ErrorBlock>
                    </ReasonSelectContainer>
                    <NoteContainer style={{ paddingTop: 0 }}>
                      <div className="Note">
                        <SelectContainer
                          id={tests_ids.cancellationNoteArea}
                          label="Note"
                          type="area"
                          star={false}
                          value={this.state.cancellationNote}
                          onChange={(cancellationNote: string) =>
                            this.setState({ cancellationNote })
                          }
                          inputProps={{
                            maxLength: 4000,
                            wrap: true,
                            style: {
                              height: '194px',
                              width: '290px',
                              textAlignVertical: 'top'
                            }
                          }}
                          areaAutoReplace={true}
                        />
                      </div>
                      <ErrorBlock error={false}>Field is mandatory</ErrorBlock>
                    </NoteContainer>
                  </AppointmentRemovalBlock>
                  <AppointmentInfoBlock>
                    <Row
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'scroll',
                        paddingTop: '24px',
                        height: '100%',
                        background: '#F7F7F7'
                      }}
                    >
                      <InsuranceContainer style={{ width: '260px' }}>
                        <label>Clinic</label>
                        <Info>{eventInfoDrag && eventInfoDrag.clinicName}</Info>
                      </InsuranceContainer>
                      <div style={{ margin: '2rem 0' }}>
                        <label>Provider</label>
                        <Info>
                          {data?.providerCodes
                            ? data?.providerCodes
                                .map((v: any) => v.name)
                                .join(', ')
                            : ''}
                        </Info>
                      </div>
                      {data.scheduleEvent?.inSeries ? (
                        <>
                          <OtherIDContainer
                            style={{ paddingTop: '2rem', width: 'unset' }}
                          >
                            <label>Recurrence</label>
                            <Info>
                              {buildRecurrenceString(
                                data.scheduleEvent?.series
                              )}
                            </Info>
                          </OtherIDContainer>
                        </>
                      ) : (
                        <>
                          {subjectSummary &&
                          subjectSummary.otherId &&
                          subjectSummary.otherId.length > 0 ? (
                            <OtherIDContainer style={{ paddingTop: '2rem' }}>
                              <label>Date</label>
                              <Info>
                                {eventInfoDrag &&
                                  moment(
                                    eventInfoDrag.scheduleEventDateTime
                                  ).format('MM/DD/YYYY')}
                              </Info>
                            </OtherIDContainer>
                          ) : null}
                          {subjectSummary &&
                          subjectSummary.otherId &&
                          subjectSummary.otherId.length > 0 ? (
                            <OtherIDContainer style={{ paddingTop: '2rem' }}>
                              <label>Time</label>
                              <Info>
                                {eventInfoDrag &&
                                  moment(
                                    eventInfoDrag.scheduleEventDateTime
                                  ).format('LT')}
                              </Info>
                            </OtherIDContainer>
                          ) : null}
                        </>
                      )}
                      <PreferedPhoneContainer style={{ paddingTop: '2rem' }}>
                        <label>Reason Code</label>
                        <Info>{eventInfoDrag?.reason.name || ''}</Info>
                      </PreferedPhoneContainer>
                    </Row>
                  </AppointmentInfoBlock>
                </CancelContentBlock>
                <CancelConfirmBlock>
                  <MessageBlock>
                    Are you sure you want to remove appointment?
                  </MessageBlock>
                  <ButtonContainer style={{ padding: '24px', width: 'auto' }}>
                    <ButtonSpan style={{ alignItems: 'center' }}>
                      <ButtonWrapper
                        id={tests_ids.cancelRemovePopupButton}
                        onClick={(e: any) => {
                          this.setState({ cancelPopup: false });
                        }}
                      >
                        <Button
                          className={'ant-btn'}
                          style={{ border: 'none', boxShadow: 'none' }}
                        >
                          Cancel
                        </Button>
                      </ButtonWrapper>
                      <ButtonWrapper
                        onClick={async (e: any) =>
                          await this.onClickDelete(subjectSummary.subjectId)
                        }
                        id={tests_ids.removeButton}
                      >
                        <Button
                          style={{ minWidth: '80px   ' }}
                          className="active-button-red focused-red-button"
                        >
                          Remove
                        </Button>
                      </ButtonWrapper>
                    </ButtonSpan>
                  </ButtonContainer>
                </CancelConfirmBlock>
              </CancelPopupContainer>
            ) : (
              <>
                {onClickAlertsView && calculateAlertsCount(alertsData) > 0 && (
                  <AlertsBar
                    data={alertsData}
                    onClickAlertsView={onClickAlertsView}
                  />
                )}
                <Footer noDrop={moment(date).isAfter(currentDate)}>
                  <label className={'preview-tab-style'}>CHECK IN STATUS</label>
                  <StatusesContainer
                    isDisabled={moment(date).isAfter(currentDate)}
                    status={status}
                    isLate={isLate}
                    onStatusChange={this.onStatusChange.bind(this)}
                  />
                </Footer>
                <PopupBody id="popupBody">
                  <PopupBodyInnerContainer>
                    <AntdRow
                      align="top"
                      gutter={16}
                      style={{ padding: '8px 24px' }}
                    >
                      {isPolicyEnabled && (
                        <Col>
                          <PolicyStatusContainer
                            statusModal={true}
                            patientId={subjectSummary.subjectId}
                            clinicId={data.scheduleEvent.clinicId}
                          />
                        </Col>
                      )}

                      <Col>
                        <AgeContainer>
                          <label className={'preview-label-style'}>Age</label>
                          <Info>
                            {moment().diff(
                              subjectSummary && subjectSummary.dob,
                              'years'
                            )}
                          </Info>
                        </AgeContainer>
                      </Col>
                      <Col>
                        <SexContainer>
                          <label className={'preview-label-style'}>Sex</label>
                          <Info>{subjectSummary?.sex}</Info>
                        </SexContainer>
                      </Col>

                      {subjectSummary && subjectSummary.chartNo && (
                        <Col>
                          <AccountNumberContainer>
                            <label className={'preview-label-style'}>
                              Chart Number
                            </label>
                            <Info>
                              <EllipsisText>
                                {subjectSummary && subjectSummary.chartNo}
                              </EllipsisText>
                            </Info>
                          </AccountNumberContainer>
                        </Col>
                      )}
                      {subjectSummary && subjectSummary.subjectId && (
                        <Col>
                          <AccountNumberContainer>
                            <label className={'preview-label-style'}>
                              Account Number
                            </label>
                            <Info>{subjectSummary?.subjectId}</Info>
                          </AccountNumberContainer>
                        </Col>
                      )}
                      {subjectSummary && subjectSummary.preferredPronoun ? (
                        <Col>
                          <PronounsContainer>
                            <label className={'preview-label-style'}>
                              Pronouns
                            </label>
                            <Info>{subjectSummary.preferredPronoun}</Info>
                          </PronounsContainer>
                        </Col>
                      ) : null}
                      {eventInfoDrag ? (
                        <Col>
                          <BlockSelfCheckIn>
                            <label className={'preview-label-style'}>
                              Block self check-in
                            </label>
                            <Info>
                              {eventInfoDrag.blockSelfCheckin === true
                                ? 'Yes'
                                : 'No'}
                            </Info>
                          </BlockSelfCheckIn>
                        </Col>
                      ) : null}
                      {data.scheduleEvent.typeAppointmentLocationId ? (
                        <Col>
                          <LocationInfo
                            typeAppointmentLocationId={
                              data.scheduleEvent.typeAppointmentLocationId
                            }
                          />
                        </Col>
                      ) : null}
                    </AntdRow>
                    <Row
                      paddingBottom={true}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '8px 24px'
                      }}
                    >
                      <AntdRow align="top" gutter={16} style={{ flex: 1 }}>
                        <Col span={12}>
                          <label className={'preview-label-style'}>
                            Clinic
                          </label>
                          <Info>
                            {eventInfoDrag && eventInfoDrag.clinicName}
                          </Info>
                        </Col>
                        <Col span={12}>
                          <label className={'preview-label-style'}>Type</label>
                          <Info>
                            {eventInfoDrag &&
                              eventInfoDrag.reason &&
                              eventInfoDrag.reason.name}
                          </Info>
                        </Col>
                      </AntdRow>
                      {eventInfoDrag?.scheduleEventDateTime && (
                        <div>
                          <OtherIDContainer
                            style={{ width: 'auto', paddingRight: '2rem' }}
                          >
                            <label className={'preview-label-style'}>
                              Date
                            </label>
                            <TimeSpan>
                              {moment(
                                eventInfoDrag.scheduleEventDateTime
                              ).format('MM/DD/YYYY')}
                            </TimeSpan>
                          </OtherIDContainer>
                          <OtherIDContainer
                            style={{ width: 'auto', paddingRight: '2rem' }}
                          >
                            <label className={'preview-label-style'}>
                              Time
                            </label>
                            <TimeSpan>
                              {moment(
                                eventInfoDrag.scheduleEventDateTime
                              ).format('hh:mm a') +
                                ' - ' +
                                moment(eventInfoDrag.endTime).format('hh:mm a')}
                            </TimeSpan>
                          </OtherIDContainer>
                        </div>
                      )}
                    </Row>
                    {data.scheduleEvent?.inSeries && (
                      <Row style={{ padding: '8px 24px 0px 24px' }}>
                        {RecurrenceDisplay(data.scheduleEvent?.series, true)}
                      </Row>
                    )}
                    <Row paddingBottom={true} style={{ padding: '8px 24px' }}>
                      <EligibilityContainer style={{ paddingRight: '5rem' }}>
                        <label className={'preview-label-style'}>Comment</label>
                        {eventInfoDrag && eventInfoDrag.comment?.length > 0 && (
                          <Info>{eventInfoDrag.comment}</Info>
                        )}
                      </EligibilityContainer>
                    </Row>
                    <Collapse
                      uppercaseHeader={true}
                      icon="roundIcon"
                      noSidePaddings={true}
                    >
                      <Panel key={'notes'} header={'Notes'}>
                        <AttachedNotes scheduleEvent={data.scheduleEvent} />
                      </Panel>
                      <Panel key={'forms'} header={'Forms'}>
                        <ContainerBlock>
                          {forms.length > 0 ? (
                            <Space wrap={true} size="small">
                              {forms.map((f: any) => {
                                const fillingInfo = formFillingInfo(f.record);
                                return (
                                  <FormSpan completed={fillingInfo.filled}>
                                    <Tooltip
                                      title={
                                        fillingInfo.filled
                                          ? `Filled by ${fillingInfo.filledBy}: ${fillingInfo.fullName}, ${fillingInfo.filledDate} `
                                          : null
                                      }
                                    >
                                      <TextEllipsisSpan
                                        style={{
                                          paddingRight: '0.5rem',
                                          paddingLeft: '0.5rem',
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        {f.name}
                                      </TextEllipsisSpan>
                                    </Tooltip>
                                  </FormSpan>
                                );
                              })}
                            </Space>
                          ) : (
                            <NoData removeVerticalPaddings={true} />
                          )}
                        </ContainerBlock>
                      </Panel>
                      <Panel
                        key={'additional_contacts'}
                        header={'Additional contacts'}
                      >
                        <AdditionalContacts
                          patientId={data.scheduleEvent.patient.id}
                        />
                      </Panel>
                      <Panel
                        key={'appointment_history'}
                        header={'Appointment history'}
                      >
                        <PatientHistory
                          patientId={data.scheduleEvent.patient.id}
                        />
                      </Panel>
                      <Panel
                        key={'Telehealth'}
                        header={
                          <Tooltip
                            title={
                              data.scheduleEvent.isTelehealth
                                ? ''
                                : 'Please enable Telehealth to attach links'
                            }
                          >
                            Telehealth
                          </Tooltip>
                        }
                        collapsible={
                          data.scheduleEvent.isTelehealth
                            ? undefined
                            : 'disabled'
                        }
                      >
                        <Telehealth data={data.scheduleEvent.telehealth} />
                      </Panel>
                      <Panel
                        key={'appointment_providers'}
                        header={'Appointment providers'}
                      >
                        <ContainerBlock>
                          <Space size="small">
                            {data?.providerCodes?.map((f: any) => {
                              return (
                                <span>
                                  <ProviderSpan>
                                    <TextEllipsisSpan
                                      style={{
                                        paddingRight: '0.5rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      {f.name}
                                    </TextEllipsisSpan>
                                  </ProviderSpan>
                                </span>
                              );
                            })}
                          </Space>
                        </ContainerBlock>
                      </Panel>
                      <Panel key={'additional_info'} header={'Additional info'}>
                        <ContainerBlock>
                          <EditAdditionalInfo
                            subjectSummary={subjectSummary}
                            getSummary={getSummary}
                          />
                          <PatientDetail
                            highRisk={
                              this.props.data.patientsInfo?.[0]?.highRisk
                            }
                            action={''}
                            dispatch={undefined}
                            scheduleEvent={data.scheduleEvent}
                            subjectId={
                              this.props.data.patients &&
                              this.props.data.patients[0]
                            }
                            viewMode={true}
                          />
                        </ContainerBlock>
                      </Panel>
                      <Panel
                        key={'appointment_log'}
                        header={'Appointment log'}
                        id={tests_ids.apptLogPanel}
                      >
                        <ContainerBlock>
                          <Row
                            style={{
                              display: 'flex',
                              padding: '8px 0'
                            }}
                          >
                            <HistoryColumnTitle style={{ width: '100px' }}>
                              Date
                            </HistoryColumnTitle>
                            <HistoryColumnTitle style={{ width: '100px' }}>
                              Time
                            </HistoryColumnTitle>
                            <HistoryColumnTitle style={{ width: '150px' }}>
                              User
                            </HistoryColumnTitle>
                            <HistoryColumnTitle style={{ flex: 1 }}>
                              Event
                            </HistoryColumnTitle>
                            <HistoryColumnTitle
                              style={{ width: '48px' }}
                            ></HistoryColumnTitle>
                          </Row>
                          <div>
                            {log?.map((item: any, index: number) => {
                              return (
                                <Row
                                  style={{
                                    display: 'flex',
                                    padding: '8px 0',
                                    alignItems: 'center'
                                  }}
                                >
                                  <HistoryColumnItem style={{ width: '100px' }}>
                                    {moment(item.dateModified).format(
                                      'MM/DD/YYYY'
                                    )}
                                  </HistoryColumnItem>
                                  <HistoryColumnItem style={{ width: '100px' }}>
                                    {moment(item.dateModified).format(
                                      'hh:mm A'
                                    )}
                                  </HistoryColumnItem>
                                  <HistoryColumnItem style={{ width: '150px' }}>
                                    {item.userCode}
                                  </HistoryColumnItem>
                                  <HistoryColumnItem style={{ flex: 1 }}>
                                    {item.action}
                                  </HistoryColumnItem>
                                  <HistoryColumnItem style={{ width: '48px' }}>
                                    {item.differences.length > 0 && (
                                      <HistoryPopUp
                                        isLast={index === log?.length - 1}
                                        parentRef={this.componentRef}
                                        buttonProps={{
                                          name: ICONS_LIST.legend,
                                          iconScale: 1.7
                                        }}
                                        tooltipProps={{
                                          placement: 'leftTop',
                                          getPopupContainer: () =>
                                            document.getElementById(
                                              tests_ids.apptLogPanel
                                            )!
                                        }}
                                        contentProps={{
                                          data: item.differences.map(
                                            (v: any) => ({
                                              ...v,
                                              oldValue:
                                                (v.name
                                                  .toLowerCase()
                                                  .includes('date') ||
                                                  v.name
                                                    .toLowerCase()
                                                    .includes('time')) &&
                                                moment(v.oldValue).isValid()
                                                  ? formattedDateTime(
                                                      v.oldValue
                                                    )
                                                  : v.oldValue,
                                              newValue:
                                                (v.name
                                                  .toLowerCase()
                                                  .includes('date') ||
                                                  v.name
                                                    .toLowerCase()
                                                    .includes('time')) &&
                                                moment(v.newValue).isValid()
                                                  ? formattedDateTime(
                                                      v.newValue
                                                    )
                                                  : v.newValue
                                            })
                                          ),
                                          columns: [
                                            {
                                              title: 'Field',
                                              flex: 'auto',
                                              key: 'name'
                                            },
                                            {
                                              title: 'Before',
                                              flex: '170px',
                                              key: 'oldValue'
                                            },
                                            {
                                              title: 'After',
                                              flex: '170px',
                                              key: 'newValue'
                                            }
                                          ]
                                        }}
                                      />
                                    )}
                                  </HistoryColumnItem>
                                </Row>
                              );
                            })}
                          </div>
                        </ContainerBlock>
                      </Panel>
                      {
                        // this have to be function because we use panel inside, if we will use like component collapse will not know where panel
                      }
                      {data.scheduleEvent.waitingList.id &&
                        ClinicList({ data })}
                    </Collapse>
                  </PopupBodyInnerContainer>
                </PopupBody>
              </>
            )}
          </MainBody>
        </PopupContainer>
      </>
    );
  }
}

export default ScheduleDetailPopup;

import styled from 'styled-components';
import { Container, ColumnBox } from 'src/CommonStyles/styles';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const FlagButtonContainer = styled.div`
  position: relative;
`;

export const FlagDot = styled.div`
  position: absolute;
  right: -2px;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${globalStyleVariables.green};
`;

export const ExcludeBlock = styled.div`
  background: ${globalStyleVariables.brandGray};
  border-radius: 2px;
  color: white;
  padding: 2px 8px;
  width: fit-content;
  line-height: 15px;
  margin: 2px 0;
`;

export const FactsDocumentsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;

  .ant-switch-checked {
    background-color: ${globalStyleVariables.newBrandingPrimary200};
  }

  .ant-modal {
    top: 0;
  }

  opacity: ${(props: { showAddFact?: any }) =>
    props.showAddFact === 'true' ? '0.1' : '1'};
`;

export const SeriesDose = styled.div`
  position: absolute;
  top: -2px;
  left: 0;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  background: ${globalStyleVariables.newBrandingPrimary200};
  color: #ffffff;
  font-size: 10px;
  width: 56px;
  font-family: lato;
  border-radius: 3px;
  z-index: 10;
`;

export const FactsDocumentsBody = styled(ColumnBox)`
  /* overflow-y: overlay !important; */
  overflow-x: hidden;
  overflow-y: scroll;

  .list-row-item {
    position: relative;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.75);
    font-family: 'Encode Sans Expanded';
    font-size: 1.2rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffffff !important;
  }

  &:hover {
    overflow-y: scroll;

    ::-webkit-scrollbar-thumb {
      background-color: #989898 !important;
    }
  }

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .excludeRow {
    filter: grayscale(1);
    background: #f7f7f7;
  }

  :hover {
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #989898 !important;
    }
  }
`;

export const WaiverName: any = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  width: 78px;
  display: flex;
  align-items: center;

  color: #666666;
`;

export const WaiverNameFacts: any = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  min-width: 78px;
  display: flex;
  align-items: center;

  color: #666666;
`;

export const MoreInfo: any = styled.span`
  display: flex;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.75);
  font-family: Encode Sans Expanded;
  font-size: 1.2rem;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  /* padding: 0.4rem; */
  border-radius: 50%;
`;

export const InfoDot: any = styled.span`
  border-radius: 50%;
  background-color: #666666;
  width: 4px;
  height: 4px;
  margin-right: 2px;
`;

export const PopoverTitleContainer: any = styled.span`
  position: relative;
  width: 100%;
  display: -ms-flexbox;
  //-webkit-box-pack: fle;
  -ms-flex-pack: justify;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  color: #333333;

  .waiting-reason {
    width: 276px;
  }
`;

export const PopoverContent: any = styled.div`
  margin-top: 10px;

  .error-border {
    border: 1px solid #f9b40b;
  }
`;

export const WaiverDateContainer: any = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #666666;
  width: 78px;
  min-width: 78px;
`;

export const UserEntered: any = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 78px;
`;

export const DateEntered: any = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 78px;
`;

export const DateCalenderContainer: any = styled.span`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 1rem;
  margin-top: 1.2rem;
  //cursor: pointer;

  svg > path {
    stroke: #666666;
  }

  :hover {
    svg > path {
      stroke: ${globalStyleVariables.newBrandingPrimary200};
    }

    //color: ${globalStyleVariables.newBrandingPrimary200};
  }
`;

export const DeleteContainer: any = styled.span`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 1rem;
  margin-top: 1.2rem;
  //cursor: pointer;

  svg > path {
    stroke: #666666;
  }

  :hover {
    svg > path {
      stroke: #ff2828;
    }

    //color: #ff2828;
  }
`;

export const DateIconContainer: any = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  background-color: rgba(230, 230, 230, 0.4);
  margin-right: 1.5rem;
`;

export const DeleteIconContainer: any = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  background-color: rgba(230, 230, 230, 0.4);
  /* opacity:0.3; */
  margin-right: 1.5rem;

  svg > path {
    fill: #ffffff;
  }

  :hover {
    svg > path {
      fill: rgb(255, 40, 40);
    }
  }
`;

export const ButtonContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  padding: 1rem 0;

  .buttons-container {
    display: flex;
    align-items: center;
  }
`;

export const ButtonSpan = styled.span`
  display: flex;
`;

export const FieldItem = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;
`;

export const PopoverTitle: any = styled.span`
  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-transform: uppercase;
  color: #666666;
  display: flex;
  justify-content: space-between;
`;

export const LogItemRow: any = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5rem;
  padding-right: 0.5rem;
`;

export const DateSpan: any = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${globalStyleVariables.newBrandingPrimary200};
`;

export const FactNameSpan: any = styled.span`
  width: 100%;
  display: block;
  width: 200px;
  //  font-family: Lato;
  font-style: normal;
  //font-weight: bold;
  font-size: 12px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
`;

export const FactStatusContent: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .disable {
    svg > path {
      stroke: #666666;
      fill: #666666;
    }

    color: #666666 !important;
  }

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
`;

export const FlagContainer = styled.span`
  height: 30px;
  min-width: 30px;
  background-color: rgba(230, 230, 230, 0.3);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;

  .documentIcon {
    height: 20px;
    width: 20px;
    mask-size: contain;
    mask-position: center center;
    mask-repeat: no-repeat no-repeat;
  }
`;

export const EmptyContainer: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  border-bottom: 0.1rem solid #e6e6e6;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.45);

  .empty-normal {
    margin-top: 1rem;
  }
`;

export const CloseIcon: any = styled.div`
  cursor: pointer;
  border: 1px dashed transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  &:hover {
    border: 1px dashed ${globalStyleVariables.newBrandingPrimary200};
    color: white;
  }

  &:focus {
    border: 1px dashed ${globalStyleVariables.newBrandingPrimary200};
  }
`;
export const NoDataContainer: any = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

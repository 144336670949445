import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Container = styled.div`
  background: #ffffff;
  padding: 16px ${globalStyleVariables.headerSidePadding};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .input-container {
    margin: 0 10px 0 0;
    width: unset;
    min-width: 140px;
  }

  .large-select {
    width: 200px;
  }
`;

export const ProviderSelectionContainer = styled.div`
  width: 240px;
  height: 60px;
  margin-right: 2.4rem;
  font-size: 12px;
`;

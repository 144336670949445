import styled from 'styled-components'

export const Container = styled.div`
    .list-item {
        position: relative;
        cursor: pointer;
        padding: 10px;
        justify-content: flex-start;
        transition: all 0.25s ease-out;
        background-color: rgba(255,255,255);
        .note-name {
        }
        &:hover {
            background-color: rgba(0,0,0,0.05);
        }
    }
`
import React, { FC } from 'react';

import { Row, Col } from 'antd';
import _ from 'lodash';

import Checkbox from 'src/Framework/Controls/Checkbox';
import Radio from 'src/Framework/Controls/Radio';
import SelectContainer from 'src/Framework/Controls/SelectContainer';

import { ITicketDetailHipaa } from '../../store/types';
import { TicketDetailHippaAction } from '../../store/types';

import PolicyStatusText from '../PolicyStatusText';

interface IProps {
  clinicId: number;
  data: ITicketDetailHipaa;
  prevData: ITicketDetailHipaa;
  setData: React.Dispatch<React.SetStateAction<ITicketDetailHipaa | null>>;
  offered: boolean;
  setOffered: React.Dispatch<React.SetStateAction<boolean>>;
}

const Component: FC<IProps> = ({
  data,
  prevData,
  setData,
  offered,
  setOffered,
  clinicId
}: IProps) => {
  const status = data.action;
  const onChange = (obj: Partial<ITicketDetailHipaa>) => {
    setData((data) => {
      if (!data) return data;
      return {
        ...data,
        ...obj
      };
    });
  };
  return (
    <div>
      <div>
        <PolicyStatusText
          disableHippaColor={true}
          data={prevData}
          clinicId={clinicId}
        />
      </div>
      <Row align="middle" gutter={16} style={{ marginBottom: 20 }}>
        <Col>
          <Checkbox
            id="policy-offered"
            checked={offered}
            onChange={() => setOffered((offered) => !offered)}
          >
            Policy offered
          </Checkbox>
        </Col>
        <Col>
          <SelectContainer
            type="date"
            label=""
            hideLabel={true}
            value={data.dosFrom}
            onChange={(dosFrom) => {
              onChange({ dosFrom });
            }}
            bottomMargin={false}
            disabled={!offered}
          />
        </Col>
      </Row>
      <Row align="middle" gutter={16}>
        <Col>Reply:</Col>
        <Col>
          <Radio
            disabled={!offered}
            id=""
            checked={
              status === TicketDetailHippaAction.Read ||
              status === TicketDetailHippaAction.Revoked
            }
            onChange={() =>
              onChange({
                action:
                  prevData.action === TicketDetailHippaAction.Accept
                    ? TicketDetailHippaAction.Revoked
                    : TicketDetailHippaAction.Read
              })
            }
          >
            No Reply
          </Radio>
        </Col>
        <Col>
          <Radio
            disabled={!offered}
            id=""
            checked={status === TicketDetailHippaAction.Decline}
            onChange={() =>
              onChange({ action: TicketDetailHippaAction.Decline })
            }
          >
            Declined
          </Radio>
        </Col>
        <Col>
          <Radio
            disabled={!offered}
            id=""
            checked={status === TicketDetailHippaAction.Accept}
            onChange={() =>
              onChange({ action: TicketDetailHippaAction.Accept })
            }
          >
            Accepted
          </Radio>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(Component);

import React from 'react';
import Highlighter from 'react-highlight-words';

const HightlightText = ({
  search,
  children
}: {
  search: string;
  children: string;
}) => (
  <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={search.split(' ')}
    autoEscape={true}
    textToHighlight={children}
  />
);

export default HightlightText;

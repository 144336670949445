import styled from 'styled-components';

interface IFooter {
  noDrop: boolean;
}

export const PopupBodyInnerContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Footer = styled.div`
  width: 100%;
  min-height: 140px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 24px;
  ${(props: IFooter) =>
    props.noDrop &&
    `
        cursor: no-drop;
        opacity: 0.6;
        pointer-events: none;
    `}
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: rgba(14, 109, 159, 0.15);
    border-radius: 5px;
  }
`;

export const Container = styled.div`
  .deleteList {
    color: rgb(243, 8, 31);
  }
`;

export const TextItem = styled.span`
  margin: 0 0 0 5px;
`;

export const SubTitle = styled.div`
  color: #666666;
  font-size: 12px;
`;

export const TextTelehealth = styled.div`
  color: #333333;
  margin: 0 0 24px 0;
  white-space: pre-wrap;
`;

export const ContainerBlock = styled.div`
  position: relative;
  padding: 0 24px;
`;

export const ItemContainer = styled.div`
  display: flex;
  padding: 2px 5px;
  margin: 0 5px 5px 0;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.15s linear;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

import React, { FC } from 'react';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import { Container, ArrowsContainer, Text } from './styled';

export interface IProps {
  data?: Array<any>;
  pageNumber: number;
  pageLength: number;
  pageStart?: number;
  pageEnd?: number;
  dataLength: number;
  onChangePage: (page: number) => void;
  hideBorder?: boolean;
  rightMargin?: number;
  startFromOne?: boolean;
  disableMargin?: boolean;
}

const Component: FC<IProps> = ({
  data,
  pageNumber,
  pageLength,
  pageStart,
  pageEnd,
  dataLength,
  onChangePage,
  hideBorder,
  rightMargin,
  startFromOne,
  disableMargin
}: IProps) => {
  const first = startFromOne ? pageNumber : pageNumber + 1;
  const pageEndCalc =
    first * pageLength > dataLength ? dataLength : first * pageLength;
  const pageEndLength = data
    ? data.slice(0, first * pageLength).length
    : pageEnd
    ? pageEnd
    : pageEndCalc;
  return (
    <Container hideBorder={hideBorder} disableMargin={disableMargin}>
      <Text>
        {data ? (
          <>
            <span className="current-items">
              <b>{`${
                data.length === 0
                  ? 0
                  : (startFromOne ? pageNumber - 1 : pageNumber) * pageLength +
                    1
              }-${pageEndLength}`}</b>
              {` of ${data.length}`}
            </span>
          </>
        ) : (
          <>
            <span className="current-items">
              <b>{`${
                pageStart !== undefined
                  ? pageStart
                  : dataLength === 0
                  ? 0
                  : (startFromOne ? pageNumber - 1 : pageNumber) * pageLength +
                    1
              }-${pageEnd ? pageEnd : pageEndCalc}`}</b>
              {` of ${dataLength}`}
            </span>
          </>
        )}
      </Text>
      <ArrowsContainer rightMargin={rightMargin}>
        <ButtonIcon
          isButton={true}
          label={
            (!(startFromOne ? pageNumber === 1 : pageNumber === 0) &&
              `Previous page, items before ${
                (startFromOne ? pageNumber - 1 : pageNumber) * pageLength + 1
              }`) ||
            'First page, no previous items'
          }
          containerClassName={'pagination-button'}
          disabled={startFromOne ? pageNumber === 1 : pageNumber === 0}
          tooltip="Previous"
          tooltipProps={{
            getPopupContainer: (element) => element.parentElement!
          }}
          name={ICONS_LIST.thinArrow}
          changeColorOnHover={true}
          onClick={() => {
            if (startFromOne ? pageNumber === 1 : pageNumber === 0) {
              return;
            }
            onChangePage(pageNumber - 1);
          }}
        />
        <ButtonIcon
          isButton={true}
          label={
            (!(data
              ? pageEndLength >= data.length
              : (pageEnd ? pageEnd : pageEndCalc) >= dataLength) &&
              `Next page, items after ${pageEndLength}`) ||
            'Last page, no next items'
          }
          containerClassName={'pagination-button'}
          tooltip={'Next'}
          tooltipProps={{
            getPopupContainer: (element) => element.parentElement!
          }}
          disabled={
            data
              ? pageEndLength >= data.length
              : (pageEnd ? pageEnd : pageEndCalc) >= dataLength
          }
          changeColorOnHover={true}
          name={ICONS_LIST.thinArrow}
          iconStyle={{ transform: 'rotate(180deg)' }}
          onClick={() => {
            if (
              data
                ? pageEndLength >= data.length
                : (pageEnd ? pageEnd : pageEndCalc) >= dataLength
            ) {
              return;
            }
            onChangePage(pageNumber + 1);
          }}
        />
      </ArrowsContainer>
    </Container>
  );
};

export default Component;

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const PatientsGroupItemContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: ${globalStyleVariables.grey};
  margin: 0.5rem;
  padding: 3px 8px;
  gap: 12px;
`;

import moment from 'moment';
import React, { FC } from 'react';
import { IScheduleEvent } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import { getActiveStatusClass } from 'src/Activities/Schedule/Utils/Utils';
import StatusesContainer from 'src/Activities/Schedule/Popups/ScheduleDetailPopup/Status';

interface IProps {
  apptData: {
    scheduleEvent: IScheduleEvent;
  };
  onStatusChange: (value: any) => any;
}

const Component: FC<IProps> = ({ apptData, onStatusChange }: IProps) => {
  const event = apptData?.scheduleEvent;
  const status = event?.status;
  let date =
    event &&
    moment(event.scheduleEventDateTime).format('YYYY-MM-DD') + 'T00:00:00';
  const currentDate = moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00';
  const isLate = getActiveStatusClass(event, 'Late', status);
  return (
    <StatusesContainer
      isDisabled={moment(date).isAfter(currentDate)}
      status={status}
      isLate={isLate}
      onStatusChange={onStatusChange}
    />
  );
};

export default React.memo(Component);

import React, { useImperativeHandle, useState } from 'react';
import _ from 'lodash';

import Button from 'src/Framework/Controls/Button';
import Modal from 'src/Framework/Controls/Modal';

import { Container } from './styled';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { ITicketDetailHipaa } from '../../store/types';

import Table from './Table';
import { getCurrentClinicId } from 'src/Framework/Controls/Selectors/ClinicSelector';

interface IProps {}

export interface IShow {
  value: Record<string, ITicketDetailHipaa>;
  clinicId?: number;
}

export interface IModalHandles {
  show(obj: IShow): void;
  close(): void;
}

const headerProps = {
  style: {
    borderTop: `4px solid ${globalStyleVariables.red}`
  }
};

const modalProps = {
  destroyOnClose: true
};

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  {},
  ref
) => {
  const [data, setData] = useState<IShow['value'] | null>(null);
  const [clinicId, setClinicId] = useState<number>(getCurrentClinicId());
  const [visible, setVisible] = useState(false);

  const show = async ({ value, clinicId }: IShow) => {
    setData(value);
    if(clinicId) {
      setClinicId(clinicId)
    }
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = () => {
    setData(null);
    setClinicId(getCurrentClinicId())
  };

  useImperativeHandle(ref, () => ({
    show: (obj: IShow) => show(obj),
    close
  }));

  const apply = async () => {
    close();
  };

  return (
    <Modal
      width={750}
      title={'Privacy Policy Status'}
      visible={visible}
      onClose={close}
      headerBorder={false}
      afterClose={setToDefault}
      modalProps={modalProps}
      headerProps={headerProps}
      buttons={
        <Button id="save-button" onClick={apply} shape="round">
          Save
        </Button>
      }
    >
      <Container>{data && <Table data={data} clinicId={clinicId} />}</Container>
    </Modal>
  );
};

export default React.forwardRef(Component);

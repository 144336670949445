import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { font_color, active_color } from 'src/CommonStyles/styles';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  .category-label {
    font-weight: bold;
    font-size: 14px;
    color: #333333;
    font-family: Lato;
    font-style: normal;
  }
`;
export const InfoBar = styled.div`
  margin-bottom: 12px;
`;

export const Title = styled.div`
  font-family: Encode Sans Expanded;
  font-size: 30px;
  font-weight: 800;
`;

export const InfoTitle = styled.div`
  font-family: Encode Sans Expanded;
  font-size: 18px;
  font-weight: 800;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;
export const ScrollableBody = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  position: relative;

  .selected-patient {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .provider-history-row {
    height: 48px;
  }
`;

interface IBlockBorders {
  bottomBorder?: boolean;
  topBorder?: boolean;
  disableBottomPadding?: boolean;
  disableSidePaddings?: boolean;
  disableTopPaddings?: boolean;
  maxHeight?: number;
}

export const Block = styled.div`
  max-height: ${(props: IBlockBorders) =>
    props.maxHeight ? props.maxHeight + 'px' : undefined};
  overflow-y: auto;
  margin-top: ${(props: IBlockBorders) =>
    props.disableTopPaddings ? '0' : '8px'};
  padding: ${(props: IBlockBorders) =>
    (props.disableTopPaddings ? '0' : '16px') +
    (props.disableSidePaddings ? ' 0' : ' 24px') +
    (props.disableBottomPadding ? ' 0' : ' 24px')};
  border-bottom: ${(props: IBlockBorders) =>
    props.bottomBorder ? '1px solid rgba(0, 0, 0, 0.1)' : 'none'};
  border-top: ${(props: IBlockBorders) =>
    props.topBorder ? '1px solid rgba(0, 0, 0, 0.1)' : 'none'};
`;

export const BlockHeader = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: ${font_color};
  margin: 4px 8px 16px 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
`;

interface OptionContainerProps {
  action?: string;
  disableDateTimeSelect?: boolean;
  typeAppointmentParticipantId?: number;
}

export const OptionContainer = styled.div`
  width: 115px;
  display: inline-block;
  cursor: pointer;
  pointer-events: ${(props: OptionContainerProps) =>
    props.action === 'edit' &&
    (props.typeAppointmentParticipantId === 1 ||
      props.typeAppointmentParticipantId === 0)
      ? 'none'
      : 'all'};

  &.btn__active {
    text-align: center;
    justify-content: center;
    height: 33px;
    align-items: center;
    position: relative;
    display: flex;
    background: rgba(83, 200, 46, 0.15);
    color: rgb(83, 200, 46);
    border-radius: 50px;
  }

  &.btn__hover {
    text-align: center;
    justify-content: center;
    height: 33px;
    align-items: center;
    position: relative;
    display: flex;

    &:hover {
      background: #e7f0f5;
      border-radius: 50px;
    }
  }

  opacity: ${(props: OptionContainerProps) => {
    if (
      props.action === 'edit' &&
      (props.typeAppointmentParticipantId === 1 ||
        props.typeAppointmentParticipantId === 0)
    ) {
      return 0.7;
    } else {
      return 1;
    }
  }};

  label {
    cursor: pointer;
  }

  .scheduleRecurr {
    padding-right: 8px;
    padding-bottom: 6px;
    display: flex;
    height: auto;

    svg > circle {
      fill: #ffffff;
    }

    svg > path {
      stroke: ${(props: OptionContainerProps) =>
        props.disableDateTimeSelect ? '#666666' : 'rgb(6, 86, 128)'};
    }
  }

  .scheduleRecurrActive {
    padding-right: 8px;
    padding-bottom: 6px;
    display: flex;
    height: auto;

    svg > circle {
      fill: #ffffff;
    }

    svg > path {
      stroke: #53c82e !important;
    }
  }

  .scheduleMark {
    padding-right: 8px;
    display: flex;
    height: auto;

    svg path {
      stroke: ${(props: OptionContainerProps) =>
        props.disableDateTimeSelect ? '#666666' : 'rgb(6, 86, 128)'};
    }
  }

  .scheduleMarkActive {
    padding-right: 8px;
    display: flex;
    height: auto;
    color: #53c82e;

    path {
      stroke: #53c82e !important;
    }
  }

  .recurrActive {
    display: flex;
    align-items: center;
    background: rgb(83, 200, 46, 0.1);
    border-radius: 16px;
    padding-left: 0.5rem;

    .scheduleRecurr {
      svg > circle {
      }

      svg > path {
        stroke: #53c82e !important;
      }
    }
  }
`;

interface OptionSelectorProps {
  disableDateTimeSelect: boolean;
  recurrActive?: boolean;
}

export const OptionSelector = styled.div`
  font-weight: bold;
  font-size: 14px;
  font-family: Lato;
  font-style: normal;
  color: ${(props: OptionSelectorProps) => {
    if (props.recurrActive) {
      return 'rgb(83,200,46)';
    } else {
      if (!props.disableDateTimeSelect) {
        return globalStyleVariables.newBrandingPrimary200;
      } else {
        return '#666666';
      }
    }
  }};
`;

export const Info = styled.div`
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
`;
export const MembersCounter = styled.span`
  margin-left: 8px;

  span {
    margin: 0 4px;
  }

  span:first-of-type {
    color: ${active_color};
  }
`;

export const ContainerBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  .ant-empty-normal {
    margin: 0 !important;
  }
`;

export const ImageContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
  box-sizing: border-box;
`;
export const NameContainer = styled.div`
  font-size: 16px;
`;
export const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-top: solid ${globalStyleVariables.newBrandingPrimary200} 4px;

  &.handleDrag {
    cursor: grab;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  padding: 2px 5px;
  margin: 0 5px 5px 0;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.15s linear;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const PopupContainer = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 320px;
  background: #ffffff;
  border-radius: 4px;
`;

export const MemberLine = styled.div`
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MembersContainer = styled.div`
  width: 240px;
`;

export const HistoryContainer = styled.div`
  height: 330px;
  position: relative;
  display: flex;
  flex-direction: column;
`;
export const EventWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .absolute-collapse {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

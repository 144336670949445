import Logger from "../Logger"
import Subscription, { UPDATE_CONNECTIONS } from "./SignalR"

type InstanceType = Subscription

class ScheduleSubscriptionWidget {
    static instance: InstanceType
    init = ({ tenantId, signalRUrl, update_connections, onConnected }: {
        tenantId: string
        signalRUrl: string
        update_connections: UPDATE_CONNECTIONS,
        onConnected: () => Promise<any>
    }) => {
        try {
            ScheduleSubscriptionWidget.instance = new Subscription({
                signalRUrl,
                tenantId,
                update_connections,
                onConnected,
                logger: new Logger({
                    key: 'scheduleSubscriptionWidget',
                    subscribeByDefault: process.env.NODE_ENV === 'development'
                }),
            })
        } catch (e) {
            console.log({ e })
        }
    }
}

export default ScheduleSubscriptionWidget
import React, { useImperativeHandle, useState, useRef, useMemo } from 'react';
import { Col, Row } from 'antd';

import Modal from 'src/Framework/Controls/Modal';
import Icon from 'src/Framework/Controls/Icon';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import EditButton from 'src/Framework/Controls/Table/EditButton';

import Main, { IProps } from './index';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';
import * as outreachActions from 'src/Activities/Home/Outreach/store/Actions';
import {
  IOutreachSimpleDto,
  IRegistrant
} from 'src/Activities/Home/Outreach/store/types';
import OutreachModal, {
  IModalHandles as IOutreachModalHandles
} from 'src/Activities/Home/Outreach/Modal';
import { EEventTypeCode, eventPermission } from 'src/Activities/Schedule/utils';

import {
  IconContainer,
  ModalTitle
} from 'src/Activities/Schedule/Popups/Outreach/styled';

interface IShow {
  outreachId: number;
}

export interface IModalHandles {
  show(obj: IShow): void;

  close(): void;
}

const modalProps = {
  destroyOnClose: true,
  mask: false
};

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  props,
  ref
) => {
  const OutreachModalRef = useRef<IOutreachModalHandles>(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IOutreachSimpleDto | null>(null);
  const [registrants, setRegistrants] = useState<IRegistrant[]>([]);
  useModalFocus({ active: visible });

  const outreachPermission = useMemo(
    () => eventPermission(EEventTypeCode.outreach),
    []
  );

  const show = async ({ outreachId }: IShow) => {
    const init = async () => {
      setLoading(true);
      const id = outreachId ? outreachId : props.id;
      const res = await outreachActions.getOutreach(id);
      if (res) {
        const response = await outreachActions.getRegistrants(res.id);
        if (response) {
          setRegistrants(Object.values(response));
          setData(res);
        }
      }
      setLoading(false);
    };
    setVisible(true);
    init();
  };

  const close = () => {
    setVisible(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const setToDefault = () => {};

  useImperativeHandle(ref, () => ({
    show: (obj: IShow) => show(obj),
    close
  }));

  const onEdit = () => {
    OutreachModalRef.current?.show({ id: props.id });
  };

  const defaultWidth = 1080;
  const title = (
    <Row align="middle" gutter={16} style={{ flexWrap: 'nowrap' }}>
      <Col>
        <IconContainer>
          <Icon name="event" />
        </IconContainer>
      </Col>
      <Col>
        <ModalTitle tabIndex={0}>
          <EllipsisText>{data?.title}</EllipsisText>
        </ModalTitle>
      </Col>
    </Row>
  );

  const headerRight = (
    <EditButton
      buttonProps={{
        disabled: !outreachPermission.success,
        tooltip: outreachPermission.success
          ? 'Edit Outreach'
          : outreachPermission.message
      }}
      onClick={() => {
        close();
        if (onEdit) {
          onEdit();
        }
      }}
    />
  );

  return (
    <>
      <OutreachModal ref={OutreachModalRef} />
      <Modal
        isModalSlider={true}
        modalSliderProps={{
          defaultWidth: `${defaultWidth}px`,
          minWidth: `${defaultWidth}px`,
          isOpen: visible,
          onClose: close,
          afterClose: setToDefault,
          title,
          headerRight
        }}
        width={defaultWidth}
        title={title}
        visible={visible}
        onClose={close}
        headerBorder={true}
        afterClose={setToDefault}
        modalProps={modalProps}
        hideHeader={true}
        hideClose={true}
        isDragable={true}
        buttons={headerRight}
      >
        {loading && <AbsoluteLoader />}
        <Main {...props} registrants={registrants} data={data} />
      </Modal>
    </>
  );
};

export default React.forwardRef(Component);

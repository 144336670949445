import React, { FC, useLayoutEffect, useMemo, useRef } from 'react';

import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import CellDrop from './CellDrop';
import { ListTypeNames } from 'src/Activities/Schedule/WaitingListSettings/store/utils';
import { TypeListEnum } from 'src/Activities/Schedule/WaitingListSettings/store/types';
import store, { useAppSelector } from 'src/store';
import { CellContainer } from './DndDragSource';

import {
  ScheduleColContainer,
  ScheduleGroupsLayout,
  ScheduleOnesLayout,
  DefaultItemHeight,
  ScheduleLayoutPadding,
  ReservationBackgroundContainer
} from './ScheduleCol_Styles';

import { ScheduleGroupTitle, ScheduleGroupBorder } from './Reservations';
import {
  getMinFrom1200AM,
  timeDiff,
  getEndTime,
  buildSliceId,
  getTimeObjectStringFromSimpleTime,
  calcDurationDateTime,
  get24HourAndMinFormat,
  newEventCardFromWaitingList,
  newEventCardFromGroupWaitingList
} from 'src/Activities/Schedule/Utils/Utils';
import {
  generateSchedules,
  renderSchedules as renderSchedules,
  sortEventsInTimeSlot
} from 'src/Activities/Schedule/Utils/renderer';
import {
  moveEvent,
  moveGroupWaitingList
} from '../../Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import { ColumnBox } from 'src/CommonStyles/styles';

import { moveEventPayload } from '../../Store/Models/ScheduleDefinition';
import {
  updateEventOnSliceEvents,
  removeScheduleSliceEvent,
  updateEventsOnAction,
  onDelete
} from '../../Store/ScheduleSliceEventsActions';

import AlertPopUp, {
  IModalHandles as IAlertPopUp
} from 'src/Framework/Controls/AlertPopUp';

import { ItemTypes } from './ItemTypes';

import Overflow from './Overflow';

import {
  getClinicConfig,
  getProviderByCode
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { getClinicByCode } from 'src/Framework/Controls/Selectors/ClinicSelector';
import { getCurrentTenantId } from 'src/App/UserPreferences/store/Actions';
import { useAppointmentReason } from 'src/App/Admin/Pages/AppointmentTypes/store/Hooks';
import { ReservationBackground } from './Reservations/ReservationBackground';
import classNames from 'classnames';

import {
  EApptTypeCode,
  EEventTypeCode,
  eventPermission
} from 'src/Activities/Schedule/utils';
import { checkPatientStatus } from 'src/Activities/Schedule/Main/ScheduleCol/ScheduleOne';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import EmptyCellContainer from './EmptyCellContainer';
//@ts-ignore
const moment = extendMoment(Moment);

interface IProps {
  updateWaitingList: Function;
  getParsedWaitingGroupPatients: () => any[];
  mainScrollContainerRef: React.RefObject<HTMLDivElement>;
  // TODO: NEED TO CHECK!!
  providerId?: number;
  sliceId: any;
  isWidget?: boolean;
}

const getEventName = (item: any) => {
  if (item.isBlock) {
    return 'block';
  }
  if (item.isEvent) {
    return `staff appointment`;
  }
  return 'appointment';
};

const Component: FC<IProps> = (props: IProps) => {
  const memoFuncRef = useRef<{
    onDrop: Function;
    onDropWithWaitingPatient: Function;
  }>({
    onDrop: () => {},
    onDropWithWaitingPatient: () => {}
  });
  const { getParsedWaitingGroupPatients, sliceId, isWidget } = props;
  const appointmentReasonHook = useAppointmentReason();
  const columnRef = useRef<HTMLDivElement>(null);
  const AlertPopUpRef = useRef<IAlertPopUp>(null);
  const scheduleTimeProps = useAppSelector(
    (state) => {
      const scheduleTimeProps = {
        ...state.scheduleDetails.filters.scheduleTimeProps
      };
      let regularMeetingTime = scheduleTimeProps.regularMeetingTime;
      if (isNaN(regularMeetingTime)) {
        regularMeetingTime = 15;
      }
      return {
        ...scheduleTimeProps,
        regularMeetingTime
      };
    },
    (prev, next) => {
      const equal = _.isEqual(prev, next);
      return equal;
    }
  );

  const showCancellations = useAppSelector(
    (state) => state.scheduleDetails.filters.showCancellations
  );
  const reservationsOnly = useAppSelector(
    (state) => state.scheduleDetails.filters.reservationsOnly
  );
  const clinics = useAppSelector((state) => state.scheduleDetails.clinics);
  const colEvents = useAppSelector(
    (state) => state.schedule.sliceEvents[sliceId],
    (prev, next) => {
      const equal = _.isEqual(prev, next);
      return equal;
    }
  );
  const slice = useAppSelector(
    (state) => state.schedule.slices[sliceId],
    (prev, next) => {
      const equal = _.isEqual(prev, next);
      return equal;
    }
  );
  const isShowingPatientName = useAppSelector(
    (state) => state.scheduleDetails.filters.isShowingPatientName
  );
  const isShowingReservationLabel = useAppSelector(
    (state) => state.scheduleDetails.filters.isShowingReservationLabel
  );
  const scheduleColMain = useMemo(() => {
    if (!slice || !colEvents) return null;
    return generateSchedules(colEvents, {
      provider: getProviderByCode(slice.providerCode),
      slice,
      clinic: getClinicByCode(slice.clinicCode),
      scheduleTimeProps,
      showCancellations
    });
  }, [colEvents, slice, scheduleTimeProps, showCancellations]);

  const { scheduleCol, otherScheduleColData } = useMemo(() => {
    if (scheduleColMain) {
      const { schedules, ...otherScheduleColData } = scheduleColMain;
      return {
        scheduleCol: scheduleColMain,
        otherScheduleColData
      };
    }
    return {
      scheduleCol: null,
      otherScheduleColData: null
    };
  }, [scheduleColMain]);
  const updatedSchedules = useMemo(() => {
    if (!scheduleCol) return null;
    return renderSchedules(scheduleCol.schedules);
  }, [scheduleCol?.schedules]);

  const mergedSchedules = useMemo(() => {
    if (!updatedSchedules) return null;
    return [].concat(
      ...updatedSchedules.map((schedule: any) => schedule.patients)
    );
  }, [updatedSchedules]);

  const onDropWithWaitingPatient = (startTime: any, draggingItem: any) => {
    if (!scheduleCol) return;
    const apptPermission = eventPermission(
      EEventTypeCode.appointment,
      EApptTypeCode.individual
    );
    if (!apptPermission.success) {
      AlertPopUpRef.current?.show({
        content: apptPermission.message,
        hideClose: true,
        action: 'info',
        onConfirm: () => {
          AlertPopUpRef.current?.close();
        }
      });
      return;
    }

    //waiting list event validation
    let waitingListPatient = draggingItem.patient;
    if (!waitingListPatient) {
      onDropAlert('Patient not found');
      return;
    }
    const currentProviderId = getProviderByCode(slice.providerCode)?.providerId;
    if (
      currentProviderId &&
      waitingListPatient.waitingListProviderIds &&
      waitingListPatient.waitingListProviderIds.length > 0 &&
      !waitingListPatient.waitingListProviderIds.includes(currentProviderId)
    ) {
      onDropAlert(`Current provider is not in the List Item's providers list`);
      return;
    }
    const appointmentReason = appointmentReasonHook.finder(
      waitingListPatient?.reason.id
    );
    if (!appointmentReason || appointmentReason.isActive === false) {
      onDropAlert('Appt Type is no longer valid');
      return;
    }
    if (slice.clinicCode !== waitingListPatient.clinicCode) {
      onDropAlert('Clinics are different');
      return;
    }
    if (
      !draggingItem.appointmentId &&
      waitingListPatient.typeList === TypeListEnum.triage
    ) {
      const status = checkPatientStatus(
        waitingListPatient?.patientInfo?.patientStatus,
        slice.scheduleDate,
        startTime,
        {
          colorWithBackground: globalStyleVariables.white,
          colorWithoutBackground: globalStyleVariables.newBrandingPrimary200
        }
      );
      if (status && status.type !== 'late') {
        if (!Moment(slice.scheduleDate).isSame(status.date, 'day')) {
          onDropAlert(
            `This ${ListTypeNames()[
              TypeListEnum.triage
            ].toLowerCase()} item's checkin status has been set on another date, so you are unable to schedule it for this date and time. If you wish to schedule it for this date, remove the previously set checkin status to place it on the calendar.`
          );
          return;
        }
      }
    }

    //Get source event information
    let event = {
      ...waitingListPatient,
      id: waitingListPatient.subjectId
    };

    if (!event) {
      onDropAlert('Event not found');
      return;
    }

    //Get Destination
    //Get calendar destination information
    let scheduleEventDateTime = startTime;
    const { providerCode, clinicCode, date } = scheduleCol;
    //Timing
    let endTime = getEndTime(
      scheduleEventDateTime,
      event.reason && event.reason.duration
    );

    let updatedEndTime = endTime;
    let updatedStartTime = scheduleEventDateTime;

    if (endTime[1] === ':') updatedEndTime = `0${endTime}`;
    if (scheduleEventDateTime[1] === ':')
      updatedStartTime = `0${scheduleEventDateTime}`;

    const duration = calcDurationDateTime(
      moment(
        moment(date).format('YYYY-MM-DD') + 'T' + updatedStartTime + ':00'
      ),
      moment(moment(date).format('YYYY-MM-DD') + 'T' + updatedEndTime + ':00')
    );
    const createdDts =
      moment().format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:ss');
    event = {
      ...event,
      startTime: scheduleEventDateTime,
      endTime,
      seriesId: null,
      scheduleEventTypeId: 1
    };
    const findProvider = getProviderByCode(slice.providerCode);
    const clinic = getClinicByCode(slice.clinicCode);
    if (!findProvider || !clinic) {
      onDropAlert('Error');
      return;
    }
    //provider
    let provider = {
      id: findProvider.providerId,
      code: findProvider.code,
      page: getClinicConfig(findProvider, clinic.id)?.maxPages || 1,
      name: `${findProvider.firstName} ${findProvider.lastName}`
    };

    //slice
    let tenantId = getCurrentTenantId();
    let targetSliceId = buildSliceId(tenantId, clinicCode, providerCode, date);

    //Construct a temporary event card to show while API is called
    let tempEventCard = newEventCardFromWaitingList(
      event,
      provider,
      scheduleCol,
      targetSliceId,
      createdDts,
      updatedEndTime,
      updatedStartTime,
      duration
    );

    //Create payload to update redux store
    let eventsToUpdate = {
      [tempEventCard.uid]: { ...tempEventCard }
    };
    const group = draggingItem.group;
    const parsedWaitingGroupPatients = getParsedWaitingGroupPatients();
    const waitingPatientList = _.cloneDeep(parsedWaitingGroupPatients || []);
    //update redux store for waiting list view, removing item from source
    const index = waitingPatientList.findIndex(
      (item: any) => item.waitingListId === group.groupId
    );
    if (index !== -1) {
      const group = waitingPatientList[index];
      if (group) {
        const subjectIndex = group.patients.findIndex(
          (item: any) => item.subjectId === waitingListPatient.subjectId
        );
        if (subjectIndex !== -1) {
          if (group?.isDeleteOnMove) {
            group.patients.splice(subjectIndex, 1);
          }
        }
      }
    }
    console.log('drag drop complete');

    //Move event
    let moveEventPayload = {
      patientId: waitingListPatient.id,
      dts: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
      appointmentBlockId: event.appointmentBlockId,
      appointmentId: event.appointmentId,
      old: {
        clinicCode: event.clinicCode,
        providerCode: event.providerCode,
        scheduleEventDateTime: event.scheduleEventDateTime,
        endTime: event.endTime
      },
      new: {
        clinicCode: clinicCode,
        providerCode: provider.code,
        scheduleEventDateTime:
          event &&
          date &&
          moment(date).format('YYYY-MM-DD') + 'T' + updatedStartTime + ':00',
        endTime:
          event &&
          date &&
          moment(date).format('YYYY-MM-DD') + 'T' + updatedEndTime + ':00.000'
      }
    };
    //Move event api loop
    sendWaitingPatientRequest(
      moveEventPayload,
      eventsToUpdate,
      waitingPatientList
    );
  };

  const getScheduleColDateTime = (
    clientY: number,
    columnBoundingRect: any,
    regularMeetingTime: number,
    dayStartTime: any,
    padding: number = 0
  ) => {
    let relativeHeight = clientY - columnBoundingRect.y - padding;
    if (relativeHeight < 0) {
      relativeHeight = 0;
    }
    const posByUnit = Math.floor(
      relativeHeight / (DefaultItemHeight() * scheduleTimeProps.slotHeight)
    );
    console.log({ dayStartTime });
    return getEndTime(
      dayStartTime,
      (posByUnit * regularMeetingTime).toString()
    );
  };
  const onDropAlert = (content: string) => {
    AlertPopUpRef.current?.show({
      content,
      action: 'info',
      hideClose: true
    });
  };

  const clinicOpenOnDrop = (clientOffset: any, duration: number): boolean => {
    const { regularMeetingTime, dayStartTime } = scheduleTimeProps;
    const columnBoundingRect = columnRef.current?.getBoundingClientRect();
    let scheduleEventDateTime = getScheduleColDateTime(
      clientOffset.y,
      columnBoundingRect,
      regularMeetingTime,
      dayStartTime,
      ScheduleLayoutPadding
    );
    if (!canDropTo(scheduleEventDateTime, duration)) {
      onDropAlert('Clinic is not open at this time');
      return false;
    }
    return true;
  };

  const sendWaitingPatientRequest = async (
    eventPayload: any,
    tempEventsToUpdate: any,
    newWaitingPatientList?: any
  ) => {
    // -- update redux store for calendar view, adding temporary event to destination slice
    const tempEventCard: any = Object.values(tempEventsToUpdate)[0];
    if (!tempEventCard) return;
    const targetSliceId = tempEventCard.sliceId;
    updateEventOnSliceEvents(targetSliceId, tempEventsToUpdate);
    // --
    const removeTempEvent = () => {
      store.dispatch(
        removeScheduleSliceEvent(targetSliceId, tempEventCard.uid)
      );
    };
    // -- Send request
    const response = await moveEvent(eventPayload);
    if (!response.data.success) {
      removeTempEvent();
      return;
    }
    const res = response.data.result;
    if (res) {
      if (!res.hasConflicts) {
        removeTempEvent();
        updateEventsOnAction(res);
        if (newWaitingPatientList) {
          props.updateWaitingList(newWaitingPatientList);
        }
      } else {
        const moveEventPayload = _.cloneDeep(eventPayload);
        const conflictResponse = res.conflicts;
        conflictResponse.forEach((item: any, indexL: any) => {
          conflictResponse[indexL]['dialogResponse'] = 'override';
        });
        moveEventPayload['conflictsResponses'] = conflictResponse;
        AlertPopUpRef.current?.show({
          content: `${
            res.reasonCode !== 4 ? res.message : `Type doesn't match`
          }${res.allowOverride ? `, do you want to override?` : ''}`,
          action: 'delete',
          hideOk: !res.allowOverride,
          onCancel: () => {
            removeTempEvent();
          },
          onConfirm: () => {
            sendWaitingPatientRequest(
              moveEventPayload,
              tempEventsToUpdate,
              newWaitingPatientList
            );
          }
        });
      }
    } else {
      removeTempEvent();
    }
  };

  const canDropTo = (startTime: any, duration: number) => {
    if (!scheduleCol) return;
    let clinic = clinics.find(
      (clinic: any) =>
        clinic.code.toLowerCase() === scheduleCol.clinicCode.toLowerCase()
    );
    let canDropTo = true;
    if (clinic) {
      if (
        getMinFrom1200AM(get24HourAndMinFormat(clinic.start, false)) >
          getMinFrom1200AM(startTime) + 5 ||
        moment(startTime, 'HH:mm')
          .add(duration, 'minute')
          .isAfter(moment(clinic.endTime?.slice(11, 16), 'HH:mm'))
      ) {
        canDropTo = false;
      }
    }
    return canDropTo;
  };

  const onDropWaitingGroup = async (item: any, delta: any) => {
    if (!scheduleCol) return;
    const { providerId } = props;
    const { regularMeetingTime, dayStartTime } = scheduleTimeProps;
    const { providerName, providerCode, clinicCode, date } = scheduleCol;
    const group = item.group;
    if (slice.clinicCode !== group.clinicCode) {
      onDropAlert('Clinics are different');
      return;
    }
    const appointmentReason = appointmentReasonHook.finder(
      group.appointmentReasonId
    );
    if (!appointmentReason || appointmentReason.isActive === false) {
      onDropAlert('Appt Type is no longer valid');
      return;
    }
    if (!clinicOpenOnDrop(delta, +(appointmentReason.duration || 0))) {
      return;
    }
    const currentProviderId = getProviderByCode(slice.providerCode)?.providerId;
    if (
      currentProviderId &&
      group.providerIds &&
      group.providerIds.length > 0 &&
      !group.providerIds.includes(currentProviderId)
    ) {
      onDropAlert('This provider is not configured for this list');
      return;
    }
    let event: any = null;
    event = group.patients[0];
    const columnBoundingRect = columnRef.current?.getBoundingClientRect();
    const scheduleEventDateTime = getScheduleColDateTime(
      delta.y,
      columnBoundingRect,
      regularMeetingTime,
      dayStartTime,
      ScheduleLayoutPadding
    );
    if (slice.clinicCode !== group.clinicCode) {
      onDropAlert('Clinics are different');
      return;
    }
    let provider = {
      id: providerId,
      code: providerCode,
      page: 1,
      name: providerName
    };
    const appointmentsList = Object.values(
      store.getState().adminPanel.appointmentTypes.summary
    );
    const reason = appointmentsList.find(
      (v) => v.id === group.appointmentReasonId
    );
    if (!reason) {
      onDropAlert('Reason not found, please add type for this list');
      return;
    }

    let endTime = getEndTime(scheduleEventDateTime, `${reason.duration}`);
    let updatedStartTime = scheduleEventDateTime;
    let updatedEndTime = endTime;

    if (endTime[1] === ':') updatedEndTime = `0${endTime}`;
    if (scheduleEventDateTime[1] === ':')
      updatedStartTime = `0${scheduleEventDateTime}`;

    let duration = calcDurationDateTime(
      moment(
        moment(date).format('YYYY-MM-DD') + 'T' + updatedStartTime + ':00'
      ),
      moment(moment(date).format('YYYY-MM-DD') + 'T' + updatedEndTime + ':00')
    );
    const createdDts =
      moment().format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:ss');
    event = {
      ...event,
      startTime: scheduleEventDateTime,
      endTime,
      seriesId: null,
      scheduleEventTypeId: 1
    };

    if (scheduleEventDateTime[1] === ':')
      updatedStartTime = `0${scheduleEventDateTime}`;
    const moveEventPayload = {
      dts: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
      old: {
        clinicCode: group.clinicCode,
        waitingListCode: group.waitingListCode
      },
      new: {
        clinicCode: clinicCode,
        providerCode: provider.code,
        scheduleEventDateTime: `${moment(date).format(
          'YYYY-MM-DD'
        )}T${updatedStartTime}:00`,
        endTime: `${moment(date).format('YYYY-MM-DD')}T${endTime}:00.000`
      }
    };

    //slice
    let tenantId = getCurrentTenantId();
    let targetSliceId = buildSliceId(tenantId, clinicCode, providerCode, date);

    const tempEventCard = newEventCardFromGroupWaitingList(
      group,
      group.patients,
      reason,
      provider,
      scheduleCol,
      targetSliceId,
      createdDts,
      updatedEndTime,
      updatedStartTime,
      duration
    );

    //Create payload to update redux store
    let eventsToUpdate = {
      [tempEventCard.uid]: { ...tempEventCard }
    };
    sendWaitingGroupRequest(moveEventPayload, eventsToUpdate, group);
  };

  const sendWaitingGroupRequest = async (
    eventPayload: any,
    tempEventsToUpdate: any,
    group: any
  ) => {
    // -- update redux store for calendar view, adding temporary event to destination slice
    const tempEventCard: any = Object.values(tempEventsToUpdate)[0];
    if (!tempEventCard) return;
    const targetSliceId = tempEventCard.sliceId;
    updateEventOnSliceEvents(targetSliceId, tempEventsToUpdate);
    // --
    const removeTempEvent = () => {
      store.dispatch(
        removeScheduleSliceEvent(targetSliceId, tempEventCard.uid)
      );
    };
    const groupActions = () => {
      if (group.isDeleteOnMove) {
        const parsedWaitingGroupPatients = getParsedWaitingGroupPatients();
        const { updateWaitingList } = props;
        if (parsedWaitingGroupPatients) {
          const data = _.cloneDeep(parsedWaitingGroupPatients);
          const index = data.findIndex((item: any) => item.id === group.id);
          data[index].patients = [];
          updateWaitingList(data);
        }
      }
    };
    // -- Send request
    const res = await moveGroupWaitingList(eventPayload);
    if (res) {
      if (!res.hasConflicts) {
        removeTempEvent();
        groupActions();
        updateEventsOnAction(res);
      } else {
        const moveEventPayload = _.cloneDeep(eventPayload);
        const conflictResponse = res.conflicts;
        conflictResponse.forEach((item: any, indexL: any) => {
          conflictResponse[indexL]['dialogResponse'] = 'override';
        });
        moveEventPayload['conflictsResponses'] = conflictResponse;
        AlertPopUpRef.current?.show({
          content: `${
            res.reasonCode !== 4 ? res.message : 'Type doesnt match'
          }${res.allowOverride ? `, do you want to override?` : ''}`,
          hideOk: !res.allowOverride,
          action: 'delete',
          onCancel: () => {
            removeTempEvent();
          },
          onConfirm: () => {
            sendWaitingGroupRequest(
              moveEventPayload,
              tempEventsToUpdate,
              group
            );
          }
        });
      }
    }
  };

  const onDrop = (item: any, startTime: any, clientOffset: any): any => {
    if (item.itemType === ItemTypes.WaitingGroup) {
      const apptPermission = eventPermission(
        EEventTypeCode.appointment,
        EApptTypeCode.group
      );
      if (!apptPermission.success) {
        AlertPopUpRef.current?.show({
          content: apptPermission.message,
          hideClose: true,
          action: 'info',
          onConfirm: () => {
            AlertPopUpRef.current?.close();
          }
        });
        return;
      }
      return onDropWaitingGroup(item, clientOffset);
    }
    const { providerId, sliceId } = props;
    if (!canDropTo(startTime, item.duration)) {
      onDropAlert('Clinic is not open at this time');
      return;
    }
    if (
      item.isEvent &&
      moment(item.date.slice(0, 10), 'YYYY-MM-DD').isBefore(
        moment().startOf('day')
      )
    ) {
      AlertPopUpRef.current?.show({
        content: `Sorry, you can not move a Staff Appointment from the past`,
        hideClose: true,
        action: 'info',
        onConfirm: () => {
          AlertPopUpRef.current?.close();
        }
      });
      return;
    }

    const onDropSuccess = () => {
      if (!scheduleCol) return;
      const {
        providerCode,
        providerName,
        clinicCode,
        clinicName,
        date,
        pages
      } = scheduleCol;
      let moveEvent = _.cloneDeep(moveEventPayload);
      let draggingItem = {
        ...item,
        startTime: item.date,
        endTime:
          moment(item.date).format('YYYY-MM-DD') + 'T' + item.endTime + ':00'
      };
      const sourceItem = _.cloneDeep(draggingItem);
      let provider = {
        id: providerId,
        code: providerCode,
        page: pages,
        name: providerName
      };
      let duration = item.duration;

      let endTime = getEndTime(startTime, duration);
      let oldEvent = {
        clinicCode: draggingItem.clinicCode,
        providerCode: draggingItem.provider?.code || draggingItem.providerCode,
        // clinicCode: capitalizeFirstLetter(draggingItem.clinicCode),
        // providerCode: capitalizeFirstLetter(
        //   draggingItem.provider?.code || draggingItem.providerCode
        // ),
        scheduleEventDateTime: draggingItem.startTime,
        endTime: draggingItem.endTime
      };
      let newEvent = {
        ...moveEvent['new'],
        scheduleEventDateTime: getTimeObjectStringFromSimpleTime(
          startTime,
          date
        ),
        endTime: getTimeObjectStringFromSimpleTime(
          getEndTime(startTime, duration),
          date
        ),
        providerCode: provider.code,
        clinicCode: clinicCode
        // providerCode: capitalizeFirstLetter(provider.code),
        // clinicCode: capitalizeFirstLetter(clinicCode)
      };
      let patients: any = draggingItem.patients
        ? Object.values(draggingItem.patients)
        : [];
      moveEvent = {
        EventId: draggingItem.eventId,
        appointmentBlockId: draggingItem.appointmentBlockId,
        appointmentId: draggingItem.appointmentId,
        patientId:
          !draggingItem.isBlock &&
          !draggingItem.isReservation &&
          !draggingItem.isEvent
            ? draggingItem.patientInfo
              ? draggingItem.patientInfo.patientId
              : patients[0].patientId
            : 0,
        dts: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        old: { ...oldEvent },
        new: { ...newEvent }
      };
      draggingItem = {
        ...draggingItem,
        startTime: moment(date).format('YYYY-MM-DD') + 'T' + startTime + ':00',
        endTime: moment(date).format('YYYY-MM-DD') + 'T' + endTime + ':00',
        provider: { ...provider },
        date,
        providerName,
        clinicName,
        sliceId
      };
      const eventsToUpdate = {
        [draggingItem.uid]: { ...draggingItem, tempLoading: true }
      };
      const prevEvent = _.cloneDeep(sourceItem);
      if (draggingItem.clinicCode.toLowerCase() !== clinicCode.toLowerCase()) {
        return;
      }
      onDropApptRequest(moveEvent, eventsToUpdate, prevEvent);
    };
    AlertPopUpRef.current?.show({
      content: `Are you sure you want to move this ${getEventName(item)}?`,
      action: 'info',
      onCancel: () => {
        AlertPopUpRef.current?.close();
      },
      onConfirm: () => {
        onDropSuccess();
      }
    });
  };

  const onDropApptRequest = async (
    eventPayload: any,
    tempEvent: any,
    sourceEvent: any
  ) => {
    // -- remove current appt from store
    store.dispatch(
      removeScheduleSliceEvent(sourceEvent.sliceId, sourceEvent.uid)
    );
    // -- update redux store for calendar view, adding temporary event to destination slice
    const tempEventCard: any = Object.values(tempEvent)[0];
    if (!tempEventCard) return;
    const targetSliceId = tempEventCard.sliceId;
    updateEventOnSliceEvents(targetSliceId, tempEvent);

    // --
    const removeTempEvent = () => {
      store.dispatch(
        removeScheduleSliceEvent(targetSliceId, tempEventCard.uid)
      );
    };
    // -- Send request
    const res: any = await moveEvent(eventPayload);
    if (res) {
      const result = res.data.result;
      if (!result.hasConflicts) {
        removeTempEvent();
        updateEventsOnAction(result);
      } else {
        const moveEventPayload = _.cloneDeep(eventPayload);
        const conflictResponse = result.conflicts;
        conflictResponse.forEach((item: any, indexL: any) => {
          conflictResponse[indexL]['dialogResponse'] = 'override';
        });
        moveEventPayload['conflictsResponses'] = conflictResponse;
        AlertPopUpRef.current?.show({
          content: `${
            result.reasonCode !== 4 ? result.message : `Type doesn't match`
          }${result.allowOverride ? `, do you want to override?` : ''}`,
          action: 'delete',
          hideOk: !result.allowOverride,
          onCancel: () => {
            removeTempEvent();
            // Back source event
            const eventsToUpdate = {
              [sourceEvent.uid]: { ...sourceEvent }
            };
            updateEventOnSliceEvents(sourceEvent.sliceId, eventsToUpdate);
          },
          onConfirm: () => {
            onDropApptRequest(moveEventPayload, tempEvent, sourceEvent);
          }
        });
      }
    }
  };

  const checkFirstInReservation = (colEvents: any, appointment: any) => {
    const { uid } = appointment;
    const apptList = Object.values(colEvents);
    const reservationsList = apptList.filter((v: any) => v.isReservation);
    const currentAppt: any = apptList.find((v: any) => v.uid === uid);
    if (!currentAppt) return false;
    return reservationsList.some(
      (v: any) =>
        v.startTime === currentAppt.startTime &&
        v.providerCode === currentAppt.providerCode &&
        v.clinicCode === currentAppt.clinicCode
    );
  };
  const checkLastInReservation = (colEvents: any, appointment: any) => {
    const { uid } = appointment;
    const apptList = Object.values(colEvents);
    const reservationsList = apptList.filter((v: any) => v.isReservation);
    const currentAppt: any = apptList.find((v: any) => v.uid === uid);
    if (!currentAppt) return false;
    return reservationsList.some(
      (v: any) =>
        v.endTime === currentAppt.endTime &&
        v.providerCode === currentAppt.providerCode &&
        v.clinicCode === currentAppt.clinicCode
    );
  };

  const overflowsPopup = (patient: any) => {
    const { sliceId } = props;
    const row = updatedSchedules[patient.index];
    if (!row) return null;
    const overflows = sortEventsInTimeSlot(row.overflows, 'scheduleStartIdx');
    return overflows?.map((item: any, index: number) => (
      <CellDrop
        sliceId={sliceId}
        key={`drag-source-${sliceId}-${item.appointmentId}-${index}`}
        patient={item}
        providerId={props.providerId}
        isShowingPatientName={isShowingPatientName}
        scheduleTimeProps={scheduleTimeProps}
        inOverflow={true}
        isWidget={props.isWidget}
        onDelete={onDelete}
        firstInReservation={checkFirstInReservation(colEvents, item)}
        lastInReservation={checkLastInReservation(colEvents, item)}
      />
    ));
  };
  useLayoutEffect(() => {
    memoFuncRef.current = {
      onDrop,
      onDropWithWaitingPatient
    };
  });

  if (!slice || !colEvents || !scheduleCol || !mergedSchedules) return null;

  const { regularMeetingTime, dayStartTime, slotHeight } = scheduleTimeProps;
  const { scheduleGroups, emptyPlaces } = scheduleCol;
  const totalHeightByUnit =
    emptyPlaces.length > 0 ? parseInt(emptyPlaces.length) : 0;
  return (
    <ColumnBox
      id={slice.id}
      className={classNames({
        'provider-container': !isWidget,
        'widget-provider-container': isWidget
      })}
    >
      <ScheduleColContainer ref={columnRef}>
        <AlertPopUp ref={AlertPopUpRef} />
        <ScheduleOnesLayout
          heightByUnit={totalHeightByUnit * scheduleTimeProps.slotHeight}
          className="scheduleOnesLayout"
        >
          <div
            style={{
              height: '100%',
              width: '100%'
            }}
          >
            <div
              id="events-cells"
              style={{ display: reservationsOnly ? 'none' : 'unset' }}
            >
              {mergedSchedules.map((patient: any, idx: number) => {
                const y =
                  (timeDiff(patient.startTime, dayStartTime) /
                    regularMeetingTime) *
                  slotHeight;
                const height =
                  (Number(patient.duration) / regularMeetingTime) * slotHeight -
                  1;
                const isCancelled = patient.scheduleEventTypeId === 4;
                const checkCanDrag =
                  !patient.inSeries && patient.uid && !isCancelled;
                return (
                  <div
                    key={`drag-source-container-${sliceId}-${idx}`}
                    onDragStart={() => {
                      if (isCancelled || patient.overflowing) return;
                      if (!checkCanDrag) {
                        AlertPopUpRef.current?.show({
                          content: `Dragging recurring ${getEventName(
                            patient
                          )} is not valid`,
                          hideClose: true,
                          action: 'info'
                        });
                      }
                    }}
                  >
                    {patient.overflowing ? (
                      <CellContainer
                        overflow={true}
                        x={patient.occupyX}
                        y={y}
                        width={patient.occupyWidth}
                        height={height}
                        patient={patient}
                        lastInReservation={checkLastInReservation(
                          colEvents,
                          patient
                        )}
                        firstInReservation={checkFirstInReservation(
                          colEvents,
                          patient
                        )}
                        mainScrollContainerRef={props.mainScrollContainerRef}
                      >
                        <Overflow
                          overflowsPopup={() => {
                            return overflowsPopup(patient);
                          }}
                          patient={patient}
                          isShowingPatientName={isShowingPatientName}
                        />
                      </CellContainer>
                    ) : (
                      <CellDrop
                        sliceId={sliceId}
                        key={`drag-source-${sliceId}-${idx}`}
                        patient={patient}
                        providerId={props.providerId}
                        isShowingPatientName={isShowingPatientName}
                        scheduleTimeProps={scheduleTimeProps}
                        isWidget={props.isWidget}
                        firstInReservation={checkFirstInReservation(
                          colEvents,
                          patient
                        )}
                        lastInReservation={checkLastInReservation(
                          colEvents,
                          patient
                        )}
                        onDelete={onDelete}
                        mainScrollContainerRef={props.mainScrollContainerRef}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <EmptyCellContainer
              emptyPlaces={emptyPlaces}
              sliceId={sliceId}
              scheduleCol={otherScheduleColData}
              scheduleTimeProps={scheduleTimeProps}
              memoFuncRef={memoFuncRef}
              mainScrollContainerRef={props.mainScrollContainerRef}
            />
          </div>
          <ReservationBackgroundContainer id="reservation-background-container">
            {scheduleGroups.map((scheduleGroup: any, key: number) => (
              <ReservationBackground
                idx={key}
                key={`group-backgrond-${sliceId}-${scheduleGroup.uid}`}
                scheduleGroup={scheduleGroup}
                scheduleTimeProps={scheduleTimeProps}
              />
            ))}
          </ReservationBackgroundContainer>
          <ScheduleGroupsLayout
            heightByUnit={totalHeightByUnit}
            className="scheduleGroupsLayout"
          >
            {scheduleGroups.map((scheduleGroup: any, key: number) => (
              <ScheduleGroupBorder
                idx={key}
                isShowingReservationLabel={isShowingReservationLabel}
                key={`group-bk-${sliceId}-${scheduleGroup.uid}`}
                scheduleGroup={scheduleGroup}
                scheduleTimeProps={scheduleTimeProps}
              />
            ))}
          </ScheduleGroupsLayout>
          <ScheduleGroupsLayout heightByUnit={0}>
            {scheduleGroups
              .filter((v: any) => v.isReservation)
              .map((scheduleGroup: any, key: number) => (
                <ScheduleGroupTitle
                  key={`group-title-${scheduleGroup.uid}${key}`}
                  idx={key}
                  isShowingReservationLabel={isShowingReservationLabel}
                  scheduleGroup={scheduleGroup}
                  scheduleTimeProps={scheduleTimeProps}
                />
              ))}
          </ScheduleGroupsLayout>
        </ScheduleOnesLayout>
      </ScheduleColContainer>
    </ColumnBox>
  );
};

export default React.memo(Component);

import React, { FC } from 'react';
import Checkbox from 'src/Framework/Controls/Checkbox';
import { useAppSelector } from 'src/store';
import { updateFilters } from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';

interface IProps {}

const Component: FC<IProps> = ({}: IProps) => {
  const reservationLabelOpacity = useAppSelector(
    (state) => state.scheduleDetails.filters.reservationLabelOpacity
  );
  return (
    <Checkbox
      id="reservation-label-opacity"
      checked={reservationLabelOpacity}
      onChange={() => {
        updateFilters({
          reservationLabelOpacity: !reservationLabelOpacity
        });
      }}
    >
      Reservation label opacity
    </Checkbox>
  );
};

export default React.memo(Component);

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactComponent from '@formio/react/lib/components/ReactComponent';
import settingsForm from './settingsForm';

import { Container } from './styled';
import {
  uploadMultipartFiles,
  updateMultipartFile
} from 'src/Framework/Controls/FileUploader';

import EditorModal, { IModalHandles } from './EditorModal';
import _ from 'lodash';
import { defaultValueField, getComponentState } from '../utils';
import RenderDocumentViews from './RenderDocumentViews';
import RenderIsRenderDocumentViews from './RenderIsRenderDocumentViews';

export const componentType = 'm1image';

interface IValue {
  defaultDocumentId?: null | number;
  crc?: string;
  documentId?: number;
  changes?: any;
  changed?: any;
}

interface IProps {
  value: IValue;
  component: {
    data?: IValue;
    allowUpload: boolean;
    key: string;
    id: string;
  };
  onChange: Function;
  readOnly: boolean;
  root: any;
  mainThis: any;
}

interface IState {
  value: IValue;
}

const M1ImageComp = class extends Component<IProps, IState> {
  fileRef: React.RefObject<HTMLInputElement>;
  defaultFileRef: React.RefObject<HTMLInputElement>;
  editorModalRef: React.RefObject<IModalHandles>;
  constructor(props: IProps) {
    super(props);
    this.state = {
      value: _.cloneDeep(props.value)
    };
    this.fileRef = React.createRef();
    this.defaultFileRef = React.createRef();
    this.editorModalRef = React.createRef();
  }

  getStateValue = () => {
    const { component } = this.props;
    const { value } = this.state;
    return value || _.cloneDeep(component[defaultValueField]);
  };

  setValue = (data: IValue) => {
    const { changed, changes, ...other } = data;
    this.setState(
      {
        value: other
      },
      () => {
        const { onChange } = this.props;
        const { changed, changes, ...other } = this.state.value;
        onChange(other, this.state.value);
      }
    );
  };

  uploadDefaultFile = async (value: File) => {
    const res = await uploadMultipartFiles([value]);
    if (res) {
      const file = res[0];
      this.setValue({
        defaultDocumentId: file.id,
        crc: file.crc,
        documentId: undefined
      });
    }
  };

  onChangeDocument = async (file: File, fromRender = false) => {
    if (fromRender) {
      const res = await uploadMultipartFiles([file]);
      if (res) {
        const file = res[0];
        this.setValue({
          crc: file.crc,
          documentId: file.id
        });
      }
    }
    const value = this.getStateValue();
    const data = value || {
      documentId: null
    };
    if (data.documentId) {
      const res = await updateMultipartFile(file, data.documentId);
      if (res) {
        const newFile = res;
        this.setValue({
          crc: newFile.crc,
          documentId: newFile.id
        });
      }
      return;
    }
    if (!data.defaultDocumentId) {
      const res = await uploadMultipartFiles([file]);
      if (res) {
        const file = res[0];
        this.setValue({
          crc: file.crc,
          defaultDocumentId: file.id
        });
      }
      return;
    }
    if (
      data.defaultDocumentId ||
      (!data.defaultDocumentId && !data.documentId)
    ) {
      const res = await uploadMultipartFiles([file]);
      if (res) {
        const file = res[0];
        this.setValue({
          crc: file.crc,
          documentId: file.id
        });
      }
      return;
    }
  };

  render() {
    const { component, readOnly, root } = this.props;
    const { isDataTab, isPreview, isBuilder, isRender } =
      getComponentState(root);
    return (
      <Container>
        <EditorModal
          ref={this.editorModalRef}
          onChange={this.onChangeDocument.bind(this)}
        />
        <input
          style={{ display: 'none' }}
          type="file"
          ref={this.defaultFileRef}
          multiple={false}
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              this.uploadDefaultFile(file);
            }
          }}
        />
        <input
          style={{ display: 'none' }}
          type="file"
          ref={this.fileRef}
          multiple={false}
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              this.onChangeDocument(file, true);
            }
          }}
        />
        {isDataTab && (
          <>
            <button
              className="btn btn-success"
              type="button"
              style={{ margin: '10px 0' }}
              onClick={() => {
                if (!readOnly) {
                  this.defaultFileRef.current?.click();
                }
              }}
            >
              Upload
            </button>
            <RenderDocumentViews
              getStateValue={this.getStateValue.bind(this)}
            />
          </>
        )}
        {isPreview && (
          <>
            <RenderDocumentViews
              getStateValue={this.getStateValue.bind(this)}
            />
            {component.allowUpload && (
              <button
                className="btn btn-success"
                type="button"
                style={{ margin: '10px 0' }}
              >
                Upload
              </button>
            )}
          </>
        )}
        {isBuilder && (
          <>
            <RenderDocumentViews
              getStateValue={this.getStateValue.bind(this)}
            />
            {component.allowUpload && (
              <button
                className="btn btn-success"
                type="button"
                style={{ margin: '10px 0' }}
              >
                Upload
              </button>
            )}
          </>
        )}
        {isRender && (
          <>
            <RenderIsRenderDocumentViews
              readOnly={readOnly}
              getStateValue={this.getStateValue.bind(this)}
              editorModalRef={this.editorModalRef}
            />
            {component.allowUpload && (
              <button
                className="btn btn-success"
                type="button"
                style={{ margin: '10px 0' }}
                onClick={() => {
                  if (!readOnly) {
                    this.fileRef.current?.click();
                  }
                }}
              >
                Upload
              </button>
            )}
          </>
        )}
      </Container>
    );
  }
};

export default class M1Image extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'Image',
      icon: 'image',
      group: 'Data',
      documentation: '',
      weight: -10,
      schema: M1Image.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    // @ts-ignore
    return ReactComponent.schema({
      type: componentType,
      label: 'Image',
      multiple: false
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element: any, ref: any) {
    if (!element) return null;
    return ReactDOM.render(
      <M1ImageComp
        ref={ref}
        // @ts-ignore
        component={this.component} // These are the component settings if you want to use them to render the component.
        // @ts-ignore
        value={this.dataValue} // The starting value of the component.
        // @ts-ignore
        onChange={this.updateValue}
        // @ts-ignore
        readOnly={this.root.options.readOnly}
        // @ts-ignore
        root={this.root}
        mainThis={this}
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element: any) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}

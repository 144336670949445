import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import store from 'src/store';
import { setPatientInfo } from '../PatientInfoActions';
import {
  IPatientContactsSimpleDto,
  IPatientInfo,
  IPatientClaimsDto,
  IPatientFullDto
} from './types';

const onChangePatientContact = (
  patientId: number,
  payload: IPatientContactsSimpleDto
) => {
  const contacts = store.getState().client.patientInfo[patientId]?.contacts;
  if (contacts) {
    contacts[payload.id] = payload;
    setPatientInfo(
      {
        contacts
      },
      patientId
    );
  }
};

const removePatientContact = (patientId: number, id: number) => {
  const contacts = store.getState().client.patientInfo[patientId]?.contacts;
  if (contacts) {
    delete contacts[id];
    setPatientInfo(
      {
        contacts
      },
      patientId
    );
  }
};

export const getPatientContacts = async (patientId: number | string): Promise<Record<string, IPatientContactsSimpleDto> | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.PATIENT_CONTACTS}`,
      {
        withCredentials: true,
        params: {
          patientId
        }
      }
    );
    if (response.data.success) {
      const contacts = response.data.result.summary;
      setPatientInfo(
        {
          contacts
        },
        patientId
      );
      return contacts
    }
    return
  } catch (e) {
    return
  }
};

export const removeOtherContact = async (patientId: number, id: number) => {
  try {
    const response = await APIHandler.AxiosInstance.delete(
      `${ApiUrl.PATIENT_CONTACTS}/${id}/delete`,
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data.success) {
      removePatientContact(patientId, id);
    }
  } catch (e) { }
};

export const postPatientContact = async (
  patientId: number,
  data: Partial<IPatientContactsSimpleDto>
): Promise<any> => {
  try {
    if (data.otherParty) {
      const { id, ...otherParty } = data.otherParty;
      const response = await APIHandler.AxiosInstance.post(
        `${ApiUrl.OTHER_PARTY}`,
        otherParty,
        {
          withCredentials: true,
          interceptError: true
        }
      );
      if (response.data.success) {
        const otherParty: any = Object.values(response.data.result.summary)[0];
        if (otherParty) {
          const response2 = await APIHandler.AxiosInstance.post(
            `${ApiUrl.PATIENT_CONTACTS}`,
            {
              ...data,
              patientId,
              otherPartyId: otherParty.id
            },
            {
              withCredentials: true,
              interceptError: true
            }
          );
          if (response2.data.success) {
            const contact: any = Object.values(
              response2.data.result.summary
            )[0];
            if (contact) {
              onChangePatientContact(patientId, contact);
              return contact;
            }
          }
        }
      }
    }

    return;
  } catch (e) {
    return;
  }
};

interface IPatchPatientContact
  extends Omit<Partial<IPatientContactsSimpleDto>, 'otherParty'> {
  otherParty?: Partial<IPatientContactsSimpleDto['otherParty']> & {
    id: number;
  };
}

export const patchPatientContact = async (
  patientId: number,
  data: IPatchPatientContact & { id: number }
): Promise<any> => {
  try {
    if (data.otherParty) {
      const response = await APIHandler.AxiosInstance.patch(
        `${ApiUrl.OTHER_PARTY}/${data.otherParty.id}`,
        data.otherParty,
        {
          withCredentials: true,
          interceptError: true
        }
      );
      if (response.data.success) {
      } else {
        return;
      }
    }
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.PATIENT_CONTACTS}/${data.id}`,
      data,
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data.success) {
      const contacts = Object.values(response.data.result.summary);
      contacts.forEach((v: any) => {
        onChangePatientContact(patientId, v);
      });
      return contacts;
    }
    return;
  } catch (e) {
    return;
  }
};

export const getOtherParty = async (
  patientId: number | string
): Promise<any> => {
  try {
    const response = await APIHandler.AxiosInstance.get(ApiUrl.OTHER_PARTY, {
      withCredentials: true,
      params: {
        patientId
      }
    });
    if (response.data.success) {
      const contacts = response.data.result.summary;
      return contacts;
    }
    return;
  } catch (e) {
    return;
  }
};

export const getPatientInfo = async (
  id: number | string
): Promise<IPatientFullDto | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.get(ApiUrl.PATIENTS, {
      withCredentials: true,
      params: {
        id
      }
    });
    if (response.data.success) {
      const data: any = Object.values(response.data.result.summary)[0];
      return data;
    }
    return;
  } catch (e) {
    return;
  }
};

export const savePatientInfo = async (request: any): Promise<any> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.PATIENTS,
      request,
      { withCredentials: true, interceptError: true }
    );
    if (response.data.success) {
      const data = Object.values(response.data.result.summary)[0];
      return data;
    }
    return;
  } catch (e) {
    return;
  }
};

export const patchPatientInfo = async (
  data: Partial<IPatientInfo> & { id: number }
): Promise<any> => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.PATIENTS}/${data.id}`,
      data,
      { withCredentials: true, interceptError: true }
    );
    if (response.data.success) {
      const data = Object.values(response.data.result.summary)[0];
      return data;
    }
    return;
  } catch (e) {
    return;
  }
};

export const getPatientClaims = async (patientId: number): Promise<void> => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      `${ApiUrl.PATIENTS}/${patientId}/claims`,
      { withCredentials: true, interceptError: true }
    );
    if (response.data.success) {
      let claimProfile;
      if (response.data.result.count === 0) {
        //new empty profile
        claimProfile = { patientId: patientId };
      } else {
        claimProfile = Object.values(response.data.result.summary)[0];
      }
      setPatientInfo({ claimProfile }, patientId);
    }
  } catch (err) {
    console.error(err);
  }
};

export const postPatientClaims = async (
  patientId: number,
  data: IPatientClaimsDto
): Promise<IPatientClaimsDto | undefined> => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.PATIENTS}/${patientId}/claims`,
      data,
      { withCredentials: true, interceptError: true }
    );
    if (response.data.success) {
      const claimProfile = Object.values(response.data.result.summary)[0];
      setPatientInfo({ claimProfile }, patientId);
      return claimProfile as IPatientClaimsDto;
    }
    return;
  } catch (err) {
    console.error(err);
    return;
  }
};

const ids = {
  SIDEBAR: 'sidebar-container',
  NOTE_FORMS: 'note-forms',
  NOTE_ATTACHMENTS: 'attachments-forms',
  SELECTED_FORM: (seedId: number) => `selected-form-${seedId}`,
  SELECTED_M1_FORM: (id: number) => `selected-m1-form-${id}`,
  LAYOUT: (id: string) => `layout-${id}`,
  // Edit modal
  EDIT_BUTTON: 'edit-modal-button',
  EDIT_MODAL_CONTAINER: 'edit-modal-container',
  SAVE_BUTTON: 'save-button',
  NOTE_NAME: 'note-name',
  DATE: 'date',
  TIME: 'time',
  //
  RIGHT_BAR_CONTINER: 'right-bar-container',
  PREVIEW: 'preview-button',
  SIGN_APPT: 'sign-appt-button',
  CREATE_APPT: 'create-appt-button',
  RESTRICTED: 'restricted-button',
  PRINT: 'print-button',
  REMOVE: 'remove-button',
  SOFT_SIGN: 'soft-sign-button',
  FINAL_SIGN: 'final-sign-button',
  LOCK: 'lock-button',
  HISTORY_BUTON: 'history-button',
  REMOVE_MODAL: 'remove-modal',
  ROUTE_TO: 'route-to-provider-select',
  SEND_ROUTE: 'send-route-button',
  EDIT_CLINIC_PROVIDER_MODAL: 'edit_clinic_provider_modal',
  ATTACH_APPOINTMENT_MODAL: 'attach_appointment_modal',
  SCHEDULE_APPOINTMENT_MODAL: 'schedule_appointment_modal',
  SIGNATURES_POPUP: 'signatures_popup',
  CCAPS_POPUP_BUTTON: 'ccaps-popup-button',
  CCAPS_POPUP: 'ccaps-popup'
};

export default ids;

import React, { FC, useState, useRef } from 'react';
import { Popover } from 'antd';

import ScheduleOne from './ScheduleOne';
import { mainContainerField } from '../index';

interface IProps {
  onDoubleClickHandler?: (e: any) => void;
  overflowsPopup: Function;
  patient: any;
  isShowingPatientName: boolean;
}

const containerStyle: React.CSSProperties = {
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: '0'
};

const Component: FC<IProps> = ({
  onDoubleClickHandler,
  overflowsPopup,
  patient,
  isShowingPatientName
}: IProps) => {
  const [visible, setVisible] = useState(false);
  const popupRef = useRef(null);
  const cellRef = useRef(null);
  const onKeyDownPopupContainer = (e: React.KeyboardEvent) => {
    if (e.target === e.currentTarget && e.shiftKey && e.key === 'Tab') {
      e.preventDefault();
      if (cellRef.current) {
        //@ts-ignore
        cellRef.current.focus();
      }
    }
    if (
      !e.shiftKey &&
      e.key === 'Tab' &&
      (e.target as HTMLElement)?.id.includes('schedule-one') &&
      !(e.target as HTMLElement)?.parentNode?.parentNode?.parentNode
        ?.nextSibling
    ) {
      e.preventDefault();
      if (cellRef.current) {
        //@ts-ignore
        cellRef.current.focus();
      }
    }
  };
  return (
    <Popover
      open={visible}
      destroyTooltipOnHide={true}
      trigger="click"
      content={
        <div
          style={containerStyle}
          tabIndex={0}
          ref={popupRef}
          onKeyDown={onKeyDownPopupContainer}
        >
          {overflowsPopup()}
        </div>
      }
      getPopupContainer={(trigger: any) =>
        document.getElementById(mainContainerField)!
      }
      placement="rightTop"
      onOpenChange={(value) => {
        setVisible(value);
      }}
      overlayClassName="schedule-overlay-popup"
    >
      <ScheduleOne
        overflowPopupRef={popupRef}
        overflowPopupOpened={visible}
        isShowingPatientName={isShowingPatientName}
        onDoubleClick={(e: any) => {
          if (onDoubleClickHandler) {
            onDoubleClickHandler(e);
          }
        }}
        onClickOverflow={() => setVisible(!visible)}
        appointment={{ ...patient }}
        cellRef={cellRef}
      />
    </Popover>
  );
};

export default Component;

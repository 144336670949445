import React from 'react';

import Appointments from './Appointments';
import Messages from './Messages';
import Tasks from './Tasks';
import Todos from './Todos';
import Risks from './Risks';
import ProviderDashboardRisks from '../ProviderDashboard/Risks';
import CaseRisk from '../CaseManagementDashboard/Risk';

export const Widget = (props: any) => {
  switch (props.type) {
    case 'provider-dashboard-risks':
      return <ProviderDashboardRisks />;
    case 'case-risks':
      return <CaseRisk />;
    case 'risks':
      return <Risks />;
    case 'appointment':
      return <Appointments />;
    case 'tasks':
      return <Tasks {...props} />;
    case 'messages':
      return <Messages />;
    case 'todos':
      return <Todos />;
    case 'fixed_widget':
      return <>{props.content}</>;
    default:
      return <div />;
  }
};

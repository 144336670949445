import { AppUrl } from 'src/Framework/Common/AppUrl';

import { ITaskItem, TaskAction } from './store/types'

interface ITaskParams {
    note: {
        id: number
    } | null,
    patientId: null | number
    createApptNoteURL: string | null,
    referralId: number | null
    templateFormRecordId: number | null,
    m1FormRecordId: number | null,
    providerId: number | null,
    reqOrderId: number | null
}

export const getTaskParams = (v: ITaskItem): ITaskParams => {
    let note = null;
    let patientId: any = null;
    let createApptNoteURL: string | null = null;
    let referralId: number | null = null
    let templateFormRecordId: number | null = null
    let providerId: number | null = null
    let m1FormRecordId: number | null = null
    let reqOrderId: number | null = null
    const getPatient = (v: ITaskItem) => {
        const entity = v.innerEntities?.find((v) => v.entityType === 'patient');
        if (entity) {
            patientId = entity.entityId;
        }
    }
    const hasNote = v.taskType.value === 'Notes';
    if (hasNote) {
        const entity = v.innerEntities?.find((v) => v.entityType === 'note');
        if (entity) {
            const inner = entity.innerEntities?.[0];
            if (inner) {
                if (
                    v.action === TaskAction.LockNote ||
                    v.action === TaskAction.AttachToAppointment
                ) {
                    patientId = inner.entityId;
                    note = {
                        id: entity.entityId
                    };
                }

            }
        } else {
            const entity = v.innerEntities?.find((v) => v.entityType === 'appointment');
            if (entity) {
                const inner = entity.innerEntities?.[0];
                if (inner) {
                    if (v.action === TaskAction.CreateAppointmentNote) {
                        patientId = inner.entityId;
                        createApptNoteURL = `${AppUrl.CLIENT}/${patientId}${AppUrl.PATIENT_NOTES}${AppUrl.NOTE_TYPE}?appointmentId=${entity.entityId}`;
                    }
                }
            }
        }
    }

    const isM1Forms = v.taskType.value === 'M1 Forms';
    if (isM1Forms) {
        const entity = v.innerEntities?.find((v) => v.entityType === 'm1FormRecord');
        if (entity) {
            const inner = entity.innerEntities?.[0];
            if (inner) {
                if (inner.entityType === 'provider') {
                    providerId = inner.entityId
                }
                if (inner.entityType === 'patient') {
                    patientId = inner.entityId
                }
                m1FormRecordId = entity.entityId

            }
        }
    }

    const isForms = v.taskType.value === 'Forms';
    if (isForms) {
        const entity = v.innerEntities?.find((v) => v.entityType === 'templateFormRecord');
        if (entity) {
            const inner = entity.innerEntities?.[0];
            if (inner) {
                patientId = inner.entityId;
                templateFormRecordId = entity.additionalFields?.seedId || null
            }
        }
        const reqOrder = v.innerEntities?.find((v) => v.entityType === 'reqOrder');
        if (reqOrder) {
            referralId = reqOrder.entityId
            const inner = reqOrder.innerEntities?.[0];
            if (inner) {
                patientId = inner.entityId;
            }
        }
    }



    const PointOfCareResult = v.taskType.value === 'Point of care Result';
    if (PointOfCareResult) {
        patientId = v.patient?.id || null
        const entity = v.innerEntities?.find((v) => v.entityType === 'reqOrder');
        if (entity) {
            reqOrderId = entity.entityId;
        }
    }

    if (!patientId) {
        getPatient(v)
    }

    return {
        note,
        referralId,
        patientId,
        createApptNoteURL,
        templateFormRecordId,
        providerId,
        m1FormRecordId,
        reqOrderId
    }
}
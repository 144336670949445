import * as React from 'react';
import { default as NumberFormat } from 'react-number-format';
import Popover from 'react-tiny-popover';

import { Option as Option2 } from 'src/Activities/Schedule/Common/Option/Option';
import { CaretDownSvg } from 'src/Activities/Schedule/Svg/CaretDownSvg';
import { CaretUpSvg } from 'src/Activities/Schedule/Svg/CaretUpSvg';
import { onChange } from '../Demographics';

interface IProps {
  info: {
    cellPhone: string;
    homePhone: string;
    pager: string;
    workPhone: string;
    typePreferredPhoneId: string;
  };
  onChangeHandler: onChange;
  onBlurHandler?: any;
}

interface IState {
  isPopoverOpen: boolean;
  focus: boolean;
}

export const phoneIds = {
  cellPhone: 2,
  homePhone: 1,
  pager: 3,
  workPhone: 0
};

class PreferredNumber extends React.Component<IProps, IState> {
  public mainField: React.RefObject<any>;
  public optionField: React.RefObject<any>;
  public lastField: React.RefObject<any>;
  public firstField: React.RefObject<any>;

  constructor(props: any) {
    super(props);
    this.state = {
      isPopoverOpen: false,
      focus: false
    };
    this.mainField = React.createRef();
    this.lastField = React.createRef();
    this.optionField = React.createRef();
    this.firstField = React.createRef();
  }

  onClickHandler = () => {
    this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
  };

  closePopOver = () => {};

  onFocus = () => this.setState({ focus: true });

  onBlur = () =>
    this.setState({
      focus: false
    });

  onKeyDownAccessibility = (
    e: React.KeyboardEvent<HTMLDivElement>,
    onEnter?: (() => any) | undefined
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.currentTarget) {
        // @ts-ignore
        e.currentTarget.click();
        if (onEnter) {
          onEnter();
        }
      }
    }
    if (e.key === 'Tab' && this.state.isPopoverOpen) {
      if (e.shiftKey) {
        if (e.currentTarget === this.mainField.current) {
          e.preventDefault();
          this.lastField.current.focus();
        }
        if (e.currentTarget === this.firstField.current) {
          e.preventDefault();
          this.optionField.current.focus();
        }
        if (e.currentTarget === this.optionField.current) {
          e.preventDefault();
          this.mainField.current.focus();
        }
      } else {
        if (e.currentTarget === this.mainField.current) {
          e.preventDefault();
          this.optionField.current.focus();
        }
        if (e.currentTarget === this.optionField.current) {
          e.preventDefault();
          this.firstField.current.focus();
        }
        if (e.currentTarget === this.lastField.current) {
          e.preventDefault();
          this.mainField.current.focus();
        }
      }
    }
  };

  render() {
    const { info } = this.props;
    const { isPopoverOpen } = this.state;
    const typePreferredPhoneIdString = info.typePreferredPhoneId + '';
    const preferred = Object.keys(phoneIds).find(
      (key) => phoneIds[key] == info.typePreferredPhoneId
    );
    const number = preferred ? info[preferred] : '';

    return (
      <Popover
        key="preferred__control"
        isOpen={isPopoverOpen}
        position={['bottom']}
        align={'center'}
        padding={0}
        onClickOutside={() => {
          this.setState({ isPopoverOpen: false });
          this.props.onBlurHandler && this.props.onBlurHandler();
        }}
        containerClassName="preferred__control"
        content={({ position, targetRect }) => {
          const style: any = {};
          if (['top', 'bottom'].indexOf(position) > -1) {
            style.width = `${targetRect.width}px`;
          }
          return (
            <div style={style} className="preferred__control--wrapper">
              <p>Preferred</p>
              {Object.keys(phoneIds).map((key: any, index) => (
                <div
                  className="preferred__control--wrapper--content"
                  key={index}
                >
                  <div
                    className="preferred__control--wrapper--content__left"
                    key={index}
                  >
                    <Option2
                      onKeyDown={this.onKeyDownAccessibility}
                      inputRef={index === 0 ? this.optionField : undefined}
                      key={index}
                      label={
                        key === 'pager' ? 'alt' : key.replace(/Phone/g, '')
                      }
                      name="phoneNumbers"
                      value={phoneIds[key]}
                      tabIndex={0}
                      checked={info.typePreferredPhoneId == phoneIds[key]}
                      onClick={(e: any) => {
                        this.props.onChangeHandler(
                          e.target.value,
                          'typePreferredPhoneId'
                        );
                      }}
                    />
                  </div>
                  <div className="preferred__control--wrapper--content__right">
                    <NumberFormat
                      onKeyDown={this.onKeyDownAccessibility}
                      getInputRef={
                        index === 3
                          ? this.lastField
                          : index === 0
                          ? this.firstField
                          : undefined
                      }
                      tabIndex={0}
                      format={
                        key === 'homePhone' || key === 'cellPhone'
                          ? '(###) ###-####'
                          : '(###) ###-#### Ext(#####)'
                      }
                      mask="_"
                      value={info[key]}
                      displayType={'input'}
                      onValueChange={(e) => {
                        this.props.onChangeHandler(e.value, key);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          );
        }}
      >
        <div
          tabIndex={0}
          ref={this.mainField}
          onKeyDown={this.onKeyDownAccessibility}
          onClick={this.onClickHandler.bind(this)}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          className={`preferred__control--number ${
            isPopoverOpen && 'preferred__control--number--active'
          } ${this.state.focus ? 'is-focused' : null}`}
        >
          <span className="visually-hidden">Preferred Phone Number</span>
          <div className="preferred__control--number__wrapper">
            <div className="preferred__control--container">
              <span className="preferred__control--container--copy">
                {preferred === 'pager'
                  ? 'alt'
                  : preferred?.replace(/Phone/g, '')}
              </span>
              <span className="preferred__control--container--number">
                {typePreferredPhoneIdString
                  ? typePreferredPhoneIdString === '1'
                    ? number.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3')
                    : typePreferredPhoneIdString === '2'
                    ? number.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3')
                    : `(${(number ? number : '').substr(0, 3)}) ${(number
                        ? number
                        : ''
                      ).substr(3, 3)}-${(number ? number : '').substr(
                        6,
                        4
                      )} Ext(${(number ? number : '').substr(10, 4)})`
                  : ''}
              </span>
            </div>
            <span className="preferred__control--icon">
              {isPopoverOpen ? <CaretUpSvg /> : <CaretDownSvg />}
            </span>
          </div>
        </div>
      </Popover>
    );
  }
}

export default PreferredNumber;

import React, { useImperativeHandle, useState } from 'react';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import Modal from 'src/Framework/Controls/Modal';

import { PopupContainer } from 'src/Activities/Schedule/Popups/EventPopup/styled';
import { IProps as IMainProps } from 'src/Activities/Schedule/Popups/ScheduleDetailPopup/Main';
import { getEvent } from 'src/Activities/Schedule/Store/Events/Actions';
import { IProviderEvent } from 'src/Activities/Schedule/Store/Events/types';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

import EventInfo from './EventInfo';

interface IShow {
  eventId: number;
  sliceId?: any;
  currentProviderId?: number;
}

export interface IModalHandles {
  show(obj: IShow): void;

  close(): void;
}

type IMain = Omit<IMainProps, 'data' | 'subjectSummary'>;

interface IProps extends IMain {
  eventId: number;
  sliceId?: any;
  currentProviderId?: number;
}

const modalProps = {
  destroyOnClose: true,
  mask: false
};
const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { eventId, ...otherProps },
  ref
) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IProviderEvent | null>(null);
  const [visible, setVisible] = useState(false);
  useModalFocus({ active: visible });

  const show = async (incomingData: IShow) => {
    init(incomingData.eventId ? incomingData.eventId : eventId);
    setVisible(true);
  };

  const close = () => {
    otherProps.onClosePopup();
    setVisible(false);
  };

  const setToDefault = () => {};

  useImperativeHandle(ref, () => ({
    show: (obj: IShow) => show(obj),
    close
  }));

  const init = async (eventId: number) => {
    setLoading(true);
    const eventInfo = await getEvent(eventId);
    if (eventInfo) {
      setData(eventInfo);
    }
    setLoading(false);
  };

  const defaultWidth = 840;

  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        hideHeader: true,
        hideCloseButton: true
      }}
      width={defaultWidth}
      visible={visible}
      onClose={close}
      headerBorder={true}
      hideClose={true}
      afterClose={setToDefault}
      modalProps={modalProps}
      hideHeader={true}
      isDragable={true}
    >
      <PopupContainer>
        {loading && <AbsoluteLoader />}
        {data && (
          <EventInfo
            data={data}
            setData={setData}
            {...otherProps}
            onClosePopup={close}
            inModal={false}
          />
        )}
      </PopupContainer>
    </Modal>
  );
};

export default React.forwardRef(Component);

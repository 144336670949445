import React, { FC } from 'react';

import { Container, AbsoluteContainer, InnerContainer } from './styled';

interface IProps {
  children: React.ReactChildren | React.ReactChild;
}

const AbsoluteScroller: FC<IProps> = ({ children }: IProps) => {
  return (
    <Container>
      <AbsoluteContainer className="absolute-container">
        <InnerContainer>{children}</InnerContainer>
      </AbsoluteContainer>
    </Container>
  );
};

export default React.memo(AbsoluteScroller);

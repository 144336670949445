import * as React from 'react';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import * as container from './TabSelect_styles';

interface IState {}

export interface IProps {
  maxSel: any;
  onFinishSelection: any;
  onCancelSelection: any;
  sel: any;
  hideRightContainer?: boolean;
}

class HeaderMultiselect extends React.Component<IProps, IState> {
  render() {
    const {
      maxSel,
      onFinishSelection,
      sel,
      onCancelSelection,
      hideRightContainer
    } = this.props;
    return (
      <container.HeaderMultiselect>
        <div className="container">
          <div className="container__left">
            <span className="selected">{sel.length}</span>
            {maxSel > 0 && <span> / {maxSel} </span>}
            <span> {` Members added`} </span>
          </div>
          {!hideRightContainer && (
            <div className="container__right">
              <ButtonIcon
                isButton={true}
                label="Cancel Selection and Close popup"
                id="close-button"
                onClick={onCancelSelection}
                name={ICONS_LIST.thinClose}
                size={28}
              />
              <ButtonIcon
                size={28}
                isButton={true}
                label="Save selection"
                id="save-button"
                onClick={() => onFinishSelection(sel)}
                color={globalStyleVariables.white}
                name={ICONS_LIST.check2}
                iconScale={1.5}
                containerBackground={globalStyleVariables.newBrandingPrimary200}
                iconStyle={{
                  background: 'white'
                }}
              />
            </div>
          )}
        </div>
      </container.HeaderMultiselect>
    );
  }
}

export default HeaderMultiselect;

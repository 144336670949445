import { store } from "src"
import { actionsTypes } from ".."
import { TempFieldsForTable } from "src/Framework/Controls/Table/types"

import { ITypeCheckInStatus } from '../types'

export const setSummary = (payload: Record<string, ITypeCheckInStatus>) => {
    store.dispatch({
        type: actionsTypes.SET_SUMMARY,
        payload
    })
}

export const onChangeItem = (value: ITypeCheckInStatus & Partial<TempFieldsForTable>) => {
    const data = { ...store.getState().adminPanel.checkInStatus.summary }
    data[value.id] = {
        ...value
    }
    setSummary(data)
}

import React, { FC } from 'react';

import Container, { ICommon } from './Container';
import { useHippa } from '../../store/Hooks/useHippa';

import Icon from '../StatusIcon/Status';

export interface IMiddleProps extends ICommon {
  patientId: number;
  data?: undefined;
}

const Component: FC<IMiddleProps> = ({
  patientId,
  ...otherProps
}: IMiddleProps) => {
  const { data, loading } = useHippa({ patientId });
  if (data) {
    return <Container {...otherProps} data={data} />;
  }
  return <Icon useIcon={false} loading={loading} />;
};

export default React.memo(Component);

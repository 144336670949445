import styled from 'styled-components';

interface IProps {
  labelInRow?: boolean;
  bottomMargin?: boolean;
}

export const Container = styled.div<IProps>`
  display: flex;
  align-items: ${({ labelInRow }) => (labelInRow ? 'center' : 'flex-start')};
  padding-bottom: ${({ bottomMargin, labelInRow }) =>
    bottomMargin && labelInRow ? '25px' : 'unset'};

  .help-icon {
    margin: 0 5px 0 5px;
    cursor: pointer;
  }
`;

export const Title = styled.div`
  display: flex;
`;

export const Star = styled.div`
  height: 12px;
  width: 12px;
  mask-size: contain;
  mask-position: center center;
  mask-repeat: no-repeat no-repeat;
  margin: 0 5px 0 0;
`;

interface ILabel {
  labelInRow?: boolean;
}

export const Label = styled.label<ILabel>`
  ${({ labelInRow }) =>
    !labelInRow
      ? `
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    margin: 0 0 5px 0;
    color: #666666;
`
      : `
    white-space:nowrap;
`}
`;

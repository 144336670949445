import React, { FC } from 'react';

import Container from '../components/Container';
import Header from '../components/Header';

interface IProps {}

const Component: FC<IProps> = ({}: IProps) => {
  return (
    <Container>
      <Header borderBottom={true}>Risks</Header>
    </Container>
  );
};

 

export default Component;

import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
`

export const TopPart = styled.div`
    padding: 16px 16px 8px 16px;
`

export const ReservationLabel = styled.div`
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
`
const ids = {
    reason: "reason-selector",
    noteArea: "note-area",
    cancel: "cancel-button",
    remove: "remove-button",
    info: 'info-button',
    forms: 'forms-button',
    chart: 'chart-button',
    note: 'note-button',
    close: 'close-button'
}
export default ids
import React, { FC, useEffect, useRef } from 'react';

import Collapse from 'src/Framework/Controls/CollapseA11y';
import Icon from 'src/Framework/Controls/Icon';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { formattedDate } from 'src/Framework/Shared/Shared';
import { patientDisplayName } from 'src/Framework/util/format';
import { useAppSelector } from 'src/store';

import { IAlertsData } from '../AppointmentPopup';
import { ALERT_ICONS } from 'src/Activities/Counseling/Dashboard/Alerts/Alert';
import * as insuranceCompaniesActions from 'src/App/Admin/Pages/InsuranceCompanies/store/Actions';

import {
  Container,
  Title,
  BlockContainer,
  RightPart,
  LeftPart
} from './styled';

const { Panel } = Collapse;

interface IProps {
  data: IAlertsData;
}

const Component: FC<IProps> = ({ data }: IProps) => {
  const containerRef = useRef(null);
  const insurancesCompanies = useAppSelector(
    (state) => state.adminPanel.insuranceCompanies.summary
  );
  useEffect(() => {
    insuranceCompaniesActions.get();
    if (containerRef.current) {
      //@ts-ignore
      containerRef?.current.focus();
    }
  }, []);

  const patientsList = data.patients.filter(
    (v) =>
      data.insurances[v.subjectId]?.length > 0 ||
      data.patientAlerts[v.subjectId]?.length > 0
  );
  const moreThan2Patients = patientsList.length > 1;
  return (
    <Container tabIndex={0} ref={containerRef}>
      <Collapse
        defaultActiveKey={moreThan2Patients ? undefined : [0]}
        icon={'cleanIcon'}
        className="collapse-cards"
      >
        {patientsList.map((v, i) => (
          <Panel
            key={i}
            header={
              <Title>{`${patientDisplayName(v)} (${
                (data.patientAlerts[v.subjectId]?.length || 0) +
                (data.insurances[v.subjectId]?.length || 0)
              })`}</Title>
            }
          >
            {data.patientAlerts[v.subjectId]?.map((value) => {
              const alert = ALERT_ICONS[value.patientAlertTypeId] || {};
              const patientName = patientDisplayName(value.patient);
              return (
                <BlockContainer key={value.id}>
                  <RightPart
                    style={{
                      background: `${alert.color}10`
                    }}
                  >
                    <div
                      className="alert-icon-container"
                      style={{
                        background: alert.color,
                        WebkitMaskImage: `url(${alert.url})`,
                        maskImage: `url(${alert.url})`
                      }}
                    />
                  </RightPart>
                  <LeftPart tabIndex={0}>
                    <div>
                      <b
                        style={{
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        {value.description}
                      </b>
                    </div>
                    <div>
                      {`${formattedDate(value.startDateTime)} - ${formattedDate(
                        value.endDateTime
                      )} by ${value.userName} to ${patientName}`}
                    </div>
                  </LeftPart>
                </BlockContainer>
              );
            })}
            {data.insurances[v.subjectId]?.map((value) => {
              const patient = data.patients.find(
                (val) => `${val.subjectId}` === `${v.patientId}`
              );
              return (
                <BlockContainer key={value.id}>
                  <RightPart
                    style={{
                      backgroundColor: '#D7EBF8'
                    }}
                  >
                    <Icon
                      name="fileContent"
                      color={globalStyleVariables.blue}
                    />
                  </RightPart>
                  <LeftPart tabIndex={0}>
                    {`${
                      patient ? `(${patientDisplayName(patient)})` : ''
                    } Insurance ${
                      value.insuranceCompanyId !== null
                        ? insurancesCompanies[value.insuranceCompanyId]?.name ||
                          ''
                        : ''
                    } has expired`}
                  </LeftPart>
                </BlockContainer>
              );
            })}
          </Panel>
        ))}
      </Collapse>
    </Container>
  );
};

export default React.memo(Component);

import React, { FC, useState } from 'react';

import { handleDownload } from 'src/Framework/Controls/FilesDownloader';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import { Item } from './styled';

interface IProps {
  value: any;
  type?: string;
  onRemove?: Function;
  index: number;
}

const defaultType =
  require('src/Framework/Common/Svg/attachments/text.svg').default;

const types = {
  'image/jpeg': defaultType,
  'image/png': defaultType,
  'application/pdf': require('src/Framework/Common/Svg/attachments/pdf.svg')
    .default
};

const Component: FC<IProps> = ({ value, type, onRemove, index }: IProps) => {
  const [loading, setLoading] = useState(false);
  const typeIcon = types[value.type] || defaultType;
  const onClick = async () => {
    if (value.id && !loading) {
      setLoading(true);
      await handleDownload(value);
      setLoading(false);
    }
  };
  return (
    <Item onClick={onClick} tabIndex={0} role="listitem">
      {loading && <AbsoluteLoader />}
      <div className="type-icon-container">
        <div
          className="type-icon"
          style={{
            WebkitMaskImage: `url(${typeIcon})`,
            maskImage: `url(${typeIcon})`
          }}
        />
      </div>
      <div className="name">
        {value.name
          ? value.name
          : value.originalFileName
          ? value.originalFileName
          : value.fileName}
      </div>
      {onRemove && (
        <ButtonIcon
          name={ICONS_LIST.thinClose}
          isButton={true}
          label="remove item"
          onClick={(e) => {
            e.stopPropagation();
            onRemove && onRemove(index);
          }}
          size={14}
          disableBackground={true}
        />
      )}
    </Item>
  );
};

export default Component;

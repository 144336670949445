import React, { FC } from 'react';

import Container, { IProps as IContainerProps } from './Container';
import MiddleComponent, { IMiddleProps } from './MiddleComponent';

type IProps = IMiddleProps | IContainerProps;

const PolicyStatusContainer: FC<IProps> = (props: IProps) => {
  const { patientId, data } = props;
  if (patientId) {
    return (
      <MiddleComponent {...props} data={undefined} patientId={patientId} />
    );
  }
  if (data) {
    return <Container {...props} data={data} patientId={undefined} />;
  }
  return null;
};

export default React.memo(PolicyStatusContainer);

import * as signalR from '@microsoft/signalr';
import Logger from '../Logger';

const CONNECTIONS = {
    UPDATE_SLICE: 'UpdateSlice',
    UPDATE_EVENT: 'UpdateEvent',
    UPDATE_SERIES: 'UpdateSeries',
    UPDATE_ARRIVED_COUND: 'UpdateArrivedCount'
} as const

type CONNECTIONS_TYPE = keyof typeof CONNECTIONS

export type UPDATE_CONNECTIONS = Record<CONNECTIONS_TYPE, (output: any) => any>

interface IProps {
    signalRUrl: string
    update_connections: UPDATE_CONNECTIONS
    tenantId: string
    logger?: Logger
    onConnected?: () => any
}

const notFilterArray = (options: any, values: any) => {
    return options.filter(
        (opt: any) => values.map((item: any) => item).indexOf(opt) <= -1
    );
};

class Subscription {
    signalRConnection: signalR.HubConnection | null = null;
    logger?: Logger
    tenantId?: string
    update_connections: UPDATE_CONNECTIONS | null = null
    onConnected?: () => Promise<any>
    lastSubscribedSlices: string[] = []
    timer?: NodeJS.Timeout;
    constructor(props: IProps) {
        const { tenantId, signalRUrl, update_connections, logger, onConnected } = props
        this.tenantId = tenantId;
        this.update_connections = update_connections;
        this.logger = logger;
        this.onConnected = onConnected
        this.startConnection(signalRUrl)
    }
    startConnection = async (signalRUrl: string) => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(signalRUrl)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (retryContext) => {
                    if (retryContext.elapsedMilliseconds < 60000) {
                        return 1000 + Math.random() * 10000;
                    } else {
                        this.logger?.log("Reconnecting ERROR", retryContext, 'error');
                        return null;
                    }
                },
            })
            .build();
        this.signalRConnection = connection;
        connection.onclose(async () => {
            this.logger?.log("SignalR closed", '', 'warning');
        });
        connection.onreconnecting((error) => {
            this.logger?.log("Trying to reconnect to signalR", '', 'warning');
        });
        connection.onreconnected((connectionId: any) => {
            this.logger?.log(
                `Successfully reconnected to signalR, new Id - ${connectionId}`
            );
            this.reconnect()
        });
        try {
            await connection.start();
            this.logger?.log('SignalR connected');
            if (this.onConnected) {
                this.onConnected();
            }
            this.bindSubscriptionEvents()
            this.reconnectSubscribedSlices()
        } catch (err) {
            this.logger?.log('SignalR connection ERROR', err, 'error');
        }
    }

    reconnect = () => {
        this.SubscribeToTenant()
        if (this.lastSubscribedSlices.length !== 0) {
            this.subscribeToSlices(this.lastSubscribedSlices)
        }
    }

    reconnectSubscribedSlices = () => {
        if (this.timer) {
            clearInterval(this.timer)
        }
        this.timer = setInterval(() => {
            this.reconnect()
        }, 120000)
    }

    SubscribeToTenant = () => {
        if (this.tenantId) {
            this.logger?.log("Subscribing To Tenant", this.tenantId);
            if (this.signalRConnection) {
                if (this.signalRConnection.state === signalR.HubConnectionState.Connected) {
                    this.signalRConnection
                        .invoke("SubscribeToTenant", `schedule-tenant#${this.tenantId}`)
                        .then(() => this.logger?.log("Subscription To Tenant successful", this.tenantId))
                        .catch((err: any) => this.logger?.log("Subscription To Tenant ERROR", err, 'error'));
                } else {
                    this.logger?.log("signalRConnection not in connected state", undefined, 'error')
                }
            } else {
                this.logger?.log("signalRConnection not found", undefined, 'error')
            }
        } else {
            this.logger?.log("tenantId not found", undefined, 'error')
        }
    }

    subscribeToSlices = async (newSlices: string[]) => {
        if (this.signalRConnection) {
            if (newSlices && newSlices.length > 0) {
                try {
                    this.logger?.log("Subscribing To Slice...", newSlices);
                    if (this.signalRConnection.state === signalR.HubConnectionState.Connected) {
                        const res = await this.signalRConnection.invoke("SubscribeToSlice", newSlices)
                        if (res) {
                            this.logger?.log(res, newSlices)
                        }
                    } else {
                        this.logger?.log("signalRConnection not in connected state", undefined, 'error')
                    }
                } catch (err) {
                    this.logger?.log("Subscribe To Slice(s) ERROR", err, 'error')
                }
            }
        } else {
            this.logger?.log("signalRConnection not found", undefined, 'error')
        }
    };

    unsubscribeToSlices = async (removedSlices: any) => {
        if (this.signalRConnection) {
            if (removedSlices && removedSlices.length > 0) {
                this.logger?.log("Unsubscribing Slices", removedSlices, 'warning');
                if (this.signalRConnection.state === signalR.HubConnectionState.Connected) {
                    this.signalRConnection
                        .invoke("UnsubscribeToSlice", removedSlices)
                        .then(() => this.logger?.log("Successfully unsubscribed from slice(s)", removedSlices, 'warning'))
                        .catch((err: any) => this.logger?.log("Unsubscribe from slice(s) ERROR", err, 'error'));
                } else {
                    this.logger?.log("signalRConnection not in connected state", undefined, 'error')
                }
            }
        } else {
            this.logger?.log("signalRConnection not found", undefined, 'error')
        }
    };

    unsubscribeAllConnections = () => {
        if (this.signalRConnection) {
            const connection = this.signalRConnection;
            if (connection) {
                if (this.signalRConnection.state === signalR.HubConnectionState.Connected) {
                    this.logger?.log("Unsubscribe all listeners", connection);
                    connection.off(CONNECTIONS.UPDATE_SLICE);
                    connection.off(CONNECTIONS.UPDATE_EVENT);
                    connection.off(CONNECTIONS.UPDATE_SERIES);
                    connection.off(CONNECTIONS.UPDATE_ARRIVED_COUND);
                } else {
                    this.logger?.log("signalRConnection not in connected state", undefined, 'error')
                }
            }
        }
    }

    bindSubscriptionEvents = () => {
        if (this.signalRConnection) {
            const connection = this.signalRConnection;
            if (connection) {
                if (this.signalRConnection.state === signalR.HubConnectionState.Connected) {
                    this.logger?.log("Subscribing to listeners", CONNECTIONS);
                    connection.on(CONNECTIONS.UPDATE_SLICE, (output: any) => {
                        this.logger?.log(CONNECTIONS.UPDATE_SLICE, output)
                        this.update_connections?.UPDATE_SLICE(output)
                    });

                    connection.on(CONNECTIONS.UPDATE_EVENT, (output: any) => {
                        this.logger?.log(CONNECTIONS.UPDATE_EVENT, output)
                        this.update_connections?.UPDATE_EVENT(output)
                    });

                    connection.on(CONNECTIONS.UPDATE_SERIES, (output: any) => {
                        this.logger?.log(CONNECTIONS.UPDATE_SERIES, output)
                        this.update_connections?.UPDATE_SERIES(output)
                    });

                    connection.on(CONNECTIONS.UPDATE_ARRIVED_COUND, (output: any) => {
                        this.logger?.log(CONNECTIONS.UPDATE_ARRIVED_COUND, output)
                        this.update_connections?.UPDATE_ARRIVED_COUND(output)
                    });
                } else {
                    this.logger?.log("signalRConnection not in connected state", undefined, 'error')
                }
            }
        }
    }

    refreshSubscriptions = (
        subscriptionInfo: {
            slices: object
            existingSlices: object
        },
        refreshAll: boolean,
        unsubscribe: boolean = true
    ) => {
        const connection = this.signalRConnection;
        if (connection) {
            if (connection.state === signalR.HubConnectionState.Connected) {
                if (subscriptionInfo) {
                    this.logger?.log("Refreshing last slice subscriptions");
                    this.SubscribeToTenant();
                    const keys = Object.keys(subscriptionInfo.slices);
                    const existingKeys = Object.keys(subscriptionInfo.existingSlices);
                    const removedSlices = notFilterArray(existingKeys, keys);
                    this.lastSubscribedSlices = keys
                    if (refreshAll) {
                        this.subscribeToSlices(keys);
                        if (unsubscribe) {
                            this.unsubscribeToSlices(removedSlices);
                        }
                    } else {
                        const newSlices = notFilterArray(keys, existingKeys);
                        this.subscribeToSlices(newSlices);
                        if (unsubscribe) {
                            this.unsubscribeToSlices(removedSlices);
                        }
                    }
                }
            } else {
                this.logger?.log("signalRConnection not in connected state", undefined, 'error')
            }
        }
    };

}

export default Subscription
import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"

export const getTrustedTicket = async () => {
    try {
        const response = await APIHandler.AxiosInstance.post(`${ApiUrl.TRUSTED_TICKET}`, {}, {
            withCredentials: true,
            interceptError: true
        })
        if (response.data.success) {
            return response.data.result.token
        }
        return
    } catch (error) {
        return
    }
}
import _ from 'lodash';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { getProviders } from 'src/Activities/Subject/PatientListActions';

import { getSubjectSearch } from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';

import store from 'src/store';

import { actionsTypes } from './Reducer';
import { ITemplateForm } from 'src/App/TemplateForms/store/types';
import { ModelFormat } from 'src/App/Admin/Pages/FormBuilder/Lists/BuildingBlocks/store/types';
import { IBuildingForm } from 'src/App/Admin/Pages/FormBuilder/Lists/Forms/store/types';
import { IFormItem } from 'src/Activities/Forms/FormList/types';
import { IFileSimpleDto } from 'src/Framework/Controls/FileUploader';

export const getStudents = async (search: string) => {
    try {
        const response = await getSubjectSearch(search, 0, undefined, 0);
        if (response.data.success) {
            return response.data.result.summary;
        }
    } catch (e) {
        console.log({ e });
    }
};

export const setLabelsToMessage = async (messageId: number, labels: any) => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            `${ApiUrl.GET_MESSAGES_LIST}${messageId}/labels`,
            { messageId, labels },
            { withCredentials: true, interceptError: true }
        );
        if (response.data) {
            if (response.data.success) {
                return response.data;
            }
        }
        return;
    } catch (e) {
        return;
    } finally {
    }
};

export const getLabels = async () => {
    try {
        const response = await APIHandler.AxiosInstance.get(`/labels`, {
            withCredentials: true,
            interceptError: true
        });
        if (response.data) {
            if (response.data.success) {
                setLabels(response.data.result.summary);
                return response.data;
            }
        }
        return;
    } catch (e) {
        return;
    } finally {
    }
};

export const getUserGroups = async () => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            ApiUrl.GET_USER_GROUPS,
            { withCredentials: true }
        );
        if (response.data) {
            if (response.data.success) {
                return response.data.result.summary;
            }
        }
        return;
    } catch (e) {
        return;
    } finally {
    }
};

export const getGroupUsers = async () => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            ApiUrl.GET_GROUP_USERS,
            { withCredentials: true }
        );
        if (response.data) {
            if (response.data.success) {
                const data = Object.values(response.data.result.summary);
                return _.orderBy(data, 'name', 'asc');
            }
        }
        return;
    } catch (e) {
        return;
    } finally {
    }
};

export const getM1Forms = async (): Promise<
    Record<string, IBuildingForm> | undefined
> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            ApiUrl.M1FormsTemplates,
            {
                withCredentials: true,
                params: {
                    format: ModelFormat.Simple,
                    onlyLatest: true,
                    isActive: true
                }
            }
        );
        if (response.data.success) {
            const data = response.data.result.summary;
            return data;
        }
        return;
    } catch (e) {
        console.error({ e });
        return;
    } finally {
    }
};

export const getForms = async (): Promise<
    Record<string, ITemplateForm> | undefined
> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            ApiUrl.GET_FORM_TEMPLATE,
            {
                withCredentials: true,
                params: {
                    isOnlyLatestForm: true
                }
            }
        );
        if (response.data) {
            if (response.data.success) {
                return response.data.result.summary;
            }
        }
        return;
    } catch (e) {
        return;
    } finally {
    }
};

const setClinicProviders = (payload: any) => {
    store.dispatch({
        type: actionsTypes.SET_CLINIC_PROVIDERS,
        payload
    });
};

export const getProvidersByClinicCode = async (code: string) => {
    const request: any = {
        typeToRetrieve: 0,
        clinicCodes: [code]
    };
    const res = await getProviders(request, false);
    if (res) {
        return res;
    }
    return;
};

export const getClinicProviders = async () => {
    const clinicCode = store.getState().user?.current?.clinicCode;
    const request: any = {
        typeToRetrieve: 0,
        clinicCodes: [clinicCode]
    };
    const res = await getProviders(request, false);
    if (res) {
        setClinicProviders(res);
    }
};

export const setLabels = (payload: any) => {
    store.dispatch({
        type: actionsTypes.SET_LABELS,
        payload
    });
};

export const setInternalMessageVisible = (payload: any) => {
    if (payload) {
        getClinicProviders();
    }
    store.dispatch({
        type: actionsTypes.SET_INTERNAL_MESSAGE_VISIBLE,
        payload
    });
};

export const setSecureMessageVisible = (payload: any) => {
    store.dispatch({
        type: actionsTypes.SET_SECURE_MESSAGE_VISIBLE,
        payload
    });
};

export const setMessageInternalParams = (payload: any) => {
    store.dispatch({
        type: actionsTypes.SET_INTERNAL,
        payload
    });
};

export const setInternalDefault = () => {
    store.dispatch({
        type: actionsTypes.SET_INTERNAL_DEFAULT
    });
};

export const setMessageSecureParams = (payload: any) => {
    store.dispatch({
        type: actionsTypes.SET_SECURE,
        payload
    });
};

export const setSecureDefault = () => {
    store.dispatch({
        type: actionsTypes.SET_SECURE_DEFAULT
    });
};

export const getPatientFormPDFPreview = async ({
    id,
    type
}: {
    id: number;
    type: 'legacy' | 'm1';
}): Promise<IFileSimpleDto | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            `/forms/${id}/preview/file`,
            {
                withCredentials: true,
                params: {
                    type
                }
            }
        );
        if (response.data) {
            if (response.data.success) {
                return response.data.result;
            }
        }
        return;
    } catch (e) {
        return;
    } finally {
    }
};

export const getTemplateFormPDFPreview = async ({
    id,
    type,
    patientId,
    providerId,
    clinicId
}: {
    id: number;
    type: 'legacy' | 'm1';
    patientId?: number | string;
    providerId?: number | string;
    clinicId?: number | string;
}): Promise<IFileSimpleDto | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            `/forms/templates/preview/file`,
            {
                withCredentials: true,
                params: {
                    id,
                    type,
                    patientId,
                    providerId,
                    clinicId
                }
            }
        );
        if (response.data) {
            if (response.data.success) {
                return response.data.result;
            }
        }
        return;
    } catch (e) {
        return;
    } finally {
    }
};

export const getLegacyPatientForms = async (
    patientId: number | string
): Promise<IFormItem[] | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            ApiUrl.GET_TEMPLATE_FORM_NEW,
            {
                withCredentials: true,
                params: {
                    patientId,
                    IsOnlyLatestForm: true
                }
            }
        );
        if (response.data.success) {
            const data: any = response.data.result.summary;
            const list: any = Object.values(data);
            const sorted = _.sortBy(
                list.filter((v: any) => v.isActive && v.status !== 'invalid'),
                'id'
            );
            return sorted;
        }
        return;
    } catch (error) {
        console.log({ error });
        return;
    } finally {
    }
};

import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { pickTextColorBasedOnBgColorSimple } from 'src/Framework/util/helps';
import { DefaultItemHeight } from '../ScheduleCol_Styles';
import { ICellSettings } from 'src/App/UserPreferences/store/types';
import { CalendarOpacityLevel } from 'src/Activities/Schedule/TopBar/FilterBar/OpacityLevel';
import { defaultApptBackgroundColor } from 'src/App/UserPreferences/store';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

interface ScheduleOneContainerProps {
  color: string;
  isBlock: boolean;
  isAppt: boolean;
  overflowing: any;
  uid: any;
  hasConflict: boolean;
  isCancelled?: boolean;
  firstInReservation?: boolean;
  lastInReservation?: boolean;
  inOverflow?: boolean;
  isDragHover?: boolean;
  iconsWithBackgroundColor?: string;
  height?: number;
  reservationSettings: ICellSettings['reservationSettings'];
  calendarOpacityLevel: CalendarOpacityLevel;
}

export const TitleContainer = styled.span`
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
`;

export const OverflowCountText = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

export const TimeBlock = styled.div`
  color: ${globalStyleVariables.brandGray};
  font-size: 11px;
  font-weight: 400;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export const ScheduleOneContainer = styled.div<ScheduleOneContainerProps>`
  width: 100%;
  position: relative;
  padding: ${({ reservationSettings, overflowing, firstInReservation }) =>
    overflowing
      ? '0px'
      : !reservationSettings?.overlap && firstInReservation
      ? '9px 0 0 6px'
      : '0px 6px'};
  color: ${(props) => {
    if (props.overflowing === 1) {
      return `${props.color}`;
    } else if (props.overflowing === 2) {
      return '#FF2828';
    } else {
      return '  ';
    }
  }};
  user-select: none;
  height: 100%;
  border-radius: 2px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: ${(props) => (props.overflowing ? 'center' : '')};
  justify-content: ${(props) => (props.overflowing ? 'center' : '')};
  border: ${({
    overflowing,
    isAppt,
    hasConflict,
    isDragHover,
    iconsWithBackgroundColor
  }) => {
    if (isDragHover) {
      return `2px solid #53C82E80`;
    }
    if (overflowing && !hasConflict) {
      return `2px solid ${iconsWithBackgroundColor}`;
    }
    if (overflowing === 1 && isAppt) {
      return 'unset';
    } else if (hasConflict) {
      return '2px dashed #FF2828';
    } else {
      return 'unset';
    }
  }};
  overflow: hidden;
  background-color: ${(props) => {
    const opacity = props.calendarOpacityLevel.value;
    if (props.isBlock) {
      return hexToRgba(
        props.color || defaultApptBackgroundColor,
        opacity ?? 0.2
      );
    } else if (props.color === '#f7f7f7' && !props.uid) {
      return '#f7f7f7';
    } else {
      let color = hexToRgba(
        props.color || defaultApptBackgroundColor,
        opacity ?? 0.2
      );
      return color;
    }
  }};
  ${({ isCancelled }) =>
    isCancelled &&
    `
    filter: grayscale(100%);
    border: 0.5px dashed grey;
    color: ${globalStyleVariables.brandGray};
  `}
  ${({ inOverflow }) =>
    inOverflow &&
    `
    margin: 0 0 2px 0;
  `}
  ${({ height, inOverflow, lastInReservation }) =>
    !inOverflow && height
      ? `height: ${
          height * DefaultItemHeight() - (lastInReservation ? 4 : 2)
        }px`
      : ''}
`;

// interface PatientNameContainerProps {
//   occupyPercent?: number;
//   inReservation?: boolean;
//   duration?: any;
//   regularMeetingTime?: number;
//   inSeries: boolean;
//   idx?: number
//   isCounseling?: boolean
// }

export const TitleNameContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

export const PatientNameContainer = styled.div`
  display: flex;
  font-family: Lato;
  font-style: normal;
  overflow: hidden;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .recurrSvg {
    svg {
      width: 15px;
      height: 18px;

      path {
        stroke: #666666;
      }

      circle {
        fill: transparent;
      }
    }
  }
`;

export const TextEllipsisSpan = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface DragSvgBlockProps {
  inSeries?: boolean;
}

export const DragSvgBlock = styled.div`
  margin-right: 0.5rem;
  margin-top: 3px;
  width: ${(props: DragSvgBlockProps) => (props.inSeries ? '30px' : '20px')};
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  &:hover {
    display: block;
  }
`;

export const ApptIconBlock = styled.div`
  margin-right: 0.5rem;
  margin-top: 3px;
`;

interface BlockTitleProps {
  color: any;
  duration?: any;
}

export const AbsolutePositionsIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const IconContainer = styled.div`
  line-height: 1;
  padding: 2px;
  border-radius: 3px;
`;

export const BlockTitle = styled.div<BlockTitleProps>`
  color: ${(props) => pickTextColorBasedOnBgColorSimple(props.color)};
  font-size: 12px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const TextIconContainer = styled.div`
  width: 16px;
  height: 20px;
  top: 1.45px;
  right: 30px;
  position: absolute;
`;

export const EditSeriesPopupContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;

  svg > circle {
    fill: none;
  }

  .disabled-menu-item {
    color: ${globalStyleVariables.brandGray};
    cursor: not-allowed;
  }
`;

export const ListItem = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: rgba(14, 109, 159, 0.15);
    border-radius: 5px;
  }
`;

export const PopupTextLine = styled.div`
  font-family: Lato;
  font-style: normal;
  line-height: 18px;
  height: 18px;
  font-size: 12px;
`;

export const PatientStatusMarkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  top: 2px;
  right: 8px;
  position: absolute;
`;

export const AppointmentReason = styled.span`
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
`;

export const NoteContainer = styled.span`
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
`;

export const TextContainer = styled.span`
  vertical-align: middle;
  padding: 0 2px;
  word-break: break-word;
  font-size: 12px;
  font-weight: bold;

  &:after {
    content: ' ';
  }
`;

export const IconBlock = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  padding: 0 1px;
`;

interface IRowContainer {
  height: number;
  reservationSettings: ICellSettings['reservationSettings'];
}

export const RowContainer = styled.span<IRowContainer>`
  span {
    line-height: ${({ height, reservationSettings }) =>
      `${reservationSettings?.overlap ? height : height - 10}px;`};
  }
`;

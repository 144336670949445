import React, { FC } from 'react';
import { usePatients } from '../Hooks/usePatient';
import { patientDisplayName } from 'src/Framework/util/format';

interface IProps {
  patientId: number;
}

const PatientName: FC<IProps> = ({ patientId }: IProps) => {
  const patients = usePatients();
  const patient = patients.getPatient(patientId);
  return <>{patientDisplayName(patient)}</>;
};

export default React.memo(PatientName);

import React, { FC, useRef, useState } from 'react';
import { Row, Col } from 'antd';

import Radio from 'src/Framework/Controls/Radio';
import Tooltip from 'src/Framework/Controls/Tooltip';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { debouncer } from 'src/Framework/util/helps';

import { IAppointmentReasonFormSimpleDto } from './types';

import { Label } from './styled';

interface IProps {
  value: IAppointmentReasonFormSimpleDto;
  onChangeForm: (
    form: IAppointmentReasonFormSimpleDto,
    value: Partial<IAppointmentReasonFormSimpleDto>
  ) => any;
  onChangeDataItem: (
    form: IAppointmentReasonFormSimpleDto,
    value: Partial<IAppointmentReasonFormSimpleDto>
  ) => any;
}

const Component: FC<IProps> = ({
  value,
  onChangeDataItem,
  onChangeForm
}: IProps) => {
  const debounce = useRef(debouncer(1000));
  const [loading, setLoading] = useState(false);
  const [frequency, setFrequency] = useState(value.frequency);
  const onChange = (frequency: number) => {
    setFrequency(frequency);
    onChangeDataItem(value, { frequency });
    debounce.current(async () => {
      setLoading(true);
      await onChangeForm(value, { frequency });
      setLoading(false);
    });
  };
  return (
    <Row align="middle" gutter={4} style={{ position: 'relative' }}>
      {loading && <AbsoluteLoader />}
      <Col>
        <Tooltip title="Show the selected form only in the first appointment occurrence">
          <Radio
            id={'frequency-once' + value.templateFormSeedId}
            name={'frequency' + value.templateFormSeedId}
            checked={+value.frequency < 0}
            onClick={() => onChange(-1)}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <span className="visually-hidden">Frequency:</span>
            show once
          </Radio>
        </Tooltip>
      </Col>
      <Col>
        <Tooltip title="Show the selected form in every appointment occurrence">
          <Radio
            id={'frequency-every' + value.templateFormSeedId}
            name={'frequency' + value.templateFormSeedId}
            checked={+value.frequency === 1}
            onClick={() => onChange(1)}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <span className="visually-hidden">Frequency: show</span>
            every appt
          </Radio>
        </Tooltip>
      </Col>
      <Col>
        <Row align="middle" gutter={4}>
          <Col>
            <Tooltip title="Show the selected form in every nth appointment occurrence">
              <Radio
                onKeyDown={(e) => e.stopPropagation()}
                id={'frequency-number' + value.templateFormSeedId}
                name={'frequency' + value.templateFormSeedId}
                checked={+value.frequency > 1}
                onClick={() => onChange(2)}
              >
                <span className="visually-hidden">Frequency: show</span>
                every
                <span className="visually-hidden">{value.frequency} appt</span>
              </Radio>
            </Tooltip>
          </Col>
          {+value.frequency > 1 && (
            <>
              <Col>
                <SelectContainer
                  label=""
                  type="number"
                  value={frequency}
                  inputProps={{
                    style: { width: 72 },
                    min: 2,
                    onBlur: () => {
                      onChange(frequency);
                    }
                  }}
                  bottomMargin={false}
                  hideLabel={true}
                  onChange={(frequency) => {
                    setFrequency(frequency);
                  }}
                />
              </Col>
              <Col>
                <Label>appt</Label>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default Component;

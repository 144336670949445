
import React from "react";

interface IProps {
    stroke?: string;
}

export const TextIconSvg = ({ stroke }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="-1 5 13 25" fill="none">
        <g>
            <path d="M3 14.2H7.8" stroke="#666666" strokeLinecap="round" strokeLinejoin="round">
            </path>
            <path d="M3 17.8H9" stroke="#666666" strokeLinecap="round" strokeLinejoin="round">
            </path>
            <path d="M11.4 22.6H0.6V9.39999H7.8L11.4 13V22.6Z" stroke="#666666" strokeLinecap="round" strokeLinejoin="round">
            </path>
        </g>
    </svg>
);
// -146

import { APIHandler } from "src/Framework/Communication/ServerProxy";
import { ApiUrl } from "src/Framework/Common/ApiUrl"
import * as Setters from './Setters';
import { setServerTimeOffset } from "../../utils";

export const get = async (): Promise<Record<string, any> | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.SETTINGS, { withCredentials: true })
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result
                Setters.summary(data)
                return data
            }
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}

export const getServerTime = async (): Promise<string | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(ApiUrl.TenantTime, { withCredentials: true })
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result
                setServerTimeOffset(data)
                return data
            }
        }
        return
    } catch (e) {
        console.log({ e })
        return
    } finally {
    }
}
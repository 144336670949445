import React, { FC, useEffect, useRef, useState } from 'react';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

import {
  getDocumentLink,
  catchDocumentUrls
} from 'src/Framework/Controls/FilesDownloader';

import { Container, ErrorContainer } from './styled';
import _ from 'lodash';

interface IProps {
  file: {
    id: number;
    crc?: string;
  };
  height?: number | string;
  width?: number | string;
  circle?: boolean;
}

const documentLoading: Record<string, boolean> = {};

const Component: FC<IProps> = ({ file, height, width, circle }: IProps) => {
  const fileUniqueId = `${file.id}-${file.crc}`;
  const ref = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [url, setUrl] = useState(catchDocumentUrls[fileUniqueId]);
  const getImage = async () => {
    if (documentLoading[fileUniqueId]) {
      const checkData = () => {
        setTimeout(() => {
          if (ref.current) {
            if (catchDocumentUrls[fileUniqueId]) {
              setUrl(catchDocumentUrls[fileUniqueId]);
            } else {
              checkData();
            }
          }
        }, 150);
      };
      checkData();
      return;
    }
    setLoading(true);
    setError(false);
    documentLoading[fileUniqueId] = true;
    const res = await getDocumentLink(file);
    if (res) {
      setUrl(res);
    } else {
      setError(true);
    }
    documentLoading[fileUniqueId] = false;
    setLoading(false);
  };
  useEffect(() => {
    if (!url) {
      getImage();
    }
  }, []);
  useEffect(() => {
    getImage();
  }, [`${file?.id}-${file?.crc}`]);
  return (
    <Container
      ref={ref}
      style={{
        width,
        height,
        borderRadius: circle ? '50%' : 2
      }}
    >
      {loading && <AbsoluteLoader />}
      {error && (
        <ErrorContainer>
          <span>
            Loading error <b onClick={getImage}>try again</b>
          </span>
        </ErrorContainer>
      )}
      {url ? (
        <div
          style={{
            width,
            height,
            backgroundImage: `url(${url})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
          }}
        />
      ) : null}
    </Container>
  );
};

Component.defaultProps = {
  height: 260,
  width: 425
};

export default React.memo(Component, (prev, next) => _.isEqual(prev, next));

import React, { useImperativeHandle, useState } from 'react';

import Modal from 'src/Framework/Controls/Modal';
import Button from 'src/Framework/Controls/Button';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import { ModalContent } from 'src/Activities/Subject/AgeModal/styled';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

interface IProps {}

export interface IModalHandles {
  show(): void;

  close(): void;
}

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  {},
  ref
) => {
  const [visible, setVisible] = useState(false);
  useModalFocus({ active: visible });
  const show = () => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show,
    close
  }));

  const apply = async () => {
    close();
  };

  const modalTitlePatient = () => {
    return (
      <Button
        color={globalStyleVariables.newBrandingPrimary200}
        style={{ width: 64 }}
        colorStyles={{
          blueBorder: true,
          boldFont: true
        }}
        id="age-modal-ok-button"
        onClick={() => {
          apply();
        }}
      >
        Ok
      </Button>
    );
  };

  return (
    <Modal
      onClose={() => {}}
      title={
        <ModalContent tabIndex={0} style={{ padding: 0 }}>
          {getPatientTerm('Patient is under 18')}
        </ModalContent>
      }
      width={400}
      visible={visible}
      headerBorder={true}
      modalProps={{
        closable: false,
        wrapClassName: 'alert-modal',
        destroyOnClose: true,
        centered: true
      }}
    >
      <ModalContent>{modalTitlePatient()}</ModalContent>
    </Modal>
  );
};

export default React.forwardRef(Component);

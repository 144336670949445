import React, { FC, useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Cache } from 'src/Framework/CatchStateData';

import { FormListActionBar } from 'src/Activities/Forms/LegacyForm/FormListActionBar';
import FormioForm from 'src/App/FormBuilder/FormioForm';
import A11yAutofocusContainer from 'src/App/Accessibility/Components/A11yAutofocusContainer';

import FormHeader, { ISendData } from './FormHeader';
import { ILegacyForm } from '../Forms';

import { SelectedForm } from '../Form_Styles';
import { InnerContainer } from './styled';

import Preview from './Preview';
import { IGetFormValidation } from 'src/App/FormBuilder/FormContainer';

interface IProps {
  formSelected: ILegacyForm;
  patientId: string;
  match?: any;
  onRemove: () => any;
  onClickSave: (value: ILegacyForm) => any;
  onChangeFormSubmission: (value: any) => any;
  onClickSend: (data: ISendData, form: any) => Promise<any>;
  getFormValidation?: IGetFormValidation;
  readOnly?: boolean;
  hideInFormHeader?: boolean;
  hideRemoveButton?: boolean;
}

const Component: FC<IProps> = ({
  formSelected,
  patientId,
  match,
  onRemove,
  onClickSave,
  onChangeFormSubmission,
  onClickSend,
  getFormValidation,
  readOnly,
  hideInFormHeader,
  hideRemoveButton
}: IProps) => {
  // For rerender
  const [key, setKey] = useState(uuidv4());
  const [onPreview, setOnPreview] = useState<boolean>(() => {
    if (match) {
      const res = Cache.get(match.url);
      if (res?.state) {
        return res.state.onPreview;
      }
    }
    return false;
  });
  const [isValid, setIsValid] = useState(() => {
    if (match) {
      const res = Cache.get(match.url);
      if (res?.state) {
        return res.state.isValid;
      }
    }
    return true;
  });
  useEffect(() => {
    if (match) {
      Cache.set(match.url, {
        state: {
          onPreview,
          isValid
        }
      });
    }
  });
  const onAppendAdded = useCallback(() => {
    setKey(uuidv4());
  }, []);
  const handleShowHidePreview = useCallback(() => {
    setOnPreview((value) => !value);
  }, []);
  return (
    <A11yAutofocusContainer style={{ position: 'relative' }}>
      {!hideInFormHeader && (
        <FormHeader
          form={formSelected}
          onClickSend={onClickSend}
          onClickSave={onClickSave}
        />
      )}
      <FormListActionBar
        onClickShowPreview={handleShowHidePreview}
        formSelected={formSelected}
        patientId={patientId}
        onRemove={onRemove}
        onClickSave={onClickSave}
        preview={onPreview}
        isNotValid={!isValid}
        onAppendAdded={onAppendAdded}
        hideRemoveButton={hideRemoveButton}
      />
      <SelectedForm id="selectedForm">
        <InnerContainer className="absolute-container">
          {formSelected.status === 'invalid' ||
          formSelected.status === 'locked' ||
          onPreview ? (
            <Preview key={key} seedId={formSelected.seedId} />
          ) : (
            <FormioForm
              key={formSelected.status}
              seedId={formSelected.seedId}
              readOnly={readOnly || formSelected.status === 'signed'}
              onChangeSubmission={(
                submission,
                isValid,
                value,
                formReadyTrigger,
                validator
              ) => {
                if (getFormValidation && validator) {
                  getFormValidation(validator);
                }
                onChangeFormSubmission(submission);
                if (value) {
                  setIsValid(value.length === 0);
                }
              }}
            />
          )}
        </InnerContainer>
      </SelectedForm>
    </A11yAutofocusContainer>
  );
};

export default React.memo(Component);

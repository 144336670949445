import React, { FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';

import Table from 'src/Framework/Controls/Table';
import {
  ColumnGlobalTitle,
  ColumnIdentifier,
  IColumn,
  ISort
} from 'src/Framework/Controls/Table/types';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { formattedDateTime } from 'src/Framework/Shared/Shared';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { patientDisplayName } from 'src/Framework/util/format';
import store, { IStore } from 'src/store';

import { loadActivityTab } from 'src/App/ActivityPanel/Store/ActivityActions';
import { isRiskModuleEnabled } from 'src/App/Settings/utils';
import { ActivityType } from 'src/App/ActivityPanel/Models/Activity';
import TypeRisks from 'src/Activities/Home/components/TypesRisks';
import { IDashboardPatientDto } from '../store/types';
import * as dashboardActions from '../store/Actions';

import { Container } from './styled';

interface IOwnProps {}

interface IProps extends IOwnProps {
  studentsOnHighRisk: IDashboardPatientDto[];
  typeRisks: IStore['typeRisks']['summary'];
  providersIds: number[];
  clinicId: number | null;
  sort: ISort;
}

const Component: FC<IProps> = ({
  providersIds,
  studentsOnHighRisk,
  typeRisks,
  clinicId,
  sort
}: IProps) => {
  const columns = useMemo(() => {
    const columns: (IColumn & { hide?: boolean })[] = [
      {
        title: ColumnGlobalTitle.accountNumber,
        key: 'patientId',
        sortBy: 'patientId',
        flex: '80px',
        identifier: ColumnIdentifier.patientId
      },
      {
        title: getPatientTerm('Subject'),
        key: 'fullName',
        sortBy: 'lastName',
        flex: 'auto',
        ellipsis: true
      },
      {
        title: 'AR',
        key: 'ar',
        sortBy: 'ar',
        flex: '120px',
        hide: !isRiskModuleEnabled()
      },
      {
        title: 'SI',
        key: 'si',
        sortBy: 'si',
        flex: '120px',
        hide: !isRiskModuleEnabled()
      },
      {
        title: 'HI',
        key: 'hi',
        sortBy: 'hi',
        flex: '120px',
        hide: !isRiskModuleEnabled()
      },
      {
        title: 'Last Appointment',
        key: 'lastAppt',
        sortBy: 'lastApptString',
        flex: '180px'
      },
      {
        title: '',
        key: 'edit',
        flex: '80px',
        centered: true
      }
    ];
    return columns.filter((v) => (v.hide === true ? false : true));
  }, []);
  return (
    <Container>
      <Table
        infinity={true}
        absoluteContainer={true}
        sort={sort}
        onChangeSort={(value) =>
          dashboardActions.setSort('studentsOnHighRisk', value)
        }
        data={studentsOnHighRisk.map((value) => {
          const patientName = patientDisplayName(value.patient);
          return {
            patientId: value.patient?.id,
            patientName,
            lastName: value.patient.lastName,
            fullName: (
              <strong style={{ marginRight: '5px' }}>{patientName}</strong>
            ),
            ar: <TypeRisks data={value.typeRiskIds} riskType="academic" />,
            si: <TypeRisks data={value.typeRiskIds} riskType="suicide" />,
            hi: <TypeRisks data={value.typeRiskIds} riskType="homicide" />,
            lastAppt: (
              <Tooltip
                //@ts-ignore
                title={
                  value.latestProviderAppointment !== null &&
                  value.latestProviderAppointment.admitTime !== null &&
                  value.latestProviderAppointment.appointmentReason.name !==
                    null
                    ? value.latestProviderAppointment.appointmentReason.name
                    : ''
                }
                trigger="hover"
              >
                <div>
                  {value.latestProviderAppointment !== null &&
                  value.latestProviderAppointment.admitTime !== null
                    ? formattedDateTime(
                        value.latestProviderAppointment.admitTime
                      )
                    : ''}
                </div>
              </Tooltip>
            ),
            lastApptString: value.latestProviderAppointment?.admitTime
              ? new Date(value.latestProviderAppointment?.admitTime).getTime()
              : '',
            edit: (
              <ButtonIcon
                isButton={true}
                label="Go to Chart"
                tooltip="Go to Chart"
                name={ICONS_LIST.folderLink}
                onClick={() => {
                  loadActivityTab(
                    `/client/${value.patient.id}/counseling/dashboard`,
                    store.dispatch,
                    {
                      activityType: ActivityType.Client
                    }
                  );
                }}
              />
            )
          };
        })}
        columns={columns}
      />
    </Container>
  );
};

const mapStateToProps = (state: IStore) => {
  const { riskManagement, sort } = state.dashboard;
  return {
    studentsOnHighRisk: riskManagement.studentsOnHighRisk,
    typeRisks: state.typeRisks.summary,
    sort: sort.studentsOnHighRisk,
    providersIds: riskManagement.filters.providersIds,
    clinicId: riskManagement.filters.clinicId
  };
};
export default connect(mapStateToProps)(Component);

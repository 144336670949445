import React from 'react';
import { notification, Button } from 'antd';
import cn from 'classnames';

import store from 'src/store';

import { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import { IAlert, IToastConfig } from './store/types';
import { formattedDate } from '../../../../Framework/Shared/Shared';
import { patientDisplayName } from 'src/Framework/util/format';

export const ALERT_ICONS: {
  [patientAlertTypeId: string]: {
    url: string;
    color: string;
  };
} = {
  1: {
    url: require('src/Framework/Common/Svg/empty-calendar.svg').default,
    color: '#53C82E'
  },
  2: {
    url: require('src/Framework/Common/Svg/settings2.svg').default,
    color: '#F9B40B'
  },
  3: {
    url: require('src/Framework/Common/Svg/error.svg').default,
    color: '#FF2828'
  }
};

interface IValue extends IAlert {
  userName: string;
}

interface ICallAlert {
  key?: string;
  message: string;
  duration?: number;
  closeIcon?: boolean;
  btn?: React.ReactNode;
  icon: {
    color: string;
    url: string;
  };
  backgroundColor: string;
  description: {
    right: string;
    left: string;
  };
}

export const callAlert = ({
  key,
  message,
  duration,
  closeIcon,
  btn,
  backgroundColor,
  icon,
  description
}: ICallAlert) => {
  notification.open({
    key,
    message: <div className="alert-description-container">{message}</div>,
    duration,
    className: cn({
      'custom-notification': true
    }),
    closeIcon: closeIcon ? (
      <div
        className="close-icon"
        style={{
          WebkitMaskImage: `url(${ICONS_LIST.thinClose})`,
          maskImage: `url(${ICONS_LIST.thinClose})`
        }}
      />
    ) : (
      <div />
    ),
    description: (
      <div className="description-container">
        <span>{description.left}</span>
        <span>{description.right}</span>
      </div>
    ),
    btn,
    icon: (
      <div
        className="left-icon-container"
        style={{
          background: backgroundColor,
          opacity: 1
        }}
      >
        <div
          className="alert-icon-container"
          style={{
            background: icon.color,
            WebkitMaskImage: `url(${icon.url})`,
            maskImage: `url(${icon.url})`
          }}
        />
      </div>
    )
  });
};

export const openAlert = (value: IValue) => {
  const patientAlertTypes = store.getState().patientsAlerts.patientAlertTypes;
  const patientAlertType = patientAlertTypes.find(
    (v) => v.id === value.patientAlertTypeId
  );
  if (!patientAlertType) {
    return;
  }
  const style = patientAlertType ? JSON.parse(patientAlertType?.style) : {};
  const toastConfig: Partial<IToastConfig> = JSON.parse(value.toastConfig);
  const key = `open_${value.id}`;
  const btn = toastConfig.alertStyle?.sticky ? (
    <Button type="primary" size="small" onClick={() => notification.close(key)}>
      Confirm
    </Button>
  ) : null;
  const icon = ALERT_ICONS[patientAlertType.id];
  notification.open({
    key,
    message: (
      <div className="alert-description-container">{value.description}</div>
    ),
    duration: toastConfig.alertStyle?.sticky
      ? 0
      : toastConfig.alertStyle?.dismissAfter || 5,
    className: cn({
      'custom-notification': true
    }),
    closeIcon:
      toastConfig.allowToDismiss && !toastConfig.alertStyle?.sticky ? (
        <div
          className="close-icon"
          style={{
            WebkitMaskImage: `url(${ICONS_LIST.thinClose})`,
            maskImage: `url(${ICONS_LIST.thinClose})`
          }}
        />
      ) : (
        <div />
      ),
    description: (
      <div className="description-container">
        <span style={{ width: 175 }}>
          {`${formattedDate(value.startDateTime)} - 
          ${formattedDate(value.endDateTime)}`}
        </span>
        <span
          style={{
            textAlign: 'end'
          }}
        >
          {`by ${value.userName} to ${patientDisplayName(value.patient)}`}
        </span>
      </div>
    ),
    btn,
    icon: (
      <div
        className="left-icon-container"
        style={{
          background: style.color,
          opacity: 1
        }}
      >
        <div
          className="alert-icon-container"
          style={{
            background: icon.color,
            WebkitMaskImage: `url(${icon.url})`,
            maskImage: `url(${icon.url})`
          }}
        />
      </div>
    )
  });
};

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

const font = `
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #666666;
`;

export const Label = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: ${globalStyleVariables.brandGray};
  margin: 0 5px 0 0;
`;

export const Value = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: ${globalStyleVariables.brandGray};
  margin: 0 10px 0 0;
`;

export const Container = styled.div`
  display: flex;
  border-bottom: 1px solid #e6e6e6;
  padding: 15px 15px 10px 20px;
  background-color: white;

  .task-icon-container {
    height: 16px;
    width: 16px;
    margin: 0 10px 0 0;

    .task-icon {
      height: 16px;
      width: 16px;
      mask-size: contain;
      mask-position: center center;
      mask-repeat: no-repeat no-repeat;
    }
  }

  .right-item {
    padding: 0 0 0 16px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const Name = styled.div`
  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
`;

export const Date = styled.div`
  ${font}
`;

export const Description = styled.div`
  margin: 5px 0 0 0;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #333333;
`;

import React, { FC } from 'react';
import { Tooltip } from 'react-tippy';

import Button from 'src/Framework/Controls/Button';
import { getProviderByCode } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { ConflictsSvg } from 'src/Framework/Common/Svg/ConflictsSvg';
import { EyeIconSnapShotSvg } from 'src/Framework/Common/Svg/EyeIconSnapShotSvg';
import ConflictsList from 'src/Activities/Schedule/ConflictsBar/ConflictsList';

import {
  ErrorBlock,
  EyeIconContainer
} from 'src/Activities/Schedule/Popups/BlockReservationPopup/BlockReservationPopup_Styles';

import test_ids from 'src/tests-script/pages/Schedule/CreateReservationOrBlock';

interface IProps {
  conflicts: {
    conflictResponse: any[];
    hasConflicts: boolean;
    message: string;
  };
  isSingle: boolean;
  onClickOverride: () => any;
  providers: any;
  clinicCode: any;
  clinicOptions: any;
  providerOptions: any;
}

export const ConflictErrorBlock: FC<IProps> = React.memo(
  ({ conflicts, onClickOverride, isSingle }: IProps) => {
    if (conflicts?.conflictResponse.length > 0) {
      let conflictsData;
      if (isSingle) {
        conflictsData = conflicts?.conflictResponse.map((item) => {
          const provider = getProviderByCode(item.providerCode[0]);
          return {
            startDate: item.scheduleEventDateTime,
            endDate: item.endTime,
            providerName: provider
              ? provider.firstName +
                (provider.lastName ? ' ' + provider.lastName : '')
              : ''
          };
        });
      } else {
        conflictsData = conflicts?.conflictResponse
          .reduce((acc: any[], item) => {
            return acc.concat(item.conflicts);
          }, [])
          .map((item) => {
            const provider = getProviderByCode(item.providerCode);
            return {
              startDate: item.scheduleEventDateTime,
              endDate: item.endTime,
              providerName: provider
                ? provider.firstName +
                  (provider.lastName ? ' ' + provider.lastName : '')
                : ''
            };
          });
      }

      let firstEvent = conflicts.conflictResponse[0];
      return (
        <ErrorBlock
          id={test_ids.conflictsContainer}
          style={{
            marginLeft: '3rem',
            color: '#FF2828',
            background: 'rgb(255, 40, 40, 0.1)',
            borderRadius: '6px',
            padding: '0.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative'
          }}
          error={conflicts.hasConflicts}
        >
          <span
            style={{
              paddingRight: '1rem',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <ConflictsSvg />
            <span style={{ marginLeft: '1rem', width: '100%' }}>
              {conflicts.message}
              {` (${conflictsData.length})`}
            </span>
          </span>
          <Tooltip
            title="calendar-snap"
            interactive
            trigger={'click'}
            position={'left'}
            delay={500}
            html={
              <div
                onMouseDown={(e: any) => e.preventDefault()}
                style={{
                  width: 576,
                  height:
                    conflictsData.length < 6 ? conflictsData.length * 48 : 304
                }}
              >
                <ConflictsList data={conflictsData} />
              </div>
            }
            arrow
            style={{
              display: 'flex',
              cursor: 'pointer',
              borderRadius: '50%',
              marginRight: '1rem',
              minWidth: '30px',
              maxWidth: '800px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            popperOptions={{
              modifiers: {
                preventOverflow: {
                  enabled: false
                },
                flip: {
                  enabled: false
                }
              }
            }}
            unmountHTMLWhenHide={true}
            theme="light"
          >
            <EyeIconContainer>
              <EyeIconSnapShotSvg />
            </EyeIconContainer>
          </Tooltip>
          {firstEvent.allowOverride && (
            <Button
              id={test_ids.overrideButton}
              onClick={onClickOverride}
              colorStyles={{ redBorder: true }}
            >
              Override
            </Button>
          )}
        </ErrorBlock>
      );
    }
    return <div></div>;
  }
);

import { useAppSelector } from "src/store";
import * as actions from 'src/App/TemplateForms/store/Actions';
import { useEffect, useState } from "react";

export const useLegacyTemplateForm = () => {
    const [loading, setLoading] = useState(false)
    const data = useAppSelector((state) => state.templateForms.summary);
    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await actions.get();
            setLoading(false);
        };
        init();
    }, []);
    return {
        data,
        loading
    }
}
import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { IPatientInfo } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import { patientDisplayName } from 'src/Framework/util/format';

import { DPContainer, HighRiskContainer } from './styled';

interface IProps {
  patient: IPatientInfo;
}

const Component: FC<IProps> = ({ patient }: IProps) => {
  return (
    <Row align="middle" gutter={8} style={{ flexWrap: 'nowrap' }}>
      <Col flex={'26px'}>
        <DPContainer>
          {patient.firstName && patient.firstName[0]}
          {patient.lastName && patient.lastName[0]}
        </DPContainer>
      </Col>
      <Col>
        <EllipsisText>{patientDisplayName(patient)}</EllipsisText>
      </Col>
      {patient.highRisk && (
        <Col>
          <HighRiskContainer>High Risk</HighRiskContainer>
        </Col>
      )}
    </Row>
  );
};

export default React.memo(Component);

import globalStyleVariables from "src/Framework/Styles/variables.module.scss";
import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    padding: 8px 16px;
    border-radius: 50px;
    overflow: hidden;
    width: 250px;
`

export const Background = styled.div`
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    background-color: ${globalStyleVariables.blue};
    z-index: 0;
    opacity: 0.1;
`
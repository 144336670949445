import { BucketsList } from "src/Framework/ApiSubscription"
import { RequestHelper } from "src/Framework/ApiSubscription/RequestHelper"
import { ApiUrl } from "src/Framework/Common/ApiUrl"
import { ITypeCheckInStatus } from "../types"

import * as Setters from './Setters'

export const SubscriptionData = () => {
    return ({
        bucket: BucketsList.typeCheckInStatus,
        filter: 'true'
    })
}

export const helper = new RequestHelper<ITypeCheckInStatus>({
    link: ApiUrl.TypeCheckInStatus,
    onCreateUpdateItem: Setters.onChangeItem,
    subscription: {
        subscriptionData: SubscriptionData,
        setSummary: Setters.setSummary
    }
})

export const { get, generateSubscriptionPath } = helper

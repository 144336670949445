import React from 'react';
import Highlighter from 'react-highlight-words';

export const HIGHLIGHT_SUFFIX = `_HIGHLIGHT`;

type SystemHightlightKey<T> = `${keyof T & string}${typeof HIGHLIGHT_SUFFIX}`;
type GKey<T> = keyof T;

export const searchHighlighter = <
  T extends {
    [key: string]: string;
  }
>({
  data,
  search
}: {
  data: T;
  search: string;
}) => {
  const newData: {
    [key in SystemHightlightKey<T> | GKey<T>]?: React.ReactElement | string;
  } = {};

  Object.keys(data).forEach((key: string) => {
    const highlightedKey: SystemHightlightKey<T> = (key +
      HIGHLIGHT_SUFFIX) as any;
    newData[highlightedKey] = (
      <Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={search.split(' ')}
        autoEscape={true}
        textToHighlight={data[key] ? data[key] : ''}
      />
    );
    newData[key as GKey<T>] = data[key];
  });
  return newData;
};

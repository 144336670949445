
export interface IStateReducer {
    summary: Record<string, ITicketDetailHipaa>
}

export interface IAction {
    type: string;
    payload: any;
}

export interface ITicketDetailHipaa {
    id: number
    patientId: number
    renderingProviderId: null | number
    dosFrom: null | string
    action: TicketDetailHippaAction
}

export enum TicketDetailHippaAction {
    Revoked = 0,
    Read = 1,
    Accept = 2,
    Decline = 3
}
import React, { FC, useEffect, useState, useMemo } from 'react';
import { Col, Row } from 'antd';

import EllipsisText from '../EllipsisText';
import ButtonIcon, { ICONS_LIST } from '../ButtonIcon';
import SelectContainer, { ISelect } from '../SelectContainer';
import { useAppSelector } from 'src/store';

import * as m1Actions from 'src/App/Admin/Pages/FormBuilder/Lists/Forms/store/Actions';

import { Container } from './styled';
import { ITemplateForm } from 'src/App/TemplateForms/store/types';
import { IBuildingForm } from 'src/App/Admin/Pages/FormBuilder/Lists/Forms/store/types';
import { useLegacyTemplateForm } from './Hooks/useLegacyTemplateForm';

export const Type = {
  m1: 'm1',
  legacy: 'legacy'
} as const;

export interface IDTOItem {
  legacy?: ITemplateForm;
  m1?: IBuildingForm;
}

interface IProps extends Omit<ISelect, 'type'> {
  onChange: (
    value: number | null,
    type: 'm1' | 'legacy' | null,
    dto: IDTOItem | null
  ) => any;
  valueM1: number | null | undefined;
  valueLegacy: number | null | undefined;
  filter?: (item: IDTOItem) => boolean;
  hideM1Forms?: boolean;
  hideLegacyForms?: boolean;
}

const Component: FC<IProps> = (props: IProps) => {
  const { data: summary, loading: legacyLoading } = useLegacyTemplateForm();
  const { valueM1, valueLegacy, onChange, hideM1Forms, hideLegacyForms } =
    props;
  const m1Forms = useAppSelector(
    (state) => state.adminPanel.formBuilder.FormsReducer.summary
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await m1Actions.get();
      setLoading(false);
    };
    init();
  }, []);

  const allOptions = useMemo(() => {
    const getLegacyForms = () => {
      return Object.values(summary).map((v) => ({
        label: (
          <Row align="middle" wrap={false}>
            <Col flex={1}>
              <EllipsisText>{v.name || ''}</EllipsisText>
            </Col>
            <Col>
              <ButtonIcon
                disableClick={true}
                name={ICONS_LIST.letterL}
                size={18}
              />
            </Col>
          </Row>
        ),
        value: `${Type.legacy}-${v.seedId}`,
        isActive: v.isActive,
        optionSearch: v.name || '',
        dto: {
          legacy: v
        }
      }));
    };
    const getM1Forms = () => {
      return Object.values(m1Forms).map((v) => ({
        label: v.name || '',
        value: `${Type.m1}-${v.id}`,
        isActive: v.isActive && v.latestPublishedVersion ? true : false,
        optionSearch: v.name || '',
        dto: {
          m1: v
        }
      }));
    };
    let allForms: (
      | ReturnType<typeof getLegacyForms>[number]
      | ReturnType<typeof getM1Forms>[number]
    )[] = [];
    if (!hideLegacyForms) {
      allForms = allForms.concat(getLegacyForms());
    }
    if (!hideM1Forms) {
      allForms = allForms.concat(getM1Forms());
    }
    allForms.sort((a, b) => a.optionSearch.localeCompare(b.optionSearch));

    if (props.filter) {
      allForms = allForms.filter(
        (item) => props.filter && props.filter(item.dto)
      );
    }
    return allForms;
  }, [summary, m1Forms, props.filter, hideM1Forms, hideLegacyForms]);

  return (
    <Container>
      <SelectContainer
        loaderProps={{
          paddingTop: 20
        }}
        {...props}
        type="select"
        loading={loading || legacyLoading}
        value={
          valueM1
            ? `${Type.m1}-${valueM1}`
            : valueLegacy
            ? `${Type.legacy}-${valueLegacy}`
            : null
        }
        allOptions={allOptions}
        options={allOptions.filter((v) => v.isActive)}
        onChange={(value: string) => {
          if (value) {
            const currentValue = allOptions.find((v) => v.value === value);
            if (currentValue) {
              const arr = value.split('-');
              const val = arr[1] ? +arr[1] : null;
              if (val) {
                if (arr[0] === Type.m1) {
                  onChange(val, Type.m1, currentValue.dto);
                }
                if (arr[0] === Type.legacy) {
                  onChange(val, Type.legacy, currentValue.dto);
                }
                return;
              }
            }
          }
          onChange(null, null, null);
        }}
      />
    </Container>
  );
};

export default React.memo(Component);

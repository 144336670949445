import styled from 'styled-components';
import {
  input_disabled_text_color,
  input_normal_bg_color,
  white_color,
  input_placeholder_text_color,
  input_active_text_color,
  gray_background_color,
  inactive_text
} from 'src/CommonStyles/styles';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const Footer = styled.div`
  padding: 20px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ModalBody = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 450px;
`;

export const ReferralResults = styled.div`
  display: flex;
  width: 100%;
  background-color: ${input_normal_bg_color};
  flex-direction: column;
`;

interface IResultsContainer {
  sliderMode?: boolean;
}

export const ResultsContainer = styled.div<IResultsContainer>`
  margin: 8px;
  display: flex;
  flex-direction: column;
  height: ${({ sliderMode }) => (sliderMode ? '100%' : '450px')};
  overflow-y: auto;
`;

export const ResultContent = styled.div`
  margin: 16px 12px 8px 12px;
  padding: 16px;
  display: flex;
  height: min-content;
  background-color: ${white_color};
  flex-direction: column;
`;

export const ResultComments = styled.div`
  margin: 0 0 16px 0;
  font-size: 12px;
  text-align: justify;
  white-space: pre-line;
`;

export const DataSection: any = styled.div`
  padding: 16px 0;
  background-color: ${white_color};
  display: flex;
  ${(props: { divider?: boolean }) =>
    props.divider === false
      ? ''
      : `border-bottom: 1px solid ${input_disabled_text_color};`}
`;

export const Label = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${input_placeholder_text_color};
`;

export const DataContainer: any = styled.div`
  margin: 0 20px 0 0;
  ${(props: { width?: string }) =>
    props.width ? `width: ${props.width};` : ''};
`;

export const Data = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-wrap;
  color: ${input_active_text_color};
`;

export const ModalTitle: any = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 500px;
  gap: 16px;
  justify-content: space-between;
`;

export const SubHeader: any = styled.div`
  font-size: 12px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
`;

export const TitleHeader: any = styled.div`
  margin-bottom: 4px;
`;
export const IconsContainer = styled.div`
  margin-right: 10px;
  width: 30%;
  display: flex;
  gap: 24px;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const CreateResultHeader = styled.div`
  margin: auto 20px;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  margin: 0 8px;

  svg > circle {
    stroke: ${gray_background_color};
    fill: ${gray_background_color};
  }
`;

export const DividingHeaderContainer = styled.div`
  font-size: 12px;
  color: ${inactive_text};

  .title {
    float: left;
    margin: 0;
    padding: 0;
    padding-right: 16px;
  }

  .sep {
    overflow: hidden;
  }

  .line {
    height: 1px;
    display: inline-block;
    background: rgba(0, 0, 0, 0.1);
    vertical-align: middle;
    width: 100%;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 6px;
  margin-bottom: 18px;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0 20px;
`;

export const ActivityFlagsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column: any = styled.div`
  display: flex;
  flex-direction: column;
  ${(props: { width?: string }) =>
    props.width ? `width: ${props.width};` : ''};
`;

export const NoteColumn: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const InlineData = styled.span`
  color: ${globalStyleVariables.brandGray};
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;

import * as signalR from '@microsoft/signalr';
import { authV2Refresh } from 'src/App/LoginPage/store/Actions';
import Logger from '../Logger';
import { parseJwt } from '../util/helps';
import * as Notification from 'src/Framework/Communication/Notification'

import { IListenerCallback, IResponse, OnReconnecionStatusChanged, StatusType } from './types';
import _ from 'lodash';

const defaultParseMethod = <T>(data: Record<string, T>) => {
    const newData: Record<string, T> = {}
    Object.values(data).forEach((v) => {
        //@ts-ignore
        newData[v.id] = v
    })
    return newData
}

interface IConstructor<I = {}, O = {}> {
    signalRUrl: string;
    BUCKETS_ENUM: I;
    ON_ENUM: O;
    onConnected?: () => any;
    logger?: Logger;
    access_token?: string;
    refresh_token?: string
    tenantId?: string
    onReconnecionStatusChanged?: OnReconnecionStatusChanged
    disableRenew?: boolean
}

type BucketsEnum<I> =
    IConstructor<I>['BUCKETS_ENUM'][keyof IConstructor<I>['BUCKETS_ENUM']];
type OnEnum<O> = IConstructor<undefined, O>['ON_ENUM'][keyof IConstructor<
    undefined,
    O
>['ON_ENUM']];

type FilterPath = string


export interface IFindRequest<T> { bucket: BucketsEnum<T>, filter: string }

type FindAndSubscribeArgs<T, I> = [requestData: IFindRequest<I>, parseMethod?: (data: Record<string, T>) => Record<string, T>, ...args: any[]]

interface ILoggerUpdates {
    getSubscribedPaths: () => any
    reconnectAll: () => any
    getSignalRConnection: () => any
    FindAndSubscribe: any
}

interface ILogger extends Logger, Partial<ILoggerUpdates> { }

class ApiSubscription<I, O> {
    signalRConnectionStatus: boolean = false;
    signalRConnection: signalR.HubConnection | null = null;
    access_token?: string;
    refresh_token?: string;
    signalRUrl: string;
    BUCKETS_ENUM: I;
    ON_ENUM: O;
    onConnected?: () => any;
    onReconnecionStatusChanged?: OnReconnecionStatusChanged
    logger?: ILogger
    tenantId?: number
    subscribedPaths: Record<FilterPath, {
        subscriptionId: string,
        args: FindAndSubscribeArgs<any, I>
    }> = {}
    tokenRefreshing: boolean = false

    renewTimer?: NodeJS.Timeout;
    renewInterval = 30000;

    disableRenew?: boolean

    constructor(props: IConstructor<I, O>) {
        const {
            BUCKETS_ENUM,
            ON_ENUM,
            access_token,
            logger,
            onConnected,
            signalRUrl,
            tenantId,
            onReconnecionStatusChanged,
            refresh_token,
            disableRenew
        } = props;
        this.disableRenew = disableRenew
        this.access_token = access_token;
        this.refresh_token = refresh_token;
        this.BUCKETS_ENUM = BUCKETS_ENUM;
        this.ON_ENUM = ON_ENUM;
        this.logger = logger;
        this.signalRUrl = signalRUrl;
        this.onConnected = onConnected;
        this.onReconnecionStatusChanged = onReconnecionStatusChanged
        this.tenantId = +(tenantId || 0)
        if (this.logger) {
            this.logger.getSubscribedPaths = () => {
                console.log(this.subscribedPaths)
            }
            this.logger.reconnectAll = () => {
                this.reconnectAllSubscriptions()
            }
            this.logger.getSignalRConnection = () => {
                console.log(this.signalRConnection)
            }
            this.logger.FindAndSubscribe = this.FindAndSubscribe
        }
        if (access_token && signalRUrl) {
            this.logger?.log(
                `Access token exist signalR start connection`,
                undefined,
                'success'
            );
            this.start({ access_token });
        } else {
            this.logger?.log(`Access token not found`, undefined, 'error');
        }
        this.Renew()
    }

    public start = ({ access_token }: { access_token: string }) => {
        this.access_token = access_token;
        if (this.signalRConnection) {
            this.signalRConnection.stop();
        }
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(this.signalRUrl)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (retryContext) => {
                    if (retryContext.elapsedMilliseconds < 60000) {
                        return 1000 + Math.random() * 10000;
                    } else {
                        return null;
                    }
                }
            })
            .build();
        this.signalRConnection = connection;
        connection.onclose(async () => {
            this.logger?.log(`On connection close`, undefined, 'error');
            this.onChangeConnectionStatus(false);
            if (this.onReconnecionStatusChanged) {
                this.onReconnecionStatusChanged(StatusType.CLOSE)
            }
        });
        connection.onreconnecting((error) => {
            this.logger?.log(`On reconnection...`, error, 'warning');
            this.onChangeConnectionStatus(false);
            if (this.onReconnecionStatusChanged) {
                this.onReconnecionStatusChanged(StatusType.RECONNECTING)
            }
        });
        connection.onreconnected((connectionId) => {
            this.logger?.log(`On reconnected`, connectionId);
            this.onChangeConnectionStatus(true);
            if (this.onReconnecionStatusChanged) {
                this.onReconnecionStatusChanged(StatusType.RECONNECTED)
            }
            this.reconnectAllSubscriptions()
        });
        this.onStartConnection();
    };
    private onStartConnection = async () => {
        if (this.signalRConnection) {
            try {
                await this.signalRConnection.start();
                this.logger?.log(`Hub connected`, undefined, 'success');
                this.onChangeConnectionStatus(true);
                if (Object.values(this.subscribedPaths).length !== 0) {
                    await this.reconnectAllSubscriptions()
                }
                if (this.onConnected) {
                    this.onConnected();
                }
            } catch (err) {
                this.logger?.log(`On start connection error`, err, 'error');
                if (
                    this.signalRConnection?.state ===
                    signalR.HubConnectionState.Disconnected
                ) {
                    setTimeout(() => {
                        this.onStartConnection();
                    }, 1000 + Math.random() * 10000);
                }
            }
        }
    };

    private updateTokens = (access_token?: string, refresh_token?: string) => {
        if (access_token) {
            this.access_token = access_token
        }
        if (refresh_token) {
            this.refresh_token = refresh_token
        }
    }

    private onChangeConnectionStatus = (value: boolean) => {
        this.signalRConnectionStatus = value;
        this.logger?.log(
            'Connection status changed',
            { status: value },
            !value ? 'warning' : undefined
        );
    };

    getConnectionStatus = () => {
        return this.signalRConnectionStatus;
    };

    private getToken = () => {
        return this.access_token;
    };

    private clearSubscriptionPaths = () => {
        this.subscribedPaths = {}
    }

    generateSubscriptionPath = (data: IFindRequest<I>) => {
        return `${data.bucket} - ${data.filter}`
    }

    reconnectAllSubscriptions = async () => {
        const status = this.getConnectionStatus();
        if (this.getToken() && this.signalRConnection && status) {
            const paths = _.cloneDeep(this.subscribedPaths)
            this.clearSubscriptionPaths()
            const list = Object.values(paths)
            await Promise.all(list.map((value) => {
                const params = value.args
                return this.FindAndSubscribe(...params)
            }))
        }
    }

    isSubscribed = (data: IFindRequest<I>) => {
        const path = this.generateSubscriptionPath(data)
        const subscribedPath = this.subscribedPaths[path]
        return subscribedPath ? true : false
    }

    private tokenValidation = async () => {
        if (this.tokenRefreshing) {
            let promise = new Promise((resolve) => {
                Notification.RefreshingNotification()
                let timer = setInterval(() => {
                    console.log("tokenRefreshing checker", this)
                    if (!this.tokenRefreshing) {
                        setTimeout(() => {
                            resolve(true)
                            Notification.RefreshingNotificationClose()
                            clearInterval(timer)
                        }, 150)
                    }
                }, 1000)
            });
            await promise
        }
        const access_token = this.getToken()
        if (access_token) {
            const parsedToken = parseJwt(access_token)
            if (parsedToken) {
                if (!parsedToken.expired) {
                    return true
                } else {
                    this.logger?.log(`Access token expired, trying to refresh token`, { parsedToken }, 'warning');
                    if (this.refresh_token) {
                        this.tokenRefreshing = true
                        const res = await authV2Refresh({
                            refreshToken: this.refresh_token
                        })
                        this.tokenRefreshing = false
                        if (res) {
                            this.logger?.log(`Access token refreshed`, { response: res }, 'success');
                            this.updateTokens(res.accessToken, res.refreshToken)
                            return true
                        } else {
                            this.logger?.log(`Refresh token request failed`, { refresh_token: this.refresh_token }, 'error');
                        }
                    } else {
                        this.logger?.log(`Refresh token not found`, { refresh_token: this.refresh_token }, 'error');
                    }
                }
            }
        }
        this.logger?.log(`Access token failed`, undefined, 'error');
        return false
    }

    on = (onString: OnEnum<O>, callback: <T = any>(output: IListenerCallback<BucketsEnum<I>, T>) => any) => {
        const status = this.getConnectionStatus();
        if (this.signalRConnection && status) {
            this.logger?.log(`${onString} - listener started`);
            this.signalRConnection.on(onString as any, (output: IListenerCallback<BucketsEnum<I>>) => {
                this.logger?.log(`${output?.entity?.bucket || onString} - have update`, output);
                callback(output);
            });
        } else {
            this.logger?.log(
                `${onString} - listener not started`,
                {
                    signalRConnection: this.signalRConnection,
                    status
                },
                'error'
            );
        }
    };

    private Renew = () => {
        if (this.disableRenew) return
        if (this.renewTimer) {
            clearInterval(this.renewTimer)
        }
        this.renewTimer = setInterval(async () => {
            const tokenIsValid = await this.tokenValidation()
            const status = this.getConnectionStatus();
            if (status && tokenIsValid) {
                const paths = this.subscribedPaths
                const responses: {
                    value: typeof paths[number],
                    response: any
                }[] = []
                // this.logger?.log('Renew started', undefined)
                const all = Promise.all(Object.values(paths).map(async (value) => {
                    if (this.signalRConnection) {
                        try {
                            const response = await this.signalRConnection.invoke('Renew', this.getToken(), value.subscriptionId);
                            responses.push({
                                value,
                                response
                            })
                        } catch (e) {
                            responses.push({
                                value,
                                response: e
                            })
                        }
                    } else {
                        responses.push({
                            value,
                            response: 'signalRConnection not found'
                        })
                    }
                }))
                await all
                // this.logger?.log('Renew finished', responses, 'warning')
            } else {
                this.logger?.log('Renew not started', {
                    status,
                    tokenIsValid
                })
            }

        }, this.renewInterval)
    }

    FindAndSubscribe = async <T = any>(...args: FindAndSubscribeArgs<T, I>) => {
        const [requestData, parseMethod, ...otherArgs] = args
        const { bucket, filter } = requestData
        const status = this.getConnectionStatus();
        const path = this.generateSubscriptionPath(requestData)
        const token = await this.tokenValidation()
        if (token && this.signalRConnection && status) {
            const request = {
                tenantId: this.tenantId,
                bucket,
                filter,
            }
            try {
                if (this.isSubscribed(requestData)) {
                    this.logger?.log(`ERROR - ${path}`, { request, error: 'This subscription already invoked. It can case issues' }, 'error');
                }
                const requestStartTime = new Date().getTime()
                const res: IResponse<T> | undefined = await this.signalRConnection.invoke('FindAndSubscribe', this.getToken(), request, ...otherArgs);
                if (res) {
                    if (res.headers.success) {
                        this.subscribedPaths[path] = {
                            subscriptionId: res.payload.subscriptionId,
                            args: [...args]
                        }
                        const requestEndTime = new Date().getTime()
                        this.logger?.log(`${path} - invoked`, { response: res, requestDuration: requestEndTime - requestStartTime });
                        const data = parseMethod ? parseMethod(res.payload.data) : defaultParseMethod<T>(res.payload.data)
                        return { data, success: res.headers.success };
                    } else {
                        this.logger?.log(
                            `${path} - invoked not successed response`,
                            { response: res },
                            'error'
                        );
                    }
                } else {
                    this.logger?.log(`${path} - invoked response not found ERROR`, { request }, 'error');
                }
            } catch (err) {
                this.logger?.log(`${path} - invoked ERROR`, { err, request }, 'error');
            }
        } else {
            this.logger?.log(
                `${path} - not invoked`,
                {
                    access_token: this.getToken(),
                    signalRConnection: this.signalRConnection,
                    status
                },
                'error'
            );
        }
        return;
    };
    Unsubscribe = async (data: IFindRequest<I>, ...args: any[]) => {
        const status = this.getConnectionStatus();
        const path = this.generateSubscriptionPath(data)
        const token = await this.tokenValidation()
        if (token && this.signalRConnection && status) {
            const subscribedPath = this.subscribedPaths[path]
            if (subscribedPath) {
                try {
                    const requestStartTime = new Date().getTime()
                    const res = await this.signalRConnection.invoke('Unsubscribe', this.getToken(), subscribedPath.subscriptionId, ...args);
                    if (res) {
                        if (res.headers.success) {
                            delete this.subscribedPaths[path]
                            const requestEndTime = new Date().getTime()
                            this.logger?.log(`${path} - unsubscribed`, { response: res, requestDuration: requestEndTime - requestStartTime }, 'success');
                            return res;
                        } else {
                            this.logger?.log(
                                `${path} - unsubscribe not successed response`,
                                res,
                                'error'
                            );
                        }
                    } else {
                        this.logger?.log(`${path} - unsubscribe response not found ERROR`, '', 'error');
                    }
                } catch (err) {
                    this.logger?.log(`${path} - unsubscribe ERROR`, { err }, 'error');
                }
            } else {
                this.logger?.log(
                    `${path} - not unsubscribed, subscribedPath not found`,
                    '',
                    'warning'
                );
            }
        } else {
            this.logger?.log(
                `${path} - not unsubscribed`,
                {
                    access_token: this.getToken(),
                    signalRConnection: this.signalRConnection,
                    status
                },
                'error'
            );
        }
    }
}

export default ApiSubscription;

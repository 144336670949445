import React from 'react';
import { notification, Row, Col } from 'antd';
import classNames from 'classnames';
import { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import Icon from 'src/Framework/Controls/Icon';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { formattedDateTime } from 'src/Framework/Shared/Shared';
import { getCurrentUserId } from 'src/App/User/Current/Setters';
import { getUserById } from 'src/Framework/Controls/Selectors/UserSelector/utils';
import { HorizontalDivider, PatientName } from './styled';
import { IMessage } from 'src/Activities/Home/Messages/store/types';
import { patientDisplayName } from 'src/Framework/util/format';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { IModalData } from '.';

const key = 'waiting-list-secure-message-alert';

export const openAlert = (
  group: IModalData,
  patientIds: number[],
  response: IMessage[]
) => {
  const currentUser = getCurrentUserId();
  const user = getUserById(currentUser);
  notification.open({
    key,
    message: (
      <div>
        <div className="alert-description-container">
          Secure message is sent to {group.groupName}
        </div>
        <HorizontalDivider />
        <Row align="middle" gutter={16}>
          <Col span={14}>
            <b>{getPatientTerm('Student')}</b>
          </Col>
          <Col span={10} style={{ textAlign: 'center' }}>
            <b>Receipt status</b>
          </Col>
        </Row>
        <HorizontalDivider />
        {patientIds.map((patientId) => {
          const patient = group.patients.find(
            (v) => v.patientInfo.patientId === patientId
          );
          if (!patient) return null;
          const receipt = response.find(
            (v) => v.actors.receivers[0]?.id === patientId
          );
          return (
            <Row gutter={16} style={{ padding: '0 0 5px 0' }}>
              <Col span={14}>
                <PatientName>
                  {patientDisplayName(patient.patientInfo)}
                </PatientName>
              </Col>
              <Col
                span={10}
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                {receipt && (
                  <Icon
                    name="check"
                    color={globalStyleVariables.newBrandingSuccessText}
                  />
                )}
              </Col>
            </Row>
          );
        })}

        <HorizontalDivider />
      </div>
    ),
    duration: 0,
    className: classNames({
      'waiting-list-secure-alert': true
    }),
    closeIcon: (
      <div
        className="close-icon"
        style={{
          WebkitMaskImage: `url(${ICONS_LIST.thinClose})`,
          maskImage: `url(${ICONS_LIST.thinClose})`
        }}
      />
    ),
    description: (
      <div className="description-container">
        <span style={{ width: 175 }}>
          {formattedDateTime(new Date().toString())}
        </span>
        <span
          style={{
            textAlign: 'end'
          }}
        >
          {`by ${user?.name}`}
        </span>
      </div>
    ),
    icon: (
      <div
        className="left-icon-container"
        style={{
          background: '#EEFAEA',
          opacity: 1
        }}
      >
        <div
          className="alert-icon-container"
          style={{
            background: globalStyleVariables.newBrandingSuccess,
            WebkitMaskImage: `url(${ICONS_LIST.newMessage})`,
            maskImage: `url(${ICONS_LIST.newMessage})`
          }}
        />
      </div>
    )
  });
};

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const PopupHeader = styled.div`
  width: 100%;
  position: relative;
  box-shadow: 0 4px 16px rgba(19, 65, 98, 0.08);
  border-top: solid ${globalStyleVariables.newBrandingPrimary200} 4px;

  &.handleDrag {
    cursor: grab;
  }
`;

export const PopupTitle = styled.div`
  padding: 16px 24px;

  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;

  color: #000000;
`;

export const IconGroupContainer = styled.div`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 0;
  background-color: #e7f0f5;
`;

export const DeleteIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditIconContainer = styled.div`
  cursor: pointer;

  svg > path {
    fill: ${(props: { showRClickPopup: any }) =>
      props.showRClickPopup === 'Edit'
        ? globalStyleVariables.newBrandingPrimary200
        : '#f7f7f7'};
  }
`;

export const MainBody = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ClosePopupContainer = styled.div`
  cursor: pointer;
`;

export const PopupBody = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
`;

export const FieldValue = styled.div`
  display: inline-block;
  vertical-align: top;
  word-wrap: break-word;
  padding-right: 4rem;
`;

export const ExtendIconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 16px;
  cursor: pointer;
`;

export const InfoWrapper = styled.div`
  padding: 0 24px;
`;

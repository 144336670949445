import globalStyleVariables from "src/Framework/Styles/variables.module.scss";
import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    border-bottom: 1px solid ${globalStyleVariables.borderColor};
`

export const Title = styled.div`
    font-weight: 500;
    font-size: 14px;
    margin: 8px 0 5px 0;
`

export const SubTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: ${globalStyleVariables.orange};
`
import React, { useImperativeHandle, useState, useMemo } from 'react';
import { Row } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import Modal from 'src/Framework/Controls/Modal';
import Button from 'src/Framework/Controls/Button';
import {
  getClinicByCode,
  getClinicById
} from 'src/Framework/Controls/Selectors/ClinicSelector';
import { getReasonByID } from 'src/Framework/Controls/Selectors/ReasonSelector/utils';
import {
  getClinicConfig,
  getProviderByCode,
  getProviderById
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { differObjects } from 'src/Framework/Shared/Shared';
import { useValidator } from 'src/Framework/util/formValidator';
import test_ids from 'src/tests-script/pages/Schedule/Event/addEdit';
import { useAppSelector } from 'src/store';

import ConflictsBar from 'src/Activities/Schedule/ConflictsBar/ConflictsBar';
import Event from './Event';
import { eventName } from 'src/Activities/Schedule/utils';
import { getProviderSelection } from 'src/Activities/Schedule/Popups/AppointmentPopup/utils';
import { findOpt, mapClinic } from 'src/Activities/Schedule/Utils/Utils';
import {
  IEventMember,
  IProviderEvent
} from 'src/Activities/Schedule/Store/Events/types';
import {
  postEvent,
  getEvent,
  patchEvent
} from 'src/Activities/Schedule/Store/Events/Actions';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';

import {
  ClinicInfo,
  PatientInfo
} from 'src/Activities/Schedule/Popups/AppointmentPopup/AppointmentPopup_Styles';
import { Title, TitleContainer, InfoBar } from './styled';

interface IOptions {
  action: string;
  clinicCode?: string;
  clinicName?: string;
  date?: Date;
  endTime: string;
  providerCode: string;
  providerName: string;
  reason?: any;
  scheduleEventTypeId: number;
  startTime: string;
  typeAppointmentParticipantId: number;
  uid: string;
  eventId?: number;
  updateSeries?: boolean;
}

interface IProps {
  onEventSaved?: () => void;
  onClosePopup?: () => void;
}

interface IShow {
  options: Partial<IOptions>;
}

export interface IEventModalHandles {
  show(obj: IShow): void;

  close(): void;
}

const defaultEvent: IProviderEvent = {
  name: '',
  clinicId: null,
  appointmentReasonId: null,
  startDate: null,
  startTime: null,
  endTime: null,
  providerIds: [],
  conflictsResponses: undefined
};

const Component: React.ForwardRefRenderFunction<IEventModalHandles, IProps> = (
  { onEventSaved, onClosePopup },
  ref
) => {
  const clinics = useAppSelector((state) => state.scheduleDetails.clinics);
  const providers = useAppSelector(
    (state) => state.schedule.clinicProviders.data
  );
  const [localOptions, setLocalOptions] = useState<Partial<IOptions> | null>(
    null
  );
  const [data, setData] = useState<Partial<IProviderEvent> | null>(
    defaultEvent
  );
  const [prevData, setPrevData] = useState<Partial<IProviderEvent> | null>(
    null
  );
  const [visible, setVisible] = useState(false);
  const [isScheduling, setScheduling] = useState(false);
  const [isSeriesOpened, setSeriesOpened] = useState<boolean>(false);
  useModalFocus({ active: visible });
  useImperativeHandle(ref, () => ({
    show,
    close
  }));

  const selectedClinic = useMemo(
    () => (data?.clinicId ? getClinicById(data?.clinicId) : null),
    [data?.clinicId]
  );
  const selectedProvidersCodes = useMemo(
    () =>
      (data?.providerIds || []).map((id) => {
        const provider = getProviderById(id);
        if (provider) {
          const pages =
            getClinicConfig(provider, data?.clinicId as number)?.maxPages || 1;
          return {
            id: provider.providerId,
            code: provider.code,
            name: provider.firstName + ' ' + provider.lastName,
            page: pages
          };
        } else {
          return undefined;
        }
      }),
    [data?.providerIds]
  );

  const isEventRecurring = useMemo(
    () => !!data?.appointmentSeries,
    [data?.appointmentSeries]
  );
  const selectedReasonName = useMemo(
    () =>
      data?.appointmentReasonId
        ? getReasonByID(data?.appointmentReasonId)?.name
        : '',
    [data?.appointmentReasonId]
  );
  const hasChanges = useMemo(() => {
    if (data && !prevData) {
      return true;
    }
    const changes = data && prevData && differObjects(data, prevData);
    return changes && !_.isEmpty(changes);
  }, [data, prevData]);

  const validator = useValidator(
    [
      { value: data?.name },
      {
        value: data?.appointmentReasonId
      },
      {
        value: data?.providerIds
      }
    ],
    [data]
  );

  const clinicOpts = clinics.map((c) => mapClinic(c));
  const selectedClinicOpts = findOpt(clinicOpts, selectedClinic?.code || '');
  let providerSelection = getProviderSelection(
    providers,
    selectedClinicOpts,
    selectedProvidersCodes
  );
  let { selectedProviders } = providerSelection;

  const show = async ({ options }: IShow) => {
    setLocalOptions(options);
    if (options.action === 'edit') {
      const eventInfo = await getEvent(options.eventId!);
      if (eventInfo) {
        const providerIds = eventInfo.eventMembers.map(
          (item: IEventMember) => item.providerId
        );
        setData({
          ...eventInfo,
          providerIds,
          eventMembers: eventInfo.eventMembers,
          startTime: eventInfo.startTime,
          endTime: eventInfo.endTime,
          startDate: eventInfo.startDate.slice(0, 11) + '00:00:00',
          action: options.action,
          updateSeries: options.updateSeries,
          conflictsResponses: undefined
        });
        setPrevData({
          ...eventInfo,
          providerIds,
          eventMembers: eventInfo.eventMembers,
          startTime: eventInfo.startTime,
          endTime: eventInfo.endTime,
          startDate: eventInfo.startDate.slice(0, 11) + '00:00:00',
          action: options.action,
          updateSeries: options.updateSeries,
          conflictsResponses: undefined
        });
      }
    } else {
      setData((data) => ({
        ...data,
        clinicId: getClinicByCode(options.clinicCode!)?.id,
        providerIds: [getProviderByCode(options.providerCode!)?.providerId!],
        startDate: moment(options.date)
          .startOf('day')
          .utc(true)
          .toISOString()
          .slice(0, 19),
        startTime:
          moment(options.date)
            .startOf('day')
            .utc(true)
            .toISOString()
            .slice(0, 11) +
          options.startTime +
          ':00'
      }));
    }
    setVisible(true);
  };
  const close = () => {
    setVisible(false);
    setToDefault();
    if (onClosePopup) {
      onClosePopup();
    }
  };

  const setToDefault = () => {
    setLocalOptions(null);
    setData(null);
    setPrevData(null);
    setSeriesOpened(false);
    setScheduling(false);
  };

  const onChangeData = (newData: Partial<IProviderEvent>) => {
    setData((data) => ({ ...data, ...newData }));
  };

  const apply = async () => {
    if (localOptions?.action === 'edit') {
      const changes = differObjects(data, prevData);
      if (
        (!changes || _.isEmpty(changes)) &&
        !(data?.conflictsResponses[0]?.dialogResponse === 'override')
      ) {
        return;
      }
      const dataToSend = _.cloneDeep(data!);
      if (
        dataToSend.appointmentSeries &&
        !_.isEmpty(dataToSend.appointmentSeries)
      ) {
        dataToSend.appointmentSeries.startDate =
          dataToSend.appointmentSeries.startDate?.slice(0, 10);
        dataToSend.appointmentSeries.endDate =
          dataToSend.appointmentSeries.endDate?.slice(0, 10);
        dataToSend.appointmentSeries.startTime =
          dataToSend.appointmentSeries.startTime?.replace('.000Z', '');
        dataToSend.appointmentSeries.endTime =
          dataToSend.appointmentSeries.endTime?.replace('.000Z', '');
        if (dataToSend.appointmentSeries.startTime) {
          dataToSend.startTime = dataToSend.appointmentSeries.startTime;
        }
        if (dataToSend.appointmentSeries.endTime) {
          dataToSend.endTime = dataToSend.appointmentSeries.endTime;
        }
      }
      dataToSend.isSeries = localOptions?.updateSeries;
      const result = await patchEvent({ ...dataToSend, id: data!.id });
      if (result) {
        if (!result.hasConflicts) {
          onEventSaved && onEventSaved();
          close();
        } else {
          const newData = _.cloneDeep(data!);
          newData.conflictsResponses = result.conflicts;
          newData.allowOverride = result.allowOverride;
          setData(newData);
          setPrevData(newData);
        }
      }
    } else {
      const dataToSend = _.cloneDeep(data!);
      if (
        dataToSend.appointmentSeries &&
        !_.isEmpty(dataToSend.appointmentSeries)
      ) {
        dataToSend.appointmentSeries.startDate =
          dataToSend.appointmentSeries.startDate?.slice(0, 10);
        dataToSend.appointmentSeries.endDate =
          dataToSend.appointmentSeries.endDate?.slice(0, 10);
        dataToSend.appointmentSeries.startTime =
          dataToSend.appointmentSeries.startTime?.replace('.000Z', '');
        dataToSend.appointmentSeries.endTime =
          dataToSend.appointmentSeries.endTime?.replace('.000Z', '');
        dataToSend.startTime = dataToSend.appointmentSeries.startTime?.replace(
          '.000Z',
          ''
        );
        dataToSend.endTime = dataToSend.appointmentSeries.endTime?.replace(
          '.000Z',
          ''
        );
        dataToSend.isSeries = true;
      }
      const result = await postEvent(dataToSend);
      if (result) {
        if (!result.hasConflicts) {
          onEventSaved && onEventSaved();
          close();
        } else {
          const newData = _.cloneDeep(data!);
          newData.conflictsResponses = result.conflicts;
          newData.allowOverride = result.allowOverride;
          setData(newData);
          setPrevData(newData);
        }
      }
    }
  };

  const onClickOverride = () => {
    setData((data) => ({
      ...data,
      conflictsResponses: (data?.conflictsResponses || []).map((item: any) => {
        item.dialogResponse = 'override';
      })
    }));
    apply();
  };

  const title = (
    <TitleContainer>
      <Title>
        {localOptions?.action === 'edit' ? 'Edit' : 'Add'} {eventName}{' '}
        {localOptions?.action === 'edit' && isEventRecurring
          ? localOptions?.updateSeries
            ? '- Series'
            : '- Single'
          : ''}
      </Title>
      {isSeriesOpened ? (
        <PatientInfo>
          <ClinicInfo>
            <Row align="middle">
              <TitleContainer
                style={{
                  marginRight: '1rem',
                  fontWeight: 'bold'
                }}
              >
                <label className={'category-label'}>Clinic:</label>
              </TitleContainer>
              <span className={'value-label'}>
                {selectedClinic?.name || ''}
              </span>
            </Row>
            <Row align="middle">
              <TitleContainer
                style={{
                  marginRight: '1rem',
                  fontWeight: 'bold'
                }}
              >
                <label className={'category-label'}>Type:</label>
              </TitleContainer>
              <span className={'value-label'}>{selectedReasonName}</span>
            </Row>
          </ClinicInfo>
        </PatientInfo>
      ) : null}
    </TitleContainer>
  );
  const headerRight = (
    <Button
      hidden={isSeriesOpened}
      onClick={apply}
      onKeyDown={(e) => e.stopPropagation()}
      className={'popup-save-button'}
      id={test_ids.saveButton}
      disabled={!validator || !hasChanges}
    >
      Save
    </Button>
  );
  const defaultWidth = 1320;

  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        headerRight,
        title,
        onClickBack: () => setScheduling(false),
        backButton: isScheduling
      }}
      id={test_ids.container}
      width={defaultWidth}
      visible={visible}
      onClose={close}
      customTitle={title}
      afterClose={setToDefault}
      buttons={headerRight}
      isDragable={true}
      modalProps={{
        className: 'event-modal',
        destroyOnClose: true,
        mask: false
      }}
    >
      {!!data?.conflictsResponses &&
        !isSeriesOpened &&
        data?.conflictsResponses.some((item: any) => !item?.dialogResponse) && (
          <InfoBar>
            <ConflictsBar
              message={'A conflict has been found'}
              disableOverride={!data?.allowOverride}
              conflictsOverride={onClickOverride}
              onEditConflicts={() => {}}
              conflictsProps={{
                conflicts: {
                  conflictResponse: data?.conflictsResponses
                },
                clinicOpts,
                selectedProviders,
                selectedClinic: selectedClinicOpts
              }}
            />
          </InfoBar>
        )}
      {visible && (
        <Event
          isScheduling={isScheduling}
          setScheduling={setScheduling}
          eventData={data!}
          onChangeEventData={onChangeData}
          isSeriesOpened={isSeriesOpened}
          setSeriesOpened={setSeriesOpened}
        />
      )}
    </Modal>
  );
};

export default React.forwardRef(Component);

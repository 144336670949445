import React, { FC } from 'react';

import { Container, InnerContainer } from './styled';

interface IProps {
  children: React.ReactNode;
  style?: any;
}

const Component: FC<IProps> = ({ children, ...props }: IProps) => {
  return (
    <Container {...props}>
      <InnerContainer tabIndex={0}>{children}</InnerContainer>
    </Container>
  );
};

export default Component;

import styled from "styled-components";

const font = `
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    color: #666666;
`

export const Container = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #E6E6E6;
    margin: 0 5px 0 20px;
    padding: 5px 10px;
`

export const Message = styled.div`
    ${font}
    margin: 5px 0 0 0;
`

export const Name = styled.div`
    font-family: Encode Sans Expanded;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
`

export const Date = styled.div`
    ${font}
    font-size: 12px;
`

export const Avatar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;
    text-transform: uppercase;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.1);
    margin: 5px 0 0 0;
`
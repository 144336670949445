import React, { FC, useEffect } from 'react';

import { useAppSelector } from 'src/store';

import RiskReport from '../RiskReport';
import * as dashboardActions from '../store/Actions';

interface IProps {}

const Component: FC<IProps> = ({}: IProps) => {
  const { provider, risks } = useAppSelector((state) => {
    const { providerDashboard, filters } = state.dashboard;
    return {
      provider: filters.provider,
      risks: providerDashboard.risks
    };
  });
  useEffect(() => {
    if (provider?.providerId) {
      dashboardActions.getProviderDashboardRisks(provider?.providerId);
    }
  }, [provider]);
  return <RiskReport data={risks} />;
};

export default Component;

import React, { FC, useEffect, useState, useRef, useMemo } from 'react';
import moment from 'moment';

import Popover from 'src/Framework/Controls/Popover';
import Tooltip from 'src/Framework/Controls/Tooltip';
import { debouncer } from 'src/Framework/util/helps';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';
import tests_ids from 'src/tests-script/pages/Schedule/SingleApptPopup';

import { AddIndividualApptSvg } from 'src/Activities/Schedule/Svg/AddIndividualApptSvg';
import { RecurreSvg } from 'src/Activities/Schedule/Svg/RecurreSvg';
import AppointmentPopup, {
  IModalHandles
} from 'src/Activities/Schedule/Popups/AppointmentPopup/Container';
import EventPopup, {
  IEventModalHandles
} from 'src/Activities/Schedule/Popups/EventPopup';
import EventInfoPopup from 'src/Activities/Schedule/Popups/EventPopup/EventInfoPopup';
import EventInfoModal, {
  IModalHandles as IEventInfoModalHandles
} from 'src/Activities/Schedule/Popups/EventPopup/EventInfoModal';
import BlockReservationPopup, {
  IModalHandles as IBlockReservation
} from 'src/Activities/Schedule/Popups/BlockReservationPopup/Container';
import ScheduleGroupPopup from 'src/Activities/Schedule/Popups/ScheduleGroupPopup';
import ScheduleGroupPopupModal, {
  IModalHandles as IScheduleGroupPopup
} from 'src/Activities/Schedule/Popups/ScheduleGroupPopup/Modal';
import OutreachModal, {
  IModalHandles as OutreachModalHandles
} from 'src/Activities/Schedule/Popups/Outreach/Modal';
import ScheduleDetailPopup from 'src/Activities/Schedule/Popups/ScheduleDetailPopup';
import ScheduleDetailPopupModal, {
  IModalHandles as IScheduleDetailPopup
} from 'src/Activities/Schedule/Popups/ScheduleDetailPopup/Modal';
import { findAndFilterExpiredEvents } from 'src/Activities/Schedule/Store/ScheduleSliceEventsActions';
import { getEventName } from 'src/Activities/Schedule/Main/ScheduleCol/utils';
import ScheduleOne from './ScheduleOne';

import {
  EditSeriesPopupContent,
  PopupTextLine,
  ListItem
} from './ScheduleOne/styled';
import { ScheduleGroupPopupContainer } from './ScheduleCol_Styles';

interface IProps {
  patient: any;
  isShowingPatientName: any;
  isDragHover?: boolean;
  onDelete: Function;
  height: number;
  firstInReservation: boolean;
  lastInReservation?: boolean;
  sliceId: any;
}

const Component: FC<IProps> = ({
  patient,
  isShowingPatientName,
  isDragHover,
  onDelete,
  height,
  firstInReservation,
  lastInReservation,
  sliceId
}: IProps) => {
  const ApptPopupRef = useRef<IModalHandles>(null);
  const EventPopupRef = useRef<IEventModalHandles>(null);
  const EventInfoModalRef = useRef<IEventInfoModalHandles>(null);
  const BlockReservationRef = useRef<IBlockReservation>(null);
  const ScheduleDetailPopupRef = useRef<IScheduleDetailPopup>(null);
  const ScheduleGroupPopupRef = useRef<IScheduleGroupPopup>(null);
  const OutreachModalRef = useRef<OutreachModalHandles>(null);
  const [visible, setVisible] = useState(false);
  const [apptPopupVisible, setApptPopupVisible] = useState(false);
  const debounce = useRef(debouncer(250));
  const seriesPopupRef = useRef(null);
  const cellRef = useRef(null);

  useEffect(() => {
    if (patient.blockReservationExpireTime) {
      const timer = setInterval(() => {
        //console.log(`Timer enabled for ${patient.uid}`);
        if (moment() >= moment(patient.blockReservationExpireTime)) {
          console.log(`Event ${patient.uid} expired`);
          findAndFilterExpiredEvents();
          if (timer) {
            clearInterval(timer);
          }
        }
      }, 1000);
      return () => {
        if (timer) {
          clearInterval(timer);
        }
      };
    }
    return () => {};
  }, []);

  const isInPast = useMemo(
    () =>
      moment(patient.date.slice(0, 10), 'YYYY-MM-DD').isBefore(
        moment().startOf('day')
      ),
    [patient.date]
  );

  const closeAll = () => {
    setVisible(false);
    setApptPopupVisible(false);
    if (cellRef.current) {
      //@ts-ignore
      cellRef.current.focus();
    }
  };
  const container = () => {
    const render = () => {
      return (
        <ScheduleOne
          onDoubleClick={(e: any) => {}}
          onClick={(e: any) => {
            debounce.current(() => {
              if (e?.detail === 1) {
                if (patient.inSeries) {
                  setVisible(true);
                }
              }
              if (e.detail === 2) {
                if (patient.isOutreach) {
                  return OutreachModalRef.current?.show({
                    outreachId: patient.outreachId
                  });
                }
                if (patient.isEvent) {
                  return EventInfoModalRef.current?.show({
                    eventId: patient.eventId
                  });
                }
                if (!patient.isBlock) {
                  if (!patient.groupAppointment) {
                    return ScheduleDetailPopupRef.current?.show({});
                  } else {
                    return ScheduleGroupPopupRef.current?.show({});
                  }
                }
                if (patient.isBlock) {
                  return BlockReservationRef.current?.show({
                    options: {
                      action: 'edit',
                      appointmentBlockId: patient.appointmentBlockId,
                      startTime: patient.date
                    }
                  });
                }
                ApptPopupRef.current?.show({
                  options: {
                    action: 'edit',
                    uid: patient.uid
                  }
                });
              }
            });
          }}
          isShowingPatientName={isShowingPatientName}
          isDragHover={isDragHover}
          appointment={patient}
          height={height}
          firstInReservation={firstInReservation}
          lastInReservation={lastInReservation}
          cellRef={cellRef}
        />
      );
    };

    const onKeyDownLastItem = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && e.target === e.currentTarget) {
        //@ts-ignore
        e.currentTarget.click();
      }
      if (e.key === 'Tab' && !e.shiftKey && e.target === e.currentTarget) {
        setVisible(false);
        e.preventDefault();
        if (cellRef.current) {
          //@ts-ignore
          cellRef.current.focus();
        }
      }
    };
    const onKeyDownWrapper = (e: React.KeyboardEvent) => {
      if (e.key === 'Tab' && e.shiftKey && e.target === e.currentTarget) {
        setVisible(false);
        e.preventDefault();
        if (cellRef.current) {
          //@ts-ignore
          cellRef.current.focus();
        }
      }
    };

    const renderAppt = () => {
      return (
        <Popover
          id={tests_ids.popover}
          overlayClassName="schedule-group-popup"
          open={apptPopupVisible}
          globalOverlay={false}
          destroyTooltipOnHide
          showArrow={false}
          arrowContent={<div />}
          content={
            patient.isEvent ? (
              <ScheduleGroupPopupContainer>
                <EventInfoPopup
                  sliceId={sliceId}
                  eventId={patient.eventId}
                  onClosePopup={() => {
                    closeAll();
                  }}
                  onDelete={onDelete}
                  onEditSchedule={(eventId, isSeries) => {
                    EventPopupRef.current?.show({
                      options: {
                        action: 'edit',
                        eventId,
                        updateSeries: isSeries
                      }
                    });
                  }}
                />
              </ScheduleGroupPopupContainer>
            ) : patient.groupAppointment ? (
              <ScheduleGroupPopupContainer>
                <ScheduleGroupPopup
                  appointmentId={patient.appointmentId}
                  onClosePopup={() => {
                    closeAll();
                  }}
                  onDelete={onDelete}
                  onEditSchedule={() => {
                    ApptPopupRef.current?.show({
                      options: {
                        action: 'edit',
                        uid: patient.uid
                      }
                    });
                  }}
                />
              </ScheduleGroupPopupContainer>
            ) : (
              <ScheduleGroupPopupContainer>
                <ScheduleDetailPopup
                  appointmentId={patient.appointmentId}
                  onClosePopup={() => {
                    closeAll();
                  }}
                  onDelete={onDelete}
                  onEditSchedule={(appointmentId, series) => {
                    ApptPopupRef.current?.show({
                      options: {
                        action: 'edit',
                        uid: `${appointmentId}`,
                        updateSeries: series
                      }
                    });
                  }}
                />
              </ScheduleGroupPopupContainer>
            )
          }
          trigger="click"
          placement={patient.isEvent ? 'leftBottom' : 'rightBottom'}
          onOpenChange={(visible: boolean) => {
            if (apptPopupVisible) {
              setApptPopupVisible(visible);
            }
          }}
        >
          {render()}
        </Popover>
      );
    };
    if (patient.inSeries) {
      return (
        <Popover
          id="series-popover"
          open={visible && !apptPopupVisible}
          globalOverlay={true}
          destroyTooltipOnHide
          content={
            <EditSeriesPopupContent
              onMouseDown={(e: any) => e.preventDefault()}
              tabIndex={0}
              role="menu"
              ref={seriesPopupRef}
              onKeyDown={onKeyDownWrapper}
            >
              <Tooltip
                title={
                  patient.isEvent && isInPast
                    ? 'You can not edit Staff Appointments from the past'
                    : undefined
                }
              >
                <ListItem
                  className={
                    patient.isEvent && isInPast
                      ? 'disabled-menu-item'
                      : undefined
                  }
                  aria-disabled={patient.isEvent && isInPast}
                  tabIndex={patient.isEvent && isInPast ? -1 : 0}
                  onKeyDown={onKeyDownAccessibility}
                  role="menuitem"
                  id={tests_ids.editSingle}
                  onClick={async (e: any) => {
                    if (patient.isBlock) {
                      BlockReservationRef.current?.show({
                        options: {
                          action: 'edit',
                          appointmentBlockId: `${patient.appointmentBlockId}`,
                          startTime: patient.date
                        }
                      });
                      setVisible(false);
                      return;
                    }
                    if (patient.isEvent) {
                      if (isInPast) {
                        return;
                      }
                      EventPopupRef.current?.show({
                        options: {
                          action: 'edit',
                          eventId: patient.eventId
                        }
                      });
                      setVisible(false);
                      return;
                    }
                    ApptPopupRef.current?.show({
                      options: {
                        action: 'edit',
                        uid: patient.uid
                      }
                    });
                    setVisible(false);
                  }}
                >
                  <AddIndividualApptSvg />
                  <PopupTextLine style={{ paddingLeft: '0.3rem' }}>
                    Edit this single {getEventName(patient)}
                  </PopupTextLine>
                </ListItem>
              </Tooltip>
              <Tooltip
                title={
                  patient.isEvent && isInPast
                    ? 'You can not edit Staff Appointments from the past'
                    : undefined
                }
              >
                <ListItem
                  className={
                    patient.isEvent && isInPast
                      ? 'disabled-menu-item'
                      : undefined
                  }
                  aria-disabled={patient.isEvent && isInPast}
                  tabIndex={patient.isEvent && isInPast ? -1 : 0}
                  onKeyDown={onKeyDownLastItem}
                  role="menuitem"
                  id={tests_ids.editAll}
                  onClick={async (e: any) => {
                    if (patient.isBlock) {
                      BlockReservationRef.current?.show({
                        options: {
                          action: 'edit',
                          appointmentBlockId: patient.appointmentBlockId,
                          startTime: patient.date,
                          updateSeries: true
                        }
                      });
                      setVisible(false);
                      return;
                    }
                    if (patient.isEvent) {
                      if (isInPast) {
                        return;
                      }
                      EventPopupRef.current?.show({
                        options: {
                          action: 'edit',
                          eventId: patient.eventId,
                          updateSeries: true
                        }
                      });
                      setVisible(false);
                      return;
                    }
                    ApptPopupRef.current?.show({
                      options: {
                        action: 'edit',
                        uid: patient.uid,
                        updateSeries: true
                      }
                    });
                    setVisible(false);
                  }}
                >
                  <RecurreSvg />
                  <PopupTextLine style={{ paddingLeft: '1rem' }}>
                    {`Edit all ${getEventName(patient)}s in this series`}
                  </PopupTextLine>
                </ListItem>
              </Tooltip>
            </EditSeriesPopupContent>
          }
          placement="rightTop"
          trigger={'click'}
          onClickOutSide={() => {
            closeAll();
          }}
          onOpenChange={(v: boolean) => {
            if (v) {
              const timer = setTimeout(() => {
                if (seriesPopupRef.current) {
                  // @ts-ignore
                  seriesPopupRef.current.focus();
                  clearTimeout(timer);
                }
              }, 500);
            }
          }}
        >
          {renderAppt()}
        </Popover>
      );
    } else {
      return renderAppt();
    }
  };
  return (
    <>
      <OutreachModal
        ref={OutreachModalRef}
        id={patient.outreachId}
        onClose={() => {
          if (cellRef.current) {
            //@ts-ignore
            cellRef.current.focus();
          }
        }}
      />
      <ScheduleGroupPopupModal
        ref={ScheduleGroupPopupRef}
        appointmentId={patient.appointmentId}
        onClosePopup={() => {
          closeAll();
        }}
        onDelete={onDelete}
        onEditSchedule={(appointmentId, series) => {
          ApptPopupRef.current?.show({
            options: {
              action: 'edit',
              uid: patient.uid,
              updateSeries: series
            }
          });
        }}
      />
      <ScheduleDetailPopupModal
        ref={ScheduleDetailPopupRef}
        appointmentId={patient.appointmentId}
        onClosePopup={() => {
          closeAll();
        }}
        onDelete={onDelete}
        onEditSchedule={(appointmentId, series) => {
          ApptPopupRef.current?.show({
            options: {
              action: 'edit',
              uid: `${appointmentId}`,
              updateSeries: series
            }
          });
        }}
      />
      <EventInfoModal
        ref={EventInfoModalRef}
        sliceId={sliceId}
        eventId={patient.eventId}
        onClosePopup={() => {
          closeAll();
        }}
        onDelete={onDelete}
        onEditSchedule={(eventId, isSeries) => {
          EventPopupRef.current?.show({
            options: {
              action: 'edit',
              eventId,
              updateSeries: isSeries
            }
          });
        }}
      />
      <EventPopup
        ref={EventPopupRef}
        onClosePopup={() => {
          if (cellRef.current) {
            //@ts-ignore
            cellRef.current.focus();
          }
        }}
      />
      <AppointmentPopup
        ref={ApptPopupRef}
        onClosePopup={() => {
          if (cellRef.current) {
            //@ts-ignore
            cellRef.current.focus();
          }
        }}
      />
      <BlockReservationPopup
        ref={BlockReservationRef}
        onClosePopup={() => {
          if (cellRef.current) {
            //@ts-ignore
            cellRef.current.focus();
          }
        }}
      />
      {container()}
    </>
  );
};

export default Component;

import React, { FC, useEffect, useRef, useState } from 'react';
import { Tooltip, notification, Row, Col } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import ElementTitle, {
  IProps as ITitleProps
} from 'src/Framework/Controls/ElementTitle';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import Icon from 'src/Framework/Controls/Icon';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';

import { IFileSimpleDto } from '../FileUploader';
import Scanner from '../Scanner';
import FileViewerPopover from '../FileViewer/Popover';

import {
  Container,
  Block,
  UploadButton,
  NamesContainer,
  FilesContainer,
  IconContainer,
  RemoveIcon,
  ScannerContainer,
  PreviewContainer,
  PreviewItem
} from './styled';

const maxSize = 10 * 1000000; // 10 mb

interface IFile extends File {
  id?: string;
}

interface IProps {
  files: IFileSimpleDto[];
  fileIds: number[] | undefined;
  filesToUpload: IFile[];
  onChangeFiles: (data: { files: IFileSimpleDto[]; fileIds: number[] }) => any;
  onChangeUploadFiles: (data: IFile[]) => any;
  titleProps?: Partial<ITitleProps>;
  id?: string;
  singleFile?: boolean;
  colorButtons?: string;
  preview?: boolean;
  accept?: string;
  maxNumberOfFiles?: number;
  patientId?: string | number;
  traineeId?: string | number;
}

const isFilesEqual = (file1: File, file2: File) => {
  const generate = (file: File) => ({
    lastModified: file.lastModified,
    name: file.name,
    size: file.size,
    type: file.type
  });
  return _.isEqual(generate(file1), generate(file2));
};

const UploaderAttachments: FC<IProps> = (props: IProps) => {
  const {
    id,
    titleProps,
    filesToUpload,
    onChangeUploadFiles,
    files,
    fileIds,
    onChangeFiles,
    singleFile,
    colorButtons,
    accept,
    preview,
    maxNumberOfFiles,
    patientId,
    traineeId
  } = props;
  const [previewObjectsUrl, setPreviewObjectsUrl] = useState<string[]>([]);

  useEffect(() => {
    if (preview) {
      const arr = filesToUpload.map((file) => URL.createObjectURL(file));
      setPreviewObjectsUrl(arr);
    }
  }, [filesToUpload, preview]);

  const inputRef = useRef<HTMLInputElement>(null);
  const onUpload = () => {
    inputRef.current?.click();
  };
  const empty = [...files, ...filesToUpload].length === 0;
  const filesNames = [
    ...files.map((v) => v.originalFileName || v.name || v.fileName),
    ...filesToUpload.map((v) => v.name)
  ].join(', ');

  const uploadFilesChanging = (arr: IFile[]) => {
    let arrayToUpload = _.cloneDeep(arr);
    for (let i = 0; i < arr.length; i++) {
      const file = arr[i];
      if (file.size > maxSize) {
        notification.error({
          message: `File ${file.name} more than 10mb`
        });
        arrayToUpload = arrayToUpload.filter((item) => item.id !== file.id);
      }
      const exist = filesToUpload.some((f) => isFilesEqual(f, file));
      if (exist) {
        notification.error({
          message: `File ${file.name} already selected`
        });
        arrayToUpload = arrayToUpload.filter((item) => item.id !== file.id);
      }
    }
    if (
      maxNumberOfFiles &&
      arrayToUpload.length + files.length + filesToUpload.length >
      maxNumberOfFiles
    ) {
      notification.error({
        message: `Total number of files is more than ${maxNumberOfFiles}`
      });
      return;
    }
    if (singleFile) {
      onChangeUploadFiles([...arrayToUpload]);
      onChangeFiles({ fileIds: [], files: [] });
    } else {
      onChangeUploadFiles([...filesToUpload, ...arrayToUpload]);
    }
  };

  return (
    <Container id={id}>
      <input
        id="upload-attchments-input"
        tabIndex={-1}
        ref={inputRef}
        type="file"
        multiple={singleFile ? false : true}
        style={{ display: 'none' }}
        accept={accept}
        onChange={(e) => {
          const files = e.target.files;
          if (files) {
            const arr = Array.from(files);
            arr.forEach((file: IFile) => {
              file.id = uuidv4();
            });
            /** Clear prev files */
            const dt = new DataTransfer();
            e.target.files = dt.files;
            uploadFilesChanging(arr);
          }
        }}
      />
      <ElementTitle name="Attachment" {...titleProps} />
      <Block>
        <NamesContainer onClick={() => onUpload()}>
          <EllipsisText>{empty ? `Files: //` : filesNames}</EllipsisText>
        </NamesContainer>
        <UploadButton
          role="button"
          id="upload-attachment-button"
          aria-label="Upload Attachment"
          tabIndex={0}
          onKeyDown={onKeyDownAccessibility}
          onClick={() => onUpload()}
          color={colorButtons}
        >
          Upload
        </UploadButton>
        <ScannerContainer
          id="scanner-button"
          color={colorButtons}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Scanner
            singleFileMode={singleFile}
            iconColor={colorButtons}
            onGetImage={(files) => {
              files.forEach((file) => {
                const f: IFile = file;
                f.id = uuidv4();
              });
              uploadFilesChanging(files);
            }}
          />
        </ScannerContainer>
      </Block>
      <FilesContainer>
        {files.map((file, index) => (
          <IconContainer
            tabIndex={0}
            role="button"
            id={'download-attachment-button-prev' + index}
            aria-label="Download attachment"
            onKeyDown={onKeyDownAccessibility}
            key={file.id}
          >
            <RemoveIcon
              tabIndex={0}
              role="button"
              id={'delete-attachment-button-prev' + index}
              aria-label="Delete attachment"
              onKeyDown={(e) => {
                e.stopPropagation();
                onKeyDownAccessibility(e);
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const arrFileIds = [...(fileIds || [])];
                const i = (fileIds || []).findIndex((v) => v === file.id);
                if (i !== -1) {
                  arrFileIds.splice(i, 1);
                }
                const arrFiles = [...files];
                arrFiles.splice(index, 1);
                onChangeFiles({ fileIds: arrFileIds, files: arrFiles });
              }}
            >
              <Icon name="removeCircle" color="black" size={12} />
            </RemoveIcon>
            <FileViewerPopover
              item={file}
              patientId={patientId}
              traineeId={traineeId}
            >
              <div>
                <Icon name="fileCommon" color="black" size={20} />
              </div>
            </FileViewerPopover>
          </IconContainer>
        ))}
        {filesToUpload.map((file, index) => (
          <IconContainer
            tabIndex={0}
            role="button"
            id={'download-attachment-button' + index}
            aria-label="Download attachment"
            onKeyDown={onKeyDownAccessibility}
            key={file.id}
            onClick={() => {
              const url = window.URL.createObjectURL(file);
              const link = document.createElement('a');
              link.setAttribute('download', file.name);
              link.href = url;
              link.click();
              window.URL.revokeObjectURL(url);
            }}
          >
            <RemoveIcon
              tabIndex={0}
              role="button"
              aria-label="Delete attachment"
              id={'delete-attachment-button' + index}
              onKeyDown={(e) => {
                e.stopPropagation();
                onKeyDownAccessibility(e);
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const arr = [...filesToUpload];
                arr.splice(index, 1);
                onChangeUploadFiles(arr);
              }}
            >
              <Icon name="removeCircle" color="black" size={12} />
            </RemoveIcon>
            <Tooltip title={file.name} overlayStyle={{ zIndex: 10000 }}>
              <div>
                <Icon name="fileCommon" color="black" size={20} />
              </div>
            </Tooltip>
          </IconContainer>
        ))}
      </FilesContainer>
      {preview && (
        <PreviewContainer>
          <Row gutter={8}>
            {previewObjectsUrl.map((value, index) => (
              <Col key={value}>
                <PreviewItem>
                  <img
                    height={200}
                    width={200}
                    src={value}
                    alt={`image-${index}`}
                  />
                </PreviewItem>
              </Col>
            ))}
          </Row>
        </PreviewContainer>
      )}
    </Container>
  );
};

export default React.memo(UploaderAttachments);

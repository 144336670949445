import React, { FC, useState } from 'react';
import classnames from 'classnames';

import SelectContainer from 'src/Framework/Controls/SelectContainer';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { useValidator } from 'src/Framework/util/formValidator';

import { IProps } from './index';
import List from './List';
import * as actions from '../Actions/index';

import { Footer, AreaSuffix } from './styled';

export const defaultCommentsData = [];

const Component: FC<IProps> = ({
  comments,
  appointmentId,
  onChangeComment,
  creationMode,
  viewMode
}: IProps) => {
  const [comment, setComment] = useState('');
  const onSend = async () => {
    const res = await actions.postAppointmentComments({
      isActive: true,
      appointmentId,
      comment
    });
    if (res) {
      onChangeComment(res);
      setComment('');
    }
  };
  const validator = useValidator(
    [
      {
        value: comment
      }
    ],
    [comment]
  );

  return (
    <>
      {!creationMode && (
        <List
          data={comments}
          onChangeComment={onChangeComment}
          viewMode={viewMode}
        />
      )}
      {!viewMode && (
        <Footer creationMode={creationMode}>
          <SelectContainer
            label="Add a comment"
            type="area"
            star={false}
            className={classnames({ ['area-with-suffix']: !creationMode })}
            areaAutoReplace={true}
            value={comment}
            onChange={(text: string) => {
              setComment(text);
              if (creationMode) {
                onChangeComment({
                  comment: text
                });
              }
            }}
            bottomMargin={false}
            inputProps={{
              rows: 5,
              maxLength: 255
            }}
          />
          {!creationMode && (
            <AreaSuffix>
              <ButtonIcon
                isButton={true}
                disableClick={!validator}
                label="add a comment"
                disableBackground={true}
                active={validator}
                name={ICONS_LIST.paperPlane}
                asyncOnClick={onSend}
              />
            </AreaSuffix>
          )}
        </Footer>
      )}
    </>
  );
};

export default React.memo(Component);

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const RightContainer = styled.div`
  position: relative;
  display: flex;
  width: 56%;
  height: 100%;
  flex-direction: column;
`;

export const LeftContainer = styled.div`
  padding: 0 15px 0 25px;
`;

export const SelectDateTimeContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;

  background: #ffffff;

  .ant-time-picker-input {
    background: #f7f7f7 !important;
    border: none;
  }

  .time-picker {
    background-color: #f7f7f7 !important;
  }
`;

export const Header = styled.div`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SetupTimeButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 109px;
  height: 32px;
  cursor: pointer;

  svg > path {
    stroke: ${globalStyleVariables.newBrandingPrimary200};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 4.5rem;
  align-items: center;
`;

export const Body = styled.div`
  display: flex;

  .loader {
    border: 6px solid rgb(83, 200, 46, 0.1);
    border-radius: 50%;
    border-top: 6px solid ${globalStyleVariables.newBrandingPrimary200};
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1.2s linear infinite; /* Safari */
    animation: spin 1.2s linear infinite;
  }
`;

export const Footer = styled.div`
  box-shadow: 7px -8px 19px rgba(19, 65, 98, 0.06);
  height: 88px;
  padding: 24px;

  button {
    cursor: pointer;
  }
`;

import React from 'react';
import { DropTarget } from 'react-dnd';

import { ItemTypes } from './ItemTypes';

interface IState {}
interface IProps {
  connectDropTarget: any;
  onHover?: (isOver: boolean, draggingItem?: any) => any;
  onHoverWithWaitingPatient?: any;
  onDropWithWaitingPatient?: any;
  onDrop?: any;
  disableHover?: any;
  providerId?: any;
  isOver: boolean;
  canDrop?: boolean;
  showHover?: boolean;
  draggingItem?: any;
}

const dropTarget = {
  canDrop(props: IProps, monitor: any) {
    const canDrop = !props.disableHover;
    return canDrop;
  },
  drop(props: IProps, monitor: any) {
    if (!props.onDropWithWaitingPatient) return;
    if (monitor.didDrop()) {
      return;
    }

    const item = monitor.getItem();
    var clientOffset = monitor.getClientOffset();
    if (item.itemType == ItemTypes.WaitingPatient) {
      props.onDropWithWaitingPatient(clientOffset, item);
      return;
    }
    const delta = monitor.getDifferenceFromInitialOffset();
    props.onDrop(item, delta, clientOffset);

    return { moved: true };
  }
};

function collect(connect: any, monitor: any) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
    draggingItem: monitor.getItem()
  };
}

class DropTargetComponent extends React.Component<IProps, IState> {
  componentDidUpdate(prevProps: IProps) {
    const { isOver, onHover, draggingItem } = this.props;
    if (prevProps.isOver !== isOver && onHover) {
      onHover(isOver, draggingItem);
    }
  }
  render() {
    const { connectDropTarget, children } = this.props;
    return connectDropTarget(
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%'
        }}
      >
        {children}
      </div>
    );
  }
}

export default DropTarget(
  () => ItemTypes.ScheduleEvent,
  dropTarget,
  collect
)(DropTargetComponent);

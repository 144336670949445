import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
`

export const Header = styled.div`
    padding: 20px 24px;
`

export const Body = styled.div`
    position: relative;
    display: flex;
    flex: 1;
`

export const Footer = styled.div`
    display: flex;
    border-top: 1px solid rgba(0,0,0,0.1);
    padding: 20px 24px;
`
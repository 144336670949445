import React from "react";

interface IProps {
}

export const BlockSvg = ({ }: IProps) => (
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.46436 9.53527L9.53527 2.46436" stroke="#2A97FF" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke="#2A97FF" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
);


import React from 'react';

export const SingleApptSvg = () => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.45">
      <path
        d="M7.16363 9.00001H0.830078V8.06164C0.830078 7.55296 1.11709 7.08744 1.57444 6.8646C2.10812 6.60459 2.92248 6.33325 3.99685 6.33325C5.07122 6.33325 5.88558 6.60459 6.41927 6.8646C6.87662 7.08744 7.16363 7.55296 7.16363 8.06164V9.00001Z"
        fill="#333333"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83012 2.8334C5.83012 3.84593 5.00926 5.00013 3.99672 5.00013C2.98419 5.00013 2.16333 3.84593 2.16333 2.8334C2.16333 1.82086 2.98419 1 3.99672 1C5.00926 1 5.83012 1.82086 5.83012 2.8334Z"
        fill="#333333"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

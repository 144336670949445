import React, { FC, useState } from 'react';
import moment from 'moment';
import { Row } from 'antd';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { getUserByCode } from 'src/Framework/Controls/Selectors/UserSelector/utils';
import tests_ids from 'src/tests-script/pages/Schedule/SingleApptPopup';

import { IPatientStatus } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import { LateSvg } from 'src/Activities/Schedule/Svg/LateSvg';
import { ArrivedSvg } from 'src/Activities/Schedule/Svg/ArrivedSvg';
import { ReadySvg } from 'src/Activities/Schedule/Svg/ReadySvg';
import { AdmittedSvg } from 'src/Activities/Schedule/Svg/AdmittedSvg';
import { DischargedSvg } from 'src/Activities/Schedule/Svg/DischargedSvg';

import Status from './Item';

interface IProps {
  isLate: string;
  status: IPatientStatus;
  onStatusChange: (data: any) => Promise<any>;
  isDisabled: boolean;
}

const Component: FC<IProps> = ({
  isLate,
  status,
  onStatusChange,
  isDisabled
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const onChange = async (data: any) => {
    if (loading) return;
    setLoading(true);
    await onStatusChange(data);
    setLoading(false);
  };
  return (
    <Row
      id={tests_ids.statusesContainer}
      align="middle"
      justify="space-between"
      style={{ margin: '15px 20px 0 20px', position: 'relative' }}
    >
      {loading && <AbsoluteLoader backgroundColor="rgba(255,255,255,0.8)" />}
      {[
        {
          active: isLate === 'active',
          tooltip: null,
          name: 'Late',
          time: '',
          icon: (
            <LateSvg
              stroke={
                isLate === 'active'
                  ? 'white'
                  : globalStyleVariables.newBrandingPrimary200
              }
            />
          )
        },
        {
          active: status?.checkedIn,
          tooltip: status?.checkedIn
            ? `by ${getUserByCode(status?.checkInUserCode)?.name}`
            : null,
          name: 'Arrived',
          time:
            status?.checkedIn &&
            moment(status?.checkedInTime).format('hh:mm a'),
          icon: (
            <ArrivedSvg
              stroke={
                status?.checkedIn
                  ? 'white'
                  : globalStyleVariables.newBrandingPrimary200
              }
            />
          )
        },
        {
          active: status?.ready,
          tooltip: status?.ready
            ? `by ${getUserByCode(status.readyUserCode)?.name}`
            : null,
          name: 'Ready',
          time: status?.ready && moment(status?.readyTime).format('hh:mm a'),
          icon: (
            <ReadySvg
              stroke={
                status?.ready
                  ? 'white'
                  : globalStyleVariables.newBrandingPrimary200
              }
            />
          )
        },
        {
          active: status?.admit,
          tooltip: status?.admit
            ? `by ${getUserByCode(status.admitUserCode)?.name}`
            : null,
          name: 'Admitted',
          time: status?.admit && moment(status?.admitTime).format('hh:mm a'),
          icon: (
            <AdmittedSvg
              stroke={
                status?.admit
                  ? 'white'
                  : globalStyleVariables.newBrandingPrimary200
              }
            />
          )
        },
        {
          active: status?.discharged,
          tooltip: status?.discharged
            ? `by ${getUserByCode(status.dischargeUserCode)?.name}`
            : null,
          name: 'Discharged',
          time:
            status?.discharged &&
            moment(status.dischargeTime).format('hh:mm a'),
          icon: (
            <DischargedSvg
              stroke={
                status?.discharged
                  ? 'white'
                  : globalStyleVariables.newBrandingPrimary200
              }
            />
          )
        }
      ].map((value, i) => (
        <Status
          key={i}
          value={value}
          onStatusChange={onChange}
          isDisabled={isDisabled}
        />
      ))}
    </Row>
  );
};

export default Component;

import { useEffect, useRef, useState } from 'react'

import { Cache } from 'src/Framework/CatchStateData';
import store from 'src/store';

export function useCacheState<S>(
  initialState: S | (() => S),
  key: string,
  // It could be cacheId!
  pathName?: string,
  disabled?: boolean
): [S, React.Dispatch<React.SetStateAction<S>>] {
  const url = useRef(pathName || window.location.pathname)
  const data = Cache.get(url.current);
  const cacheData = data?.[key] === undefined ? initialState : data?.[key];
  const state = useState(disabled ? initialState : cacheData);
  useEffect(() => {
    if (!disabled) {
      if (url.current === window.location.pathname) {
        const activities = store.getState().activity
        const activity = activities.find((v) => v.cacheId ? v.cacheId === url.current : v.targetURL === url.current && v.isCurrent === true)
        if (activity) {
          Cache.set(url.current, {
            [key]: state[0]
          });
        }
      }
    }

  }, [state[0]]);
  return state;
}

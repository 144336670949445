import React, { FC, useState } from 'react';
import { Popover } from 'antd';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import { IApptResponse } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import { containerId } from './GroupMembersDetail';

interface IProps {
  patient: any;
  appointment: IApptResponse;
  formsContent: (patientId: string | number, appointment: IApptResponse) => any;
}

const Component: FC<IProps> = ({
  patient,
  formsContent,
  appointment
}: IProps) => {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <Popover
        showArrow={false}
        getPopupContainer={(node: any) =>
          document.getElementById(containerId)
            ? document.getElementById(containerId)
            : node.parentNode
        }
        key={`forms-popup-${patient.patientId}`}
        id={`forms-popup-${patient.patientId}`}
        overlayStyle={{ zIndex: 50000 }}
        content={
          <div
            id="forms_popup"
            style={{
              padding: 16,
              height: 'auto',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {formsContent(patient.patientId, appointment)}
          </div>
        }
        trigger="click"
        open={visible}
        onOpenChange={(v) => setVisible(v)}
      >
        <ButtonIcon
          isButton={true}
          label="Forms list"
          haspopup="listbox"
          controlsId="forms_popup"
          name={ICONS_LIST.formChecked}
          disableBackground={true}
          id="forms-list"
          onClick={(e) => {
            e.stopPropagation();
            setVisible(true);
          }}
          active={visible}
          // containerProps={{
          //   'aria-describedby': `forms-popup-${patient.patientId}`
          // }}
        />
      </Popover>
    </div>
  );
};

export default Component;

import React, { FC } from 'react';

import Popover from 'src/Framework/Controls/Popover';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import { IScheduleEvent } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import CalendarSnap, {
  columnsNumberLimit
} from 'src/Activities/Schedule/Common/Editors/CalendarSnapShot/CalendarSnap';

import { ButtonContainer } from '../styled';

interface IProps {
  value: IScheduleEvent;
}

const previewPopupId = 'preview-popup-id';

const Component: FC<IProps> = ({ value }) => {
  const calendarData = {
    CLINIC: value.clinicName ? value.clinicName : value.clinicCode,
    DATE: value.startTime,
    DateValue: value.startTime,
    TIME: '',
    PROVIDER: [value.providerCode],
    Event: {
      ...value,
      sheduleEvent: {
        ...value
      }
    }
  };

  const popOverContent = () => {
    return (
      <CalendarSnap
        clinicOptions={[
          {
            label: value.clinicName ? value.clinicName : value.clinicCode,
            value: value.clinicCode,
            id: value.clinicId ? value.clinicId : 0
          }
        ]}
        providers={
          value.providers?.length
            ? value.providers.map((item) => ({
                value: item.code,
                label: item.name ? item.name : item.code,
                page: item.page
              }))
            : [
                {
                  value: value.provider
                    ? value.provider.code
                    : value.providerCode,
                  label: value.provider
                    ? value.provider.name
                    : value.providerName,
                  page: value.provider ? value.provider.page : 0
                }
              ]
        }
        searchLine={calendarData}
      />
    );
  };

  return (
    <ButtonContainer>
      <Popover
        placement="left"
        id="popover-content"
        trigger={'click'}
        content={
          <div
            id={previewPopupId}
            onMouseDown={(e: any) => e.preventDefault()}
            style={{
              width: value.providers?.length
                ? value.providers?.length >= columnsNumberLimit
                  ? columnsNumberLimit * 200 + 136
                  : value.providers?.length * 200 + 136
                : 336,
              height: 290
            }}
          >
            {popOverContent()}
          </div>
        }
      >
        <ButtonIcon
          isButton={true}
          label="preview"
          haspopup="dialog"
          controlsId={previewPopupId}
          name={ICONS_LIST.eye2}
          changeColorOnHover={true}
        />
      </Popover>
    </ButtonContainer>
  );
};

export default React.memo(Component);

import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const SearchIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Div = styled.div`
  display: flex;
`;

export const Drawer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1100;
  width: 170px;
`;
export const MainSideBarContainer = styled(Div) <{ opened?: boolean }>`
  background-color: ${globalStyleVariables.newBrandingPrimary300};
  display: flex;
  height: 100%;
  flex-direction: column;
  .drawer {
    margin-left: 5rem;
    left: -170px;
    right: unset;
    transition: left 0.3s ease;
    visibility: hidden;

    .highlight {
      background-color: ${({ opened }) => (opened ? 'white' : 'transparent')};
      color: ${({ opened }) =>
    opened
      ? globalStyleVariables.newBrandingPrimary300
      : globalStyleVariables.newBrandingMenuItem};
    }

    &.visible {
      left: 0;
      visibility: visible;
    }
  }
  ${({ opened }) => opened && `
    .drawer {
      visibility: visible;
      left: 0;

      .highlight {
        background-color: white;
        color: ${globalStyleVariables.newBrandingPrimary300};
      }
    }

    .navActive {
      border-radius: 18px 0 0 18px;
      margin-right: 0;
      padding-right: 7px;
      width: 43px;
    }

    .logoPicture {
      width: 138px;
    }
  `}
  &:hover {
    .arrowButton {
      opacity: 1;
    } 
    .logoPicture {
      opacity: 0;
    }
  }

`;

export const LogoWrapper = styled(Div)`
  display: flex;
  justify-content: flex-start;
  padding-left: 1rem;
  z-index: 1330;
  flex-shrink: 0;
  flex-basis: 42px;
  background-color: ${globalStyleVariables.newBrandingPrimary300};
`;

export const LogoPicture = styled.img`
  position: absolute;
  height: 30px;
  margin-top: 8px;
  overflow: hidden !important;
  width: 30px;
  object-fit: cover;
  object-position: left;
  transition: all 0.25s linear;
  &.visible {
    width: 138px;
    opacity: 1 !important;
  }
`;

export const BodyWrapper = styled(Div)`
  flex: 1;
  flex-direction: column;
  background: linear-gradient(0deg, #ffffff, #ffffff), #f8f8f8;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SmallMenuBar = styled(Div)`
  flex-direction: column;
  max-width: 50px;
  min-width: 50px;
  width: 50px;
  border-right: none;
  box-shadow: 10px 0 22px -6px rgba(210, 204, 204, 0.34);

  :hover {
    border-right: none;
    box-shadow: none;
  }
`;
export const MenuDiv = styled(Div)`
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  z-index: 1310;
  padding-top: 10px;
  background-color: ${globalStyleVariables.newBrandingPrimary300};
`;
export const MenuTopIcons = styled(Div)`
  flex-direction: column;
  align-items: flex-end;
`;
export const MenuTop = styled(Div)`
  flex-direction: column;
  padding-top: 10px;
`;

export const SearchBarDiv = styled(Div)`
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 10px 0 12px;
  padding: 2px 5px;
`;
export const SearchInput = styled.div`
  input {
    background-color: white !important;
    height: unset !important;
  }
`;

export const MenuBottom = styled(Div)`
  flex-direction: column;

  .user-block {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 2rem;
  }

  .user-block-text {
    margin: 0;
  }

  .user-name-block {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 2rem;
  }

  .white-label {
    color: white !important;
  }
`;
export const MenuItem = styled(Div)`
  height: 50px;
  min-height: 30px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin: 0;
  display: ${(props: { show: any }) =>
    props.show === 'true' ? 'flex' : 'none'};
`;

export const DPContainer = styled(Div)`
  border-radius: 50%;
  background-color: white;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: ${globalStyleVariables.newBrandingPrimary200};
  transition: all 0.25s linear;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: ${globalStyleVariables.newBrandingPrimary200};
  }
`;

interface IMenuItemSmall {
  show: any;
}

export const MenuItemSmall = styled(NavLink) <IMenuItemSmall>`
  height: 50px;
  min-height: 30px;
  display: ${({ show }) => (show === 'true' ? 'flex' : 'none')};
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  color: ${globalStyleVariables.newBrandingMenuItem};

  &.navActive {
    box-sizing: border-box;
    background-color: white;
    margin-top: 7px;
    margin-bottom: 7px;
    margin-left: 7px;
    height: 36px;
    color: ${globalStyleVariables.newBrandingPrimary300};
    border-radius: 50%;
    margin-right: 7px;
    padding-right: 0px;
    width: 36px;
    &.opened {
      border-radius: 18px 0 0 18px;
      margin-right: 0px;
      padding-right: 7px;
      width: 43px;
    }

    /* div > div {
      background-color: ${globalStyleVariables.newBrandingPrimary300};
    } */
  }
`;

export const ProfileIconContainer = styled.div`
  height: 32px;
  width: 32px;
  display: ${(props: { show: any }) =>
    props.show === 'true' ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  color: #cccccc;

  :hover {
    color: white;
  }

  &.navActive {
    color: #53c82e;
  }

  svg {
    stroke: currentColor;
  }
`;

export const BottomIconContainer = styled.div`
    border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  transition: all 0.25s linear;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    color: ${globalStyleVariables.newBrandingPrimary200};
    background-color: white;
  }
  &.active {
    color: ${globalStyleVariables.newBrandingPrimary200};
    background-color: white;
  }
`
export const MenuItemText = styled.span`
  font-size: 14px;
  display: flex;
  white-space: nowrap;
  justify-content: flex-start;
  height: 36px;
  line-height: 36px;
  width: 100%;
  margin-right: 7px;
  border-radius: 0 16px 16px 0;
  color: ${globalStyleVariables.newBrandingMenuItem};
  background-color: transparent;
`;

export const MenuItemTextClinic = styled(MenuItemText)`
  height: 20px;
`;

export const ClinicDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
`;

export const SideButton = styled.span`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover,
  :focus,
  :active {
    div > div {
      background: ${globalStyleVariables.newBrandingPrimary200};
    }
  }
`;

export const MenuItemSubText = styled.span`
  font-size: 11px;
  display: flex;
  justify-content: start;
  color: ${(props: { clinic?: any }) =>
    props.clinic === 'true'
      ? globalStyleVariables.newBrandingMenuItem
      : globalStyleVariables.newBrandingMenuItem};

  :hover {
    color: ${globalStyleVariables.newBrandingPrimary200};
  }
`;
export const LogoIcon = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  cursor: pointer;

  svg {
    width: 28px;
    height: 28px;
  }
`;
export const LogoIconText = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: ${globalStyleVariables.newBrandingPrimary300};
  z-index: 200;
  flex-shrink: 0;
  flex-basis: 42px;
  box-sizing: border-box;
`;
export const SidePopUPMenu = styled(Div)`
  flex-direction: column;
  transition: all 0.4s ease-out;
  width: 170px;
  height: 100%;
  background-color: #f0f0f0;
  z-index: 13;
`;
export const MenuBarDiv = styled(Div)`
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  background-color: ${globalStyleVariables.newBrandingPrimary300};

  .accessibility-menu {
    height: 45px;
  }
`;
export const VersionDiv = styled.div`
  position: relative;
  height: 32px;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: ${globalStyleVariables.newBrandingMenuItem};
`;

export const StatusPosition = styled.div`
  position: absolute;
  right: 5px;
  top: 18px;
  z-index: 5;
`;

export const ButtonContainer = styled.div<{ open: boolean }>`
  position: absolute;
  padding: 10px;
  height: 52px;
  z-index: 1331;
  left:0;
  transition: all 0.3s ease;
  opacity: 0;
  ${({ open }) => open && `
    left: 160px;
    opacity: 1 !important;
  `}
`

export const LogoutContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NavigationTooltipText = styled.div`
  font-size: 16px;
`
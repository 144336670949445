import * as React from 'react';

import {
  ScheduleGroupContainer,
  GroupTitle,
  GroupTitleStyle,
  GroupBkStyle,
  GroupStyleForTitle
} from './ScheduleGroup_Styles';

import { timeDiff } from 'src/Activities/Schedule/Utils/Utils';
import { ScheduleTimeProps } from 'src/Activities/Schedule/Common/Interfaces';

interface IState {}
type IProps = ScheduleTimeProps & {
  appointmentFor: string;
  isBlock: Boolean;
  color: string;
  startTime: string;
  endTime: string;
  duration: string;
  isReservation: boolean;
  onOpenReservationPopup: any;
  isShowingReservationLabel: boolean;
  scheduleGroup: any;
};

export class ScheduleGroupBk extends React.Component<IProps, IState> {
  openEditReservation(e: any, scheduleGroup: any) {
    e.preventDefault();
    this.props.onOpenReservationPopup(e, scheduleGroup);
  }
  render() {
    var {
      color,
      isBlock,
      scheduleGroup,
      regularMeetingTime,
      dayStartTime,
      duration,
      startTime,
      isReservation,
      appointmentFor: groupTitle
    } = this.props;
    var groupLen = Number(duration) / regularMeetingTime;
    var groupUIOffset = timeDiff(startTime, dayStartTime) / regularMeetingTime;

    if (isBlock && !isReservation) {
      groupTitle = this.props.appointmentFor;
    }

    if (groupTitle == 'Blank' || groupTitle == 'None') {
      return <div />;
    }
    return (
      <ScheduleGroupContainer
        onDoubleClick={(e: any) => this.openEditReservation(e, scheduleGroup)}
        style={GroupBkStyle(
          color,
          groupLen,
          groupUIOffset,
          groupTitle,
          isReservation
        )}
      ></ScheduleGroupContainer>
    );
  }
}

export class ScheduleGroupTitle extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  openEditReservation(e: any, scheduleGroup: any) {
    e.preventDefault();
    this.props.onOpenReservationPopup(e, scheduleGroup);
  }
  render() {
    var {
      startTime,
      isBlock,
      dayStartTime,
      scheduleGroup,
      appointmentFor: groupTitle,
      color,
      regularMeetingTime,
      isReservation,
      isShowingReservationLabel
    } = this.props;
    var groupUIOffset = timeDiff(startTime, dayStartTime) / regularMeetingTime;

    if (isBlock && !isReservation) {
      groupTitle = this.props.appointmentFor;
    }

    if (groupTitle == 'Blank' || groupTitle == 'None') {
      return <div />;
    }

    if (groupTitle && groupTitle.length > 35) {
      groupTitle = groupTitle.slice(0, 33) + '..';
    }
    return (
      <ScheduleGroupContainer
        style={GroupStyleForTitle(groupUIOffset, isReservation)}
        onDoubleClick={
          isReservation
            ? (e: any) => this.openEditReservation(e, scheduleGroup)
            : () => {}
        }
      >
        {groupTitle && groupTitle.length && isShowingReservationLabel ? (
          <GroupTitle style={GroupTitleStyle(groupTitle, color, isReservation)}>
            {groupTitle}
          </GroupTitle>
        ) : (
          ''
        )}
      </ScheduleGroupContainer>
    );
  }
}

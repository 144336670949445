import { useCallback, useMemo, useRef } from 'react';
import { useState } from 'react';

import * as Actions from '../Actions';

import { debouncer } from 'src/Framework/util/helps';
import { ISort } from 'src/Framework/Controls/Table/types';
import { IOrderManagerBasicFilters, IOrderManagerFilter, IOrderManagerPagination } from '../types';
import { useCacheState } from 'src/Framework/CatchStateData/hooks';
import { permittedClasses } from '../../utils';

type IData = IOrderManagerPagination

export const count = 30;

const defaultData = () => ({
  count: 20,
  startIndex: 0,
  result: [],
  total: 0
})

type IProps = {
  enableCache?: boolean
  patientId?: number
}

export const useOrderManager = ({ enableCache, patientId }: IProps) => {
  const debounce = useRef(debouncer(500));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useCacheState<IData>(defaultData(), `dataOrderManager-${patientId}`, undefined, enableCache ? false : true);

  const clearData = useCallback(() => {
    setData(defaultData())
  }, [])

  const debounceGet = useCallback((...args: Parameters<typeof get>) => {
    debounce.current(() => {
      get(...args);
    });
  }, []);

  const patch = useCallback(async (...args: Parameters<typeof Actions.patch>) => {
    const response = await Actions.patch(...args)
    if (response) {
      setData((data) => {
        const result = [...data.result]
        const i = result.findIndex((v) => v.reqOrderId === response.reqOrderId)
        if (i !== -1) {
          result[i] = response
        }
        return {
          ...data,
          result
        }
      })
      return response
    }
    return
  }, [])

  const get = useCallback(
    async ({
      pageNumber,
      sort,
    }: {
      pageNumber: number;
      sort: ISort;
    }, filters: Partial<IOrderManagerFilter>) => {
      setLoading(true);
      const request: IOrderManagerBasicFilters = {
        startIndex: pageNumber * count,
        count: count,
        ...sort
      };
      const filtersObj = {
        ...filters
      }
      if (patientId) {
        filtersObj.patientId = patientId
      }
      if (!filtersObj.txnCodeClassIds || filtersObj.txnCodeClassIds.length === 0) {
        filtersObj.txnCodeClassIds = permittedClasses()
      }
      const res = await Actions.get({
        basic: request,
        filters: filtersObj
      });
      if (res) {
        setData(res);
      } else {
        setData(defaultData())
      }
      setLoading(false);
    },
    []
  );

  const getById = useCallback(
    async ({
      reqOrderId
    }: {
      reqOrderId: number
    }) => {
      const request: IOrderManagerBasicFilters = {
        startIndex: 0,
        count: 10,
        sortBy: null,
        sortType: null,
        ids: [reqOrderId]
      };
      const res = await Actions.get({
        basic: request,
        filters: {}
      });
      const item = res?.result[0]
      if (item) {
        setData(data => {
          const result = [...data.result]
          const index = result.findIndex((v) => v.reqOrderId === item.reqOrderId)
          if (index !== -1) {
            result[index] = item
          }
          return {
            ...data,
            result
          }
        });
      }
      return item
    },
    []
  );

  const actions = useMemo(() => {
    return {
      get,
      debounceGet,
      patch,
      getById
    }
  }, [
    get,
    debounceGet,
    patch,
    getById
  ])

  return {
    /** Loading status */
    loading,
    // Pagination data
    data,
    // Requests
    actions,
    clearData
  };
};

import React, { FC } from 'react';
import { waitingListTagDefinition } from 'src/Activities/Schedule/RightSideBar/WaitingList/Group/GroupItem/StudentItem/Modal';
import Tags from 'src/App/Tags/Components/Tags';
import useTags from 'src/App/Tags/Hooks/useTags';

interface IProps {
  data: number[];
}

const Component: FC<IProps> = ({ data }: IProps) => {
  const { values: tagValues } = useTags({
    tagDefinitionId: waitingListTagDefinition
  });
  return (
    <Tags
      tags={data}
      values={tagValues}
      tagDefinitionId={waitingListTagDefinition}
      onChange={async () => {}}
      disableAddButton={true}
    />
  );
};

export default React.memo(Component);

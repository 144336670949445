import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { List, Row } from 'antd';

import store, { IStore } from 'src/store';
import Pagination from 'src/Framework/Controls/Pagination';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { loadActivityTab } from 'src/App/ActivityPanel/Store/ActivityActions';

import Container from '../components/Container';
import Header from '../components/Header';
import Tabs, { IData } from '../components/Tabs';
import { HeaderLink } from '../components/Header/styled';

import Item from './Item';

import { ListContainer, ListInnerContainer } from '../../styled';
import * as messagesActions from '../../../Messages/store/Actions';

interface IOwnProps {}

interface IProps extends IOwnProps {
  messagesReducer: IStore['messages'];
  provider: any;
}

const unreadInternalCategoryId = 'inbox:internal:sys2';
const unreadSecureCategoryId = 'inbox:secure:sys2';

const data: IData = [
  {
    label: 'Secure',
    value: 0
  },
  {
    label: 'Internal',
    value: 1
  }
];

const Component: FC<IProps> = ({
  messagesReducer,
  provider
}: IProps) => {
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageLength: 50
  });
  const [selected, setSelected] = useState<string | number>(0);
  const secure = messagesReducer.secureMessages.filter((v) =>
    v.folderIds.includes(unreadSecureCategoryId)
  );
  const internal = messagesReducer.internalMessages.filter((v) =>
    v.folderIds.includes(unreadInternalCategoryId)
  );
  const list = selected === 0 ? secure : internal;
  const { pageNumber, pageLength } = pagination;
  const paginationList = list.slice(
    pageNumber * pageLength,
    (pageNumber + 1) * pageLength
  );

  const onChangePagination = (obj: any) => {
    setPagination({
      ...pagination,
      ...obj
    });
  };

  useEffect(() => {
    messagesActions.setSelectedProviders([provider]);
  }, [provider]);

  return (
    <Container>
      <Row
        align="middle"
        justify="space-between"
        style={{ margin: '0 20px 0 0' }}
      >
        <Header>
          <HeaderLink
            onClick={() =>
              loadActivityTab(`/activity/messages`, store.dispatch)
            }
          >
            Unread Messages
          </HeaderLink>
        </Header>
        <Pagination
          data={list}
          pageNumber={pageNumber}
          pageLength={pageLength}
          dataLength={list.length}
          onChangePage={(pageNumber: number) =>
            onChangePagination({ pageNumber })
          }
          hideBorder={true}
        />
      </Row>
      <Tabs
        data={data.map((v) => ({
          ...v,
          label: `${v.label} (${
            v.value === 0 ? secure.length : internal.length
          })`
        }))}
        selected={selected}
        onChange={(value) => {
          setSelected(value);
        }}
      />
      {messagesReducer.loading || messagesReducer.loadingSecure ? (
        <AbsoluteLoader />
      ) : (
        <ListContainer>
          <ListInnerContainer>
            {paginationList.length === 0 ? (
              <List dataSource={[]} />
            ) : (
              paginationList.map((val) => <Item key={val.id} value={val} />)
            )}
          </ListInnerContainer>
        </ListContainer>
      )}
    </Container>
  );
};

 

const mapStateToProps = (state: IStore, ownProps: IOwnProps) => {
  return {
    messagesReducer: state.messages,
    provider: state.dashboard.filters.provider
  };
};

export default connect(mapStateToProps)(React.memo(Component));

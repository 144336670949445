import { IPatientContactsSimpleDto } from "src/Activities/Subject/Patients/store/types";
import { ApiUrl } from "src/Framework/Common/ApiUrl";
import { APIHandler } from "src/Framework/Communication/ServerProxy";

export const getPatientContacts = async (patientId: number): Promise<Record<string, IPatientContactsSimpleDto> | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(`${ApiUrl.PATIENT_CONTACTS}`, {
            withCredentials: true,
            params: {
                patientId
            }
        });
        if (response.data.success) {
            const contacts = response.data.result.summary
            return contacts
        }
        return
    } catch (e) {
        return
    }
}
import React, { FC, useRef } from 'react';
import { Common } from './DndDragSource';
import { TotalDragSourceStyle } from './ScheduleCol_Styles';
import { CALENDAR_ZINDEX } from './utils';
import { useInteractionObserver } from 'src/Framework/InteractionObserver/useInteractionObserver';

type IProps = Common & {
  children: React.ReactElement;
  isRelative?: boolean;
  id?: string;
  isFake?: boolean;
};

const Component: FC<IProps> = ({
  children,
  x,
  y,
  width,
  height,
  isRelative,
  isDragging,
  patient,
  lastInReservation,
  firstInReservation,
  mainScrollContainerRef,
  id,
  isFake
}: IProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isVisible } = useInteractionObserver({
    targetRef: ref,
    root: mainScrollContainerRef
  });
  return (
    <div
      id={id}
      ref={ref}
      style={{
        ...TotalDragSourceStyle(
          x,
          y,
          width,
          height,
          patient,
          lastInReservation,
          firstInReservation
        ),
        zIndex: isDragging ? 100 : CALENDAR_ZINDEX.events,
        position: isRelative ? 'relative' : 'absolute',
        backgroundColor: isFake ? 'transparent' : 'white'
      }}
    >
      {isVisible ? children : null}
    </div>
  );
};

export default React.memo(Component);

import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { useAppSelector } from 'src/store';

import SelectContainer from '../../SelectContainer';
import { IAutoComplete } from 'src/Framework/Controls/SelectContainer/Components/AutoComplete';

import { Container } from './styled';

interface IProps {
  value: any;
  onChange: (value: string | null) => void;
  selectProps?: Partial<IAutoComplete>;
  disabled?: boolean;
  mandatory?: boolean;
}

const Component: FC<IProps> = ({
  onChange,
  value,
  selectProps,
  disabled,
  mandatory
}: IProps) => {
  const referrals = useAppSelector((state) => state.user.referrals.summary);
  const [search, setSearch] = useState('');
  const data = Object.values(referrals).filter((v) => v.isActive);
  const options = _.sortBy(
    data.map((value) => ({
      label: `${value.firstName} ${value.lastName}`,
      value: `${value.id}`
    })),
    'label'
  );

  useEffect(() => {
    const find = options.find((v) => v.value === value);
    if (find) {
      setSearch(`${find.label}`);
    }
  }, [value]);

  return (
    <Container
      className={cn({
        disabled: disabled
      })}
    >
      <SelectContainer
        star={mandatory}
        label="Referrals"
        type="auto-complete"
        value={search}
        onChange={(e: any, option: any) => {
          onChange(option.value);
          setSearch(option.label);
        }}
        onSearch={(search: string) => {
          if (!search) {
            onChange(null);
          }
          setSearch(search);
        }}
        options={options.filter((v) =>
          v.label.toLowerCase().includes(search.toLowerCase())
        )}
        inputProps={{
          allowClear: true
        }}
        {...selectProps}
      />
    </Container>
  );
};
export default Component;

import React from "react";

interface IProps {
    stroke?: string;
    height?: string;
    width?: string;
}

export const LateSvg = ({ stroke, height, width }: IProps) => (
    <svg width={width || "20"} height={height || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 5V10H13.5" stroke={stroke || "#666666"} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" 
        stroke={stroke || "#666666"} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

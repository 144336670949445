import React, { FC } from 'react';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

interface IProps {
  onClick: () => any;
}

const Component: FC<IProps> = ({onClick}: IProps) => {
  return (
    <ButtonIcon
      tooltip="Manage Results"
      changeColorOnHover={true}
      name={ICONS_LIST.nurseOrders}
      onClick={onClick}
    />
  );
};

export default React.memo(Component);

import React from "react";

export const ApptConflictSvg = () =>
(
<svg width="34" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.3999 14V19.6" stroke="#666666" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.2 16.8H17.6" stroke="#666666" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.4 23.8H4.79995C3.91675 23.8 3.19995 23.1728 3.19995 22.4L3.19995 6.99998C3.19995 6.22718 3.91675 5.59998 4.79995 5.59998H24C24.8832 5.59998 25.6 6.22718 25.6 6.99998V11.2" stroke="#666666" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3.19995 9.79999H25.6" stroke="#666666" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.59985 2.79999V5.59999" stroke="#666666" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.2 2.79999V5.59999" stroke="#666666" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M23.9998 17.08V17.4069V19.768" stroke="#FF2828" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M23.9998 21.616V21.6262V21.7" stroke="#FF2828" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.2798 23.8L23.9998 13.72L30.7198 23.8H17.2798Z" stroke="#FF2828" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

);
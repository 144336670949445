import React from 'react';

export const EyeIconSnapShotSvg = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#F7F7F7" />
    <path
      d="M8.5 16C8.5 16 11.5 10.5 16 10.5C20.5 10.5 23.5 16 23.5 16C23.5 16 20.5 21.5 16 21.5C11.5 21.5 8.5 16 8.5 16Z"
      stroke="#3A5B8B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 18.5C17.3807 18.5 18.5 17.3807 18.5 16C18.5 14.6193 17.3807 13.5 16 13.5C14.6193 13.5 13.5 14.6193 13.5 16C13.5 17.3807 14.6193 18.5 16 18.5Z"
      stroke="#3A5B8B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from 'react';
import { useEffect, useState } from 'react';

import Spinner from 'src/Framework/Controls/AbsoluteLoader/Spinner';
import { useAppSelector } from 'src/store';

import { LoadingContainer } from '../../styled';

import * as actions from '../Actions';
import * as Setters from '../Actions/Setters';

import ApiSubscription from 'src/Framework/ApiSubscription';

export const useCheckInStatus = () => {
  const [loading, setLoading] = useState(false);
  const data = useAppSelector(
    (state) => state.adminPanel.checkInStatus.summary
  );
  useEffect(() => {
    const init = async () => {
      const pathKey = actions.generateSubscriptionPath();
      const alreadySubscribed =
        ApiSubscription.instance.subscribedPaths[pathKey];
      if (alreadySubscribed) {
        return;
      }
      setLoading(true);
      const res = await actions.get();
      if (res?.success) {
        Setters.setSummary(res.data);
      }
      setLoading(false);
    };
    init();
  }, []);
  const genericType = Object.values(data);
  return {
    /** Loading status */
    loading,
    /**
     * List of all generic types, includes inActive
     */
    allGenericTypes: genericType,
    /**
     * List of active generic types
     */
    genericTypes: genericType.filter((v: any) => {
      if (v.hasOwnProperty('isActive')) {
        return v.isActive;
      }
      return true;
    }),
    /**
     *
     * @param value used for finding element
     * @param field by what field need to find element
     * @default id
     * @return Element
     */
    finder: (value: any, field?: keyof (typeof genericType)[number]) => {
      const fieldName = field || 'id';
      const i = genericType.findIndex((v) => v[fieldName] === value);
      if (i !== -1) {
        return genericType[i];
      }
      return null;
    },
    /**
     * Method will give you loader component when data still in pending and value that our need after
     * @param value
     * @param findValue
     * @param byField - default "id"
     * @returns
     */
    loadingFinder: (
      value: any,
      findValue: keyof (typeof genericType)[number],
      byField?: keyof (typeof genericType)[number]
    ): string | null | React.ReactNode => {
      if (loading) {
        return (
          <LoadingContainer>
            <Spinner size={14} />
          </LoadingContainer>
        );
      }
      const fieldName = byField || 'id';
      const i = genericType.findIndex((v) => v[fieldName] === value);
      if (i !== -1) {
        return genericType[i][findValue] || '';
      }
      return null;
    }
  };
};

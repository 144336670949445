import _ from 'lodash'
import {
    ITypeRisksItem,
    riskGroups
} from 'src/App/Admin/Pages/TypeRisks/store/types';
import { IPatient } from '../store/types';

type Item = {
    risk: ITypeRisksItem,
    patients: IPatient[]
} | null
type IArrItem = Item[]
type IArr = IArrItem[]

export const func = (typeRisks: ITypeRisksItem[], obj: {
    [key: string]: IPatient[]
}, arr: IArr): IArr => {
    const a: IArrItem = [null, null, null];
    let listOfRisks = [...typeRisks.filter((v) => v.isActive && v.isEnabled)];
    const group = _.groupBy(listOfRisks, (v) => v.riskGroup)
    riskGroups.forEach((r, index) => {
        if (group[r]) {
            const risk = group[r][0]
            if (risk) {
                listOfRisks = listOfRisks.filter((v) => v.id !== risk.id)
                a[index] = { risk, patients: obj[risk.id] || [] }
            }
        }
    })
    arr.push(a);
    if (listOfRisks.length !== 0) {
        return func(listOfRisks, obj, arr);
    }
    return arr;
};
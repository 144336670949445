import React, { FC, useRef, useMemo } from 'react';
import { Col, Row } from 'antd';
import cn from 'classnames';
import _ from 'lodash';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';

import {
  IAppointment,
  IGroup
} from 'src/Activities/Schedule/RightSideBar/WaitingList/Group/types';
import { TypeListEnum } from 'src/Activities/Schedule/WaitingListSettings/store/types';
import AppointmentPopup, {
  IModalHandles as IAppointmentPopupModalHandles
} from 'src/Activities/Schedule/Popups/AppointmentPopup/Container';
import { IOnChangeComment } from '../Comments';
import WLModal, { IModalHandles as WLModalHandles } from './WLModal';

import { MoreIcon } from '../styled';

interface IOwnProps {
  data: IGroup[];
  value: IAppointment;
  group: IGroup;
  updateWaitingList: Function;
  onChangeComment: IOnChangeComment;
}

interface IProps extends IOwnProps {}

export const waitingListTagDefinition = 10;

const Component: FC<IProps> = (props: IProps) => {
  const appointmentRef = useRef<IAppointmentPopupModalHandles>(null);
  const WLModalRef = useRef<WLModalHandles>(null);
  const { value, group, data, updateWaitingList, onChangeComment } = props;

  const listUpdateCallback = (apptData: any) => {
    const groups = _.cloneDeep(data);
    const i = groups.findIndex((v) => v.id === group.id);
    if (i !== -1) {
      const index = groups[i].patients.findIndex((v) => v.uid === apptData.uid);
      if (index !== -1) {
        const dataToSend = {
          ...apptData,
          subjectId: apptData?.subjectId || apptData.patientInfo.patientId,
          note: apptData.note || '',
          name: apptData?.patientInfo?.patientName || ''
        };
        groups[i].patients[index] = dataToSend;
      }
    }
    updateWaitingList(groups);
  };

  const statusIconName = useMemo(() => {
    if (group.typeList === TypeListEnum.triage) {
      const status = value.patientInfo.patientStatus;
      if (status.discharged) {
        return 'check';
      }
      if (status.admit) {
        return 'admitted';
      }
      if (status.ready) {
        return 'ready';
      }
      if (status.checkedIn) {
        return 'arrived';
      }
      return 'late';
    } else {
      return 'edit';
    }
  }, [value]);

  const isTriage = group.typeList === TypeListEnum.triage;
  return (
    <>
      <AppointmentPopup ref={appointmentRef} />
      <Row align="middle" gutter={4} wrap={false}>
        <Col>
          <MoreIcon
            className={cn({
              visible: false
            })}
          >
            <ButtonIcon
              isButton={true}
              size={isTriage ? 24 : 20}
              label={isTriage ? 'Change status' : 'Edit Roster'}
              tooltip={isTriage ? 'Change status' : 'Edit Roster'}
              haspopup="dialog"
              controlsId="edit-member-modal"
              onClick={() => {
                WLModalRef.current?.show({});
              }}
              name={ICONS_LIST[statusIconName]}
            />
          </MoreIcon>
        </Col>
        {isTriage && (
          <Col>
            <ButtonIcon
              isButton={true}
              size={20}
              label="Edit Triage"
              tooltip="Edit Triage"
              haspopup="dialog"
              controlsId="edit-member-modal"
              onClick={() => {
                appointmentRef.current?.show({
                  options: {
                    uid: `${value.appointmentId}`,
                    action: 'edit',
                    isTriage: true,
                    triageUpdateCallback: listUpdateCallback
                  }
                });
              }}
              name={ICONS_LIST.edit}
            />
          </Col>
        )}
      </Row>
      <WLModal
        ref={WLModalRef}
        data={data}
        group={group}
        value={value}
        onChangeComment={onChangeComment}
        updateWaitingList={updateWaitingList}
      />
    </>
  );
};

export default Component;

import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import Popover from 'src/Framework/Controls/Popover';
import Button from 'src/Framework/Controls/Button';

import {
  BarContainer,
  ConflictsContainer,
  PopoverContainer,
  ActionButtonContainer
} from './ConflictsBar_Styles';
import { AlertsBarIcon } from '../Svg/AlertsBarIcon';
import { AlertsEyeIcon } from '../Svg/AlertsEyeIcon';
import { conflictsContent, IConflictsContentProps } from './ConflictsContent';

interface IProps {
  conflictsProps: IConflictsContentProps;
  conflictsOverride: () => any;
  message: string;
  disableOverride?: boolean;
  onEditConflicts: any;
  isInSeries?: boolean;
}

interface IViewButtonProps {
  conflict?: boolean;
  active: boolean;
  onClick: () => void;
}

interface IPopOverContentProps {
  action: () => void;
  content: any;
  conflicts: number;
  disableOverride?: boolean;
  isInSeries?: boolean;
}

const barContainerId = 'conflicts-appointment-popup';

const ViewButton = ({ ...props }: IViewButtonProps) => (
  <Button
    id="conflics-view-button"
    disableClickLoader={true}
    colorStyles={{
      redBorder: true
    }}
    className={classnames({
      'view-conflicts-button': true,
      'view-conflicts-button-active': props.active
    })}
    aria-pressed={props.active}
    onClick={() => props.onClick()}
  >
    <AlertsEyeIcon conflict active={props.active} />
    <span className={'button'}>View</span>
  </Button>
);

const PopOverContent = ({
  disableOverride,
  ...props
}: IPopOverContentProps) => {
  return (
    <PopoverContainer>
      <ConflictsContainer>{props.content}</ConflictsContainer>
      {!disableOverride && (
        <ActionButtonContainer>
          <Button
            id="override-conflict-button"
            disableClickLoader={true}
            colorStyles={{
              redBorder: true
            }}
            className={'override-conflicts-button'}
            onClick={() => props.action()}
          >
            {props.isInSeries ? 'Edit conflicts' : 'Override conflicts'}
          </Button>
        </ActionButtonContainer>
      )}
    </PopoverContainer>
  );
};

const ConflictsBar = ({ disableOverride, ...props }: IProps) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const { conflicts } = props.conflictsProps;
  useEffect(() => {
    const container = document.getElementById(barContainerId);
    if (container) {
      container.focus();
    }
  }, []);

  const conflictsNumber = conflicts.conflictResponse.length;

  const handleOpenTooltip = (visible: boolean) => {
    setOpenTooltip(visible);
  };

  return (
    <BarContainer id={barContainerId} tabIndex={0}>
      <div style={{ display: 'flex' }}>
        <AlertsBarIcon conflict />
        <div tabIndex={0}>
          <span className="count">{conflictsNumber}</span>
          <span> {props.message}</span>
        </div>
      </div>
      <Popover
        getPopupContainer={(node: any) =>
          document.getElementById('conflicts-appointment-popup')
            ? document.getElementById('conflicts-appointment-popup')
            : node.parentNode
        }
        key={`alerts-popup`}
        id={`alerts-popup`}
        overlayStyle={{ zIndex: 50000, height: '415px' }}
        placement="bottomRight"
        destroyTooltipOnHide={true}
        content={
          <PopOverContent
            action={() => {
              handleOpenTooltip(false);
              if (props.isInSeries) {
                props.onEditConflicts();
              } else {
                props.conflictsOverride();
              }
            }}
            content={conflictsContent(props.conflictsProps)}
            conflicts={conflictsNumber}
            disableOverride={disableOverride}
            isInSeries={props.isInSeries}
          />
        }
        trigger="click"
        open={openTooltip}
        onOpenChange={(visible: boolean) => handleOpenTooltip(visible)}
      >
        <ViewButton
          active={openTooltip}
          conflict
          onClick={() => handleOpenTooltip(true)}
        />
      </Popover>
    </BarContainer>
  );
};

export default ConflictsBar;

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import {
  getClinicByCode,
  getClinics
} from 'src/Framework/Controls/Selectors/ClinicSelector';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import store, { IStore } from 'src/store';

import {
  updateBlock,
  createScheduleEvent
} from '../../Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import { ApiUrl } from '../../Store/ScheduleActions/ScheduleDetailActions/ApiUrl';
import { initReleaseBeforeInterval } from '../../Store/Models/ScheduleDefinition';
import {
  getScheduleBlockAppointment,
  removeScheduleEvent
} from '../../Store/ScheduleEventsReducer/Actions';
import { IScheduleClinic } from '../../Store/ScheduleReducers/ScheduleDetailsReducer/types';
import { IApptResponse } from '../../Store/ScheduleEventsReducer/types';
import { createNewEventPayload } from '../../Utils/Utils';
import { setEventDuration } from '../AppointmentPopup/utils';
import BlockReservationPopup from './BlockReservationPopup';

import { Container } from './BlockReservationPopup_Styles';

// import { updateEventsOnAction } from '../../Store/ScheduleSliceEventsActions';
interface IState {
  groupReasons: any;
  conflicts: any;
  canAdd: boolean;
  reasons: any;
  eventInfo: IApptResponse | null;
  loading: boolean;
}

interface IOptions {
  endTime: string;
  date: string;
  scheduleEventTypeId: number;
  default?: boolean;
  clinicCode?: string;
  reason?: any;
  updateSeries?: boolean;
}

export interface IActionOptions extends Partial<IOptions> {
  appointmentBlockId: string;
  action: string;
  startTime: string;
}

interface IOwnProps {
  onEventSaved?: (newEventPayload: any, response: any, uid: any) => void;
  onEventDeleted?: (newEventPayload: any, response: any) => void;
  onClosePopup: () => void;
  actionOption: IActionOptions;
}

export interface IProps extends IOwnProps {
  providers: any;
  clinics: IScheduleClinic[];
}

class BlockReservationPopupContainer extends React.Component<IProps, IState> {
  state: IState = {
    reasons: [],
    canAdd: false,
    groupReasons: [],
    eventInfo: null,
    loading: false,
    conflicts: {
      hasConflicts: false,
      message: '',
      reasonCode: null,
      conflictResponse: []
    }
  };
  getApptEvent = async () => {
    const ownProps = this.props;
    this.setState({ loading: true });
    const { actionOption } = this.props;
    const eventInfo = await getScheduleBlockAppointment({
      appointmentBlockId: actionOption.appointmentBlockId,
      startTime: actionOption.startTime
    });
    if (eventInfo) {
      let occuranceStartDate = moment(
        ownProps.actionOption.date ??
          eventInfo.scheduleEvent.scheduleEventDateTime
      );
      let occuranceStartDateTime =
        occuranceStartDate.format('YYYY-MM-DD') +
        'T' +
        moment(eventInfo.scheduleEvent.scheduleEventDateTime).format('HH:mm') +
        ':00';
      eventInfo.scheduleEvent.scheduleEventDateTime = occuranceStartDateTime;
      if (
        !eventInfo['scheduleEvent']['scheduleEventBlock'][
          'releaseBeforeInterval'
        ]
      ) {
        eventInfo['scheduleEvent']['scheduleEventBlock'][
          'releaseBeforeInterval'
        ] = _.cloneDeep(initReleaseBeforeInterval);
      }
      this.setState({
        eventInfo
      });
    }
    this.setState({ loading: false });
  };

  componentDidMount() {
    const clinics = getClinics();
    const { actionOption } = this.props;
    if (actionOption.action === 'edit') {
      this.getApptEvent();
    } else {
      const options = actionOption;
      if (!options.clinicCode) {
        options.clinicCode = clinics[0]?.code;
      }
      let newEventPayload = createNewEventPayload(options);
      const selectedClinic = getClinicByCode(
        newEventPayload.scheduleEvent.clinicCode
      );
      // newEventPayload.scheduleEvent.reason = options.reason;
      if (options.reason && selectedClinic) {
        if (newEventPayload.scheduleEvent.scheduleEventDateTime) {
          if (options.reason.duration > 0) {
            newEventPayload = setEventDuration(
              selectedClinic,
              newEventPayload,
              newEventPayload.scheduleEvent.scheduleEventDateTime,
              options.reason.duration
            );
          }
        }
        let inSeries =
          newEventPayload.scheduleEvent.series?.typeFrequencyId > 0;
        if (!newEventPayload.scheduleEvent.series.endTime || !inSeries) {
          newEventPayload.scheduleEvent.series.endTime =
            newEventPayload.scheduleEvent.endTime;
        }
      }
      this.setState({
        eventInfo: newEventPayload
      });
    }
  }

  addSliceEvent(event: any, newEventPayload: any) {
    //Update a store
  }

  onDeleteEvent = (oldEventPayload: any) => {
    const { actionOption } = this.props;
    let details = {
      appointmentBlockId: oldEventPayload.scheduleEvent.appointmentBlockId,
      scheduleEventTypeId: oldEventPayload.scheduleEvent.scheduleEventTypeId,
      cancelSeries: actionOption.updateSeries,
      cancelMulti: true,
      cancelDate:
        moment(oldEventPayload.scheduleEvent.scheduleEventDateTime).format(
          'YYYY-MM-DD'
        ) + 'T00:00:00.000'
    };
    if (details) {
      APIHandler.AxiosInstance.post(
        ApiUrl.DELETE_SCHEDULE_EVENT,
        {
          ...details
        },
        { withCredentials: true }
      )
        .then((response) => {
          let data: any = response.data;
          if (data.success) {
            if (this.props.onEventDeleted) {
              this.props.onEventDeleted(oldEventPayload, response.data.result);
            }
            this.props.onClosePopup();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  onUpdateEvent = async (newEventPayload: any, oldEvenPayload: any) => {
    const { actionOption } = this.props;
    newEventPayload.updateSeries = actionOption.updateSeries;
    newEventPayload['dts'] = new Date();
    this.setState({
      canAdd: true,
      conflicts: {
        hasConflicts: false,
        message: '',
        reasonCode: null,
        conflictResponse: []
      }
    });
    try {
      const response = await updateBlock({
        ...newEventPayload,
        startTime: actionOption.startTime
      });
      if (response.data.success) {
        if (!response.data.result.hasConflicts) {
          // updateEventsOnAction(response.data.result);
          if (this.props.onEventSaved) {
            this.props.onEventSaved(
              response.data.result,
              newEventPayload,
              oldEvenPayload
            );
            this.setState({
              canAdd: false
            });
          }
          this.props.onClosePopup();
          store.dispatch(removeScheduleEvent(newEventPayload.uid));
        } else {
          let conflictResponse = response.data.result.conflicts;
          newEventPayload['conflictsResponses'] = conflictResponse;
          this.setState({
            conflicts: {
              hasConflicts: true,
              message:
                response.data.result.reasonCode !== 4
                  ? response.data.result.message
                  : 'Type doesnt match',
              conflictResponse: conflictResponse
            },
            canAdd: false
          });
        }
      } else {
        console.log('updateBlock response success false');
      }
    } catch (e) {
      console.log('updateBlock error', e);
    }
  };

  onCreateEvent(newEventPayload: any) {
    newEventPayload['dts'] = new Date();
    this.setState({
      canAdd: true,
      conflicts: {
        hasConflicts: false,
        message: '',
        reasonCode: null,
        conflictResponse: []
      }
    });
    createScheduleEvent(newEventPayload)
      .then((response) => {
        if (response.data.success) {
          if (!response.data.result.hasConflicts) {
            // updateEventsOnAction(response.data.result);
            if (this.props.onEventSaved) {
              this.props.onEventSaved(
                response.data.result,
                newEventPayload,
                this.state.eventInfo
              );
              this.setState({
                canAdd: false
              });
            }
            this.props.onClosePopup();
          } else {
            let conflictResponse = response.data.result.conflicts;
            newEventPayload['conflictsResponses'] = conflictResponse;
            this.setState({
              conflicts: {
                hasConflicts: true,
                message:
                  response.data.result.reasonCode !== 4
                    ? response.data.result.message
                    : 'Type doesnt match',
                reasonCode: response.data.result.reasonCode,
                conflictResponse: conflictResponse
              },
              canAdd: false
            });
          }
        } else {
          console.log('createScheduleEvent response success false');
        }
      })
      .catch((error) => {
        console.log('createScheduleEvent error', error);
      });
  }

  onSaveEvent(newEventPayload: any, oldEvenPayload: any) {
    if (this.props.actionOption?.action === 'edit') {
      this.onUpdateEvent(newEventPayload, oldEvenPayload);
    } else {
      this.onCreateEvent(newEventPayload);
    }
  }

  render() {
    let { groupReasons, loading } = this.state;
    return (
      <Container>
        {loading && <AbsoluteLoader />}
        {this.state.eventInfo && this.state.eventInfo?.scheduleEvent ? (
          <BlockReservationPopup
            key={'block_reservation_popup'}
            {...this.props}
            action={this.props.actionOption?.action}
            eventInfo={this.state.eventInfo}
            groupReasons={groupReasons}
            conflicts={this.state.conflicts}
            canAdd={this.state.canAdd}
            onSaveEvent={(newEventPayload: any, oldEventPayload: any) =>
              this.onSaveEvent(newEventPayload, oldEventPayload)
            }
            onDeleteEvent={(oldEventPayload: any) =>
              this.onDeleteEvent(oldEventPayload)
            }
          />
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: IOwnProps) => {
  return {
    clinics: state.scheduleDetails.clinics,
    providers: state.schedule.clinicProviders.data
  };
};
export default connect(mapStateToProps)(BlockReservationPopupContainer);

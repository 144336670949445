import React, { FC, useState, useRef } from 'react';

import Popover from 'src/Framework/Controls/Popover';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';

import ApptPopup from 'src/Activities/Schedule/Popups/ScheduleDetailPopup';
import GroupApptPopup from 'src/Activities/Schedule/Popups/ScheduleGroupPopup';
import EventPopup, {
  IEventModalHandles
} from 'src/Activities/Schedule/Popups/EventPopup';
import EventInfoPopup from 'src/Activities/Schedule/Popups/EventPopup/EventInfoPopup';
import AppointmentPopup, {
  IModalHandles
} from 'src/Activities/Schedule/Popups/AppointmentPopup/Container';
import { ApiUrl } from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ApiUrl';
import { updateEventsOnAction } from 'src/Activities/Schedule/Store/ScheduleSliceEventsActions';

import OutreachModal, {
  IModalHandles as IOutreachModal
} from 'src/Activities/Schedule/Popups/Outreach/Modal';

import { ButtonContainer } from '../styled';
import { PopoverContainer } from './styled';

interface IProps {
  id: number;
  isOutreach: boolean;
  isEvent?: boolean;
  isGroupAppt: boolean;
  setRecordChanged: () => void;
  currentProviderId?: number;
}

const popoverId = 'outreach-popover';

const Component: FC<IProps> = ({
  id,
  isOutreach,
  isGroupAppt,
  setRecordChanged,
  isEvent,
  currentProviderId
}) => {
  const ApptPopupRef = useRef<IModalHandles>(null);
  const EventPopupRef = useRef<IEventModalHandles>(null);
  const OutreachModalRef = useRef<IOutreachModal>(null);
  const EditButtonRef = useRef(null);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const deleteHandler = async (
    details: any,
    singleSubject?: boolean,
    callback = (data: any, error: any) => {}
  ) => {
    try {
      const response = await APIHandler.AxiosInstance.post(
        ApiUrl.DELETE_SCHEDULE_EVENT,
        {
          ...details
        },
        { withCredentials: true }
      );
      let data: any = response.data;
      callback(data, null);
      if (data.success) {
        const { result } = data;
        updateEventsOnAction(result);
        setRecordChanged();
        return result;
      }
    } catch (error) {
      console.log(error);
      callback(null, error);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      (e.target as HTMLElement).click();
    }
    if (popupVisible && !e.shiftKey && e.key === 'Tab') {
      e.preventDefault();
    }
  };

  return (
    <ButtonContainer>
      <Popover
        globalOverlay={true}
        id={popoverId}
        open={popupVisible}
        destroyTooltipOnHide
        onOpenChange={(visible: boolean) => {
          if (popupVisible) {
            setPopupVisible(visible);
          }
        }}
        overlayClassName="schedule-outreach-popup"
        trigger={'click'}
        placement="leftBottom"
        arrowContent={<div />}
        content={
          <PopoverContainer>
            {isEvent ? (
              <EventInfoPopup
                eventId={id}
                currentProviderId={currentProviderId}
                onClosePopup={() => {
                  setPopupVisible(false);
                }}
                onDelete={() => {
                  setRecordChanged();
                }}
                setRecordChanged={setRecordChanged}
                onEditSchedule={(eventId, isSeries) => {
                  EventPopupRef.current?.show({
                    options: {
                      action: 'edit',
                      eventId,
                      updateSeries: isSeries
                    }
                  });
                }}
              />
            ) : isGroupAppt ? (
              <GroupApptPopup
                callerRef={EditButtonRef}
                onClosePopup={() => {
                  setPopupVisible(false);
                }}
                onDelete={deleteHandler}
                setRecordChanged={setRecordChanged}
                onEditSchedule={(id, series) => {
                  ApptPopupRef.current?.show({
                    options: {
                      action: 'edit',
                      uid: `${id}`,
                      updateSeries: series
                    }
                  });
                }}
                appointmentId={id}
              />
            ) : (
              <ApptPopup
                callerRef={EditButtonRef}
                onClosePopup={() => {
                  setPopupVisible(false);
                }}
                setRecordChanged={setRecordChanged}
                onDelete={deleteHandler}
                onEditSchedule={(id, series) => {
                  ApptPopupRef.current?.show({
                    options: {
                      action: 'edit',
                      uid: `${id}`,
                      updateSeries: series
                    }
                  });
                }}
                appointmentId={id}
              />
            )}
          </PopoverContainer>
        }
      >
        <ButtonIcon
          isButton={true}
          label="edit appointment"
          haspopup="dialog"
          controlsId={popoverId}
          iconScale={2}
          name={ICONS_LIST.edit}
          changeColorOnHover={true}
          onClick={() => {
            if (isOutreach) {
              OutreachModalRef.current?.show({
                outreachId: id
              });
            } else {
              setPopupVisible(true);
            }
          }}
          containerProps={{
            ref: EditButtonRef,
            onKeyDown: (e: React.KeyboardEvent) => onKeyDown(e)
          }}
        />
      </Popover>
      {isOutreach && (
        <OutreachModal ref={OutreachModalRef} id={id} onClose={() => {}} />
      )}

      <AppointmentPopup
        ref={ApptPopupRef}
        onEventSaved={() => setRecordChanged()}
      />
      <EventPopup ref={EventPopupRef} />
    </ButtonContainer>
  );
};
export default React.memo(Component);

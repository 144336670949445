import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const TimeText = styled.div`
  font-size: 12px;
  margin-left: 4px;
  color: ${globalStyleVariables.newBrandingPrimary200};
`;

export const PatientIconContainer = styled.div`
  background-color: transparent;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const DeleteIconContainer = styled.span`
  background-color: transparent;
  width: 100%;

  svg > path {
    fill: #ffffff;
  }

  :hover {
    svg > path {
      fill: rgb(255, 40, 40);
    }
  }
`;
export const CreateNoteIconContainer = styled.div`
  background-color: transparent;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

interface AttachedFormsContainerProps {
  active: boolean;
}

export const AttachedFormsContainer = styled.div`
  border-radius: 50%;
  height: 28px;
  width: 28px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props: AttachedFormsContainerProps) =>
    props.active ? globalStyleVariables.newBrandingPrimary200 : 'none'};

  svg > path {
    stroke: ${(props: AttachedFormsContainerProps) =>
      props.active ? '#ffffff' : globalStyleVariables.newBrandingPrimary200};
  }

  :hover {
    background: ${(props: AttachedFormsContainerProps) =>
      props.active ? globalStyleVariables.newBrandingPrimary200 : '#f7f7f7'};
  }
`;

export const GroupMembersTable: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.05);

  > div {
    width: 100%;
  }

  .selected-patient {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .ag-row-selected {
    background-color: rgba(83, 200, 46, 0.05) !important;
    border: 1px solid #53c82e;
  }

  .ag__theme__custom {
    width: 100%;

    .ag-root-wrapper {
      .ag-header {
        height: 32px !important;
        min-height: 32px !important;
        border-bottom: none !important;
      }

      .ag-react-container {
        width: 100% !important;

        > div {
          margin: 0;
        }
      }

      .ag-body-viewport {
        .ag-row {
          width: 100%;
          z-index: 0;

          .ag-cell {
            padding: 0 24px;
            overflow: visible !important;
          }
        }

        .ag-row.ag-row-focus {
          z-index: 1;
        }
      }
    }
  }
`;

export const IconLabelContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
`;

export const VerticalDivider = styled.div`
  border-right: 1px solid #dee2e6;
  width: 24px;
`;

export const DPContainer = styled.div`
  display: flex;
  border-radius: 50%;
  background-color: ${globalStyleVariables.newBrandingPrimary200};
  height: 24px;
  width: 24px;
  margin-right: 16px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  font-size: 10px !important;
`;

interface FormProps {
  completed: boolean;
}

export const FormSpan = styled.span`
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 16px;
  display: flex;
  height: 32px;
  width: fit-content;
  background: ${(props: FormProps) =>
    props.completed ? '#EEFAEA' : '#f7f7f7'};
  border-radius: 4px;
  align-items: center;
`;

export const FormIconsSpan = styled.span`
  margin: 7px;

  svg {
    width: 18px;
    height: 18px;
  }

  width: 20px;
`;

export const FormsLabel = styled.span`
  font-size: 14px;
`;

export const CloseSpan = styled.span`
  cursor: pointer;
  padding: 6px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const EllipsisText = styled.div`
  font-size: 12px;
  line-height: 24px;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

import App from './App';
import { GlobalStyle } from './App/App_Styles';
import history from './history';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Switch, Route } from 'react-router';

//@ts-ignore
import FocusOverlay from 'focus-overlay';
import 'bootswatch/dist/cosmo/bootstrap.css';
import 'formiojs/dist/formio.full.css';

import './CommonStyles/styles.css';
import './polyfills/css/rc-calendar/modified.css';
import './polyfills/css/react-perfect-scrollbar/modified.css';
import 'antd/dist/antd.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'react-tippy/dist/tippy.css';
import './index.css';
import './Framework/Styles/styles.scss';

import './Framework/Styles/variables.css';

import 'suneditor/dist/css/suneditor.min.css';

import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { notification } from 'antd';
import Button from 'src/Framework/Controls/Button';

import storeRoot, { IStore as IRootStore } from './store';
import ServiceWorkerWrapper from './serviceWorkerWrapper';

import * as serviceWorkerRegistration from './registerServiceWorker';

import TemporaryStore, { TempContext } from './store/TemporaryStore';

import ManagementSystem from 'src/ManagementSystem';
import { isManagementSystem } from './ManagementSystem/utils';

//import { register } from './registerServiceWorker';

// Cache data
import { cleanupOutdatedCaches } from 'workbox-precaching';
import * as core from 'workbox-core';
import { useScreenOrientation } from './Framework/util/screenOrientations';
import Portrait from './Portrait';
//

import './Framework/BroadcastChannel/GlobalChannels';

import * as AccessTokenActions from 'src/App/LoginPage/store/AccessToken'

export type IStore = IRootStore;

const localBuildDateKey = 'buildDateTime';

const localBuildDate = localStorage.getItem(localBuildDateKey);

export const BuildDate: number | undefined = localBuildDate
  ? +localBuildDate
  : undefined;

export const store = storeRoot;
const focusOverlayOptions = {
  alwaysActive: true
};
export const FocusOverlayInstance = new FocusOverlay(
  document.body,
  focusOverlayOptions
);

const key = 'new-build-version';

// @ts-ignore
window.setAccessToken = AccessTokenActions.set

const Application = () => {
  const { portraitOrientation } = useScreenOrientation();
  const isManagementProject = useRef(isManagementSystem());
  const { showReload } = ServiceWorkerWrapper();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    new APIHandler().init().then(() => {
      setLoading(false);
    });
  }, []);
  useEffect(() => {
    if (showReload) {
      notification.success({
        key,
        duration: 0,
        onClose: () => {},
        closeIcon: <div />,
        message:
          'Medicat One has been updated, you may need to refresh the tab to get the latest version',
        btn: (
          <Button
            id="refresh-button"
            className="active-button-small focused-blue-button"
            size="small"
            onClick={() => {
              notification.close(key);
              window.location.reload();
            }}
          >
            Refresh
          </Button>
        )
      });
    }
  }, [showReload]);
  return (
    <>
      {portraitOrientation && <Portrait />}
      {!loading ? (
        <Provider store={TemporaryStore} context={TempContext as any}>
          <Provider store={store}>
            <Router history={history}>
              <Switch>
                {isManagementProject.current ? (
                  <Route path="/" component={ManagementSystem} />
                ) : (
                  <Route path="/" component={App} />
                )}
              </Switch>
              <GlobalStyle />
            </Router>
          </Provider>
        </Provider>
      ) : null}
    </>
  );
};

if (process.env.NODE_ENV !== 'test') {
  const buildDateChanged = (latestDate: number, currentDate: number) => {
    const momLatestDateTime = latestDate;
    const momCurrentDateTime = currentDate;

    if (momLatestDateTime !== momCurrentDateTime) {
      return true;
    } else {
      return false;
    }
  };
  const refreshCacheAndReload = async () => {
    if (caches) {
      console.log('Cache removing');
      if ('serviceWorker' in navigator) {
        serviceWorkerRegistration.unregister();
        cleanupOutdatedCaches();
        caches
          .keys()
          .then(function (cacheNames) {
            console.log({ cacheNames, core: core.cacheNames });
            let validCacheSet = new Set(Object.values(core.cacheNames));
            return Promise.all(
              cacheNames
                .filter(function (cacheName) {
                  return !validCacheSet.has(cacheName);
                })
                .map(function (cacheName) {
                  console.log('deleting cache', cacheName);
                  return caches.delete(cacheName);
                })
            );
          })
          .then(() => {
            // serviceWorkerRegistration.register();
            // @ts-ignore
            window.location.reload(true);
          });
      }
    }
  };

  const getMetaData = async () => {
    try {
      const response = await fetch('/meta.json');
      const meta = await response.json();
      const latestVersionDate = meta.buildDate;
      const currentVersionDate = BuildDate;
      localStorage.setItem(localBuildDateKey, `${latestVersionDate}`);
      if (currentVersionDate) {
        const shouldForceRefresh = buildDateChanged(
          latestVersionDate,
          currentVersionDate
        );
        if (shouldForceRefresh) {
          console.log('Detected new build');
          await refreshCacheAndReload();
        } else {
        }
      } else {
      }
    } catch (e) {
      console.log({ e });
    }
  };
  getMetaData();
  ReactDOM.render(
    <Application />,
    document.getElementById('root') as HTMLElement
  );
  // register({onUpdate: (registration: ServiceWorkerRegistration) => {
  //   console.log("updating service worker registration");
  //   registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
  // }});
}

import React, { FC, useState } from 'react';
import { Button, notification } from 'antd';
import _ from 'lodash';

import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import ReasonSelector from 'src/Framework/Controls/Selectors/ReasonSelector';
import { getReasonByCode } from 'src/Framework/Controls/Selectors/ReasonSelector/utils';
import { useAppSelector } from 'src/store';

import { ApiUrl } from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ApiUrl';
import { AlertRedTriangleSvg } from 'src/Activities/Schedule/Svg/AlertRedTriangle';
import { getFormattedStr } from 'src/Activities/Schedule/Utils/Utils';
import {
  IAppointment,
  IGroup
} from 'src/Activities/Schedule/RightSideBar/WaitingList/Group/types';
import {
  waitingListKeyGeneration,
  setUkvpWaitingList
} from 'src/App/Ukvp/store/Actions';

import {
  PopoverTitleContainer,
  DeleteWarning
} from 'src/Activities/Schedule/RightSideBar/WaitingList/GroupList/GroupList_Styles';
import { ButtonSpanPatient } from './styles';
import {
  PopoverContent,
  ButtonContainer
} from 'src/Activities/Subject/Facts/Facts/styles';

interface IProps {
  data: IGroup[];
  value: IAppointment;
  group: IGroup;
  setRemove: Function;
  updateWaitingList: Function;
  selectedCancellationReason: any;
  setSelectedCancellationReason: Function;
  close: Function;
}

const Component: FC<IProps> = ({
  value,
  group,
  data,
  setRemove,
  close,
  updateWaitingList,
  selectedCancellationReason,
  setSelectedCancellationReason
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const sortLists = useAppSelector((state) => state.ukvp.summary);
  const key = waitingListKeyGeneration(group.id);
  const currentSortList = sortLists[key]?.parsedValue as string[];

  const onConfirmDelete = async () => {
    try {
      setLoading(true);
      let details = {
        cancelReasonCode: selectedCancellationReason.value,
        subjectId: value?.subjectId || value.patientInfo.patientId,
        appointmentId: value.appointmentId,
        scheduleEventTypeId: 5
      };
      const response = await APIHandler.AxiosInstance.post(
        ApiUrl.DELETE_SCHEDULE_EVENT,
        {
          ...details
        },
        { withCredentials: true }
      );
      if (response.data.success) {
        const arr = _.cloneDeep(data);
        let index =
          arr?.findIndex(
            (item: any) => item.waitingListId === group.waitingListId
          ) ?? -1;
        if (arr && index !== -1) {
          let subjectIndex =
            arr[index]?.patients.findIndex((item: any) => {
              if (item?.subjectId && value?.subjectId) {
                return item.subjectId === value.subjectId;
              } else {
                return (
                  item.patientInfo.patientId === value.patientInfo.patientId
                );
              }
            }) ?? -1;
          if (subjectIndex !== -1) {
            arr[index].patients.splice(subjectIndex, 1);
          }
        }
        if (currentSortList) {
          await setUkvpWaitingList(
            key,
            currentSortList.filter((item) => item !== value.appointmentId + '')
          );
        }
        updateWaitingList(arr);
        close();
      } else {
        notification.error({
          key: 'error-refresh-message',
          message: response.data.error
        });
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <PopoverContent
      className={'waiting-list'}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column'
      }}
    >
      <PopoverTitleContainer style={{ paddingLeft: '1rem' }}>
        <DeleteWarning>
          <div>
            <AlertRedTriangleSvg />
          </div>
          <div>
            {`You are going to delete ${value.name} from ${getFormattedStr(
              group.groupName,
              70
            )}. Please select a reason and confirm`}
          </div>
        </DeleteWarning>
      </PopoverTitleContainer>
      <PopoverTitleContainer
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          paddingLeft: '1.5rem',
          marginTop: 10
        }}
      >
        <ReasonSelector
          name="Delete Reason"
          value={selectedCancellationReason?.code}
          clinicCodes={undefined}
          useCode={true}
          onChange={(value: any) => {
            const data: any = getReasonByCode(value);
            setSelectedCancellationReason(data);
          }}
          reasonTypes={['cancellation']}
          selectProps={{
            bottomMargin: false,
            star: false,
            inputProps: {
              placeholder: 'Select Reason'
            }
          }}
        />
      </PopoverTitleContainer>
      <ButtonContainer
        style={{ marginTop: '1.5rem', borderTop: '1px solid #e8e8e8' }}
      >
        <ButtonSpanPatient>
          {/*<TrashIcon>*/}
          {/*  <TrashSvg />*/}
          {/*</TrashIcon>*/}
          <div></div>
          <div className="buttons-container">
            <Button
              style={{ border: 'none', boxShadow: 'none' }}
              onClick={(e: any) => setRemove(false)}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              disabled={!selectedCancellationReason}
              onClick={(e: any) => onConfirmDelete()}
              style={{ minWidth: '80px' }}
              className="active-button focused-button"
            >
              Confirm
            </Button>
          </div>
        </ButtonSpanPatient>
      </ButtonContainer>
    </PopoverContent>
  );
};

export default Component;

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

interface IContainer {
  cancelled: boolean;
}

export const Container = styled.div<IContainer>`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid ${globalStyleVariables.newBrandingPrimary200};
  border-radius: 4px;
  padding: 10px 0;
  font-size: 12px;
  margin-top: 10px;
  ${({ cancelled }) =>
    cancelled && `border-left: 4px solid rgb(153, 153, 153);`}
`;

export const GroupContainer = styled.div`
  position: relative;
`;

export const LoadingContainer = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import React, { FC, useEffect, useState, useRef, useCallback } from 'react';
import { List } from 'antd';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import Pagination, {
  IProps as IPaginationProps
} from 'src/Framework/Controls/Pagination';
import Header from 'src/Framework/Controls/Table/Header';
import { ISort } from 'src/Framework/Controls/Table/types';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';

import { getPatientHistory } from '../Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import { getScheduleAppointment } from '../Store/ScheduleEventsReducer/Actions';
import {
  IApptResponse,
  IScheduleEvent
} from '../Store/ScheduleEventsReducer/types';

import Item from './Item';

import {
  Container,
  HeaderContainer,
  PaginationContainer,
  MainContainer
} from './styled';

interface IProps {
  patientId: number | string;
  pageLength?: number;
  disablePagination?: boolean;
  listHeight?: string;
  enableEdit?: boolean;
  paginationProps?: Partial<IPaginationProps>;
}

export const columns = {
  1: '68px',
  2: 'auto',
  3: 'auto',
  4: '120px',
  5: '120px',
  6: 'auto',
  7: '60px',
  8: '60px',
  9: '60px'
};

const Component: FC<IProps> = (props: IProps) => {
  const {
    patientId,
    disablePagination,
    listHeight,
    paginationProps,
    enableEdit = false
  } = props;
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageLength: props.pageLength || 8
  });
  const [loading, setLoading] = useState(false);
  const [sortingField, setSortingField] = useState<string>('Date & Time');
  const [sortOrder, setSortOrder] = useState<ISort['sortType']>('desc');
  const [history, setHistory] = useState<IScheduleEvent[]>([]);
  const { pageNumber, pageLength } = pagination;
  const eventsCache = useRef<Record<string, IApptResponse>>({});

  const sortingCB = (a: any, b: any) => {
    let sortingItemA, sortingItemB;
    if (sortingField === 'Type') {
      sortingItemA = a.reason?.name;
      sortingItemB = b.reason?.name;
    } else if (sortingField === 'Provider') {
      sortingItemA = a.providers
        ? a.providers[0].name
        : a.waitingList && a.waitingList?.id !== 0
        ? a.waitingList?.name
        : a.provider?.name;
      sortingItemB = b.providers
        ? b.providers[0].name
        : b.waitingList && b.waitingList?.id !== 0
        ? b.waitingList?.name
        : b.provider?.name;
    } else if (sortingField === 'Date & Time') {
      sortingItemA = new Date(a.startTime).getTime();
      sortingItemB = new Date(b.startTime).getTime();
    } else if (sortingField === 'Clinic') {
      sortingItemA = a.clinicName;
      sortingItemB = b.clinicName;
    }
    if (sortingItemA === sortingItemB || sortOrder === null) {
      return 0;
    } else if (sortingItemA > sortingItemB) {
      return sortOrder === 'asc' ? 1 : -1;
    } else {
      return sortOrder === 'asc' ? -1 : 1;
    }
  };

  const init = useCallback(async () => {
    setLoading(true);
    const res = await getPatientHistory(patientId);
    if (res) {
      setHistory(res);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    init();
  }, []);

  const sortingHandler = (field: string) => {
    if (field === sortingField) {
      setSortOrder(defineSortOrder);
    } else {
      setSortingField(field);
      setSortOrder('asc');
    }
  };

  const defineSortOrder = () => {
    switch (sortOrder) {
      case 'asc':
        return 'desc';
      case 'desc':
        return null;
      case null:
        return 'asc';
    }
  };

  const getEvent = useCallback(
    async (appointmentId: number, scheduleEventTypeId?: number) => {
      if (eventsCache.current[appointmentId]) {
        return eventsCache.current[appointmentId];
      }
      try {
        const response = await getScheduleAppointment(
          appointmentId,
          scheduleEventTypeId
        );
        if (response) {
          eventsCache.current[appointmentId] = response;
          return response;
        }
        return;
      } catch (e) {
        return;
      }
    },
    []
  );
  const data = disablePagination
    ? [...history].sort(sortingCB)
    : [...history]
        .sort(sortingCB)
        .slice(pageNumber * pageLength, (pageNumber + 1) * pageLength);
  return (
    <MainContainer>
      {loading && <AbsoluteLoader />}
      <HeaderContainer>
        <Header
          sort={{
            sortBy: sortingField,
            sortType: sortOrder
          }}
          enableEdit={enableEdit}
          paddings="0 14px"
          onSort={sortingHandler}
          horizontalItemPadding={8}
          columns={[
            {
              title: '',
              key: '',
              flex: columns[1]
            },
            {
              title: 'Date & Time',
              sortBy: 'Date & Time',
              key: '',
              flex: columns[2]
            },
            {
              title: 'Type',
              sortBy: 'Type',
              key: '',
              flex: columns[3]
            },
            {
              title: 'Clinic',
              sortBy: 'Clinic',
              key: '',
              flex: columns[4]
            },
            {
              title: 'Provider',
              sortBy: 'Provider',
              key: '',
              flex: columns[5]
            },
            {
              title: 'Client',
              key: '',
              flex: columns[6]
            },
            {
              title: 'Comment',
              key: '',
              flex: columns[7],
              centered: true
            },
            {
              title: 'Preview',
              key: '',
              flex: columns[8],
              centered: true
            },
            {
              title: 'Edit',
              key: '',
              flex: columns[9],
              centered: true
            }
          ]}
        />
      </HeaderContainer>
      <Container listHeight={listHeight}>
        <List
          className={'appts-history-list'}
          dataSource={data}
          renderItem={(item: any) => (
            <List.Item
              className={'appt-list-item'}
              tabIndex={0}
              onKeyDown={onKeyDownAccessibility}
              role={item.cancellation?.isCancelled ? 'deletion' : 'listitem'}
            >
              <Item
                key={item.uid}
                value={item}
                getEvent={getEvent}
                refreshList={init}
                enableEdit={enableEdit}
              />
            </List.Item>
          )}
        />
        {!disablePagination && (
          <PaginationContainer>
            <Pagination
              data={history}
              pageNumber={pageNumber}
              pageLength={pageLength}
              dataLength={history.length}
              onChangePage={(pageNumber) =>
                setPagination((pagination) => ({
                  ...pagination,
                  pageNumber
                }))
              }
              {...paginationProps}
            />
          </PaginationContainer>
        )}
      </Container>
    </MainContainer>
  );
};

export default Component;

import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import Header from 'src/Framework/Controls/ModalSlider/Header';
import { useReqOrderResult } from '../../store/Hooks/useReqOrderResults';

import { Container, TableContainer } from './styled';
import Table from 'src/Framework/Controls/Table';
import {
  IColumn,
  ITableRenderItemData
} from 'src/Framework/Controls/Table/types';
import Button from 'src/Framework/Controls/Button';
import { ReqOrderResult } from '../../store/types';
import Result from './Result';
import { Col, Row } from 'antd';
import EditButton from 'src/Framework/Controls/Table/EditButton';
import RemoveButton from 'src/Framework/Controls/Table/RemoveButton';
import FilesDownloader from 'src/Framework/Controls/FilesDownloader';
import M1FormRecordButton from 'src/Activities/Forms/M1FormModal/M1FormRecordButton';
import { formattedDateTime } from 'src/Framework/Shared/Shared';
import { useOrderManager } from 'src/Activities/Home/OrderManager/store/Hooks/useOrderManager';
import * as txnCodeActions from 'src/App/Admin/Pages/TransactionCodes/store/Actions';
import { TxnCode } from 'src/App/Admin/Pages/TransactionCodes/store/types';
import {
  IOrderManagerItem,
  TypeReadFlag
} from 'src/Activities/Home/OrderManager/store/types';
import RadioComponent from 'src/Framework/Controls/Radio';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import AlertPopUp, {
  IModalHandles as IAlertPopUp
} from 'src/Framework/Controls/AlertPopUp';
import { patch as M1FormRecordPatch } from 'src/App/M1FormRecords/store/Actions/index';
import { IM1FormRecord } from 'src/App/M1FormRecords/store/types';

interface IProps {
  reqOrderId: number;
  patientId: number;
  close?: () => any;
  onOrderUpdated?: (data: { reqOrderId: number }) => any;
}

enum Pages {
  main = 'main',
  add = 'add'
}

export const defaultData = (): Partial<ReqOrderResult> => {
  return {};
};

const Component: FC<IProps> = ({
  reqOrderId,
  close: onClose,
  patientId,
  onOrderUpdated
}: IProps) => {
  const currentResultForms = useRef<Record<number, IM1FormRecord>>({});
  const AlertPopUpRef = useRef<IAlertPopUp>(null);
  const [order, setOrder] = useState<IOrderManagerItem | null>(null);

  const { actions: orderManagerActions } = useOrderManager({
    enableCache: false
  });
  const [loadingOrderChange, setLoadingOrderChange] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [txnCodeFullDto, setTxnCodeFullDto] = useState<TxnCode | null>(null);
  const [page, setPage] = useState(Pages.main);
  const [currentResult, setCurrentResult] = useState<ReqOrderResult | null>(
    null
  );
  const { actions, data, loading } = useReqOrderResult();

  const getFormCallback = useCallback((value: IM1FormRecord) => {
    currentResultForms.current[value.id] = value;
  }, []);

  const getData = useCallback(async () => {
    setLoadingData(true);
    const res = await actions.get({
      reqOrderId
    });
    if (res) {
      const reqOrderItem = await orderManagerActions.getById({ reqOrderId });
      if (reqOrderItem) {
        setOrder(reqOrderItem);
        const txnCodeFullDto = await txnCodeActions.getFullDto(
          reqOrderItem.txnCodeId
        );
        if (txnCodeFullDto) {
          setTxnCodeFullDto(txnCodeFullDto);
        }
      }
    }
    setLoadingData(false);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const dataSource = useMemo(() => {
    return (data[reqOrderId]?.summary || []).filter((v) => v.isActive);
  }, [data[reqOrderId]]);

  const renderItemData = useCallback(
    (
      value: (typeof dataSource)[number]
    ): ITableRenderItemData<typeof value> => {
      return {
        ...value,
        date: formattedDateTime(value.createdDts),
        attachment: (
          <>
            {value.fileDefinition && (
              <FilesDownloader
                patientId={patientId}
                popoverProps={{
                  buttonSize: 32,
                  buttonProps: {
                    containerProps: {
                      style: {
                        margin: 0
                      }
                    }
                  }
                }}
                popoverEnable={true}
                files={[value.fileDefinition]}
                containerStyles={{
                  margin: 0
                }}
              />
            )}
            {value.m1FormRecordId && (
              <M1FormRecordButton
                onRemoveForm={getData}
                m1FormRecordId={value.m1FormRecordId}
                patientId={patientId}
                showRemovedAlert={true}
                getFormCallback={getFormCallback}
              />
            )}
          </>
        ),
        actions: (
          <Row align="middle" gutter={8} wrap={false}>
            <Col>
              <EditButton
                onClick={() => {
                  setPage(Pages.add);
                  setCurrentResult(value);
                }}
              />
            </Col>
            <Col>
              <RemoveButton
                approve={true}
                removeAction={async () => {
                  const remove = async () => {
                    await actions.put({
                      ...value,
                      isActive: false
                    });
                  };
                  if (value.m1FormRecordId) {
                    const form =
                      currentResultForms.current[value.m1FormRecordId];
                    if (form && form.isActive === true) {
                      AlertPopUpRef.current?.show({
                        action: 'delete',
                        content:
                          'Do you want to delete the form attached to this result?',
                        onConfirmAsync: async () => {
                          await Promise.all([
                            remove(),
                            M1FormRecordPatch(
                              {
                                isActive: false,
                                id: value.m1FormRecordId!
                              },
                              ''
                            )
                          ]);
                        },
                        onCancel: () => remove()
                      });
                      return;
                    }
                  }
                  await remove();
                }}
              />
            </Col>
          </Row>
        )
      };
    },
    []
  );

  const onBack = useCallback(() => {
    setPage(Pages.main);
  }, []);

  const onClickDefaultResult = useCallback(
    async (data: { resultM1FormTemplateId: number }) => {
      const res = await actions.post({
        title: 'Default result',
        text: 'Default result',
        m1FormTemplateId: data.resultM1FormTemplateId,
        reqOrderId
      });
      if (res) {
        actions.get({
          reqOrderId
        });
      }
    },
    []
  );

  const onChangeOrderRead = useCallback(
    async (unread: TypeReadFlag) => {
      setLoadingOrderChange(true);
      if (order) {
        const res = await orderManagerActions.patch({
          ...order,
          unread
        });
        if (onOrderUpdated) {
          onOrderUpdated({ reqOrderId: order.reqOrderId });
        }
        if (res) {
          setOrder(res);
        }
      }
      setLoadingOrderChange(false);
    },
    [order]
  );

  return (
    <Container>
      <AlertPopUp ref={AlertPopUpRef} />
      {page === Pages.main && (
        <>
          <Header
            title={'Manage Results'}
            onClose={onClose}
            headerRight={
              <Row align="middle" gutter={16}>
                {dataSource.length === 0 &&
                  txnCodeFullDto &&
                  txnCodeFullDto.order?.resultM1FormTemplateId && (
                    <Col>
                      <Button
                        id=""
                        onClick={async () => {
                          if (txnCodeFullDto.order?.resultM1FormTemplateId) {
                            await onClickDefaultResult({
                              resultM1FormTemplateId:
                                txnCodeFullDto.order?.resultM1FormTemplateId
                            });
                          }
                        }}
                      >
                        Default result
                      </Button>
                    </Col>
                  )}

                <Col>
                  <Button
                    id=""
                    onClick={() => {
                      setPage(Pages.add);
                      setCurrentResult(null);
                    }}
                  >
                    Add Result
                  </Button>
                </Col>
              </Row>
            }
          />

          <TableContainer>
            <Row
              align="middle"
              gutter={16}
              style={{ padding: 20, position: 'relative' }}
            >
              {loadingOrderChange && <AbsoluteLoader />}
              {[
                {
                  value: TypeReadFlag.notResulted,
                  label: 'Not resulted'
                },
                {
                  value: TypeReadFlag.needsReading,
                  label: 'Needs reading'
                },
                {
                  value: TypeReadFlag.read,
                  label: 'Read'
                }
              ].map((v) => (
                <Col key={v.label}>
                  <RadioComponent
                    id=""
                    checked={order?.unread === v.value}
                    onChange={() => onChangeOrderRead(v.value)}
                  >
                    {v.label}
                  </RadioComponent>
                </Col>
              ))}
            </Row>
            <Table
              absoluteContainer={true}
              absoluteLoading={loading || loadingData}
              dataSource={dataSource}
              renderItemData={renderItemData}
              columns={columns}
              rowHover={true}
            />
          </TableContainer>
        </>
      )}
      {page === Pages.add && (
        <Result
          actions={actions}
          reqOrderId={reqOrderId}
          onClose={onClose}
          currentResult={currentResult}
          onBack={onBack}
          patientId={patientId}
        />
      )}
    </Container>
  );
};

export default React.memo(Component);

const columns: IColumn[] = [
  {
    title: 'Create Date',
    flex: '180px',
    key: 'date',
    ellipsis: true
  },
  {
    title: 'Title',
    flex: '150px',
    key: 'title',
    ellipsis: true
  },
  {
    title: 'Attachment',
    flex: '100px',
    key: 'attachment',
    centered: true
  },
  {
    title: 'Description',
    flex: 'auto',
    key: 'text',
    ellipsis: true
  },
  {
    title: 'Action',
    flex: '120px',
    key: 'actions',
    centered: true
  }
];

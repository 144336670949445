import React, { FC, useCallback, useMemo, useRef } from 'react';
import PatientName from 'src/App/Patients/Components/PatientName';
import PolicyStatusContainer from '../PolicyStatusContainer';
import Button from 'src/Framework/Controls/Button';
import Table from 'src/Framework/Controls/Table';
import { IColumn } from 'src/Framework/Controls/Table/types';
import { IShow } from '.';
import { useAppSelector } from 'src/store';

import PrivacyPolicyStatusModal, {
  IModalHandles
} from '../PrivacyPolicyStatusModal';

interface IProps {
  data: IShow['value'];
  clinicId: number;
}

const Component: FC<IProps> = ({ data, clinicId }: IProps) => {
  const modalRef = useRef<IModalHandles>(null);

  const hippo = useAppSelector((state) => state.hippa.summary);

  const dataSource = useMemo(() => {
    if (!data) return [];
    return Object.values(hippo).filter((v) => data[v.id]);
  }, [data, hippo]);

  const renderItemData = useCallback(
    (value: (typeof dataSource)[number]) => {
      return {
        name: <PatientName patientId={value.patientId} />,
        status: <PolicyStatusContainer data={value} clinicId={clinicId} />,
        accept: (
          <Button
            id=""
            disableClickLoader={true}
            colorStyles={{ blueBorder: true }}
            onClick={() => {
              modalRef.current?.show({ value });
            }}
          >
            Accept Privacy Policy
          </Button>
        )
      };
    },
    [clinicId]
  );

  return (
    <>
      <PrivacyPolicyStatusModal ref={modalRef} clinicId={clinicId} />
      <Table
        dataSource={dataSource}
        renderItemData={renderItemData}
        columns={columns}
        absoluteContainer={true}
      />
    </>
  );
};

export default React.memo(Component);

export const columns: IColumn[] = [
  {
    title: 'Full Name',
    key: 'name',
    flex: 'auto'
  },
  {
    title: 'Privacy Policy current status',
    key: 'status',
    flex: '250px'
  },
  {
    title: '',
    key: 'accept',
    flex: '210px'
  }
];

import React, { FC, useRef, useState, useEffect, useMemo } from 'react';
import moment from 'moment/moment';

import Popover from 'src/Framework/Controls/Popover';
import { getReasonByID } from 'src/Framework/Controls/Selectors/ReasonSelector/utils';
import { formattedTime } from 'src/Framework/Shared/Shared';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';
import { useAppSelector } from 'src/store';

import { timeDiff } from 'src/Activities/Schedule/Utils/Utils';
import { ScheduleTimeProps } from 'src/Activities/Schedule/Common/Interfaces';
import { AddIndividualApptSvg } from 'src/Activities/Schedule/Svg/AddIndividualApptSvg';
import { RecurreSvg } from 'src/Activities/Schedule/Svg/RecurreSvg';
import BlockReservationPopup, {
  IModalHandles
} from 'src/Activities/Schedule/Popups/BlockReservationPopup/Container';
import { defaultCalendarOpacityLevel } from 'src/Activities/Schedule/TopBar/FilterBar/OpacityLevel';
import { findAndFilterExpiredEvents } from 'src/Activities/Schedule/Store/ScheduleSliceEventsActions';
import { TypeReservationType } from 'src/App/UserPreferences/store/types';
import { useGroupType } from 'src/App/Admin/Pages/GroupTypes/store/Hooks/useGroupType';

import {
  EditSeriesPopupContent,
  PopupTextLine,
  ListItem
} from '../ScheduleOne/styled';
import {
  ScheduleGroupContainer,
  GroupTitle,
  GroupTitleStyle,
  GroupBkStyle,
  GroupStyleForTitle
} from './styled';
import { checkClinicOutOfRange } from '../utils';

type IProps = {
  isShowingReservationLabel: boolean;
  scheduleGroup: any;
  idx: number;
  scheduleTimeProps: ScheduleTimeProps;
};

export const ScheduleGroupBorder: FC<IProps> = (props: IProps) => {
  const reservationType = useAppSelector(
    (state) =>
      state.user.preferences.calendarCellSettings?.parsedValue
        ?.reservationType || TypeReservationType.flag
  );
  const { scheduleGroup, scheduleTimeProps } = props;
  const calendarOpacityLevel = useAppSelector(
    (state) =>
      state.user.preferences.calendarOpacityLevel?.parsedValue ||
      defaultCalendarOpacityLevel
  );
  const { regularMeetingTime, dayStartTime } = scheduleTimeProps;
  const {
    color,
    isBlock,
    duration,
    startTime,
    isReservation,
    appointmentFor,
    reservation
  } = scheduleGroup;
  let groupTitle = appointmentFor;
  const groupLen =
    (Number(duration) / regularMeetingTime) * scheduleTimeProps.slotHeight;
  const groupUIOffset =
    (timeDiff(startTime, dayStartTime) / regularMeetingTime) *
    scheduleTimeProps.slotHeight;

  if (isBlock && !isReservation) {
    groupTitle = appointmentFor;
  }

  if (groupTitle == 'Blank' || groupTitle == 'None') {
    return <div />;
  }

  const groupStyle = GroupBkStyle(
    color,
    groupLen,
    groupUIOffset,
    groupTitle,
    isReservation,
    calendarOpacityLevel
  );
  const styleObject: React.CSSProperties = {};
  if (
    reservationType === TypeReservationType.border ||
    reservationType === TypeReservationType.fillBackground
  ) {
    styleObject.border = groupStyle.borderLeft;
  }
  if (
    !checkClinicOutOfRange({
      clinicCode: reservation.clinicCode,
      endTime: reservation.endTime
    })
  ) {
    return null;
  }

  return (
    <ScheduleGroupContainer
      style={{
        ...groupStyle,
        ...styleObject
      }}
    />
  );
};

export const ScheduleGroupTitle: FC<IProps> = (props: IProps) => {
  const calendarOpacityLevel = useAppSelector(
    (state) =>
      state.user.preferences.calendarOpacityLevel?.parsedValue ||
      defaultCalendarOpacityLevel
  );
  const reservationLabelOpacity = useAppSelector(
    (state) => state.scheduleDetails.filters.reservationLabelOpacity
  );
  const [visible, setVisible] = useState(false);
  const seriesPopupRef = useRef(null);
  const BlockReservationRef = useRef<IModalHandles>(null);
  const parentCellRef = useRef(null);
  const types = useGroupType('groupAppointmentReason');
  const { scheduleGroup, isShowingReservationLabel, scheduleTimeProps } = props;
  const { dayStartTime, regularMeetingTime } = scheduleTimeProps;

  const getEventLabel = useMemo(() => {
    let typeString = 'reservation';

    let descriptionString = scheduleGroup.appointmentFor || '';

    let seriesString = scheduleGroup.inSeries
      ? 'in series. use Shift plus Enter to get extra menu.'
      : '';

    let providerString = scheduleGroup.isMultiProvider
      ? 'Several providers.'
      : `Provider is ${scheduleGroup.provider.name}.`;

    return `time is ${formattedTime(
      moment(scheduleGroup.startTime, 'HH:mm').toISOString()
    )}. ${typeString} ${descriptionString} ${seriesString} ${providerString}`;
  }, []);

  const {
    isBlock,
    startTime,
    appointmentFor,
    color,
    isReservation,
    reservation,
    reason
  } = scheduleGroup;

  const appointmentTypes = useMemo(() => {
    return types.genericTypes || [];
  }, [types]);

  let groupTitle = reason
    ? getReasonByID(reason?.id)?.name
    : appointmentTypes.length > 0 && reservation?.groupId
    ? appointmentTypes.find((item: any) => item.id === reservation?.groupId)
        ?.name
    : appointmentFor;

  const groupUIOffset =
    (timeDiff(startTime, dayStartTime) / regularMeetingTime) *
    scheduleTimeProps.slotHeight;

  useEffect(() => {
    if (props.scheduleGroup?.reservation?.blockReservationExpireTime) {
      const timer = setInterval(() => {
        if (
          moment() >=
          moment(props.scheduleGroup?.reservation?.blockReservationExpireTime)
        ) {
          console.log(`Event ${props.scheduleGroup?.reservation?.uid} expired`);
          findAndFilterExpiredEvents();
          if (timer) {
            clearInterval(timer);
          }
        }
      }, 1000);
      return () => {
        if (timer) {
          clearInterval(timer);
        }
      };
    }
    return () => {};
  }, []);

  if (isBlock && !isReservation) {
    groupTitle = appointmentFor;
  }

  if (groupTitle == 'Blank' || groupTitle == 'None') {
    return <div />;
  }

  if (groupTitle && groupTitle.length > 35) {
    groupTitle = groupTitle.slice(0, 33) + '..';
  }

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const clickType =
        !scheduleGroup.inSeries || (!e.shiftKey && scheduleGroup.inSeries)
          ? 'dblclick'
          : 'click';
      const event = new Event(clickType, { bubbles: true });
      (e.currentTarget as HTMLElement).dispatchEvent(event);
    }
  };

  const onKeyDownWrapper = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab' && e.shiftKey && e.target === e.currentTarget) {
      setVisible(false);
      e.preventDefault();
      if (parentCellRef.current) {
        //@ts-ignore
        parentCellRef.current.focus();
      }
    }
  };
  const onKeyDownLastItem = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && e.target === e.currentTarget) {
      //@ts-ignore
      e.currentTarget.click();
    }
    if (e.key === 'Tab' && !e.shiftKey && e.target === e.currentTarget) {
      setVisible(false);
      e.preventDefault();
      if (parentCellRef.current) {
        //@ts-ignore
        parentCellRef.current.focus();
      }
    }
  };

  if (
    !checkClinicOutOfRange({
      clinicCode: reservation.clinicCode,
      endTime: reservation.endTime
    })
  ) {
    return null;
  }
  return (
    <>
      <BlockReservationPopup
        ref={BlockReservationRef}
        onClosePopup={() => {
          if (parentCellRef.current) {
            //@ts-ignore
            parentCellRef.current.focus();
          }
        }}
      />
      <ScheduleGroupContainer
        style={GroupStyleForTitle(groupUIOffset, isReservation)}
        onDoubleClick={(e) => {
          e.stopPropagation();
          BlockReservationRef.current?.show({
            options: {
              action: 'edit',
              appointmentBlockId: `${reservation.appointmentBlockId}`,
              startTime: reservation.startTime
            }
          });
          setVisible(false);
        }}
        id={reservation?.uid}
      >
        {groupTitle && groupTitle.length && isShowingReservationLabel ? (
          <Popover
            id="series-popover"
            globalOverlay={true}
            destroyTooltipOnHide
            open={props.scheduleGroup.inSeries ? visible : false}
            onOpenChange={(visible) => {
              setVisible(visible);
              if (visible) {
                const timer = setTimeout(() => {
                  if (seriesPopupRef.current) {
                    // @ts-ignore
                    seriesPopupRef.current.focus();
                    clearTimeout(timer);
                  }
                }, 500);
              }
            }}
            content={
              <div
                onMouseDown={(e: any) => e.preventDefault()}
                style={{
                  width: '250px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <EditSeriesPopupContent
                  tabIndex={0}
                  role="menu"
                  ref={seriesPopupRef}
                  onKeyDown={onKeyDownWrapper}
                >
                  <ListItem
                    tabIndex={0}
                    onKeyDown={onKeyDownAccessibility}
                    role="menuitem"
                    onClick={async (e: any) => {
                      BlockReservationRef.current?.show({
                        options: {
                          action: 'edit',
                          appointmentBlockId: `${reservation.appointmentBlockId}`,
                          startTime: reservation.startTime
                        }
                      });
                      setVisible(false);
                    }}
                  >
                    <AddIndividualApptSvg />
                    <PopupTextLine style={{ paddingLeft: '0.3rem' }}>
                      Edit this single reservation
                    </PopupTextLine>
                  </ListItem>
                  <ListItem
                    tabIndex={0}
                    onKeyDown={onKeyDownLastItem}
                    role="menuitem"
                    onClick={async (e: any) => {
                      BlockReservationRef.current?.show({
                        options: {
                          action: 'edit',
                          appointmentBlockId: reservation.appointmentBlockId,
                          startTime: reservation.startTime,
                          updateSeries: true
                        }
                      });
                      setVisible(false);
                    }}
                  >
                    <RecurreSvg />
                    <PopupTextLine style={{ paddingLeft: '1rem' }}>
                      Edit all reservations in this series
                    </PopupTextLine>
                  </ListItem>
                </EditSeriesPopupContent>
              </div>
            }
            placement="rightTop"
            trigger={'click'}
          >
            <GroupTitle
              tabIndex={0}
              aria-label={getEventLabel}
              ref={parentCellRef}
              onKeyDown={onKeyDown}
              style={GroupTitleStyle(
                groupTitle,
                color,
                isReservation,
                reservationLabelOpacity ? calendarOpacityLevel : { value: 1 }
              )}
            >
              {groupTitle}
            </GroupTitle>
          </Popover>
        ) : null}
      </ScheduleGroupContainer>
    </>
  );
};

import React, {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import Item, { getEmptySlotPosition } from './Item';
import { DefaultItemHeight } from '../ScheduleCol_Styles';
import _ from 'lodash';

interface IProps {
  sliceId: string;
  emptyPlaces: any[];
  scheduleCol: any;
  scheduleTimeProps: any;
  memoFuncRef: any;
  mainScrollContainerRef: React.RefObject<HTMLDivElement>;
}

const Component: FC<IProps> = ({
  emptyPlaces,
  sliceId,
  scheduleTimeProps,
  scheduleCol,
  memoFuncRef,
  mainScrollContainerRef
}: IProps) => {
  const [showIds, setShowIds] = useState<string[]>([]);
  const isScrolling = useRef(false);
  const dataRef = useRef({
    positions: emptyPlaces.map((value) => ({
      id: `${value.startTime}-${value.endTime}`,
      position: getEmptySlotPosition({
        value,
        scheduleTimeProps
      })
    })),
    emptyPlacesItems: emptyPlaces,
    showIds: [] as string[]
  });
  useLayoutEffect(() => {
    dataRef.current = {
      positions: emptyPlaces.map((value) => ({
        id: `${value.startTime}-${value.endTime}`,
        position: getEmptySlotPosition({
          value,
          scheduleTimeProps
        })
      })),
      emptyPlacesItems: emptyPlaces,
      showIds
    };
  });
  useEffect(() => {
    const ItemHeight = DefaultItemHeight();
    const alfa = 5 * ItemHeight;
    const visibilityChecker = () => {
      if (isScrolling.current) {
        isScrolling.current = false;
        requestAnimationFrame(visibilityChecker);
        return;
      }
      if (mainScrollContainerRef.current) {
        const { positions, emptyPlacesItems, showIds } = dataRef.current;
        const showIdsOnColumn: string[] = [];
        emptyPlacesItems.forEach((value) => {
          const currentId = `${value.startTime}-${value.endTime}`;
          if (mainScrollContainerRef.current) {
            const itemPosition = positions.find((v) => v.id === currentId);
            if (itemPosition) {
              const top = mainScrollContainerRef.current.scrollTop;
              const bottom =
                mainScrollContainerRef.current.scrollTop +
                mainScrollContainerRef.current.clientHeight;
              const currentlyVisible =
                itemPosition.position.y >= top - alfa &&
                itemPosition.position.y + itemPosition.position.height <=
                  bottom + alfa;
              if (currentlyVisible) {
                showIdsOnColumn.push(currentId);
              }
            }
          }
        });
        if (!_.isEqual(showIds, showIdsOnColumn)) {
          setShowIds(showIdsOnColumn);
        }
      }
    };
    visibilityChecker();
    let scrollPosition = mainScrollContainerRef.current?.scrollTop || 0;
    const callbackScroll = (e: any) => {
      const diff = Math.abs(scrollPosition - e.target.scrollTop);
      if (diff > alfa * 0.5) {
        scrollPosition = e.target.scrollTop;
        visibilityChecker();
      }
    };
    mainScrollContainerRef.current?.addEventListener('scroll', callbackScroll, {
      passive: true
    });
    return () => {
      mainScrollContainerRef.current?.removeEventListener(
        'scroll',
        callbackScroll
      );
    };
  }, [emptyPlaces]);

  const data = useMemo(() => {
    return emptyPlaces.filter((v) =>
      showIds.includes(`${v.startTime}-${v.endTime}`)
    );
  }, [emptyPlaces, showIds]);
  return (
    <div id="empty-cells" className="emptycells-container">
      {data.map((value: any) => {
        return (
          <Item
            key={`empty-cell-${sliceId}-${value.startTime}-${value.endTime}`}
            value={value}
            sliceId={sliceId}
            scheduleTimeProps={scheduleTimeProps}
            memoFuncRef={memoFuncRef}
            scheduleCol={scheduleCol}
          />
        );
      })}
    </div>
  );
};

export default React.memo(Component);

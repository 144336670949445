const ManagementSystem = '/management-system'
const TableauPublisher = '/tableau-publisher'

export const ROUTES = {
    Login: '/login',
    ManagementSystem,
    BuildingBlocks: `${ManagementSystem}/building-blocks`,
    Forms: `${ManagementSystem}/forms`,
    Publisher: `${ManagementSystem}${TableauPublisher}/publisher`,
    AddSources: `${ManagementSystem}${TableauPublisher}/add-sources`,
    UpdateSources: `${ManagementSystem}${TableauPublisher}/update-sources`,
}
import React from 'react';

interface IProps {
  stroke?: string;
}

export const PlusSvg = ({ stroke }: IProps) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99805 1L6.00328 11"
      stroke="#3A5B8B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 6H1"
      stroke="#3A5B8B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Container = styled.div`
    width: 800px;
`

export const Header = styled.div`
    padding: 15px 20px;
    border-bottom: 1px solid ${globalStyleVariables.borderColor};
`

export const Top = styled.div`
    padding: 15px 20px;
    border-bottom: 1px solid ${globalStyleVariables.borderColor};
`

export const Main = styled.div``

export const Bottom = styled.div`
    padding: 15px 20px;
    border-top: 1px solid ${globalStyleVariables.borderColor};
`

export const Name = styled.div`
    font-family: Encode Sans Expanded;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
`

export const SubTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #666666;
    margin: 0 0 20px 0;
`

export const BlockContainer = styled.div`
    height: 360px;
    overflow: auto;
    padding: 20px;
`

export const Info = styled.div`
    margin: 0 0 20px 0;
`
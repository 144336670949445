import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;
export const PaginationContainer = styled.div``;
export const ColumnsContainer = styled.div`
  width: ${({ providerCnt }: ScheduleContentLayoutProps) =>
    providerCnt * DefaultItemWidth + 136}px;
  display: flex;
  padding-left: 96px;
`;

export const ProviderTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Encode Sans Expanded;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
  padding: 10px;
  padding-top: 0;
`;

export const ScrollContainer = styled.div`
  position: relative;
  height: 225px;
  width: ${({ providerCnt }: ScheduleContentLayoutProps) =>
    providerCnt * DefaultItemWidth + 136}px;
  overflow: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const DateTitle = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;

  /* identical to box height, or 114% */
  text-align: center;

  /* Black / 80% */

  color: #333333;
`;

const DefaultItemWidth = 200;

interface ScheduleContentLayoutProps {
  providerCnt: number;
}

export const ScheduleContentLayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  margin-left: 120px;

  .selected-date-container {
    margin-left: 24px;
    width: 114px;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .all-providers-view {
    /* width: calc(100% - 128px);
    overflow-y: hidden; */
  }

  .all-providers-container {
    width: ${({ providerCnt }: ScheduleContentLayoutProps) =>
      providerCnt * DefaultItemWidth}px;
  }

  .provider-container {
    width: 200px;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ClosedClinic = styled.div`
  color: #f9b40b;
  text-align: center;
`;

export const DateProviderTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
`;
export const ColumnHeader = styled.div`
  width: 100%;
`;

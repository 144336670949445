import { useEffect, useState } from "react"
import { useAppSelector } from "src/store"

import * as actions from '../Actions'
import * as Setters from '../Actions/Setters'
import { getCurrentProvider } from "src/Framework/Controls/Selectors/ProviderSelector/utils"
import { generateDefaultHipaaData } from "../../Components/utils"

interface IUseHippaProps {
    patientId: number | string
}
export const useHippa = ({ patientId }: IUseHippaProps) => {
    const [loading, setLoading] = useState(false)
    const data = useAppSelector((state) => Object.values(state.hippa.summary).find(v => `${v.patientId}` === `${patientId}`))
    useEffect(() => {
        const init = async () => {
            setLoading(true)
            const res = await actions.findAndUnsubscribe([patientId], 'patientId')
            if (res?.success) {
                if (Object.values(res.data).length === 0) {
                    const newItem = generateDefaultHipaaData(+patientId, getCurrentProvider())
                    Setters.setSummary({
                        [newItem.id]: newItem
                    })
                } else {
                    Setters.setSummary(res.data)
                }
            }
            setLoading(false)
        }
        init()
    }, [])
    return {
        loading,
        data: data ? data : undefined
    }
}
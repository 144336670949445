import React, { FC } from 'react';

import ElementTitle from 'src/Framework/Controls/ElementTitle';

import Comments from 'src/Activities/Schedule/RightSideBar/WaitingList/Group/GroupItem/StudentItem/Comments/List';
import { IApptResponse } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import Tags from './Tags';

import { TagsContainer } from './styled';

interface IProps {
  data: IApptResponse;
}

const Component: FC<IProps> = ({ data }: IProps) => {
  return (
    <>
      {data.scheduleEvent?.tags && data.scheduleEvent?.tags.length > 0 && (
        <>
          <ElementTitle star={false} name="Tags:" />
          <TagsContainer>
            <Tags data={data.scheduleEvent.tags} />
          </TagsContainer>
        </>
      )}
      <ElementTitle star={false} name="Comments:" />
      <Comments
        removeItemHorizontalMargins={true}
        viewMode={true}
        data={data.scheduleEvent.comments || []}
      />
    </>
  );
};

export default React.memo(Component);

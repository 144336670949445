import React, { FC } from 'react';
import AsyncImage from 'src/Framework/Controls/AsyncImage';

interface IProps {
  getStateValue: () => any;
  readOnly: boolean;
  editorModalRef: any;
}

const Component: FC<IProps> = ({
  getStateValue,
  readOnly,
  editorModalRef
}: IProps) => {
  const value = getStateValue();
  const data = value || {
    documentId: null
  };
  if (data.documentId) {
    return (
      <div
        className="async-image"
        style={{ margin: '10px 0' }}
        onClick={() => {
          if (!readOnly && data.documentId) {
            editorModalRef.current?.show({
              value: {
                id: data.documentId,
                crc: data.crc
              }
            });
          }
        }}
      >
        <AsyncImage
          key={data.crc}
          file={{
            id: data.documentId,
            crc: data.crc
          }}
          width={300}
          height={300}
        />
      </div>
    );
  }
  if (data.defaultDocumentId) {
    return (
      <div
        className="async-image"
        style={{ margin: '10px 0' }}
        onClick={() => {
          if (!readOnly && data.defaultDocumentId) {
            editorModalRef.current?.show({
              value: {
                id: data.defaultDocumentId,
                crc: data.crc
              }
            });
          }
        }}
      >
        <AsyncImage
          key={data.crc}
          file={{
            id: data.defaultDocumentId,
            crc: data.crc
          }}
          width={300}
          height={300}
        />
      </div>
    );
  }
  return null;
};

export default React.memo(Component);

import React, { FC } from 'react';
import DragSourceComponent from './DndDragSource';
import Cell from './Cell';
import { timeDiff } from 'src/Activities/Schedule/Utils/Utils';
import _ from 'lodash';

interface IProps {
  patient: any;
  providerId: any;
  isShowingPatientName: any;
  scheduleTimeProps: any;
  inOverflow?: boolean;
  isWidget: boolean | undefined | null;
  onDelete: Function;
  firstInReservation: boolean;
  sliceId: any;
  lastInReservation?: boolean;
  mainScrollContainerRef?: React.RefObject<HTMLDivElement>;
}

const onStartDrag = () => {};

const Component: FC<IProps> = (props: IProps) => {
  const {
    patient,
    providerId,
    isShowingPatientName,
    scheduleTimeProps,
    inOverflow,
    isWidget,
    onDelete,
    firstInReservation,
    lastInReservation,
    sliceId,
    mainScrollContainerRef
  } = props;
  const { dayStartTime, regularMeetingTime, slotHeight } = scheduleTimeProps;
  const x = inOverflow ? 0 : patient.occupyX;
  const y = inOverflow
    ? 0
    : (timeDiff(patient.startTime, dayStartTime) / regularMeetingTime) *
      slotHeight;
  const width = inOverflow ? 1 : patient.occupyWidth;
  const height = inOverflow
    ? 1
    : (Number(patient.duration) / regularMeetingTime) * slotHeight;
  const isCancelled = patient.scheduleEventTypeId === 4;
  const checkCanDrag =
    !isWidget &&
    !patient.inSeries &&
    patient.uid &&
    !isCancelled &&
    !patient.isOutreach;

  const patientStructure = inOverflow
    ? {
        ...patient,
        overflowing: false,
        inOverflow: true
      }
    : patient;
  return (
    <DragSourceComponent
      x={x}
      y={y}
      patient={patientStructure}
      width={width}
      height={height}
      providerId={providerId}
      canDrag={checkCanDrag}
      onStartDrag={onStartDrag}
      overflow={inOverflow ? false : patient.overflowing}
      isRelative={inOverflow}
      firstInReservation={firstInReservation}
      lastInReservation={lastInReservation}
      mainScrollContainerRef={mainScrollContainerRef}
    >
      <Cell
        sliceId={sliceId}
        height={height}
        onDelete={onDelete}
        patient={patientStructure}
        isShowingPatientName={isShowingPatientName}
        firstInReservation={firstInReservation}
        lastInReservation={lastInReservation}
      />
    </DragSourceComponent>
  );
};

export default React.memo(Component, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
);

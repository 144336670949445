import React, { FC } from 'react';

import Checkbox from 'src/Framework/Controls/Checkbox';

import { IModalData } from '..';

import { Container, Title, SubTitle } from './styled';
import { CheckboxContainer, Item } from '../styled';

interface IProps {
  value: IModalData;
  active: boolean;
  onSelectAll: () => any;
  title: string;
}

const Component: FC<IProps> = ({
  value,
  active,
  onSelectAll,
  title
}: IProps) => {
  return (
    <Container>
      <CheckboxContainer>
        <div>All</div>
        <Checkbox
          id="select-all-checkbox"
          checked={active}
          onChange={onSelectAll}
        >
          <label className="visually-hidden">select all members</label>
        </Checkbox>
      </CheckboxContainer>
      <Item>
        <Title>{title}</Title>
        <SubTitle>{value.patients.length} MEMBERS</SubTitle>
      </Item>
    </Container>
  );
};

export default React.memo(Component);

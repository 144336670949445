import React, { FC } from 'react';
import { ITicketDetailHipaa } from '../../store/types';

import Status from './Status';
import Container from './Container';

export interface ICommon {
  statusModal?: boolean;
  useIcon?: boolean;
  clinicId?: number;
}

interface IProps extends ICommon {
  defaultData?: ITicketDetailHipaa;
  patientId: number | string;
}

const Component: FC<IProps> = ({
  defaultData,
  patientId,
  ...common
}: IProps) => {
  return defaultData ? (
    <Status data={defaultData} {...common} />
  ) : (
    <Container patientId={patientId} {...common} />
  );
};

export default React.memo(Component);

import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import DropTargetComponent from '../DndDropTarget';
import Moment from 'moment';

import Tooltip from 'src/Framework/Controls/Tooltip';

import { Item } from './styled';
import {
  getTimeObjectStringFromSimpleTime,
  timeDiff
} from 'src/Activities/Schedule/Utils/Utils';
import classNames from 'classnames';
import { isInTimeRange } from '../EmptyCell';

import RClickPopup from 'src/Activities/Schedule/Popups/RClickPopup';
import { extendMoment } from 'moment-range';
import {
  DefaultItemHeight,
  RangeTime,
  RangeTimeContainer
} from '../ScheduleCol_Styles';
import { getMilitaryTimeFromStandart } from 'src/Framework/Shared/Shared';
import { debouncer } from 'src/Framework/util/helps';
import {
  EApptTypeCode,
  EEventTypeCode,
  eventPermission
} from 'src/Activities/Schedule/utils';
import { permissionChecking } from 'src/App/User/Permission';

import AppointmentPopup, {
  IModalHandles
} from 'src/Activities/Schedule/Popups/AppointmentPopup/Container';
import EventPopup, {
  IEventModalHandles
} from 'src/Activities/Schedule/Popups/EventPopup';
import BlockReservationPopup, {
  IModalHandles as IBlockReservation
} from 'src/Activities/Schedule/Popups/BlockReservationPopup/Container';
import OutreachModal, {
  IModalHandles as IModalOutreachHandles
} from 'src/Activities/Home/Outreach/Modal';
import { DefaultItemWidth } from '../../Main_Styles';

interface IProps {
  scheduleTimeProps: any;
  value: any;
  sliceId: string;
  scheduleCol: any;
  memoFuncRef: React.MutableRefObject<{
    onDrop: Function;
    onDropWithWaitingPatient: Function;
  }>;
}

const emptyFunc = () => {};

//@ts-ignore
const moment = extendMoment(Moment);

export const getEmptySlotPosition = ({
  value,
  scheduleTimeProps
}: {
  value: any;
  scheduleTimeProps: any;
}) => {
  const itemHeight = DefaultItemHeight();
  return {
    y:
      itemHeight *
        ((timeDiff(value.startTime, scheduleTimeProps.dayStartTime) /
          scheduleTimeProps.regularMeetingTime) *
          scheduleTimeProps.slotHeight) +
      1,
    x: 1,
    height: itemHeight * scheduleTimeProps.slotHeight - 2,
    width: DefaultItemWidth - 1
  };
};

const Component: FC<IProps> = ({
  scheduleTimeProps,
  value,
  memoFuncRef,
  scheduleCol
}: IProps) => {
  const itemRef = useRef<HTMLDivElement>(null);
  const renderedRef = useRef(false);
  const [rendered, setRendered] = useState(false);

  const OutreachPopupRef = useRef<IModalOutreachHandles>(null);
  const ApptPopupRef = useRef<IModalHandles>(null);
  const EventPopupRef = useRef<IEventModalHandles>(null);
  const BlockReservationRef = useRef<IBlockReservation>(null);

  const position = useMemo(() => {
    return getEmptySlotPosition({
      value,
      scheduleTimeProps
    });
  }, [value, scheduleTimeProps]);

  const onMouseEnter = useCallback(() => {
    if (!renderedRef.current) {
      setRendered(true);
      renderedRef.current = true;
    }
  }, []);
  const anyEventPermission = useMemo(
    () =>
      eventPermission(EEventTypeCode.appointment, EApptTypeCode.individual)
        .success ||
      eventPermission(EEventTypeCode.appointment, EApptTypeCode.couple)
        .success ||
      eventPermission(EEventTypeCode.appointment, EApptTypeCode.family)
        .success ||
      eventPermission(EEventTypeCode.appointment, EApptTypeCode.group)
        .success ||
      eventPermission(EEventTypeCode.staffAppointment).success ||
      eventPermission(EEventTypeCode.outreach).success ||
      permissionChecking({
        anyClinic: false,
        clinicId: scheduleCol.clinicId,
        moduleId: 1,
        functionId: 4
      }).success,
    [scheduleCol.clinicId]
  );
  const debounce = useRef(debouncer(150));
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isHovering, setIsHovering] = useState<{
    item: any;
    hover: boolean;
  }>({
    item: null,
    hover: false
  });
  const onHover = useCallback((value: boolean, item: any) => {
    setIsHovering({
      hover: value,
      item: value ? item : null
    });
  }, []);
  const onVisibleChanged = useCallback((value: boolean) => {
    if (!value) {
      setPopoverOpen(false);
    }
  }, []);
  const getReservation = useCallback((emptyRegion: any) => {
    const { date, scheduleGroups, providerCode } = scheduleCol;
    return scheduleGroups.find((s: any) => {
      if (providerCode.toLowerCase() === s.provider.code.toLowerCase()) {
        const resStartTime = moment(
          getTimeObjectStringFromSimpleTime(s.startTime, date)
        );
        const resEndTime = moment(
          getTimeObjectStringFromSimpleTime(s.endTime, date)
        );
        const resRange = moment.range(resStartTime, resEndTime);
        const regionStartTime = moment(
          getTimeObjectStringFromSimpleTime(emptyRegion.startTime, date)
        );
        if (
          resRange.contains(regionStartTime, {
            excludeStart: false,
            excludeEnd: true
          })
        ) {
          return s;
        }
      }
      return false;
    });
  }, []);
  const onClickItem = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    debounce.current(() => {
      if (e?.detail === 1) {
        setPopoverOpen(anyEventPermission && true);
      }
      if (e?.detail === 2) {
        onOpenSingleAppt();
      }
    });
  }, []);

  const outOfTimeRange = !isInTimeRange(
    scheduleCol.clinicId,
    value.startTime,
    value.endTime
  );

  const reservation = getReservation(value);
  const inReservaton = reservation ? true : false;

  const onOpenSingleAppt = () => {
    const apptPermission = eventPermission(EEventTypeCode.appointment);
    if (!apptPermission.success) {
      return;
    }

    const options: any = {
      action: inReservaton ? 'reservedAppt' : '',
      providerCode: scheduleCol.providerCode,
      providerName: scheduleCol.providerName,
      clinicName: scheduleCol.clinicName,
      clinicCode: scheduleCol.clinicCode,
      startTime: value.startTime,
      endTime: value.endTime,
      scheduleEventTypeId: 1,
      typeAppointmentParticipantId: apptPermission.firstAvailableOption,
      uid: '',
      reason: reservation?.reason,
      date: value.date
    };
    ApptPopupRef.current?.show({
      options
    });
  };
  const onOpenEventPopup = () => {
    const options: any = {
      action: '',
      providerCode: scheduleCol.providerCode,
      providerName: scheduleCol.providerName,
      clinicName: scheduleCol.clinicName,
      clinicCode: scheduleCol.clinicCode,
      startTime: value.startTime,
      endTime: value.endTime,
      scheduleEventTypeId: 7,
      typeAppointmentParticipantId: 1,
      uid: '',
      reason: reservation?.reason,
      date: value.date
    };
    EventPopupRef.current?.show({
      options
    });
  };
  const onDropItem = useCallback((v: any, delta: any, clientOffset: any) => {
    memoFuncRef.current.onDrop(v, value.startTime, clientOffset);
  }, []);
  return (
    <Item
      ref={itemRef}
      position={position}
      id={`${value.startTime}-${value.endTime}`}
      onMouseEnter={onMouseEnter}
      outOfTimeRange={outOfTimeRange}
      className={classNames({
        isHovering: isHovering.hover
      })}
    >
      <DropTargetComponent
        onDropWithWaitingPatient={(a: any, item: any) =>
          memoFuncRef.current.onDropWithWaitingPatient(value.startTime, item)
        }
        onDrop={onDropItem}
        onHover={onHover}
        showHover={true}
      >
        {rendered ? (
          <>
            <RClickPopup
              popoverOpen={popoverOpen}
              onVisibleChanged={onVisibleChanged}
              onOpenOutreach={() => {
                OutreachPopupRef.current?.show({
                  calendar: {
                    clinicId: scheduleCol.clinicId,
                    startTime: value.startTime,
                    endTime: value.endTime,
                    date: value.date
                  }
                });
              }}
              outOfTimeRange={outOfTimeRange}
              scheduleCol={scheduleCol}
              inReservaton={reservation ? true : false}
              reservedReason={reservation}
              startTime={value.startTime}
              onClosePopup={emptyFunc}
              onOpenBlockPopup={() => {
                const options: any = {
                  action: '',
                  providerCode: scheduleCol.providerCode,
                  providerName: scheduleCol.providerName,
                  clinicName: scheduleCol.clinicName,
                  clinicCode: scheduleCol.clinicCode,
                  startTime: value.startTime,
                  endTime: value.endTime,
                  scheduleEventTypeId: 2,
                  uid: '',
                  date: value.date,
                  reason: reservation?.reason
                };
                BlockReservationRef.current?.show({
                  options
                });
              }}
              onOpenGroupApptPopup={(typeAppointmentParticipantId: number) => {
                const options: any = {
                  scheduleEventTypeId: 1,
                  action: inReservaton ? 'reservedAppt' : '',
                  providerCode: scheduleCol.providerCode,
                  providerName: scheduleCol.providerName,
                  clinicName: scheduleCol.clinicName,
                  clinicCode: scheduleCol.clinicCode,
                  startTime: value.startTime,
                  endTime: value.endTime,
                  typeAppointmentParticipantId,
                  uid: '',
                  reason: reservation?.reason,
                  date: value.date
                };
                ApptPopupRef.current?.show({
                  options
                });
              }}
              onOpenIndividualApptPopup={() => onOpenSingleAppt()}
              onOpenEventPopup={() => onOpenEventPopup()}
              onOpenReservationPopup={() => {
                const options: any = {
                  action: '',
                  providerCode: scheduleCol.providerCode,
                  providerName: scheduleCol.providerName,
                  clinicName: scheduleCol.clinicName,
                  clinicCode: scheduleCol.clinicCode,
                  startTime: value.startTime,
                  endTime: value.endTime,
                  scheduleEventTypeId: 3,
                  date: value.date,
                  uid: '',
                  reason: reservation?.reason
                };
                BlockReservationRef.current?.show({
                  options
                });
              }}
            >
              <Tooltip
                mouseEnterDelay={0.6}
                title={
                  outOfTimeRange ? 'Out of clinic working time' : undefined
                }
              >
                <RangeTimeContainer onClick={onClickItem}>
                  <RangeTime className="range-time">{`${getMilitaryTimeFromStandart(
                    value.startTime
                  )} - ${getMilitaryTimeFromStandart(
                    value.endTime
                  )}`}</RangeTime>
                </RangeTimeContainer>
              </Tooltip>
            </RClickPopup>
            <AppointmentPopup ref={ApptPopupRef} />
            <EventPopup ref={EventPopupRef} />
            <BlockReservationPopup ref={BlockReservationRef} />
            <OutreachModal ref={OutreachPopupRef} />
          </>
        ) : (
          <RangeTimeContainer />
        )}
      </DropTargetComponent>
    </Item>
  );
};

export default React.memo(Component);

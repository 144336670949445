import * as React from 'react';
import TabSelect from './TabSelect';
import MultiTabSelect from './MultiTabSelect';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import { searchHighlighter } from 'src/Framework/Controls/Table/searchHightLight';

interface IState {
  columns: any;
  receiverColumns: any;
}

interface IProps {
  isMulti: boolean;
  maxSelectionCount?: number;
  onSelectionChanged?: any;
  onFinishSelection: any;
  onCancelSelection: any;
  dispatch?: any;
  data: any;
  selected: any;
  popupRef?: React.MutableRefObject<any>;
  columnsToShow?: string[];
  containerWidth?: number;
  searchText?: string;
}

class SubjectTabulator extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      columns: [
        { title: '#', field: 'subjectId', width: 72 },
        { title: 'Last Name', field: 'lastName', align: 'left', width: 128 },
        { title: 'First Name', field: 'firstName', align: 'left', width: 128 },
        { title: 'Sex', field: 'sex', align: 'left', width: 60 },
        {
          title: 'Preferred',
          field: 'preferredName',
          align: 'left',
          width: 128
        },
        { title: 'Other ID', field: 'otherId', width: 128 },
        { title: 'DOB', field: 'dob', width: 80 }
      ],
      receiverColumns: [
        { title: '#', field: 'subjectId', width: 70 },
        { title: 'Last Name', field: 'lastName', align: 'left', width: 90 },
        { title: 'First Name', field: 'firstName', align: 'left', width: 90 }
      ]
    };
  }

  render() {
    const onSelectorKeyDown = (e: React.KeyboardEvent) => {
      if (
        e.key === 'Escape' ||
        (e.shiftKey &&
          e.key === 'Tab' &&
          e.target === this.props.popupRef?.current)
      ) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onCancelSelection();
      }
    };
    if (!this.state.columns) {
      return '';
    }
    return (
      <div
        tabIndex={0}
        ref={this.props.popupRef}
        onKeyDown={onSelectorKeyDown}
        aria-label={`${getPatientTerm('student')} selecting table`}
      >
        {this.props.isMulti ? (
          <MultiTabSelect
            maxSelectionCount={this.props.maxSelectionCount}
            columns={this.state.columns}
            receiverColumns={this.state.receiverColumns}
            selected={this.props.selected}
            data={this.props.data}
            onSelectionChanged={this.props.onSelectionChanged}
            onFinishSelection={this.props.onFinishSelection}
            onCancelSelection={this.props.onCancelSelection}
            filterSender={false}
            filterReceiver={true}
            keyColumn={'subjectId'}
          />
        ) : (
          <TabSelect
            columns={this.state.columns
              .filter((item: any) => {
                if (!this.props.columnsToShow) {
                  return true;
                } else {
                  return this.props.columnsToShow.includes(item.field);
                }
              })
              .map((item: any, index: number, array: any[]) => ({
                ...item,
                flex: index === array.length - 1 ? 'auto' : item.width + 'px',
                key: item.field,
                centered:
                  !item.align || (item.align && item.align === 'center'),
                sortBy:
                  item.field !== 'dob' ? item.field : 'dobStringForSorting'
              }))}
            selected={this.props.selected}
            data={this.props.data.map((item: any) => ({
              ...item,
              dobStringForSorting: new Date(item.dob).getTime(),
              ...searchHighlighter({
                data: {
                  lastName: item.lastName,
                  firstName: item.firstName
                },
                search: this.props.searchText || ''
              }),
              fullObj: item
            }))}
            onSelection={this.props.onFinishSelection}
            containerWidth={this.props.containerWidth}
          />
        )}
      </div>
    );
  }
}

export default SubjectTabulator;

import * as React from 'react';

import Table from 'src/Framework/Controls/Table';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import AppErrorBoundary from 'src/Framework/Errors/AppErrorBoundary';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';

import HeaderMultiselect, {
  IProps as IHeaderMultiSelect
} from './HeaderMultiselect';

import * as container from './TabSelect_styles';

interface IState {}

interface IProps {
  maxSelectionCount?: number;
  onSelectionChanged?: any;
  onFinishSelection: any;
  onCancelSelection: any;
  dispatch?: any;
  columns?: any;
  receiverColumns?: any;
  data: any;
  selected: any;
  filterSender?: any;
  filterReceiver?: any;
  keyColumn: string;
  headerMultiSelectProps?: Partial<IHeaderMultiSelect>;
}

class MultiTabSelect extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
  }

  removeButton(props: any) {
    return (
      <span className={'remove-cell'}>
        <i className="fas fa-times" text-align={'center'}></i>
      </span>
    );
  }

  onRemoveRowClick(data: any) {
    this.onManageItem(data);
  }

  onManageItem = (value: any) => {
    const data = this.props.selected ? [...this.props.selected] : [];
    const i = data.findIndex((v: any) => v.subjectId === value.subjectId);
    if (i !== -1) {
      data.splice(i, 1);
    } else {
      data.push(value);
    }
    this.props.onSelectionChanged(data);
  };
  onRowClick = (value: any) => {
    if (
      this.props.maxSelectionCount &&
      this.props.maxSelectionCount > 0 &&
      this.props.selected &&
      this.props.maxSelectionCount <= this.props.selected.length
    ) {
      return;
    }
    this.onManageItem(value);
  };

  render() {
    const { headerMultiSelectProps, data } = this.props;
    let sel = this.props.selected || [];
    let selCount = sel.length ? sel.length : 0;
    let maxSel = this.props.maxSelectionCount
      ? this.props.maxSelectionCount
      : 0;
    if (!data) return '';
    return (
      <AppErrorBoundary>
        <container.TabulatorWrapper
          disable={maxSel > 0 && selCount > maxSel ? 'true' : 'false'}
          visibility={'true'}
        >
          <container.Container
            id="multi-select"
            role="tablist"
            onKeyDown={onKeyDownAccessibility}
          >
            <container.TableContainer tabIndex={0} role="tab">
              <Table
                horizontalItemPadding={16}
                containerId="patient-table"
                bodyContainerId="patient-table"
                absoluteContainer={true}
                data={this.props.data.map((v: any) => ({
                  ...v,
                  id: v.subjectId
                }))}
                columns={columns}
                onKeyDownRow={(e) => {
                  e.stopPropagation();
                }}
                onClickRow={(i, v) => this.onRowClick(v)}
              />
            </container.TableContainer>
            <container.ReceiverOptionsContainer role="tab" tabIndex={0}>
              <HeaderMultiselect
                maxSel={maxSel}
                onCancelSelection={this.props.onCancelSelection}
                onFinishSelection={this.props.onFinishSelection}
                sel={sel}
                {...headerMultiSelectProps}
              />
              <Table
                horizontalItemPadding={4}
                containerId="patient-table"
                bodyContainerId="patient-table"
                absoluteContainer={true}
                data={sel.map((item: any) => ({
                  ...item,
                  delete: (
                    <ButtonIcon
                      isButton={true}
                      label="delete item"
                      name={ICONS_LIST.thinClose}
                      size={16}
                      disableBackground={true}
                      changeColorOnHover={true}
                      onClick={(e) => this.onRemoveRowClick(item)}
                    />
                  )
                }))}
                columns={selectedColumns}
                onKeyDownRow={(e) => {
                  e.stopPropagation();
                }}
                onClickRow={(i, v) => this.onRemoveRowClick(v)}
              />
            </container.ReceiverOptionsContainer>
          </container.Container>
        </container.TabulatorWrapper>
      </AppErrorBoundary>
    );
  }
}

export default MultiTabSelect;

const columns = [
  {
    flex: '80px',
    title: '#',
    key: 'subjectId'
  },
  {
    title: 'Last Name',
    key: 'lastName',
    flex: '120px'
  },
  {
    title: 'First Name',
    key: 'firstName',
    flex: '120px'
  },
  {
    title: 'Sex',
    key: 'sex',
    flex: '60px'
  },
  {
    title: 'Preferred',
    key: 'nickName',
    flex: 'auto'
  },
  {
    title: 'Other ID',
    key: 'otherId',
    flex: '120px'
  },
  {
    title: 'DOB',
    key: 'dob',
    flex: '100px'
  }
];
const selectedColumns = [
  {
    flex: '70px',
    title: '#',
    key: 'subjectId'
  },
  {
    title: 'Last Name',
    key: 'lastName',
    flex: '100px',
    ellipsis: true
  },
  {
    title: 'First Name',
    key: 'firstName',
    flex: '100px',
    ellipsis: true
  },
  {
    flex: '20px',
    title: '',
    key: 'delete'
  }
];

import React, { FC } from 'react';
import { connect } from 'react-redux';

import { IStore } from 'src/store';
import { RiskGroup } from 'src/App/Admin/Pages/TypeRisks/store/types';

import { RiskContainer } from './styled';
import { pickTextColorBasedOnBgColorSimple } from 'src/Framework/util/helps';

interface IOwnProps {
  data: number[];
  riskType: RiskGroup;
}
interface IProps extends IOwnProps {
  typeRisks: IStore['typeRisks'];
}

const Component: FC<IProps> = ({
  data,
  riskType,
  typeRisks
}: IProps) => {
  let component = null;
  data.forEach((v) => {
    const find = typeRisks.summary.find(
      (val) => val.id === v && val.riskGroup === riskType
    );
    if (find) {
      component = (
        <RiskContainer
          color={find.riskColor}
          style={{
            color: pickTextColorBasedOnBgColorSimple(find.riskColor)
          }}
        >
          {find.name}
        </RiskContainer>
      );
    }
  });
  return component;
};
const mapStateToProps = (state: IStore, ownProps: IOwnProps) => {
  return {
    typeRisks: state.typeRisks
  };
};

export default connect(mapStateToProps)(Component);

import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { ReqOrderResult } from '../types';

interface IGetParams {
    reqOrderId: number
}

export const get = async ({
    reqOrderId
}: IGetParams): Promise<ReqOrderResult[] | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.get(
            `${ApiUrl.ReqOrders}/${reqOrderId}/results`,
            {
                withCredentials: true,
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result.summary;
                return data;
            }
        }
        return;
    } catch (e) {
        return;
    } finally {
    }
};

export const post = async (
    data: Partial<ReqOrderResult> & {
        reqOrderId: number
    }
): Promise<ReqOrderResult | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.post(
            `${ApiUrl.ReqOrders}/${data.reqOrderId}/results`,
            data,
            {
                withCredentials: true
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result;
                return data;
            }
        }
        return;
    } catch (e) {
        return;
    }
};

export const put = async (
    data: ReqOrderResult
): Promise<ReqOrderResult | undefined> => {
    try {
        const response = await APIHandler.AxiosInstance.put(
            `${ApiUrl.ReqOrders}/${data.reqOrderId}/results/${data.id}`,
            data,
            {
                withCredentials: true
            }
        );
        if (response.data) {
            if (response.data.success) {
                const data = response.data.result;
                return data;
            }
        }
        return;
    } catch (e) {
        return;
    }
};
import React from 'react';
import _ from 'lodash';
import { Row as ARow, Col } from 'antd';
import moment from 'moment';

import SelectContainer from 'src/Framework/Controls/SelectContainer';
import {
  ClinicSelector,
  ProviderSelector
} from 'src/Framework/Controls/Selectors';
import {
  getClinicConfig,
  getProviderByCode
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import Button from 'src/Framework/Controls/Button';
import { getClinicByCode } from 'src/Framework/Controls/Selectors/ClinicSelector';
import ReasonSelector from 'src/Framework/Controls/Selectors/ReasonSelector';
import { getReasonByID } from 'src/Framework/Controls/Selectors/ReasonSelector/utils';
import GroupAppointmentReason from 'src/Framework/Controls/Selectors/GroupAppointmentReason';
import { groupAppointmentReasonById } from 'src/Framework/Controls/Selectors/GroupAppointmentReason/utils';
import ColorSelector from 'src/Framework/Controls/Selectors/ColorSelector';
import ConditionalButton from 'src/Framework/Controls/ConditionalButton';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import Table from 'src/Framework/Controls/Table';
import RemoveButton from 'src/Framework/Controls/Table/RemoveButton';
import HistoryPopUp from 'src/Framework/Controls/HistoryPopUp';
import Collapse from 'src/Framework/Controls/CollapseA11y';
import AlertPopUp, { IModalHandles } from 'src/Framework/Controls/AlertPopUp';
import ElementTitle from 'src/Framework/Controls/ElementTitle';
import Checkbox from 'src/Framework/Controls/Checkbox';
import { formattedDateTime } from 'src/Framework/Shared/Shared';
import test_ids from 'src/tests-script/pages/Schedule/CreateReservationOrBlock';

import {
  PopupContent,
  Row,
  AlignLeft,
  AlignRight
} from 'src/Activities/Schedule/Common/Classes';
import { Option } from 'src/Activities/Schedule/Common/Option/Option';
import SeriesEditor from 'src/Activities/Schedule/Common/Editors/SeriesEditor';
import { RecurrenceDisplay } from 'src/Activities/Schedule/Common/Editors/RecurrenceDisplay';
import MinuteHourDayEditor from 'src/Activities/Schedule/Common/Editors/MinuteHourDayEditor';
import { ConflictErrorBlock } from 'src/Activities/Schedule/Common/Editors/ConflictErrorBlock';
import {
  findOpt,
  getScheduleEventTypeName,
  momentDateHandFormatter,
  validateClinicOpenByDate,
  mapClinic
} from 'src/Activities/Schedule/Utils/Utils';
import { getAppointmentLog } from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import { permissionChecking } from 'src/App/User/Permission';
import {
  getDateTimeSelection,
  getEndTime,
  getProviderSelection,
  setEventEndTime,
  setEventStartTime,
  validateEndTimeRule,
  validateStartTimeRule
} from '../AppointmentPopup/utils';
import { IEventPayload } from '../AppointmentPopup/types';
import { IProps as IContainerProps } from './index';

import {
  ErrorBlock,
  TitleContainer,
  PopupBody,
  PopupTitle,
  ReasonSelectContainer,
  ClinicSelectContainer,
  ProviderSelectContainer,
  ReleaseBeforeCheckBoxContainer,
  BlockReservationTypeContainer,
  NoteContainer,
  Block,
  Info
} from './BlockReservationPopup_Styles';
import {
  OptionContainer,
  ClinicInfo,
  PatientInfo
} from '../AppointmentPopup/AppointmentPopup_Styles';

const { Panel } = Collapse;

interface IState {
  showColorPicker: boolean;
  newEventPayload: IEventPayload;
  option: string;
  error: any;
  filteredProviderOptions: any[];
  addColor: boolean;
  date: any;
  isSelectingRecurringAppt: boolean;
  selectedGroupIds: any;
  conflicts: any;
  logs: any[];
  formIsValid: boolean;
}

interface IProps extends IContainerProps {
  groupReasons: any[];
  conflicts: any;
  canAdd: boolean;
  onSaveEvent: any;
  onDeleteEvent: any;
  eventInfo: any;
  action?: string;
}

class BlockReservationPopup extends React.Component<IProps, IState> {
  public oldEventPayload: any;
  public componentRef: React.RefObject<any>;
  AlertPopUpRef = React.createRef<IModalHandles>();

  constructor(props: any) {
    super(props);
    let { eventInfo, actionOption } = this.props;
    this.componentRef = React.createRef();

    let newEventPayload = { ...eventInfo };
    if (actionOption.updateSeries) {
      newEventPayload.updateSeries = true;
    }
    this.state = {
      showColorPicker: false,
      selectedGroupIds: [],
      newEventPayload: { ...newEventPayload },
      isSelectingRecurringAppt: false,
      filteredProviderOptions: [],
      addColor: false,
      option: 'type',
      date: moment(eventInfo.scheduleEvent.scheduleEventDateTime).format(
        'MM/DD/YYYY'
      ),
      conflicts: { ...this.props.conflicts },
      error: {
        clinic: false,
        provider: false,
        reason: false,
        startTime: false,
        duration: false,
        startDate: false,
        clinicClosed: false
      },
      logs: [],
      formIsValid: false
    };
  }

  onChangeOption = (value: boolean) => {
    const { newEventPayload } = this.state;
    const data = _.cloneDeep(newEventPayload);
    data.scheduleEvent.scheduleEventBlock.isGroupReservation = value;
    this.setState({
      newEventPayload: data
    });
  };

  onColorChange(value: any) {
    let { newEventPayload } = this.state;
    newEventPayload.scheduleEvent.scheduleEventBlock.color = value;
    this.setState({
      newEventPayload: newEventPayload
    });
  }

  onOptionChange(value: any) {
    this.setState(
      {
        option: value
      },
      () => {
        const error = _.cloneDeep(this.state.error);
        error.reason = false;
        this.setState({
          error
        });
      }
    );
  }

  componentDidUpdate(prevProps: any, prevState: IState) {
    if (
      this.props.eventInfo &&
      !_.isEqual(this.props.eventInfo, prevProps.eventInfo)
    ) {
      this.setState({
        newEventPayload: { ...this.props.eventInfo }
      });
    }
    if (
      this.props.conflicts &&
      !_.isEqual(this.props.conflicts, prevProps.conflicts)
    ) {
      this.setState({
        conflicts: { ...this.props.conflicts }
      });
    }

    if (this.state.newEventPayload !== prevState.newEventPayload) {
      this.validateClinicOpen();
      this.validationCheck();
    }
  }

  validateClinicOpen = () => {
    const clinicOpen = validateClinicOpenByDate(
      this.state.newEventPayload.scheduleEvent.clinicCode,
      this.state.newEventPayload.scheduleEvent.series.startDate
    );
    this.setState({
      error: {
        ...this.state.error,
        clinicClosed: !clinicOpen
          ? 'The clinic is closed on the selected date'
          : false
      }
    });
  };

  onClinicChange = (value: string) => {
    const { clinics } = this.props;
    let { newEventPayload, error } = this.state;
    const selectedClinic = clinics.find((v) => v.code === value);
    if (selectedClinic) {
      newEventPayload.scheduleEvent.scheduleEventDateTime =
        validateStartTimeRule(
          selectedClinic,
          newEventPayload.scheduleEvent.scheduleEventDateTime
        );
      newEventPayload.scheduleEvent.endTime = validateEndTimeRule(
        selectedClinic,
        newEventPayload.scheduleEvent.scheduleEventDateTime,
        newEventPayload.scheduleEvent.endTime,
        true
      );
      newEventPayload.scheduleEvent.clinicCode = value;
      error.clinic = false;
      this.setState({
        newEventPayload: {
          ...newEventPayload,
          conflictsResponses: []
        },
        error: error,
        conflicts: {
          ...this.state.conflicts,
          hasConflicts: false
        }
      });
    }
  };

  onShowColorPickerChange() {
    if (this.state.showColorPicker) {
      this.setState({
        showColorPicker: true
      });
    }
  }

  onGroupReasonChange(value: any, newEventPayload: any) {
    let reason = {
      id: undefined,
      isShownToConsumer: false,
      ccmhScheduleCategoryId: undefined,
      clinicCode: undefined,
      color: '#000000',
      name: value.name,
      consumerName: undefined,
      groupScheduleReasonName: value.name,
      groupScheduleReasonId: value.id,
      inactive: false,
      code: '',
      confirmationReason: undefined,
      scheduleReasonTypeId: 1,
      duration: undefined,
      isConfirmationRequired: false,
      isPortalReservationSearching: false,
      isTimeOff: false,
      consumerSpecialInstruction: undefined
    };
    newEventPayload.scheduleEvent.reason = reason;
    newEventPayload.scheduleEvent.scheduleEventBlock.groupId = value.id;
    newEventPayload.scheduleEvent.scheduleEventBlock.reasonCode = '';
  }

  onScheduleReasonChange(
    selectedReason: any,
    clinic: any,
    newEventPayload: any
  ) {
    if (selectedReason) {
      newEventPayload.scheduleEvent.scheduleEventBlock.color =
        selectedReason.color;
      newEventPayload.scheduleEvent.reason = selectedReason;
      newEventPayload.scheduleEvent.series.endTime = getEndTime(
        clinic,
        newEventPayload.scheduleEvent.series.endTime,
        newEventPayload.scheduleEvent.series.startTime,
        selectedReason.duration
      );
      if (newEventPayload.scheduleEvent.scheduleEventDateTime) {
        newEventPayload.scheduleEvent.scheduleEventDateTime =
          validateStartTimeRule(
            clinic,
            newEventPayload.scheduleEvent.scheduleEventDateTime
          );
        newEventPayload.scheduleEvent.endTime = getEndTime(
          clinic,
          newEventPayload.scheduleEvent.endTime,
          newEventPayload.scheduleEvent.scheduleEventDateTime,
          selectedReason.duration
        );
      }
    } else {
      newEventPayload.scheduleEvent.scheduleEventBlock.color =
        selectedReason.color;
    }
  }

  onReasonChange = (value: any, clinic: any) => {
    let { newEventPayload } = this.state;
    const isGroupReservation =
      newEventPayload.scheduleEvent.scheduleEventBlock.isGroupReservation;
    if (!isGroupReservation) {
      this.onScheduleReasonChange(value, clinic, newEventPayload);
    } else {
      this.onGroupReasonChange(value, newEventPayload);
    }
    this.setState({
      newEventPayload: {
        ...newEventPayload,
        conflictsResponses: []
      },
      error: {
        ...this.state.error,
        reason: false
      },
      conflicts: {
        ...this.state.conflicts,
        hasConflicts: false
      }
    });
  };

  onStartTimeChange(value: any, clinic: any) {
    let { newEventPayload, error } = this.state;
    let updatedPayload = setEventStartTime(newEventPayload, clinic, value);
    error.startTime = false;
    this.setState({
      newEventPayload: {
        ...updatedPayload,
        conflictsResponses: []
      },
      error: error,
      conflicts: {
        ...this.state.conflicts,
        hasConflicts: false
      }
    });
  }

  onEndTimeChange(value: any, clinic: any) {
    let { newEventPayload, error } = this.state;
    let updatedPayload = setEventEndTime(newEventPayload, clinic, value);
    this.setState({
      newEventPayload: {
        ...updatedPayload,
        conflictsResponses: []
      },
      error: {
        ...error,
        duration: false
      },
      conflicts: {
        ...this.state.conflicts,
        hasConflicts: false
      }
    });
  }

  onSeriesUpdated(payload: any) {
    this.setState({ newEventPayload: payload });
  }

  getLogs = async () => {
    try {
      const eventData = this.props.eventInfo.scheduleEvent;
      const response = await getAppointmentLog({
        appointmentId: eventData.appointmentBlockId,
        scheduleEventTypeId: eventData.scheduleEventTypeId
      });
      if (response.data.success) {
        this.setState({
          logs: response.data.result
        });
      }
    } catch (e) {
    } finally {
    }
  };

  componentDidMount() {
    this.oldEventPayload = _.cloneDeep(this.props.eventInfo);
    this.validateClinicOpen();
    if (this.props.action === 'edit') {
      this.getLogs();
    }
  }

  onSaveEvent = () => {
    const { newEventPayload } = this.state;
    const { scheduleEvent } = newEventPayload;
    if (scheduleEvent.scheduleEventTypeId === 2) {
      const reason = getReasonByID(scheduleEvent.reason.id);
      if (!reason || !reason.isActive) {
        return this.AlertPopUpRef.current?.show({
          content: 'Block Type is no longer valid',
          action: 'info',
          hideClose: true
        });
      }
    }
    if (!newEventPayload.scheduleEvent.scheduleEventBlock.isGroupReservation) {
      newEventPayload.scheduleEvent.scheduleEventBlock.groupId = null;
    }
    this.props.onSaveEvent(newEventPayload, this.oldEventPayload);
  };

  onStartDateChange(value: string) {
    let error = this.state.error;
    error.startDate = false;
    let { newEventPayload } = this.state;
    let scheduleEvent = newEventPayload.scheduleEvent;
    let date = momentDateHandFormatter(value);
    if (scheduleEvent['scheduleEventDateTime']) {
      scheduleEvent['scheduleEventDateTime'] =
        date +
        scheduleEvent['scheduleEventDateTime'].substring(
          scheduleEvent['scheduleEventDateTime'].indexOf('T'),
          scheduleEvent['scheduleEventDateTime'].length
        );
      if (this.props.action !== 'edit') {
        scheduleEvent['series'] = {
          ...scheduleEvent['series'],
          startDate: date + 'T00:00:00',
          startTime: scheduleEvent['scheduleEventDateTime']
        };
      }
    }
    if (scheduleEvent['endTime']) {
      scheduleEvent['endTime'] =
        date +
        scheduleEvent['endTime'].substring(
          scheduleEvent['endTime'].indexOf('T'),
          scheduleEvent['endTime'].length
        );
      if (this.props.action !== 'edit') {
        scheduleEvent['series'] = {
          ...scheduleEvent['series'],
          endTime: scheduleEvent['endTime']
        };
      }
    }
    const clinicOpen = validateClinicOpenByDate(
      this.state.newEventPayload.scheduleEvent.clinicCode,
      scheduleEvent.series.startDate
    );
    error.clinicClosed = clinicOpen
      ? false
      : 'The clinic is closed on the selected date';
    this.setState({
      newEventPayload: {
        ...newEventPayload,
        conflictsResponses: []
      },
      date: value,
      error: error,
      conflicts: {
        ...this.state.conflicts,
        hasConflicts: false
      }
    });
  }

  onGroupProviderChange(goodOptions: any, selectedGroupIds: any) {
    this.setState({
      filteredProviderOptions: goodOptions,
      selectedGroupIds: selectedGroupIds,
      conflicts: {
        ...this.state.conflicts,
        hasConflicts: false
      }
    });
  }

  onOpenColorPicker(e: any) {
    e.preventDefault();
    if (!this.state.showColorPicker) {
      this.setState({
        showColorPicker: true
      });
    }
  }

  onDropdownVisibleChange = () => {
    this.setState({
      showColorPicker: false,
      addColor: false
    });
  };

  onSelectFromDefaultPicker(e: any, color: any) {
    e.preventDefault();
    this.onColorChange(color);
  }

  onSelectFromColorPicker(value: any) {
    this.onColorChange(value);
  }

  onAddNewColor = (e: any) => {
    e.preventDefault();
    this.setState({
      addColor: true
    });
  };

  onSelectRecurring = (open: boolean) => {
    if (open) {
      this.setState({
        isSelectingRecurringAppt: true,
        conflicts: {
          ...this.state.conflicts,
          hasConflicts: false
        }
      });
    }
  };

  onReleaseBeforeStartTimeChange = (releaseBeforeStartTime: boolean) => {
    const { newEventPayload } = this.state;
    const newEvent = _.cloneDeep(newEventPayload);
    newEvent.scheduleEvent.scheduleEventBlock.releaseBeforeStartTime =
      releaseBeforeStartTime;
    this.setState({
      newEventPayload: newEvent
    });
  };

  onEndSelectRecurring = () => {
    this.setState({
      isSelectingRecurringAppt: false
    });
  };
  onClickOverride = () => {
    let { conflicts, canAdd } = this.props;
    if (conflicts && !canAdd) {
      let conflictResponse = conflicts.conflictResponse;
      conflictResponse.map((item: any, indexL: any) => {
        conflictResponse[indexL]['dialogResponse'] = 'override';
      });
      this.props.onSaveEvent(
        {
          ...this.state.newEventPayload,
          conflictsResponses: conflictResponse
        },
        this.oldEventPayload
      );
    }
  };
  onProvidersChange = (values: string[]) => {
    const { error, newEventPayload } = this.state;
    const clinicCode = newEventPayload.scheduleEvent.clinicCode;
    const clinic = getClinicByCode(clinicCode);
    if (clinic) {
      const providerCodes: any[] = [];
      values.forEach((code) => {
        const provider = getProviderByCode(code);
        if (provider) {
          const maxPages = getClinicConfig(provider, clinic.id)?.maxPages || 1;
          providerCodes.push({
            id: provider.providerId,
            code: provider.code,
            page: maxPages,
            name: provider.lastName
          });
        }
      });
      newEventPayload['providerCodes'] = providerCodes;
      error.provider = false;
      this.setState({
        newEventPayload: {
          ...newEventPayload,
          conflictsResponses: []
        },

        error: error,
        conflicts: {
          ...this.state.conflicts,
          hasConflicts: false
        }
      });
    }
  };

  onReleaseBeforeIntervalChange(value: any, type: string) {
    let newEventPayload = _.cloneDeep(this.state.newEventPayload);
    if (
      !newEventPayload['scheduleEvent']['scheduleEventBlock'][
        'releaseBeforeInterval'
      ]
    ) {
      newEventPayload['scheduleEvent']['scheduleEventBlock'][
        'releaseBeforeInterval'
      ] = {};
    }
    newEventPayload['scheduleEvent']['scheduleEventBlock'][
      'releaseBeforeInterval'
    ][type] = value;
    this.setState({
      newEventPayload: newEventPayload
    });
  }

  validateReason = () => {
    const { newEventPayload } = this.state;
    const isGroupReservation =
      newEventPayload.scheduleEvent.scheduleEventBlock.isGroupReservation;
    let eventType = getScheduleEventTypeName(
      newEventPayload.scheduleEvent.scheduleEventTypeId
    );
    let selectedReason;
    if (eventType === 'Block' || !isGroupReservation) {
      selectedReason = newEventPayload.scheduleEvent.reason?.id;
    } else {
      selectedReason =
        newEventPayload.scheduleEvent.scheduleEventBlock?.groupId;
    }
    return !selectedReason ? true : false;
  };

  formValidation = () => {
    const { newEventPayload } = this.state;
    const error = _.cloneDeep(this.state.error);
    if (!newEventPayload?.scheduleEvent?.clinicCode) error.clinic = true;
    error.provider = !(
      newEventPayload.providerCodes && newEventPayload.providerCodes.length > 0
    );
    error.reason = this.validateReason();
    error.date = !newEventPayload.scheduleEvent.scheduleEventDateTime;
    error.startTime = !newEventPayload.scheduleEvent.scheduleEventDateTime;
    error.endTime = !newEventPayload.scheduleEvent.endTime;
    return error;
  };

  noErrorCheck(obj: any) {
    let check = Object.keys(obj).every((item: any) => obj[item] === false);
    return check;
  }

  validationCheck() {
    let error = this.formValidation();
    const isEventRecurring =
      this.state.newEventPayload.scheduleEvent?.series?.typeFrequencyId > 0;
    this.setState({
      error: error,
      formIsValid:
        this.noErrorCheck(error) ||
        (isEventRecurring &&
          error.clinicClosed === 'The clinic is closed on the selected date')
    });
  }

  onClickSave() {
    this.onSaveEvent();
  }

  onAppointmentNoteChange = (comment: string) => {
    const { newEventPayload } = this.state;
    const scheduleEvent = newEventPayload.scheduleEvent;
    this.setState({
      newEventPayload: {
        ...newEventPayload,
        scheduleEvent: { ...scheduleEvent, comment }
      }
    });
  };

  render() {
    const { providers, clinics, canAdd, action, actionOption } = this.props;
    const { isSelectingRecurringAppt, newEventPayload, error } = this.state;
    const isGroupReservation =
      newEventPayload.scheduleEvent.scheduleEventBlock.isGroupReservation;
    let showRecurringButton = true;
    const clinicOpts = clinics.map((c) => mapClinic(c));
    const isCreation = actionOption.action !== 'edit';
    const isEventRecurring =
      newEventPayload.scheduleEvent?.series?.typeFrequencyId > 0;
    let showRecurringText = false;
    let updateSeries = actionOption.updateSeries;
    if (action === 'edit') {
      showRecurringText = updateSeries && isEventRecurring ? true : false;
    } else {
      showRecurringText = isEventRecurring;
    }
    const selectedClinic = findOpt(
      clinicOpts,
      newEventPayload.scheduleEvent.clinicCode
    );
    const providerSelection = getProviderSelection(
      providers,
      selectedClinic,
      newEventPayload.providerCodes
    );
    let { providerOpts, selectedProviders } = providerSelection;

    let eventType = getScheduleEventTypeName(
      newEventPayload.scheduleEvent.scheduleEventTypeId
    );
    let selectedReason: any = {};
    if (eventType === 'Block' || !isGroupReservation) {
      selectedReason = getReasonByID(newEventPayload.scheduleEvent.reason?.id);
    } else {
      const groupId = newEventPayload.scheduleEvent.scheduleEventBlock?.groupId;
      selectedReason = groupId ? groupAppointmentReasonById(groupId) : null;
    }
    let releaseBeforeStartTime =
      newEventPayload.scheduleEvent.scheduleEventBlock
        ?.releaseBeforeStartTime ?? false;

    let releaseBeforeInterval =
      newEventPayload.scheduleEvent.scheduleEventBlock?.releaseBeforeInterval;

    const dateTimeSelection = getDateTimeSelection(
      selectedClinic,
      newEventPayload
    );

    const { startTimeOptions, endTimeOptions, startDateTime, endTimeOpt } =
      dateTimeSelection;

    let startDate = this.state.date;
    if (startDateTime) {
      startDate = startDateTime.format('MM/DD/YYYY');
    }
    let chosenColor = '#CCCCCC';
    chosenColor = newEventPayload.scheduleEvent.scheduleEventBlock?.color;
    if (!chosenColor) {
      chosenColor = newEventPayload.scheduleEvent.reason?.color;
    }
    if (!chosenColor) {
      chosenColor = '#CCCCCC';
    }
    let disableDateTimeSelect =
      (newEventPayload.providerCodes &&
        newEventPayload.providerCodes.length == 0) ||
      !selectedClinic ||
      !selectedReason;

    showRecurringButton =
      action !== 'edit' ||
      (action === 'edit' && updateSeries && isEventRecurring)
        ? true
        : false;

    let popUpTitle = eventType;
    if (isEventRecurring) {
      popUpTitle = `${eventType} Series`;
      if (action === 'edit' && !updateSeries) {
        popUpTitle = `${eventType} Series - Editing single`;
      }
    }

    let scheduleEvent = newEventPayload.scheduleEvent;
    let pastDay = false;
    let pastTime = false;
    if (scheduleEvent.scheduleEventDateTime && this.props.action !== 'edit') {
      let today = moment(new Date()).format('YYYY-MM-DD') + 'T' + '00:00:00';
      let selectedDate =
        moment(scheduleEvent.scheduleEventDateTime).format('YYYY-MM-DD') +
        'T' +
        '00:00:00';
      if (moment(selectedDate).isBefore(today)) {
        pastDay = true;
      }
      if (moment(scheduleEvent.scheduleEventDateTime).isBefore(new Date())) {
        pastTime = true;
      }
    }
    const recurringDisabled = isGroupReservation && !endTimeOpt;
    const { scheduleEventDateTime, endTime } = newEventPayload.scheduleEvent;
    const startTimeValue = scheduleEventDateTime
      ? moment(scheduleEventDateTime).format('HH:mm')
      : null;
    const endTimeValue = endTime ? moment(endTime).format('HH:mm') : null;

    const renderItemDataLog = (item: any, index: number) => {
      return {
        ...item,
        date: moment(item.dateModified).format('MM/DD/YYYY'),
        time: moment(item.dateModified).format('hh:mm A'),
        user: item.userCode,
        event: item.action,
        log: item.differences.length > 0 && (
          <HistoryPopUp
            isLast={index === this.state.logs?.length - 1}
            parentRef={this.componentRef}
            buttonProps={{
              name: ICONS_LIST.legend,
              iconScale: 1.7
            }}
            tooltipProps={{
              placement: 'leftTop'
            }}
            contentProps={{
              data: item.differences.map((v: any) => ({
                ...v,
                oldValue:
                  (v.name.toLowerCase().includes('date') ||
                    v.name.toLowerCase().includes('time')) &&
                  moment(v.oldValue).isValid()
                    ? formattedDateTime(v.oldValue)
                    : v.oldValue,
                newValue:
                  (v.name.toLowerCase().includes('date') ||
                    v.name.toLowerCase().includes('time')) &&
                  moment(v.newValue).isValid()
                    ? formattedDateTime(v.newValue)
                    : v.newValue
              })),
              columns: [
                {
                  title: 'Field',
                  flex: 'auto',
                  key: 'name'
                },
                {
                  title: 'Before',
                  flex: '170px',
                  key: 'oldValue'
                },
                {
                  title: 'After',
                  flex: '170px',
                  key: 'newValue'
                }
              ]
            }}
          />
        )
      };
    };
    const onKeyDownContainer = (e: React.KeyboardEvent) => {
      // if (e.key === 'Enter') {
      //   e.stopPropagation();
      // }
      if (e.shiftKey && e.code === 'Tab' && e.target === e.currentTarget) {
        e.preventDefault();
      }
      if (
        !e.shiftKey &&
        e.code === 'Tab' &&
        (e.target as HTMLElement).className.includes('ant-collapse-header') &&
        (e.target as HTMLElement).parentElement?.nextElementSibling === null &&
        (!(e.target as HTMLElement).parentElement?.className.includes(
          'ant-collapse-item-active'
        ) ||
          this.state.logs.length === 0)
      ) {
        if (this.componentRef?.current) {
          e.preventDefault();
          (this.componentRef?.current as HTMLElement).focus();
        }
      }
    };

    const editPermission = permissionChecking({
      anyClinic: true,
      moduleId: 1,
      functionId: 4
    });

    return (
      <>
        <AlertPopUp ref={this.AlertPopUpRef} />
        <PopupContent
          id={test_ids.CONTAINER}
          tabIndex={0}
          ref={this.componentRef}
          onKeyDown={onKeyDownContainer}
        >
          <ARow align="middle" justify="space-between" className={'header'}>
            <Col>
              <PopupTitle style={{ display: 'flex' }}>
                {popUpTitle}
                {isSelectingRecurringAppt ? (
                  <PatientInfo>
                    <ClinicInfo>
                      <ARow align="middle">
                        <TitleContainer
                          style={{
                            marginRight: '1rem',
                            fontWeight: 'bold'
                          }}
                        >
                          <label className={'category-label'}>Clinic:</label>
                        </TitleContainer>
                        <span className={'value-label'}>
                          {selectedClinic.label}
                        </span>
                      </ARow>
                      <ARow align="middle">
                        <TitleContainer
                          style={{
                            marginRight: '1rem',
                            fontWeight: 'bold'
                          }}
                        >
                          <label className={'category-label'}>Type:</label>
                        </TitleContainer>
                        <span className={'value-label'}>
                          {selectedReason ? selectedReason.name : ''}
                        </span>
                      </ARow>
                    </ClinicInfo>
                  </PatientInfo>
                ) : null}
              </PopupTitle>
            </Col>
            <Col style={{ padding: 5 }}>
              <ARow align="middle" gutter={10}>
                <Col>
                  {this.props.action === 'edit' && (
                    <RemoveButton
                      id={test_ids.removeButton}
                      removeAction={async () => {
                        this.AlertPopUpRef.current?.show({
                          onConfirm: () => {
                            this.props.onDeleteEvent(this.oldEventPayload);
                          },
                          content: 'Are you sure you want to remove the event?',
                          action: 'delete'
                        });
                      }}
                    />
                  )}
                </Col>
                <Col>
                  {!isSelectingRecurringAppt ? (
                    <div className={'popup-buttons-container'}>
                      <Button
                        onClick={() => {
                          if (!canAdd) {
                            this.onClickSave();
                          }
                        }}
                        className={'popup-save-button'}
                        id={test_ids.saveButton}
                        loading={canAdd}
                        disabled={
                          !editPermission.success ||
                          !this.state.formIsValid ||
                          this.state.conflicts.hasConflicts
                        }
                        tooltip={{
                          title: editPermission.success
                            ? undefined
                            : editPermission.message
                        }}
                      >
                        Save
                      </Button>
                      <ButtonIcon
                        name={ICONS_LIST.thinClose}
                        isButton={true}
                        label="close dialog"
                        id={test_ids.closeButton}
                        onClick={() => this.props.onClosePopup()}
                        disableBackground={true}
                        size={28}
                      />
                    </div>
                  ) : null}
                </Col>
              </ARow>
            </Col>
          </ARow>
          <PopupBody>
            {!isSelectingRecurringAppt && (
              <Info>
                <BlockReservationTypeContainer>
                  <AlignLeft>
                    <TitleContainer
                      style={{
                        display: 'block',
                        marginRight: '1rem',
                        fontWeight: 'bold'
                      }}
                    >
                      <label
                        className={'category-label'}
                      >{`${eventType} Info`}</label>
                    </TitleContainer>
                  </AlignLeft>
                  <AlignRight></AlignRight>
                </BlockReservationTypeContainer>
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: '16px',
                    paddingBottom: '7px'
                  }}
                  paddingBottom={true}
                >
                  <ClinicSelectContainer>
                    <ClinicSelector
                      multiple={false}
                      value={selectedClinic?.code}
                      onChange={(value: string) => {
                        this.onClinicChange(value);
                      }}
                      filterItem={(clinic) =>
                        eventType !== 'Block' ||
                        permissionChecking({
                          anyClinic: false,
                          clinicId: clinic.id,
                          moduleId: 1,
                          functionId: 4
                        }).success
                      }
                      useClinicCode={true}
                      mandatory={true}
                      selectProps={{
                        id: test_ids.clinicSelect,
                        label: 'Clinic',
                        inputProps: {
                          allowClear: false,
                          placeholder: 'Select Clinic'
                        },
                        bottomMargin: false
                      }}
                    />
                    <ErrorBlock error={error.clinic}>
                      Field is mandatory
                    </ErrorBlock>
                  </ClinicSelectContainer>
                  <ProviderSelectContainer style={{ maxWidth: '491px' }}>
                    <ProviderSelector
                      useCode={true}
                      enableFilters={true}
                      multiple={true}
                      roleFilter="appointment"
                      filterByClinics={
                        selectedClinic ? [selectedClinic.id] : []
                      }
                      value={
                        newEventPayload.providerCodes?.map((v) => v.code) || []
                      }
                      onChange={(values: any, e: any) => {
                        this.onProvidersChange(values);
                      }}
                      selectProps={{
                        id: test_ids.providerSelect,
                        star: true,
                        bottomMargin: false
                      }}
                    />
                    <ErrorBlock error={error.provider}>
                      Field is mandatory
                    </ErrorBlock>
                  </ProviderSelectContainer>
                </Row>
                <BlockReservationTypeContainer
                  style={{
                    borderTop: '1px solid #e6e6e6',
                    paddingTop: '24px'
                  }}
                >
                  {eventType !== 'Reservation' && (
                    <AlignLeft style={{ marginRight: '24px' }}>
                      <TitleContainer
                        style={{
                          display: 'block',
                          marginRight: '1rem',
                          fontWeight: 'bold'
                        }}
                      >
                        <label
                          className={'category-label'}
                        >{`${eventType} Type`}</label>
                      </TitleContainer>
                    </AlignLeft>
                  )}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {eventType === 'Reservation' && (
                      <div style={{ display: 'flex' }}>
                        <OptionContainer
                          style={{ width: 'auto', marginRight: 15 }}
                        >
                          <Option
                            id={test_ids.options.reservationType}
                            label="Reservation Type"
                            name="timeOptionType"
                            value="type"
                            checked={!isGroupReservation}
                            onClick={() => this.onChangeOption(false)}
                          />
                        </OptionContainer>
                        <OptionContainer style={{ width: 'auto' }}>
                          <Option
                            id={test_ids.options.reservationGroup}
                            label="Reservation group"
                            name="timeOptionType"
                            value="custoGroup Of types"
                            checked={isGroupReservation}
                            onClick={() => this.onChangeOption(true)}
                          />
                        </OptionContainer>
                      </div>
                    )}
                    {this.state.conflicts?.conflictResponse.length > 0 && (
                      <ConflictErrorBlock
                        isSingle={
                          !isEventRecurring ||
                          (isEventRecurring && !updateSeries)
                        }
                        clinicCode={newEventPayload.scheduleEvent.clinicCode}
                        conflicts={this.state.conflicts}
                        clinicOptions={clinicOpts}
                        providerOptions={providerOpts}
                        providers={selectedProviders}
                        onClickOverride={this.onClickOverride}
                      />
                    )}
                  </div>
                </BlockReservationTypeContainer>
                <ARow gutter={16} style={{ marginTop: 10, marginBottom: 10 }}>
                  <Col flex={1}>
                    <ReasonSelectContainer>
                      {isGroupReservation ? (
                        <GroupAppointmentReason
                          value={
                            newEventPayload.scheduleEvent?.scheduleEventBlock
                              ?.groupId
                          }
                          onChange={(value: any) => {
                            const a = groupAppointmentReasonById(value);
                            this.onReasonChange(a, selectedClinic);
                          }}
                          selectProps={{
                            id: test_ids.groupSelect,
                            bottomMargin: false
                          }}
                        />
                      ) : (
                        <ReasonSelector
                          clinicCodes={
                            selectedClinic ? [selectedClinic.value] : []
                          }
                          name="Type"
                          reasonTypes={[
                            eventType === 'Block' ? 'block' : 'appointment'
                          ]}
                          useCode={false}
                          value={selectedReason?.id}
                          onChange={(value: any) => {
                            const val = getReasonByID(value);
                            this.onReasonChange(val, selectedClinic);
                          }}
                          selectProps={{
                            id: test_ids.reasonSelect
                          }}
                        />
                      )}

                      <ErrorBlock error={error.reason}>
                        Field is mandatory
                      </ErrorBlock>
                    </ReasonSelectContainer>
                  </Col>
                  <Col>
                    <ColorSelector
                      value={chosenColor}
                      onChange={(color: string) =>
                        this.onSelectFromColorPicker(color)
                      }
                      selectProps={{
                        id: test_ids.colorSelect,
                        star: false,
                        bottomMargin: false,
                        label: 'Type Color'
                      }}
                    />
                    <ErrorBlock error={false}></ErrorBlock>
                  </Col>
                </ARow>
                <Row
                  style={{
                    display: 'flex',
                    padding: 0,
                    alignItems: 'flex-end'
                  }}
                  borderTop={true}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Row
                      disableSvg={disableDateTimeSelect}
                      style={{
                        paddingTop: 0,
                        paddingBottom: 16,
                        marginTop: 24,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <ElementTitle name={`${eventType} Info`} star={false} />
                      {showRecurringButton ? (
                        <ConditionalButton
                          id={test_ids.recurringButton}
                          iconName="recurrence"
                          text="Recurring"
                          onClick={() => {
                            this.onSelectRecurring(!disableDateTimeSelect);
                          }}
                          isFilled={
                            recurringDisabled ? false : isEventRecurring
                          }
                          isDisabled={
                            recurringDisabled || disableDateTimeSelect
                          }
                        />
                      ) : null}
                    </Row>
                    {isEventRecurring && (
                      <div
                        style={{
                          cursor:
                            isCreation || updateSeries
                              ? recurringDisabled
                                ? 'auto'
                                : 'pointer'
                              : 'auto'
                        }}
                        onClick={() => {
                          if (!(isCreation || updateSeries)) return;
                          if (recurringDisabled) return;
                          this.onSelectRecurring(!disableDateTimeSelect);
                        }}
                      >
                        {RecurrenceDisplay(
                          newEventPayload.scheduleEvent.series
                        )}
                      </div>
                    )}
                    {!showRecurringText && (
                      <>
                        {eventType === 'Block' && (
                          <Checkbox
                            id={test_ids.allDayBlockCheckbox}
                            checked={
                              this.state.newEventPayload.scheduleEvent
                                ?.scheduleEventBlock.allDay
                            }
                            onChange={() => {
                              this.onStartTimeChange(
                                selectedClinic.dayStartTime.substring(11, 16),
                                selectedClinic
                              );
                              this.onEndTimeChange(
                                selectedClinic.dayEndTime.substring(11, 16),
                                selectedClinic
                              );
                              const newScheduleEvent = _.cloneDeep(
                                this.state.newEventPayload.scheduleEvent
                              );
                              newScheduleEvent.scheduleEventBlock.allDay =
                                !newScheduleEvent.scheduleEventBlock.allDay;
                              this.setState({
                                newEventPayload: {
                                  ...this.state.newEventPayload,
                                  scheduleEvent: newScheduleEvent
                                }
                              });
                            }}
                            style={{
                              marginBottom: 16
                            }}
                          >
                            All Day
                          </Checkbox>
                        )}
                        <ARow align="top" gutter={20} style={{ height: 98 }}>
                          <Col
                            className="column-container"
                            style={{ width: 220 }}
                          >
                            <SelectContainer
                              id={test_ids.startDate}
                              type="date"
                              label="Start Date"
                              value={startDate}
                              onChange={(value: any) => {
                                this.onStartDateChange(value);
                              }}
                              bottomMargin={false}
                              inputProps={{
                                allowClear: false
                              }}
                            />
                            <ErrorBlock
                              style={{
                                flexDirection: 'column',
                                alignItems: 'start'
                              }}
                              error={
                                error.startDate || pastDay || error.clinicClosed
                              }
                            >
                              {error.startDate ? (
                                <span>Field is mandatory</span>
                              ) : pastDay ? (
                                <span>
                                  The selected date has already passed
                                </span>
                              ) : null}
                              {error.clinicClosed && (
                                <span>{error.clinicClosed}</span>
                              )}
                            </ErrorBlock>
                          </Col>
                          {!this.state.newEventPayload.scheduleEvent
                            ?.scheduleEventBlock.allDay && (
                            <>
                              <Col
                                className="column-container"
                                style={{ width: 150 }}
                              >
                                <SelectContainer
                                  id={test_ids.startTime}
                                  type="select"
                                  label="Start Time"
                                  avoidOptionsSorting={true}
                                  value={startTimeValue}
                                  options={startTimeOptions}
                                  onChange={(value: any) => {
                                    this.onStartTimeChange(
                                      value,
                                      selectedClinic
                                    );
                                  }}
                                  bottomMargin={false}
                                  inputProps={{
                                    allowClear: false,
                                    placeholder: '--',
                                    disabled: startDate ? false : true
                                  }}
                                />
                                <ErrorBlock
                                  error={
                                    error.startDate || (pastTime && !pastDay)
                                  }
                                >
                                  {!(pastTime && !pastDay)
                                    ? 'Field is mandatory'
                                    : 'The selected time has already passed'}
                                </ErrorBlock>
                              </Col>
                              <Col
                                className="column-container"
                                style={{ width: 150 }}
                              >
                                <SelectContainer
                                  id={test_ids.endTime}
                                  type="select"
                                  label="End Time"
                                  avoidOptionsSorting={true}
                                  value={endTimeValue}
                                  options={endTimeOptions}
                                  onChange={(value: any) => {
                                    this.onEndTimeChange(value, selectedClinic);
                                  }}
                                  bottomMargin={false}
                                  inputProps={{
                                    allowClear: false,
                                    placeholder: '--',
                                    disabled: startTimeValue ? false : true
                                  }}
                                />
                                <ErrorBlock error={error.endTime}>
                                  Field is mandatory
                                </ErrorBlock>
                              </Col>
                            </>
                          )}
                          <Col>
                            <ReleaseBeforeCheckBoxContainer
                              style={{
                                marginTop: releaseBeforeStartTime ? 0 : 24
                              }}
                            >
                              <Checkbox
                                id={test_ids.releaseBeforeStartTimeCheckbox}
                                checked={releaseBeforeStartTime}
                                onChange={() =>
                                  this.onReleaseBeforeStartTimeChange(
                                    !releaseBeforeStartTime
                                  )
                                }
                              >
                                Release before the start time of event
                              </Checkbox>
                            </ReleaseBeforeCheckBoxContainer>
                            {releaseBeforeStartTime ? (
                              <MinuteHourDayEditor
                                onChange={(value: any, type: string) =>
                                  this.onReleaseBeforeIntervalChange(
                                    value,
                                    type
                                  )
                                }
                                releaseBefore={releaseBeforeInterval}
                              />
                            ) : null}
                          </Col>
                        </ARow>
                      </>
                    )}
                  </div>
                </Row>
                <NoteContainer>
                  <SelectContainer
                    id={test_ids.noteArea}
                    label={'Comment'}
                    star={false}
                    type="area"
                    value={scheduleEvent['comment']}
                    onChange={(value: string) =>
                      this.onAppointmentNoteChange(value)
                    }
                    bottomMargin={false}
                    areaAutoReplace={true}
                    inputProps={{
                      maxLength: 4000,
                      placeholder: 'Write a comment here'
                    }}
                  />
                  <ErrorBlock error={false}>Field is mandatory</ErrorBlock>
                </NoteContainer>
              </Info>
            )}

            {isSelectingRecurringAppt && (
              <SeriesEditor
                type={eventType?.toLowerCase()}
                onSubmit={this.onEndSelectRecurring.bind(this)}
                onCancel={this.onEndSelectRecurring.bind(this)}
                top={'89px'}
                originalPayload={newEventPayload}
                providers={providers}
                clinics={clinics}
                onSeriesUpdated={this.onSeriesUpdated.bind(this)}
              />
            )}
            {!isSelectingRecurringAppt && this.props.action === 'edit' && (
              <Collapse
                noSidePaddings={true}
                uppercaseHeader={true}
                icon="roundIcon"
              >
                <Panel
                  key={'eventLog'}
                  header={
                    (this.props.eventInfo.scheduleEvent.scheduleEventTypeId ===
                    2
                      ? 'block'
                      : 'reservation') + ' log'
                  }
                >
                  <Block>
                    <Table
                      absoluteContainer={true}
                      dataSource={this.state.logs}
                      columns={eventLogColumns}
                      renderItemData={renderItemDataLog}
                    />
                  </Block>
                </Panel>
              </Collapse>
            )}
          </PopupBody>
        </PopupContent>
      </>
    );
  }
}

const eventLogColumns = [
  {
    title: 'Date',
    key: 'date',
    flex: '130px'
  },
  {
    title: 'Time',
    key: 'time',
    flex: '130px'
  },
  {
    title: 'User',
    key: 'user',
    flex: '140px'
  },
  {
    title: 'Event',
    key: 'action',
    flex: 'auto'
  },
  {
    title: '',
    key: 'log',
    flex: '48px',
    centered: true
  }
];

export default BlockReservationPopup;

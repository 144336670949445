import React, { FC } from 'react';

import Table from '../Table';

import { Container } from './styled';

export interface IProps {
  data: any[];
  columns: any[];
}

const Component: FC<IProps> = ({ data, columns }: IProps) => {
  return (
    <Container id="history-popup">
      <Table
        data={data}
        columns={
          columns
            ? columns
            : [
                {
                  title: 'Date',
                  key: 'dts',
                  flex: '120px'
                },
                {
                  title: 'User',
                  key: 'user',
                  flex: 'auto'
                },
                {
                  title: 'Action',
                  flex: '150px',
                  key: 'action'
                }
              ]
        }
        horizontalItemPadding={24}
      />
    </Container>
  );
};

export default Component;

import {
  updateActivity,
  updateCurrentActivity
} from 'src/App/ActivityPanel/Store/ActivityActions';
import { AppUrl } from 'src/Framework/Common/AppUrl';

import * as Getters from 'src/App/Admin/Pages/PatientMenuItems/store/Actions/Getter';
import store from 'src/store';
import { setAccordionExpandCollapse } from 'src/Activities/Subject/Navigation/Store/NavigationActions';
import {
  EnumPatientMenuSystemIds,
  IPatientChartMenu
} from 'src/App/Admin/Pages/PatientMenuItems/store/types';
import { NoteSimpleDto } from './Note/store/types';

export const includesInPatientMenuItem = (
  v: NoteSimpleDto,
  projectionItem: IPatientChartMenu
) => {
  if (v.typeNote !== 'counseling') {
    if (projectionItem.legacyNoteClinicIds) {
      return projectionItem.legacyNoteClinicIds.includes(v.clinicId);
    }
    return false;
  } else {
    if (v.noteType?.seedId !== undefined) {
      return projectionItem.noteTypeSeedIds?.includes(v.noteType?.seedId);
    }
    return false;
  }
};

export const redirectNoteWithParams = (
  noteTypeSeedId: number,
  patientId: number | string,
  id: number | string,
  activityId?: string
) => {
  const list = Getters.getUserProjectionSummary();
  const item = list.find(
    (v) => v.isActive && v.noteTypeSeedIds?.includes(noteTypeSeedId)
  );
  if (item) {
    const targetURL = `${AppUrl.CLIENT}/${patientId}${AppUrl.PATIENT_NOTES}/edit/${id}?type=all&category=${item.id}`;
    if (activityId) {
      updateActivity({
        activityId,
        targetURL
      });
    } else {
      updateCurrentActivity({
        targetURL
      });
    }
    store.dispatch(setAccordionExpandCollapse(patientId, item.id, true));
  } else {
    const targetURL = `${AppUrl.CLIENT}/${patientId}${AppUrl.PATIENT_NOTES}/edit/${id}?type=all&category=${EnumPatientMenuSystemIds.AllNotes}`;
    if (activityId) {
      updateActivity({
        activityId,
        targetURL
      });
    } else {
      updateCurrentActivity({
        targetURL
      });
    }
    store.dispatch(
      setAccordionExpandCollapse(
        patientId,
        EnumPatientMenuSystemIds.AllNotes,
        true
      )
    );
  }
};

export const SearchParamsNoteData = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const category = searchParams.get('category');
  const type = searchParams.get('type');
  const referralId = searchParams.get('referralId');
  const paramsExist = category || type ? true : false;
  const params = new URLSearchParams();
  if (category) {
    params.set('category', category);
  }
  if (type) {
    params.set('type', type);
  }
  return {
    params: {
      category,
      type,
      referralId
    },
    paramsString: params.toString(),
    paramsExist: paramsExist
  };
};

export const goToNoteListPage = (patientId: string | number) => {
  const params = SearchParamsNoteData();
  updateCurrentActivity({
    targetURL: `${AppUrl.CLIENT}/${patientId}${AppUrl.PATIENT_NOTES}${
      params.paramsExist ? `?${params.paramsString}` : ''
    }`
  });
};

export const goToNoteCreatePage = (patientId: string | number) => {
  const params = SearchParamsNoteData();
  updateCurrentActivity({
    targetURL: `${AppUrl.CLIENT}/${patientId}${AppUrl.PATIENT_NOTES}${
      AppUrl.NOTE_TYPE
    }${params.paramsExist ? `?${params.paramsString}` : ''}`
  });
};

export const getNoteM1Link = (
  patientId: string | number,
  id: string | number
) => {
  const params = SearchParamsNoteData();
  return `${AppUrl.CLIENT}/${patientId}${AppUrl.PATIENT_NOTES}/edit/${id}${
    params.paramsExist ? `?${params.paramsString}` : ''
  }`;
};

export const goToNotePage = (
  patientId: string | number,
  id: string | number
) => {
  updateCurrentActivity({
    targetURL: getNoteM1Link(patientId, id)
  });
};

export const getNoteInListLink = (
  patientId: string | number,
  id: string | number
) => {
  const params = SearchParamsNoteData();
  return `${AppUrl.CLIENT}/${patientId}${AppUrl.PATIENT_NOTES}?${params.paramsString}&noteId=${id}`;
};

export const goToNoteInList = (
  patientId: string | number,
  id: string | number
) => {
  updateCurrentActivity({
    targetURL: getNoteInListLink(patientId, id)
  });
};

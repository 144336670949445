import React, { FC, useMemo, useRef, useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Col, Row } from 'antd';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import EditButton from 'src/Framework/Controls/Table/EditButton';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import Tooltip from 'src/Framework/Controls/Tooltip';
import Collapse from 'src/Framework/Controls/CollapseA11y';
import { handleNoteClick } from 'src/Framework/Controls/NoteButton';
import RemoveButton from 'src/Framework/Controls/Table/RemoveButton';
import {
  getProviderByCode,
  getProviderById,
  providerDisplayName
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import Table from 'src/Framework/Controls/Table';
import Image from 'src/Framework/Controls/AsyncImage';
import { getClinicById } from 'src/Framework/Controls/Selectors/ClinicSelector';
import { getReasonByID } from 'src/Framework/Controls/Selectors/ReasonSelector/utils';
import { getUserById } from 'src/Framework/Controls/Selectors/UserSelector/utils';
import AlertPopUp, { IModalHandles } from 'src/Framework/Controls/AlertPopUp';
import StatusLabel from 'src/Framework/Controls/StatusLabel';
import HistoryPopUp from 'src/Framework/Controls/HistoryPopUp';
import NoData from 'src/Framework/Controls/Table/NoData';
import { formattedDateTime } from 'src/Framework/Shared/Shared';
import { useAppSelector } from 'src/store';
import test_ids from 'src/tests-script/pages/Schedule/Event/info';

import { NoteSimpleDto } from 'src/Activities/Counseling/NewNotes/Note/store/types';
import EditRClickPopup from 'src/Activities/Schedule/Popups/ScheduleDetailPopup/EditRClickPopup';
import {
  IProviderEvent,
  IEventMember
} from 'src/Activities/Schedule/Store/Events/types';
import { eventName } from 'src/Activities/Schedule/utils';
import {
  patchEventMember,
  deleteEventMember,
  deleteEvent
} from 'src/Activities/Schedule/Store/Events/Actions';
import { getAppointmentLog } from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import { EEventTypeCode, eventPermission } from 'src/Activities/Schedule/utils';

import ProviderHistory from './ProviderHistory';

import {
  InfoTitle,
  Block,
  Info,
  MembersCounter,
  ContainerBlock,
  ItemContainer,
  ImageContainer,
  NameContainer,
  PopupHeader,
  ScrollableBody,
  EventWrapper
} from './styled';

const { Panel } = Collapse;

export interface IProps {
  onDelete?: any;
  onClosePopup: () => void;
  inModal?: boolean;
  onEditSchedule: (appointmentId: number, isSeries?: boolean) => void;
  setRecordChanged?: () => void;
  data: IProviderEvent;
  setData: React.Dispatch<React.SetStateAction<IProviderEvent | null>>;
  sliceId?: any;
  currentProviderId?: number;
}

const Component: FC<IProps> = ({
  onClosePopup,
  onEditSchedule,
  data,
  setData,
  sliceId,
  setRecordChanged,
  currentProviderId,
  inModal
}) => {
  const {
    name,
    endTime,
    clinicId,
    appointmentReasonId,
    // note,
    startDate,
    eventMembers,
    startTime,
    id,
    appointmentSeries,
    note
  } = data;
  const slice = useAppSelector((state) => state.schedule.slices[sliceId]);
  const sliceProvider = slice
    ? getProviderByCode(slice.providerCode)
    : getProviderById(currentProviderId!);
  const AlertPopUpRef = useRef<IModalHandles>(null);
  const TitleRef = useRef<any>(null);
  const editButtonRef = useRef<any>(null);
  const deleteButtonRef = useRef<any>(null);
  const componentRef = useRef<any>(null);
  const [showRClickPopup, setShowRClickPopup] = useState<string>('');
  const [logData, setLogData] = useState<any[]>([]);
  const [selectedProviderId, setSelectedProviderId] = useState<number | null>(
    null
  );
  const eventHasArrived = useMemo(() => {
    return eventMembers?.some((item) => item.status === 2);
  }, [eventMembers]);

  const isInPast = useMemo(
    () =>
      moment(startDate?.slice(0, 10), 'YYYY-MM-DD').isBefore(
        moment().startOf('day')
      ),
    [startDate]
  );
  useEffect(() => {
    if (TitleRef.current) {
      TitleRef.current.focus();
    }
    getLogs();
  }, []);
  const arrivedMembers = useMemo(() => {
    return eventMembers?.reduce((acc, item) => {
      if (item.status === 2) {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
  }, [eventMembers]);

  const staffApptPermission = useMemo(
    () => eventPermission(EEventTypeCode.staffAppointment),
    []
  );

  const noteList: NoteSimpleDto[] = [];

  const memberArrivedHandler = async (eventMember: IEventMember) => {
    const result = await patchEventMember({
      id: eventMember.id,
      status: eventMember.status === 1 ? 2 : 1
    });
    if (result) {
      const newEventMembers = _.cloneDeep(eventMembers);
      const index = newEventMembers?.findIndex((item) => item.id === result.id);
      setRecordChanged && setRecordChanged();
      if (index !== -1) {
        newEventMembers![index!] = result;
        setData({
          ...data,
          eventMembers: newEventMembers
        });
      }
    }
  };
  const removeMemberHandler = async (
    memberId: number,
    deletedProviderId: number
  ) => {
    const result = await deleteEventMember(memberId);
    if (result) {
      const newEventMembers = _.cloneDeep(eventMembers);
      setData({
        ...data,
        eventMembers: newEventMembers?.filter((item) => item.id !== memberId)
      });
      if (
        deletedProviderId === sliceProvider?.providerId ||
        newEventMembers?.filter((item) => item.id !== memberId)?.length === 0
      ) {
        onClosePopup();
      }
    }
  };

  const deleteSingleEventHandler = async () => {
    AlertPopUpRef.current?.show({
      onConfirm: async () => {
        const result = await deleteEvent(id!, false);
        if (result) {
          onClosePopup();
        }
      },
      content: `Are you sure you want to remove the ${eventName}(s)?`,
      action: 'delete'
    });
  };
  const deleteSingleHandler = async () => {
    const result = await deleteEvent(id!, false);
    if (result) {
      onClosePopup();
    }
  };

  const deleteSeriesHandler = async () => {
    AlertPopUpRef.current?.show({
      onConfirm: async () => {
        const result = await deleteEvent(id!, true);
        if (result) {
          onClosePopup();
        }
      },
      content: `Are you sure you want to remove the ${eventName}(s)?`,
      action: 'delete'
    });
  };

  const editButtonHandler = () => {
    if (!appointmentSeries) {
      onEditSchedule(data.id!);
      onClosePopup();
    } else {
      setShowRClickPopup('Edit');
    }
  };

  const editSingleEventHandler = () => {
    if (showRClickPopup === 'Remove') {
      deleteSingleEventHandler();
    } else if (showRClickPopup === 'Edit') {
      onEditSchedule(data.id!);
      onClosePopup();
    }
    setShowRClickPopup('');
  };

  const editSeriesHandler = () => {
    if (showRClickPopup === 'Remove') {
      deleteSeriesHandler();
    } else if (showRClickPopup === 'Edit') {
      onEditSchedule(data.id!, true);
      onClosePopup();
    }
    setShowRClickPopup('');
  };

  const renderItemData = (item: IEventMember) => {
    return {
      ...item,
      name: (
        <>
          <ImageContainer>
            {getProviderById(item.providerId)?.photoFileDefinitionId && (
              <Image
                width={24}
                height={24}
                file={{
                  id: getProviderById(item.providerId)!.photoFileDefinitionId!
                }}
              />
            )}
          </ImageContainer>
          <NameContainer>
            <EllipsisText maxWidth={160} height={18}>
              {providerDisplayName(item.providerId)}
            </EllipsisText>
          </NameContainer>
        </>
      ),
      arrived: (
        <>
          <ButtonIcon
            isButton={true}
            size={32}
            label={`${item.status === 2 ? 'Unset' : 'Set'} arrive status`}
            toggled={item.status === 2}
            active={item.status === 2}
            onClick={() => {
              memberArrivedHandler(item);
            }}
            changeColorOnHover={true}
            disabled={moment()
              .startOf('day')
              .isBefore(moment(data.startDate).startOf('day'))}
            containerProps={{
              style: {
                marginRight: 16
              }
            }}
            id={test_ids.arriveButton + item.id}
            name={ICONS_LIST.arrived}
          />
          {item.arriveTime ? moment(item.arriveTime).format('hh:mm A') : ''}
        </>
      ),
      confirmedName:
        item.userId && item.userId !== 0 && item.status === 2 ? (
          <EllipsisText maxWidth={160}>
            {getUserById(item.userId)?.name}
          </EllipsisText>
        ) : null,
      delete: (
        <RemoveButton
          buttonIconProps={{
            disabled: item.status === 2
          }}
          approve={true}
          id={test_ids.deleteButton + item.id}
          removeAction={async () =>
            removeMemberHandler(item.id, +item.providerId)
          }
        />
      )
    };
  };
  const renderItemDataLog = (item: any, index: number) => {
    return {
      ...item,
      date: moment(item.dateModified).format('MM/DD/YYYY'),
      time: moment(item.dateModified).format('hh:mm A'),
      user: item.userCode,
      event: item.action,
      log: item.differences.length > 0 && (
        <HistoryPopUp
          isLast={index === logData?.length - 1}
          parentRef={componentRef}
          buttonProps={{
            name: ICONS_LIST.legend,
            iconScale: 1.7
          }}
          tooltipProps={{
            placement: 'leftTop'
          }}
          contentProps={{
            data: item.differences.map((v: any) => ({
              ...v,
              oldValue:
                (v.name.toLowerCase().includes('date') ||
                  v.name.toLowerCase().includes('time')) &&
                moment(v.oldValue).isValid()
                  ? formattedDateTime(v.oldValue)
                  : v.oldValue,
              newValue:
                (v.name.toLowerCase().includes('date') ||
                  v.name.toLowerCase().includes('time')) &&
                moment(v.newValue).isValid()
                  ? formattedDateTime(v.newValue)
                  : v.newValue
            })),
            columns: [
              {
                title: 'Field',
                flex: 'auto',
                key: 'name'
              },
              {
                title: 'Before',
                flex: '170px',
                key: 'oldValue'
              },
              {
                title: 'After',
                flex: '170px',
                key: 'newValue'
              }
            ]
          }}
        />
      )
    };
  };

  const getLogs = async () => {
    try {
      const response = await getAppointmentLog({
        appointmentId: data.id,
        scheduleEventTypeId: 7
      });
      if (response.data.success) {
        setLogData(
          _.orderBy(response.data.result, (o: any) => o.dateModified, ['desc'])
        );
      }
    } catch (e) {
    } finally {
    }
  };

  const onCloseEditPopup = () => {
    if (showRClickPopup === 'Edit') {
      if (editButtonRef.current) {
        //@ts-ignore
        editButtonRef.current.focus();
      }
    } else if (showRClickPopup === 'Remove') {
      if (deleteButtonRef.current) {
        //@ts-ignore
        deleteButtonRef.current.focus();
      }
    }
    setShowRClickPopup('');
  };
  const onClickEventMember = (member: any) => {
    setSelectedProviderId(member.providerId);
  };

  const onKeyDownContainer = (e: React.KeyboardEvent) => {
    // if (e.key === 'Enter') {
    //   e.stopPropagation();
    // }
    if (e.shiftKey && e.code === 'Tab' && e.target === e.currentTarget) {
      e.preventDefault();
    }
    if (
      !e.shiftKey &&
      e.code === 'Tab' &&
      (e.target as HTMLElement).className.includes('ant-collapse-header') &&
      (e.target as HTMLElement).parentElement?.nextElementSibling === null &&
      (!(e.target as HTMLElement).parentElement?.className.includes(
        'ant-collapse-item-active'
      ) ||
        logData.length === 0)
    ) {
      if (componentRef?.current) {
        e.preventDefault();
        (componentRef?.current as HTMLElement).focus();
      }
    }
  };

  return (
    <EventWrapper
      tabIndex={0}
      ref={componentRef}
      onKeyDown={onKeyDownContainer}
    >
      {showRClickPopup && showRClickPopup.length > 0 ? (
        <EditRClickPopup
          onClosePopup={() => onCloseEditPopup()}
          type={showRClickPopup}
          editSeries={() => editSeriesHandler()}
          editSingle={() => editSingleEventHandler()}
          eventType={eventName}
          buttonRef={
            showRClickPopup === 'Edit' ? editButtonRef : deleteButtonRef
          }
        />
      ) : null}
      <AlertPopUp ref={AlertPopUpRef} />
      <PopupHeader>
        <InfoTitle ref={TitleRef} tabIndex={0}>
          <ButtonIcon
            size={40}
            disabled={true}
            containerClassName={'borderedIcon'}
            containerProps={{
              style: {
                filter: 'none',
                opacity: 1
              }
            }}
            name={ICONS_LIST.event}
          />
          <label className="visually-hidden">Event</label>
          <span>{name}</span>
        </InfoTitle>
        <InfoTitle>
          <EditButton
            buttonProps={{
              disabled: !staffApptPermission.success || isInPast,
              id: test_ids.editButton,
              containerProps: {
                ref: editButtonRef
              },
              tooltip: staffApptPermission.success
                ? isInPast
                  ? 'You can not edit Staff Appointments from the past'
                  : 'Edit Staff Appointment'
                : staffApptPermission.message
            }}
            onClick={editButtonHandler}
          />
          <RemoveButton
            id={test_ids.deleteButton}
            buttonIconProps={{
              containerProps: {
                ref: deleteButtonRef
              },
              disabled: eventHasArrived || (!!appointmentSeries && isInPast),
              tooltip: eventHasArrived
                ? "Can't cancel after Provider arrived"
                : !!appointmentSeries && isInPast
                ? "Can't cancel Staff Appointments series from the past"
                : `Cancel ${eventName}`
            }}
            removeAction={async () => {
              if (data.appointmentSeries) {
                setShowRClickPopup('Remove');
              } else {
                AlertPopUpRef.current?.show({
                  onConfirm: () => {
                    deleteSingleHandler();
                  },
                  content: `Are you sure you want to remove the ${eventName}?`,
                  action: 'delete'
                });
              }
            }}
          />
          <ButtonIcon
            isButton={true}
            id={test_ids.closeButton}
            changeColorOnHover={true}
            name={ICONS_LIST.thinClose}
            iconScale={1.9}
            label="Close dialog"
            tooltip="Close dialog"
            onClick={() => onClosePopup()}
          />
        </InfoTitle>
      </PopupHeader>
      <Block topBorder={true}>
        <Row>
          <Col span={5}>
            <label className={'preview-label-style'}>Clinic</label>
            <Info>
              <EllipsisText maxWidth={148}>
                {getClinicById(clinicId)?.name}
              </EllipsisText>
            </Info>
          </Col>
          <Col span={5}>
            <label className={'preview-label-style'}>Type</label>
            <Info>
              <EllipsisText maxWidth={148}>
                {getReasonByID(appointmentReasonId)?.name}
              </EllipsisText>
            </Info>
          </Col>
          <Col span={5}>
            <label className={'preview-label-style'}>Date</label>
            <Info>{moment(startDate).format('MM/DD/YYYY')}</Info>
          </Col>
          <Col span={5}>
            <label className={'preview-label-style'}>Time</label>
            <Info>
              {moment(startTime).format('hh:mm A') +
                ' - ' +
                moment(endTime).format('hh:mm A')}
            </Info>
          </Col>
        </Row>
      </Block>
      <ScrollableBody>
        <Collapse
          noSidePaddings={true}
          uppercaseHeader={true}
          icon="roundIcon"
          containerClass={'absolute-collapse'}
        >
          <Panel
            key={'members'}
            header={
              <>
                {eventName} members
                <MembersCounter>
                  <span>{arrivedMembers}</span>/
                  <span>{eventMembers?.length}</span>
                </MembersCounter>
              </>
            }
          >
            <Block
              disableSidePaddings={true}
              disableBottomPadding={true}
              disableTopPaddings={true}
            >
              <Table
                containerStyleProps={{ borderTop: '1px solid #0000001f' }}
                headerBackground="#ffffff"
                dataSource={eventMembers}
                columns={eventMembersColumns}
                renderItemData={renderItemData}
                onClickRow={(index, member) => onClickEventMember(member)}
                rowIdFieldname={'id'}
                dynamicClassNameRow={{
                  className: 'selected-patient',
                  onEnabled: (value: any) =>
                    value.providerId === selectedProviderId
                }}
              />
            </Block>
          </Panel>
          <Panel
            key={'eventsHistory'}
            header={
              <Tooltip
                title={
                  !selectedProviderId
                    ? 'Please select a member from the staff appointment members'
                    : ''
                }
              >
                <label>
                  Staff appointments history
                  {selectedProviderId && (
                    <span
                      style={{
                        textTransform: 'none'
                      }}
                    >
                      {` - ${providerDisplayName(selectedProviderId)}`}
                    </span>
                  )}
                </label>
              </Tooltip>
            }
            collapsible={!selectedProviderId ? 'disabled' : undefined}
          >
            <Block
              disableBottomPadding={true}
              disableSidePaddings={true}
              disableTopPaddings={true}
            >
              {selectedProviderId && (
                <ProviderHistory providerId={selectedProviderId} />
              )}
            </Block>
          </Panel>
          <Panel
            key={'comment'}
            header={'COMMENT'}
            collapsible={!note ? 'disabled' : undefined}
          >
            <Block
              disableBottomPadding={true}
              disableSidePaddings={false}
              disableTopPaddings={true}
            >
              {note}
            </Block>
          </Panel>

          <Panel key={'notes'} header={'NOTES'}>
            <Block
              disableBottomPadding={true}
              disableSidePaddings={true}
              disableTopPaddings={true}
            >
              <ContainerBlock>
                {noteList.length === 0 ? (
                  <NoData removeVerticalPaddings={true} />
                ) : (
                  noteList.map((item) => (
                    <ItemContainer
                      onClick={() => {
                        handleNoteClick(
                          item.id,
                          item.patientId,
                          true,
                          item.typeNote
                        );
                      }}
                    >
                      {item.summary}
                    </ItemContainer>
                  ))
                )}
              </ContainerBlock>
            </Block>
          </Panel>
          <Panel key={'eventLog'} header={'Staff Appointment log'}>
            <Block
              disableBottomPadding={true}
              disableSidePaddings={true}
              disableTopPaddings={true}
            >
              <Table
                dataSource={logData}
                columns={eventLogColumns}
                renderItemData={renderItemDataLog}
              />
            </Block>
          </Panel>
        </Collapse>
      </ScrollableBody>
    </EventWrapper>
  );
};

const eventMembersColumns = [
  {
    title: 'Name',
    key: 'name',
    flex: '256px'
  },
  {
    title: <StatusLabel statusCode={'Arrived'} />,
    key: 'arrived',
    flex: '196px'
  },
  {
    title: 'Confirmed by',
    key: 'confirmedName',
    flex: 'auto'
  },
  {
    title: '',
    key: 'delete',
    flex: '96px',
    centered: true
  }
];

const eventLogColumns = [
  {
    title: 'Date',
    key: 'date',
    flex: '100px'
  },
  {
    title: 'Time',
    key: 'time',
    flex: '120px'
  },
  {
    title: 'User',
    key: 'user',
    flex: '140px'
  },
  {
    title: 'Event',
    key: 'action',
    flex: 'auto'
  },
  {
    title: '',
    key: 'log',
    flex: '48px',
    centered: true
  }
];

export default React.memo(Component);

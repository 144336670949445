import React, { FC, useRef } from 'react';
import { Container, Background } from './styled';
import { Col, Row } from 'antd';

import Icon from '../StatusIcon/Status';
import Text from '../PolicyStatusText';
import { ITicketDetailHipaa } from '../../store/types';
import { getCurrentClinicId } from 'src/Framework/Controls/Selectors/ClinicSelector';
import { isHippoExpired } from '../../store/utils';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import { HippaStatusColors } from '../utils';
import PrivacyPolicyStatusModal, {
  IModalHandles
} from '../PrivacyPolicyStatusModal';

export interface ICommon {
  clinicId?: number;
  statusModal?: boolean;
}

export interface IProps extends ICommon {
  patientId?: undefined;
  data: ITicketDetailHipaa;
}

const PolicyStatusContainer: FC<IProps> = ({
  data,
  clinicId,
  statusModal
}: IProps) => {
  const modalRef = useRef<IModalHandles>(null);

  const currentClinicId = clinicId || getCurrentClinicId();
  const expired = isHippoExpired(currentClinicId, data?.dosFrom);
  const color = expired
    ? globalStyleVariables.red
    : HippaStatusColors[data?.action] || globalStyleVariables.blue;
  return (
    <>
      {statusModal && (
        <PrivacyPolicyStatusModal ref={modalRef} clinicId={currentClinicId} />
      )}
      <Container
        onClick={() => {
          if (statusModal) {
            modalRef.current?.show({
              value: data
            });
          }
        }}
        style={{
          cursor: statusModal ? 'pointer' : 'auto'
        }}
      >
        <Background style={{ backgroundColor: `${color}` }} />
        <Row align="middle" gutter={8} wrap={false}>
          <Col>
            <Icon data={data} useIcon={true} clinicId={clinicId} />
          </Col>
          <Col>
            <Text data={data} clinicId={clinicId} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default React.memo(PolicyStatusContainer);

import React, { FC } from 'react';

import Checkbox from 'src/Framework/Controls/Checkbox';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import { patientDisplayName } from 'src/Framework/util/format';

import { IModalData } from '.';

import { PatientItemContainer, CheckboxContainer, Item } from './styled';

interface IProps {
  value: IModalData['patients'][number];
  active: boolean;
  onChangeSelected: (value: IModalData['patients'][number]) => any;
}

const Component: FC<IProps> = ({ active, value, onChangeSelected }: IProps) => {
  return (
    <PatientItemContainer
      active={active}
      onClick={() => {
        onChangeSelected(value);
      }}
    >
      <CheckboxContainer>
        <Checkbox
          id={'select-subject-checkbox' + value.patientInfo.patientId}
          checked={active}
          onChange={() => {}}
        >
          <label className="visually-hidden">
            select {value.patientInfo.firstName} {value.patientInfo.lastName}
          </label>
        </Checkbox>
      </CheckboxContainer>
      <Item>
        <div>
          <b>
            <EllipsisText maxWidth={320}>
              {patientDisplayName(value.patientInfo)}
            </EllipsisText>
          </b>
        </div>
        <div>PatientId: {value.patientInfo.patientId}</div>
      </Item>
    </PatientItemContainer>
  );
};

export default React.memo(Component);

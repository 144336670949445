import React, { FC } from 'react';

import { Container } from './styled';
import HtmlRenderer from '../HtmlRenderer';

import ElementTitle, {
  IProps as IPropsElementTitle
} from 'src/Framework/Controls/ElementTitle';

import WysiwygJS from './WysiwygJS';

import SunEditor from 'suneditor/src/lib/core';
export interface IProps {
  id?: string;
  getWysiwygRef?: (ref: SunEditor) => any;
  readMode?: boolean;
  title?: IPropsElementTitle['name'];
  titleProps?: Partial<IPropsElementTitle>;
  marginBottom?: boolean;
  value?: string | null;
  readOnly?: boolean;
  onChange?: (...args: any) => any;
    /**
   * @param defaultValue - Need to use this param super carefully. 
   * This param will be used on first render and data should already be here.
   * param value will not work with defaultValue
   * @param value - Need if you want to change data when component already mounted
   */
  defaultValue?: string | null;
}

const Component: FC<IProps> = ({
  readMode,
  title,
  titleProps,
  marginBottom,
  ...props
}: IProps) => {
  return (
    <Container marginBottom={marginBottom}>
      {readMode ? (
        <HtmlRenderer value={props.value as string} />
      ) : (
        <>
          <ElementTitle star={false} name={title} {...titleProps} />
          <WysiwygJS key={`${props.readOnly}`} readMode={readMode} {...props} />
        </>
      )}
    </Container>
  );
};

export default React.memo(Component);

import React, { FC } from 'react';
import SelectContainer from 'src/Framework/Controls/SelectContainer';

import { IOption, ISingle } from './types';

interface IProps extends ISingle {
  options: IOption[];
}

const Component: FC<IProps> = ({
  value,
  onChange,
  selectProps,
  options,
  useCode
}: IProps) => {
  return (
    <SelectContainer
      label="Category"
      {...selectProps}
      options={options}
      type="select"
      value={value}
      onChange={(value: any) => {
        onChange(useCode ? value : value ? +value : null);
      }}
    />
  );
};

export default Component;

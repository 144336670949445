import _ from 'lodash';
import React, { FC, useEffect } from 'react';
import { usePreviousProps } from 'src/Framework/util/helps';
import { getAlerts } from './alertsUtils';

interface IProps {
  selectedSubjects?: any[];
  onChangeAlertsData: (data: any) => any;
  locationIndex?: number
}

const Component: FC<IProps> = ({
  selectedSubjects,
  onChangeAlertsData,
  locationIndex
}: IProps) => {
  const prevProps = usePreviousProps({ selectedSubjects });
  useEffect(() => {
    if (selectedSubjects) {
      const init = async () => {
        const alertsData = await getAlerts(selectedSubjects, locationIndex);
        onChangeAlertsData(alertsData);
      };
      if (selectedSubjects.length !== 0) {
        if (!_.isEqual(prevProps?.selectedSubjects, selectedSubjects)) {
          init();
        }
      } else {
        onChangeAlertsData({
          insurances: {},
          patientAlerts: {},
          isUnderAge: {},
          patients: []
        });
      }
    }
  }, [selectedSubjects]);
  return null;
};

export default React.memo(Component);

import { useAppSelector } from "src/store"

export const useAppointmentReason = () => {
    const appointmentTypes = useAppSelector((state) => (state.adminPanel.appointmentTypes.summary))
    return {
        finder: (id: number | string) => {
            const apptType = appointmentTypes[id]
            if (apptType) {
                return apptType
            }
            return null
        }
    }
}
import { initWeekdayOptions } from 'src/Activities/Schedule/InitData';

export const [
  // eslint-disable-next-line no-unused-vars
  SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY,
] = [...Array(7)].map((el, index) => index);

/* Simple getters - getting a property of a given point in time */

export const getYear = (date: any) => {
  if (date instanceof Date) {
    return date.getFullYear();
  }

  if (typeof date === "number") {
    return date;
  }

  const year = parseInt(date, 10);

  if (typeof date === "string" && !isNaN(year)) {
    return year;
  }

  throw new Error(`Failed to get year from date: ${date}.`);
};

export const getMonthIndex = (date: any) => date.getMonth();

export const getDay = (date: any) => date.getDate();

export const getDayOfWeek = (date: any, calendarType = "ISO 8601") => {
  const weekday = date.getDay();

  switch (calendarType) {
    case "ISO 8601":
      // Shifts days of the week so that Monday is 0, Sunday is 6 
      {
        let startDate:any = null;
        initWeekdayOptions.map((item:any)=> {
          if( !startDate) {
            if( item.startOfTheWeek) {
              startDate = 7-(item.code-1);
            }
          }

        })
        return (weekday + startDate) % 7;
      }

    case "Arabic":
      return (weekday + 1) % 7;
    case "Hebrew":
    case "US":
      return weekday;
    default:
      throw new Error("Unsupported calendar type.");
  }
};

/* Complex getters - getting a property somehow related to a given point in time */

export const getBeginOfCenturyYear = (date: any) => {
  const year = getYear(date) - 1;
  return year + (-year % 100) + 1;
};

const getBeginOfCentury = (date: any) => {
  const beginOfCenturyYear = getBeginOfCenturyYear(date);
  return new Date(beginOfCenturyYear, 0, 1);
};

const getEndOfCentury = (date: any) => {
  const beginOfCenturyYear = getBeginOfCenturyYear(date);
  return new Date(beginOfCenturyYear + 100, 0, 1, 0, 0, 0, -1);
};

const getCenturyRange = (date: any) => [
  getBeginOfCentury(date),
  getEndOfCentury(date),
];

const getBeginOfPreviousCentury = (date: any) => {
  const previousCenturyYear = getYear(date) - 100;
  return getBeginOfCentury(previousCenturyYear);
};

const getEndOfPreviousCentury = (date: any) => {
  const previousCenturyYear = getYear(date) - 100;
  return getEndOfCentury(previousCenturyYear);
};

const getBeginOfNextCentury = (date: any) => {
  const nextCenturyYear = getYear(date) + 100;
  return getBeginOfCentury(nextCenturyYear);
};

export const getBeginOfDecadeYear = (date: any) => {
  const year = getYear(date) - 1;
  return year + (-year % 10) + 1;
};

export const getBeginOfDecade = (date: any) => {
  const beginOfDecadeYear = getBeginOfDecadeYear(date);
  return new Date(beginOfDecadeYear, 0, 1);
};

export const getEndOfDecade = (date: any) => {
  const beginOfDecadeYear = getBeginOfDecadeYear(date);
  return new Date(beginOfDecadeYear + 16, 0, 1, 0, 0, 0, -1);
};

const getDecadeRange = (date: any) => [
  getBeginOfDecade(date),
  getEndOfDecade(date),
];

const getBeginOfPreviousDecade = (date: any, offset = 10) => {
  const previousDecadeYear = getBeginOfDecadeYear(date) - offset;
  return getBeginOfDecade(previousDecadeYear);
};

const getEndOfPreviousDecade = (date: any, offset = 10) => {
  const previousDecadeYear = getBeginOfDecadeYear(date) - offset;
  return getEndOfDecade(previousDecadeYear);
};

const getBeginOfNextDecade = (date: any, offset = 10) => {
  const nextDecadeYear = getBeginOfDecadeYear(date) + offset;
  return getBeginOfDecade(nextDecadeYear);
};

/**
 * Returns the beginning of a given year.
 *
 * @param {Date} date Date.
 */
export const getBeginOfYear = (date: any) => {
  const year = getYear(date);
  return new Date(year, 0, 1);
};

/**
 * Returns the end of a given year.
 *
 * @param {Date} date Date.
 */
export const getEndOfYear = (date: any) => {
  const year = getYear(date);
  return new Date(year + 1, 0, 1, 0, 0, 0, -1);
};

/**
 * Returns an array with the beginning and the end of a given year.
 *
 * @param {Date} date Date.
 */
const getYearRange = (date: any) => [
  getBeginOfYear(date),
  getEndOfYear(date),
];

const getBeginOfPreviousYear = (date: any, offset = 1) => {
  const previousYear = getYear(date) - offset;
  return getBeginOfYear(previousYear);
};

const getEndOfPreviousYear = (date: any, offset = 1) => {
  const previousYear = getYear(date) - offset;
  return getEndOfYear(previousYear);
};

const getBeginOfNextYear = (date: any, offset = 1) => {
  const nextYear = getYear(date) + offset;
  return getBeginOfYear(nextYear);
};

/**
 * Returns the beginning of a given month.
 *
 * @param {Date} date Date.
 */
export const getBeginOfMonth = (date: any) => {
  const year = getYear(date);
  const monthIndex = getMonthIndex(date);
  return new Date(year, monthIndex, 1);
};

/**
 * Returns the end of a given month.
 *
 * @param {Date} date Date.
 */
export const getEndOfMonth = (date: any) => {
  const year = getYear(date);
  const monthIndex = getMonthIndex(date);
  return new Date(year, monthIndex + 1, 1, 0, 0, 0, -1);
};

const getEndOfWeek = (date: any) => {
  var beginOfWeek = getBeginOfWeek(date);
  const year = getYear(beginOfWeek);
  const monthIndex = getMonthIndex(beginOfWeek);
  const day = getDay(beginOfWeek);
  let endDay: any = null;
  let startDay: any = null;

  initWeekdayOptions.map((item:any)=> {
    if( item.endOfTheWeek) {
      endDay = item.code
    }
    if( item.startOfTheWeek) {
      startDay = item.code
    }
  })
  return new Date(year, monthIndex, day + (endDay-startDay), 0, 0, 0, -1);
}

export const getEndOfWorkWeek = (date: any) => {
  var beginOfWeek = getBeginOfWeek(date);
  const year = getYear(beginOfWeek);
  const monthIndex = getMonthIndex(beginOfWeek);
  const day = getDay(beginOfWeek);
  let endDay: any = null;
  let startDay: any = null;

  initWeekdayOptions.map((item:any)=> {
    if( item.endOfTheWeek) {
      endDay = item.code
    }
    if( item.startOfTheWeek) {
      startDay = item.code
    }
  })
  return new Date(year, monthIndex, day + (endDay-startDay+1), 0, 0, 0, -1);
};

/**
 * Returns the beginning of a given week.
 *
 * @param {Date} date Date.
 * @param {String} calendarType Calendar type. Can be ISO 8601 or US.
 */
export const getBeginOfWeek = (date: any, calendarType = "ISO 8601") => {
  const year = getYear(date);
  const monthIndex = getMonthIndex(date);
  const day = date.getDate() - getDayOfWeek(date, calendarType);
  return new Date(year, monthIndex, day);
};

/**
 * Returns an array with the beginning and the end of a given month.
 *
 * @param {Date} date Date.
 */
const getMonthRange = (date: any) => [
  getBeginOfMonth(date),
  getEndOfMonth(date),
];

const getWeekRange = (date: any) => [
  getBeginOfWeek(date),
  getEndOfWeek(date)
];

const getWorkWeekRange = (date: any) => [
  getBeginOfWeek(date),
  getEndOfWorkWeek(date)
];

const getDifferentMonth = (date: any, offset: number) => {
  const year = getYear(date);
  const previousMonthIndex = getMonthIndex(date) + offset;
  return new Date(year, previousMonthIndex, 1);
};

const getBeginOfPreviousMonth = (date: any, offset = 1) => {
  const previousMonth = getDifferentMonth(date, -offset);
  return getBeginOfMonth(previousMonth);
};

const getEndOfPreviousMonth = (date: any, offset = 1) => {
  const previousMonth = getDifferentMonth(date, -offset);
  return getEndOfMonth(previousMonth);
};

const getBeginOfNextMonth = (date: any, offset = 1) => {
  const nextMonth = getDifferentMonth(date, offset);
  return getBeginOfMonth(nextMonth);
};

export const getBeginOfDay = (date: any) => {
  const year = getYear(date);
  const monthIndex = getMonthIndex(date);
  const day = getDay(date);
  return new Date(year, monthIndex, day);
};

export const getEndOfDay = (date: any) => {
  const year = getYear(date);
  const monthIndex = getMonthIndex(date);
  const day = getDay(date);
  return new Date(year, monthIndex, day + 1, 0, 0, 0, -1);
};

/**
 * Returns an array with the beginning and the end of a given day.
 *
 * @param {Date} date Date.
 */
const getDayRange = (date: any) => [
  getBeginOfDay(date),
  getEndOfDay(date),
];

/**
 * Gets week number according to ISO 8601 or US standard.
 * In ISO 8601, Arabic and Hebrew week 1 is the one with January 4.
 * In US calendar week 1 is the one with January 1.
 *
 * @param {Date} date Date.
 * @param {String} calendarType Calendar type. Can be ISO 8601 or US.
 */
export const getWeekNumber = (date: any, calendarType = "ISO 8601") => {
  const calendarTypeForWeekNumber = calendarType === "US" ? "US" : "ISO 8601";
  const beginOfWeek: any = getBeginOfWeek(date, calendarTypeForWeekNumber);
  let year = getYear(date) + 1;
  let dayInWeekOne;
  let beginOfFirstWeek: any;

  // Look for the first week one that does not come after a given date
  do {
    dayInWeekOne = new Date(year, 0, calendarTypeForWeekNumber === "ISO 8601" ? 4 : 1);
    beginOfFirstWeek = getBeginOfWeek(dayInWeekOne, calendarTypeForWeekNumber);
    year -= 1;
  } while (date - beginOfFirstWeek < 0);

  return Math.round((beginOfWeek - beginOfFirstWeek) / (8.64e7 * 7)) + 1;
};

/**
 * Returns the beginning of a given range.
 *
 * @param {String} rangeType Range type (e.g. "day")
 * @param {Date} date Date.
 */
export const getBegin = (rangeType: any, date: any) => {
  switch (rangeType) {
    case "century":
      return getBeginOfCentury(date);
    case "decade":
      return getBeginOfDecade(date);
    case "year":
      return getBeginOfYear(date);
    case "month":
      return getBeginOfMonth(date);
    case "day":
      return getBeginOfDay(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
};

export const getBeginPrevious = (rangeType: any, date: any) => {
  switch (rangeType) {
    case "century":
      return getBeginOfPreviousCentury(date);
    case "decade":
      return getBeginOfPreviousDecade(date);
    case "year":
      return getBeginOfPreviousYear(date);
    case "month":
      return getBeginOfPreviousMonth(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
};

export const getBeginNext = (rangeType: any, date: any) => {
  switch (rangeType) {
    case "century":
      return getBeginOfNextCentury(date);
    case "decade":
      return getBeginOfNextDecade(date);
    case "year":
      return getBeginOfNextYear(date);
    case "month":
      return getBeginOfNextMonth(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
};

/**
 * Returns the end of a given range.
 *
 * @param {String} rangeType Range type (e.g. "day")
 * @param {Date} date Date.
 */
export const getEnd = (rangeType: any, date: any) => {
  switch (rangeType) {
    case "century":
      return getEndOfCentury(date);
    case "decade":
      return getEndOfDecade(date);
    case "year":
      return getEndOfYear(date);
    case "month":
      return getEndOfMonth(date);
    case "day":
      return getEndOfDay(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
};

export const getEndPrevious = (rangeType: any, date: any) => {
  switch (rangeType) {
    case "century":
      return getEndOfPreviousCentury(date);
    case "decade":
      return getEndOfPreviousDecade(date);
    case "year":
      return getEndOfPreviousYear(date);
    case "month":
      return getEndOfPreviousMonth(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
};

/**
 * Returns an array with the beginning and the end of a given range.
 *
 * @param {String} rangeType Range type (e.g. "day")
 * @param {Date} date Date.
 */
export const getRange = (rangeType: any, date: any) => {
  switch (rangeType) {
    case "century":
      return getCenturyRange(date);
    case "decade":
      return getDecadeRange(date);
    case "year":
      return getYearRange(date);
    case "month":
      return getMonthRange(date);
    case "week":
      return getWeekRange(date);
    case "workweek":
      return getWorkWeekRange(date);
    case "day":
      return getDayRange(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
};

/**
 * Returns a number of days in a month of a given date.
 *
 * @param {Date} date Date.
 */
export const getDaysInMonth = (date: any) => {
  const year = getYear(date);
  const monthIndex = getMonthIndex(date);
  return new Date(year, monthIndex + 1, 0).getDate();
};

const toYearLabel = ([start, end]: any[]) => `${getYear(start)} – ${getYear(end)}`;

/**
 * Returns a string labelling a century of a given date.
 * For example, for 2017 it will return 2001-2100.
 *
 * @param {Date|String|Number} date Date or a year as a string or as a number.
 */
export const getCenturyLabel = (date: any) => toYearLabel(getCenturyRange(date));

/**
 * Returns a string labelling a century of a given date.
 * For example, for 2017 it will return 2011-2020.
 *
 * @param {Date|String|Number} date Date or a year as a string or as a number.
 */
export const getDecadeLabel = (date: any) => toYearLabel(getDecadeRange(date));

/**
 * Returns a boolean determining whether a given date is on Saturday or Sunday.
 *
 * @param {Date} date Date.
 */
export const isWeekend = (date: any, calendarType = "ISO 8601") => {
  const weekday = date.getDay();
  switch (calendarType) {
    case "Arabic":
    case "Hebrew":
      return weekday === FRIDAY || weekday === SATURDAY;
    case "ISO 8601":
    case "US":
      return weekday === SATURDAY || weekday === SUNDAY;
    default:
      throw new Error("Unsupported calendar type.");
  }
};
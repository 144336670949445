import store from 'src/store';
import { actionsTypes } from './index';

import { APIHandler } from 'src/Framework/Communication/ServerProxy';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';

import { UkvpType } from './types';
import { getCurrentTenantId } from 'src/App/UserPreferences/store/Actions';

export const get = async (keys: string[]) => {
  try {
    const response = await APIHandler.AxiosInstance.post(`${ApiUrl.UKVP}/query`, {
      keys
    }, {
      withCredentials: true,
    });
    if (response.data) {
      if (response.data.success) {
        const data: any = response.data.result.summary;
        Object.values(data).forEach((v: any) => {
          v.parsedValue = JSON.parse(v.value);
        });
        set(data);
        return data;
      }
    }
    return;
  } catch (e) {
    console.log({ e });
    return;
  } finally {
  }
};

export const set = (payload: any) => {
  store.dispatch({
    type: actionsTypes.SET,
    payload
  });
};

export const waitingListKeyGeneration = (id: number | string) => {
  const tenantId = getCurrentTenantId();
  return `viewsetting-tenant#${tenantId}:waitinglist#${id}`;
};

export const getUkvpWaitingList = (ids: number[]) => {
  const keys = ids.map((id) => waitingListKeyGeneration(id));
  if (keys && keys.length > 0) {
    get(keys);
  }
};

export const setUkvpWaitingList = async (
  key: string,
  ids: number[] | string[]
) => {
  try {
    const body = {
      key,
      type: UkvpType.ViewSetting,
      value: JSON.stringify(ids),
      parsedValue: ids
    };
    set({
      [key]: body
    });
    const response = await APIHandler.AxiosInstance.post(ApiUrl.UKVP, body, {
      withCredentials: true,
      interceptError: true
    });
    if (response.data) {
      if (response.data.success) {
        const data: any = response.data.result;
        data.parsedValue = JSON.parse(data.value);
        set(data);
        return response.data.result.value;
      }
    }
    return;
  } catch (e) {
    console.log({ e });
    return;
  } finally {
  }
};

export interface IStateReducer {
    summary: {
        [key: string]: {
            type: UkvpType
            key: string
            value: string
            parsedValue?: number[] | string[]
        }
    }
}

export interface IAction {
    type: string;
    payload: any;
}

export enum UkvpType {
    ViewSetting = 1,
}
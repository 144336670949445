import { dec2bin } from 'src/Framework/Shared/Shared';
import { ILocation } from 'src/Activities/Counseling/Dashboard/Alerts/store/types';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';

export const locations = (): Array<ILocation> => [
  {
    name: `${getPatientTerm('Student')} Chart (On load)`,
    id: '0'
  },
  {
    name: 'Calendar (On schedule)',
    id: '1'
  },
  {
    name: 'Calendar (On add list)',
    id: '2'
  },
  {
    name: 'Calendar (On Appt View)',
    id: '3'
  }
];

export const getLocationsFromNumber = (
  locationNumber: number | undefined
): Array<ILocation> => {
  if (locationNumber !== undefined) {
    const array: any = [];
    const bits = [...dec2bin(locationNumber)].reverse().join('');
    locations().forEach((v, index) => {
      if (bits[index] === '1') {
        array.push(v);
      }
    });
    return array;
  }
  return [];
};

export const columns = [
  {
    flex: '100px',
    title: 'Enabled',
    key: 'isEnabled',
    centered: true
  },
  {
    flex: '160px',
    title: 'Date range',
    key: 'dateRange'
  },
  {
    flex: 'auto',
    title: 'Description',
    key: 'description'
  },
  {
    span: 8,
    title: 'Locations',
    key: 'locations'
  },
  {
    span: 2,
    title: 'Category',
    key: 'category'
  }
  // {
  //     flex: '80px',
  //     title: 'Modify',
  //     centered: true,
  //     key: 'edit'
  // },
  // {
  //     flex: '80px',
  //     title: 'Delete',
  //     centered: true,
  //     key: 'remove'
  // }
];

import styled from "styled-components";

export const InputContainer = styled.div`
    flex:1;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    font-size: 12px;
    margin-bottom: 16px;
`;

import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const MainLayout = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 75px);
  left: 1px;
  top: 73px;
  background: #ffffff;

  .loader {
    border: 4.5px solid rgb(83, 200, 46, 0.1);
    border-radius: 50%;
    border-top: 4.5px solid ${globalStyleVariables.newBrandingPrimary200};
    border-right: 4.5px solid ${globalStyleVariables.newBrandingPrimary200};
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1.2s linear infinite; /* Safari */
    animation: spin 1.2s linear infinite;
  }
`;

export const TabLabel = styled.div`
  display: flex;
`;

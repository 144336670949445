import React, { FC } from 'react';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';

import {
  ActivityPath,
  gotoClient
} from 'src/App/ActivityPanel/Store/ActivityActions';

interface IProps {
  patientId: string | number;
  id: string;
}

const Component: FC<IProps> = ({ patientId, id }: IProps) => {
  return (
    <ButtonIcon
      isButton={true}
      label={`${getPatientTerm('Subject')} Info`}
      id={id}
      changeColorOnHover={true}
      name={ICONS_LIST.user}
      tooltip={`${getPatientTerm('Subject')} Info`}
      onClick={() =>
        gotoClient(`${patientId}`, ActivityPath.CLIENT_DEMOGRAPHICS)
      }
    />
  );
};

export default Component;

import React, { FC } from 'react';

import ButtonIcon, {
  ICONS_LIST,
  IIcon
} from 'src/Framework/Controls/ButtonIcon';
import store from 'src/store';

import { loadActivityTab } from 'src/App/ActivityPanel/Store/ActivityActions';

interface ICommon {
  patientId: string | number;
  id?: string;
}

interface IProps extends ICommon {
  buttonProps?: Partial<IIcon>;
  color?: string;
}

export const onClickOnChart = (patientId: string | number) => {
  if (patientId) {
    loadActivityTab(
      `/client/${patientId}/counseling/dashboard`,
      store.dispatch
    );
  }
};

const Component: FC<IProps> = ({
  patientId,
  buttonProps,
  id,
  color
}: IProps) => {
  const onClick = () => {
    onClickOnChart(patientId);
  };

  return (
    <ButtonIcon
      isButton={true}
      label="Go to Chart"
      color={color}
      id={id}
      name={ICONS_LIST.folder}
      tooltip="Go to Chart"
      changeColorOnHover={true}
      size={32}
      onClick={onClick}
      {...buttonProps}
    />
  );
};

export default Component;

const ids = {
  CONTAINER: 'appointment-creation-container',
  SAVE_BUTTON: 'save-appt-button',
  BACK_BUTTON: 'back-button',
  BLOCK_SELF_CHECKING: 'block-self-checking',
  CLOSE_BUTTON: 'close-button',
  OPTIONS: {
    INDIVIDUAL: 'individual-option',
    COUPLE: 'couple-option',
    FAMILY: 'family-option',
    GROUP: 'group-option'
  },
  TELEHEALTH_BUTTON: 'telehealth-button',
  PROVIDER_OPTIONS: {
    SINGLE: 'provider-option-single',
    MULTIPLE: 'provider-option-multiple'
  },
  CLINIC_SELECTOR: 'clinic-selector',
  REASON_SELECTOR: 'reason-selector',
  MULTIPLE_PROVIDER_SELECTOR: 'multiple-provider-selector',
  PROVIDER_SELECTOR: 'provider-selector',
  ADD_CATEGORY: 'add-group-button',
  GROUP_SELECTOR: 'group-selector',
  MAX_PARTICIPANTS: 'max-participants-input',
  RECURRING_BUTTON: 'recurring-button',
  SCHEDULING_BUTTON: 'scheduling-button',
  SETTINGS_BUTTON: 'settings-button',
  START_DATE: 'start-date-selector',
  START_TIME: 'start-time-selector',
  END_TIME: 'end-time-selector',
  COMMENT_AREA: 'comment-area',
  APPOINTMENT_HISTORY_BUTTON: 'appointment-history-button'
};

export default ids;

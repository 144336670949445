import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/store';

import * as actions from '../Actions';

export const useSettings = () => {
  const [loading, setLoading] = useState(false);
  const data = useAppSelector((state) => {
    const settings = state.settings.data;
    return settings;
  });
  useEffect(() => {
    const init = async () => {
      if (Object.values(data).length === 0) {
        setLoading(true);
        await actions.get();
        setLoading(false);
      }
    };
    init();
  }, []);

  return {
    /** Loading status */
    loading,
    data,
  };
};

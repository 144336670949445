import React, { FC } from 'react';
import { Row, Col } from 'antd';

import Icon from 'src/Framework/Controls/Icon';
import Table from 'src/Framework/Controls/Table';
import Button from 'src/Framework/Controls/Button';
import RemoveButton from 'src/Framework/Controls/Table/RemoveButton';
import { formattedDate } from 'src/Framework/Shared/Shared';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import ids from 'src/tests-script/pages/Activity/Outreach';

import { useGroupType } from 'src/App/Admin/Pages/GroupTypes/store/Hooks/useGroupType';
import SubjectSearch from 'src/Activities/Schedule/Common/Select/Tabulators/SubjectSearch';
import * as outreachActions from 'src/Activities/Home/Outreach/store/Actions';
import * as Getters from 'src/Activities/Home/Outreach/store/Actions/Getters';
import {
  IOutreachSimpleDto,
  IRegistrant
} from 'src/Activities/Home/Outreach/store/types';

import { Container, Header, Body, Footer } from './styled';

interface IProps {
  onClickBack: () => any;
  outreach: IOutreachSimpleDto;
  registrants: Record<string, IRegistrant>;
  setRegistrants: React.Dispatch<
    React.SetStateAction<Record<string, IRegistrant>>
  >;
}

const Component: FC<IProps> = ({
  onClickBack,
  outreach,
  registrants,
  setRegistrants
}: IProps) => {
  const classes = useGroupType('typeClass');
  return (
    <Container id={ids.outreachRegistrants}>
      <Header>
        <SubjectSearch
          modalHomeId={'registrants-modal-search'}
          newInput={true}
          selected={[]}
          onSelectionChanged={async (selectedSubjects) => {
            const patient = selectedSubjects[0];
            if (patient) {
              const res = await outreachActions.putRegistrant(
                outreach.id,
                patient.subjectId
              );
              if (res) {
                const key = Getters.getCompositeKeyRegistrant(res);
                setRegistrants((values) => {
                  const data = {
                    ...values,
                    [key]: res
                  };
                  return data;
                });
              }
            }
          }}
          align={'start'}
          isMulti={false}
          maxSelectionCount={1}
          autoSearch={false}
          showSelectionName={true}
        />
      </Header>
      <Body>
        <Table
          absoluteContainer={true}
          data={Object.values(registrants).map((value) => ({
            lastName: value.patient.lastName,
            nickName: value.patient.nickName || value.patient.firstName,
            dob: formattedDate(value.patient.birthDate),
            class: classes.loadingFinder(
              value.patient.patientStudentInfo.typeClassId,
              'name'
            ),
            registrationDate: formattedDate(value.dts),
            checkIn: value.isCheckedIn ? (
              <Icon
                name="check"
                color={globalStyleVariables.newBrandingSuccessText}
              />
            ) : (
              ''
            ),
            remove: (
              <RemoveButton
                removeAction={async () => {
                  const res = await outreachActions.deleteRegistrant(
                    outreach.id,
                    value.patientId
                  );
                  if (res) {
                    const key = Getters.getCompositeKeyRegistrant(value);
                    setRegistrants((values) => {
                      delete values[key];
                      return { ...values };
                    });
                  }
                }}
              />
            )
          }))}
          columns={[
            {
              title: 'Last Name',
              key: 'lastName',
              flex: '160px',
              ellipsis: true
            },
            {
              title: 'Preferred Name',
              key: 'nickName',
              flex: '160px',
              ellipsis: true
            },
            {
              title: 'DOB',
              key: 'dob',
              flex: '120px'
            },
            {
              title: 'Class',
              key: 'class',
              flex: 'auto',
              ellipsis: true
            },
            {
              title: 'Registration date',
              key: 'registrationDate',
              flex: '120px'
            },
            {
              title: 'Check-in',
              key: 'checkIn',
              flex: '80px',
              centered: true
            },
            {
              title: '',
              key: 'remove',
              flex: '80px',
              centered: true
            }
          ]}
          headerBackground="#F7F7F7"
          horizontalItemPadding={24}
        />
      </Body>
      <Footer>
        <Row align="middle" justify="space-between" style={{ flex: 1 }}>
          <Col>
            <Button
              id="remove-all-button"
              disabled={Object.values(registrants).length === 0}
              onClick={async () => {
                const res = await outreachActions.deleteAllRegistrants(
                  outreach.id
                );
                if (res) {
                  setRegistrants({});
                }
              }}
            >
              Remove all
            </Button>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
};

export default Component;

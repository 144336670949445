import React from 'react';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

interface IProps {
  stroke?: string;
}

export const CompletedForm = ({ stroke }: IProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM14.7179 7.69615C15.1024 7.29966 15.0926 6.66657 14.6961 6.2821C14.2997 5.89763 13.6666 5.90737 13.2821 6.30385L8.14079 11.6058L6.67572 10.2628C6.26861 9.88965 5.63604 9.91716 5.26285 10.3243C4.88965 10.7314 4.91716 11.364 5.32428 11.7372L7.50609 13.7372C7.90516 14.103 8.52286 14.0848 8.89972 13.6961L14.7179 7.69615Z"
      fill={globalStyleVariables.green}
    />
  </svg>
);

import React, { FC, useEffect, useState } from 'react';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';

import { useAppSelector } from 'src/store';
import * as CategoriesActions from 'src/App/Admin/Pages/Categories/store/Actions';
import { ICategory } from 'src/App/Admin/Pages/Categories/store/types';
import * as ukvpActions from 'src/App/Ukvp/store/Actions';

import Multiple from './Multiple';
import Single from './Single';

import { ISingle, IMultiple } from './types';
import { Container } from './styled';
import { categoryUkvpKeyGeneration } from 'src/App/Admin/Pages/Categories/utils';
import _ from 'lodash';

type ISelect = ISingle | IMultiple;
type IProps = ISelect & {
  filter?: (category: ICategory) => boolean;
  useCode?: boolean;
};

const Component: FC<IProps> = (props: IProps) => {
  const [loading, setLoading] = useState(false);
  const { type, filter, useCode } = props;
  const categories = useAppSelector((state) => state.adminPanel.categories);
  const ukvp = useAppSelector((state) => state.ukvp.summary);
  const key = categoryUkvpKeyGeneration();

  useEffect(() => {
    const init = async () => {
      if (Object.values(categories.summary).length === 0) {
        setLoading(true);
        await ukvpActions.get([key]);
        await CategoriesActions.get();
        setLoading(false);
      }
    };
    init();
  }, []);
  const sortIds: any = ukvp[key]?.parsedValue || [];
  const orderedList = _.sortBy(Object.values(categories.summary), (item) =>
    sortIds.indexOf(item.id)
  );
  const list = orderedList.filter((v) => v.isActive);
  const options = (filter ? list.filter((value) => filter(value)) : list).map(
    (value) => ({
      label: `${value.name} ( ${value.patientCount} member(s) )`,
      value: useCode ? value.code : value.id
    })
  );
  return (
    <Container>
      {loading && <AbsoluteLoader />}
      {type === 'multiple' && (
        <Multiple {...(props as IMultiple)} type="multiple" options={options} />
      )}
      {(type === undefined || type === 'single') && (
        <Single {...(props as ISingle)} type="single" options={options} />
      )}
    </Container>
  );
};

export default Component;

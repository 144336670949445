import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { getClinicByCode } from 'src/Framework/Controls/Selectors/ClinicSelector';
import store, { IStore } from 'src/store';

import AppointmentPopup, { IAlertsData } from './AppointmentPopup';
import {
  updateAppointment,
  createScheduleEvent,
  getCategoryList,
  getFormsFromClinic,
  getSubjectSummary,
  updateSubjectSummary
} from 'src/Activities/Schedule/Store/ScheduleActions/ScheduleDetailActions/ScheduleDetailAction';
import {
  getScheduleAppointment,
  removeScheduleEvent
} from 'src/Activities/Schedule/Store/ScheduleEventsReducer/Actions';
import { IScheduleClinic } from 'src/Activities/Schedule/Store/ScheduleReducers/ScheduleDetailsReducer/types';
import { IApptResponse } from 'src/Activities/Schedule/Store/ScheduleEventsReducer/types';
import {
  createNewEventPayload,
  mapClinic
} from 'src/Activities/Schedule/Utils/Utils';
import { findItem } from 'src/Activities/Subject/PatientListActions';
import { setEventDuration } from './utils';

import { Container } from './AppointmentPopup_Styles';

// import { updateEventsOnAction } from '../../Store/ScheduleSliceEventsActions';

interface IState {
  conflicts: any;
  canAdd: boolean;
  categories: any;
  eventInfo: IApptResponse | null;
  forms: any;
  loading: boolean;
}

interface IOptions {
  startTime: string;
  endTime: string;
  date: string;
  scheduleEventTypeId: number;
  default?: boolean;
  clinicCode?: string;
  clinicName?: string;
  reason?: any;
  updateSeries?: boolean;
  defaultPatientIds?: number[];
  providerCode?: string | null;
  providerName?: string | null;
  typeAppointmentParticipantId?: number;
}

export interface IActionOptions extends Partial<IOptions> {
  uid: string;
  action: string;
  cellRef?: React.MutableRefObject<any>;
  isTriage?: boolean;
  triageUpdateCallback?: (apptData: any) => void;
}

interface IOwnProps {
  actionOption: IActionOptions;
  onEventSaved?: (newEventPayload: any) => void;
  onEventDeleted?: (newEventPayload: any, response: any) => void;
  onClosePopup: () => void;
}

export interface IProps extends IOwnProps {
  subjectSummary?: any;
  clinics: IScheduleClinic[];
  providers: any;
  onClickAlertsView?: (data: IAlertsData) => any;
  isTriage?: boolean;
  onCreateTriage?: (payload: any) => any;
}

class AppointmentPopupContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      forms: null,
      canAdd: false,
      categories: [],
      eventInfo: null,
      conflicts: {
        allowOverride: true,
        hasConflicts: false,
        message: '',
        reasonCode: null,
        conflictResponse: []
      }
    };
  }

  updateSummary = async (patientId: number) => {
    const reqTime = new Date().getTime();
    try {
      const response = await getSubjectSummary(patientId);
      if (response.data.success) {
        store.dispatch(updateSubjectSummary(reqTime, response.data.result));
        return response.data.result;
      } else {
        console.log('get subject summary success false');
      }
    } catch (e) {}
  };
  getApptEvent = async () => {
    this.setState({ loading: true });
    const { actionOption } = this.props;
    const response = await getScheduleAppointment(actionOption.uid);
    if (response) {
      this.setState(
        {
          eventInfo: response
        },
        () => {
          const patientId = response.scheduleEvent.patientInfo?.patientId;
          if (patientId) {
            this.updateSummary(patientId);
          }
          this.getForms(
            response.scheduleEvent.reason.id,
            response.scheduleEvent.clinicCode,
            true
          );
        }
      );
    }
    this.setState({ loading: false });
  };
  componentDidMount = async () => {
    const { actionOption } = this.props;
    if (actionOption.action === 'edit') {
      this.getApptEvent();
    } else {
      const options = actionOption;
      if (!options.clinicCode) {
        options.clinicCode = this.props.clinics[0]?.code;
      }
      let newEventPayload = createNewEventPayload(options);
      if (actionOption.clinicCode) {
        newEventPayload.scheduleEvent.clinicCode = actionOption.clinicCode;
      }
      if (actionOption.clinicName) {
        newEventPayload.scheduleEvent.clinicName = actionOption.clinicName;
      }
      const clinicOpts = this.props.clinics.map((c: any) => mapClinic(c));
      const selectedClinic = findItem(
        clinicOpts,
        newEventPayload.scheduleEvent.clinicCode
      );
      newEventPayload.scheduleEvent.reason = options.reason;
      if (options.reason) {
        if (newEventPayload.scheduleEvent.scheduleEventDateTime) {
          if (options.reason.duration > 0) {
            newEventPayload = setEventDuration(
              selectedClinic,
              newEventPayload,
              newEventPayload.scheduleEvent.scheduleEventDateTime,
              options.reason.duration
            );
          }
        }
        let inSeries =
          newEventPayload.scheduleEvent.series?.typeFrequencyId > 0;
        if (!newEventPayload.scheduleEvent.series.endTime || !inSeries) {
          newEventPayload.scheduleEvent.series.endTime =
            newEventPayload.scheduleEvent.endTime;
        }
        const formsResponse = await getFormsFromClinic(
          options.reason.id,
          selectedClinic.id
        );
        let newForms;
        let result = formsResponse.data.result;
        if (formsResponse.data.success) {
          newForms = Object.values(result.appointmentReasonForms || {}).filter(
            (item: any) => !item.inactive && item.selected
          );
          newEventPayload.appointmentForms = newForms;
        }
      }
      if (actionOption.defaultPatientIds) {
        newEventPayload.patients = actionOption.defaultPatientIds;
        if (newEventPayload.patients.length === 1) {
          const res = await this.updateSummary(newEventPayload.patients[0]);
          if (res) {
            newEventPayload.subjectSummary = res;
          }
        }
      }
      this.setState({
        eventInfo: newEventPayload
      });
    }
    this.getCategoryCodes();
  };
  getForms = async (
    appointmentId: any,
    clinicCode: any,
    selection: boolean = false
  ) => {
    const selectedClinic = getClinicByCode(clinicCode);
    try {
      const response = await getFormsFromClinic(
        appointmentId,
        selectedClinic?.id
      );
      let result = response.data.result;
      if (response.data.success) {
        let forms: { clinicForms: any; appointmentReasonForms: any } = {
          clinicForms: result.clinicForms,
          appointmentReasonForms: undefined
        };
        if (selection) {
          forms.appointmentReasonForms = _.pickBy(
            result.appointmentReasonForms,
            (item) => !item.inactive
          );
        }
        this.setState({
          forms
        });
      }
    } catch (error) {
      console.log('getScheduleReasons error', error);
    }
  };

  getValueAndLabel(categoriesInfo: []) {
    const sortedCategoriesInfo = _.sortBy(categoriesInfo, (item: any) =>
      item.name?.toLowerCase()
    );
    const categories = sortedCategoriesInfo.map((category) => ({
      label: `${category.name} ( ${category.count} member(s) )`,
      value: category.code,
      count: category.count
    }));
    return categories;
  }

  getCategoryCodes = async () => {
    try {
      const response = await getCategoryList();
      if (response.data.success) {
        const categories = this.getValueAndLabel(response.data.result);
        this.setState({
          categories
        });
      }
    } catch (e) {
      console.log({ e });
    }
  };

  onUpdateEvent = async (newEventPayload: any) => {
    const { actionOption } = this.props;
    newEventPayload.updateSeries = actionOption.updateSeries;
    newEventPayload['dts'] = new Date();
    this.setState({
      canAdd: true,
      conflicts: {
        hasConflicts: false,
        message: '',
        reasonCode: null,
        conflictResponse: [],
        allowOverride: true
      }
    });
    try {
      const response = await updateAppointment(newEventPayload);
      if (response.data.success) {
        if (!response.data.result.hasConflicts) {
          if (this.props.onEventSaved) {
            this.props.onEventSaved(response.data.result);
            this.setState({
              canAdd: false
            });
          }
          store.dispatch(removeScheduleEvent(newEventPayload.uid));
          this.props.onClosePopup();
        } else {
          const temporaryConflictResponse = response.data.result.conflicts;
          const conflictResponse = _.uniqBy(
            temporaryConflictResponse,
            'scheduleEventKey'
          );
          conflictResponse.forEach((item: any, indexL: any) => {
            conflictResponse[indexL]['dialogResponse'] = 'override';
          });
          newEventPayload['conflictsResponses'] = conflictResponse;
          this.setState({
            conflicts: {
              hasConflicts: true,
              message:
                response.data.result.reasonCode !== 4
                  ? response.data.result.message
                  : 'Type doesnt match',
              conflictResponse: conflictResponse,
              allowOverride: response.data.result.allowOverride
            }
          });
        }
      } else {
        console.log('updateBlock response success false');
      }
    } catch (e) {
      console.log({ e });
    } finally {
      this.setState({
        canAdd: false
      });
    }
  };
  onCreateEvent = async (newEventPayload: any) => {
    try {
      newEventPayload['dts'] = new Date();
      this.setState({
        canAdd: true,
        conflicts: {
          hasConflicts: false,
          message: '',
          reasonCode: null,
          conflictResponse: []
        }
      });
      const response = await createScheduleEvent(newEventPayload);
      if (response.data.success) {
        if (!response.data.result.hasConflicts) {
          if (this.props.onEventSaved) {
            this.props.onEventSaved(response.data.result);
          }
          // updateEventsOnAction(response.data.result)
          this.props.onClosePopup();
        } else {
          const temporaryConflictResponse = response.data.result.conflicts;
          const conflictResponse = _.uniqBy(
            temporaryConflictResponse,
            'scheduleEventKey'
          );
          conflictResponse.forEach((item: any, indexL: any) => {
            conflictResponse[indexL]['dialogResponse'] = 'override';
          });
          this.setState({
            conflicts: {
              hasConflicts: true,
              message:
                response.data.result.reasonCode !== 4
                  ? response.data.result.message
                  : 'Type doesnt match',
              reasonCode: response.data.result.reasonCode,
              conflictResponse: conflictResponse,
              allowOverride: response.data.result.allowOverride
            }
          });
        }
      } else {
        console.log('createScheduleEvent response success false');
      }
    } catch (error) {
      console.log('createScheduleEvent error', error);
    } finally {
      this.setState({
        canAdd: false
      });
    }
  };

  resetConflicts() {
    this.setState({
      conflicts: {
        hasConflicts: false,
        message: '',
        reasonCode: null,
        conflictResponse: [],
        allowOverride: true
      }
    });
  }

  onSaveEvent = async (newEventPayload: any) => {
    const { isTriage, onCreateTriage } = this.props;
    if (isTriage) {
      if (this.props.actionOption.action === 'edit') {
        this.onUpdateEvent(newEventPayload);
      } else {
        if (onCreateTriage) {
          this.setState({ canAdd: true });
          await onCreateTriage(newEventPayload);
          this.setState({ canAdd: false });
        }
      }
      return;
    }
    if (this.props.actionOption.action === 'edit') {
      this.onUpdateEvent(newEventPayload);
    } else {
      this.onCreateEvent(newEventPayload);
    }
  };

  render() {
    return (
      <Container>
        {this.state.eventInfo && this.state.eventInfo.scheduleEvent ? (
          <AppointmentPopup
            key={'appointment_popup'}
            {...this.props}
            eventInfo={this.state.eventInfo}
            conflicts={this.state.conflicts}
            resetConflicts={() => this.resetConflicts()}
            getForms={this.getForms.bind(this)}
            forms={this.state.forms}
            categories={this.state.categories}
            canAdd={this.state.canAdd}
            onSaveEvent={(newEventPayload: any) =>
              this.onSaveEvent(newEventPayload)
            }
          />
        ) : (
          <AbsoluteLoader />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state: IStore, ownProps: IOwnProps) => {
  let subjectSummary = state.scheduleDetails.subjectSummary;
  return {
    subjectSummary,
    clinics: state.scheduleDetails.clinics,
    providers: state.schedule.clinicProviders.data
  };
};
export default connect(mapStateToProps)(AppointmentPopupContainer);

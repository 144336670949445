import React from "react";

interface IProps {
}

export const CaretUpSvg = ({}: IProps) => (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.35355 5.35355C9.15829 5.54882 8.84171 5.54882 8.64645 5.35355L5 1.70711L1.35355 5.35355C1.15829 5.54882 0.841709 5.54882 0.646446 5.35355C0.451184 5.15829 0.451184 4.84171 0.646446 4.64645L4.64645 0.646447C4.84171 0.451184 5.15829 0.451184 5.35355 0.646447L9.35355 4.64645C9.54882 4.84171 9.54882 5.15829 9.35355 5.35355Z" fill="#333333"/>
    </svg>
);


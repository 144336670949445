import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
`

export const Body = styled.div`
    position: relative;
    display: flex;
    flex: 1;
`

export const BodyInner = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex: 1;
    overflow: overlay;
    padding: 0 28px 0 28px;
`

export const Footer = styled.div`
    padding: 20px 24px;
    border-top: 1px solid rgba(0,0,0,0.1);
`
import React from 'react';
import { IHeaderProps } from './Header';
import { INoDataProps } from 'src/Framework/Controls/Table/NoData';

export interface IColumnFilter {
  filterEnabled?: boolean;
  filterTooltip?: string | null | undefined;
  filterContent: (data: {
    onOpenChange: (value: boolean) => any;
  }) => React.ReactNode;
  onPopoverStateChanged?: (value: boolean) => any;
}

// This enum needs to identify some column all over the product
export enum ColumnIdentifier {
  patientId = 'patientId'
}

export enum ColumnGlobalTitle {
  accountNumber = 'Account #'
}

export interface IColumn extends Partial<IColumnFilter> {
  minWidth?: number;
  span?: number;
  title: any;
  sortBy?: string;
  key: string;
  centered?: boolean;
  justify?: string;
  type?: 'change-order';
  flex?: number | 'none' | 'auto' | string;
  style?: any;
  ellipsis?: boolean;
  tooltipText?: string;
  sticky?: {
    position: 'right' | 'left';
  };
  headerEllipsis?: boolean;
  identifier?: ColumnIdentifier;
}

export interface ISort {
  sortBy: string | null;
  sortType: 'asc' | 'desc' | null;
}

export interface IDragAndDrop {
  rootList: Array<any>;
  onChangeOrder: (prevIndex: number, nextIndex: number) => void;
}

export interface IDefaultSortOptions {
  defaultSortBy: string;
  defaultSortType: 'desc' | 'asc';
}

export interface ITableBatchSettings<T = any> {
  id: string | number;
  title: string | React.ReactNode;
  rightComponent?: React.ReactNode;
  defaultOpen?: boolean;
  onChangeOpen?: (value: boolean) => any;
  children: ITableRenderItemData<T>[];
}

export interface IMouseOverRow {
  position?: 'right' | 'left'; // In progress
  component: ({
    onOpenPopoverChange,
    active,
    onPreventClose
  }: {
    onOpenPopoverChange: (value: boolean) => any;
    onPreventClose: (value: boolean) => any;
    active: boolean;
  }) => React.ReactNode;
}

export interface IUnderRowView {
  defaultOpened?: boolean;
  onOpenChanged?: (value: boolean) => any;
  component: React.ReactNode;
}

export type IOptional<T> = {
  HIDE_ITEM?: boolean
  hideOrderButtons?: boolean;
  mouseOverRow?: IMouseOverRow;
  batchSettings?: ITableBatchSettings<T>;
  children?: any[];
  underRowView?: IUnderRowView;
  underRowComponent?: any;
  id?: any;
  memoizeBy?: any;
  tempPushTimestampField?: number;
  onRowMount?: (value: ITableRenderItemData<T>) => any;
  onRowUnMount?: (value: ITableRenderItemData<T>) => any;
  rowAdditionalStyles?: React.CSSProperties;
  searchFields?: string[]
  // Not prepared
  colAdditionalStyles?: (column: IColumn) => React.CSSProperties;
};

export type ITableRenderItemData<A = any> = Partial<IOptional<A>> & {
  [key: string]: any;
};

export interface ITablePagination {
  pageLength: number;
  startIndex: number;
  total: number;
  onChangePage: (value: number) => any;
}

export type IRenderItemData<A> = (value: A, index: number) => ITableRenderItemData<A>

export interface IProps<T = any, A = any> extends IHeaderProps {
  rowBackgroundTransparent?: boolean;
  enableRelativePosition?: boolean;
  paginationControls?: ITablePagination;
  enableBorder?: boolean;
  dndDisabledTooltip?: string;
  disableDragAndDrop?: boolean;
  pushHightlightColor?: string;
  dataSource?: A[];
  renderItemData?: IRenderItemData<A>;
  // Functions
  rowComponentContainer?: ({
    children,
    value,
    index
  }: {
    children: any;
    value: T & IOptional<T>;
    index: number;
  }) => any;
  onChangeSort?: (value: ISort) => any;
  disableDefaultSort?: boolean;
  onClickRow?: (index: number, value: T & IOptional<T>) => any;
  onChangeOrder?: (prevIndex: number, nextIndex: number) => void;
  columnClassName?: (
    value: T & IOptional<T>,
    rowIndex: number,
    colIndex: number
  ) => string;
  onRowOpen?: Function;
  dynamicClassNameRow?: {
    className: string;
    onEnabled: (value: T & IOptional<T>, index: number) => boolean;
  };
  hightlightFunction?: Function;
  selectedRowsFunction?: Function;
  // Dynamic
  loading?: boolean;
  data?: (T & IOptional<T>)[];
  onKeyDownRow?: (e: React.KeyboardEvent<HTMLDivElement>) => any;
  innerPagination?: {
    pageNumber: number;
    pageLength: number;
  };
  enablePagination?: boolean;
  onChangeInnerPagination?: (page: number) => any;
  rowIdFieldname?: string;
  rowHover?: boolean;
  bodyContainerId?: string;
  absoluteContainer?: boolean;
  defaultSortOptions?: IDefaultSortOptions;
  openedRows?: Array<number>;
  hideHeader?: boolean;
  emptyComponent?: React.ReactNode;
  infinity?: boolean;
  infinityProps?: any;
  openedRowClassname?: string;
  openedColumnClassname?: string;
  rowClassname?: string;
  containerId?: string;
  infinityContainerProps?: any;
  disableEmptyContainerMargin?: boolean;
  disableVerticalPadding?: boolean;
  absoluteLoading?: boolean;
  containerStyleProps?: React.CSSProperties;
  disableScrollSync?: boolean;
  tableCaption?: string;
  noDataProps?: INoDataProps;
}

export interface TempFieldsForTable {
  tempPushTimestampField: number;
}

import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 20px;
  border-bottom: 1px solid ${globalStyleVariables.borderColor};
  background-color: ${globalStyleVariables.grey};

  ol {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    gap: 20px;

    li {
      list-style-type: none;
      position: relative;
    }

    li:not(:last-child)::after {
      content: '>';
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      right: -14px;
    }
  }

  .add-container {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;

    span {
      color: ${globalStyleVariables.newBrandingPrimary200};
      margin-left: 12px;
      font-family: Lato;
      font-weight: normal;
      font-size: 14px;
    }
  }
`;

interface IItemContainer {
  onClick?: () => any;
}

export const ItemContainer = styled.a<IItemContainer>`
  ${({ onClick }) =>
    !onClick &&
    `
  cursor: auto;
  `}
`;

export const Item = styled.div`
  color: ${globalStyleVariables.darkGrey};
  font-family: Encode Sans Expanded;
  font-weight: 800;
  font-size: 12px;
  line-height: 20px;
`;

interface IRightPart {
  noGap?: boolean;
}

export const RightPart = styled.div<IRightPart>`
  display: flex;
  flex: 1;
  align-items: center;
  gap: ${({ noGap }) => (noGap ? '8px 0' : '8px 16px')};
  flex-wrap: wrap;
`;

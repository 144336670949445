import styled from 'styled-components';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export const BarContainer = styled.div`
  height: 50px !important;
  background: #ffeaea;
  border-radius: 48px;
  margin: 12px 24px 0 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-right: 8px;

  .count {
    color: #ff2828;
    margin-right: 8px;
  }

  svg {
    margin: 3px 6px 0 8px;
  }

  div::-webkit-scrollbar {
    width: 4px;
  }

  .ant-popover-inner-content,
  .ant-popover-content,
  .ant-popover-inner {
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .view-conflicts-button {
    font-size: 14px;
    color: ${globalStyleVariables.white};
    background-color: ${globalStyleVariables.red};
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;

    svg {
      margin: 0 !important;
    }
  }

  .view-conflicts-button-active {
    color: ${globalStyleVariables.red};
    background-color: transparent;
  }
`;

export const PopoverContainer = styled.div`
  display: block;
`;

export const ConflictsContainer = styled.div`
  position: relative;
  height: 316px !important;
  overflow-y: hidden;
  width: 100%;

  -webkit-scrollbar {
    width: 10px;
  }
`;
export const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  .override-conflicts-button {
    color: ${globalStyleVariables.white};
    background-color: ${globalStyleVariables.red};
  }
`;

export const ListContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  width: 576px;
  height: 304px;
  overflow-y: auto;
`;

export const Info = styled.div`
  width: 100%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:last-of-type {
    width: 160px;
    text-align: right;
  }
`;

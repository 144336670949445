import React, { FC } from 'react';
import { ITicketDetailHipaa, TicketDetailHippaAction } from '../../store/types';
import { patientDisplayName } from 'src/Framework/util/format';
import { formattedDate } from 'src/Framework/Shared/Shared';
import { usePatients } from 'src/App/Patients/Hooks/usePatient';
import { Container } from './styled';
import { getHipaaColor } from '../utils';
import { getCurrentClinicId } from 'src/Framework/Controls/Selectors/ClinicSelector';
import { isHippoExpired } from '../../store/utils';

interface IProps {
  data: ITicketDetailHipaa;
  disableHippaColor?: boolean;
  clinicId?: number;
}

const expiredText = (data: ITicketDetailHipaa) => (
  <span>
    Privacy Policy <b>expired</b> since <b>{formattedDate(data.dosFrom)}</b>
  </span>
);

const Component: FC<IProps> = ({
  data,
  disableHippaColor,
  clinicId
}: IProps) => {
  const currentClinicId = clinicId || getCurrentClinicId();
  const patients = usePatients();
  const patient = patients.getPatient(data.patientId);
  const expired = isHippoExpired(currentClinicId, data?.dosFrom);
  const ReadRevoked = () => {
    return (
      <span>
        <b>{patientDisplayName(patient)}</b>
        <span>{` did not reply to the privacy policy on ${formattedDate(
          data.dosFrom
        )}`}</span>
      </span>
    );
  };
  const values = {
    [TicketDetailHippaAction.Accept]: (
      <span>
        <b>{patientDisplayName(patient)}</b>
        <span>{` accepted the privacy policy on ${formattedDate(
          data.dosFrom
        )}`}</span>
      </span>
    ),
    [TicketDetailHippaAction.Decline]: (
      <span>
        <b>{patientDisplayName(patient)}</b>
        <span>{` declined the privacy policy on ${formattedDate(
          data.dosFrom
        )}`}</span>
      </span>
    ),
    [TicketDetailHippaAction.Read]: ReadRevoked(),
    [TicketDetailHippaAction.Revoked]: ReadRevoked()
  };
  return (
    <Container
      style={{
        color: !disableHippaColor
          ? getHipaaColor(data, currentClinicId)
          : undefined
      }}
    >
      {expired ? expiredText(data) : values[data.action] || ''}
    </Container>
  );
};

export default React.memo(Component);

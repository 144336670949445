import React from 'react';

import _ from 'lodash';

import { IItemTree } from 'src/Framework/Controls/Tree';
import { idGenerator } from 'src/Framework/Controls/Tree/utils';
import { onKeyDownCallbacks } from 'src/Framework/util/accessibility';
import { getPatientTerm } from 'src/Framework/util/patient-term-util';
import history from 'src/history';

import {
  PortalOptionGroupNames,
  PortalOptionGroup
} from './Pages/PatientPortalSetup/store/types';
import {
  additionalInfoPermission,
  counselingPermission,
  diagnosisCodesByClinicPermission,
  diagnosisCodesPermission,
  disclaimerPermission,
  dropdownManagement,
  formBuilderPermission,
  IPermissionResult,
  ordersManagerPermission,
  patientPortalSetupPermission,
  planPermission,
  pptSetupPermissions,
  providersPermission,
  usersPermission,
  providerTypesPermission,
  ruleManagerPermission,
  supervisionNoteTypes,
  supervisionTrainee,
  txnCodePermission,
  txnSetsPermission,
  pointOfCarePermission,
  labsPermission,
  limitChartAccessPermission,
  securityPermission
} from './permissions';
import { getDisclaimerStatus } from './Pages/PatientPortalSetup/store/utils';
import { TypeTxnClass } from './Pages/TransactionCodes/store/types';

const NoteTypes = React.lazy(
  () => import(/* webpackChunkName: "NoteTypes" */ './Pages/NoteTypes')
);
const SupervisionNoteTypes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SupervisionNoteTypes" */ './Pages/SupervisionNoteTypes'
    )
);
const TypeRisks = React.lazy(
  () => import(/* webpackChunkName: "TypeRisks" */ './Pages/TypeRisks')
);
const InsuranceCompanies = React.lazy(
  () =>
    import(
      /* webpackChunkName: "InsuranceCompanies" */ './Pages/InsuranceCompanies'
    )
);
const ProviderTypes = React.lazy(
  () => import(/* webpackChunkName: "ProviderTypes" */ './Pages/ProviderTypes')
);
const Categories = React.lazy(
  () => import(/* webpackChunkName: "Categories" */ './Pages/Categories')
);
const AppointmentTypes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AppointmentTypes" */ './Pages/AppointmentTypes'
    )
);
const ListOfClinics = React.lazy(
  () => import(/* webpackChunkName: "ListOfClinics" */ './Pages/Clinics')
);
const Users = React.lazy(
  () => import(/* webpackChunkName: "Users" */ './Pages/Users')
);
const UserGroups = React.lazy(
  () => import(/* webpackChunkName: "UserGroups" */ './Pages/UserGroups')
);
const LimitChartAccess = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LimitChartAccess" */ './Pages/LimitChartAccess'
    )
);
const Plan = React.lazy(
  () => import(/* webpackChunkName: "Plan" */ './Pages/Plan')
);
const AutomatedTasks = React.lazy(
  () =>
    import(/* webpackChunkName: "AutomatedTasks" */ './Pages/AutomatedTasks')
);
const Providers = React.lazy(
  () => import(/* webpackChunkName: "Providers" */ './Pages/Providers')
);
const RulesManager = React.lazy(
  () =>
    import(
      /* webpackChunkName: "RulesManager" */ 'src/Activities/SystemSetup/RulesManager/RulesManager'
    )
);
const NotificationManager = React.lazy(
  () =>
    import(
      /* webpackChunkName: "NotificationManager" */ 'src/Activities/SystemSetup/NotificationManager/NotificationManager'
    )
);
const SupervisorRoles = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SupervisorRoles" */ 'src/App/Admin/Pages/SupervisorRoles'
    )
);

// Form builder
const BuildingBlocks = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BuildingBlocks" */ './Pages/FormBuilder/Lists/BuildingBlocks'
    )
);
const HeadersAndFooters = React.lazy(
  () =>
    import(
      /* webpackChunkName: "HeadersAndFooters" */ './Pages/FormBuilder/Lists/HeadersAndFooters'
    )
);
const Forms = React.lazy(
  () =>
    import(/* webpackChunkName: "Forms" */ './Pages/FormBuilder/Lists/Forms')
);

const LetterTemplatesManagement = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LetterTemplatesManagement" */ './Pages/LetterTemplatesManagement'
    )
);

const QAndA = React.lazy(
  () => import(/* webpackChunkName: "Q&A" */ './Pages/Q&A')
);

const Settings = React.lazy(
  () => import(/* webpackChunkName: "Settings" */ './Pages/Settings')
);

const GroupTypes = React.lazy(
  () => import(/* webpackChunkName: "Settings" */ './Pages/GroupTypes')
);

const PatientMenuItems = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PatientMenuItems" */ './Pages/PatientMenuItems'
    )
);

const TxnClinicLinks = React.lazy(
  () =>
    import(/* webpackChunkName: "TxnClinicLinks" */ './Pages/TxnClinicLinks')
);

const DiagnosisClinicLinks = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DiagnosisClinicLinks" */ './Pages/DiagnosisClinicLinks'
    )
);

// Txn codes

const TxnHomework = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TxnHomework" */ './Pages/TransactionCodes/Pages/Homework'
    )
);

// const InsurancePayment = React.lazy(
//   () =>
//     import(
//       /* webpackChunkName: "InsurancePayment" */ './Pages/TransactionCodes/Pages/InsurancePayment'
//     )
// );

// const ChargeAdjustment = React.lazy(
//   () =>
//     import(
//       /* webpackChunkName: "ChargeAdjustment" */ './Pages/TransactionCodes/Pages/ChargeAdjustment'
//     )
// );

// const Payment = React.lazy(
//   () =>
//     import(
//       /* webpackChunkName: "Payment" */ './Pages/TransactionCodes/Pages/Payment'
//     )
// );

// const PaymentAdjustment = React.lazy(
//   () =>
//     import(
//       /* webpackChunkName: "PaymentAdjustment" */ './Pages/TransactionCodes/Pages/PaymentAdjustment'
//     )
// );

const XRay = React.lazy(
  () =>
    import(/* webpackChunkName: "XRay" */ './Pages/TransactionCodes/Pages/XRay')
);

const PointOfCare = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PointOfCare" */ './Pages/TransactionCodes/Pages/PointOfCare'
    )
);

const Education = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Education" */ './Pages/TransactionCodes/Pages/Education'
    )
);

const ProcedureAndService = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProcedureAndService" */ './Pages/TransactionCodes/Pages/ProcedureAndService'
    )
);

// const Recall = React.lazy(
//   () =>
//     import(
//       /* webpackChunkName: "Recall" */ './Pages/TransactionCodes/Pages/Recall'
//     )
// );

// const Allergy = React.lazy(
//   () =>
//     import(
//       /* webpackChunkName: "Allergy" */ './Pages/TransactionCodes/Pages/Allergy'
//     )
// );

const Lab = React.lazy(
  () =>
    import(/* webpackChunkName: "Lab" */ './Pages/TransactionCodes/Pages/Lab')
);

const Nurse = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Nurse" */ './Pages/TransactionCodes/Pages/Nurse'
    )
);

const Referral = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Referral" */ './Pages/TransactionCodes/Pages/Referral'
    )
);

// const Rx = React.lazy(
//   () => import(/* webpackChunkName: "Rx" */ './Pages/TransactionCodes/Pages/Rx')
// );

// -- end txn codes

const CheckInStatus = React.lazy(
  () => import(/* webpackChunkName: "CheckInStatus" */ './Pages/CheckInStatus')
);

const DiagnosisCodes = React.lazy(
  () =>
    import(/* webpackChunkName: "DiagnosisCodes" */ './Pages/DiagnosisCodes')
);

const TraineeType = React.lazy(
  () => import(/* webpackChunkName: "TraineeType" */ './Pages/TraineeType')
);

const AdditionalInfo = React.lazy(
  () =>
    import(/* webpackChunkName: "AdditionalInfo" */ './Pages/AdditionalInfo')
);

const PublicForms = React.lazy(
  () => import(/* webpackChunkName: "PublicForms" */ './Pages/PublicForms')
);

const PatientPortalSetup = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PatientPortalSetup" */ './Pages/PatientPortalSetup'
    )
);

const Reordering = React.lazy(
  () => import(/* webpackChunkName: "Reordering" */ './Pages/Reordering')
);

const Adjustments = React.lazy(
  () => import(/* webpackChunkName: "Adjustments" */ './Pages/Adjustments')
);

const StatusSetup = React.lazy(
  () =>
    import(
      /* webpackChunkName: "StatusSetup" */ './Pages/OrdersManager/StatusSetup'
    )
);

const ResultCodes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ResultCodes" */ './Pages/OrdersManager/ResultCodes'
    )
);
const OrderActions = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OrderActions" */ './Pages/OrdersManager/OrderActions'
    )
);

const ReportSetup = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReportSetup" */ './Pages/OrdersManager/ReportSetup'
    )
);

const TransactionSets = React.lazy(
  () =>
    import(/* webpackChunkName: "TransactionSets" */ './Pages/TransactionSets')
);

const Disclaimer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Disclaimer" */ './Pages/PatientPortalSetup/Disclaimer'
    )
);

const ADUsers = React.lazy(
  () => import(/* webpackChunkName: "ADUsers" */ './Pages/ADUsers')
);

const LegacyTxnLinker = React.lazy(
  () => import(/* webpackChunkName: "LegacyTxnLinker" */ './Pages/LegacyTxnLinker')
);

export const getFirstPage = (item: IItem) => {
  const key = item.withoutClick ? Object.keys(item.options)[0] : item.key;
  return {
    key,
    path: item.withoutClick ? PagesList()[key].path : `${PagesList()[key].path}`
  };
};

export const letterTemplatesManagementUrl =
  '/admin/letter-templates-management';

export const genericTypeMainUrl = '/admin/group-types';

export const publicFormsMainUrl = '/admin/public-forms';

export const patientMenuItemsUrl = '/admin/patient-menu-items';

export const patientPortalSetupUrl = '/admin/patient-portal-setup';

export const marketplaceDisplayName = 'Library';

interface IPageListItem {
  path: string;
  component: any;
  key: string;
  permission?: () => IPermissionResult;
  containerId?: string;
  exact?: boolean;
}

interface IPageList {
  [key: string]: IPageListItem;
}

const portalOptionSetupKey = (key: PortalOptionGroup) => {
  return `portalOption${key}`;
};

const portalOptionsPages = (): {
  data: IPageList;
  groups: PortalOptionGroup[];
} => {
  const data: IPageList = {};
  const groups: PortalOptionGroup[] = [
    PortalOptionGroup.Main,
    PortalOptionGroup.Forms,
    PortalOptionGroup.SecureMessage,
    PortalOptionGroup.Immunization,
    PortalOptionGroup.Insurance,
    PortalOptionGroup.Education,
    PortalOptionGroup.Statements,
    PortalOptionGroup.Status,
    PortalOptionGroup.LabResults,
    PortalOptionGroup.DocumentUpload,
    PortalOptionGroup.VisitPublish,
    PortalOptionGroup.Authentication,
    PortalOptionGroup.Appointments
  ];
  const disclaimerData = getDisclaimerStatus();
  if (disclaimerData && disclaimerData.isAccepted) {
    groups.forEach((k) => {
      const key = portalOptionSetupKey(k);
      data[key] = {
        path: `${patientPortalSetupUrl}/${k}`,
        component: PatientPortalSetup,
        exact: false,
        key: key,
        containerId: `admin-patient-portal-setup-${k}`,
        permission: pptSetupPermissions[k]
      };
    });
  } else {
    return {
      data,
      groups: []
    };
  }

  return { data, groups };
};

export const PagesList = (): IPageList => ({
  checkInStatus: {
    path: '/admin/check-in-status',
    component: CheckInStatus,
    key: 'checkInStatus',
    containerId: 'admin-check-in-status'
  },
  settings: {
    path: '/admin/settings',
    component: Settings,
    key: 'settings',
    containerId: 'admin-settings'
  },
  QAndA: {
    path: '/admin/q&a',
    component: QAndA,
    key: 'QAndA',
    containerId: 'admin-QAndA'
  },
  letterTemplatesManagement: {
    exact: false,
    path: letterTemplatesManagementUrl,
    component: LetterTemplatesManagement,
    key: 'letterTemplatesManagement',
    containerId: 'admin-letter-templates-management'
  },
  riskTypes: {
    path: '/admin/risks-type',
    component: TypeRisks,
    key: 'riskTypes',
    permission: () => counselingPermission(),
    containerId: 'admin-risk-type'
  },
  noteTypes: {
    path: '/admin/note-types',
    component: NoteTypes,
    key: 'noteTypes',
    permission: () => counselingPermission(),
    containerId: 'admin-note-types'
  },
  supervisionNoteTypes: {
    path: '/admin/supervision-note-types',
    component: SupervisionNoteTypes,
    key: 'supervisionNoteTypes',
    permission: () => supervisionNoteTypes(),
    containerId: 'admin-supervision-note-types'
  },
  providerTypes: {
    path: '/admin/provider-types',
    component: ProviderTypes,
    key: 'providerTypes',
    permission: () => providerTypesPermission(),
    containerId: 'admin-provider-types'
  },
  rule_manager: {
    path: '/admin/rule-manager',
    component: RulesManager,
    key: 'rule_manager',
    containerId: 'admin-rule-manager',
    permission: () => ruleManagerPermission()
  },
  notification_manager: {
    path: '/admin/notification-manager',
    component: NotificationManager,
    key: 'notification_manager',
    containerId: 'admin-notification-manager'
  },
  insurance_companies: {
    path: '/admin/insurance-companies',
    component: InsuranceCompanies,
    key: 'insurance_companies',
    containerId: 'admin-insurance-companies'
  },
  categories: {
    path: '/admin/categories',
    component: Categories,
    key: 'categories',
    containerId: 'admin-categories'
  },
  appointment_types: {
    path: '/admin/appointment-types',
    component: AppointmentTypes,
    key: 'appointment_types',
    containerId: 'admin-appointment-types'
  },
  listOfClinics: {
    path: '/admin/list-of-clinics',
    component: ListOfClinics,
    key: 'listOfClinics',
    containerId: 'admin-list-of-clinics'
  },
  users: {
    path: '/admin/users',
    component: Users,
    key: 'users',
    containerId: 'admin-users'
  },
  providers: {
    path: '/admin/providers',
    component: Providers,
    key: 'providers',
    containerId: 'admin-provider-page',
    permission: () => providersPermission()
  },
  plan: {
    path: '/admin/plan',
    component: Plan,
    key: 'plan',
    permission: () => {
      const permission1 = counselingPermission();
      if (!permission1) {
        return permission1;
      }
      return planPermission();
    },
    containerId: 'admin-plan'
  },
  automatedTasks: {
    path: '/admin/automated-tasks',
    component: AutomatedTasks,
    key: 'automatedTasks',
    permission: () => counselingPermission(),
    containerId: 'admin-automated-tasks'
  },
  userGroups: {
    path: '/admin/user-groups',
    component: UserGroups,
    key: 'userGroups',
    containerId: 'admin-user-groups'
  },
  limitChartAccess: {
    path: '/admin/user-limit-chart-access',
    component: LimitChartAccess,
    key: 'limitChartAccess',
    permission: () => limitChartAccessPermission(),
    containerId: 'admin-limit-chart-access'
  },
  buildingBlocks: {
    path: '/admin/building-blocks',
    component: BuildingBlocks,
    key: 'buildingBlocks',
    permission: () => formBuilderPermission(),
    containerId: 'admin-building-blocks'
  },
  headersAndFooters: {
    path: '/admin/headers-footers',
    component: HeadersAndFooters,
    key: 'headersAndFooters',
    permission: () => formBuilderPermission(),
    containerId: 'admin-headers-footers'
  },
  forms: {
    path: '/admin/forms',
    component: Forms,
    key: 'forms',
    permission: () => formBuilderPermission(),
    containerId: 'admin-forms'
  },
  supervisorRoles: {
    path: '/admin/supervision-roles',
    component: SupervisorRoles,
    key: 'supervisorRoles',
    permission: () => supervisionTrainee(),
    containerId: 'admin-supervision-roles'
  },
  traineeType: {
    path: '/admin/traineeType',
    component: TraineeType,
    key: 'traineeType',
    containerId: 'admin-traineeType'
  },
  genericTypes: {
    path: genericTypeMainUrl,
    permission: () => dropdownManagement(),
    component: GroupTypes,
    key: 'genericTypes',
    containerId: 'admin-generic-types'
  },
  genericTypes1: {
    permission: () => dropdownManagement(),
    path: `${genericTypeMainUrl}/:id`,
    component: GroupTypes,
    key: 'genericTypes',
    containerId: 'admin-generic-types'
  },
  patientMenuItems: {
    path: `${patientMenuItemsUrl}`,
    component: PatientMenuItems,
    key: 'patientMenuItems',
    containerId: 'admin-patient-menu-items'
  },
  transactionCodes: {
    path: `/admin/transaction-codes`,
    component: TxnClinicLinks,
    key: 'transactionCodes',
    containerId: 'admin-transaction-codes'
  },
  diagnosisClinicLinks: {
    path: `/admin/diagnosis-clinic-links`,
    component: DiagnosisClinicLinks,
    key: 'diagnosisClinicLinks',
    containerId: 'admin-diagnosis-clinic-links',
    permission: () => diagnosisCodesByClinicPermission()
  },
  homework: {
    path: `/admin/homework`,
    component: TxnHomework,
    key: 'homework',
    containerId: 'admin-homework',
    permission: () => txnCodePermission()
  },
  // insurancePayment: {
  //   path: `/admin/insurancePayment`,
  //   component: InsurancePayment,
  //   key: 'insurancePayment',
  //   containerId: 'admin-insurancePayment',
  //   permission: () => txnCodePermission()
  // },
  // chargeAdjustment: {
  //   path: `/admin/chargeAdjustment`,
  //   component: ChargeAdjustment,
  //   key: 'chargeAdjustment',
  //   containerId: 'admin-chargeAdjustment',
  //   permission: () => txnCodePermission()
  // },
  // payment: {
  //   path: `/admin/payment`,
  //   component: Payment,
  //   key: 'payment',
  //   containerId: 'admin-payment',
  //   permission: () => txnCodePermission()
  // },
  // paymentAdjustment: {
  //   path: `/admin/paymentAdjustment`,
  //   component: PaymentAdjustment,
  //   key: 'paymentAdjustment',
  //   containerId: 'admin-paymentAdjustment',
  //   permission: () => txnCodePermission()
  // },
  xRay: {
    path: `/admin/xRay`,
    component: XRay,
    key: 'xRay',
    containerId: 'admin-xRay',
    permission: () => txnCodePermission()
  },
  pointOfCare: {
    path: `/admin/pointOfCare`,
    component: PointOfCare,
    key: 'pointOfCare',
    containerId: 'admin-pointOfCare',
    permission: () => pointOfCarePermission()
  },
  education: {
    path: `/admin/education`,
    component: Education,
    key: 'education',
    containerId: 'admin-education',
    permission: () => txnCodePermission()
  },
  procedureAndService: {
    path: `/admin/procedureAndService`,
    component: ProcedureAndService,
    key: 'procedureAndService',
    containerId: 'admin-procedureAndService',
    permission: () => txnCodePermission()
  },
  // recall: {
  //   path: `/admin/recall`,
  //   component: Recall,
  //   key: 'recall',
  //   containerId: 'admin-recall',
  //   permission: () => txnCodePermission()
  // },
  // allergy: {
  //   path: `/admin/allergy`,
  //   component: Allergy,
  //   key: 'allergy',
  //   containerId: 'admin-allergy',
  //   permission: () => txnCodePermission()
  // },
  lab: {
    path: `/admin/lab`,
    component: Lab,
    key: 'lab',
    containerId: 'admin-lab',
    permission: () => labsPermission()
  },
  nurse: {
    path: `/admin/nurse`,
    component: Nurse,
    key: 'nurse',
    containerId: 'admin-nurse',
    permission: () => txnCodePermission()
  },
  referral: {
    path: `/admin/referral`,
    component: Referral,
    key: 'referral',
    containerId: 'admin-referral',
    permission: () => txnCodePermission()
  },
  // rx: {
  //   path: `/admin/rx`,
  //   component: Rx,
  //   key: 'rx',
  //   containerId: 'admin-rx',
  //   permission: () => txnCodePermission()
  // },
  diagnosisCodes: {
    path: `/admin/diagnosis-codes`,
    component: DiagnosisCodes,
    key: 'diagnosisCodes',
    containerId: 'admin-diagnosis-codes',
    permission: () => diagnosisCodesPermission()
  },
  additionalInfo: {
    path: `/admin/additional-info`,
    component: AdditionalInfo,
    key: 'additionalInfo',
    containerId: 'admin-additional-info',
    permission: () => additionalInfoPermission()
  },
  publicForms: {
    path: publicFormsMainUrl,
    component: PublicForms,
    key: 'publicForms',
    containerId: 'admin-public-forms'
  },
  publicForms1: {
    path: `${publicFormsMainUrl}/:type`,
    component: PublicForms,
    key: 'publicForms',
    containerId: 'admin-public-forms'
  },
  publicForms2: {
    path: `${publicFormsMainUrl}/:type/:id`,
    component: PublicForms,
    key: 'publicForms',
    containerId: 'admin-public-forms'
  },
  transactionSets: {
    path: `/admin/transaction-sets`,
    component: TransactionSets,
    key: 'transactionSets',
    containerId: 'admin-transaction-sets',
    permission: () => txnSetsPermission()
  },
  ...portalOptionsPages().data,
  reordering: {
    path: `/admin/reordering`,
    component: Reordering,
    key: 'reordering',
    containerId: 'admin-reordering'
  },
  adjustments: {
    path: `/admin/adjustments`,
    component: Adjustments,
    key: 'adjustments',
    containerId: 'admin-adjustments'
  },
  disclaimer: {
    path: `/admin/disclaimer`,
    component: Disclaimer,
    key: 'disclaimer',
    containerId: 'admin-disclaimer',
    permission: () => disclaimerPermission()
  },
  statusSetup: {
    path: `/admin/status-setup`,
    component: StatusSetup,
    key: 'statusSetup',
    containerId: 'status-setup'
  },
  resultCodes: {
    path: `/admin/result-codes`,
    component: ResultCodes,
    key: 'resultCodes',
    containerId: 'result-codes'
  },
  reportSetup: {
    path: `/admin/report-setup`,
    component: ReportSetup,
    key: 'reportSetup',
    containerId: 'report-setup'
  },
  orderActions: {
    path: `/admin/order-actions`,
    component: OrderActions,
    key: 'orderActions',
    containerId: 'order-actions'
  },
  adUsers: {
    path: `/admin/ad-users`,
    component: ADUsers,
    key: 'adUsers',
    containerId: 'ad-users'
  },
  legacyTxnLinker: {
    path: `/admin/legacyTxnLinker`,
    component: LegacyTxnLinker,
    key: 'legacyTxnLinker',
    containerId: 'legacyTxnLinker'
  }
});

export const AdminRightContainerID = 'admin-right-container';

export const handleClasses = {};

[
  'top',
  'left',
  'bottom',
  'topRight',
  'bottomRight',
  'bottomLeft',
  'topLeft'
].forEach((key: string) => {
  // @ts-ignore
  handleClasses[key] = 'displayNone';
});

interface IItem extends IMenuItem, IItemTree {
  children?: any[];
  title: string;
  key: string;
}

export const getTreeData = (data: ISideBar) => {
  const treeData: IItem[] = [];
  const defaultExpandedKeys: Array<string> = [];
  const recursion = (obj: Record<string, IMenuItem>, array: IItem[]) => {
    Object.keys(obj).forEach((key) => {
      let item: IItem = {
        ...obj[key],
        key: '',
        title: ''
      };
      if (item.permission && !item.permission().success) {
        return;
      }
      const pagePermission = PagesList()[key]?.permission;
      if (pagePermission && !pagePermission().success) {
        return;
      }
      if (item.options) {
        item.children = [];
        recursion(item.options, item.children);
      }
      item.title = item.name;
      item.key = key;
      if (item.open) {
        defaultExpandedKeys.push(item.key);
      }
      array.push(item);
    });
  };
  if (data.menu) {
    recursion(data.menu, treeData);
  }
  return {
    defaultExpandedKeys,
    treeData: treeData.sort((a, b) => (a.name || '').localeCompare(b.name))
  };
};

interface IMenuItem {
  name: string;
  open?: boolean;
  withoutClick?: boolean;
  options: Record<string, IMenuItem>;
  permission?: () => IPermissionResult;
}

interface ISideBar {
  menu: Record<string, IMenuItem>;
}

export const transactionCodes: Record<string, ISideBar['menu']['']['options'][''] & {
  typeTxnClass: TypeTxnClass
}> = {
  procedureAndService: {
    name: 'Procedure and service',
    options: {},
    permission: () => txnCodePermission(),
    typeTxnClass: TypeTxnClass.Procedure
  },
  // xRay: { name: 'X-Ray', options: {}, permission: () => txnCodePermission() },
  education: {
    name: 'Education',
    options: {},
    permission: () => txnCodePermission(),
    typeTxnClass: TypeTxnClass.PatientEducation
  },
  homework: {
    name: 'Homework',
    options: {},
    permission: () => txnCodePermission(),
    typeTxnClass: TypeTxnClass.Plan
  },
  // insurancePayment: {
  //   name: 'Insurance Payment',
  //   options: {},
  //   permission: () => txnCodePermission()
  // },
  // chargeAdjustment: {
  //   name: 'Charge Adjustment',
  //   options: {},
  //   permission: () => txnCodePermission()
  // },
  // payment: {
  //   name: 'Payment',
  //   options: {},
  //   permission: () => txnCodePermission()
  // },
  // paymentAdjustment: {
  //   name: 'Payment Adjustment',
  //   options: {},
  //   permission: () => txnCodePermission()
  // },
  pointOfCare: {
    name: 'Point of Care',
    options: {},
    permission: () => pointOfCarePermission(),
    typeTxnClass: TypeTxnClass.PointOfCare
  },
  // recall: {
  //   name: 'Recall',
  //   options: {},
  //   permission: () => txnCodePermission()
  // },
  // allergy: {
  //   name: 'Allergy',
  //   options: {},
  //   permission: () => txnCodePermission()
  // },
  lab: {
    name: 'Lab', options: {}, permission: () => labsPermission(),
    typeTxnClass: TypeTxnClass.Laboratory
  },
  // nurse: { name: 'Nurse', options: {}, permission: () => txnCodePermission() },
  referral: {
    name: 'Referral',
    options: {},
    permission: () => txnCodePermission(),
    typeTxnClass: TypeTxnClass.Referral
  }
  // rx: { name: 'Rx', options: {}, permission: () => txnCodePermission() }
};

const transactionCodesOptions = Object.keys(transactionCodes)
  .sort()
  .reduce((obj, key) => {
    // @ts-ignore
    obj[key] = transactionCodes[key];
    return obj;
  }, {});

export const SIDEBAR_DATA = (): ISideBar => ({
  menu: {
    billing: {
      name: 'Billing',
      open: true,
      withoutClick: true,
      options: {
        insurance_companies: { name: 'Insurance companies', options: {} }
      }
    },
    calendar: {
      name: 'Calendar',
      open: true,
      withoutClick: true,
      options: {
        appointment_types: { name: 'Item types', options: {} }
      }
    },
    categories: {
      name: 'Categories',
      open: true,
      options: {}
    },
    clinics: {
      name: 'Clinics',
      open: true,
      withoutClick: true,
      options: {
        listOfClinics: { name: 'Clinic Setup', options: {} },
        checkInStatus: { name: 'Check In Status', options: {} },
        transactionCodes: {
          name: 'Transaction Codes by Clinic',
          options: {}
        }
      }
    },
    complience: {
      name: 'Compliance',
      open: true,
      withoutClick: true,
      options: {
        notification_manager: { name: 'Notification manager', options: {} },
        rule_manager: {
          name: 'Rule Manager',
          options: {}
        }
      }
    },
    couseling: {
      name: 'Counseling',
      open: true,
      withoutClick: true,
      permission: () => counselingPermission(),
      options: {
        riskTypes: { name: 'Risk types', options: {} },
        // plan: {
        //   name: 'Plan management',
        //   permission: () => planPermission(),
        //   options: {}
        // },
        automatedTasks: { name: 'Automated tasks', options: {} }
      }
    },
    genericTypes: {
      name: 'Drop-down management',
      open: true,
      options: {},
      permission: () => dropdownManagement()
    },
    formBuilder: {
      name: 'Form Builder',
      open: true,
      permission: () => formBuilderPermission(),
      options: {
        headersAndFooters: { name: 'Headers & Footers', options: {} },
        buildingBlocks: { name: 'Building Blocks', options: {} },
        forms: { name: 'Forms', options: {} },
        publicForms: { name: marketplaceDisplayName, options: {} },
        legacyTxnLinker: { name: 'Legacy Txn Linker', options: {} }
      }
    },
    ordersManager: {
      name: 'Orders Manager',
      open: true,
      permission: () => ordersManagerPermission(),
      options: {
        orderActions: {
          name: 'Order Actions',
          options: {},
          permission: () => ordersManagerPermission()
        },
        statusSetup: {
          name: 'Status Setup',
          options: {},
          permission: () => ordersManagerPermission()
        },
        resultCodes: {
          name: 'Result Codes',
          options: {},
          permission: () => ordersManagerPermission()
        },
        reportSetup: {
          name: 'Report Setup',
          options: {},
          permission: () => ordersManagerPermission()
        }
      }
    },
    noteTypes: {
      name: 'Note types',
      open: true,
      options: {}
    },
    supervisionIntern: {
      name: 'Supervision-Training',
      open: true,
      withoutClick: true,
      permission: () => supervisionNoteTypes(),
      options: {
        supervisionNoteTypes: { name: 'Note types', options: {} },
        supervisorRoles: {
          name: 'Supervisor roles',
          options: {},
          permission: () => supervisionTrainee()
        },
        traineeType: {
          name: 'Trainee Type',
          options: {}
        }
      }
    },
    studentChart: {
      name: getPatientTerm('Student chart'),
      open: true,
      withoutClick: true,
      options: {
        patientMenuItems: {
          name: 'Menu items',
          options: {}
        },
        additionalInfo: { name: 'Additional Info', options: {} }
      }
    },
    transactionSets: {
      name: 'Transaction Sets',
      open: true,
      options: {},
      permission: () => txnSetsPermission()
    },
    transactionCodesSettings: {
      name: 'Transaction Codes',
      open: true,
      withoutClick: true,
      permission: () => txnCodePermission(),
      options: transactionCodesOptions
    },
    // inventoryMaintenance: {
    //   name: 'Inventory Maintenance',
    //   open: true,
    //   withoutClick: true,
    //   options: {
    //     reordering: {
    //       name: 'Reordering',
    //       options: {},
    //     },
    //     adjustments: {
    //       name: 'Adjustments',
    //       options: {},
    //     }
    //   }
    // },
    providersMenu: {
      name: 'Providers',
      open: true,
      withoutClick: true,
      permission: () => providerTypesPermission(),
      options: {
        providers: {
          name: 'Providers',
          options: {}
        },
        providerTypes: {
          name: 'Provider types',
          options: {}
        }
      }
    },
    usersMenu: {
      name: 'Users',
      open: true,
      withoutClick: true,
      permission: () => usersPermission(),
      options: {
        users: {
          name: 'Users',
          options: {}
        },
        userGroups: {
          name: 'User Groups',
          options: {}
        },
        limitChartAccess: {
          name: 'Limit Chart Access',
          options: {},
          permission: () => limitChartAccessPermission()
        }
      }
    },
    diagnosis: {
      name: 'Diagnosis Codes',
      open: true,
      withoutClick: true,
      permission: () => {
        const data = diagnosisCodesPermission();
        if (data.success) {
          return data;
        }
        return diagnosisCodesByClinicPermission();
      },
      options: {
        diagnosisCodes: {
          name: 'Diagnosis Codes',
          open: true,
          options: {},
          permission: () => diagnosisCodesPermission()
        },
        diagnosisClinicLinks: {
          name: 'Diagnosis Codes by Clinic',
          options: {},
          permission: () => diagnosisCodesByClinicPermission()
        }
      }
    },
    letterTemplatesManagement: {
      name: 'Blaster Letter Templates',
      open: true,
      options: {}
    },
    patientPortalSetup: {
      // Requirements was changed
      name: 'Patient Portal Setup', // `${getPatientTerm('Patient')} Portal Setup`,
      open: true,
      permission: () => patientPortalSetupPermission(),
      options: {
        disclaimer: {
          name: 'Disclaimer',
          options: {}
        },
        ..._.reduce(
          portalOptionsPages().groups,
          (obj, param) => {
            // @ts-ignore
            obj[portalOptionSetupKey(param)] = {
              name: PortalOptionGroupNames()[param],
              options: {}
            };
            return obj;
          },
          {}
        )
      }
    },
    security: {
      name: 'Security',
      open: true,
      withoutClick: true,
      permission: () => securityPermission(),
      options: {
        QAndA: { name: 'Q & A', options: {} },
        settings: { name: 'Settings', options: {} },
        adUsers: { name: 'AD users', options: {} }
      }
    }
  }
});

export const focusOnSidebar = (e: React.KeyboardEvent<HTMLDivElement>) => {
  onKeyDownCallbacks(e, {
    ArrowLeft: () => {
      const menuData = getTreeData(SIDEBAR_DATA());
      const el = checkActive(menuData);
      const current = document.getElementById(idGenerator(el.key));
      if (current) {
        current.focus();
      }
    }
  });
};

export const onKeyDownOnPage = (e: React.KeyboardEvent<HTMLDivElement>) => { };

export const TableOnKeyDownRow = (e: React.KeyboardEvent<HTMLDivElement>) => { };

export const checkActive = (menuData: any) => {
  const value = Object.values(PagesList()).find((v) =>
    history?.location?.pathname.includes(v.path)
  );
  if (value && (value.permission ? value.permission() : true)) {
    return value;
  }
  const firstMenuItem = getFirstPage(menuData.treeData[0]);
  return firstMenuItem;
};

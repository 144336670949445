import { IPagination } from 'src/Framework/Controls/Pagination/types';

export interface IStateReducer {
  summary: ITasksSummary;
  loading: boolean;
  currentCategory: string | null;
  filters: IFilter;
  pagination: IPagination;
  sort: {
    sortBy: string | null;
    sortType: 'desc' | 'asc' | null;
  };
}

export interface ITasksSummary {
  [key: string]: {
    data: ITaskItem[];
    name: string;
  };
}

export interface IAction {
  type: string;
  payload: any;
}

export interface ITaskItem {
  clinicId: number;
  comment: string;
  dts: string;
  id: number;
  providerId: number;
  fromProviderId: null | number;
  status: string | null;
  taskType: { value: string };
  patient?: {
    firstName: string;
    id: number;
    lastName: string;
    nickName: string;
  };
  innerEntities?: IInnerEntity[];
  action?: TaskAction;
  uuid?: string;
  summary?: string;
}

export enum TaskAction {
  Invalid = 'invalid',
  CreateAppointmentNote = 'createAppointmentNote',
  LockNote = 'lockNote',
  AttachToAppointment = 'attachToAppointment'
}

enum LinkedEntityType {
  m1FormRecord = 'm1FormRecord',
  Invalid = 'invalid',
  TemplateForm = 'templateForm',
  TemplateFormRecord = 'templateFormRecord',
  Patient = 'patient',
  Note = 'note',
  Appointment = 'appointment',
  Referral = 'reqOrder',
  provider = 'provider'
}

interface IInnerEntity {
  entityId: number;
  entityType: LinkedEntityType;
  innerEntities: IInnerEntity[];
  additionalFields?: {
    seedId?: number;
  };
}

export interface IFilter {
  clinicIds: number[];
  providers: any[];
}

import React from 'react';
import { RecurrContainer } from 'src/Activities/Schedule/Popups/AppointmentPopup/AppointmentPopup_Styles';
import {
  TitleContainer,
  SelectionContainer
} from '../../Popups/BlockReservationPopup/BlockReservationPopup_Styles';
import { TextEllipsisSpan } from 'src/Activities/Schedule/Main/ScheduleCol/ScheduleOne/styled';
import { buildRecurrenceString } from 'src/Activities/Schedule/Utils/Utils';
import Tooltip from 'src/Framework/Controls/Tooltip';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import ReccuringEventsList from 'src/Activities/Schedule/Common/Editors/ReccuringEventsList';

export interface IOccurrence {
  date?: string;
  startTime?: string;
  endTime: string;
  isSkipped: boolean;
  scheduleEventDateTime?: string;
}

export function RecurrenceDisplay(series: any, disablePadding = false) {
  let label = buildRecurrenceString(series);
  const hideEmptyOccurences =
    !series?.seriesAppointments || series?.seriesAppointments.length === 0;
  const containerStyle: any = {
    display: 'flex',
    width: '700px',
    alignItems: 'center',
    marginBottom: 10
  };
  if (!disablePadding) {
    containerStyle.paddingTop = '10px';
  }

  let eventsList: IOccurrence[];
  if (!series?.seriesAppointments || series?.seriesAppointments.length === 0) {
    eventsList = [];
  } else {
    eventsList = (series?.seriesAppointments as IOccurrence[]).map((item) => ({
      ...item,
      date: item?.scheduleEventDateTime?.slice(0, 11) + '00:00:00',
      startTime: item?.scheduleEventDateTime
    }));
  }

  return (
    <SelectionContainer style={containerStyle}>
      <TitleContainer
        style={{ display: 'block', marginRight: '1rem', fontWeight: 'bold' }}
      >
        <label className={'category-label'}>Recurrence</label>
      </TitleContainer>
      <RecurrContainer>
        <Tooltip
          destroyTooltipOnHide={true}
          color={globalStyleVariables.white}
          overlayInnerStyle={{
            width: 388
          }}
          title={
            hideEmptyOccurences ? '' : <ReccuringEventsList data={eventsList} />
          }
        >
          <TextEllipsisSpan style={{ whiteSpace: 'initial' }} title={label}>
            {label +
              (hideEmptyOccurences
                ? ''
                : ` ${
                    eventsList.filter((item: IOccurrence) => !item.isSkipped)
                      .length
                  }/${eventsList.length}`)}
          </TextEllipsisSpan>
        </Tooltip>
      </RecurrContainer>
    </SelectionContainer>
  );
}

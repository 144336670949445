import React, { FC, useEffect, useState, useMemo } from 'react';

import { getCurrentProvider } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { getCurrentClinicId } from 'src/Framework/Controls/Selectors/ClinicSelector';
import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import Breadcrumbs from 'src/Framework/Controls/Breadcrumbs';
import { onKeyDownAccessibility } from 'src/Framework/util/accessibility';
import { useShallowEqualSelector } from 'src/store';

import { permissionChecking } from 'src/App/User/Permission';
import MyWatchList from './MyWatchList';
import Header from './Header';
import StudentsOfConcern from './StudentsOfConcern';
import StudentsOnHighRisk from './StudentsOnHighRisk';
import CrisisAppointments from './CrisisAppointments';
import * as dashboardActions from '../store/Actions';

import {
  RisksOuterContainer,
  RisksContainer,
  RisksLeft,
  RisksRight,
  RisksTab,
  TabName,
  CountLabel
} from '../styled';

interface IOwnProps {}

interface IProps extends IOwnProps {}

const TabContent = (props: any) => {
  switch (props.activeTab) {
    case 0:
      return <MyWatchList />;
    case 1:
      return <StudentsOfConcern />;
    case 2:
      return <StudentsOnHighRisk />;
    case 3:
      return <CrisisAppointments />;
    default:
      return <div />;
  }
};

const breadcrumbsBase = [
  {
    id: 0,
    title: 'Activity'
  },
  {
    id: 1,
    title: 'Risk Management'
  }
];

export const riskManagementGlobalPermissions = () => {
  const watchList = permissionChecking({
    anyClinic: true,
    moduleId: 105,
    functionId: 1200
  });
  const studentsOnHighRisk = permissionChecking({
    anyClinic: true,
    moduleId: 105,
    functionId: 1202
  });
  const crisisAppointments = permissionChecking({
    anyClinic: true,
    moduleId: 105,
    functionId: 1203
  });
  const studentsOfConcern = permissionChecking({
    anyClinic: true,
    moduleId: 105,
    functionId: 1216
  });
  if (
    !watchList.success &&
    !studentsOnHighRisk.success &&
    !crisisAppointments.success &&
    !studentsOfConcern.success
  ) {
    return {
      success: false,
      message: 'Permission for any of risk management parts was not found'
    };
  }
  return {
    success: true,
    message: ''
  };
};

const Component: FC<IProps> = ({}: IProps) => {
  const {
    studentsOfConcern,
    studentsOnHighRisk,
    watchList,
    crisisAppointments,
    providersIds,
    clinicId,
    activeTab,
    dateRange
  } = useShallowEqualSelector((state) => {
    const { riskManagement } = state.dashboard;
    return {
      studentsOfConcern: riskManagement.studentsOfConcern,
      studentsOnHighRisk: riskManagement.studentsOnHighRisk,
      watchList: riskManagement.myWatchList,
      crisisAppointments: riskManagement.crisisAppointments,
      providersIds: riskManagement.filters.providersIds,
      clinicId: riskManagement.filters.clinicId,
      activeTab: riskManagement.active,
      dateRange: riskManagement.filters.dateRange
    };
  });
  const [loading, setLoading] = useState(false);
  const breadcrumbs = useMemo(() => {
    switch (activeTab) {
      case 0:
        return breadcrumbsBase.concat([
          {
            id: 2,
            title: 'My Watch List'
          }
        ]);
      case 1:
        return breadcrumbsBase.concat([
          {
            id: 2,
            title: 'Reports of Concern'
          }
        ]);
      case 2:
        return breadcrumbsBase.concat([
          {
            id: 2,
            title: 'High Risk'
          }
        ]);
      case 3:
        return breadcrumbsBase.concat([
          {
            id: 2,
            title: 'Crisis Appointments'
          }
        ]);
      default:
        return breadcrumbsBase;
    }
  }, [activeTab]);

  const checkPermissions = (clinicId: number | null) => {
    const data = [
      {
        tabID: 0,
        title: 'My Watch List',
        count: watchList.length,
        permission: permissionChecking({
          clinicId: clinicId ? clinicId : undefined,
          anyClinic: false,
          moduleId: 105,
          functionId: 1200
        }).success
      },
      {
        tabID: 2,
        title: 'High Risk',
        count: studentsOnHighRisk.length,
        permission: permissionChecking({
          clinicId: clinicId ? clinicId : undefined,
          anyClinic: false,
          moduleId: 105,
          functionId: 1202
        }).success
      },
      {
        tabID: 3,
        title: (
          <>
            Crisis <br /> Appointments
          </>
        ),
        count: crisisAppointments.length,
        permission: permissionChecking({
          clinicId: clinicId ? clinicId : undefined,
          anyClinic: false,
          moduleId: 105,
          functionId: 1203
        }).success
      },
      {
        tabID: 1,
        title: (
          <>
            Reports <br />
            of Concern
          </>
        ),
        count: studentsOfConcern.filter((v) => v.latestNoteOfConcern).length,
        permission: permissionChecking({
          clinicId: clinicId ? clinicId : undefined,
          anyClinic: false,
          moduleId: 105,
          functionId: 1216
        }).success
      }
    ];
    return data;
  };

  const dataWithPermission = checkPermissions(clinicId).filter(
    (v) => v.permission
  );
  const [] = useState(() => {
    dashboardActions.setActiveDashboard('Risk Management');
    if (!clinicId) {
      const startClinicId = getCurrentClinicId();
      dashboardActions.setRisksFilters({ clinicId: startClinicId });
      const dataWithPermission = checkPermissions(startClinicId).filter(
        (v) => v.permission
      );
      dashboardActions.setActiveRiskDashboard(dataWithPermission[0]?.tabID);
    } else {
      const dataWithPermission = checkPermissions(clinicId).filter(
        (v) => v.permission
      );
      dashboardActions.setActiveRiskDashboard(dataWithPermission[0]?.tabID);
    }
    if (providersIds.length === 0) {
      const providerId = getCurrentProvider() as number;
      dashboardActions.setRisksFilters({ providersIds: [providerId] });
    }
  });

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await dashboardActions.getStudentsOfConcern({ clinicId });
      setLoading(false);
    };
    init();
  }, [clinicId]);

  useEffect(() => {
    const init = async () => {
      if (providersIds?.length === 0) {
        return;
      }
      setLoading(true);
      await Promise.all([
        dashboardActions.getStudentsOnHighRisk({ providersIds, clinicId }),
        dashboardActions.getMyWatchListForRisks({ providersIds, clinicId }),
        dashboardActions.getCrisisAppointments({
          providersIds,
          clinicId,
          dateRange
        })
      ]);
      setLoading(false);
    };
    init();
  }, [providersIds]);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await dashboardActions.getCrisisAppointments({
        providersIds,
        clinicId,
        dateRange
      });
      setLoading(false);
    };
    init();
  }, [dateRange]);

  const handleTabChange = (tabIndex: number) => {
    dashboardActions.setActiveRiskDashboard(tabIndex);
  };

  const onKeyDownTab = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && e.target === e.currentTarget) {
      //@ts-ignore
      e.target.click();
    }
  };
  return (
    <RisksOuterContainer>
      {loading && <AbsoluteLoader />}
      <Breadcrumbs data={breadcrumbs} isHeader={true} />
      <Header />
      <RisksContainer>
        <RisksLeft role="tablist" onKeyDown={onKeyDownAccessibility}>
          {dataWithPermission.map((v, index) => (
            <RisksTab
              key={v.tabID}
              tabIndex={0}
              role="tab"
              aria-selected={activeTab === v.tabID}
              active={activeTab === v.tabID}
              first={index === 0}
              last={index === dataWithPermission.length - 1}
              onClick={() => handleTabChange(v.tabID)}
              onKeyDown={onKeyDownTab}
            >
              <TabName>{v.title}</TabName>
              <CountLabel>{v.count}</CountLabel>
            </RisksTab>
          ))}
        </RisksLeft>
        <RisksRight role="tabpanel">
          <TabContent activeTab={activeTab} />
        </RisksRight>
      </RisksContainer>
    </RisksOuterContainer>
  );
};

export default React.memo(Component);

import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding: 20px;
`

export const Footer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(0,0,0,0.1);
`
const ids = {
  CONTAINER: 'recurring-container',
  providerSelector: 'provider-selector',
  options: {
    single: 'single-option',
    multiple: 'multiple-option'
  },
  repeats: 'repeats-select',
  dayOfWeekFilter: 'dayOfWeekFilter-select',
  dayOfMonthFilter: 'dayOfMonthFilter-select',
  weekNumber: 'weekNumber-select',
  weeksButtons: 'weeks-buttons-container',
  dayItem: (code: string) => `day-item-${code}`,
  startDate: 'start-date-picker',
  endOption: 'end-option-picker',
  occurrences: 'occurrences-select',
  endDate: 'end-date-picker',
  startTime: 'start-time-picker',
  endTime: 'end-time-picker',
  duration: 'duration-select',
  cancel: 'cancel-button',
  removeRecurrence: 'remove-recurrence-button',
  confirmOrCheckConflicts: 'confirm-or-check-conflicts',
  overrideAll: 'overrode-all-checkbox',
  overrideAllButton: 'overrode-all-button',
  skipAll: 'skip-all-checkbox',
  resolveItem: (id: string) => `resolve-item-${id}`,
  skipItem: (id: string) => `skip-item-${id}`,
  allDayBlockCheckBox: 'all-day-block-checkbox'
};

export default ids;

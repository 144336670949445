import { useCallback, useMemo, useRef, useState } from "react"
import { ReqOrderResult } from "../types"

import * as Actions from '../Actions'

export const useReqOrderResult = () => {
    const loadingReqOrders = useRef<{
        [reqOrderId: number]: boolean
    }>({})
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<Partial<{
        [reqOrderId: number]: {
            summary: ReqOrderResult[]
        }
    }>>({

    })
    const get = useCallback(async (...args: Parameters<typeof Actions.get>) => {
        const item = args[0]
        if (loadingReqOrders.current[item.reqOrderId]) return
        loadingReqOrders.current[item.reqOrderId] = true
        setLoading(true)
        const response = await Actions.get(...args)
        if (response) {
            setData(data => ({
                ...data,
                [item.reqOrderId]: {
                    summary: response
                }
            }))
        }
        setLoading(false)
        loadingReqOrders.current[item.reqOrderId] = false
        return response
    }, [])

    const post = useCallback(async (...args: Parameters<typeof Actions.post>) => {
        const response = await Actions.post(...args)
        if (response) {
            setData(data => {
                const summary = [...(data[response.reqOrderId]?.summary || [])]
                summary.push(response)
                return {
                    ...data,
                    [response.reqOrderId]: {
                        ...data[response.reqOrderId],
                        summary
                    }
                }
            })
            return response
        }
        return
    }, [])
    const put = useCallback(async (...args: Parameters<typeof Actions.put>) => {
        const response = await Actions.put(...args)
        if (response) {
            setData(data => {
                const summary = [...(data[response.reqOrderId]?.summary || [])]
                const i = summary.findIndex((v) => v.id === response.id)
                if (i !== -1) {
                    summary[i] = {
                        ...response
                    }
                }
                return {
                    ...data,
                    [response.reqOrderId]: {
                        ...data[response.reqOrderId],
                        summary
                    }
                }
            })
            return response
        }
        return
    }, [])

    const actions = useMemo(() => {
        return {
            get,
            post,
            put,
        }
    }, [get, post, put,])
    return {
        data,
        loading,
        actions,
    }
}
import React, { FC, useRef } from 'react';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import ids from 'src/tests-script/pages/counseling/Dashboard/Referrals';

import ReferralResultModal, {
  IModalHandles
} from 'src/Activities/Counseling/Dashboard/CounselingReferrals/ReferralResutModal';

interface IProps {
  patientId: string | number;
  referralId: number;
  color?: string;
}

const Component: FC<IProps> = ({ patientId, referralId, color }: IProps) => {
  const ReferralResultRef = useRef<IModalHandles>(null);
  return (
    <>
      <ReferralResultModal
        ref={ReferralResultRef}
        subjectId={patientId}
        sliderMode={true}
      />
      <ButtonIcon
        isButton={true}
        label="Referral"
        haspopup="dialog"
        controlsId={ids.REFERRALS_ACTIVITY_LOG_MODAL}
        color={color}
        tooltip="Referral"
        name={ICONS_LIST.activityLog}
        onClick={() => {
          ReferralResultRef.current?.show({
            value: referralId
          });
        }}
      />
    </>
  );
};

export default Component;

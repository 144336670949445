import { IControls } from "../../../../App/Admin/Pages/NoteTypes/store/types";
import _ from "lodash";
import { Alert } from "../../../../Framework/Common/Notification";
import { ILegacyForm, IM1Form, Type, ValidationsStatuses } from "./types";
import TESTS_IDS from 'src/tests-script/pages/counseling/NewNotes/Note';
import { IFormItem } from "src/Activities/Forms/FormList/types";
import { IM1FormRecord } from "src/App/M1FormRecords/store/types";

export const formsElementId = TESTS_IDS.NOTE_FORMS
export const attachmentsElementId = TESTS_IDS.NOTE_ATTACHMENTS
export const containerId = 'main-note-container';
export const formIOId = 'formIO-container';

export function checkInView(element: HTMLElement): boolean {
    const container = document.getElementById(containerId);
    if (!container) {
        return false;
    }
    //Get container properties
    const cTop = container.scrollTop;
    const cBottom = cTop + container.clientHeight;

    //Get element properties
    const eTop = element.offsetTop;
    const eBottom = eTop + element.clientHeight;

    //Check if in view
    const isTotal = eTop >= cTop && eBottom <= cBottom;
    const isPartial =
        ((eTop < cTop + 5 || eTop == cTop) && eBottom > cTop + 5)
    //Return outcome
    return isTotal || isPartial;
}

export function constructDetailsRequest(control: IControls, data: any) {
    const details: any = {
        details: {
            [control.id]: {
                type: control.controlType || "invalid",
                data: {}
            }
        }
    }
    switch (control.controlType) {
        case 'narrative':
            details.details[control.id].data = {}
            break;
        case 'risk':
            details.details[control.id].data = {}
            break;
        case 'diagnosis':
            details.details[control.id].data = {}
            break;
        case 'ticketDetailGeneric':
            details.details[control.id].data = {}
            break;
        case 'referral':
            details.details[control.id].data = {
                reqOrders: data
            }
            break;
    }
    return data // details for now I changed it, have to be updated
}

export function checkForInvalidControlRules(control: IControls, validations: ValidationsStatuses) {
    let valid = true;
    _.values(validations).forEach((validStatus) => {
        if (!validStatus.valid) {
            valid = false
            Alert('warning', `${control.id}-${control.controlType}`, validStatus.message);
        }
    });
    return valid;
}

export const LegacyFormConvert = (form: IFormItem): ILegacyForm => {
    return {
        type: Type.LEGACY,
        ...form
    }
}

export const M1FormConvert = (form: IM1FormRecord): IM1Form => {
    return {
        type: Type.M1,
        ...form
    }
}

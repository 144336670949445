import Moment from 'moment';
import { extendMoment } from 'moment-range';

import _ from 'lodash';
import {
  getEndTime,
  getMinFrom1200AM,
  getTimeObjectStringFromSimpleTime,
  timeDiff
} from './Utils';
import { getApptColor } from 'src/Activities/Schedule/utils';
import { ScheduleTimeProps } from '../Common/Interfaces';
import { IProvider } from 'src/App/Admin/Pages/Providers/store/types';
import { ClinicSimpleQueryResult } from 'src/App/Admin/Pages/Clinics/store/types';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

//@ts-ignore
const moment = extendMoment(Moment);

function buildEvent(
  event: any,
  sliceId: any,
  duration: any,
  clinicCode: string
) {
  let eventCard = {
    ...event,
    color: event.color || '#000000',
    duration,
    endTime: moment(event.endTime).format('HH:mm'),
    date: event.startTime,
    startTime: moment(event.startTime).format('HH:mm'),
    sliceId,
    clinicCode
  };
  return eventCard;
}

function getParsedReservation(event: any, sliceId: any) {
  if (event?.isReservation) {
    // if its a resrvation
    return {
      // TODO: need to optimize it
      appointmentFor: event.appointmentFor,
      isBlock: event.isBlock,
      uid: event.uid,
      isReservation: event.isReservation,
      provider: event.provider,
      color: event.color,
      appointmentBlockId: event.appointmentBlockId,
      inSeries: event.inSeries,
      sliceId,
      isMultiProvider: event.isMultiProvider,
      reason: event.reason,
      reservation: event,
      startTime: moment(event.startTime).format('HH:mm'),
      endTime: moment(event.endTime).format('HH:mm'),
      duration: timeDiff(
        moment(event.endTime).format('HH:mm'),
        moment(event.startTime).format('HH:mm')
      )
    };
  }
  return;
}

function getParsedEvent(
  event: any,
  dayStartTime: any,
  regularMeetingTime: any,
  schedules: any[],
  sliceId: any
) {
  if (!event) {
    return;
  }

  const startTime =
    event.startTime?.length > 5
      ? moment(event.startTime).format('HH:mm')
      : event.startTime;
  const endTime =
    event.endTime?.length > 5
      ? moment(event.endTime).format('HH:mm')
      : event.endTime;

  const duration = timeDiff(endTime, startTime);
  const timeDiffStart = timeDiff(startTime, dayStartTime);
  const timeDiffEnd = timeDiff(endTime, dayStartTime);
  let scheduleStartIdx = timeDiffStart / regularMeetingTime;
  let scheduleEndIdx = timeDiffEnd / regularMeetingTime;
  // TODO: Event mutation super bad decision
  event.duration = duration;
  event.sliceId = sliceId;

  if (!schedules[scheduleStartIdx]) {
    // MISSED APPT
    scheduleStartIdx = Math.round(scheduleStartIdx);
    scheduleEndIdx = Math.round(scheduleEndIdx);
    if (!schedules[scheduleStartIdx]) {
      return;
    }
  }
  event.scheduleStartIdx = scheduleStartIdx;
  event.scheduleEndIdx = scheduleEndIdx;
  for (let i = scheduleStartIdx; i < scheduleEndIdx; i += 1) {
    if (schedules[i]) {
      if (event.isReservation) {
      } else {
        schedules[i].eventKeys.push(event.uid);
      }
    }
  }
}

export function getParsedEvents(
  events: any,
  dayStartTime: any,
  dayEndTime: any,
  regularMeetingTime: any,
  date: any,
  allowedLimit: number,
  sliceId: string,
  showCancellations: boolean
) {
  //generating a empty schedule
  const emptySchedules = generateEmptySchedulesFromDayStartEndTime(
    dayStartTime,
    dayEndTime,
    Number(regularMeetingTime),
    date
  );
  const tempSchedule = _.cloneDeep(emptySchedules);
  let scheduleGroups: any = [];

  //parsing appointment and blocks
  Object.keys(events).forEach((uid: string) => {
    const event = events[uid];
    if (showCancellations || event?.scheduleEventTypeId !== 4) {
      const item = getParsedReservation(event, sliceId);
      if (item) {
        scheduleGroups.push(item);
      }
      // TODO: prevent mutations inside this function in future
      getParsedEvent(
        event,
        dayStartTime,
        regularMeetingTime,
        tempSchedule,
        sliceId
      );
    }
  });
  //parsing reservations
  const overlaps = getOverlaps(tempSchedule);
  const regions = mergeOverlaps(date, events, overlaps);
  const regionsWithConflicts = checkConflicts(regions, allowedLimit);
  //preparing empty slots in column
  emptySchedules.forEach((timeSlot: any) => {
    timeSlot['spaceExists'] = {
      1: true,
      2: true
    };
  });

  const emptyPlaces = pushRegionsToSchedule(_.cloneDeep(emptySchedules), []);
  const schedules = pushRegionsToSchedule(emptySchedules, regionsWithConflicts);
  return {
    scheduleGroups,
    schedules,
    emptyPlaces
  };
}

function checkConflicts(merged: any, allowedLimit: number) {
  let regions = merged;
  regions.forEach((region: any) => {
    checkConflict(region, allowedLimit);
  });
  return regions;
}

function checkConflict(region: any, allowedLimit: number) {
  checkBlockConflicts(region);
  region.events = resortConflictEvents(region);
  checkAppointmentConflicts(region, allowedLimit);
}

function checkBlockConflicts(region: any) {
  let blocks: any[] = [];
  region.events.forEach((event: any) => {
    if (event && event.isBlock) {
      blocks.push(event);
    }
  });
  let hasBlocks: boolean = blocks && blocks.length > 0;
  region.events.forEach((event: any) => {
    event.appointmentOverlapsCount = 0;
    //console.log("event.scheduleEventTypeId", event.scheduleEventTypeId)
    if (
      event &&
      (event.scheduleEventTypeId == 1 || event.scheduleEventTypeId == 6)
    ) {
      var hasBlockConflict: boolean = false;
      if (hasBlocks) {
        //console.log("--blocks",blocks);
        hasBlockConflict = checkBlockOverlaps(event, blocks);
        event.hasBlockConflict = hasBlockConflict;
        event.hasConflict = hasBlockConflict;
      }
    }
  });
}

function resortConflictEvents(region: any) {
  let appointmentEvents: any[] = [];
  let blockConflictEvents: any[] = [];
  let cancelEvents: any[] = [];
  let resortedEvents: any[] = [];
  region.events.forEach((event: any) => {
    if (
      event &&
      (event.scheduleEventTypeId == 1 || event.scheduleEventTypeId == 6)
    ) {
      appointmentEvents.push(event);
    } else if (event && event.scheduleEventTypeId == 4) {
      cancelEvents.push(event);
    } else {
      if (event?.isBlock) {
        blockConflictEvents.push(event);
      } else {
        resortedEvents.push(event);
      }
    }
  });
  cancelEvents.forEach((event: any) => {
    resortedEvents.push(event);
  });
  appointmentEvents.forEach((event: any) => {
    resortedEvents.push(event);
  });
  blockConflictEvents.forEach((event: any) => {
    resortedEvents.push(event);
  });
  return resortedEvents;
}

function checkAppointmentConflicts(region: any, allowedLimit: number) {
  let apptIndex: number = 0;
  region.events.forEach((event: any) => {
    event.appointmentOverlapsCount = 0;
    //console.log("event.scheduleEventTypeId", event.scheduleEventTypeId)
    if (
      event &&
      (event.scheduleEventTypeId == 1 || event.scheduleEventTypeId == 6)
    ) {
      if (!event.hasBlockConflict) {
        //Check how many appointment overlaps are there for the appointment
        //console.log("region events",region.events);
        let appointmentOverlaps = checkAppointmentConflict(
          event,
          region.events,
          apptIndex
        );
        event.appointmentOverlaps = appointmentOverlaps;
        event.hasConflict =
          appointmentOverlaps.overlapsCount > allowedLimit &&
          appointmentOverlaps.overlapOrder >= allowedLimit;
      }
      apptIndex += 1;
    }
  });
  region.eventKeys = region.events.map((event: any) => {
    return event.uid;
  });
}

function checkAppointmentConflict(
  appt: any,
  regionEvents: any[],
  apptIndex: number
) {
  let overlapsCount: number = 0;
  let overlapOrder: number = 0;
  let checkIndex: number = 0;
  regionEvents.forEach((event: any) => {
    if (event && !event.isBlock && event.scheduleEventTypeId !== 4) {
      const thisEventRange = moment.range(
        moment(moment(event.date).format('YYYY-MM-DD') + ' ' + event.startTime),
        moment(moment(event.date).format('YYYY-MM-DD') + ' ' + event.endTime)
      );
      const eventRange = moment.range(
        moment(moment(appt.date).format('YYYY-MM-DD') + ' ' + appt.startTime),
        moment(moment(appt.date).format('YYYY-MM-DD') + ' ' + appt.endTime)
      );
      if (thisEventRange.overlaps(eventRange)) {
        if (apptIndex > checkIndex && !event.hasBlockConflict) {
          overlapOrder += 1;
        }
        overlapsCount += 1;
      }
      checkIndex += 1;
    }
  });

  return { overlapOrder, overlapsCount };
}

function checkBlockOverlaps(eventToCheck: any, blocks: any) {
  let isOverlapPresent = false;
  blocks.map((event: any) => {
    //console.log(event)
    let eTime = moment(
      moment(event.date).format('YYYY-MM-DD') + ' ' + event.endTime
    );
    let sTime = moment(
      moment(event.date).format('YYYY-MM-DD') + ' ' + event.startTime
    );
    let eventRange = moment.range(sTime, eTime);
    let endTime = moment(
      moment(eventToCheck.date).format('YYYY-MM-DD') +
        ' ' +
        eventToCheck.endTime
    );
    let startTime = moment(
      moment(eventToCheck.date).format('YYYY-MM-DD') +
        ' ' +
        eventToCheck.startTime
    );
    let range = moment.range(startTime, endTime);
    //console.log(range)
    //console.log(eventRange)
    if (eventRange.overlaps(range)) {
      isOverlapPresent = true;
    }
  });
  //console.log(isOverlapPresent);
  return isOverlapPresent;
}

function mergeOverlaps(date: any, events: any, overlaps: any) {
  let merged = overlaps;
  merged.forEach((overlap: any) => {
    mergeOverlap(date, events, overlap);
  });
  return merged;
}

function mergeOverlap(date: any, events: any, overlap: any) {
  overlap.events = [];
  overlap.eventKeys.forEach((eventKey: any) => {
    const event = events[eventKey];
    if (event) {
      const eventCard = buildEvent(
        events[eventKey],
        event.sliceId,
        event.duration,
        event.clinicCode
      );
      overlap.events.push(eventCard);
    }
  });
  const maxEndTimeValue = findMaxEndTimeInTimeSlot(overlap.events);
  const maxEndTime = getTimeObjectStringFromSimpleTime(
    maxEndTimeValue.endTime,
    date
  );
  overlap.maxEndTime = maxEndTime;
  overlap.scheduleEndIdx = maxEndTimeValue.scheduleEndIdx;
  overlap.events = sortEventsInTimeSlot(overlap.events);
  overlap.eventKeys = overlap.events.map((event: any) => {
    return event.uid;
  });
}

function getOverlaps(schedules: any[]) {
  const overlaps: any = [];
  const schedulesWithEvents: any = [];
  schedules.forEach((schedule: any) => {
    let length: number = schedule.eventKeys?.length ?? 0;
    if (length > 0) {
      schedulesWithEvents.push(schedule);
    }
  });
  let row: any = {
    eventKeys: []
  };
  schedulesWithEvents.forEach((schedule: any) => {
    //initialize
    if (overlaps.length === 0) {
      row.eventKeys = [...schedule.eventKeys];
      row.startTime = schedule.startTime;
      row.scheduleStartIdx = schedule.index;
      overlaps.push(row);
    }
    //subsequent
    else {
      const intersects = row.eventKeys.filter((x: string) => {
        return schedule.eventKeys.includes(x);
      });
      const intLength = intersects?.length ?? 0;
      if (intLength > 0) {
        row.eventKeys = [...new Set([...row.eventKeys, ...schedule.eventKeys])];
      } else {
        row = {
          startTime: schedule.startTime,
          scheduleStartIdx: schedule.index,
          eventKeys: [...schedule.eventKeys]
        };
        overlaps.push(row);
      }
    }
  });
  return overlaps;
}

export function generateEmptySchedulesFromDayStartEndTime(
  dayStartTime: string,
  dayEndTime: string,
  regularMeetingTime: number,
  date?: any
) {
  // generating empty schedules
  const schedules = [];

  const minstart: number = getMinFrom1200AM(dayStartTime);
  const minend: number = getMinFrom1200AM(dayEndTime);
  let timeIdx = 0;
  const increment: number = Number(regularMeetingTime);
  let index = 0;
  for (timeIdx = minstart; timeIdx <= minend; timeIdx += increment) {
    schedules.push({
      index,
      date,
      startTime: getEndTime('0:00', timeIdx.toString()),
      endTime: getEndTime('0:00', (timeIdx + increment).toString()),
      patients: [],
      eventKeys: [],
      overflows: []
    });
    index += 1;
  }
  return schedules;
}

export function sortEventsInTimeSlot(
  overlapEvents: any[],
  key: any = 'createdDts'
) {
  let s1: any[] = _.clone(overlapEvents);
  let s2: any[] = [];
  if (s1.length > 0) {
    s1 = _.sortBy(s1, (event: any) => {
      return new Date(event[key]);
    });
    s2 = [
      ...s1.filter((c) => c.isBlock === true),
      ...s1.filter((c) => c.isBlock === false)
    ];
  }

  return s2;
}

function pushRegionsToSchedule(schedules: any, overlaps: any) {
  overlaps.forEach((overlap: any) => {
    pushRegionToSchedule(schedules, overlap);
  });
  return schedules;
}

function getColumnOrder(event: any, placedEvents: any) {
  var columnOrder = 0;
  placedEvents.forEach((placedEvent: any) => {
    //Check if start or end time is within range of placed card start/end time
    let eventStartOverlaps =
      event.scheduleStartIdx >= placedEvent.scheduleStartIdx &&
      event.scheduleStartIdx < placedEvent.scheduleEndIdx;
    let eventEndOverlaps =
      placedEvent.scheduleStartIdx >= event.scheduleStartIdx &&
      placedEvent.scheduleStartIdx < event.scheduleEndIdx;

    if (eventStartOverlaps || eventEndOverlaps) {
      columnOrder = placedEvent.columnOrder == 1 ? 0 : 1;
    }
  });
  return columnOrder;
}

function pushOverflows(schedules: any, overlap: any, event: any) {
  if (!schedules[overlap.scheduleStartIdx]) {
  } else {
    schedules[overlap.scheduleStartIdx].overflows.push(event);
    schedules[overlap.scheduleStartIdx].overflowEndTime = overlap.maxEndTime;
    setOverflows(schedules, overlap);
  }
}

const canPlaceEvent = (schedules: any, event: any) => {
  let place: boolean = true;
  for (var i = event.scheduleStartIdx; i < event.scheduleEndIdx; i += 1) {
    if (schedules[i] && !schedules[i].spaceExists[event.order]) {
      place = false;
    }
  }
  return place;
};

function pushRegionToSchedule(schedules: any, overlap: any) {
  if (overlap.events.length > 0) {
    let placedEvents: any = [];
    overlap.events.forEach((event: any) => {
      if (!schedules[event.scheduleStartIdx]) {
      } else {
        var overlapCount = getOverlapCount(schedules, event);
        event.overlapCount = overlapCount;
        if (overlapCount < 2) {
          event.columnOrder = getColumnOrder(event, placedEvents);
          event.order = event.columnOrder + 1;
          if (canPlaceEvent(schedules, event)) {
            for (
              var i = event.scheduleStartIdx;
              i < event.scheduleEndIdx;
              i += 1
            ) {
              if (!schedules[i]) {
              } else {
                schedules[i].eventKeys.push(event.uid);
                schedules[i].spaceExists[event.order] = false;
              }
            }
            placedEvents.push(event);
          } else {
            pushOverflows(schedules, overlap, event);
          }
        } else {
          pushOverflows(schedules, overlap, event);
        }
      }
    });
    //calculate the overlap count after all events are placed
    placedEvents.forEach((event: any) => {
      if (!schedules[event.scheduleStartIdx]) {
      } else {
        var overlapCount = getOverlapCount(schedules, event);
        //console.log(event, overlapCount)
        event.overlapCount = overlapCount - 1;
        schedules[event.scheduleStartIdx].patients.push(event);
      }
    });
  }
  return schedules;
}

function setOverflows(schedules: any, overlap: any) {
  //console.log(schedules, overlap);
  for (var i = overlap.scheduleStartIdx; i < overlap.scheduleEndIdx; i += 1) {
    if (i < schedules.length && i >= 0) {
      schedules[i].hasOverflows = true;
    }
  }
}

function getOverlapCount(schedules: any, event: any) {
  var overlapCount = 0;
  for (var i = event.scheduleStartIdx; i < event.scheduleEndIdx; i += 1) {
    if (!schedules[i]) {
    } else {
      let scheduleOverlaps = schedules[i].eventKeys.length ?? 0;
      if (scheduleOverlaps > overlapCount) {
        overlapCount = scheduleOverlaps;
      }
    }
  }
  return overlapCount;
}

function findMaxEndTimeInTimeSlot(overlapEvents: any[]) {
  let sortedEvents = _.orderBy(
    overlapEvents,
    function (o) {
      return moment(
        getTimeObjectStringFromSimpleTime(o.endTime, o.date)
      ).format('HH:mm');
    },
    ['asc']
  );
  const event = sortedEvents[sortedEvents.length - 1];
  return {
    endTime: event.endTime,
    scheduleEndIdx: event.scheduleEndIdx
  };
}

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const generateSchedules = (
  sliceData: any,
  props: {
    clinic: ClinicSimpleQueryResult | null;
    provider?: IProvider;
    slice: any;
    scheduleTimeProps: ScheduleTimeProps;
    showCancellations: boolean;
  }
) => {
  const sliceTempData = _.cloneDeep(sliceData);
  const { provider, clinic, slice, scheduleTimeProps, showCancellations } =
    props;
  const sliceId = slice.id;
  const { dayStartTime, regularMeetingTime, dayEndTime } = scheduleTimeProps;

  // parse params from slice
  const params = {};
  sliceId.split(':').forEach((paramData: string) => {
    var [paramKey, paramValue] = paramData.split('#');
    params[paramKey] = paramValue;
  });

  const providerCode = provider?.code || '';
  const providerName = `${provider?.firstName} ${provider?.lastName}`;

  const clinicCode = clinic?.code || '';
  const clinicName = clinic?.name;

  const allowedLimit = (slice?.pages ?? 0) === 0 ? 1 : slice?.pages;
  const date = new Date(
    params['date'].replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3')
  );

  const weekDay = weekDays[date.getDay()];

  //placing all events in slots
  const parsedEventObject = getParsedEvents(
    sliceTempData,
    dayStartTime,
    dayEndTime,
    regularMeetingTime,
    date,
    allowedLimit,
    sliceId,
    showCancellations
  );
  return {
    date,
    weekDay,
    providerCode,
    providerName,
    clinicCode,
    clinicName,
    clinicId: clinic?.id,
    schedules: parsedEventObject.schedules,
    scheduleGroups: parsedEventObject.scheduleGroups,
    objKey: sliceId,
    pages: allowedLimit,
    emptyPlaces: parsedEventObject.emptyPlaces || []
  };
};

export function renderSchedules(schedules: any) {
  const updatedSchedules = renderTimeSlots(schedules);

  updatedSchedules.forEach((timeSlot: any, indexL: number) => {
    updatedSchedules[indexL] = setCardSize(updatedSchedules[indexL]);
  });

  return updatedSchedules;
}

function setCardSize(timeSlot: any) {
  const overflowContainerPresent = timeSlot.hasOverflows ?? false;
  const allowedWidth = overflowContainerPresent ? 0.716825 : 0.925;
  timeSlot.patients.forEach((patient: any, indexL: number) => {
    let occupyWidth = allowedWidth;
    if (patient.uid) {
      if (
        patient.overlapCount > 0 ||
        timeSlot.eventKeys.length > 1 ||
        patient.columnOrder > 0
      ) {
        occupyWidth = occupyWidth / 2;
      }
      timeSlot.patients[indexL].occupyWidth = occupyWidth;
      timeSlot.patients[indexL].occupyX =
        occupyWidth * (timeSlot.patients[indexL].order - 1);
    }
  });
  return timeSlot;
}

function renderTimeSlotOverflows(timeSlot: any, index: number) {
  if (timeSlot.overflows.length > 0) {
    const maxEndTime = timeSlot.overflowEndTime;
    timeSlot.maxEndTime = maxEndTime;
    const isAllCancelled = timeSlot.overflows.every(
      (item: any) => item.scheduleEventTypeId === 4
    );
    const firstActiveAppt = timeSlot.overflows.find(
      (item: any) => item.scheduleEventTypeId !== 4
    );
    const sTime = getTimeObjectStringFromSimpleTime(
      timeSlot.startTime,
      timeSlot.date
    );
    const hasConflict = timeSlot.overflows.some(
      (event: any) => event.hasConflict
    );
    timeSlot.patients.push({
      endTime: moment(maxEndTime).format('HH:mm'),
      date: timeSlot.date,
      startTime: moment(sTime).format('HH:mm'),
      duration: moment
        .duration(moment(maxEndTime).diff(moment(sTime)))
        .asMinutes(),
      color: isAllCancelled
        ? globalStyleVariables.cancelledScheduleEventBG
        : getApptColor(firstActiveAppt),
      overflowing: 2,
      overflowCount: timeSlot.overflows.length,
      hasConflict: hasConflict,
      index: index,
      order: 3,
      occupyX: 0.716875,
      occupyWidth: 0.208125,
      provider: timeSlot.overflows[0]?.provider
    });
  }
}

function renderTimeSlots(schedules: any) {
  //rendering overflow container
  schedules.map((schedule: any, index: number) => {
    renderTimeSlotOverflows(schedule, index);
  });

  return schedules;
}

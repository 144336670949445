import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { Row, Col } from 'antd';
import memoize from 'fast-memoize';

import Modal from 'src/Framework/Controls/Modal';
import Button from 'src/Framework/Controls/Button';
import SelectContainer from 'src/Framework/Controls/SelectContainer';
import ProviderSelector from 'src/Framework/Controls/Selectors/ProviderSelector';
import {
  clinicHasRoleProviders,
  clinicProviderRoleChecker,
  getCurrentProvider
} from 'src/Framework/Controls/Selectors/ProviderSelector/utils';
import { getClinicByCode } from 'src/Framework/Controls/Selectors/ClinicSelector';
import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import Attachments from 'src/Framework/Controls/Attachments';
import Checkbox from 'src/Framework/Controls/Checkbox';
import {
  IFileSimpleDto,
  uploadMultipartFiles
} from 'src/Framework/Controls/FileUploader';
import { useValidatorV2 } from 'src/Framework/util/formValidatorV2';

import Forms, {
  IModalHandles as IModalHandlesForms
} from 'src/Activities/Home/NewMessage/Forms';
import { useModalFocus } from 'src/App/Accessibility/Hooks/useModalFocus';
import { useSettings } from 'src/App/Settings/store/Hooks/useSettings';
import {
  getPatientFormPDFPreview,
  getTemplateFormPDFPreview
} from 'src/Activities/Home/NewMessage/store/Actions';

import PatientItem from './PatientItem';
import SelectedAll from './SelectAll';
import * as actions from './Actions';
import { openAlert } from './Alert';

import {
  Container,
  Sidebar,
  Right,
  Title,
  PatientsListContainer,
  PatientsListAbsolute,
  LineDivider
} from './styled';

interface IProps {
  providerIds?: number[];
  title: string;
}

interface IShow {
  value: IData;
}

export interface IModalHandles {
  show(obj: IShow): void;

  close(): void;
}

export interface IModalData {
  clinicCode: string;
  groupName?: string;
  providerId?: number;
  patients: {
    patientInfo: {
      patientId: number;
      firstName: string;
      lastName: string;
    };
  }[];
}

type IData = IModalData | null;

const messageAreaInputs = {
  rows: 16
};

const getDefaultData = (): {
  subject: string;
  message: string;
  providerId: null | number;
  followupDate: null | string;
  hasFollowup: boolean;
} => ({
  subject: '',
  message: '',
  providerId: null,
  followupDate: null,
  hasFollowup: false
});

const defaultMessageData = {
  followupDate: null,
  priority: 'normal',
  readReceipt: false,
  type: 'secure',
  conversationId: null,
  students: [],
  attachmentIds: [],
  hasFollowup: false
};

const Component: React.ForwardRefRenderFunction<IModalHandles, IProps> = (
  { providerIds, title },
  ref
) => {
  const settings = useSettings();
  const UploaderRef = useRef<HTMLInputElement>(null);
  const FormsRef = useRef<IModalHandlesForms>(null);
  const [readReceipt, setReadReceipt] = useState(false);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [data, setData] = useState<IData>(null);
  const [visible, setVisible] = useState(false);
  const [selectedPatientIds, setSelectedPatientIds] = useState<number[]>([]);
  const [inputs, setInputs] = useState(getDefaultData());
  const [fileDefinitions, setFileDefinitions] = useState<IFileSimpleDto[]>([]);
  useModalFocus({ active: visible });
  const currentClinic = useMemo(() => {
    if (data?.clinicCode) {
      const clinic = getClinicByCode(data.clinicCode);
      if (clinic) {
        return clinic;
      }
    }
    return;
  }, [data?.clinicCode]);

  const show = ({ value }: IShow) => {
    if (value) {
      const clinic = getClinicByCode(value.clinicCode);
      const providerId = value.providerId || getCurrentProvider();
      if (clinic && providerId) {
        const checkCurrentProvider = clinicProviderRoleChecker(
          clinic.id,
          providerId,
          'appointment'
        );
        if (checkCurrentProvider) {
          setInputs((inputs) => ({
            ...inputs,
            providerId
          }));
        } else {
          const providers = clinicHasRoleProviders(clinic.id, 'appointment');
          if (providers.length !== 0) {
            setInputs((inputs) => ({
              ...inputs,
              providerId: providers[0]?.providerId || null
            }));
          }
        }
      }
      setData(value);
    }

    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const setToDefault = useCallback(() => {
    setData(null);
    setSelectedPatientIds([]);
    setInputs(getDefaultData());
    setAttachments([]);
    setReadReceipt(false);
  }, []);

  useImperativeHandle(ref, () => ({
    show,
    close
  }));

  const onChangeAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const files = Array.from(e.target.files);
        setAttachments((value) => [...value, ...files]);
      }
    },
    []
  );

  const onClickAddAttachment = useCallback(
    () => UploaderRef?.current?.click(),
    []
  );

  const onClickOpenForms = useCallback(() => {
    FormsRef.current?.show({});
  }, []);

  const onRemoveAttachment = useCallback((index: number) => {
    setAttachments((value) => {
      const data = [...value];
      data.splice(index, 1);
      return data;
    });
  }, []);

  const onChangeSelected = useCallback(
    (value: IModalData['patients'][number]) => {
      setSelectedPatientIds((selected) => {
        const arr = [...selected];
        const i = arr.indexOf(value.patientInfo.patientId);
        if (i !== -1) {
          arr.splice(i, 1);
          return arr;
        }
        return [...arr, value.patientInfo.patientId];
      });
    },
    []
  );

  const onChangeInputs = useCallback(
    memoize((field: keyof typeof inputs) => (value: any) => {
      setInputs((data) => ({
        ...data,
        [field]: value
      }));
    }),
    []
  );

  const onSelectAll = useCallback(() => {
    if (data) {
      setSelectedPatientIds((selected) => {
        if (selected.length === data.patients.length) {
          return [];
        } else {
          const values = data.patients.map((v) => v.patientInfo.patientId);
          return values;
        }
      });
    }
  }, [data]);

  const validation = useValidatorV2(
    [
      {
        fieldName: 'Recipients',
        value: selectedPatientIds.length > 0
      },
      {
        fieldName: 'Subject',
        value: inputs.subject
      },
      {
        fieldName: 'Provider',
        value: inputs.providerId
      },
      {
        fieldName: 'Message Text',
        value: inputs.message
      }
    ],
    [selectedPatientIds, inputs]
  );

  const apply = async () => {
    if (data && currentClinic) {
      let attachmentIds: number[] = fileDefinitions.map((v) => v.id);
      if (attachments.length > 0) {
        const res = await uploadMultipartFiles(attachments);
        res.forEach((v) => attachmentIds.push(v.id));
      }
      const res = await actions.postMessages(
        selectedPatientIds.map((v) => ({
          ...defaultMessageData,
          actors: {
            receivers: [
              {
                id: v,
                actorType: 'patient',
                type: 'patient'
              }
            ],
            sender: {
              id: inputs.providerId,
              actorType: 'provider',
              type: 'provider'
            }
          },
          clinicId: currentClinic.id,
          text: inputs.message,
          subject: inputs.subject,
          hasFollowup: inputs.hasFollowup,
          followupDate: inputs.hasFollowup ? inputs.followupDate : null,
          dts: new Date(),
          readReceipt,
          attachmentIds
        }))
      );
      if (res) {
        openAlert(data, selectedPatientIds, res);
        close();
      }
    }
  };

  const defaultWidth = 1250;

  return (
    <Modal
      isModalSlider={true}
      modalSliderProps={{
        defaultWidth: `${defaultWidth}px`,
        minWidth: `${defaultWidth}px`,
        isOpen: visible,
        onClose: close,
        afterClose: setToDefault,
        hideHeader: true
      }}
      width={defaultWidth}
      title={''}
      hideHeader={true}
      visible={visible}
      onClose={close}
      hideClose={true}
      headerBorder={true}
      afterClose={setToDefault}
      modalProps={{
        destroyOnClose: true
      }}
      id="new-secure-message-modal"
    >
      {data && currentClinic && (
        <Container>
          <Forms
            ref={FormsRef}
            message={{ clinicId: currentClinic.id }}
            onChangeForms={async ({
              formTemplates,
              formatType,
              patientForms
            }) => {
              if (formatType === 'link') {
                let text = '';
                const url = settings.data.portalOptions?.summary.url.value;
                if (url) {
                  if (formTemplates.length !== 0) {
                    text = formTemplates
                      .map((v) => {
                        if (v.type === 'legacy') {
                          return `${url}/note.aspx?formid=${v.value.id}&clinicid=${currentClinic.id}&providerid=${inputs.providerId}`;
                        }
                        if (v.type === 'm1') {
                          return `${url}/note.aspx?m1formid=${v.value.id}&clinicid=${currentClinic.id}&providerid=${inputs.providerId}`;
                        }
                        return '';
                      })
                      .join(`\n`);
                  }
                  if ((patientForms || []).length !== 0) {
                    text += (patientForms || [])
                      .map((v) => {
                        if (v.type === 'legacy') {
                          return `${url}/note.aspx?recordId=${v.value.id}`;
                        }
                        if (v.type === 'm1') {
                          return `${url}/note.aspx?m1RecordId=${v.value.id}`;
                        }
                        return '';
                      })
                      .join(`\n`);
                  }
                }
                onChangeInputs('message')(`${inputs.message}\n${text}`);
              }
              if (formatType === 'pdf') {
                const files: IFileSimpleDto[] = [];
                if (formTemplates.length !== 0) {
                  const res = await Promise.all(
                    formTemplates.map((v) => {
                      if (v.type === 'legacy') {
                        return getTemplateFormPDFPreview({
                          id: v.value.seedId,
                          type: v.type,
                          clinicId: currentClinic.id || undefined,
                          providerId: inputs.providerId || undefined
                        });
                      }
                      if (v.type === 'm1') {
                        return getTemplateFormPDFPreview({
                          id: v.value.id,
                          type: v.type
                        });
                      }
                      return;
                    })
                  );
                  res.forEach((v) => {
                    if (v) {
                      files.push(v);
                    }
                  });
                }
                if ((patientForms || []).length !== 0) {
                  const res = await Promise.all(
                    (patientForms || []).map((v) => {
                      if (v.type === 'legacy') {
                        return getPatientFormPDFPreview({
                          id: v.value.seedId,
                          type: v.type
                        });
                      }
                      if (v.type === 'm1') {
                        return getPatientFormPDFPreview({
                          id: v.value.id,
                          type: v.type
                        });
                      }
                      return;
                    })
                  );
                  res.forEach((v) => {
                    if (v) {
                      files.push(v);
                    }
                  });
                }
                setFileDefinitions((data) =>
                  [...data, ...files].filter(
                    (v, i, arr) => !arr.some((val) => val.id === v.id)
                  )
                );
                return;
              }
            }}
          />
          <Row style={{ height: '100%' }}>
            <Col span={8} style={{ display: 'flex' }}>
              <Sidebar>
                <LineDivider />
                <SelectedAll
                  active={data.patients.length === selectedPatientIds.length}
                  value={data}
                  onSelectAll={onSelectAll}
                  title={title}
                />
                <PatientsListContainer>
                  <PatientsListAbsolute>
                    {data.patients.map((v) => (
                      <PatientItem
                        key={v.patientInfo.patientId}
                        value={v}
                        active={
                          selectedPatientIds.indexOf(
                            v.patientInfo.patientId
                          ) !== -1
                        }
                        onChangeSelected={onChangeSelected}
                      />
                    ))}
                  </PatientsListAbsolute>
                </PatientsListContainer>
              </Sidebar>
            </Col>
            <Col span={16}>
              <Right>
                <Row justify="space-between">
                  <Col>
                    <Title>
                      <b>
                        Send Message{' '}
                        <span className="patients-number">
                          {selectedPatientIds.length}
                        </span>{' '}
                        recipients
                      </b>
                    </Title>
                  </Col>
                  <Col style={{ display: 'flex', alignItems: 'center' }}>
                    <Row align="middle" gutter={16} justify="end">
                      <Col>
                        <Checkbox
                          id="read-receipt"
                          checked={readReceipt}
                          onChange={() => setReadReceipt((v) => !v)}
                        >
                          Read Receipt
                        </Checkbox>
                      </Col>
                      <Col>
                        <ButtonIcon
                          isButton={true}
                          label="Forms"
                          haspopup="dialog"
                          onClick={onClickOpenForms}
                          name={ICONS_LIST.paper}
                          tooltip="Forms"
                          tooltipProps={{
                            overlayStyle: {
                              zIndex: 1400
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <input
                          style={{ display: 'none' }}
                          ref={UploaderRef}
                          onClick={(e: any) => (e.target.value = null)}
                          onChange={onChangeAttachment}
                          type="file"
                          multiple={true}
                        />
                        <ButtonIcon
                          isButton={true}
                          label="Attachments"
                          haspopup="dialog"
                          onClick={onClickAddAttachment}
                          name={ICONS_LIST.attachment}
                          tooltip="Attachments"
                          tooltipProps={{
                            overlayStyle: {
                              zIndex: 1400
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <Button
                          tooltip={{
                            title: validation.isValid
                              ? undefined
                              : validation.errorMessage
                          }}
                          id="send-message"
                          onClick={apply}
                          disabled={!validation.isValid}
                        >
                          Send Message
                        </Button>
                      </Col>
                      <Col>
                        <ButtonIcon
                          name={ICONS_LIST.thinClose}
                          isButton={true}
                          label="close dialog"
                          id="close-dialog-button"
                          onClick={close}
                          size={28}
                          disableBackground={true}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row align="middle" gutter={16}>
                  <Col flex={1}>
                    <SelectContainer
                      star={true}
                      label="Subject"
                      type="input"
                      value={inputs.subject}
                      onChange={onChangeInputs('subject')}
                    />
                  </Col>
                  <Col>
                    <Row align="middle" gutter={16} style={{ marginBottom: 5 }}>
                      <Col>
                        <Checkbox
                          id="follow-up"
                          checked={inputs.hasFollowup}
                          onChange={() =>
                            onChangeInputs('hasFollowup')(!inputs.hasFollowup)
                          }
                        >
                          Follow Up
                        </Checkbox>
                      </Col>
                      <Col>
                        <SelectContainer
                          type={'date'}
                          label="Follow date"
                          inputProps={{
                            disabled: !inputs.hasFollowup
                          }}
                          hideLabel={true}
                          value={inputs.followupDate}
                          onChange={(followupDate: any) => {
                            onChangeInputs('followupDate')(followupDate);
                          }}
                          bottomMargin={false}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <ProviderSelector
                  selectProps={{
                    star: true,
                    inputProps: {
                      allowClear: false
                    }
                  }}
                  value={inputs.providerId}
                  onChange={onChangeInputs('providerId')}
                  roleFilter="appointment"
                  filterByClinic={currentClinic?.id}
                  disabled={providerIds?.length === 1}
                  providerFilter={
                    providerIds
                      ? (value) => providerIds.includes(value.providerId)
                      : undefined
                  }
                />
                <SelectContainer
                  star={true}
                  label="Message Text"
                  type="area"
                  value={inputs.message}
                  onChange={onChangeInputs('message')}
                  inputProps={messageAreaInputs}
                  bottomMargin={false}
                  areaAutoReplace={true}
                />
                {[...attachments, ...fileDefinitions].length > 0 && (
                  <Attachments
                    files={[...attachments, ...fileDefinitions]}
                    onRemove={onRemoveAttachment}
                  />
                )}
              </Right>
            </Col>
          </Row>
        </Container>
      )}
    </Modal>
  );
};

export default React.forwardRef(Component);

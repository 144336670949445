import React from "react";

export const AlertRedTriangleSvg = () => (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7V7.38918V10.2" stroke="#FF2828" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 12.3999V12.4121V12.4999" stroke="#FF2828" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3 15L10 3L17 15H3Z" stroke="#FF2828" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>



);
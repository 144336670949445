import { DefaultItemHeight } from "src/Activities/Schedule/Main/Main_Styles";
import { pickTextColorBasedOnBgColorSimple } from "src/Framework/util/helps";
import styled from "styled-components";


export const ScheduleGroupContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  user-select: none;
`;

export const GroupTitle = styled.div`
  position: absolute;
  height: 10px;
  top: 0px;
  left: 0px;

  padding: 0px 4px 0px 4px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 14px;
  /* or 175% */
  
  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #FFFFFF;
`;

export function GroupTitleStyle(groupTitle: string, color: string, isReservation: boolean): any {
  var groupTitleStyle: any = {
    backgroundColor: color, color: pickTextColorBasedOnBgColorSimple(
      color,
      'white',
      'black'
    )
  };
  if (groupTitle.length > 25) {
    groupTitleStyle.width = "100%";
  } else if (groupTitle == "Block") {
    groupTitleStyle.width = "38px";
  }
  return groupTitleStyle;
};

export function GroupBkStyle(color: string, groupLen: number, groupUIOffset: number, groupTitle: string, isReservation: boolean): React.CSSProperties {
  var groupStyle: React.CSSProperties = {
    position: `absolute`,
    height: `${DefaultItemHeight() * groupLen}px`,
    borderLeft: isReservation ? `solid ${color} 2px` : "none",
    top: 5 + DefaultItemHeight() * groupUIOffset,
    left: 0,
    width: "3px",
    zIndex: isReservation ? 3 : 1
  };
  if (groupTitle == "Block") {
    groupStyle.background = "#F7F7F7";
    groupStyle.cursor = "no-drop";
    groupStyle.borderLeft = "none"
  }
  return groupStyle;
}

export function GroupStyleForTitle(groupUIOffset: number, isReservation: boolean): React.CSSProperties {
  var groupStyle: React.CSSProperties = {
    position: `absolute`,
    height: `10px`,
    top: 5 + DefaultItemHeight() * groupUIOffset,
    left: 0,
    cursor: isReservation ? "pointer" : "initial"
  };
  return groupStyle;
}
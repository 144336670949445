import React from 'react';
import moment from 'moment';

import DragAndDrop from 'src/Framework/Layout/DragAndDrop';
import { providerDisplayNameByCode } from 'src/Framework/Controls/Selectors/ProviderSelector/utils';

import CalendarSnap, {
  columnsNumberLimit
} from 'src/Activities/Schedule/Common/Editors/CalendarSnapShot/CalendarSnap';
import { getTimeObject } from 'src/Activities/Schedule/Utils/Utils';
import ConflictsList from 'src/Activities/Schedule/ConflictsBar/ConflictsList';

export interface IConflictsContentProps {
  conflicts: any;
  clinicOpts: any;
  selectedProviders: any;
  selectedClinic: any;
}

export interface IConflictsList {
  startDate: string;
  endDate: string;
  providerName: string;
}

export function conflictsContent({ ...props }: IConflictsContentProps) {
  const conflictColumnsNumber = props.conflicts.conflictResponse.reduce(
    (acc: number, item: any) => acc + item?.providerCode?.length || 0,
    0
  );

  const allConflicts = () => {
    return props.conflicts.conflictResponse.map((item: any) => ({
      startDate: item.scheduleEventDateTime,
      endDate: item.endTime,
      providerName: item.providerCode
        .map((code: string) => providerDisplayNameByCode(code) || '')
        .join(', ')
    }));
  };

  if (props.conflicts && props.conflicts?.conflictResponse.length > 0) {
    const startConflict = [...props.conflicts.conflictResponse].sort(
      (a: any, b: any) =>
        new Date(a.scheduleEventDateTime).getTime() -
        new Date(b.scheduleEventDateTime).getTime()
    );
    const endConflict = [...props.conflicts.conflictResponse].sort(
      (a: any, b: any) =>
        new Date(b.endTime).getTime() - new Date(a.endTime).getTime()
    );
    let searchLine = {
      Event: {
        startTime: startConflict[0]?.scheduleEventDateTime
          ? getTimeObject(
              moment(startConflict[0].scheduleEventDateTime).format()
            )
          : null,
        endTime: endConflict[0]?.endTime
          ? getTimeObject(moment(endConflict[0].endTime).format())
          : null,
        providerCodes: props.selectedProviders
          ? props.selectedProviders.map((item: any) => {
              return item.value;
            })
          : [],
        clinicCode: props.selectedClinic.value
      }
    };

    return (
      <div
        onMouseDown={(e: any) => e.preventDefault()}
        style={
          props.selectedProviders
            ? {
                height: 300,
                width:
                  conflictColumnsNumber <= columnsNumberLimit
                    ? conflictColumnsNumber * 200 + 160
                    : 600
              }
            : { height: 300, width: '100%' }
        }
      >
        {conflictColumnsNumber <= columnsNumberLimit && (
          <DragAndDrop>
            <CalendarSnap
              clinicOptions={props.clinicOpts}
              providers={props.selectedProviders}
              searchLine={searchLine}
            />
          </DragAndDrop>
        )}
        {conflictColumnsNumber > columnsNumberLimit && (
          <ConflictsList data={allConflicts()} />
        )}
      </div>
    );
  }
  return null;
}
